import React, { useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

import styles from './HelpBox.module.scss';

interface HelpBoxProps {
  children: React.ReactNode;
  content: React.ReactNode | string;
  width?: number;
  placement?:
    | 'top'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined;
}

const HelpBox = ({ content, children, placement, width }: HelpBoxProps) => {
  const place:
    | 'top'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'bottom'
    | 'right'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined = placement || 'top';

  const uID = new Date().getTime();

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const hidePophover = () => {
    setShow(false);
  };

  const showPopover = () => {
    setShow(true);
  };

  const maxWidth: number = width || 450;

  return (
    <>
      <span ref={target} onMouseEnter={showPopover} onMouseLeave={hidePophover}>
        {children}
      </span>

      <Overlay
        target={target.current}
        show={show}
        placement={place}
        rootClose
        rootCloseEvent="mousedown"
        onHide={hidePophover}
      >
        {({ arrowProps, ...props }) => {
          return (
            <Popover
              id={`popover-help-${uID}`}
              {...props}
              onMouseEnter={showPopover}
              onMouseLeave={hidePophover}
              arrowProps={{ ...arrowProps, style: { display: 'none' } }}
              className={place === 'bottom' ? styles.helpBoxBottom : styles.helpBox}
              style={{ ...props.style, maxWidth: maxWidth }}
            >
              <Popover.Content bsPrefix="helpBoxBody">{content}</Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
};

export default HelpBox;
