import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const GasVsElectricVehicle: React.FC = () => {
  const columns = [
    {
      dataField: 'vehicleType',
      text: 'Vehicle Type >>',
    },
    {
      dataField: 'param',
      text: '',
    },
    {
      dataField: 'electricLow',
      text: 'Electric',
    },
    {
      dataField: 'electricHigh',
      text: 'Electric',
    },
    {
      dataField: 'gasolineLow',
      text: 'Gasoline',
    },
    {
      dataField: 'gasolineHigh',
      text: 'Gasoline',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Gas vs. Electric Vehicle</h1>

        <p className={styles.bodyText}>
          Following are few important factors to consider before deciding to buy a gas or electric vehicle.
        </p>

        <h3 className={styles.paraHeading}>Purchase Price</h3>

        <p className={styles.bodyText}>
          In general, electric vehicles (EVs) are more expensive than gasoline vehicles. Base price of a mid-sized sedan
          electric vehicle (EV) can range between $30,000 to $40,000. A similar gasoline powered vehicle is about
          $10,000 to $15,000 cheaper.
        </p>

        <h3 className={styles.paraHeading}>Government Incentives</h3>
        <p className={styles.bodyText}>
          The Government of Canada offers incentives to the Canadians for purchasing environment friendly vehicles.
          These include battery electric vehicles and plug-in hybrid electric vehicles. Incentives range between $2,500
          to $5,000 paid from the Government fund at point-of-sale directly to the dealer. In other words, Canadian
          consumers can deduct the Government incentive from the purchase price.
        </p>
        <p className={styles.bodyText}>
          In addition to the government incentives, there are significant incentives at the provincial levels. British
          Columbia, New Brunswick, Ontario, Nova Scotia, Prince Edward Island and Quebec offers excellent incentives for
          their residents that can range up to $3,000.
        </p>

        <h3 className={styles.paraHeading}>Fuel Savings</h3>
        <p className={styles.bodyText}>
          With the rise of fuel cost driven by Ukraine war and increasing carbon tax imposed by the Government of
          Canada, electric vehicles (EVs) are beginning to make more commercial sense. Cost of batteries are also coming
          down gradually.
        </p>
        <p className={styles.bodyText}>
          CAA Car Cost Calculator estimates that the fuel cost of two equivalent cars to be $200 of electricity per year
          for every 10,000 km for electric vehicles (EVs) and compared to of $1,500 gas per year for gasoline cars. The
          difference is about $13,000 during the first 100,000 km life of the car.
        </p>

        <h3 className={styles.paraHeading}>Maintenance Costs</h3>
        <p className={styles.bodyText}>
          Maintenance costs are lower for electric vehicles (EVs) compared to gasoline cars. Electric vehicles (EVs)
          don’t need many routine maintenances like oil changes or engine air filters changes. According to CAA
          estimates, two similar car’s annual maintenance cost is about $430 for electric vehicles (EVs) and $630 for
          gasoline cars. That adds up to $2,000 over a ten-year period.
        </p>
        <p className={styles.bodyText}>
          Remember, electric vehicles (EVs) are relatively new and long-term maintenance cost are largely unknown. For
          example, its battery alone can cost $15,000 to replace in case it fails and not covered by the warranty
        </p>

        <h3 className={styles.paraHeading}>Insurance</h3>
        <p className={styles.bodyText}>
          Insurance on electric vehicles (EVs) are generally more than gasoline cars. Electric vehicles (EVs) is a new
          technology and the insurance companies do not yet have enough historical data to analyze and set up an
          informed pricing structure. Electric vehicle’s (EVs) parts are expensive as well. Some insurers offer ‘green’
          discounts for environment-friendly vehicles like EVs or hybrids. It is better to contact few insurers to get a
          sense of pricing for your chosen model.
        </p>

        <h3 className={styles.paraHeading}>Other Considerations</h3>
        <p className={styles.bodyText}>
          Electric vehicle’s (EVs) charging cost may vary significantly depending on the province you live, the car you
          drive and where and when you charge. Natural Resources Canada exhibits electric consumption rate of every
          electric vehicle (EVs).
        </p>
        <p className={styles.bodyText}>
          There are many free charging stations across Canada, but it is not easy to find a spot on a regular basis.
          Cost of charging at a supercharging station operated by private operators can be quite expensive and could
          often be at par with today’s gasoline prices.
        </p>

        <h3 className={styles.paraHeading}>Conclusion</h3>
        <SimpleTable
          keyField="chargerLevels"
          data={[
            {
              vehicleType: 'Description',
              param: 'Assumption',
              electricLow: 'Low',
              electricHigh: 'High',
              gasolineLow: 'Low',
              gasolineHigh: 'High',
            },
            {
              vehicleType: 'Purchase Price',
              param: 'Retail Price',
              electricLow: '$30,000',
              electricHigh: '$40,000',
              gasolineLow: '$20,000',
              gasolineHigh: '$25,000',
            },
            {
              vehicleType: 'Less: Federal Incentive',
              param: 'One Time',
              electricLow: '2,500',
              electricHigh: '5,000',
              gasolineLow: '0',
              gasolineHigh: '0',
            },
            {
              vehicleType: 'Less: Provincial Incentive',
              param: 'One Time',
              electricLow: '1,500',
              electricHigh: '3,000',
              gasolineLow: '0',
              gasolineHigh: '0',
            },
            {
              vehicleType: 'Add: Fuel Cost',
              param: '100,000 kms',
              electricLow: '2,000',
              electricHigh: '2,000',
              gasolineLow: '15,000',
              gasolineHigh: '15,000',
            },
            {
              vehicleType: 'Add: Maintenance Cost',
              param: '5 Years',
              electricLow: '2,250',
              electricHigh: '2,250',
              gasolineLow: '3,250',
              gasolineHigh: '3,250',
            },
            {
              vehicleType: 'Add: Insurance Difference',
              param: '5 Years',
              electricLow: '3,000',
              electricHigh: '6,000',
              gasolineLow: '0',
              gasolineHigh: '0',
            },
            {
              vehicleType: 'Total',
              param: '',
              electricLow: '$33,250',
              electricHigh: '$42,250',
              gasolineLow: '$38,250',
              gasolineHigh: '$43,250',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <p className={styles.bodyText}>
          As shown in the above summary table, electric vehicles (EVs) cost more at the time of purchase. After taking
          into consideration of the savings from fuel and maintenance costs, electric vehicle&apos;s (EVs) cost of
          ownership comes down significantly and can be at par to a comparable gasoline powered vehicle, if not lower.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          The above example not vehicle specific but an illustration of the methodology and a road map you can follow
          about how to approach the cost comparison exercise. Insert your own figures and assumptions for your
          short-listed electric vehicle (EV) and gasoline vehicle to see which one make makes sense for you.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/12/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-II/text/short/12-jul-2022/04" />
    </>
  );
};
