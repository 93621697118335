import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const CashInYourOldPhone: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Cash-in Your Old Phone</h1>

        <p className={styles.bodyText}>
          If you are like the most Canadians, then chances are you change your phone every three years or so. It is also
          highly likely that your old phones are sitting idle somewhere in your drawer or basement. Not taking any
          action means you are letting go thousands of your retirement dollars.
        </p>

        <p className={styles.bodyText}>
          You can sell your phone to any of the following parties. Your selling price will largely depend on who you are
          selling to. The list below is ranked in order of price you will get, lowest to highest:
        </p>

        <ol className={styles.list}>
          <li>Carriers (Bell, Rogers, Telus).</li>
          <li>Retailers (Best Buy, Amazon).</li>
          <li>Device Manufacturers (Apple, Samsung).</li>
          <li>Used Device Buyers and Resellers (EcoATM, Gazelle, Back Market, Decluttr).</li>
          <li>Direct to Buyer (Swappa, Craigslist, eBay, Facebook Market Place).</li>
        </ol>

        <p className={styles.bodyText}>
          You may need to choose between convenience of selling and getting a better price. Trading in with a carrier or
          retailer would be most convenient but will offer the lowest price. Selling direct will offer the highest price
          but you must find a right buyer and handle queries, shipping, returns etc. Does it worth the extra effort?
          Perhaps. The highest offers are generally double of the lowest offers in this market.
        </p>

        <p className={styles.bodyText}>
          Buyback programs by the carriers offer the best prices at the time of the new model launch. At times they are
          so aggressive that the new phone is offered without any additional payments.
        </p>

        <p className={styles.bodyText}>
          The price of your phone will also depend on the age, model, and manufacturer of your phone. Condition of the
          phone and whether it is locked or unlocked will also have an impact.
        </p>

        <p className={styles.bodyText}>
          Timing is very critical. Smartphones tend to lose value very quickly. It is important to sell your phone as
          soon as possible. Android phones tend to lose value faster than iPhones.
        </p>

        <p className={styles.bodyText}>
          There are generally more used phone supplies in the market right after Apple releases new models in September,
          and this oversupply causes used phone prices to decline. Plan to list your phone before September to get a
          higher price.
        </p>

        <p className={styles.bodyText}>
          Getting a good price for your used phone needs some advance planning. To keep your phone in a better shape,
          you should use a case and screen protector. This will keep your phone almost in new condition.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Used phone market is much more structured and organized today. You have plenty of options to choose from. It
          is time to find your old phone and cash-in.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/18/2022'} />
      </div>

      <FeedbackBox source="/expenses/save-on-phone-bills/text/short/18-mar-2022/01" />
    </>
  );
};
