import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhyBond: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Why Bond</h1>

        <p className={styles.bodyText}>Key Advantages of bonds are appended below:</p>

        <ol className={styles.list}>
          <li>
            <strong>Predictable Return: </strong>Bond’s interest rate and as well as principal value are pre-set and you
            will know both before buying. Therefore, you know exactly what returns you are going to get for the risks
            you are undertaking.
          </li>
          <li>
            <strong>Better Returns: </strong>Bonds offer comparable and at times better returns compared to other
            investment options such as stocks, after considering associated risks, transaction costs, fees, taxes and
            bad timing of buying or selling generally faced by individual investors.
          </li>
          <li>
            <strong>Low Risk: </strong>Bonds are one of the lowest risk investments you can find. You are unlikely to
            lose even a cent, if you invest in a good quality Plain Vanilla Bonds, dominated in Canadian Dollars and
            hold it until those bonds become due (Maturity Date).
          </li>
          <li>
            <strong>Low Transaction Costs: </strong>Bonds have a relatively low transaction cost. The fee charged by the
            brokers for selling the Plain Vanilla Bond is generally small. If you buy the Bonds at the time of their
            issuing (Initial Public Offering, IPO), then there are no transaction fees or selling commissions charges at
            all.
          </li>
          <li>
            <strong>Provides Income: </strong>Bonds generally make regular intertest payments on quarterly, half-yearly
            or annual basis. This is a continuous source of income and suitable for any retirement portfolio.
          </li>
          <li>
            <strong>Customizable: </strong>Bond portfolio can be tailored to fit your individual needs.
          </li>
          <li>
            <strong>Simple: </strong>Constructing a Bond portfolio is a simple process. It is easy to understand and any
            average individual without any prior financial knowledge can do it without professional help.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'03/09/2022'} />
      </div>

      <FeedbackBox source="/investment/bond/text/short/9-mar-2022/01" />
    </>
  );
};
