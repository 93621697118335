import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const GoodChoice: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: '',
    },
    {
      dataField: 'o1',
      text: 'Option 1',
    },
    {
      dataField: 'o2',
      text: 'Option 2',
    },
    {
      dataField: 'o3',
      text: 'Option 3',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Making a Good Choice</h1>

        <p className={styles.bodyText}>
          In order to make critical decisions, having a simple but effective tool could help to make wise choices.
          Thoughtful decisions can really be instrumental in building future blocks of life, particularly for the high
          value and durable items. These decisions not only involve a high financial commitment, but also it is very
          difficult and costly to undo these mistakes. Making good choices is indeed mission critical.
        </p>

        <ol className={styles.list}>
          <li>
            <b>Criteria:</b> Selecting key criteria is a good start. Ideal criteria should be short and simple. Limited
            within three to five, no matter how big the decision is. Keeping small number of criteria forces us to us
            think harder to boil down what is important to us about any decision. Finally, these criteria should be
            ranked in order of their preference.
          </li>
          <li>
            <b>Options:</b> Options are the alternative choices available from where we need to choose the best one.
            Again, goal is to shortlist the choices to three to five.
          </li>
        </ol>

        <SimpleTable
          keyField="id"
          data={[
            { o0: 'Criteria 1', o1: '', o2: '', o3: '' },
            { o0: 'Criteria 2', o1: '', o2: '', o3: '' },
            { o0: 'Criteria 3', o1: '', o2: '', o3: '' },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={clsx(styles.bodyText, styles.last)}>
          Final step would be to assign score for each criteria of each option in the scale of one to Ten; Ten being the
          highest. While scoring, it is essential to be as objective as unbiased as possible. Rest of the process should
          be simple. The option with the highest score would be the most logical choice.{' '}
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/13/2021'} />
      </div>

      <FeedbackBox source="/goal/settinggoal/text/short/13-jun-2021/01" />
    </>
  );
};
