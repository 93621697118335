import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { BarChart } from './BarChart';
import { PublishedDate } from './PublishedDate';
import HelpBox from '../components/Common/HelpBox';
import infoImg from '../common/images/info.svg';

export const InterestRateRaised: React.FC = () => {
  const data = [
    {
      Date: 'Oct 28, 2020',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Dec 9, 2020',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Jan 20, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Mar 10, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Apr 21, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Jun 9, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'July 14, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Sep 8, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Oct 27, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Dec 28, 2021',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Jan 26, 2022',
      'Target (%)': 0.25,
      color: 'rgb(84,129,176)',
    },
    {
      Date: 'Mar 2, 2022',
      'Target (%)': 0.5,
      color: 'rgb(84,129,176)',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Interest Rate Raised
          <HelpBox
            placement="right"
            content={
              <>
                <p className="mb-1">Source:</p>
                <p className="mb-0">Bank of Canada</p>
              </>
            }
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <h2 className={styles.subHeading}>Bank of Canada</h2>

        <ul className={styles.list}>
          <li>
            On March 02, 2022, The Bank of Canada increased its target for the overnight rate to ½ %, with the Bank Rate
            at ¾ % and the deposit rate at ½ %.
          </li>
          <li>
            Bank of Canada indicated that more rate increases may be needed, with current inflation level at a much
            higher level of its 2% target. Current annual inflation rate is at 30-year high level of 5.1%.
          </li>
          <li>
            Central banks around the world are beginning to announce their strategies to control the inflation. In
            response to the pandemic, central banks reduced their intertest rate to almost zero.
          </li>
        </ul>

        <div className={styles.chartWrapper}>
          <div className={styles.chartContainer}>
            <BarChart
              data={data}
              keys={['Target (%)']}
              indexBy={'Date'}
              axisLeftLegendTitle={'Target (%)'}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 90, left: 70 },
                legends: [],
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
              }}
            />
          </div>
        </div>

        <h3 className={styles.paraHeading}>Outlook</h3>

        <ul className={styles.list}>
          <li>
            Bank of Canada indicated that the “interest rates will need to rise further”, in response to the continued
            pressure on inflation due to expanding economy.
          </li>
          <li>
            In fourth quarter of 2021, Canada’s economy posted 6.7% annualized growth with economy is operating at its
            full capacity. Gross domestic product is now above pre-pandemic level. Household spending remains robust.
          </li>
          <li>
            In this context, some economists believe there could be another 3 Interest Rate increases during rest of
            2022.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Analysis</h3>
        <ul className={styles.list}>
          <li>Inflation expectation in the market is high.</li>
          <li>
            Apart from the post pandemic surge in demand, the war in Ukraine and ban on Russian oil by USA and Canada
            has further intensified the inflation risk.
          </li>
          <li>Overall, we are in a raising interest rate environment.</li>
          <li>
            We alert all our customers to adjust their debt and investment related strategies accordingly.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/14/2022'} />
      </div>

      <FeedbackBox source="/overview/economy/text/short/14-mar-2022/01" />
    </>
  );
};
