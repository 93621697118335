import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import styles from './SignUpLayout.module.scss';

const DEFAULT_CIRCLE_BG = {
  background: 'linear-gradient(-18.14deg, rgba(198, 198, 198, 1) 0%, rgba(239, 239, 239, 1) 100%)',
};
const ACTIVE_CIRCLE_BG = {
  background: 'linear-gradient(-18.14deg, rgba(66, 161, 236, 1) 0%, rgba(0, 112, 201, 1) 100%)',
};

const SIGNUP_STEP: Record<string, number> = {
  'account-info': 1,
  'address-info': 2,
  'subscription-info': 3,
  completed: 4,
};

export const SignUpLayout: React.FC = ({ children }) => {
  const { step } = useParams<{ step?: string }>();
  const stepId = step ? SIGNUP_STEP[step] : 0;

  const [width, setWidth] = useState('0.01%');
  const [activatedStepBg, setActivatedStepBg] = useState({
    1: DEFAULT_CIRCLE_BG,
    2: DEFAULT_CIRCLE_BG,
    3: DEFAULT_CIRCLE_BG,
    4: DEFAULT_CIRCLE_BG,
  });
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressBarRef.current) {
      setTimeout(() => {
        const width = stepId !== 4 ? 'calc(' + stepId * 25 + '% - 40px)' : '100%';
        setWidth(width);
      }, 300);
      setTimeout(() => {
        setActivatedStepBg({
          1: stepId >= 1 ? ACTIVE_CIRCLE_BG : DEFAULT_CIRCLE_BG,
          2: stepId >= 2 ? ACTIVE_CIRCLE_BG : DEFAULT_CIRCLE_BG,
          3: stepId >= 3 ? ACTIVE_CIRCLE_BG : DEFAULT_CIRCLE_BG,
          4: stepId >= 4 ? ACTIVE_CIRCLE_BG : DEFAULT_CIRCLE_BG,
        });
      }, 600);
    }
  }, [stepId]);

  return (
    <div className={styles.container}>
      <div className={styles.stepsBlock}>
        <div className={styles.logo} />
        <h1 className={styles.title}>Create your Arctic Rich account</h1>
        <div className={styles.steps}>
          <div className={clsx(styles.stepLine)}></div>
          <div className={clsx(styles.stepLineActive)} style={{ width }} ref={progressBarRef}></div>

          <div className={styles.stepContainer}>
            <div
              className={clsx(styles.stepCircle, styles.stepCircleFirst, { [styles.stepCircleActive]: stepId >= 1 })}
              style={activatedStepBg[1]}
            />
            <div className={styles.stepText}>Account</div>
          </div>
          <div className={styles.stepContainer}>
            <div
              className={clsx(styles.stepCircle, { [styles.stepCircleActive]: stepId >= 2 })}
              style={activatedStepBg[2]}
            />
            <div className={styles.stepText}>Personal</div>
          </div>
          <div className={styles.stepContainer}>
            <div
              className={clsx(styles.stepCircle, { [styles.stepCircleActive]: stepId >= 3 })}
              style={activatedStepBg[3]}
            />
            <div className={styles.stepText}>Payment</div>
          </div>
          <div className={styles.stepContainer}>
            <div
              className={clsx(styles.stepCircle, styles.stepCircleLast, { [styles.stepCircleActive]: stepId >= 4 })}
              style={activatedStepBg[4]}
            />
            <div className={styles.stepText}>Success</div>
          </div>
        </div>
      </div>
      <div className={styles.contentBlock}>{children}</div>
      <div className={styles.linksBlock}>
        Already a member?{' '}
        <Link to="/user/login" className={styles.link}>
          Login
        </Link>
        <div className={styles.policyBlock}>
          <a href="https://arcticrich.com/privacypolicy.html" className={styles.policyLink}>
            Privacy
          </a>{' '}
          |{' '}
          <a href="https://arcticrich.com/termsofuse.html" className={styles.policyLink}>
            Terms
          </a>
        </div>
      </div>
    </div>
  );
};
