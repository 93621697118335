import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';

import accountReducer from '../features/account/accountSlice';
import authReducer from '../features/auth/authSlice';
import billReducer from '../features/bill/billSlice';
import creditScoreReducer from '../features/creditScore/creditScoreSlice';
import debtReducer from '../features/debt/debtSlice';
import goalReducer from '../features/goal/goalSlice';
import autoReducer from '../features/auto/autoSlice';
import homeReducer from '../features/home/homeSlice';
import taxReducer from '../features/tax/taxSlice';
import incomeReducer from '../features/income/incomeSlice';
import savingReducer from '../features/saving/savingSlice';
import insuranceReducer from '../features/insurance/insuranceSlice';
import investmentReducer from '../features/investment/investmentSlice';
import transactionReducer from '../features/transaction/transactionSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import overviewReducer from '../features/overview/overviewSlice';
import alertsReducer from '../features/commCenter/alertSlice';
import questionsReducer from '../features/invenstmentProfile/questionsSlice';

import { authMiddleware } from './authMiddleware';

const middleware = [
  ...getDefaultMiddleware({ serializableCheck: false }),
  authMiddleware,
  promise,
  createLogger({
    collapsed: true,
  }),
];

export const store = configureStore({
  reducer: {
    account: accountReducer,
    auth: authReducer,
    bill: billReducer,
    creditScore: creditScoreReducer,
    debt: debtReducer,
    goal: goalReducer,
    income: incomeReducer,
    saving: savingReducer,
    insurance: insuranceReducer,
    investment: investmentReducer,
    transaction: transactionReducer,
    auto: autoReducer,
    home: homeReducer,
    tax: taxReducer,
    calendar: calendarReducer,
    overview: overviewReducer,
    alerts: alertsReducer,
    questions: questionsReducer,
  },
  middleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
