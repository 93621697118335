import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TaxablevsNonTaxableIncome: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Taxable vs. Non-Taxable Income</h1>

        <p className={styles.bodyText}>
          All the money you earn can be divided into two types of income. Taxable income and non-taxable income.
        </p>

        <h3 className={styles.paraHeading}>What is Taxable Income</h3>

        <p className={styles.bodyText}>
          You don’t have to pay taxes on every dollar you earn. Your government decides the type of your earnings you
          would need to pay taxes.
        </p>

        <p className={styles.bodyText}>
          The earnings on which your government asks you to pay taxes are called taxable Income.
        </p>

        <p className={styles.bodyText}>
          The total amount of taxes you pay are calculated based on taxable income only. Most of the income are taxable.
          Taxable income includes not just one’s salary but also compensation in other forms, such as tips, bonuses,
          allowances, commissions, and capital gains.
        </p>

        <h3 className={styles.paraHeading}>What is Non-Taxable Income</h3>

        <p className={styles.bodyText}>
          The earnings on which you don’t have to pay any taxes to the government is called non-taxable income.
        </p>

        <p className={styles.bodyText}>
          Governments decide not to impose tax many earnings for achieving many of its greater purposes, such as helping
          the disabled or families of deceased persons, support childcare, education or training, encourage saving,
          investment, or home ownership, protect environment, promote technology or innovation etc.
        </p>

        <h3 className={styles.paraHeading}>What is Gross Income</h3>

        <p className={styles.bodyText}>
          Gross income is a cool name of total income. Gross income consists of taxable income and non-taxable income.
        </p>

        <p className={styles.bodyText}>Gross Income or Total Income = Taxable Income + Non-Taxable Income.</p>

        <p className={clsx(styles.bodyText, styles.last)}>
          To calculate your taxable income, you would need to deduct non-taxable income from your gross or total income.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/08/2022'} />
      </div>

      <FeedbackBox source="/income/key-terms/text/short/08-jun-2022/01" />
    </>
  );
};
