import { yupResolver } from '@hookform/resolvers';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import styles from './PublicBillInfo.module.scss';
import { Form, Input, SubmitButton } from '../../components/Form';

import { useLocation } from 'react-router-dom';
import { getBillByToken, updateBillByToken } from './billSlice';
import { BadRequestError } from '../../helpers/api';

type Bill = {
  id: string;
  sector: string;
};

export type BillFormInputs = {
  amount: number;
};

const schemaResolver = yupResolver<BillFormInputs>(
  yup.object().shape({
    amount: yup.number().min(0).typeError('Please enter a number').required('Please enter an amount'),
  }),
);

export const PublicBillInfo: React.FC = () => {
  const { search } = useLocation();

  const [bill, setBill] = useState<Bill | null>(null);
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<string>();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    const getBill = async (token: string) => {
      setBill((await getBillByToken(token)).bill);
      setToken(token);
    };

    const params = new URLSearchParams(search);
    const t = params.get('token');
    t && getBill(t);
  }, [search]);

  const onSubmit = async (data: BillFormInputs) => {
    try {
      await updateBillByToken({ token: token ?? '', ...data });
      setMessage('Bill updated successfully');
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
        setError(err.response + '');
      } else {
        console.log(err);
      }
    }
  };

  return (
    <Container fluid className="px-0 mt-3">
      {bill ? (
        <React.Fragment>
          <div className={styles.header}>
            <div className={styles.headerTitleLeft}>Edit</div>
            <div className={styles.headerTitleDelimiter} />
            <div className={styles.headerTitleRight}>{bill.sector}</div>
          </div>
          <div className={styles.headerGradient} />
          <div className={styles.container}>
            <React.Fragment>{error ? <div className="alert alert-danger my-2">{error}</div> : null}</React.Fragment>
            <React.Fragment>
              {message ? <div className="alert alert-success my-2">{message}</div> : null}
            </React.Fragment>
            <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{}}>
              <Input name="amount" placeholder="Amount" label="Amount" prefix="$" />
              <SubmitButton value="Submit" type="submit" />
            </Form>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="alert alert-danger my-2">No billing information found</div>
        </React.Fragment>
      )}
    </Container>
  );
};
