import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HomeBuyersPlan: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Home Buyers&apos; Plan (HBP)</h1>

        <p className={styles.bodyText}>
          The Home Buyers&apos; Plan (HBP) has been designed to help home buyers to fund their home.The plan let&apos;s
          Registered Retirement Savings Plan (RRSP) account holders to withdraw funds from their RRSP Accounts to buy or
          build a qualifying home for them or for their related person with a disability.
        </p>

        <h3 className={styles.paraHeading}>Limit</h3>

        <p className={styles.bodyText}>
          Maximum of $35,000 can be withdrawn from the RRSP account without paying any taxes under the Home Buyers’
          Plan. Withdrawals can be made from a single or more than one RRSP account of the same RRSP account owner.
        </p>

        <h3 className={styles.paraHeading}>Conditions</h3>

        <p className={styles.bodyText}>Following conditions must be met to be qualify. You must</p>

        <ul className={styles.list}>
          <li>Be a first-time home buyer</li>
          <li>Have a written agreement to buy or build the home</li>
          <li>Be a resident of Canada while withdrawing funds from RRSPs and until the home is bought or built</li>
          <li>
            Must have the intent to occupy the home as principal residence within one year after buying or building it.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Repeat Participation</h3>

        <p className={styles.bodyText}>
          Anyone can participate in a Home Buyer Plan (HBP), even if they have previously participated in this Plan,
          provided the previously drawn amount has been fully repaid as of Jan 01 of that Year.
        </p>

        <h3 className={styles.paraHeading}>Withdrawal</h3>

        <p className={styles.bodyText}>
          To withdraw funds from your RRSPs under the Home Buyers&apos; Plan (HBP), fill out Form T1036, Home
          Buyers&apos; Plan (HBP) Request to Withdraw Funds from an RRSP. You have to fill out this form for each
          withdrawal you make.
        </p>

        <p className={styles.bodyText}>
          You can withdraw a single amount or make a series of withdrawals in the same calendar year. Some RRSPs, such
          as locked-in or group RRSPs, do not allow you to withdraw funds from them.
        </p>

        <h3 className={styles.paraHeading}>Repayments</h3>

        <p className={styles.bodyText}>
          Amounts withdrawn under Home Buyer’s Plan from RRSP must be repaid within 15 years from the date of
          withdrawal.
        </p>

        <p className={styles.bodyText}>
          Repayment starts from the second year after withdrawal. Example, if the funds were withdrawn in 2022, then the
          repayments will begin from 2024.
        </p>

        <h3 className={styles.noteHeading}>Note</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          RRSP could also mean Pooled Registered Pension Plan (PRPP) or Specified Pension Plan (SPP).
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/03/2021'} />
      </div>

      <FeedbackBox source="/home/the-basics/text/short/03-may-2022/01" />
    </>
  );
};
