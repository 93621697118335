import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const TypesofETFAdvanced: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Types of ETF - Advanced</h1>

        <p className={styles.bodyText}>
          There are few additional types of ETFs, which are relatively more complex and we recommend these only for more
          investors with more advanced knowledge. We don’t recommend these for beginners.
        </p>

        <h2 className={clsx(styles.paraHeading)}>1. Alternative Investment ETFs</h2>

        <p className={styles.bodyText}>
          These types of ETFs offer access to investment styles generally adopted by Hedge Funds like long or short
          strategies or currency hedging.
        </p>

        <h2 className={clsx(styles.paraHeading)}>2. Leveraged and Inverse ETFs</h2>

        <p className={styles.bodyText}>
          These ETFs are generally traded daily and enable people to boost returns of an underlying index or provide the
          inverse of the benchmark they follow, by using leverage.
        </p>
        <p className={styles.bodyText}>
          Some ETFs of this category are the top performers. But these lucrative returns are not without risk. If the
          stocks or other assets tracked by the fund happens to crash, significant loss may occur.
        </p>

        <h2 className={clsx(styles.paraHeading)}>3. Exchange Traded Notes</h2>
        <p className={styles.bodyText}>
          Exchange Traded Notes (ETNs) are very much like Exchange Traded Funds (ETFs), trading continuously throughout
          a session. However, unlike ETFs the Exchange Traded Notes do not own the underlying stocks or other assets.
          They are indeed debt securities guaranteed (underwritten) by the banks.
        </p>

        <h2 className={clsx(styles.paraHeading)}>4. Actively Managed ETFs</h2>
        <p className={clsx(styles.bodyText, styles.last)}>
          Actively Managed ETFs combine the best of both, low cost and tax benefits of ETFs with active stock picking
          feature of Mutual Funds. Transparency remains an issue with these type of ETFs since the Fund Managers often
          do not update their holdings daily to avoid making their game plan public. More recently many Actively Managed
          ETFs allow timely and full discloser including in the Bond or Fixed Income space.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/01/2022'} />
      </div>

      <FeedbackBox source="/investment/etf/text/short/01-mar-2022/02" />
    </>
  );
};
