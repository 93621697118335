import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HomeBuyersAmount: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Home Buyer’s Amount</h1>

        <h3 className={styles.paraHeading}>How Much You Can Claim</h3>

        <p className={styles.bodyText}>You can claim up to $5,000 for the purchase of a qualifying home.</p>

        <h3 className={styles.paraHeading}>Conditions</h3>

        <ol className={styles.list}>
          <li>You or your spouse acquired a qualifying home.</li>
          <li>
            You did not live in another home owned by you or your spouse in the year of acquisition or in any of the
            four preceding years.
          </li>
          <li>You must live in the home as your principal residence within one year of acquisition.</li>
        </ol>

        <h3 className={styles.paraHeading}>What is Qualifying Home</h3>
        <p className={styles.bodyText}>A qualifying home must be:</p>
        <ol className={styles.list}>
          <li>Registered in your or your spouse&apos;s name</li>
          <li>Located in Canada.</li>
        </ol>

        <p className={styles.bodyText}>It includes existing homes and homes under construction.</p>
        <p className={styles.bodyText}>The following are considered qualifying homes:</p>

        <ul className={styles.list}>
          <li>Single-family houses</li>
          <li>Semi-detached houses</li>
          <li>Townhouses</li>
          <li>Mobile homes</li>
          <li>Condominium units</li>
          <li>Apartments in duplexes, triplexes, fourplexes, or apartment buildings</li>
        </ul>

        <h3 className={styles.noteHeading}>Note on Co-operative Housing:</h3>

        <ol className={styles.list} type="a">
          <li>
            A share in a co-operative housing corporation that entitles you to own and gives you an equity interest in a
            housing unit qualifies.
          </li>
          <li>
            A share that only gives you the right to tenancy in the housing unit does not qualify.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'04/08/2022'} />
      </div>

      <FeedbackBox source="/home/the-basics/text/short/08-apr-2022/03" />
    </>
  );
};
