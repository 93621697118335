import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhatIsBond: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is Bond</h1>

        <p className={styles.bodyText}>Bond is an investment instrument and a way to invest for any individual.</p>

        <p className={styles.bodyText}>
          Bonds are generally issued by a federal or provincial government, municipality, or company to borrow money
          from the market.
        </p>

        <p className={styles.bodyText}>
          When you buy a bond, you are essentially lending your money to that entity who has issued the bond. Like any
          lender, you are entitled to earn interests at a predetermined rate on regular intervals and get back the money
          you originally lent in full when the bond becomes due.
        </p>

        <p className={styles.bodyText}>
          Bond is also called Fixed Income since your interest earnings from the bonds are predetermined and remains
          fixed during the entire life of the bond.
        </p>

        <h2 className={styles.paraHeading}>Key Terms in Bond</h2>

        <ul className={styles.list}>
          <li>
            <strong>Maturity: </strong>The date on which the bond becomes due for repayment and lender receives the
            money in full from the borrower or issuer of the bond.
          </li>
          <li>
            <strong>Face Value: </strong>Amount the bond will worth when it becomes due.
          </li>
          <li>
            <strong>Coupon: </strong>The rate of interest which borrower or issuer of the bond agrees to pay the lender
            or bondholder.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/09/2022'} />
      </div>

      <FeedbackBox source="/investment/bond/text/short/9-mar-2022/04" />
    </>
  );
};
