import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const BuyNowPayLater: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Buy Now | Pay Later</h1>

        <p className={styles.bodyText}>
          From 2022 the leading credit rating agencies, Equifax and TransUnion, are expected to report “Buy Now, Pay
          Later” plans subscribed by the consumers in their respective credit reports.
        </p>
        <p className={styles.bodyText}>
          &ldquo;Buy Now, Pay Later&rdquo; is a new type of payment plan that lets the shoppers pay in four biweekly
          instalments rather than the full price while checking out. These plans are particularly popular to those with
          limited credit history and do not generally qualify for credit cards or other form of conventional credit
          facilities.
        </p>

        <p className={styles.bodyText}>
          The payment plans are not large (average transaction value is around $150) but may add up quickly, if
          consumers tend to use this feature frequently. In recent years, this type of payment plans, commonly known as
          &ldquo;pay-in-4&rdquo; plans, have become quite popular.
        </p>
        <p className={styles.bodyText}>
          The plan allows buyers to split the purchase price into four equal payments and charged directly to their
          credit or debit card. For instance, $100 purchase would need only $25 payment at the time of purchase and
          balance in three $25 instalments charged every two weeks.
        </p>
        <p className={styles.bodyText}>
          Two largest players in this business are Afterpay Ltd. and Klarna Bank AB. Afterpay processed $9.8 billion of
          transactions during the year ended on June 30, 2021, and Klarna proceeded $3.2 billion transactions during Jan
          to June 2021 in North America.
        </p>
        <p className={styles.bodyText}>
          Both high-end and discount retailers offer the choice of this payment plan at their online checkouts. It is
          also becoming increasingly available at the stores.
        </p>
        <p className={styles.bodyText}>
          Except for few large ticket items, most of these plans are not currently reflected in the credit reports. This
          is creating a challenge for the lenders since current credit reports are not displaying the actual payment
          obligations of the credit applicant.
        </p>
        <p className={styles.bodyText}>The following details will be included in Credit report:</p>

        <ul className={styles.list}>
          <li>Date of opening the plan.</li>
          <li>Scheduled payments.</li>
          <li>Date of actual payments.</li>
        </ul>
        <p className={clsx(styles.bodyText, styles.last)}>
          Frequent opening and closing of these &ldquo;pay-in-4&rdquo; plans are likely to hurt the credit score
          although the providers like Afterpay and Klarna do not want their clients to be penalized for using their
          services too often.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'12/22/2021'} />
      </div>

      <FeedbackBox source="/creditscore/whatsnew/text/short/22-dec-2021/01" />
    </>
  );
};
