import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useHistory, Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { Form, Input, SubmitButton } from '../../components/Form';
import { PasswordResetFormInputs } from './types';
import { executePasswordReset, BadRequestError } from '../../helpers/api';

import styles from './PasswordReset.module.scss';

const schemaResolver = yupResolver<PasswordResetFormInputs>(
  yup.object().shape({
    password: yup.string().required('Please enter Password').min(8, 'Password should be at least 8 symbols'),
    password_confirmation: yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Both passwords need to be the same'),
    }),
  }),
);

export const PasswordReset: React.FC = () => {
  const [formError, setFormError] = useState<string>('');
  const history = useHistory();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const token = params.get('token');

  const onSubmit = async (data: PasswordResetFormInputs) => {
    try {
      await executePasswordReset(data, token);
      history.push('/user/reset-password-confirmation');
    } catch (err) {
      if (err instanceof BadRequestError) {
        if (err.response.status) {
          setFormError('Token does not exist');
        } else if (err.response.password && Array.isArray(err.response.password)) {
          setFormError(err.response.password[0]);
        } else {
          setFormError('Unknown error occurred');
        }
        console.log(err.response);
      } else {
        setFormError('Unknown error occurred');
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Container fluid className="px-0 position-relative">
      <h1 className={styles.title}>Reset your Arctic Rich password</h1>

      <React.Fragment>
        {formError.length ? <div className={styles.formErrorBlock + ' invalid-feedback'}>{formError}</div> : null}
      </React.Fragment>
      <Form onSubmit={onSubmit} resolver={schemaResolver}>
        <Input
          type="password"
          name="password"
          placeholder="Create Password"
          className={styles.inputBlock}
          labelClassName={styles.label}
        />
        <Input
          type="password"
          name="password_confirmation"
          placeholder="Confirm Password"
          className={styles.inputBlock}
          labelClassName={styles.label}
        />
        <SubmitButton value="Continue" className={styles.submitButtonBlock} type="submit" />
        <div className={styles.linksBlock}>
          Don’t have an account?{' '}
          <Link to="/user/sign-up/account-info" className={styles.link}>
            Sign Up
          </Link>
        </div>
      </Form>
    </Container>
  );
};
