import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const BonusForSalariedEmployees: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Bonus for Salaried Employees</h1>

        <p className={styles.bodyText}>
          Bonus is generally a lump sum amount received by an employee as part of compensation, it can be periodical or
          yearly depending upon the employers’ policy.
        </p>

        <p className={styles.bodyText}>
          As the bonus amount is lumpsum and withholding tax is deducted by the employer while making payments to the
          employees, this is a massive tax burden borne by the employees.
        </p>

        <h3 className={styles.paraHeading}>Avoid Tax on Your Bonus</h3>

        <p className={styles.bodyText}>
          The best option is to deposit/contribute the bonus to your Registered Retirement Savings Plan (RRSP).
        </p>

        <h3 className={styles.paraHeading}>What is RRSP</h3>

        <p className={styles.bodyText}>
          RRSP is a retirement saving plan registered with Government of Canada, where you or your spouse (including
          common-law partner) can contribute. Such contributions reduce you’re the amount of tax you will need to pay.
        </p>

        <p className={styles.bodyText}>
          In addition, you don’t have to pay taxes on any income earned on the investments of your RRSP account as long
          as it stays within the RRSP account.
        </p>

        <h3 className={styles.paraHeading}>How to Save on Tax</h3>

        <p className={styles.bodyText}>
          You can contribute/deposit the net of taxes bonus amount to RRSP. Then you can deduct this contribution amount
          from your taxable income, which will lower the tax liability for that particular year. You will receive
          benefit in form of either refund of tax you already paid in advance or reduced tax liability after filing your
          income tax return.
        </p>

        <h3 className={styles.paraHeading}>Save More</h3>

        <p className={styles.bodyText}>
          If you request your employer in advance to contribute/deposit the entire bonus amount directly into your or
          your spouse’s RRSP account, then there will be no requirement to deduct withholding taxes on this contribution
          by your employer.
        </p>

        <p className={styles.bodyText}>
          By adopting this simple way, you can maximise the benefit from RRSP as your full gross bonus amount will be
          invested instead of net of taxes amount. This single strategy alone will significantly grow your investments
          at a much faster pace compared to the bonuses received net of taxes amount.
        </p>

        <p className={styles.bodyText}>
          Continuing this practice over the years would certainly make a meaningful difference in your wealth.
        </p>

        <h3 className={styles.paraHeading}>Caution</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          Ensure that you have more RRSP contribution limit available than your gross bonus amount during the year.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/22/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expensesII/text/short/22-mar-2022/01" />
    </>
  );
};
