import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AdoptionDependenciesElectricVehicles: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Adoption Dependencies</h1>
        <h1 className={styles.subHeading}>Electric Vehicles</h1>

        <p className={styles.bodyText}>
          Wide adoption of electric vehicles (EVs) in Canada would largely depend on the coordination among the main
          stakeholders within the value chain:
        </p>

        <h3 className={styles.paraHeading}>Car Manufacturers</h3>
        <p className={styles.bodyText}>
          At first, car manufacturers have to produce a wide range of car models and types of electric vehicles (EVs) at
          different price points, so that all segments of the market can be adequately served.
        </p>

        <h3 className={styles.paraHeading}>Governments</h3>
        <p className={styles.bodyText}>
          Government commitment is required to allocate adequate funds to encourage investments in the infrastructure
          like charging stations and offering instant cash incentives for consumer vehicle purchases.
        </p>

        <h3 className={styles.paraHeading}>Utilities</h3>
        <p className={styles.bodyText}>
          Canadian households would clearly consume a lot more electricity than they are doing today to power their
          electric vehicles (EVs) at home. For the increased pace of electricity distribution, significant investment
          would be needed in the grid infrastructure.
        </p>
        <p className={styles.bodyText}>
          Adoption of solar power by the households would also need the infrastructure for the two-way energy flow.
        </p>

        <h3 className={styles.paraHeading}>Service Stations</h3>
        <p className={styles.bodyText}>
          Charging Station Operators would need to device an operating procedure which is standardized, efficient and
          user friendly. Pricing and membership plans also have to be transparent and easy to understand by the mass
          market.
        </p>

        <h3 className={styles.paraHeading}>Miners</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          Canada has a large deposit of minerals needed to produce the electric vehicle’s batteries. Significant
          investment would be required in the mining sector to make the best use of this opportunity.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/12/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-II/text/short/12-jul-2022/03" />
    </>
  );
};
