import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import DebtUtilizationRatioImg from '../common/images/DebtUtilizationRatio.png';
export const DebtUtilizationRatio: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Debt Utilization Ratio</h1>

        <h3 className={styles.paraHeading}>What is Debt Utilization Ratio</h3>
        <p className={styles.bodyText}>
          A Debt Utilization Ratio indicates how much you are using of the total debt limit available to you.
        </p>
        <p className={styles.bodyText}>
          The formula for the Debt Utilization Ratio is the current balances of all your debts, divided by the total
          amount of debt limit available to you. Multiplied by 100 because, it is expressed as a percent.
        </p>

        <div className={styles.imgContainer}>
          <img src={DebtUtilizationRatioImg} alt="" width="500" />
        </div>

        <p className={styles.bodyText}>
          For example, you have a total of $1,000 in credit available on one credit card and one line of credit.
          Currently you have $100 balance on your credit card and $200 balance your line of credit. Your debt
          utilization rate is $300 ($100 + $200) divided by $1,000 times 100 or 30%.
        </p>

        <p className={styles.bodyText}>
          Debt Utilization Ratio is a point in time ratio, which will vary from time to time depending on your total
          debt balance and debt limit at that particular point in time.
        </p>

        <p className={styles.bodyText}>
          Debt Utilization Ratio can also be calculated for each facility separately like credit card and line of credit
          in the above example.
        </p>

        <h3 className={styles.paraHeading}>Acceptable Debt Utilization Ratio </h3>
        <ul className={styles.list}>
          <li>Lenders prefer Debt Utilization Ratio below 30%.</li>
          <li>
            Accordingly, Credit Agencies track the Debt Utilization Ratio based on the 30% Debt Utilization Ratio
            benchmark.{' '}
          </li>
          <li>
            For example, if your total debt limit is $1,000, then your total debt balance should ideally be below $300.{' '}
          </li>
          <li>A low Debt Utilization Ratio indicates you are responsibly managing your debt. </li>
          <li>
            A high Debt Utilization Ratio could be a red flag to the potential lenders. It indicates you are likely
            having financial difficulty and looking to borrow more for funding your expenses.{' '}
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Managing Debt Utilization Ratio </h3>
        <ul className={styles.list}>
          <li>
            The best way to keep your Debt Utilization Ratio low would be to pay off your debt balances from your
            earnings as soon as you can.{' '}
          </li>
          <li>
            Another way to lower your ratio is by increasing your credit limit. However, this method has to be adopted
            with utmost caution since higher available limit may trigger more spending and resulting in higher debt
            balance.{' '}
          </li>
          <li>
            Since all the debt and credit facilities are taken into account into the Debt Utilization Ratio calculation,
            the debt and credit facilities that you don&apos;t use could contribute to improve your ratio.{' '}
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Conclusion</h3>
        <p className={styles.bodyText}>
          Debt Utilization Ratio is one of the most important ratios to watch. It carries a major weight in calculating
          credit score and approving new debt.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          It should be monitored regularly although you may not in the market for a large debt facility like mortgage or
          car loan but simply to ensure your sound financial health.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/30/2022'} />
      </div>

      <FeedbackBox source="/debt/debt-ratios/text/short/30-jun-2022/02" />
    </>
  );
};
