import React, { useEffect } from 'react';

import successSvg from '../../common/images/success.svg';

import styles from './SignUpSteps.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

export const SignUpStep4: React.FC = () => {
  useEffect(() => {
    /* tslint:disable */
    if (window['fbq']) window['fbq']('track', 'PageView');
  }, []);

  return (
    <div className={styles.step4}>
      <img src={successSvg} className={styles.image} alt="success" width="141" height="27" />
      <h1 className={styles.thankYou}>Thank you for signing up</h1>
      <div className={styles.separator} />
      <div className={styles.description}>
        We will contact you <br /> with next steps
      </div>
    </div>
  );
};
