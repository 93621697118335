import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { BarChart } from './BarChart';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';

export const Stocks: React.FC = () => {
  const data = [
    {
      Year: 'Year 10',
      'Investment Value': 222,
      color: 'rgb(84,129,176)',
    },
    {
      Year: 'Year 20',
      'Investment Value': 854,
      color: 'rgb(84,129,176)',
    },
    {
      Year: 'Year 30',
      'Investment Value': 2646,
      color: 'rgb(84,129,176)',
    },
    {
      Year: 'Year 40',
      'Investment Value': 7728,
      color: 'rgb(84,129,176)',
    },
  ];

  const columns = [
    {
      dataField: 'marketIndex',
      text: 'Market Index',
    },
    {
      dataField: 'country',
      text: 'Country',
    },
    {
      dataField: 'year',
      text: 'Year of Inception',
    },
    {
      dataField: 'stocks',
      text: 'Number of Stock',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Stocks</h1>

        <p className={styles.bodyText}>
          Investing in Stock Market would generate similar, if not better, returns of a Bond or Fixed Income Market for
          any average person. Despite all the day to day noises, complexities, and volatilities there is an astonishing
          consistency pattern in the stock market over the long run.
        </p>

        <p className={styles.bodyText}>
          Although many aspects of the share market are highly technical, surprisingly anyone can harvest its full
          benefits without understanding any of these technical details.
        </p>

        <h3 className={styles.paraHeading}>What is a Stock</h3>

        <p className={styles.bodyText}>Stock is a piece of ownership of a company. Also known as share.</p>

        <p className={styles.bodyText}>
          When you buy a stock of Apple, you become an owner of Apple and entitled to the profit of the company in
          proportion to your ownership.
        </p>

        <h3 className={styles.paraHeading}>Investment Strategy</h3>

        <h3 className={styles.paraHeading}>1. Diversify</h3>

        <p className={styles.bodyText}>Diversify simply means don’t keep all your eggs in one basket.</p>
        <p className={styles.bodyText}>
          Buying shares of only one company is never a good idea. Any single company can’t persistently be an
          outstanding performer or a leader in their sector forever. In contract, buying a little bit of stocks in many
          companies would help you to spread the risk.
        </p>
        <p className={styles.bodyText}>
          Some of today’s top performing companies will continue to do the best while many newer companies will emerge
          and take the lead. Since you own a piece of ownership in every company representing the market, you will
          continue to benefit, no matter who the winner or leader is in the long run.
        </p>

        <h3 className={styles.paraHeading}>2. Buy and Hold</h3>

        <p className={styles.bodyText}>
          Those who buy and hold on to the shares reap much better returns than those who try to make profits by buying
          and selling shares in short intervals. Buying shares and holding forever is one of the most proven winning
          strategies.
        </p>

        <h3 className={styles.paraHeading}>What is Market Index</h3>

        <p className={styles.bodyText}>
          A Market Index is an imaginary mix of a small number of company’s stocks, bonds or other investments, which
          represents performance the overall market or a particular industry or sector like the tech stocks.
        </p>

        <h3 className={styles.paraHeading}>Market Indexes</h3>

        <p className={styles.bodyText}>
          For our purposes, we will be focusing on the overall Stock Market Indexes as we intend to diversify and spread
          our risk as much as possible. These are the top market indexes in USA, Canada and UK, which follow broad
          market behaviour:
        </p>

        <SimpleTable
          keyField="marketIndexes"
          data={[
            {
              marketIndex: 'S&P 500',
              country: 'United States',
              year: '1957',
              stocks: '500',
            },
            {
              marketIndex: 'DJIA',
              country: 'United States',
              year: '1896',
              stocks: '30',
            },
            {
              marketIndex: 'NASDAQ',
              country: 'United States',
              year: '1971',
              stocks: '3,097',
            },
            {
              marketIndex: 'TSX Composite',
              country: 'Canada',
              year: '1977',
              stocks: '300',
            },
            {
              marketIndex: 'FTSE 100',
              country: 'United Kingdom',
              year: '1984',
              stocks: '100',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h3 className={styles.paraHeading}>Investing in Stocks</h3>

        <p className={styles.bodyText}>
          The more time you have to invest in the stock market, the less you will be exposed to volatility of the market
          and better will be your chances of getting higher and stable returns.
        </p>

        <p className={styles.bodyText}>
          Refer to the following example. The investment value continues to grow to $7.7 million over 40 years at 10.99%
          average annual Return based on 30 Year Average Annual Return of Dow Jones Industrial Average Index and{' '}
          <strong>$1,000</strong> monthly contribution.
        </p>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>Estimated Investment Value</h4>
          <h5 className={styles.chartSubHeader}>Figures in Thousand Dollars</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={data}
              keys={['Investment Value']}
              indexBy={'Year'}
              axisLeftLegendTitle={'Investment Value'}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 50, left: 70 },
                axisLeft: {
                  format: ',',
                },
                labelFormat: ',',
                tooltipFormat: ',',
                legends: [],
                padding: 0.6,
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
              }}
            />
          </div>
        </div>
        <p className={styles.chartSource}>Source: Dow Jones Industrial Average</p>

        <p className={clsx(styles.bodyText, styles.last)}>
          It is important to note the highest amount of gain in the investment value was accumulated in last the 10
          years.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/22/2022'} />
      </div>

      <FeedbackBox source="/overview/know-your-allies/text/short/22-mar-2022/01" />
    </>
  );
};
