import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const NetfileAdvantages: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Advantages of NETFILE:</h1>

        <ul className={styles.list}>
          <li>
            <strong>More Accurate: </strong>Tax Application is filed Electronically. Errors are more easily identified
            by the Software compared to the paper-based applications.
          </li>
          <li>
            <strong>Immediate Confirmation: </strong>Tax returns are filed directly to Canada Revenue Agency. CRA
            receives and sends confirmation instantly.
          </li>
          <li>
            <strong>No Supporting Papers: </strong>Unlike the paper-based applications, no supporting documents or
            receipts are required to be submitted with the application. These documents may be requested at a later date
            by CRA.
          </li>
          <li>
            <strong>Faster Refund: </strong>CRA can process the refund much faster when the Tax Return Application is
            submitted through NETFILE. Fund is deposited in the applicant&apos;s designated account through Direct
            Deposit System usually within 2 Weeks.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>
        <PublishedDate date={'11/08/2021'} />
      </div>

      <FeedbackBox source="/tax/electronicfiling/text/short/08-nov-2021/01" />
    </>
  );
};
