import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { OverViewLearnValue } from './OverViewLearnValue';
import { WorkingForMoney } from './WorkingForMoney';
import { LearningProcess } from './LearningProcess';
import { WeeklySaving } from './WeeklySaving';
import { StartSmall } from './StartSmall';
import { Focus } from './Focus';
import { InformationKnowledge } from './InformationKnowledge';
import { AmberLights } from './AmberLights';
import { DebtManagement } from './DebtManagement';
import { GoodChoice } from './GoodChoice';
import { FiveMinuteRule } from './FiveMinuteRule';
import { PuttingOffThings } from './PuttingOffThings';
import { SettingGoal } from './SettingGoal';
import { DontHaveTime } from './DontHaveTime';
import { WeeklyReview } from './WeeklyReview';
import { HelpBox } from './HelpBox';
import { WhoUseCreditScore } from './WhoUseCreditScore';
import { MeaningCreditScore } from './MeaningCreditScore';
import { LongRecordStays } from './LongRecordStays';
import { WhatIsCreditScore } from './WhatIsCreditScore';
import { BillingMembershipFee } from './BillingMembershipFee';
import { ContactSupport } from './ContactSupport';
import { Donts } from './Donts';
import { AccountAccess } from './AccountAccess';
import { ChildCareExpenses } from './ChildCareExpenses';
import { PasswordGuideline } from './PasswordGuideline';
import { PhoneFamily } from './PhoneFamily';
import { PopularSmartPhones } from './PopularSmartPhones';
import { WhenToAdopt5G } from './WhenToAdopt5G';
import { WhyCalendar } from './WhyCalendar';
import { CreditScoreVsCreditReport } from './CreditScoreVsCreditReport';
import { PhoneCallAlternatives } from './PhoneCallAlternatives';
import { WinterTires } from './WinterTires';
import { CalculatingCreditScore } from './CalculatingCreditScore';
import { HowOftenUpdated } from './HowOftenUpdated';
import { TireExpiry } from './TireExpiry';
import { TireTreadDepth } from './TireTreadDepth';
import { KnowledgePower } from './KnowledgePower';
import { TireSpeedRating } from './TireSpeedRating';
import { AssetAllocation } from './AssetAllocation';
import { AssetAllocationMid20 } from './AssetAllocationMid20';
import { AssetAllocationMid30 } from './AssetAllocationMid30';
import { AssetAllocationMid50 } from './AssetAllocationMid50';
import { AssetAllocationMid60 } from './AssetAllocationMid60';
import { Inflation } from './Inflation';
import { IncomeDistribution } from './IncomeDistribution';

import { Page1 } from './Page1';
import { Page0001 } from './Page0001';
import { Page0002 } from './Page0002';
import { Page0003 } from './Page0003';
import { Page0004 } from './Page0004';
import { Page0005 } from './Page0005';
import { Page0006 } from './Page0006';
import { Page0007 } from './Page0007';
import { Page0008 } from './Page0008';
import { Page0009 } from './Page0009';
import { Page0010 } from './Page0010';
import { Page0011 } from './Page0011';
import { Netfile } from './Netfile';
import { MakeItFailProof } from './MakeItFailProof';
import { TminusFive } from './TminusFive';
import { WriteItGoal } from './WriteItGoal';
import { MilestonesGoal } from './MilestonesGoal';
import { NetfileAdvantages } from './NetfileAdvantages';
import { NetfileRestrications } from './NetfileRestrictions';
import { NetfileServiceHours } from './NetfileServiceHours';
import { TopTenManufacturers } from './TopTenManufacturers';
import { CdmaVsGsmVsLte } from './CdmaVsGsmVsLte';
import { BuyNowPayLater } from './BuyNowPayLater';
import { TaxRates } from './TaxRates';
import { TaxCredit } from './TaxCredit';
import { SavingAndInvestment } from './SavingAndInvestment';
import { WhatNewIn2021TaxOther } from './WhatNewIn2021TaxOther';
import { PayYourselfFirst } from './PayYourselfFirst';
import { CreditScoreVsInterestRate } from './CreditScoreVsInterestRate';
import { DeductionOfVehicleExpenses } from './DeductionOfVehicleExpenses';
import { InterestOnStudentLoan } from './InterestOnStudentLoan';
import { TuitionAndTrainingFees } from './TuitionAndTrainingFees';
import { ProfessionalUnionDues } from './ProfessionalUnionDues';
import { RelocationExpenses } from './RelocationExpenses';
import { AccountingAndLegalFees } from './AccountingAndLegalFees';
import { HowManyBankAccounts } from './HowManyBankAccounts';
import { ETF } from './ETF';
import { EnvelopMethod } from './EnvelopMethod';
import { WhyETF } from './WhyETF';
import { TypesOfETF } from './TypesOfETF';
import { ETFInMyPortfolio } from './ETFInMyPortfolio';
import { ProtectYourDeposit } from './ProtectYourDeposit';
import { CustomerCareSatisfaction } from './CustomerCareSatisfaction';
import { Plan_20_30_50 } from './Plan_20_30_50';
import { Saving20 } from './Saving20';
import { Wants30 } from './Wants30';
import { MustHave50 } from './MustHave50';
import { Income } from './Income';
import { SuppliesExpense } from './SuppliesExpense';
import { TravellingExpense } from './TravellingExpense';
import { ParkingExpense } from './ParkingExpense';
import { FourPercRule } from './FourPercRule';
import { TypesofETFAdvanced } from './TypesofETFAdvanced';
import { RetirementSavingsRequired } from './RetirementSavingsRequired';
import { LimitationsCreditScore } from './LimitationsCreditScore';
import { Time } from './Time';
import { WhatIsBond } from './WhatIsBond';
import { RisksOfBonds } from './RisksOfBonds';
import { WhyBond } from './WhyBond';
import { AllBondPortfolio } from './AllBondPortfolio';
import { BondRatings } from './BondRatings';
import { InterestRateRaised } from './InterestRateRaised';
import { TheLatteFactor } from './TheLatteFactor';
import { WhoProvidesCreditScores } from './WhoProvidesCreditScores';
import { StayWithTheBigOne } from './StayWithTheBigOne';
import { WhereToBuyInsurance } from './WhereToBuyInsurance';
import { TopFiveLifeInsurance } from './TopFiveLifeInsurance';
import { TelephoneBanking } from './TelephoneBanking';
import { CashInYourOldPhone } from './CashInYourOldPhone';
import { FilingComplaints } from './FilingComplaints';
import { CompoundInterest } from './CompoundInterest';
import { Stocks } from './Stocks';
import { GrantsSubsidiesRebates } from './GrantsSubsidiesRebates';
import { NonSufficientFund } from './NonSufficientFund';
import { BonusForSalariedEmployees } from './BonusForSalariedEmployees';
import { RealEstate } from './RealEstate';
import { OverdraftProtection } from './OverdraftProtection';
import { AutomateTransactions } from './AutomateTransactions';
import { TypesOfLifeInsurance } from './TypesOfLifeInsurance';
import { MinimumMonthlyPayment } from './MinimumMonthlyPayment';
import { CreditUtilization } from './CreditUtilization';
import { DisposableIncome } from './DisposableIncome';
import { DisposableIncomeTrend } from './DisposableIncomeTrend';
import { InteracETransferAutoDeposit } from './InteracETransferAutoDeposit';
import { TaxDeductionForSelfEmployed } from './TaxDeductionForSelfEmployed';
import { WhenCreditScoreIsUsed } from './WhenCreditScoreIsUsed';
import { DiscretionaryIncome } from './DiscretionaryIncome';
import { CanadaGreenerHomes } from './CanadaGreenerHomes';
import { NewHousingGSTHSTRefunds } from './NewHousingGSTHSTRefunds';
import { HomeBuyersAmount } from './HomeBuyersAmount';
import { ClaimingTaxBenefitsForDependents } from './ClaimingTaxBenefitsForDependents';
import { EmergencyFunds } from './EmergencyFunds';
import { MediumTermInvestments } from './MediumTermInvestments';
import { LongTermInvestment } from './LongTermInvestment';
import { BuyingAHouseCriteria } from './BuyingAHouseCriteria';
import { BenefitsOfOwningAHome } from './BenefitsOfOwningAHome';
import { AddVideo } from './AddVideo';
import { EditDeleteVideo } from './EditDeleteVideo';
import { WhatIsRRSP } from './WhatIsRRSP';
import { BorrowingForRRSPContribution } from './BorrowingForRRSPContribution';
import { RRSPVSTFSA } from './RRSPVSTFSA';
import { RRSPVSTFSA2 } from './RRSPVSTFSA copy';
import { TypesOfSavingsAccounts } from './TypesOfSavingsAccounts';
import { RRSPContributionLimit } from './RRSPContributionLimit';
import { TaxesOnRRSPWithdrawals } from './TaxesOnRRSPWithdrawals';
import { HomeBuyersPlan } from './HomeBuyersPlan';
import { IncomeFromOtherSources } from './IncomeFromOtherSources';
import { EmployerPensionPlans } from './EmployerPensionPlans';
import { SupportVideo } from './SupportVideo';
import { RRSPQualifiedInvestments } from './RRSPQualifiedInvestments';
import { FourPercentRule2022 } from './FourPercentRule2022';
import { FactorsInfluencingPremium } from './FactorsInfluencingPremium';
import { HowToSaveOnCarInsurance } from './HowToSaveOnCarInsurance';
import { TypesOfAutoInsurance } from './TypesOfAutoInsurance';
import { OwnWaterHeater } from './OwnWaterHeater';
import { DisabilityInsurance } from './DisabilityInsurance';
import { ElectricityTimingOfUse } from './ElectricityTimingOfUse';
import { AirFilters } from './Air Filters';
import { IncomeTaxRefund } from './IncomeTaxRefund';
import { KeepingCarLonger } from './KeepingCarLonger';
import { LatePaymentsCostYou } from './LatePaymentsCostYou';
import { HomeInsuranceRates } from './HomeInsuranceRates';
import { FixedOrFloatingEnergyRate } from './FixedOrFloatingEnergyRate';
import { EqualMonthlyPaymentPlan } from './EqualMonthlyPaymentPlan';
import { TaxablevsNonTaxableIncome } from './TaxablevsNonTaxableIncome';
import { ControlYourExpenses } from './ControlYourExpenses';
import { ActivePassiveIncomeRatio } from './ActivePassiveIncomeRatio';
import { EarnedvsUnearnedIncome } from './EarnedvsUnearnedIncome';
import { ActivevsPassiveIncome } from './ActivevsPassiveIncome';
import { SoftInquiryvsHardInquiry } from './SoftInquiryvsHardInquiry';
import { InteractInternationalTransfer } from './InteractInternationalTransfer';
import { InteracCrossBorder } from './InteracCrossBorder';
import { TheSixLevelModelOfWork } from './TheSixLevelModelOfWork';
import { CalendarAndReminders } from './CalendarAndReminders';
import { TypesOfGICs } from './TypesOfGICs';
import { WhatIsGIC } from './WhatIsGIC';
import { WhoIsInterac } from './WhoIsInterac';
import { NoMoreDailyToDoList } from './NoMoreDailyToDoList';
import { HoweTransferSecurityWorks } from './HoweTransferSecurityWorks';
import { LifetimeGoals } from './LifetimeGoals';
import { GoalsVsMilestones } from './GoalsVsMilestones';
import { HowMilestonesHelpToAccomplishGoals } from './HowMilestonesHelpToAccomplishGoals';
import { RentalVacancyRatesOntario2020_2021 } from './RentalVacancyRatesOntario2020_2021';
import { InternationalMoneyTransferProviders } from './InternationalMoneyTransferProviders';
import { ElectricVehicle } from './ElectricVehicle';
import { AllElectricBy2030 } from './AllElectricBy2030';
import { AllElectricVehiclesFuelEconomy } from './AllElectricVehiclesFuelEconomy';
import { EVRankBySales } from './EVRankBySales';
import { ElectricVehicleFederalGovernmentIncentives } from './ElectricVehicleFederalGovernmentIncentives';
import { TechnologyInRealEstate } from './TechnologyInRealEstate';
import { DebtToIncomeRatio } from './DebtToIncomeRatio';
import { DebtUtilizationRatio } from './DebtUtilizationRatio';
import { DebtLoad } from './DebtLoad';
import { DemandDebtRatio } from './DemandDebtRatio';
import { PredatoryLending } from './PredatoryLending';
import { SignsOfPredatoryLending } from './SignsOfPredatoryLending';
import { HowToAvoidPredatoryLending } from './HowToAvoidPredatoryLending';
import { AlternativesToPredatoryLenders } from './AlternativesToPredatoryLenders';
import { PaydayLoanProviders } from './PaydayLoanProviders';
import { ChargerLevels } from './ChargerLevels';
import { CostToPowerElectricVehicle } from './CostToPowerElectricVehicle';
import { AdoptionDependenciesElectricVehicles } from './AdoptionDependenciesElectricVehicles';
import { GasVsElectricVehicle } from './GasVsElectricVehicle';
import { EVWaitTimes } from './EVWaitTimes';
import { MobilePaymentApps } from './MobilePaymentApps';
import { SelectionCriteria } from './SelectionCriteria';
import { SecuringMobilePayments } from './SecuringMobilePayments';
import { MobilePaymentsVsDigitalWallets } from './MobilePaymentsVsDigitalWallets';
import { RisksOfMobilePayments } from './RisksOfMobilePayments';
import { WhatIsACreditUnion } from './WhatIsACreditUnion';
import { AdvantagesOfCreditUnion } from './AdvantagesOfCreditUnion';
import { Top20CreditUnions } from './Top20CreditUnions';
import { CreditUnionVsBanks } from './CreditUnionVsBanks';
import { CreditScoreForRenting } from './CreditScoreForRenting';

export const staticPagesRoutes = (
  <Switch>
    <Route path="/overview/text/short/18-mar-2021/01" exact component={OverViewLearnValue} />
    <Route path="/overview/text/short/23-may-2021/02" exact component={WorkingForMoney} />
    <Route path="/overview/text/short/23-may-2021/03" exact component={LearningProcess} />
    <Route path="/overview/learn/text/short/05-jun-2021/01" exact component={WeeklySaving} />
    <Route path="/overview/learn/text/short/05-jun-2021/02" exact component={StartSmall} />
    <Route path="/overview/learn/text/short/06-jun-2021/01" exact component={Focus} />
    <Route path="/overview/learn/text/short/07-jun-2021/01" exact component={InformationKnowledge} />
    <Route path="/overview/navigating-this-site/video/short/30-sep-2021/01" exact component={HelpBox} />
    <Route path="/overview/add/video/short/18-apr-2022/02" exact component={AddVideo} />
    <Route path="/overview/edit-delete/video/short/18-apr-2022/01" exact component={EditDeleteVideo} />
    <Route path="/overview/support/video/short/04-may-2022/01" exact component={SupportVideo} />

    <Route path="/overview/onedollaratatime/text/short/18-oct-2021/01" exact component={KnowledgePower} />
    <Route path="/overview/text/short/25-nov-2021/01" exact component={Inflation} />

    <Route path="/overview/know-your-allies/text/short/02-mar-2022/01" exact component={Time} />
    <Route path="/overview/know-your-allies/text/short/18-mar-2022/01" exact component={CompoundInterest} />
    <Route path="/overview/know-your-allies/text/short/22-mar-2022/01" exact component={Stocks} />
    <Route path="/overview/know-your-allies/text/short/22-mar-2022/02" exact component={RealEstate} />

    <Route path="/overview/economy/text/short/14-mar-2022/01" exact component={InterestRateRaised} />

    <Route path="/debt/managingdebt/text/short/12-jun-2021/01" exact component={AmberLights} />
    <Route path="/debt/managingdebt/text/short/13-jun-2021/01" exact component={DebtManagement} />
    <Route path="/debt/understandingfundamentals/text/short/26-mar-2022/02" exact component={MinimumMonthlyPayment} />
    <Route path="/debt/understandingfundamentals/text/short/26-mar-2022/01" exact component={CreditUtilization} />
    <Route path="/debt/debt-ratios/text/short/30-jun-2022/01" exact component={DebtToIncomeRatio} />
    <Route path="/debt/debt-ratios/text/short/30-jun-2022/02" exact component={DebtUtilizationRatio} />
    <Route path="/debt/debt-ratios/text/short/30-jun-2022/03" exact component={DebtLoad} />
    <Route path="/debt/debt-ratios/text/short/30-jun-2022/04" exact component={DemandDebtRatio} />
    <Route path="/debt/predatory-lending/text/short/14-jul-2022/01" exact component={PredatoryLending} />
    <Route path="/debt/predatory-lending/text/short/14-jul-2022/02" exact component={SignsOfPredatoryLending} />
    <Route path="/debt/predatory-lending/text/short/14-jul-2022/03" exact component={HowToAvoidPredatoryLending} />
    <Route path="/debt/predatory-lending/text/short/14-jul-2022/04" exact component={AlternativesToPredatoryLenders} />
    <Route path="/debt/predatory-lending/text/short/14-jul-2022/05" exact component={PaydayLoanProviders} />

    <Route path="/goal/settinggoal/text/short/13-jun-2021/01" exact component={GoodChoice} />
    <Route path="/goal/goal/text/short/24-may-2021/01" exact component={SettingGoal} />
    <Route path="/goal/settinggoal/text/short/20-oct-2021/01" exact component={WriteItGoal} />
    <Route path="/goal/settinggoal/text/short/20-oct-2021/02" exact component={MilestonesGoal} />
    <Route path="/goal/basics/text/short/29-jun-2022/01" exact component={LifetimeGoals} />
    <Route path="/goal/basics/text/short/29-jun-2022/02" exact component={GoalsVsMilestones} />
    <Route path="/goal/basics/text/short/29-jun-2022/03" exact component={HowMilestonesHelpToAccomplishGoals} />

    <Route path="/calendar/time-management/text/short/28-jul-2021/01" exact component={FiveMinuteRule} />
    <Route path="/calendar/time-management/text/short/28-jul-2021/02" exact component={PuttingOffThings} />
    <Route path="/calendar/time-management/text/short/27-jul-2021/01" exact component={DontHaveTime} />
    <Route path="/calendar/time-management/text/short/27-jul-2021/02" exact component={WeeklyReview} />
    <Route path="/calendar/time-management/text/short/12-sep-2021/01" exact component={WhyCalendar} />
    <Route path="/calendar/time-management/text/short/23-jun-2022/01" exact component={TheSixLevelModelOfWork} />
    <Route path="/calendar/time-management/text/short/23-jun-2022/02" exact component={CalendarAndReminders} />
    <Route path="/calendar/organizing-tools/text/short/28-jun-2022/01" exact component={NoMoreDailyToDoList} />

    <Route
      path="/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/03"
      exact
      component={WhoUseCreditScore}
    />
    <Route
      path="/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/02"
      exact
      component={MeaningCreditScore}
    />
    <Route
      path="/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/01"
      exact
      component={LongRecordStays}
    />
    <Route
      path="/creditscore/managingcreditscore/thebasics/text/short/25-aug-2021/0"
      exact
      component={WhatIsCreditScore}
    />
    <Route path="/creditscore/thebasics/text/short/19-sep-2021/01" exact component={Donts} />
    <Route
      path="/creditscore/managingcreditscore/thebasics/text/short/25-aug-2021/1"
      exact
      component={CreditScoreVsCreditReport}
    />

    <Route
      path="/creditscore/understandingcredit-score/text/short/10-oct-2021/01"
      exact
      component={CalculatingCreditScore}
    />

    <Route path="/creditscore/understandingcredit-score/text/short/10-oct-2021/02" exact component={HowOftenUpdated} />

    <Route path="/creditscore/whatsnew/text/short/22-dec-2021/01" exact component={BuyNowPayLater} />
    <Route path="/credit-score/whats-new/text/short/05-aug-2022/02" exact component={CreditScoreForRenting} />

    <Route path="/creditscore/text/short/01-mar-2022/01" exact component={LimitationsCreditScore} />

    <Route
      path="/creditscore/impacton-bottomline/text/short/23-jan-2022/01"
      exact
      component={CreditScoreVsInterestRate}
    />
    <Route path="/creditscore/impactonbottomline/text/short/14-mar-2022/01" exact component={WhoProvidesCreditScores} />
    <Route
      path="/creditscore/impact-on-bottom-line/text/short/31-mar-2022/01"
      exact
      component={WhenCreditScoreIsUsed}
    />
    <Route path="/credit-score/key-terms/text/short/23-jun-2022/01" exact component={SoftInquiryvsHardInquiry} />

    <Route path="/wintertires/text/short/29-sept-2021/01" exact component={WinterTires} />

    <Route path="/user-account/support/text/short/19-sep-2021/01" exact component={ContactSupport} />
    <Route path="/user-account/managing-account/text/short/19-sep-2021/02" exact component={BillingMembershipFee} />
    <Route path="/user-account/managing-account/text/short/19-sep-2021/01" exact component={AccountAccess} />
    <Route path="/user-account/managing-account/text/short/12-sep-2021/0" exact component={PasswordGuideline} />

    <Route path="/bills/managingbills/phone/text/short/25-aug-2021/01" exact component={PopularSmartPhones} />
    <Route path="/bills/managingbills/phone/text/short/25-aug-2021/02" exact component={WhenToAdopt5G} />
    <Route path="/bills/managingbills/phone/text/short/25-nov-2021/01" exact component={CdmaVsGsmVsLte} />
    <Route path={'/bills/phone/text/short/22-feb-2022/01'} exact component={CustomerCareSatisfaction} />

    <Route path="/expenses/save-on-phone-bills/text/short/12-sep-2021/0" exact component={PhoneFamily} />
    <Route path="/expenses/save-on-phone-bills/text/short/19-sep-2021/01" exact component={PhoneCallAlternatives} />
    <Route path="/expenses/save-on-phone-bills/text/short/18-mar-2022/01" exact component={CashInYourOldPhone} />

    <Route path="/expenses/bills/utility-bills/text/short/29-may-2022/01" exact component={OwnWaterHeater} />
    <Route path="/expenses/bills/utility-bills/text/short/29-may-2022/02" exact component={ElectricityTimingOfUse} />
    <Route path="/expenses/bills/utility-bills/text/short/29-may-2022/03" exact component={AirFilters} />
    <Route path="/expenses/bills/utility-bills/text/short/03-jun-2022/01" exact component={FixedOrFloatingEnergyRate} />

    <Route path="/expenses/bills/bill-payments/text/short/03-jun-2022/01" exact component={LatePaymentsCostYou} />
    <Route path="/expenses/bills/bill-payments/text/short/03-jun-2022/02" exact component={EqualMonthlyPaymentPlan} />

    <Route path="/tax/tax-deductible-expenses/text/short/12-sep-2021/01" exact component={ChildCareExpenses} />
    <Route path="/tax/electronicfiling/text/short/22-oct-2021/01" exact component={Netfile} />
    <Route path="/tax/electronicfiling/text/short/08-nov-2021/01" exact component={NetfileAdvantages} />
    <Route path="/tax/electronicfiling/text/short/08-nov-2021/02" exact component={NetfileRestrications} />
    <Route path="/tax/electronicfiling/text/short/08-nov-2021/03" exact component={NetfileServiceHours} />
    <Route path="/tax/tax-deductible-expenses/text/short/10-feb-2022/01" exact component={DeductionOfVehicleExpenses} />
    <Route path="/tax/tax-deductible-expenses/text/short/10-feb-2022/02" exact component={InterestOnStudentLoan} />
    <Route path="/tax/tax-deductible-expenses/text/short/10-feb-2022/03" exact component={TuitionAndTrainingFees} />
    <Route path="/tax/tax-deductible-expenses/text/short/10-feb-2022/04" exact component={ProfessionalUnionDues} />
    <Route
      path="/tax/tax-deductible-expenses-for-business/text/short/22-mar-2022/01"
      exact
      component={GrantsSubsidiesRebates}
    />
    <Route
      path="/tax/tax-deductible-expenses-for-business/text/short/31-mar-2022/01"
      exact
      component={TaxDeductionForSelfEmployed}
    />

    <Route
      path="/tax/tax-deductible-expenses-for-business/text/short/08-apr-2022/01"
      exact
      component={ClaimingTaxBenefitsForDependents}
    />

    <Route
      path="/tax/tax-deductible-expenses-for-business/text/short/03-may-2022/01"
      exact
      component={IncomeFromOtherSources}
    />

    <Route path="/tax/tax-deductible-expensesII/text/short/17-feb-2022/01" exact component={RelocationExpenses} />
    <Route path="/tax/tax-deductible-expensesII/text/short/17-feb-2022/02" exact component={AccountingAndLegalFees} />
    <Route path="/tax/tax-deductible-expensesII/text/short/22-feb-2022/02" exact component={SuppliesExpense} />
    <Route path="/tax/tax-deductible-expensesII/text/short/22-feb-2022/03" exact component={TravellingExpense} />
    <Route path="/tax/tax-deductible-expensesII/text/short/22-feb-2022/04" exact component={ParkingExpense} />
    <Route
      path="/tax/tax-deductible-expensesII/text/short/22-mar-2022/01"
      exact
      component={BonusForSalariedEmployees}
    />
    <Route path="/tax/tax-refund/text/short/29-may-2022/01" exact component={IncomeTaxRefund} />

    <Route path="/billpayments/text/short/18-oct-2021/02" exact component={MakeItFailProof} />
    <Route path="/billpayments/text/short/18-oct-2021/03" exact component={TminusFive} />

    <Route path="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/01" exact component={TaxRates} />
    <Route path="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/02" exact component={TaxCredit} />
    <Route path="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/03" exact component={SavingAndInvestment} />
    <Route path="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/04" exact component={WhatNewIn2021TaxOther} />

    <Route path="/auto/tire/text/short/18-oct-2021/01" exact component={TireExpiry} />
    <Route path="/auto/tire/text/short/18-oct-2021/02" exact component={TireTreadDepth} />
    <Route path="/auto/tire/text/short/19-oct-2021/01" exact component={TireSpeedRating} />
    <Route path="/auto/tire/text/short/25-nov-2021/01" exact component={TopTenManufacturers} />

    <Route path="/auto/car/text/short/03-jun-2021/01" exact component={KeepingCarLonger} />
    <Route path="/auto/electric-vehicle-series-I/text/short/06-jul-2022/01" exact component={ElectricVehicle} />
    <Route path="/auto/electric-vehicle-series-I/text/short/06-jul-2022/02" exact component={AllElectricBy2030} />
    <Route
      path="/auto/electric-vehicle-series-I/text/short/06-jul-2022/03"
      exact
      component={AllElectricVehiclesFuelEconomy}
    />
    <Route path="/auto/electric-vehicle-series-II/text/short/06-jul-2022/01" exact component={EVRankBySales} />
    <Route
      path="/auto/electric-vehicle-series-I/text/short/06-jul-2022/04"
      exact
      component={ElectricVehicleFederalGovernmentIncentives}
    />
    <Route path="/auto/electric-vehicle-series-II/text/short/12-jul-2022/01" exact component={ChargerLevels} />
    <Route
      path="/auto/electric-vehicle-series-II/text/short/12-jul-2022/02"
      exact
      component={CostToPowerElectricVehicle}
    />
    <Route
      path="/auto/electric-vehicle-series-II/text/short/12-jul-2022/03"
      exact
      component={AdoptionDependenciesElectricVehicles}
    />
    <Route path="/auto/electric-vehicle-series-II/text/short/12-jul-2022/04" exact component={GasVsElectricVehicle} />
    <Route path="/auto/electric-vehicle-series-II/text/short/12-jul-2022/05" exact component={EVWaitTimes} />

    <Route path="/saving/timelessprinciples/text/short/20-jan-2022/01" exact component={PayYourselfFirst} />
    <Route path="/saving/timelessprinciples/text/short/19-feb-2022/01" exact component={EnvelopMethod} />
    <Route path="/saving/timelessprinciples/text/short/14-mar-2022/01" exact component={TheLatteFactor} />
    <Route path="/saving/timeless-principles/text/short/08-jun-2022/01" exact component={ControlYourExpenses} />

    <Route path="/saving/20-30-50-plan/text/short/24-feb-2022/01" exact component={Plan_20_30_50} />
    <Route path="/saving/20-30-50-plan/text/short/24-feb-2022/02" exact component={Saving20} />
    <Route path="/saving/20-30-50-plan/text/short/24-feb-2022/03" exact component={Wants30} />
    <Route path="/saving/20-30-50-plan/text/short/24-feb-2022/04" exact component={MustHave50} />
    <Route path="/saving/20-30-50-plan/text/short/24-feb-2022/05" exact component={Income} />

    <Route path="/savinggoals/text/short/01-mar-2022/01" exact component={RetirementSavingsRequired} />

    <Route path="/investments/lifecycle-investment-guide/text/short/24-oct-2021/0" exact component={AssetAllocation} />
    <Route
      path="/investments/lifecycle-investment-guide/text/short/24-oct-2021/02"
      exact
      component={AssetAllocationMid20}
    />
    <Route
      path="/investments/lifecycle-investment-guide/text/short/24-oct-2021/03"
      exact
      component={AssetAllocationMid30}
    />
    <Route
      path="/investments/lifecycle-investment-guide/text/short/24-oct-2021/04"
      exact
      component={AssetAllocationMid50}
    />
    <Route
      path="/investments/lifecycle-investment-guide/text/short/24-oct-2021/05"
      exact
      component={AssetAllocationMid60}
    />
    <Route path="/investment/etf/text/short/17-feb-2022/01" exact component={ETF} />
    <Route path="/investment/etf/text/short/19-feb-2022/01" exact component={WhyETF} />
    <Route path="/investment/etf/text/short/19-feb-2022/02" exact component={TypesOfETF} />
    <Route path="/investment/etf/text/short/19-feb-2022/03" exact component={ETFInMyPortfolio} />
    <Route path="/investment/etf/text/short/01-mar-2022/02" exact component={TypesofETFAdvanced} />
    <Route path="/investment/bond/text/short/9-mar-2022/04" exact component={WhatIsBond} />
    <Route path="/investment/bond/text/short/9-mar-2022/05" exact component={RisksOfBonds} />
    <Route path="/investment/bond/text/short/9-mar-2022/01" exact component={WhyBond} />
    <Route path="/investment/bond/text/short/9-mar-2022/02" exact component={AllBondPortfolio} />
    <Route path="/investment/bond/text/short/9-mar-2022/03" exact component={BondRatings} />
    <Route path="/investment/bond/text/short/9-mar-2022/03" exact component={BondRatings} />
    <Route path="/investment/investement-types/text/short/12-apr-2022/01" exact component={EmergencyFunds} />
    <Route path="/investment/investement-types/text/short/12-apr-2022/02" exact component={MediumTermInvestments} />
    <Route path="/investment/investement-types/text/short/12-apr-2022/03" exact component={LongTermInvestment} />
    <Route path="/investment/RRSP/text/short/07-may-2022/01" exact component={WhatIsRRSP} />
    <Route path="/investment/RRSP/text/short/07-may-2022/02" exact component={BorrowingForRRSPContribution} />
    <Route path="/investment/RRSP/text/short/07-may-2022/03" exact component={RRSPVSTFSA} />
    <Route path="/investment/RRSP/text/short/07-may-2022/04" exact component={RRSPVSTFSA2} />
    <Route path="/investment/RRSP/text/short/3-may-2022/02" exact component={RRSPContributionLimit} />
    <Route path="/investment/RRSP/text/short/3-may-2022/01" exact component={TaxesOnRRSPWithdrawals} />
    <Route path="/investment/RRSP/text/short/10-may-2022/01" exact component={RRSPQualifiedInvestments} />

    <Route path="/investment/basics/text/short/03-may-2022/01" exact component={EmployerPensionPlans} />

    <Route path="/income/text/short/25-nov-2021/01" exact component={IncomeDistribution} />
    <Route path="/income/the-basics/text/short/31-mar-2022/01" exact component={DisposableIncome} />
    <Route path="/income/thebasics/text/short/31-mar-2022/02" exact component={DisposableIncomeTrend} />
    <Route path="/income/the-basics/text/short/08-apr-2022/01" exact component={DiscretionaryIncome} />

    <Route path="/income/key-terms/text/short/08-jun-2022/01" exact component={TaxablevsNonTaxableIncome} />
    <Route path="/income/key-terms/text/short/08-jun-2022/03" exact component={ActivePassiveIncomeRatio} />
    <Route path="/income/key-terms/text/short/08-jun-2022/02" exact component={EarnedvsUnearnedIncome} />
    <Route path="/income/key-terms/text/short/08-jun-2022/04" exact component={ActivevsPassiveIncome} />

    <Route path="/home/the-basics/text/short/08-apr-2022/01" exact component={CanadaGreenerHomes} />
    <Route path="/home/the-basics/text/short/08-apr-2022/02" exact component={NewHousingGSTHSTRefunds} />
    <Route path="/home/the-basics/text/short/08-apr-2022/03" exact component={HomeBuyersAmount} />
    <Route path="/home/the-basics/text/short/08-apr-2022/03" exact component={HomeBuyersAmount} />
    <Route path="/home/the-basics/text/short/03-may-2022/01" exact component={HomeBuyersPlan} />

    <Route path="/home/buying-a-house/text/short/12-apr-2022/01" exact component={BuyingAHouseCriteria} />
    <Route path="/home/buying-a-house/text/short/12-apr-2022/02" exact component={BenefitsOfOwningAHome} />
    <Route path="/home/buying-a-house/text/short/29-jun-2022/01" exact component={RentalVacancyRatesOntario2020_2021} />
    <Route path="/home/buying-a-house/text/short/30-jun-2022/02" exact component={TechnologyInRealEstate} />

    <Route path="/page1" exact component={Page1} />
    <Route path="/page0001" exact component={Page0001} />
    <Route path="/A01B1C1D2E1F1G1H01I221220J1" exact component={Page0002} />
    <Route path="/A01B1C1D2E01F1G01H01I180121J01K01" exact component={Page0003} />
    <Route path="/A01B1C1D2E01F1G01H01I180121J02K01" exact component={Page0004} />
    <Route path="/A01B1C1D2E01F1G01H01I180121J03K01" exact component={Page0005} />
    <Route path="/A01B1C1D2E01F1G01H01I180121J04K01" exact component={Page0006} />
    <Route path="/A01B1C1D2E01F1G01H01I180121J05K01" exact component={Page0007} />
    <Route path="/A01B1C1D2E01F1G01H01I180121J06K01" exact component={Page0008} />
    <Route path="/A01B1C1D2E01F1G01H01I190121J01K01" exact component={Page0009} />
    <Route path="/A01B1C1D2E01F1G01H01I270121J1K01" exact component={Page0010} />
    <Route path="/A01B1C1D2E01F1G01H01I270121J2K01" exact component={Page0011} />

    <Route path="/balance/bankaccounts/text/short/17-feb-2022/01" exact component={HowManyBankAccounts} />
    <Route path="/balance/bankaccounts/text/short/19-feb-2022/01" exact component={ProtectYourDeposit} />
    <Route path="/balance/bankaccounts/text/short/18-mar-2022/01" exact component={TelephoneBanking} />
    <Route path="/balance/bankaccounts/text/short/18-mar-2022/02" exact component={FilingComplaints} />
    <Route path="/balance/basics/text/short/7-may-2022/01" exact component={TypesOfSavingsAccounts} />

    <Route path="/balance/basics/text/short/28-jun-2022/01" exact component={TypesOfGICs} />
    <Route path="/balance/basics/text/short/28-jun-2022/02" exact component={WhatIsGIC} />

    <Route path="/balance/basics/text/short/29-jun-2022/02" exact component={InternationalMoneyTransferProviders} />

    <Route path="/balance/credit-union/text/short/05-aug-2022/01" exact component={WhatIsACreditUnion} />
    <Route path="/balance/credit-union/text/short/05-aug-2022/02" exact component={AdvantagesOfCreditUnion} />
    <Route path="/balance/credit-union/text/short/05-aug-2022/03" exact component={Top20CreditUnions} />
    <Route path="/balance/credit-union/text/short/05-aug-2022/04" exact component={CreditUnionVsBanks} />

    <Route path="/transaction/banktransactions/text/short/22-mar-2022/01" exact component={NonSufficientFund} />
    <Route path="/transaction/banktransactions/text/short/25-mar-2022/02" exact component={OverdraftProtection} />
    <Route path="/transaction/banktransactions/text/short/25-mar-2022/01" exact component={AutomateTransactions} />
    <Route
      path="/transaction/bank-transactions/text/short/31-mar-2022/01"
      exact
      component={InteracETransferAutoDeposit}
    />
    <Route path="/transaction/basics/text/short/23-jun-2022/01" exact component={InteractInternationalTransfer} />
    <Route path="/transaction/basics/text/short/23-jun-2022/02" exact component={InteracCrossBorder} />
    <Route path="/transaction/basics/text/short/28-jun-2022/01" exact component={WhoIsInterac} />
    <Route path="/transaction/basics/text/short/28-jun-2022/02" exact component={HoweTransferSecurityWorks} />
    <Route path="/transaction/mobile-payments/text/short/27-jul-2022/01" exact component={MobilePaymentApps} />
    <Route path="/transaction/mobile-payments/text/short/27-jul-2022/02" exact component={SelectionCriteria} />
    <Route path="/transaction/mobile-payments/text/short/27-jul-2022/03" exact component={SecuringMobilePayments} />
    <Route
      path="/transaction/mobile-payments/text/short/27-jul-2022/04"
      exact
      component={MobilePaymentsVsDigitalWallets}
    />
    <Route path="/transaction/mobile-payments/text/short/27-jul-2022/05" exact component={RisksOfMobilePayments} />

    <Route path="/bills/phone/text/short/22-feb-2022/01" exact component={CustomerCareSatisfaction} />

    <Route path="/4-percent-rule/text/short/01-mar-2022/06" exact component={FourPercRule} />
    <Route path="/4-percent-rule/text/short/10-may-2022/01" exact component={FourPercentRule2022} />

    <Route path="/insurance/thebasics/text/short/14-mar-2022/01" exact component={StayWithTheBigOne} />
    <Route path="/insurance/thebasics/text/short/14-mar-2022/02" exact component={WhereToBuyInsurance} />
    <Route path="/insurance/thebasics/text/short/18-mar-2022/01" exact component={TopFiveLifeInsurance} />
    <Route path="/insurance/thebasics/text/short/26-mar-2022/01" exact component={TypesOfLifeInsurance} />
    <Route path="/insurance/auto-insurance/text/short/10-may-2022/01" exact component={FactorsInfluencingPremium} />
    <Route path="/insurance/auto-insurance/text/short/10-may-2022/02" exact component={HowToSaveOnCarInsurance} />
    <Route path="/insurance/auto-insurance/text/short/10-may-2022/03" exact component={TypesOfAutoInsurance} />

    <Route path="/insurance/other-insurance/text/short/29-may-2022/01" exact component={DisabilityInsurance} />
    <Route path="/insurance/other-insurance/text/short/03-jun-2022/01" exact component={HomeInsuranceRates} />
  </Switch>
);

export const staticPagesRoutesPaths = [
  '/overview/text/short/18-mar-2021/01',
  '/overview/text/short/23-may-2021/02',
  '/overview/text/short/23-may-2021/03',
  '/overview/learn/text/short/05-jun-2021/01',
  '/overview/learn/text/short/05-jun-2021/02',
  '/overview/learn/text/short/06-jun-2021/01',
  '/overview/learn/text/short/07-jun-2021/01',
  '/overview/support/video/short/04-may-2022/01',

  '/overview/navigating-this-site/video/short/30-sep-2021/01',
  '/overview/add/video/short/18-apr-2022/02',
  '/overview/edit-delete/video/short/18-apr-2022/01',

  '/overview/onedollaratatime/text/short/18-oct-2021/01',
  '/overview/text/short/25-nov-2021/01',

  '/overview/know-your-allies/text/short/02-mar-2022/01',
  '/overview/know-your-allies/text/short/18-mar-2022/01',
  '/overview/know-your-allies/text/short/22-mar-2022/01',
  '/overview/know-your-allies/text/short/22-mar-2022/02',

  '/overview/economy/text/short/14-mar-2022/01',

  '/debt/managingdebt/text/short/12-jun-2021/01',
  '/debt/managingdebt/text/short/13-jun-2021/01',
  '/debt/understandingfundamentals/text/short/26-mar-2022/02',
  '/debt/understandingfundamentals/text/short/26-mar-2022/01',

  '/debt/debt-ratios/text/short/30-jun-2022/01',
  '/debt/debt-ratios/text/short/30-jun-2022/02',
  '/debt/debt-ratios/text/short/30-jun-2022/03',
  '/debt/debt-ratios/text/short/30-jun-2022/04',

  '/debt/predatory-lending/text/short/14-jul-2022/01',
  '/debt/predatory-lending/text/short/14-jul-2022/02',
  '/debt/predatory-lending/text/short/14-jul-2022/03',
  '/debt/predatory-lending/text/short/14-jul-2022/04',
  '/debt/predatory-lending/text/short/14-jul-2022/05',

  '/goal/settinggoal/text/short/13-jun-2021/01',
  '/goal/goal/text/short/24-may-2021/01',
  '/goal/settinggoal/text/short/20-oct-2021/01',
  '/goal/settinggoal/text/short/20-oct-2021/02',
  '/goal/basics/text/short/29-jun-2022/01',
  '/goal/basics/text/short/29-jun-2022/02',
  '/goal/basics/text/short/29-jun-2022/03',

  '/calendar/time-management/text/short/28-jul-2021/01',
  '/calendar/time-management/text/short/28-jul-2021/02',
  '/calendar/time-management/text/short/27-jul-2021/01',
  '/calendar/time-management/text/short/27-jul-2021/02',
  '/calendar/time-management/text/short/12-sep-2021/01',
  '/calendar/time-management/text/short/23-jun-2022/01',
  '/calendar/time-management/text/short/23-jun-2022/02',
  '/calendar/organizing-tools/text/short/28-jun-2022/01',

  '/creditscore/managingcreditscore/thebasics/text/short/25-aug-2021/0',
  '/creditscore/thebasics/text/short/19-sep-2021/01',
  '/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/03',
  '/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/02',
  '/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/01',
  '/creditscore/managingcreditscore/thebasics/text/short/25-aug-2021/1',
  '/creditscore/understandingcredit-score/text/short/10-oct-2021/01',
  '/creditscore/understandingcredit-score/text/short/10-oct-2021/02',
  '/creditscore/impacton-bottomline/text/short/23-jan-2022/01',
  '/creditscore/text/short/01-mar-2022/01',

  '/creditscore/impactonbottomline/text/short/14-mar-2022/01',
  '/creditscore/impact-on-bottom-line/text/short/31-mar-2022/01',

  '/credit-score/key-terms/text/short/23-jun-2022/01',

  '/creditscore/whatsnew/text/short/22-dec-2021/01',
  '/credit-score/whats-new/text/short/05-aug-2022/02',

  '/user-account/support/text/short/19-sep-2021/01',
  '/user-account/managing-account/text/short/19-sep-2021/02',
  '/user-account/managing-account/text/short/19-sep-2021/01',
  '/user-account/managing-account/text/short/12-sep-2021/0',

  '/bills/managingbills/phone/text/short/25-aug-2021/01',
  '/bills/managingbills/phone/text/short/25-aug-2021/02',
  '/bills/managingbills/phone/text/short/25-nov-2021/01',
  '/bills/phone/text/short/22-feb-2022/01',

  '/expenses/save-on-phone-bills/text/short/12-sep-2021/0',
  '/expenses/save-on-phone-bills/text/short/19-sep-2021/01',
  '/expenses/save-on-phone-bills/text/short/18-mar-2022/01',

  '/expenses/bills/utility-bills/text/short/29-may-2022/01',
  '/expenses/bills/utility-bills/text/short/29-may-2022/02',
  '/expenses/bills/utility-bills/text/short/29-may-2022/03',
  '/expenses/bills/utility-bills/text/short/03-jun-2022/01',

  '/expenses/bills/bill-payments/text/short/03-jun-2022/01',
  '/expenses/bills/bill-payments/text/short/03-jun-2022/02',

  '/tax/tax-deductible-expenses/text/short/12-sep-2021/01',
  '/tax/electronicfiling/text/short/22-oct-2021/01',
  '/tax/electronicfiling/text/short/08-nov-2021/01',
  '/tax/electronicfiling/text/short/08-nov-2021/02',
  '/tax/electronicfiling/text/short/08-nov-2021/03',
  '/tax/tax-deductible-expenses/text/short/10-feb-2022/01',
  '/tax/tax-deductible-expenses/text/short/10-feb-2022/02',
  '/tax/tax-deductible-expenses/text/short/10-feb-2022/03',
  '/tax/tax-deductible-expenses/text/short/10-feb-2022/04',

  '/tax/tax-deductible-expenses-for-business/text/short/22-mar-2022/01',
  '/tax/tax-deductible-expenses-for-business/text/short/31-mar-2022/01',
  '/tax/tax-deductible-expenses-for-business/text/short/08-apr-2022/01',
  '/tax/tax-deductible-expenses-for-business/text/short/03-may-2022/01',

  '/tax/tax-deductible-expensesII/text/short/17-feb-2022/01',
  '/tax/tax-deductible-expensesII/text/short/17-feb-2022/02',
  '/tax/tax-deductible-expensesII/text/short/22-feb-2022/02',
  '/tax/tax-deductible-expensesII/text/short/22-feb-2022/03',
  '/tax/tax-deductible-expensesII/text/short/22-feb-2022/04',
  '/tax/tax-deductible-expensesII/text/short/22-mar-2022/01',

  '/tax/tax-refund/text/short/29-may-2022/01',

  '/billpayments/text/short/18-oct-2021/02',
  '/billpayments/text/short/18-oct-2021/03',
  '/wintertires/text/short/29-sept-2021/01',

  '/managingtaxes/whatsnewin2021/text/short/23-jan-2022/01',
  '/managingtaxes/whatsnewin2021/text/short/23-jan-2022/02',
  '/managingtaxes/whatsnewin2021/text/short/23-jan-2022/03',
  '/managingtaxes/whatsnewin2021/text/short/23-jan-2022/04',

  '/auto/tire/text/short/18-oct-2021/01',
  '/auto/tire/text/short/18-oct-2021/02',
  '/auto/tire/text/short/19-oct-2021/01',
  '/auto/tire/text/short/25-nov-2021/01',

  '/auto/car/text/short/03-jun-2021/01',

  '/auto/electric-vehicle-series-I/text/short/06-jul-2022/01',
  '/auto/electric-vehicle-series-I/text/short/06-jul-2022/02',
  '/auto/electric-vehicle-series-I/text/short/06-jul-2022/03',
  '/auto/electric-vehicle-series-II/text/short/06-jul-2022/01',
  '/auto/electric-vehicle-series-I/text/short/06-jul-2022/04',
  '/auto/electric-vehicle-series-II/text/short/12-jul-2022/01',
  '/auto/electric-vehicle-series-II/text/short/12-jul-2022/02',
  '/auto/electric-vehicle-series-II/text/short/12-jul-2022/03',
  '/auto/electric-vehicle-series-II/text/short/12-jul-2022/04',
  '/auto/electric-vehicle-series-II/text/short/12-jul-2022/05',

  '/saving/timelessprinciples/text/short/20-jan-2022/01',
  '/saving/timelessprinciples/text/short/19-feb-2022/01',
  '/saving/timelessprinciples/text/short/14-mar-2022/01',
  '/saving/timeless-principles/text/short/08-jun-2022/01',

  '/saving/20-30-50-plan/text/short/24-feb-2022/01',
  '/saving/20-30-50-plan/text/short/24-feb-2022/02',
  '/saving/20-30-50-plan/text/short/24-feb-2022/03',
  '/saving/20-30-50-plan/text/short/24-feb-2022/04',
  '/saving/20-30-50-plan/text/short/24-feb-2022/05',

  '/savinggoals/text/short/01-mar-2022/01',

  '/investments/lifecycle-investment-guide/text/short/24-oct-2021/0',
  '/investments/lifecycle-investment-guide/text/short/24-oct-2021/02',
  '/investments/lifecycle-investment-guide/text/short/24-oct-2021/03',
  '/investments/lifecycle-investment-guide/text/short/24-oct-2021/04',
  '/investments/lifecycle-investment-guide/text/short/24-oct-2021/05',
  '/investment/etf/text/short/17-feb-2022/01',
  '/investment/etf/text/short/19-feb-2022/01',
  '/investment/etf/text/short/19-feb-2022/02',
  '/investment/etf/text/short/19-feb-2022/03',
  '/investment/etf/text/short/01-mar-2022/02',
  '/investment/bond/text/short/9-mar-2022/04',
  '/investment/bond/text/short/9-mar-2022/05',
  '/investment/bond/text/short/9-mar-2022/01',
  '/investment/bond/text/short/9-mar-2022/02',
  '/investment/bond/text/short/9-mar-2022/03',

  '/investment/investement-types/text/short/12-apr-2022/01',
  '/investment/investement-types/text/short/12-apr-2022/02',
  '/investment/investement-types/text/short/12-apr-2022/03',

  '/investment/RRSP/text/short/07-may-2022/01',
  '/investment/RRSP/text/short/07-may-2022/02',
  '/investment/RRSP/text/short/07-may-2022/03',
  '/investment/RRSP/text/short/07-may-2022/04',
  '/investment/RRSP/text/short/3-may-2022/02',
  '/investment/RRSP/text/short/3-may-2022/01',
  '/investment/RRSP/text/short/10-may-2022/01',

  '/investment/basics/text/short/03-may-2022/01',

  '/income/text/short/25-nov-2021/01',
  '/income/the-basics/text/short/31-mar-2022/01',
  '/income/thebasics/text/short/31-mar-2022/02',
  '/income/the-basics/text/short/08-apr-2022/01',

  '/income/key-terms/text/short/08-jun-2022/01',
  '/income/key-terms/text/short/08-jun-2022/02',
  '/income/key-terms/text/short/08-jun-2022/03',
  '/income/key-terms/text/short/08-jun-2022/04',

  '/balance/bankaccounts/text/short/17-feb-2022/01',
  '/balance/bankaccounts/text/short/19-feb-2022/01',
  '/balance/bankaccounts/text/short/18-mar-2022/01',
  '/balance/bankaccounts/text/short/18-mar-2022/02',
  '/balance/basics/text/short/7-may-2022/01',
  '/balance/basics/text/short/28-jun-2022/01',
  '/balance/basics/text/short/28-jun-2022/02',

  '/balance/basics/text/short/29-jun-2022/02',

  '/balance/credit-union/text/short/05-aug-2022/01',
  '/balance/credit-union/text/short/05-aug-2022/02',
  '/balance/credit-union/text/short/05-aug-2022/03',
  '/balance/credit-union/text/short/05-aug-2022/04',

  '/transaction/banktransactions/text/short/22-mar-2022/01',
  '/transaction/banktransactions/text/short/25-mar-2022/02',
  '/transaction/banktransactions/text/short/25-mar-2022/01',
  '/transaction/bank-transactions/text/short/31-mar-2022/01',

  '/transaction/basics/text/short/23-jun-2022/01',
  '/transaction/basics/text/short/23-jun-2022/02',
  '/transaction/basics/text/short/28-jun-2022/01',
  '/transaction/basics/text/short/28-jun-2022/02',

  '/transaction/mobile-payments/text/short/27-jul-2022/01',
  '/transaction/mobile-payments/text/short/27-jul-2022/02',
  '/transaction/mobile-payments/text/short/27-jul-2022/03',
  '/transaction/mobile-payments/text/short/27-jul-2022/04',
  '/transaction/mobile-payments/text/short/27-jul-2022/05',

  '/bills/phone/text/short/22-feb-2022/01',

  '/4-percent-rule/text/short/01-mar-2022/06',
  '/4-percent-rule/text/short/10-may-2022/01',

  '/insurance/thebasics/text/short/14-mar-2022/01',
  '/insurance/thebasics/text/short/14-mar-2022/02',
  '/insurance/thebasics/text/short/18-mar-2022/01',
  '/insurance/thebasics/text/short/26-mar-2022/01',

  '/insurance/auto-insurance/text/short/10-may-2022/01',
  '/insurance/auto-insurance/text/short/10-may-2022/02',
  '/insurance/auto-insurance/text/short/10-may-2022/03',

  '/insurance/other-insurance/text/short/29-may-2022/01',
  '/insurance/other-insurance/text/short/03-jun-2022/01',

  '/home/the-basics/text/short/08-apr-2022/01',
  '/home/the-basics/text/short/08-apr-2022/02',
  '/home/the-basics/text/short/08-apr-2022/03',
  '/home/the-basics/text/short/03-may-2022/01',
  '/home/buying-a-house/text/short/12-apr-2022/01',
  '/home/buying-a-house/text/short/12-apr-2022/02',
  '/home/buying-a-house/text/short/29-jun-2022/01',
  '/home/buying-a-house/text/short/30-jun-2022/02',

  'page1',
  '/page0001',
  '/A01B1C1D2E1F1G1H01I221220J1',
  '/A01B1C1D2E01F1G01H01I180121J01K01',
  '/A01B1C1D2E01F1G01H01I180121J02K01',
  '/A01B1C1D2E01F1G01H01I180121J03K01',
  '/A01B1C1D2E01F1G01H01I180121J04K01',
  '/A01B1C1D2E01F1G01H01I180121J05K01',
  '/A01B1C1D2E01F1G01H01I180121J06K01',
  '/A01B1C1D2E01F1G01H01I190121J01K01',
  '/A01B1C1D2E01F1G01H01I270121J1K01',
  '/A01B1C1D2E01F1G01H01I270121J2K01',
];
