import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';

export const Page0005: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">Introducing Key Concepts</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        We will orient ourselves with these Key Concepts, which would be instrumental throughout our journey.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">1. Personal Finance:</span> Bring all the art and science of money matters at
        your fingertips.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">2. Technology:</span> New technology that we can use to save time as well as
        cost and build our wealth.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">3. Business Models:</span> How the companies make money and to use that
        market intelligence to our advantage as consumers.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">4. Regulations:</span> How should we respond to the regulatory framework or
        changes for our most benefit.
      </Typography>
    </Container>
  );
};
