import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { IncomeForm } from './IncomeForm';
import { IncomeFormInputs } from './types';
import { createIncome, getIncomeList } from './incomeSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddIncome: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getIncomeListAction = useCallback(() => dispatch(getIncomeList()), [dispatch]);

  const onSubmit = async (data: IncomeFormInputs) => {
    try {
      await createIncome(data);
      history.push('/income/income');
      getIncomeListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Income']}>
      <IncomeForm onSubmit={onSubmit} />
    </Modal>
  );
};
