import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TaxDeductionForSelfEmployed: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tax Deduction</h1>

        <h2 className={styles.subHeading}>For Self-Employed</h2>

        <p className={styles.bodyText}>
          If you’re self-employed and your business is not incorporated, you must file an individual tax return each
          year.
        </p>

        <p className={styles.bodyText}>Business income includes money earned from:</p>

        <ol className={styles.list}>
          <li>Profession</li>
          <li>Trade</li>
          <li>Manufacture or</li>
          <li>
            Undertaking of any kind, an adventure or concern in the nature of trade, or any other activity you carry on
            for profit and there is evidence to support that intention.
          </li>
        </ol>

        <h3 className={styles.paraHeading}>Claiming Business Expenses</h3>

        <p className={styles.bodyText}>
          You can deduct most expenses that incur to earn business income, provided those are reasonable.
        </p>

        <ul className={styles.list}>
          <li>
            <strong>Meals and Entertainment: </strong>You can claim up to 50% of the actual expense, or any amount that
            is reasonable in the circumstances, whichever is less.
          </li>
          <li>
            <strong>Home Office Expenses: </strong>You can deduct expenses for the business use of a work space in your
            home if:
            <ul className={styles.subList} style={{ listStyle: 'circle' }}>
              <li>It is the main work place of your business, or</li>
              <li>
                You use it only to earn your business income, and to meet clients, customers or patients on a regular
                and ongoing basis. If the home is used for personal as well as business use, then you can only deduct
                the proportionate amount used for business purposes. Use a “reasonable basis” to calculate the amount
                like area of your business work space divided by the total area of your home.
              </li>
              <li>You can’t charge home office expenses to create a business loss.</li>
              <li>
                For Home Office expenses you can deduct
                <ol>
                  <li>Your mortgage interest or rent</li>
                  <li>Property taxes</li>
                  <li>Capital cost allowance</li>
                  <li>Utilities</li>
                  <li>Home insurance</li>
                  <li>Maintenance</li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            <strong>Business Start-up Costs: </strong>You can deduct expenses that preceded the operation of the
            business. However, you can only claim expenses, if you operated the business in the fiscal period in which
            the expense was incurred.
          </li>
          <li>
            <strong>Supplies, Management and Administrative Fees,</strong> Advertisement expenses etc. can be deducted
            to the extent used for business operations.
          </li>
          <li>
            <strong>Capital Property Costs: </strong>Capital property includes items like furniture, computer equipment
            or a building that you buy for business use only. You can write off those costs over time as depreciation.
            This is called Capital Cost Allowance (CCA).
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Calculating Income Using Form T2125</h3>

        <p className={styles.bodyText}>
          For a self-employed person, you must fill out{' '}
          <a
            href="https://www.canada.ca/content/dam/cra-arc/formspubs/pbg/t2125/t2125-17e.pdf"
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Form T2125
          </a>{' '}
          - the Statement of Business or Professional Activities.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          This form helps you to calculate your Gross Income, which is the total amount of money you earned during the
          year and deduct the allowable expenses from your Gross Income to lower your Taxable Income, so that you pay
          less income taxes.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/31/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses-for-business/text/short/31-mar-2022/01" />
    </>
  );
};
