import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const CreditUnionVsBanks: React.FC = () => {
  const creditUnion = [
    {
      dataField: 'advantages',
      text: 'Advantages',
    },
    {
      dataField: 'disadvantages',
      text: 'Disadvantages',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Credit Union vs. Banks</h1>

        <h3 className={styles.paraHeading}>Ownership</h3>
        <p className={styles.bodyText}>
          Credit Union is owned by its clients who are also the member of the cooperative. Bank in contrast is owned by
          a group of shareholders who may or may not be the bank’s clients.
        </p>

        <h3 className={styles.paraHeading}>Profits</h3>
        <p className={styles.bodyText}>
          Banks operation to make a profit. Whether they are privately owned or publicly traded, banks operate with only
          one goal in mind, to make a profit for their shareholders.
        </p>
        <p className={styles.bodyText}>
          Credit unions in contrast are not-for-profit institutions. They operate in a way to offer the best services at
          lowest possible price and generate enough revenue to cover their operating costs.
        </p>

        <h3 className={styles.paraHeading}>Tax</h3>
        <p className={styles.bodyText}>Bank&aos;s profits are taxable and credit unions are tax exempt.</p>

        <h3 className={styles.paraHeading}>Advantages and Disadvantages</h3>
        <h3 className={styles.paraHeading}>Credit Union</h3>
        <SimpleTable
          keyField="creditUnionVsBank"
          data={[
            {
              advantages: '1. Fewer and lower fees.',
              disadvantages: '1. Fewer Branches.',
            },
            {
              advantages: '2. Lower interest rates on loans.',
              disadvantages: '2. Limited number of advanced products.',
            },
            {
              advantages: '3. Higher interest on deposits.',
              disadvantages: '3. No international presence.',
            },
            {
              advantages: '4. Outstanding customer service.',
              disadvantages: '4. Limited budget for technology.',
            },
            {
              advantages: '5. Insured deposit by province.',
              disadvantages: '5. Eligibility requirement for membership.',
            },
            {
              advantages: '6. Large partnership ATM Network.',
              disadvantages: '',
            },
          ]}
          columns={creditUnion}
          bootstrap4
        />

        <h3 className={styles.paraHeading}>Banks</h3>
        <SimpleTable
          keyField="creditUnionVsBank"
          data={[
            {
              advantages: '1. More branches and owned ATMs.',
              disadvantages: '1. Higher interest rates on loans.',
            },
            {
              advantages: '2. Advanced technology adoption.',
              disadvantages: '2. Lower interest on savings.',
            },
            {
              advantages: '3. Wide product range.',
              disadvantages: '3. Excessive fees.',
            },
            {
              advantages: '4. Insured deposit by CDIC.',
              disadvantages: '4. Focus on profitability than customer.',
            },
            {
              advantages: '5. International network support.',
              disadvantages: '5. Average customer service.',
            },
          ]}
          columns={creditUnion}
          bootstrap4
        />

        <PublishedDate date={'08/05/2022'} />
      </div>

      <FeedbackBox source="/balance/credit-union/text/short/05-aug-2022/04" />
    </>
  );
};
