import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ActivePassiveIncomeRatio: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Active Passive Income Ratio</h1>

        <p className={styles.bodyText}>
          The most common wisdom is, we would need the passive income only when we retire. That’s why no one really
          tracks the contribution of passive income in the total income until it is time to retire.
        </p>

        <p className={styles.bodyText}>
          However, passive income is a very important part throughout our life cycle. Here is why:
        </p>
        <ul className={styles.list}>
          <li>
            It takes time to generate passive income and hard to make significant change overnight. Hence, it is
            important to track the progress on a regular basis so that there are surprises at the finish line.
          </li>
          <li>
            Passive income can give you financial independence. Sooner the financial independence is achieved, the
            better. Future is always uncertain, sudden loss of job, disability or death can happen.
          </li>
          <li>
            Even a partial passive income can entail you a lot of flexibility like liberty to choose the job or role you
            like, taking time off for higher education or pursuing a greater cause.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>10% Passive Income</h3>
        <ul className={styles.list}>
          <li>Target age 25.</li>
          <li>Habit of building passive income should be built early on.</li>
          <li>Habit of building passive income should be built early on.</li>
          <li>You are still very much depended on active income for the necessities and survival.</li>
        </ul>

        <h3 className={styles.paraHeading}>30% Passive Income</h3>
        <ul className={styles.list}>
          <li>Target age 30.</li>
          <li>
            You are beginning to see the benefits of passive income as a third of your income is coming from passive
            sources.
          </li>
          <li>
            You are beginning to see the benefits of passive income as a third of your income is coming from passive
            sources.
          </li>
          <li>
            You are beginning to see the benefits of passive income as a third of your income is coming from passive
            sources.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>50% Passive Income</h3>
        <ul className={styles.list}>
          <li>Target age 35.</li>
          <li>You are now generating as much as passive income as active income.</li>
          <li>You are now generating as much as passive income as active income.</li>
        </ul>

        <h3 className={styles.paraHeading}>70% Passive Income</h3>
        <ul className={styles.list}>
          <li>Target age 40.</li>
          <li>
            Majority of your income is coming from passive income. But you enjoy the work you do and still progressing
            in your career.
          </li>
          <li>You have more flexibility to try new roles, industry locations.</li>
        </ul>

        <h3 className={styles.paraHeading}>90% Passive Income</h3>
        <ul className={styles.list}>
          <li>Target age 45</li>
          <li>At this point don’t really need to work. Worrying about money is a thing of the past.</li>
          <li>You can enjoy the job do, purpose you serve and life you live.</li>
        </ul>

        <h3 className={styles.paraHeading}>100% Passive Income</h3>
        <ul className={styles.list}>
          <li>Target age 50.</li>
          <li>You are completely financially independent at this point.</li>
          <li>
            There is no need for you to work for living. You now have the liberty to dedicate your time for the causes
            you care.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/08/2022'} />
      </div>

      <FeedbackBox source="/income/key-terms/text/short/08-jun-2022/03" />
    </>
  );
};
