import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const MediumTermInvestments: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Medium Term Investments</h1>

        <p className={styles.bodyText}>Medium Term saving generally ranges between 2-5 years of investment horizon.</p>

        <p className={styles.bodyText}>
          Funds are invested in short to medium term investment instruments like GICs, Bonds etc. This ensures a balance
          between earning good returns while being able to convert into cash without any loss of principal when needed
          (liquidity).
        </p>

        <p className={styles.bodyText}>Medium Term Investments looks to achieve certain goal or purpose, such as:</p>
        <ol className={styles.list}>
          <li>Down payment for buying a house.</li>
          <li>Down payment for buying a car.</li>
          <li>Setting aside funds for wedding expense.</li>
          <li>Tuition for continuing education.</li>
        </ol>

        <p className={styles.bodyText}>
          Each investment must be associated with specific goal or purpose along with the following:
        </p>
        <ol className={styles.list}>
          <li>Target amount to be saved.</li>
          <li>Target date of maturity.</li>
          <li>
            Instruments of saving (e.g. GICs, Bonds etc.).
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'04/12/2022'} />
      </div>

      <FeedbackBox source="/investment/investement-types/text/short/12-apr-2022/02" />
    </>
  );
};
