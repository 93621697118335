import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhereToBuyInsurance: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Where to Buy Insurance</h1>

        <h3 className={styles.paraHeading}>1. Insurance Company</h3>
        <p className={styles.bodyText}>
          Buying directly from an Insurance Company is always a great way to save by eliminating the middleman. You will
          get the same insurance policy at a cheaper price than other alternatives since there are no middleman
          commissions involved.
        </p>
        <p className={styles.bodyText}>
          Reputed insurance companies are well resourced and have good details about their offers available to help you
          to make the right choice. The knowledgebase in this section of your Arctic Rich portal will prepare you to
          shop for the policy that would be suitable for you.
        </p>

        <h3 className={styles.paraHeading}>2. Insurance Agent</h3>
        <p className={styles.bodyText}>
          An Insurance Agent represents an Insurance Company. The insurance agent can represent only one insurance
          company at a time. However, they are usually quite knowledgeable of what they sell and can help you to choose
          the right solution from all the available options offered from by the company they represent.
        </p>
        <p className={styles.bodyText}>
          Insurance Agents generally earn a base salary plus a small commission or incentive for the sales revenue they
          bring in.
        </p>

        <h3 className={styles.paraHeading}>3. Insurance Broker</h3>
        <p className={styles.bodyText}>
          Insurance Brokers are independent agents. They are not affiliated to any insurance company or policy
          providers. Insurance Brokers represent the insurance buyers and should work in your best interest. They are
          free to choose insurance policies from different insurance companies, which would be the most suitable
          solution for their clients.
        </p>
        <p className={styles.bodyText}>
          You don’t need to pay anything to your insurance broker. They get paid by the insurance company whose policy
          you will ultimately buy. Their compensation is entirely commission based. Their commission range between 2% to
          8% of your insurance premiums.
        </p>
        <p className={styles.bodyText}>
          Bear in mind that there is an apparent conflict of interest here since the insurance brokers are supposed to
          work in your best interest while they are getting paid by the insurance companies a variable commission that
          depends on the policy you buy from these brokers.
        </p>

        <h3 className={styles.paraHeading}>4. Lender</h3>
        <p className={styles.bodyText}>
          Lenders often buy insurance policies on your behalf from the insurance companies while processing a loan
          application in order to adequately cover their loan or credit exposure which they are about to undertake on
          you.
        </p>

        <h3 className={styles.noteHeading}>Note</h3>
        <p className={styles.bodyText}>
          You can buy an insurance policy in person, over phone or online.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/14/2021'} />
      </div>

      <FeedbackBox source="/insurance/thebasics/text/short/14-mar-2022/02" />
    </>
  );
};
