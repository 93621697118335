import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhatIsCreditScore: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is Credit Score</h1>

        <p className={styles.bodyText}>
          Credit means “borrowing something and paying for it later” and Score means “some numerical value”. So, Credit
          Score is the numerical value used by the lenders to determine the risk of giving loan to a borrower.
        </p>

        <p className={styles.bodyText}>
          A Credit Score is a number between 300-900, which simply tells a lender that how likely someone will repay
          their loan. A high score means there is a greater chance of the person who is borrowing the money will pay it
          back according to the terms of the credit facility.
        </p>

        <p className={styles.bodyText}>
          Lenders judge future performance of any borrower based on their past behavior like loan repayment history,
          total amount borrowed etc. Therefore, it is very important for the borrower to be very mindful of their
          financial commitments to the lenders as well as other parties, to remain in their good standing.
        </p>

        <p className={styles.bodyText}>
          There are direct benefits which borrowers can harvest by remaining in good standing such as qualify for lower
          interest rate, higher amount of loan, more flexible terms etc. Lenders give such preferential treatment to the
          borrower because they are more likely to repay the loan on time, hence considered less risky as a customer.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          In contrast, lower credit score indicates uncertain repayment possibility from the lender&apos;s point of view
          and and accordingly they are treated as risky customers. Obviously, the lenders do not want to lend too much
          money to a risky borrower and always add a built-in cushion in their pricing (interest rate) for taking that
          additional risk.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'08/25/2021'} />
      </div>

      <FeedbackBox source="/creditscore/managingcreditscore/thebasics/text/short/25-aug-2021/0" />
    </>
  );
};
