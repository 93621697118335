import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';

import { RootState } from '../../redux/';
import { CreditScoreItems, CreditScoreFormInputs, CreditFactorFormInputs } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';

export interface CreditScoreState {
  list: CreditScoreItems;
  listLoading: boolean;
}

const initialState: CreditScoreState = {
  list: [],
  listLoading: false,
};

export const getCreditScoreList = createAsyncAction('GET_CREDIT_SCORE_LIST', async () => {
  return await doGetRequest('credit-reports/');
});

export const createCreditScore = (creditScoreRequestBody: CreditScoreFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...creditScoreRequestBody,
    date: format(fromUnixTime(Date.parse(creditScoreRequestBody.date) / 1000), 'yyyy-MM-dd'),
    rating: 'Good',
  };
  return doPostRequest('credit-reports/', updatedRequestBody);
};

export const updateCreditScore = (creditScoreId: number, creditScoreRequestBody: CreditScoreFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...creditScoreRequestBody,
    date: format(fromUnixTime(Date.parse(creditScoreRequestBody.date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('credit-reports/' + creditScoreId + '/', updatedRequestBody);
};

export const deleteCreditScore = async (creditScoreId: number) =>
  await doDeleteRequest('credit-reports/' + creditScoreId + '/');

export const deleteCreditFactor = async (creditFactorId: number) =>
  await doDeleteRequest('score-factors/' + creditFactorId + '/');

export const createCreditFactor = (creditFactorRequestBody: CreditFactorFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...creditFactorRequestBody,
    target_date: format(fromUnixTime(Date.parse(creditFactorRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('score-factors/', updatedRequestBody);
};

export const updateCreditFactor = (creditFactorId: number, creditFactorRequestBody: CreditFactorFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...creditFactorRequestBody,
    target_date: format(fromUnixTime(Date.parse(creditFactorRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('score-factors/' + creditFactorId + '/', updatedRequestBody);
};

export const creditScoreSlice = createSlice({
  name: 'creditScore',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getCreditScoreList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getCreditScoreList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getCreditScoreList.fulfilled)]: (state, action: PayloadAction<CreditScoreItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

export const selectCreditScoreList = (state: RootState) => state.creditScore.list;
export const selectListLoading = (state: RootState) => state.creditScore.listLoading;

export const selectTargetOrActualCreditScoreList = (type: string) => (state: RootState) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  let foundIncomeItems;

  if (type === 'Actual') {
    foundIncomeItems = state.creditScore.list.filter((creditScoreItem) => {
      return (
        parseInt(creditScoreItem.date.substring(0, 4), 10) < currentYear ||
        (parseInt(creditScoreItem.date.substring(0, 4), 10) === currentYear &&
          parseInt(creditScoreItem.date.substring(5, 7), 10) < currentMonth)
      );
    });
  } else {
    foundIncomeItems = state.creditScore.list.filter((creditScoreItem) => {
      return (
        parseInt(creditScoreItem.date.substring(0, 4), 10) > currentYear ||
        (parseInt(creditScoreItem.date.substring(0, 4), 10) === currentYear &&
          parseInt(creditScoreItem.date.substring(5, 7), 10) >= currentMonth)
      );
    });
  }

  if (!foundIncomeItems) {
    return [];
  }

  return foundIncomeItems;
};

export const selectCreditScoreById = (creditScoreId: number) => (state: RootState) =>
  state.creditScore.list.find((creditScoreItem) => {
    return creditScoreItem.id === creditScoreId;
  });

export const selectCreditFactorsByYear = (year: number) => (state: RootState) => {
  const foundCreditScores = state.creditScore.list.filter((creditScoreItem) => {
    return parseInt(creditScoreItem.date.substring(0, 4), 10) === year;
  });

  if (!foundCreditScores) {
    return [];
  }

  return foundCreditScores
    .flatMap((creditScore) => creditScore.factors_influencing_score)
    .map((creditFactor) => {
      const parentCreditScore = foundCreditScores.find((creditScore) => creditScore.id === creditFactor.credit_report);
      return {
        ...creditFactor,
        provider: parentCreditScore ? parentCreditScore.provider : '',
        date: parentCreditScore ? parentCreditScore.date : '',
      };
    });
};

export const selectCreditFactorById = (creditFactorId: number) => (state: RootState) => {
  let foundCreditFactor = undefined;

  state.creditScore.list.forEach((creditScoreItem) => {
    const creditFactorFoundOrNot = creditScoreItem.factors_influencing_score.find((creditFactorItem) => {
      return creditFactorItem.id === creditFactorId;
    });
    if (creditFactorFoundOrNot) {
      foundCreditFactor = creditFactorFoundOrNot;
    }
  });

  return foundCreditFactor;
};

export default creditScoreSlice.reducer;
