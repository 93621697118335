import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const BondRatings: React.FC = () => {
  const columns = [
    {
      dataField: 'sp',
      text: 'Standard & Poor’s',
    },
    {
      dataField: 'moody',
      text: 'Moody’s',
    },
    {
      dataField: 'fitch',
      text: 'Fitch',
    },
    {
      dataField: 'grade',
      text: 'Grade',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Bond Ratings</h1>

        <h2 className={styles.subHeading}>Introduction</h2>

        <ul className={styles.list}>
          <li>
            Bond ratings indicate creditworthiness of individual bonds. This is very similar to the credit scores of
            individuals.
          </li>
          <li>
            Creditworthiness means issuer’s ability to pay the interest on regular basis and repay the loan in full when
            it becomes due.
          </li>
          <li>There are three major rating agencies who rate the bonds: Standard and Poor’s, Moody’s and Fitch.</li>
          <li>
            Lower rated bonds generally offer higher returns or interest rates to compensate the additional risk
            involved.
          </li>
        </ul>

        <h2 className={styles.paraHeading}>How Bonds are Rated</h2>

        <ul className={styles.list}>
          <li>
            Rating agencies conduct independent in-depth analysis of a borrower or issuer of the bond to assign a bond
            rating.
          </li>
          <li>Rating agencies also evaluate the security and repayment structure of the bond itself.</li>
          <li>
            Although the ratings agencies are paid by the borrower or issuer of the bond for their services, these
            ratings are meant to be objective and independent; aimed to serve the investors.
          </li>
          <li>All three major rating agencies use very similar rating matrix for easier comparisons.</li>
        </ul>

        <h2 className={styles.paraHeading}>Investment Grade Bonds</h2>
        <p className={styles.bodyText}>
          Investment Grade Bonds are generally considered high quality bonds and suitable for long-term investments. The
          list below is ranked in order of their grade or quality.
        </p>

        <SimpleTable
          keyField="id"
          data={[
            {
              sp: 'AAA',
              moody: 'Aaa',
              fitch: 'AAA',
              grade: 'Prime',
            },
            {
              sp: 'AA+',
              moody: 'Aa1',
              fitch: 'AA+',
              grade: 'High',
            },
            {
              sp: 'AA',
              moody: 'Aa2',
              fitch: 'AA',
              grade: 'High',
            },
            {
              sp: 'AA-',
              moody: 'Aa3',
              fitch: 'AA-',
              grade: 'High',
            },
            {
              sp: 'A+',
              moody: 'A1',
              fitch: 'A+',
              grade: 'Upper Medium',
            },
            {
              sp: 'A',
              moody: 'A2',
              fitch: 'A',
              grade: 'Upper Medium',
            },
            {
              sp: 'A-',
              moody: 'A3',
              fitch: 'A-',
              grade: 'Upper Medium',
            },
            {
              sp: 'BBB+',
              moody: 'Baa1',
              fitch: 'BBB+',
              grade: 'Lower Medium',
            },
            {
              sp: 'BBB',
              moody: 'Baa2',
              fitch: 'BBB',
              grade: 'Lower Medium',
            },
            {
              sp: 'BBB-',
              moody: 'Baa3',
              fitch: 'BBB-',
              grade: 'Lower Medium',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: Standard & Poor’s, Moody’s and Fitch</div>

        <h2 className={styles.paraHeading}>Non-Investment Grade Bonds</h2>
        <p className={styles.bodyText}>
          Non-investment Grade Bonds carry significantly higher risk than Investment Grade Bonds are generally treated
          as speculative investments. Utmost caution must be exercised before considering these bonds. There are also
          commonly known as Junk Bonds or High Yield Bonds. The list below is ranked in order of their grade or quality.
        </p>

        <SimpleTable
          keyField="id"
          data={[
            {
              sp: 'BB+',
              moody: 'Ba1',
              fitch: 'BB+',
              grade: 'Non-investment',
            },

            { sp: 'BB', moody: 'Ba2', fitch: 'BB', grade: 'Non-investment' },
            { sp: 'BB-', moody: 'Ba3', fitch: 'BB-', grade: 'Non-investment' },
            { sp: 'B+', moody: 'B1', fitch: 'B+', grade: 'Highly speculative' },
            { sp: 'B', moody: 'B2', fitch: 'B', grade: 'Highly speculative' },
            { sp: 'B-', moody: 'B3', fitch: 'B-', grade: 'Highly speculative' },
            { sp: 'CCC+', moody: 'Caa1', fitch: 'CCC+', grade: 'Substantial risks' },
            { sp: 'CCC', moody: 'Caa2', fitch: 'CCC', grade: 'Extremely Speculative' },
            { sp: 'CCC-', moody: 'Caa3', fitch: 'CCC-', grade: 'Default imminent' },
            { sp: 'CC', moody: 'Ca', fitch: 'CC', grade: 'Default imminent' },
          ]}
          columns={columns}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: Standard & Poor’s, Moody’s and Fitch</div>

        <h2 className={styles.paraHeading}>The Weakest Bonds</h2>
        <p className={styles.bodyText}>The list below is ranked in order of their grade or quality.</p>

        <SimpleTable
          keyField="id"
          data={[
            {
              sp: 'C',
              moody: 'C',
              fitch: 'C',
              grade: 'Default imminent',
            },
            {
              sp: 'D',
              moody: 'N/A',
              fitch: 'D',
              grade: 'In Default',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: Standard & Poor’s, Moody’s and Fitch</div>

        <h2 className={styles.paraHeading}>Recommendations</h2>
        <ul className={styles.list}>
          <li>We recommend to limit your investments only with the investment grade bonds.</li>
          <li>
            Non-investment grade investments are not suitable unless you are prepared to tolerate high level of risks.
            i.e. ready to lose the entire principal amount you invested. In most cases, the additional risk exposure
            does not justify the incremental return offered.
          </li>
          <li>
            After purchasing the bond, we recommend to monitor the ratings on regular basis since bond ratings may
            change reflecting to changing financial health of the borrower or issuer of the bond.
          </li>
          <li>
            If one of your bonds is downgraded and but there is still no risk of losing money, then it is recommended to
            hold the bond until it becomes due, because you may have to sell it at a lower price and record losses.
          </li>
        </ul>

        <PublishedDate date={'03/09/2021'} />
      </div>

      <FeedbackBox source="/investment/bond/text/short/9-mar-2022/03" />
    </>
  );
};
