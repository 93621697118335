import React from 'react';
import '@brainhubeu/react-carousel/lib/style.css';

import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';

/*const getListOfCarouselPlugins = (numberOfSlides: number) => {
  return [
    'infinite',
    'centered',
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides,
      },
    },
    {
      resolve: arrowsPlugin,
      options: {
        arrowLeft: <div className={styles.leftArrow} />,
        arrowLeftDisabled: <div className={styles.leftArrow} />,
        arrowRight: <div className={styles.rightArrow} />,
        arrowRightDisabled: <div className={styles.rightArrow} />,
        addArrowClickHandler: true,
      },
    },
  ];
};*/

export const InsuranceGallery: React.FC = () => {
  return (
    <React.Fragment>
      <Spacer variant="red-spacer" />
      <Typography variant="h3">KNOWLEDGE</Typography>
      <Spacer variant="small" />
      <Typography variant="h1">Insurance Coverage</Typography>
      <Spacer variant="large" />

      <ThumbnailGallery apiUrl="sections/insurances/categories/" />
    </React.Fragment>
  );
};
