import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const SupportVideo: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Support</h1>

        <div className={styles.videoContainer}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://player.vimeo.com/video/706310071?h=df7a239608&color=FF0000&title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Add"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <p className={clsx(styles.bodyText, styles.last)}>
          Feel free to contact support for any assistance you may need. Expert and professional help is just a click
          away.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/04/2022'} />
      </div>

      <FeedbackBox source="/overview/support/video/short/04-may-2022/01" />
    </>
  );
};
