import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { GoalMilestones } from './types';
import { Table, dateFormatter, editIconFormatter, deleteIconFormatter, TableSortCaret } from '../../components/Table';
import styles from '../../components/Table/Table.module.scss';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import { getGoalList, deleteMilestone } from './goalSlice';

const PriorityHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Rank Milestones in order of your sequence or priority.</li>
        <br />
        <li>You can change the rankings as your priorities evolve.</li>
        <br />
        <li>SWAP Priorities by clicking the EDIT Button.</li>
        <br />
        <li>
          Example: You can change Priority 1 to Priority 2. Priority 2 will automatically become Priority 1. Similarly,
          you can SWAP Priority 2 and 3 and so on.
        </li>
      </ul>
    </>
  );
};

const DescriptionHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention your Milestone here.</li>
        <br />
        <li>Breakdown your goals into manageable pieces. </li>
        <br />
        <li>Keep the Description brief but clear.</li>
        <br />
        <li>Learn more in the Knowledge Section.</li>
      </ul>
    </>
  );
};

const TargetDateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Set a time here when you would like to complete this Milestone.</li>
        <br />
        <li>Be realistic in your estimate.</li>
        <br />
        <li>If needed, you can change it anytime.</li>
      </ul>
    </>
  );
};

const StatusHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Update the status on regular basis. </li>
        <br />
        <li>Mark it complete when the Milestone is accomplished. </li>
      </ul>
    </>
  );
};
export interface MilestoneTableProps {
  list: GoalMilestones;
}

const milestoneFormatter = (priority: string) => {
  return <span>Milestone {priority}</span>;
};

export const MilestoneTable: React.FC<MilestoneTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteMilestone(id);
    getGoalListAction();
  };

  const columns = [
    {
      dataField: 'priority',
      text: 'Priority',
      sort: true,
      sortCaret: TableSortCaret,
      helpText: <PriorityHelp />,
      formatter: milestoneFormatter,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      sortCaret: TableSortCaret,
      helpText: <DescriptionHelp />,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'target_date',
      text: 'Target Date',
      helpText: <TargetDateHelp />,
      formatter: dateFormatter('MMM dd, yyyy'),
      sort: true,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      sortCaret: TableSortCaret,
      helpText: <StatusHelp />,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/goals/goal/edit/milestone/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
