import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import infoImg from '../common/images/info.svg';
import HelpBox from '../components/Common/HelpBox';

export const InteracETransferAutoDeposit: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Interac e-Transfer Auto Deposit
          <HelpBox
            placement="right"
            content={
              <>
                <p className="mb-1">Source</p>
                <p className="mb-0">Interac</p>
              </>
            }
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <h3 className={styles.paraHeading}>What is e-Transfer Auto Deposit</h3>

        <p className={styles.bodyText}>
          You can receive money sent to you via Interac e-Transfer automatically deposited into your bank account,
          without having to formally accept the funds and entering any password.
        </p>

        <h3 className={styles.paraHeading}>Benefits</h3>

        <p className={styles.bodyText}>Interac e-Transfer Auto Deposit has quickly gained popularity because it is</p>

        <ol className={styles.list}>
          <li>Secure.</li>
          <li>Automatic.</li>
          <li>Fast and super easy to manage money.</li>
        </ol>

        <h3 className={styles.paraHeading}>Steps to Set Up</h3>

        <ol className={styles.list}>
          <li>Log into your online banking or mobile app.</li>
          <li>Look for Auto Deposit in the Interac e-Transfer section.</li>
          <li>Register your email address and link your account.</li>
          <li>Click “Complete Registration” in the confirmation email.</li>
        </ol>

        <p className={styles.bodyText}>
          That completes the setup process. The added security is now enabled. You can enjoy the ease of Auto Deposit.
        </p>

        <h3 className={styles.paraHeading}>Change Setup</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          You can change your setups anytime at your convenience from the Settings Tab.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/31/2022'} />
      </div>

      <FeedbackBox source="/transaction/bank-transactions/text/short/31-mar-2022/01" />
    </>
  );
};
