import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { BillFormInputs } from './types';
import { DatePicker, Form, Input, Select, SubmitButton } from '../../components/Form';

import { billingCycleOptions } from './constants';

const schemaResolver = yupResolver<BillFormInputs>(
  yup.object().shape({
    sector: yup.string().required('please enter a sector'),
    institution: yup.string().required('please enter a provider'),
    amount: yup.number().min(0).typeError('please enter a number').required('please enter an amount'),
    billing_cycle: yup.string().required('please choose a billing cycle'),
    last_due_date: yup.date().required('please choose a last due date'),
  }),
);

export type BillFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<BillFormInputs>>;
  onSubmit: SubmitHandler<BillFormInputs>;
};

export const BillForm: React.FC<BillFormProps> = ({ defaultValues = {}, onSubmit }) => {
  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <Input name="sector" placeholder="Sector" label="Sector" />
        <Input name="institution" placeholder="Provider" label="Provider" />
        <Input name="amount" placeholder="Amount" label="Amount" prefix="$" />
        <DatePicker name="last_due_date" placeholder="Last Due Date" />
        <Select name="billing_cycle" options={billingCycleOptions} placeholder="Billing Cycle" />

        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
