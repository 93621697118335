import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { HomeMaintenances } from './types';
import { Table, dateFormatter, editIconFormatter, deleteIconFormatter, TableSortCaret } from '../../components/Table';
import styles from '../../components/Table/Table.module.scss';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import { deleteMaintenance, getMaintenanceList } from './homeSlice';

const DescriptionHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Enter here the upcoming home maintenance items. </li>
        <br />
        <li>Learn more in the Knowledge Section</li>
      </ul>
    </>
  );
};

const AmountHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Estimate approximate cost for each item.</li>
        <br />
        <li>Do not underestimate. Add 5-10% cushion to avoid shortfall.</li>
      </ul>
    </>
  );
};

const TargetDateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Enter the target date of the maintenance. </li>
        <br />
        <li>It does not have to be exact. A best guess at this point will serve the purpose. </li>
        <br />
        <li>Learn more in the Knowledge Section.</li>
      </ul>
    </>
  );
};

export interface MaintenanceTableProps {
  list: HomeMaintenances;
}

const maintenanceFormatter = (priority: string) => {
  return <span>{priority}</span>;
};

export const MaintenanceTable: React.FC<MaintenanceTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getMaintenanceListAction = useCallback(() => dispatch(getMaintenanceList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteMaintenance(id);
    getMaintenanceListAction();
  };

  const columns = [
    {
      dataField: 'description',
      text: 'Description',
      helpText: <DescriptionHelp />,
      formatter: maintenanceFormatter,
      sort: true,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'target_price',
      text: 'Amount',
      sort: true,
      helpText: <AmountHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'target_date',
      text: 'Target Date',
      helpText: <TargetDateHelp />,
      formatter: dateFormatter('MMM dd, yyyy'),
      sort: true,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/asset/home/edit/maintenance/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
