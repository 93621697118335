import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Modal } from '../../components/Modal';
import { TaxDeductibleForm } from './TaxDeductibleForm';
import { TaxDeductibleFormInputs } from './types';
import { createTaxDeductible, getTaxDeductibleList } from './taxSlice';
import { BadRequestError } from '../../helpers/api';

export const AddTaxDeductible: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getDeductibleListAction = useCallback(() => dispatch(getTaxDeductibleList()), [dispatch]);

  const onSubmit = async (data: TaxDeductibleFormInputs) => {
    try {
      await createTaxDeductible(data);
      history.push('/income/tax');
      getDeductibleListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Tax Deductible']}>
      <TaxDeductibleForm onSubmit={onSubmit} />
    </Modal>
  );
};
