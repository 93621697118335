import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const SoftInquiryvsHardInquiry: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Soft Inquiry vs. Hard Inquiry</h1>

        <h3 className={styles.paraHeading}>Soft Inquiry</h3>
        <ul className={styles.list}>
          <li>Soft inquiries do not hurt your credit score.</li>
          <li>Soft inquiry also known as “Soft Pull” or “Soft Credit Check”.</li>
          <li>
            Soft inquiries incur when
            <ul className={styles.subList} style={{ listStyle: 'circle' }}>
              <li>you inquire your credit score</li>
              <li>one of your current creditors checks your credit score</li>
              <li>a creditor inquires when you apply for a soft-pull pre-approval</li>
              <li>a creditor inquires to qualify you for a pre-approved offer.</li>
            </ul>
          </li>
          <li>Soft inquiries do not reflect on you as a potentially risky borrower.</li>
          <li>
            Soft inquiry entails the lender to do a high-level review of your financial history, so that they can
            pre-approve certain offers, or show you what you could potentially be eligible for.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Hard Inquiry</h3>
        <ul className={styles.list}>
          <li>Hard inquiry occurs when the lender checks the credit score as a part of the credit approval process.</li>
          <li>This is also known as “Hard Pull”.</li>
          <li>
            Hard inquiry checks how you have handled your credit in the past, whether you paid your debts or bills on
            time, or are there any derogatory mark on credit report.
          </li>
          <li>Hard inquiry is made when you apply for a credit facility like a mortgage, loan, or credit card.</li>
          <li>You need to pre-authorize the lenders to make a hard inquiry in your name.</li>
          <li>One hard inquiry may slightly reduce your credit score.</li>
          <li>Many hard queries in a short span of time can significantly hurt your credit score.</li>
          <li>
            Multiple hard inquiries in a brief period may lead potential lenders to red flag you as a high-risk
            applicant, as they often infer that you could potentially be short of cash or planning to borrow a hefty sum
            of money.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Record Retention</h3>
        <ul className={styles.list}>
          <li>
            Both hard and soft inquiries remain listed on your credit report for up to two years.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/23/2022'} />
      </div>

      <FeedbackBox source="/credit-score/key-terms/text/short/23-jun-2022/01" />
    </>
  );
};
