import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { CreditScoreForm } from './CreditScoreForm';
import { CreditScoreFormInputs } from './types';
import { updateCreditScore, getCreditScoreList } from './creditScoreSlice';
import { BadRequestError } from '../../helpers/api';
import { selectCreditScoreById, selectListLoading } from './creditScoreSlice';

export const EditCreditScore: React.FC = () => {
  let { creditScoreId } = useParams();
  creditScoreId = parseInt(creditScoreId, 10);
  const creditScoreItem = useSelector(selectCreditScoreById(creditScoreId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const onSubmit = async (data: CreditScoreFormInputs) => {
    try {
      await updateCreditScore(creditScoreId, data);
      history.push('/loan/credit-score');
      getCreditScoreListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Credit Score']}>
      <CreditScoreForm defaultValues={creditScoreItem} onSubmit={onSubmit} />
    </Modal>
  );
};
