import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';

export const ContactSupport: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Contact Support</h1>

        <ol className={styles.list}>
          <li>You are never alone. Support is just a click away.</li>
          <li>Contact support for assistance. Create a Support Ticket.</li>
          <li>Use the Comment Box in any article to highlight any missing information.</li>
          <li>
            In Support Tickets, select the appropriate topic from the dropdown menu for faster processing and response.
          </li>
          <li>Request assistance for any information, which you can&apos;t find or don&apos;t understand.</li>
          <li>
            Be sympathetic to other&apos;s needs. Frequent unnecessary requests may hinder others from getting urgent
            support.
          </li>
        </ol>

        <PublishedDate date={'09/19/2021'} />
      </div>

      <FeedbackBox source="/user-account/support/text/short/19-sep-2021/01" />
    </>
  );
};
