import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import {
  getTaxList,
  selectListLoading,
  selectTaxList,
  getTaxDeductibleList,
  selectTaxDeductibleByYear,
} from './taxSlice';
import { TaxTable } from './TaxTable';
import { TaxDeductibleTable } from './TaxDeductibleTable';
import clsx from 'clsx';
import styles from './TaxList.module.scss';
// import { CreditFactorTable } from './CreditFactorTable';

export function TaxList(): JSX.Element {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const dispatch = useDispatch();
  const getTaxListAction = useCallback(() => dispatch(getTaxList()), [dispatch]);
  const getTaxDeductibleListAction = useCallback(() => dispatch(getTaxDeductibleList()), [dispatch]);

  const taxList = useSelector(selectTaxList);
  const listLoading = useSelector(selectListLoading);

  const selectedTaxDeductible = useSelector(selectTaxDeductibleByYear(selectedYear));

  useEffect(() => {
    getTaxListAction();
    getTaxDeductibleListAction();
  }, [getTaxListAction, getTaxDeductibleListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />

        <div className="d-flex align-items-center">
          <Typography variant="h1">Taxes</Typography>
          <ModalLinkButton to="/income/tax/add/tax" className="ml-3">
            Add
          </ModalLinkButton>
        </div>
        <TaxTable list={taxList} />
        <Spacer variant="gray-spacer" />
        <div className="d-flex align-items-center">
          <Typography variant="h2">Tax Deductible Expenses</Typography>
          <ModalLinkButton to="/income/tax/add/tax-deductible/" className="ml-3">
            Add
          </ModalLinkButton>
        </div>
        <Spacer variant="medium" />
        {[currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4].map((year) => (
          <React.Fragment key={year}>
            {year !== currentYear ? <div className={styles.taxBlockSpacing} /> : null}
            <span
              onClick={() => setSelectedYear(year)}
              className={clsx(styles.taxBlock, { [styles.taxBlockSelected]: selectedYear === year })}
            >
              {year}
            </span>
          </React.Fragment>
        ))}
        <TaxDeductibleTable list={selectedTaxDeductible} />
        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Managing Taxes</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/taxes/categories/" />
      </Col>
    </Row>
  );
}
