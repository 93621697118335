import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhenCreditScoreIsUsed: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>When Credit Score is Used</h1>

        <p className={styles.bodyText}>Credit Score is generally used in the following scenarios:</p>

        <ol className={styles.list}>
          <li>
            <strong>Banks, Retailers and Financial Institutions</strong> use Credit Score prior to issuing new credit or
            renewing existing credit facilities. They use Credit Score to understand applicant’s past payment behaviour
            and overall debt position to assess their future debt capacity and probability of recovering the credit.
          </li>
          <li>
            <strong>Car Leasing Companies</strong> also look up Credit Score prior to approving the lease. They are
            apparently not very stringent on the Credit Score criteria since the lease is secured by the asset they are
            going to lease (vehicle) and it largely depends on the cost of the vehicle and down payment.
          </li>
          <li>
            <strong>Mobile Phone Companies</strong> use Credit Score prior to accepting a new subscriber to assess how
            likely the applicant will pay the phone bills on time and ultimately be a good standing long-term client.
          </li>
          <li>
            <strong>Insurance Companies</strong> use Credit Score prior to issuing new policies to its prospects.
            Consumers with the lowest credit scores with everything being equal may pay two, three or four times more
            premium compared to those with the best credit scores.
          </li>
          <li>
            <strong>Government Agencies</strong> like Public Services and Procurement Canada (PSPC) has implemented
            mandatory Credit Score checks to determine an individual’s reliability, particularly their ability to meet
            financial obligations.
          </li>
          <li>
            <strong>Employers</strong> check Credit Score prior to hiring to see the late payment history, way of
            handling personal finances or any financial distress to determine the nature of the job applicant.
          </li>
          <li>
            <strong>Landlords</strong> review Credit Score prior to signing a rental agreement to check rental payment
            history, previous rental defaults or litigations in addition to other credit history and insolvency status
            to determine the potential tenant’s ability to pay the rent regulalry.
          </li>
          <li>
            <strong>Online Dating Sites</strong> use Credit Score to match their Users. It is increasingly becoming a
            mandatory field in the User’s Profile in these dating websites as more Users want to know about the
            financial life of their potential mates.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'03/31/2022'} />
      </div>

      <FeedbackBox source="/creditscore/impact-on-bottom-line/text/short/31-mar-2022/01" />
    </>
  );
};
