import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const SavingAndInvestment: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Saving and Investment</h1>

        <h2 className={clsx(styles.subHeading)}>Capital Gains</h2>
        <p className={styles.bodyText}>50% of the Capital Gain is taxable.</p>

        <h2 className={clsx(styles.paraHeading)}>Tax Free Savings Account (TFSA)</h2>
        <p className={styles.bodyText}>Annual Contribution Limit remains at $6,000 for 2022.</p>
        <p className={styles.bodyText}>
          Cumulative TFSA Limit as at January 01, 2022 is $81,500 for those who were at least 18 years old when the TFSA
          was introduced in 2009.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Registered Retired Savings Plan (RRSP)</h2>
        <p className={styles.bodyText}>
          For 2021, maximum RRSP Contribution Limit is $27,830 (for those who had income of $154,611 or more in year
          2020).
        </p>
        <p className={styles.bodyText}>
          For 2022, maximum RRSP Contribution Limit is $29,210 (for those who had income of $162,278 or more in year
          2021).
        </p>
        <p className={styles.bodyText}>
          If an individual is a member of a Registered Pension Plan (RPP), then her RRSP Contribution Limit is reduced
          by the prior year’s Pension Adjustment (PA).
        </p>
        <p className={styles.bodyText}>
          <strong>Deadline:</strong> The deadline to contribute for 2021 RRSP is March 01, 2022. Any contribution made
          after this date cannot be claimed in 2021 Tax Return for tax deductions.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Investment Loans</h2>
        <p className={styles.bodyText}>
          Prescribed interest rate on investment loans by Canadian Revenue Agency (CRA) is 1%. This is at all time low
          level.
        </p>
        <p className={styles.bodyText}>
          For those, who have taxable investment income and is at a higher tax bracket than their spouse or children, it
          is better to shift the investment income to their spouse or children so that it is reported on the tax return
          of their spouse or children and taxed at a lower rate.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'01/23/2022'} />
      </div>

      <FeedbackBox source="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/03" />
    </>
  );
};
