import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { TaxItems } from './types';
import {
  Table,
  priceFormatter,
  numberFormatter,
  editIconFormatter,
  deleteIconFormatter,
  uploadIconFormatter,
  TableSortCaret,
} from '../../components/Table';
import styles from '../../components/Table/Table.module.scss';
import { getTaxList, deleteTax } from './taxSlice';
import { uploadFile } from '../../helpers/api';

export interface TaxTableProps {
  list: TaxItems;
}

export const TaxTable: React.FC<TaxTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getTaxListAction = useCallback(() => dispatch(getTaxList()), [dispatch]);
  const deleteEntityAction = async (id: number) => {
    await deleteTax(id);
    getTaxListAction();
  };

  const fileUpload = (row: any, f: FileList | null) => {
    if (f) {
      uploadFile(`taxes/${row['id']}/files/`, f[0]);
    }
  };

  const columns = [
    {
      dataField: 'year',
      text: 'Tax Year',
      sort: true,
      // formatter: dateFormatter('MMM yyyy'),
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'taxable_income',
      text: 'Taxable Income',
      sort: true,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'tax_payable',
      text: 'Tax Payable',
      sort: true,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'tax_rate',
      text: 'Tax Rate',
      sort: true,
      formatter: numberFormatter,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'tax_refund',
      text: 'Tax Refund',
      sort: true,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'upload',
      text: 'File',
      sort: false,
      formatter: (cell: any, row: any) => uploadIconFormatter(row, fileUpload),
      classes: styles.uploadIconColumn,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/income/tax/edit/tax/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
