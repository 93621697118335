import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const IncomeTaxRefund: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Income Tax Refund</h1>

        <h2 className={styles.paraHeading}>Time to Receive Refund</h2>

        <p className={styles.bodyText}>
          Generally, CRA (Canada Revenue Agency) send your tax refund within following timeframe, only if you filed your
          return on or before the due date:
        </p>

        <ul className={styles.list}>
          <li>2 weeks of filing online return or</li>
          <li>8 weeks of filing paper or offline return.</li>
        </ul>

        <h2 className={styles.paraHeading}>Delays to Receive Refund</h2>

        <p className={styles.bodyText}>
          Refund might take longer time due to non-filing of return before the deadline. Other reasons of getting tax
          refund after above timelines of 2 and 8 weeks are:
        </p>

        <ul className={styles.list}>
          <li>You live outside Canada and filed a non-resident personal tax return.</li>
          <li>CRA selected your return for detailed review.</li>
        </ul>

        <h2 className={styles.paraHeading}>Direct Deposit</h2>

        <p className={styles.bodyText}>Using Direct Deposit may help to get the refund faster.</p>

        <h2 className={styles.paraHeading}>Not Receiving Refund</h2>

        <p className={styles.bodyText}>Reasons of not receiving tax refund:</p>
        <ul className={styles.list}>
          <li>Having any outstanding GST/HST returns from partnership or sole proprietorship.</li>
          <li>
            Having any other federal or provincial outstanding dues such as student loans, insurance, immigration loan
            etc.
          </li>
          <li>Tax refund receivable of $2 or less</li>
        </ul>

        <h2 className={styles.paraHeading}>Receiving Less Refund</h2>

        <p className={styles.bodyText}>Reasons of receiving refund less than the refund claimed:</p>
        <ul className={styles.list}>
          <li>Adjustment of taxes outstanding from previous year.</li>
          <li>
            Adjustments due to tax computation differences such as adjustments to certain credits and deductions, which
            are not allowable as per tax laws.
          </li>
        </ul>

        <h2 className={styles.paraHeading}>Interest on Tax Refund</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          CRA pays compounded interest on tax refund on the basis of number of days.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/29/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-refund/text/short/29-may-2022/01" />
    </>
  );
};
