import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const RRSPQualifiedInvestments: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>RRSP - Qualified Investments</h1>

        <p className={styles.bodyText}>
          Only few qualified investment instruments are permitted in the government registered plans like RRSP,
          according to the requirements set by Canada Revenue Agency (CRA) and Department of Finance, Government of
          Canada.
        </p>

        <p className={styles.bodyText}>
          Having investments in RRSP, which are not qualified may have serious tax implications and could result in
          significant penalties from CRA.
        </p>

        <h3 className={styles.paraHeading}>What is a Qualified Investment?</h3>
        <p className={styles.bodyText}>
          To be a Qualified Investment, the security must trade in at least one of the Designated Stock Exchange like
          Toronto Stock Exchange, TSX Venture Exchange, New York Stock Exchange, NASDAQ, London Stock Exchange etc.
        </p>
        <p className={styles.bodyText}>
          Currently there are about 46 Stock Exchanges worldwide, which qualify as Designated Stock Exchange according
          to Department of Finance, Government of Canada.
        </p>
        <p className={styles.bodyText}>
          If a security trades only on Over the Counter (OTC) markets, then it is a not a Qualified Investment. However,
          if the security trades on Over the Counter (OTC) markets as well as on a Designated Exchange, then it may be
          considered qualified.
        </p>

        <h3 className={styles.paraHeading}>Steps to Take If You Have Non-Qualified Investments in RRSP</h3>
        <p className={styles.bodyText}>
          Ask your financial institution to immediately remove the security from your RRSP, by taking one of the
          following steps:
        </p>
        <ol className={styles.list}>
          <li>Sell the security.</li>
          <li>Transfer the security to a non-registered account.</li>
          <li>Remove it from Registered Plan if the security is worthless.</li>
        </ol>

        <h3 className={styles.paraHeading}>Support and Help</h3>
        <ul className={styles.list}>
          <li>
            <a
              href="https://www.canada.ca/en/department-finance/services/designated-stock-exchanges.html"
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>{' '}
            for the full list of the designated Stock Exchange.
          </li>
          <li>
            Call Canada Revenue Agency (CRA) at 1-800-959-8281.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'05/10/2022'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/10-may-2022/01" />
    </>
  );
};
