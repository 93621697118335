import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AllElectricBy2030: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>All Electric by 2030</h1>

        <h3 className={styles.paraHeading}>Mercedes-Benz</h3>
        <h2 className={styles.paraSubHeading}>Goes All-Electric by 2030</h2>

        <p className={styles.bodyText}>
          On September 30, 2021, Mercedes-Benz announced that it targets Plug-in Hybrid (PHEVs) and Battery-Electric
          Vehicles (BEVs), to account for 50% of its global volume by 2025.
        </p>
        <p className={styles.bodyText}>
          This is double of the target Mercedes-Benz had initially announced two years ago.
        </p>
        <p className={styles.bodyText}>By 2030, Mercedes will switch entirely to Battery-Electric Vehicles (BEVs).</p>
        <p className={styles.bodyText}>
          Only exception could be some small markets where conditions such as lack of charging stations would make that
          difficult.
        </p>

        <h3 className={styles.paraHeading}>General Motors</h3>
        <h2 className={styles.paraSubHeading}>Goes All-Electric by 2035</h2>
        <p className={styles.bodyText}>
          General Motors plans to completely phase out vehicles using internal combustion engines by 2035, the Company
          announced on January 28, 2021.
        </p>
        <p className={styles.bodyText}>
          General Motors will be fully carbon neutral at all facilities worldwide by 2035.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          The Company is launching 30 new plug-in models by 2025 and committed $27 billion new investment.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/06/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-I/text/short/06-jul-2022/02" />
    </>
  );
};
