import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const NoMoreDailyToDoList: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>No More Daily To-Do List</h1>

        <p className={styles.bodyText}>
          Ideally, there should be no “Daily To-Do List”, although most productivity experts recommend it. Including
          such a list in the daily Calendar are not effective for two reasons:
        </p>

        <h3 className={styles.paraHeading}>First Reason</h3>

        <ul className={styles.list}>
          <li>
            It is almost impossible to setup a predetermined list of daily priorities beforehand in today’s
            fast-changing environment where new priorities are surfacing every hour.
          </li>
          <li>
            It is helpful to have a comprehensive game plan, but it must have enough flexibility to be adjusted during
            the day depending on the newly surfaced priorities.
          </li>
          <li>
            Trying to keep a written list in the Calendar which must be rewritten next day for the items not done is a
            waste of time and very discouraging. This practice also gives your mind the permission to roll the
            incomplete tasks over and over.
          </li>
          <li>
            Instead, all of the To-Do list items, including the most time sensitive ones, should go to the “Next Action”
            list. These don’t have to be rewritten daily and would have the flexibility to be re-prioritized depending
            on the evolving situation.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Second Reason</h3>

        <ul className={styles.list}>
          <li>
            The priorities get diluted if the daily To-Do list includes items which not necessarily have to be done that
            day.
          </li>
          <li>
            If you have to make a repayment of my Credit Card Bill by today, and you mix it with five other less
            important tasks with it then, it is not the best use of the system.
          </li>
          <li>
            A part of your brain has to remain active to ensure you distinguish the must To-Do credit card payment from
            rest of the less time sensitive tasks.
          </li>
          <li>
            The calendar should be a scared territory. If something is mentioned in the Calendar, then it must be done
            that day or not at all.
          </li>
          <li>
            Only rewriting in the Calendar should be for the appointments which are rescheduled.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/28/2022'} />
      </div>

      <FeedbackBox source="/calendar/organizing-tools/text/short/28-jun-2022/01" />
    </>
  );
};
