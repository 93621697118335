import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CreditScoreItems, CreditScoreItem } from './types';
import {
  Table,
  dateFormatter,
  editIconFormatter,
  deleteIconFormatter,
  uploadIconFormatter,
  TableSortCaret,
} from '../../components/Table';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import styles from '../../components/Table/Table.module.scss';
import { getCreditScoreList, deleteCreditScore } from './creditScoreSlice';

const CreditScoreHelp = () => {
  return (
    <>
      {/* <h6 className="font-weight-bold">Credit Score Map</h6> */}
      <ul className="mb-0">
        <li>800 to 900 : Excellent​</li>
        <br />
        <li>720 to 799 : Very Good​</li>
        <br />
        <li>650 to 719 : Good</li>
        <br />
        <li>600 to 649 : Fair​</li>
        <br />
        <li>300 to 599 : Poor​</li>
      </ul>
    </>
  );
};

const DateHelp = () => {
  return (
    <>
      <h6 className="mb-0">Date of the Credit Report</h6>
    </>
  );
};

const ProviderHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention here the name of the Credit Agency providing the Credit Score.</li>
        <br />
        <li>There are only two reputed Compnaies : Equifax and Transunion. </li>
        <br />
        <li>Select one from the Dropdown Menu. </li>
      </ul>
    </>
  );
};

const RatingHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>No input is required here.</li>
        <br />
        <li>Equivalent Credit Rating will be automatically calculated and displayed here.</li>
      </ul>
    </>
  );
};

const FileHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Upload a PDF File of the Credit Report here, if available. </li>
        <br />
        <li>It is a good idea to safe keep a copy for future reference. </li>
        <br />
        <li>Maximum File Size is 16 MB.</li>
      </ul>
    </>
  );
};

export interface CreditScoreTableProps {
  list: CreditScoreItems;
}

export const CreditScoreTable: React.FC<CreditScoreTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteCreditScore(id);
    getCreditScoreListAction();
  };

  const fileUpload = (row: any, f: FileList | null) => {
    console.log(row, f);
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      helpText: <DateHelp />,
      formatter: dateFormatter('MMM yyyy'),
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'provider',
      text: 'Provider',
      sort: true,
      helpText: <ProviderHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'credit_score',
      text: 'Credit Score',
      sort: true,
      sortCaret: TableSortCaret,
      helpText: <CreditScoreHelp />,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'rating',
      text: 'Rating',
      helpText: <RatingHelp />,
      formatter: (cell: string, row: CreditScoreItem) => {
        switch (true) {
          case row.credit_score >= 800:
            return 'Excellent';
          case row.credit_score >= 720 && row.credit_score <= 799:
            return 'Very good';
          case row.credit_score >= 650 && row.credit_score <= 719:
            return 'Good';
          case row.credit_score >= 600 && row.credit_score <= 649:
            return 'Fair';
          default:
            return 'Poor';
        }
      },
      sort: true,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'upload',
      text: 'File',
      sort: false,
      helpText: <FileHelp />,
      formatter: (cell: any, row: any) => uploadIconFormatter(row, fileUpload),
      classes: styles.uploadIconColumn,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/loan/credit-score/edit/credit-score/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
