import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhoIsInterac: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Who is Interac</h1>

        <p className={styles.bodyText}>
          Historically, there have been two providers of different Interac-branded products and services, Interac
          Association and Acxsys Corporation.
        </p>

        <h3 className={styles.paraHeading}>Interac Association</h3>

        <ul className={styles.list}>
          <li>Founded in 1984.</li>
          <li>A cooperative venture originally created by Canada’s major financial institutions.</li>
          <li>Goal is to give Canadians broader access to their money through a single shared network.</li>
          <li>Products launched were Interac Debit ABM withdrawals, Chip and PIN, and contactless payments.</li>
        </ul>

        <h3 className={styles.paraHeading}>Acxsys Corporation</h3>

        <ul className={styles.list}>
          <li>Founded in 1996 by eight financial institutions.</li>
          <li>They built the Interac network to develop new business partnerships and services.</li>
          <li>They built the Interac network to develop new business partnerships and services.</li>
        </ul>

        <p className={styles.bodyText}>The above two separate entities were eventually combined to join forces.</p>

        <h3 className={styles.paraHeading}>Interac Corp.</h3>

        <ul className={styles.list}>
          <li>In 2018, Interac Association and Acxsys Corporation merged into one Company.</li>
          <li>The name of the new Company is Interac Corp.</li>
          <li>Interac Corp. brought all the Interac-branded products and services together under a single entity.</li>
          <li>
            As a single corporate entity, Interac Corp. is better able to fund innovation, while reducing certain
            administrative, operating, and legal complexities.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/28/2022'} />
      </div>

      <FeedbackBox source="/transaction/basics/text/short/28-jun-2022/01" />
    </>
  );
};
