import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { MilestoneForm } from './MilestoneForm';
import { GoalItems, MilestoneFormInputs } from './types';
import {
  updateMilestone,
  getGoalList,
  selectListLoading,
  selectMilestoneById,
  selectOnlyCompleteGoals,
  updateMilestonePriority,
} from './goalSlice';
import { BadRequestError } from '../../helpers/api';

export const getMilestonesByGoalId = (goalId: number) => (goals: GoalItems) => {
  const foundGoal = goals.find((goalItem) => {
    return goalItem.id === goalId;
  });

  if (!foundGoal) {
    return [];
  }

  return foundGoal.milestones;
};

export const EditMilestone: React.FC = () => {
  let { milestoneId } = useParams();
  milestoneId = parseInt(milestoneId, 10);
  const milestoneItem = useSelector(selectMilestoneById(milestoneId));
  const listLoading = useSelector(selectListLoading);
  const activeGoals = useSelector(selectOnlyCompleteGoals(false));

  const history = useHistory();
  const dispatch = useDispatch();
  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const onSubmit = async (data: MilestoneFormInputs) => {
    try {
      await updateMilestone(milestoneId, data);

      // update already existing milestones if needed

      const activeMilestones = getMilestonesByGoalId(+data.goal)(activeGoals);

      const alreadyExistingMilestone = activeMilestones.find((goal) => goal.priority === data.priority);
      const nonUpdatedMilestone = activeMilestones.find((goal) => goal.id === milestoneId);

      if (alreadyExistingMilestone && nonUpdatedMilestone) {
        await updateMilestonePriority(alreadyExistingMilestone.id, nonUpdatedMilestone.priority);
      }

      history.push('/goals/goal');
      getGoalListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  if (!milestoneItem) {
    return <div>Milestone not found</div>;
  }

  return (
    <Modal title={['Edit', 'Milestone']}>
      <MilestoneForm defaultValues={milestoneItem} onSubmit={onSubmit} />
    </Modal>
  );
};
