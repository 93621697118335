import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import { AlertItems } from './types';
import {
  doGetRequest,
  doDeleteRequest,
  doMultiplePostRequest,
  doMultipleDeleteRequest,
  doPostRequest,
} from '../../helpers/api';

export interface AlertState {
  unreadList?: AlertItems;
  list: AlertItems;
  listLoading: boolean;
  isAlertDeleted: boolean;
  isFlagToggled: boolean;
  isReadFlagChanged: boolean;
  error: string | null;
}

const initialState: AlertState = {
  unreadList: [],
  list: [],
  listLoading: false,
  isAlertDeleted: false,
  isFlagToggled: false,
  isReadFlagChanged: false,
  error: null,
};

export const getAlertsList = createAsyncAction('GET_ALERTS_LIST', async (filters?: any) => {
  let url = 'alerts/';
  if (filters) {
    url += '?' + new URLSearchParams(filters).toString();
  }
  return await doGetRequest(url);
});

export const getUnReadAlerts = createAsyncAction('GET_UNREAD_ALERTS_LIST', async () => {
  const url = 'alerts/?show=unread';
  return await doGetRequest(url);
});

export const toggleAlertFlag = createAsyncAction('TOGGLE_ALERT_FLAG', async (alertId: string) => {
  return await doPostRequest(`alerts/${alertId}/flag/`, {});
});

export const toggleAllAlertsFlag = createAsyncAction(
  'TOGGLE_MULTIPLE_ALERTS_FLAG',
  async (alertIds: Array<string | number>) => {
    const urls: Array<string> = [];
    for (let index = 0; index < (alertIds || []).length; index++) {
      const alertId = alertIds[index];
      urls.push(`alerts/${alertId}/flag/`);
    }

    return await doMultiplePostRequest(urls, {});
  },
);

export const deleteAlert = createAsyncAction('DELETE_ALERT', async (alertId: string) => {
  return await doDeleteRequest(`alerts/${alertId}/delete/`);
});

export const deleteMultipleAlerts = createAsyncAction('DELETE_ALL_ALERTS', async (alertIds: Array<string | number>) => {
  const urls: Array<string> = [];
  for (let index = 0; index < (alertIds || []).length; index++) {
    const alertId = alertIds[index];
    urls.push(`alerts/${alertId}/flag/`);
  }

  return await doMultipleDeleteRequest(urls, {});
});

export const setReadFlag = createAsyncAction('SET_READ_FLAG', async (alertId: string) => {
  return await doPostRequest(`alerts/${alertId}/read/`, {});
});

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {},
  extraReducers: {
    //  get alerts
    [String(getAlertsList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getAlertsList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getAlertsList.fulfilled)]: (state, action: PayloadAction<AlertItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
        isAlertDeleted: false,
        isFlagToggled: false,
        error: null,
      };
    },

    [String(getUnReadAlerts.pending)]: (state) => {
      return {
        ...state,
      };
    },
    [String(getUnReadAlerts.rejected)]: (state) => {
      return {
        ...state,
      };
    },
    [String(getUnReadAlerts.fulfilled)]: (state, action: PayloadAction<AlertItems>) => {
      const list = action.payload;
      return {
        ...state,
        unreadList: list,
      };
    },

    // toggle alerts flag
    [String(toggleAlertFlag.pending)]: (state) => {
      return {
        ...state,
        isFlagToggled: false,
      };
    },
    [String(toggleAlertFlag.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isFlagToggled: false,
        error: action.payload || null,
      };
    },
    [String(toggleAlertFlag.fulfilled)]: (state) => {
      return {
        ...state,
        isFlagToggled: true,
      };
    },

    // delete alerts
    [String(deleteAlert.pending)]: (state) => {
      return {
        ...state,
        isAlertDeleted: false,
      };
    },
    [String(deleteAlert.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isAlertDeleted: false,
        error: action.payload || null,
      };
    },
    [String(deleteAlert.fulfilled)]: (state) => {
      return {
        ...state,
        isAlertDeleted: true,
      };
    },

    // read-unread flags
    [String(setReadFlag.pending)]: (state) => {
      return {
        ...state,
        isReadFlagChanged: false,
      };
    },
    [String(setReadFlag.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isReadFlagChanged: false,
        error: action.payload || null,
      };
    },
    [String(setReadFlag.fulfilled)]: (state) => {
      return {
        ...state,
        isReadFlagChanged: true,
      };
    },

    // toggle multiple alerts flag
    [String(toggleAllAlertsFlag.pending)]: (state) => {
      return {
        ...state,
        isFlagToggled: false,
      };
    },
    [String(toggleAllAlertsFlag.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isFlagToggled: false,
        error: action.payload || null,
      };
    },
    [String(toggleAllAlertsFlag.fulfilled)]: (state) => {
      return {
        ...state,
        isFlagToggled: true,
      };
    },

    // delete multiple alerts
    [String(deleteMultipleAlerts.pending)]: (state) => {
      return {
        ...state,
        isAlertDeleted: false,
      };
    },
    [String(deleteMultipleAlerts.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isAlertDeleted: false,
        error: action.payload || null,
      };
    },
    [String(deleteMultipleAlerts.fulfilled)]: (state) => {
      return {
        ...state,
        isAlertDeleted: true,
      };
    },
  },
});

export const selectUnreadAlertsList = (state: RootState) => state.alerts.unreadList;
export const selectAlertsList = (state: RootState) => state.alerts.list;
export const selectListLoading = (state: RootState) => state.alerts.listLoading;

export default alertsSlice.reducer;
