import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const RRSPVSTFSA2: React.FC = () => {
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'rrspTaxRateUnchanged',
      text: 'Tax Rate: Unhanged',
    },
    {
      dataField: 'tfsaTaxRateUnchanged',
      text: 'Tax Rate: Unhanged',
    },
    {
      dataField: 'rrspTaxRateIncreasing',
      text: 'Tax Rate: Increasing',
    },
    {
      dataField: 'tfsaTaxRateIncreasing',
      text: 'Tax Rate: Increasing',
    },
    {
      dataField: 'rrspTaxRateDecreasing',
      text: 'Tax Rate: Decreasing',
    },
    {
      dataField: 'tfsaTaxRateDecreasing',
      text: 'Tax Rate: Decreasing',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>RRSP VS TFSA</h1>
        <h2 className={styles.subHeading}>Tax Rate Assumptions</h2>

        <p className={styles.bodyText}>
          Assumption of your future tax rates can have an impact on your decision to choose between RRSP and TFSA.
        </p>

        <p className={styles.bodyText}>
          Following chart evaluates three sperate scenarios, where your future income rates are assumed to remain
        </p>

        <ol className={styles.list} type="a">
          <li>Unchanged,</li>
          <li>Increasing and</li>
          <li>Decreasing.</li>
        </ol>

        <SimpleTable
          keyField="rrsp"
          data={[
            {
              description: '',
              rrspTaxRateUnchanged: 'RRSP',
              tfsaTaxRateUnchanged: 'TFSA',
              rrspTaxRateIncreasing: 'RRSP',
              tfsaTaxRateIncreasing: 'TFSA',
              rrspTaxRateDecreasing: 'RRSP',
              tfsaTaxRateDecreasing: 'TFSA',
            },
            {
              description: 'Income Before Tax. Available to Invest',
              rrspTaxRateUnchanged: '$100',
              tfsaTaxRateUnchanged: '$100',
              rrspTaxRateIncreasing: '$100',
              tfsaTaxRateIncreasing: '$100',
              rrspTaxRateDecreasing: '$100',
              tfsaTaxRateDecreasing: '$100',
            },
            {
              description: 'Tax Rate: Current',
              rrspTaxRateUnchanged: '-',
              tfsaTaxRateUnchanged: '35%',
              rrspTaxRateIncreasing: '-',
              tfsaTaxRateIncreasing: '25%',
              rrspTaxRateDecreasing: '-',
              tfsaTaxRateDecreasing: '35%',
            },
            {
              description: 'Tax to be Paid',
              rrspTaxRateUnchanged: '-',
              tfsaTaxRateUnchanged: '$35',
              rrspTaxRateIncreasing: '-',
              tfsaTaxRateIncreasing: '$25',
              rrspTaxRateDecreasing: '-',
              tfsaTaxRateDecreasing: '$35',
            },
            {
              description: 'Actual Plan Contribution',
              rrspTaxRateUnchanged: '$100',
              tfsaTaxRateUnchanged: '$65',
              rrspTaxRateIncreasing: '$100',
              tfsaTaxRateIncreasing: '$75',
              rrspTaxRateDecreasing: '$100',
              tfsaTaxRateDecreasing: '$65',
            },
            {
              description: 'Return on Investment (5 %, 30 Years)',
              rrspTaxRateUnchanged: '$432',
              tfsaTaxRateUnchanged: '$281',
              rrspTaxRateIncreasing: '$432',
              tfsaTaxRateIncreasing: '$324',
              rrspTaxRateDecreasing: '$432',
              tfsaTaxRateDecreasing: '$281',
            },
            {
              description: 'Tax Rate: During Withdrawal',
              rrspTaxRateUnchanged: '35%',
              tfsaTaxRateUnchanged: '-',
              rrspTaxRateIncreasing: '35%',
              tfsaTaxRateIncreasing: '-',
              rrspTaxRateDecreasing: '25%',
              tfsaTaxRateDecreasing: '-',
            },
            {
              description: 'Tax to be paid During Withdrawal',
              rrspTaxRateUnchanged: '$151',
              tfsaTaxRateUnchanged: '-',
              rrspTaxRateIncreasing: '$151',
              tfsaTaxRateIncreasing: '-',
              rrspTaxRateDecreasing: '$108',
              tfsaTaxRateDecreasing: '-',
            },
            {
              description: 'Actual Cash at Hand',
              rrspTaxRateUnchanged: '$281',
              tfsaTaxRateUnchanged: '$281',
              rrspTaxRateIncreasing: '$281',
              tfsaTaxRateIncreasing: '$324',
              rrspTaxRateDecreasing: '$3248',
              tfsaTaxRateDecreasing: '$281',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={styles.paraHeading}>Conclusion</p>

        <ul className={styles.list}>
          <li>If you expect your future tax rates to increase, then TFSA would be a better option.</li>
          <li>
            If you expect your future tax rates to decrease, then RRSP would be a better option.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'05/07/2022'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/07-may-2022/04" />
    </>
  );
};
