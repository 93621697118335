import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import flowOfCash from '../common/images/flowOfCashInDiffAccounts.png';
import { PublishedDate } from './PublishedDate';

export const HowManyBankAccounts: React.FC = () => {
  const columns = [
    {
      dataField: 'sno',
      text: 'Sl. No.',
    },
    {
      dataField: 'account',
      text: 'Account',
    },
    {
      dataField: 'purpose',
      text: 'Purpose',
    },
    {
      dataField: 'use',
      text: 'Use',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>How Many Bank Accounts</h1>

        <p className={styles.bodyText}>How many Bank Accounts should you have?</p>

        <ul className={styles.list}>
          <li>It depends on your personal context. There is no magic number.</li>
          <li>Number and type of your Account selection will depend on your budget and goal.</li>
          <li>
            Number of accounts should not be too many that you lose track of them. It should also not be too few that
            all your transactions get mixed up and you are unable to categorize efficiently. Balance between these two
            extremes would be the key.
          </li>
          <li>
            Each of your active account should have a specific purpose and you should be crystal clear how would you
            like to use it.
          </li>
          <li>
            Since there are many free Accounts in the market to choose from, we recommend a separate account for each of
            your key items like income, expense, or investment, which would facilitate better organization,
            recordkeeping, tracking and above all visibility.
          </li>
          <li>
            We recommend choosing the best accounts from each category, irrespective of the Bank or Financial
            Institution. Arctic Rich takes care of the consolidation, monitoring and tracking part for you from a
            central place of your personal portal.
          </li>
          <li>
            Remember, neither your Bank nor any Bank Account is fixed. You should be ready switch to other Banks for
            better offers or building a stronger long-term relationship. For example, if a Bank is willing to approve a
            good Mortgage for you, then you should not hesitate to switch your salary, investment, or insurance accounts
            with them to sweeten the deal.
          </li>
          <li>
            Don&apos;t limit your options only within the Banks located in your neighbourhood. There are plenty of fine
            online providers. Look them up at the recommendation section at your Arctic Rich Portal.
          </li>
        </ul>

        <p className={styles.bodyText}>Here are some of the Accounts you may consider:</p>

        <SimpleTable
          keyField="id"
          data={[
            {
              sno: '1',
              account: 'Chequeing Account',
              purpose: 'Salary / Income Account',
              use: 'Main hub, distribute income.',
            },
            { sno: '2', account: 'RRSP', purpose: 'Retirment Saving', use: 'Long Term Saving.' },
            { sno: '3', account: 'TFSA', purpose: 'Retirement, Down Payment', use: 'Short, Medium, Long-Term Saving.' },
            { sno: '4', account: 'RESP', purpose: 'Tax Free Education Find', use: 'Continued / Children Education.' },
            { sno: '5', account: 'GIC', purpose: 'Down Payment Home, Car', use: 'Short, Medium-Term Saving' },
            {
              sno: '6',
              account: 'Saving Account',
              purpose: 'Save - 20% of Income',
              use: 'Monthly Saving. In Transit.',
            },
            {
              sno: '6',
              account: 'Saving Account',
              purpose: 'Frequent Expense Items',
              use: 'Grocery, Shopping, Travel, Gift',
            },
            {
              sno: '6',
              account: 'Saving Account',
              purpose: 'Occasional Expense Items',
              use: 'Combine 2+, Property Tax, Insurance',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={clsx(styles.paraHeading)}>Note</h2>

        <ol className={styles.list}>
          <li>
            While selecting the Chequeing or Saving Account, look up the number of free transactions allowed compared to
            the maximum number of transactions you may need for that purpose of the account.
          </li>
          <li>
            You may combine two or more expense items into one Saving Account, if each of these expense items occur
            occasionally, like once in a month, e.g. Property Tax, Home Insurance, Auto Insurance etc.
          </li>

          <p className={styles.bodyText}>Flow of cash into different accounts:</p>

          <div className={styles.imgContainer}>
            <img src={flowOfCash} alt="" width="500" />
          </div>

          <li>
            If the expenses are charged on annual or semi-annual or quarterly basis, then you can set aside a percent of
            the total every month into that account.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/17/2022'} />
      </div>

      <FeedbackBox source="/balance/bankaccounts/text/short/17-feb-2022/01" />
    </>
  );
};
