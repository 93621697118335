import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const EmployerPensionPlans: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Employer Pension Plans</h1>

        <p className={styles.bodyText}>
          There are two types of employer pension plans: g are also required to be included in the tax return.
        </p>

        <h3 className={styles.paraHeading}>Defined Benefit Plan</h3>

        <ol className={styles.list}>
          <li>Sponsored by the employer,</li>
          <li>Guaranteed to pay a specific amount to the retiree</li>
          <li>Contribution is made by both the employer and retiree</li>
          <li>Management of the Retirement Fund rests with the employer</li>
          <li>
            Amount payable is based on a simple formula:
            <p className={styles.listText}>
              2% X average salary during last 5 years prior to retirement X number of years enrolled in the plan
            </p>
          </li>
        </ol>

        <p className={styles.bodyText}>
          <strong>Example</strong>
        </p>

        <p className={styles.bodyText}>
          John is retiring in 2022. His average salary in last five had been $80,000. He is with the Company for last 25
          years and made regular contributions for his pension plan.
        </p>

        <p className={styles.bodyText}>John’s estimated Annual Pension will be:</p>

        <p className={styles.bodyText}>$40,000 = 0.02 X $80,000 X 25</p>

        <h3 className={styles.paraHeading}>Defined Contribution Plan</h3>

        <ol className={styles.list}>
          <li>Contribution is known.</li>
          <li>Contribution is made by both employer and employee</li>
          <li>
            Amount to be received during retirement is unknown and depends on the available funds invested from
            contributions.
          </li>
          <li>Retiree is responsible for investing the funds.</li>
          <li>Retiree can choose how to withdraw the funds.</li>
        </ol>

        <h3 className={styles.paraHeading}>Trends</h3>

        <p className={styles.bodyText}>
          Employers are gradually phasing out of the Defined Benefit Plan. With increasing number of Defined
          Contribution Plans, it is far more important than ever before for the plan holders to understand where they
          are investing and how it is going to impact their future.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Ownership and responsibility of the entire process now rests on the plan holders.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/03/2021'} />
      </div>

      <FeedbackBox source="/investment/basics/text/short/03-may-2022/01" />
    </>
  );
};
