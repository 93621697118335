import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const FactorsInfluencingPremium: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Factors Influencing Premium</h1>

        <p className={styles.bodyText}>
          Following are the factors which influence the Auto Insurance Premium the most. All insurance providers do not
          give the same weight or importance to these factors. Accordingly, their premiums may significantly vary for
          the same applicant.
        </p>

        <h3 className={styles.paraHeading}>1. Driving Record</h3>
        <p className={styles.bodyText}>
          The better your driving record, the lower would be your auto insurance premium. Accidents or serious traffic
          violation history will negatively impact on your insurance premium.
        </p>
        <h3 className={styles.paraHeading}>2. Kilometers Driven Annually</h3>
        <p className={styles.bodyText}>The more you drive, the more there are chances of accidents.</p>
        <h3 className={styles.paraHeading}>3. Nature of Use</h3>
        <p className={styles.bodyText}>
          Premiums would be higher, if the car is used for work or long commute compared to occasional use for pleasure.
        </p>
        <h3 className={styles.paraHeading}>4. Geographical Location</h3>
        <p className={styles.bodyText}>
          Urban locations have higher premium compared to smaller towns or rural areas due to higher rates of vandalism,
          thefts, and accidents. Factors like where you park (street or secured and covered parking), and anti-theft
          features installed in the car will also have impact on the premium.
        </p>
        <h3 className={styles.paraHeading}>5. Age</h3>
        <p className={styles.bodyText}>
          Mature drivers historically have fewer accidents compared to the less experienced drivers, particularly
          teenagers.
        </p>
        <h3 className={styles.paraHeading}>6. Driving Experience</h3>
        <p className={styles.bodyText}>Premiums would be higher if drivers aged 25 or younger drives your car.</p>
        <h3 className={styles.paraHeading}>7. The Car</h3>
        <p className={styles.bodyText}>
          Insurers pay attention to factors like value of the car, likelihood of theft, cost of repairs, safety record
          of the car, safety feature of the driver and occupants, chances of inflicting damage to other cars in case of
          a collusion etc.
        </p>
        <h3 className={styles.paraHeading}>8. Credit Score</h3>
        <p className={styles.bodyText}>
          Your scores indicate how likely you will pay the premiums regularly and on time. Better your credit score,
          lower would be your auto insurance premium.
        </p>
        <h3 className={styles.paraHeading}>9. Type of Coverage and Deductibles</h3>
        <p className={styles.bodyText}>
          The type insurance coverage (Basic or Comprehensive), and the amount of deductibles have significant impact on
          your insurance premium. High Credit score holders also tend to file for less insurance claims.
        </p>
        <h3 className={styles.paraHeading}>10. Insurance and Claims History</h3>
        <p className={styles.bodyText}>
          Track record of previous insurance policy coverages and particularly your claims history would also have an
          impact on your auto insurance premium.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/10/2022'} />
      </div>

      <FeedbackBox source="/insurance/auto-insurance/text/short/10-may-2022/01" />
    </>
  );
};
