import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { BarChart } from './BarChart';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const Time: React.FC = () => {
  const data = [
    {
      Year: 'Year 10',
      'Investment Value': 222,
      color: 'rgb(84,129,176)',
    },
    {
      Year: 'Year 20',
      'Investment Value': 854,
      color: 'rgb(84,129,176)',
    },
    {
      Year: 'Year 30',
      'Investment Value': 2646,
      color: 'rgb(84,129,176)',
    },
    {
      Year: 'Year 40',
      'Investment Value': 7728,
      color: 'rgb(84,129,176)',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Time</h1>

        <p className={styles.bodyText}>
          Time is the most important ally you can count on building your wealth. Like other forces of nature such as
          gravitation, it has no bias. It will work for you as hard as anybody else.
        </p>

        <p className={styles.bodyText}>
          All you have to do is begin saving as early as you can, no matter how little it is and invest it in the safest
          and most rewarding investment opportunity you can find.
        </p>

        <p className={styles.bodyText}>
          That’s all. Time will do the rest. You will continue to earn money, even while you are sleeping.
        </p>

        <p className={styles.bodyText}>
          If you haven’t started already, then don’t lose heart or look back. Simply roll your sleeves and begin today,
          right where you stand. It is never too late to start. As soon as you start, Time will get to work and begin to
          do its job – relentlessly grow your wealth.
        </p>

        <p className={styles.bodyText}>
          Let’s look at the following example. The bar chart exhibits investment value of a person who contributes
          $1,000 monthly for 40 Years in Dow Jones Industrial Average (DJIA) Index Funds and earns 10.99% per year,
          which is the 30-Year Historical Average Return of the Index.
        </p>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>Estimated Investment Value</h4>
          <h5 className={styles.chartSubHeader}>Figures in Thousand Dollars</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={data}
              keys={['Investment Value']}
              indexBy={'Year'}
              axisLeftLegendTitle={'Investment Value'}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 50, left: 70 },
                axisLeft: {
                  format: ',',
                },
                labelFormat: ',',
                tooltipFormat: ',',
                legends: [],
                padding: 0.6,
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
              }}
            />
          </div>
        </div>
        <p className={styles.chartSource}>Source: Dow Jones Industrial Average</p>

        <p className={styles.bodyText}>
          $7.73 million dollars would be the estimated value of your investments over 40 years, if you invest $1,000 a
          month between your age 25 and 65 in the Dow Jones Industrial Average, which had an average historical return
          of 10.99% over last 30 years.
        </p>

        <p className={styles.bodyText}>
          You will notice that, although you are investing the same amount of $1,000 every month, the most significant
          increase in the dollar value of $5.08 million was indeed in the last 10 years of your investment period, i.e.,
          between Year 30 and 40.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          If you start late, say at age 45 and invest for only 20 years, then you will retire with the balance of
          $854,124 only when you are 65. Even this modest return might be in question. The shorter your investment
          period, the higher you are exposed to the stock market volatility – making your outcome uncertain.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/02/2022'} />
      </div>

      <FeedbackBox source="/overview/know-your-allies/text/short/02-mar-2022/01" />
    </>
  );
};
