import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const CustomerCareSatisfaction: React.FC = () => {
  const columns = [
    {
      dataField: 'rank',
      text: 'Rank',
    },
    {
      dataField: 'year_2021',
      text: '2021',
    },
    {
      dataField: 'year_2020',
      text: '2020',
    },
    {
      dataField: 'year_2019',
      text: '2019',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Customer Care Satisfaction</h1>

        <p className={styles.bodyText}>
          Reviewing a good Customer Survey before making any buying decision is one of the smartest ways to evaluate the
          quality and dependability of different brands. This is particularly important in choosing a phone service
          provider because we are so much dependent on the serviceability of phone.
        </p>

        <p className={styles.bodyText}>
          Phone has become such an integral part of our daily life that even a short interruption of service may
          seriously threaten our personal safety, financial security or working conditions. Accordingly, prompt customer
          support to trouble-shoot any technical or billing related issues is extremely important for such an
          indispensable service.
        </p>

        <p className={styles.bodyText}>
          The quality of the Survey Company will eventually decide how much we can rely on its findings. We recommend JD
          Power as it is the most respected name in this business.
        </p>

        <h2 className={clsx(styles.paraHeading)}>JD Power Customer Satisfaction Ranking</h2>
        <h4 className={styles.paraHeading}>2021 | 2020 | 2019</h4>

        <p className={styles.bodyText}>
          Ranking of the Service Providers based on the Customer Service Satisfaction Survey conducted by JD Power for
          last three years:
        </p>

        <SimpleTable
          keyField="id"
          data={[
            { rank: 1, year_2021: 'Videotron', year_2020: 'Virgin Mobile', year_2019: 'Virgin Mobile' },
            { rank: 2, year_2021: 'Virgin Mobile', year_2020: 'Fido', year_2019: 'Videotron' },
            { rank: 3, year_2021: 'SaskTel', year_2020: 'SaskTel', year_2019: 'Koodo Mobile' },
            { rank: 4, year_2021: 'TELUS Mobility', year_2020: 'Videotron', year_2019: 'SaskTel' },
            { rank: 5, year_2021: 'Freedom Mobile', year_2020: 'TELUS Mobility', year_2019: 'TELUS Mobility' },
            { rank: 6, year_2021: 'Koodo Mobile', year_2020: 'Koodo Mobile', year_2019: 'Fido' },
            { rank: 7, year_2021: 'Fido', year_2020: 'Rogers Wireless', year_2019: 'Freedom Mobile' },
            { rank: 8, year_2021: 'Bell Mobility', year_2020: 'Freedom Mobile', year_2019: 'Rogers Wireless' },
            { rank: 9, year_2021: 'Rogers Wireless', year_2020: 'Bell MTS', year_2019: 'Bell Mobility' },
            { rank: 10, year_2021: 'Bell MTS', year_2020: 'Bell Mobility', year_2019: 'Bell MTS' },
          ]}
          columns={columns}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: J. D. Power</div>

        <ul className={styles.list}>
          <li>
            Despite positioning as an economy brand, Virgin Mobile had consistently been the top performer in last three
            years.
          </li>
          <li>
            Rankings had been consistent over last 3 years. Although there were slight changes in the brand’s individual
            positions, each brand was stable in their respective top, middle and bottom tiers.
          </li>
          <li>
            Premium brand does not necessarily always translate into happier customers. Among the premium service
            providers, only Telus is above the industry average for last 3 years, while Bell Mobility and Rogers
            Wireless had consistently been rated below industry average.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>Conclusion</h2>

        <p className={styles.bodyText}>
          Before buying your next phone service, keep in mind the above rankings and consider it as one of your key
          decision-making criteria. After all, if customers are telling something similar year after year (good or bad),
          there must be some truth in it.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/22/2022'} />
      </div>

      <FeedbackBox source="/bills/phone/text/short/22-feb-2022/01" />
    </>
  );
};
