import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const Top20CreditUnions: React.FC = () => {
  const top10CreditUnions = [
    {
      dataField: 'rank',
      text: 'Rank',
    },
    {
      dataField: 'creditUnion',
      text: 'Credit Union',
    },
    {
      dataField: 'province',
      text: 'Province',
    },
    {
      dataField: 'assets',
      text: 'Assets Billion $',
    },
    {
      dataField: 'members',
      text: 'Members In Thousands',
    },
    {
      dataField: 'locations',
      text: 'Locations',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Top 20 Credit Unions</h1>

        <p className={styles.bodyText}>List of top 20 credit unions are appended below:</p>

        <h3 className={styles.paraHeading}>Top 10 Credit Unions</h3>
        <SimpleTable
          keyField="top10CreditUnions"
          data={[
            {
              rank: 1,
              creditUnion: 'Vancity',
              province: 'BC',
              assets: '$26.6',
              members: '530',
              locations: '55',
            },
            {
              rank: 2,
              creditUnion: 'Meridian Credit Union Limited',
              province: 'ON',
              assets: '24.1',
              members: '379',
              locations: '92',
            },
            {
              rank: 3,
              creditUnion: 'Coast Capital Savings Federal',
              province: 'BC',
              assets: '21.6',
              members: '592',
              locations: '48',
            },
            {
              rank: 4,
              creditUnion: 'Servus Credit Union Ltd.',
              province: 'AB',
              assets: '17.5',
              members: '376',
              locations: '103',
            },
            {
              rank: 5,
              creditUnion: 'First West Credit Union',
              province: 'BC',
              assets: '12.7',
              members: '248',
              locations: '46',
            },
            {
              rank: 6,
              creditUnion: 'Desjardins Ontario Credit Union',
              province: 'ON',
              assets: '9.9',
              members: '132',
              locations: '57',
            },
            {
              rank: 7,
              creditUnion: 'Steinbach Credit Union Limited',
              province: 'MB',
              assets: '8.3',
              members: '103',
              locations: '3',
            },
            {
              rank: 8,
              creditUnion: 'Affinity Credit Union',
              province: 'SK',
              assets: '6.86',
              members: '126',
              locations: '56',
            },
            {
              rank: 9,
              creditUnion: 'Prospera Credit Union',
              province: 'BC',
              assets: '6.7',
              members: '117',
              locations: '26',
            },

            {
              rank: 10,
              creditUnion: 'Conexus Credit Union',
              province: 'SK',
              assets: '6.7',
              members: '134',
              locations: '30',
            },
          ]}
          columns={top10CreditUnions}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: Canadian Credit Union Association</div>

        <h3 className={styles.paraHeading}>Credit Unions Rank 11-20</h3>
        <SimpleTable
          keyField="top20CreditUnions"
          data={[
            {
              rank: 11,
              creditUnion: 'Alterna Savings and Credit Union',
              province: 'ON',
              assets: '$6.7',
              members: '154',
              locations: '35',
            },
            {
              rank: 12,
              creditUnion: 'Connect First Credit Union Ltd.',
              province: 'AB',
              assets: '6.3',
              members: '124',
              locations: '41',
            },
            {
              rank: 13,
              creditUnion: 'Access Credit Union Limited',
              province: 'MB',
              assets: '6.2',
              members: '90',
              locations: '27',
            },
            {
              rank: 14,
              creditUnion: 'Assiniboine Credit Union Limited',
              province: 'MB',
              assets: '5.6',
              members: '124',
              locations: '16',
            },
            {
              rank: 15,
              creditUnion: 'DUCA Financial Service Credit',
              province: 'ON',
              assets: '5.5',
              members: '83',
              locations: '16',
            },
            {
              rank: 16,
              creditUnion: 'Libro Credit Union Limited',
              province: 'ON',
              assets: '5.4',
              members: '110',
              locations: '34',
            },
            {
              rank: 17,
              creditUnion: 'UNI Financial Cooperation',
              province: 'NB',
              assets: '5.2',
              members: '156',
              locations: '43',
            },
            {
              rank: 18,
              creditUnion: 'FirstOntario Credit Union',
              province: 'ON',
              assets: '5.2',
              members: '120',
              locations: '32',
            },
            {
              rank: 19,
              creditUnion: 'BlueShore Financial Credit Union',
              province: 'BC',
              assets: '5.1',
              members: '35',
              locations: '13',
            },

            {
              rank: 20,
              creditUnion: 'Cambrian Credit Union Limited',
              province: 'MB',
              assets: '4.6',
              members: '68',
              locations: '11',
            },
          ]}
          columns={top10CreditUnions}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: Canadian Credit Union Association</div>

        <PublishedDate date={'08/05/2022'} />
      </div>

      <FeedbackBox source="/balance/credit-union/text/short/05-aug-2022/03" />
    </>
  );
};
