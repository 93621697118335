import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const OwnWaterHeater: React.FC = () => {
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'min',
      text: 'Minimum',
    },
    {
      dataField: 'max',
      text: 'Maximum',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Own Water Heater</h1>

        <h2 className={styles.paraHeading}>Summary</h2>

        <ol className={styles.list}>
          <li>Renting a water heater costs more than 8 times compared to owning one.</li>
          <li>Savings during the 16-year life of a water heater is estimated between $7,500 to $12,600.</li>
        </ol>

        <h2 className={styles.paraHeading}>Cost to Buy a Water Heater</h2>

        <p className={styles.bodyText}>
          It costs $600 to $1,000 to buy a new hot water tank and $400 to $600 to have it professionally installed for a
          total cost of $1,000 to $1,600.
        </p>

        <p className={styles.bodyText}>
          When purchased from reputed sellers like Home Depo and Lowes, high product quality and professional
          installation is guaranteed, and the buying experience is completely hustle free.
        </p>

        <h2 className={styles.paraHeading}>Cost to Rent a Water Heater</h2>

        <p className={styles.bodyText}>
          The two main rental service providers in Ontario, Reliance Home Comfort and Enercare (previously Direct
          Energy) charge rent between $30 to $40 per month depending on the size of the tank. Tankless water heater
          rentals cost $35 to $50 per month.
        </p>

        <p className={styles.bodyText}>
          Total rental payments made during the life of a water heater is between $5,760 to $9,600 considering average
          life of 16 years.
        </p>

        <p className={styles.bodyText}>
          These charges are increased every year according to the conditions mentioned in the contracts. Reliance: 3.5%
          plus inflation (Ontario CPI) and Enercare: 2% plus inflation (Canada CPI).
        </p>

        <p className={styles.bodyText}>
          After considering these annual price increases (assuming 2% annual inflation), total rental payment made is
          between $8,500 and $14,200.
        </p>

        <h2 className={styles.paraHeading}>Lifespan of Tank Water Heaters</h2>

        <p className={styles.bodyText}>
          Conventional tank water heaters usually last between 8 to 12 years on average. When maintained appropriately,
          water heaters last much more than 15 years. Enercare’s 2021 annual report states that average life of their
          water heaters are 16 years.
        </p>

        <h2 className={styles.paraHeading}>Calculations - Own vs. Rent</h2>

        <SimpleTable
          keyField="ownHeater"
          data={[
            {
              description: 'A. Monthly Rent',
              min: '$30',
              max: '$50',
            },
            {
              description: 'B. Yearly Rent (B = A X 12)',
              min: '$360',
              max: '$600',
            },
            {
              description: 'C. Rent in 16 Years (C = B X 16)',
              min: '$5,760',
              max: '$9,600',
            },
            {
              description: 'D. Total Rent Paid in 16 Years after Yearly Increases',
              min: '$8,500',
              max: '$14,200',
            },
            {
              description: 'E. Price of a Water Heater',
              min: '$600',
              max: '$1,000',
            },
            {
              description: 'F. Installation Cost of a Water Heater',
              min: '$400',
              max: '$600',
            },
            {
              description: 'G. Total Cost of Owning a Water Heater (G = E + F)',
              min: '$1,000',
              max: '$1,600',
            },
            {
              description: 'H. Times Cost Rest vs. Owned (H = D / G)',
              min: '8.5X',
              max: '8.9X',
            },
            {
              description: 'I. Dollar Savings Rent vs. Owned (I = D – G)',
              min: '$7,500',
              max: '$12,600',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={styles.paraHeading}>Conclusion</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          The benefits of owning a water heater are overwhelming.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/29/2022'} />
      </div>

      <FeedbackBox source="/expenses/bills/utility-bills/text/short/29-may-2022/01" />
    </>
  );
};
