import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler } from 'react-hook-form/dist/types/form';

import { TextArea, Select, SubmitButton } from '../../components/Form';

import { SupportFormInputs } from './types';
import styles from './SupportForm.module.scss';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';

const schemaResolver = yupResolver<SupportFormInputs>(
  yup.object().shape({
    message: yup.string().required('please describe the detail'),
    subject: yup.string().required('please select the section'),
    second_subject: yup.string().required('please select the subject'),
  }),
);

export type SupportFormProps = {
  onSubmit: SubmitHandler<SupportFormInputs>;
};

export const SupportForm: React.FC<SupportFormProps> = ({ onSubmit }) => {
  const sectionOptions = ['Goal', 'Income', 'Expense', 'Saving', 'Debt', 'Asset', 'Account'];
  const secondSubjectOptions: { [key: string]: Array<string> } = {
    Goal: ['Overview', 'Goal', 'Calendar'],
    Income: ['Income', 'Tax'],
    Expense: ['Bill'],
    Saving: ['Saving', 'Investment', 'Insurance'],
    Debt: ['Debt', 'Credit Score'],
    Asset: ['Home', 'Auto'],
    Account: ['Balance', 'Transaction'],
  };

  const methods = useForm({ mode: 'onChange', resolver: schemaResolver });
  const { handleSubmit, register, errors, formState, watch } = methods;
  const { isValid } = formState;

  const values = watch();

  return (
    <Container fluid className="px-0">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formBlock} noValidate>
          <div className={styles.dropDownWrapper}>
            <Select
              name="subject"
              options={sectionOptions.map((o) => ({ label: o, value: o }))}
              placeholder="Section"
              className={clsx(styles.select, styles.selectLeft)}
              {...errors}
              register={register}
            />

            <Select
              name="second_subject"
              options={values.subject ? secondSubjectOptions[values.subject].map((o) => ({ label: o, value: o })) : []}
              placeholder="Subject"
              className={clsx(styles.select, styles.selectRight)}
              {...errors}
              register={register}
            />
          </div>

          <TextArea
            name="message"
            placeholder="Please describe the details"
            label=""
            maxLength={300}
            className={styles.message}
            textAreaClassName={styles.messageTextArea}
            {...errors}
            register={register}
          />

          <SubmitButton value="Submit" type="submit" disabled={!isValid} />
        </form>
      </FormProvider>
    </Container>
  );
};
