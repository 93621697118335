import React, { useEffect, useRef, useState, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { signOut, getUserDetails } from '../../features/auth/authSlice';
import { getUnReadAlerts, selectUnreadAlertsList } from '../../features/commCenter/alertSlice';

import { FlashMessage } from '../FlashMessage';
import { SupportTicket } from '../SupportTicket/';

import styles from './Header.module.scss';

import logo from '../../common/images/logo.svg';
import headerButton1 from '../../common/images/header-button-1.svg';
import headerButton2 from '../../common/images/header-button-2.svg';
// import backArrow from '../../common/images/back-arrow.svg';

import profileIcon from '../../common/images/profile-icon.svg';
import investorProfileIcon from '../../common/images/investor-profile-icon.svg';
import signOutIcon from '../../common/images/sign-out-icon.svg';
import supportIcon from '../../common/images/support-icon.svg';

import insuranceIcon from '../../common/images/menu/insurance.svg';
import investmentIcon from '../../common/images/menu/investment.svg';
import debtIcon from '../../common/images/menu/debt.svg';
import creditScoreIcon from '../../common/images/menu/credit-score.svg';
import billsIcon from '../../common/images/menu/bills.svg';
import balanceIcon from '../../common/images/menu/balance.svg';
import transactionIcon from '../../common/images/menu/transaction.svg';
import overviewIcon from '../../common/images/menu/overview.svg';
import goalIcon from '../../common/images/menu/goal.svg';
import calendarIcon from '../../common/images/menu/calendar.svg';
import incomeIcon from '../../common/images/menu/income.svg';
import taxesIcon from '../../common/images/menu/taxes.svg';
import homeIcon from '../../common/images/menu/home.svg';
import autoIcon from '../../common/images/menu/auto.svg';
import savingIcon from '../../common/images/menu/savings.svg';

import { staticPagesRoutesPaths } from '../../staticPages';

const menuMapping = [
  {
    baseUrl: '/save',
    label: 'Saving',
    items: [
      {
        url: '/save/saving',
        label: 'Saving',
        icon: savingIcon,
      },
      {
        url: '/save/investment',
        label: 'Investment',
        icon: investmentIcon,
      },
      {
        url: '/save/insurance',
        label: 'Insurance',
        icon: insuranceIcon,
      },
    ],
  },
  {
    baseUrl: '/goals',
    label: 'Goal',
    items: [
      {
        url: '/goals/overview',
        label: 'Overview',
        icon: overviewIcon,
      },
      {
        url: '/goals/goal',
        label: 'Goal',
        icon: goalIcon,
      },
      {
        url: '/goals/calendar',
        label: 'Calendar',
        icon: calendarIcon,
      },
    ],
  },
  {
    baseUrl: '/accounts',
    label: 'Account',
    items: [
      {
        url: '/accounts/balance',
        label: 'Balance',
        icon: balanceIcon,
      },
      {
        url: '/accounts/transaction',
        label: 'Transaction',
        icon: transactionIcon,
      },
    ],
  },
  {
    baseUrl: '/income',
    label: 'Income',
    items: [
      {
        url: '/income/income',
        label: 'Income',
        icon: incomeIcon,
      },
      {
        url: '/income/tax',
        label: 'Tax',
        icon: taxesIcon,
      },
    ],
  },
  {
    baseUrl: '/expense',
    label: 'Expense',
    items: [
      {
        url: '/expense/bill',
        label: 'Bills',
        icon: billsIcon,
      },
    ],
  },
  {
    baseUrl: '/loan',
    label: 'Debt',
    items: [
      {
        url: '/loan/debt',
        label: 'Debt',
        icon: debtIcon,
      },
      {
        url: '/loan/credit-score',
        label: 'Credit Score',
        icon: creditScoreIcon,
      },
    ],
  },
  {
    baseUrl: '/asset',
    label: 'Asset',
    items: [
      {
        url: '/asset/home',
        label: 'Home',
        icon: homeIcon,
      },
      {
        url: '/asset/auto',
        label: 'Auto',
        icon: autoIcon,
      },
    ],
  },
];

const ProfileDropdown = () => {
  const [showSupport, setShowSupport] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const target = useRef(null);

  return (
    <>
      <Link to="#" onClick={() => setShow(!show)} ref={target}>
        <img src={headerButton1} alt="Arctic Rich Profile" width={25} height={28} />
      </Link>

      <Overlay target={target.current} show={show} placement="bottom">
        {({ arrowProps, ...props }) => (
          <Popover id="popover-profile" {...props} arrowProps={{ ...arrowProps, style: { display: 'none' } }}>
            <Popover.Content>
              <div className="popover-container">
                <div className="popover-block">
                  <img src={profileIcon} alt="Arctic Rich Account" width={22} height={18.5} />
                  <Link
                    to="/user/profile"
                    onClick={() => {
                      setShow(false);
                      return true;
                    }}
                  >
                    Account
                  </Link>
                </div>
                <div className="popover-line" />
                <div className="popover-block">
                  <img src={investorProfileIcon} alt="Arctic Rich Profile" width={22} height={18.5} />
                  <Link
                    to="/user/investment-profile"
                    onClick={() => {
                      setShow(false);
                      return true;
                    }}
                  >
                    Profile
                  </Link>
                </div>
                <div className="popover-line" />
                <div className="popover-block">
                  <img src={supportIcon} alt="Arctic Rich Support" width={22} height={18.5} />
                  <Link
                    to="#"
                    onClick={() => {
                      setShow(false);
                      setShowSupport(true);
                    }}
                  >
                    Support
                  </Link>
                </div>
                <div className="popover-line" />
                <div className="popover-block">
                  <img src={signOutIcon} alt="Arctic Rich Sign Out" width={22} height={18.5} />
                  <Link to="#" onClick={() => signOut()}>
                    Sign Out
                  </Link>
                </div>
              </div>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
      {showSupport ? (
        <SupportTicket
          onClose={() => {
            setShowSupport(false);
          }}
        />
      ) : null}
    </>
  );
};

export const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [navState, setNavState] = useState(false);
  const [navHeight, setNavHeight] = useState('0px');
  const [secondaryMenuItems, setSecondaryMenuItems] = useState<{ url: string; label: string; icon?: string }[]>([]);

  // const [backPressed, setBackPressed] = useState(false);

  // useEffect(() => {
  //   return history.listen(() => {
  //     history.action === 'POP' && !backPressed && signOut();
  //   });
  // }, [history, backPressed]);

  // useEffect(() => {
  //   if (backPressed) {
  //     setBackPressed(false);
  //     history.goBack();
  //   }
  // }, [history, backPressed]);

  useEffect(() => {
    const foundDefaultSecondaryMenuItems = menuMapping.find(
      (item) => location.pathname.indexOf(item.baseUrl) > -1 && !staticPagesRoutesPaths.includes(location.pathname),
    );

    if (foundDefaultSecondaryMenuItems) {
      setSecondaryMenuItems(foundDefaultSecondaryMenuItems.items);
    } else {
      setSecondaryMenuItems([]);
    }
  }, [location.pathname]);

  const primaryMenuItemClickHandler = (e: React.MouseEvent, baseUrl: string, isMobile = false) => {
    e.preventDefault();

    if (isMobile) {
      navOpenHandler();
    }

    const foundSecondaryMenuItems = menuMapping.find((item) => item.baseUrl === baseUrl);

    if (foundSecondaryMenuItems) {
      history.push(foundSecondaryMenuItems.items[0].url);
    }
  };

  const navOpenHandler = () => {
    setNavState(!navState);
    if (!navState) {
      document.body.style.overflow = 'hidden';
      setNavHeight('100%');
    } else {
      document.body.style.overflow = 'unset';
      setNavHeight('0px');
    }
  };

  const dispatch = useDispatch();
  const getUserDetailsAction = useCallback(() => dispatch(getUserDetails()), [dispatch]);
  const getAlertsListAction = useCallback(() => dispatch(getUnReadAlerts()), [dispatch]);
  const alertsList = useSelector(selectUnreadAlertsList);

  // This will trigger the auth if not authenticated
  useEffect(() => {
    getUserDetailsAction();
  }, [getUserDetailsAction]);

  useEffect(() => {
    const c = setInterval(() => getAlertsListAction(), 15000);
    return () => clearInterval(c);
  }, [getAlertsListAction]);

  return (
    <>
      <Navbar expand="lg" className={styles.navContainerMb + ' row  d-flex d-lg-none'} variant="dark">
        <Navbar.Toggle onClick={() => navOpenHandler()} className={'ml-10-cust nav-toggler-mb border-0'} />

        <Navbar.Brand className={styles.brandContainer + ' align-items-center justify-content-center'}>
          <LinkContainer to={'/goals/overview'}>
            <img src={logo} alt="Arctic Rich" width={162} height={54} className={styles.brandLogo} />
          </LinkContainer>
        </Navbar.Brand>

        <div
          className={styles.navItemsContainer}
          style={{
            height: navHeight,
          }}
        >
          <span className={styles.closebtnStyles} onClick={() => navOpenHandler()}>
            &times;
          </span>
          <div className={styles.navList}>
            <Nav>
              <Nav.Item className={styles.navItemsMb}>
                <LinkContainer to="/goals" onClick={(e) => primaryMenuItemClickHandler(e, '/goals', true)}>
                  <div>Goal</div>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item className={styles.navItemsMb}>
                <LinkContainer to="/income" onClick={(e) => primaryMenuItemClickHandler(e, '/income', true)}>
                  <div>Income</div>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item className={styles.navItemsMb}>
                <LinkContainer to="/expense" onClick={(e) => primaryMenuItemClickHandler(e, '/expense', true)}>
                  <div>Expense</div>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item className={styles.navItemsMb}>
                <LinkContainer to="/save" onClick={(e) => primaryMenuItemClickHandler(e, '/save', true)}>
                  <div>Saving</div>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item className={styles.navItemsMb}>
                <LinkContainer to="/loan" onClick={(e) => primaryMenuItemClickHandler(e, '/loan', true)}>
                  <div>Debt</div>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item className={styles.navItemsMb}>
                <LinkContainer to="/asset" onClick={(e) => primaryMenuItemClickHandler(e, '/asset', true)}>
                  <div>Asset</div>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item className={styles.navLastItemsMb}>
                <LinkContainer to="/accounts" onClick={(e) => primaryMenuItemClickHandler(e, '/accounts', true)}>
                  <div>Account</div>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </div>
        </div>

        <Navbar.Brand>
          <ProfileDropdown />
        </Navbar.Brand>
      </Navbar>

      <Row className={styles.primaryContainer + ' d-none d-lg-flex'}>
        <Col xs="2" className="text-right">
          <LinkContainer to={'/goals/overview'}>
            <img src={logo} alt="Arctic Rich" width={162} height={54} className={styles.brandLogo} />
          </LinkContainer>
        </Col>
        <Col xs="8">
          <Nav fill defaultActiveKey="/" className={styles.primaryNav + ' d-flex align-items-center'} as="ul">
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/goals" onClick={(e) => primaryMenuItemClickHandler(e, '/goals')}>
                <div>Goal</div>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/income" onClick={(e) => primaryMenuItemClickHandler(e, '/income')}>
                <div>Income</div>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/expense" onClick={(e) => primaryMenuItemClickHandler(e, '/expense')}>
                <div>Expense</div>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/save" onClick={(e) => primaryMenuItemClickHandler(e, '/save')}>
                <div>Saving</div>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/loan" onClick={(e) => primaryMenuItemClickHandler(e, '/loan')}>
                <div>Debt</div>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/asset" onClick={(e) => primaryMenuItemClickHandler(e, '/asset')}>
                <div>Asset</div>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li" className="d-flex align-items-center justify-content-center">
              <LinkContainer to="/accounts" onClick={(e) => primaryMenuItemClickHandler(e, '/accounts')}>
                <div>Account</div>
              </LinkContainer>
            </Nav.Item>
          </Nav>
        </Col>
        <Col xs="2" className="d-flex align-items-center">
          <Link to="/comm-center" className="pr-4">
            <span className="position-relative">
              <img src={headerButton2} alt="Arctic Rich Notifications" width={24} height={30} />
              {alertsList && alertsList.length ? <span className={styles.commCenterUnRead}></span> : null}
            </span>
          </Link>
          <ProfileDropdown />
        </Col>
      </Row>
      <div className={styles.spacer} />
      {secondaryMenuItems.length > 0 ? (
        <Row className={styles.secondaryContainer}>
          {/* <Col xs="2">
            <div className={styles.backArrowContainer}>
              <a role="button" onClick={() => setBackPressed(true)} className={styles.backArrow}>
                <img src={backArrow} alt="Back" width={62} height={32} />
              </a>
            </div>
          </Col> */}
          <Col xs="12">
            <Nav defaultActiveKey="/" className={styles.secondaryNav + ' d-flex justify-content-center'} as="ul">
              {secondaryMenuItems.map((item) => (
                <React.Fragment key={item.url}>
                  <Nav.Item as="li" className="d-flex align-items-center">
                    <LinkContainer to={item.url}>
                      <div className={styles.secondaryNavLinkBlock}>
                        {item.icon ? (
                          <img src={item.icon} alt={item.label} height="40" className={styles.secondaryNavLinkImage} />
                        ) : null}
                        {item.label}
                      </div>
                    </LinkContainer>
                  </Nav.Item>
                </React.Fragment>
              ))}
            </Nav>
          </Col>
        </Row>
      ) : (
        <div className={styles.emptyMenu}>
          {/* <Row className={styles.emptyMenuContainer}>
            <Col xs="2">
              <div className={styles.backArrowContainer}>
                <a role="button" onClick={() => setBackPressed(true)} className={styles.backArrow}>
                  <img src={backArrow} alt="Back" width={62} height={32} />
                </a>
              </div>
            </Col>
          </Row> */}
        </div>
      )}
      {location.pathname === '/save/investment' ? (
        <FlashMessage severity="normal">
          Investments are automatically updated from your Banks. Choose the right purpose of your investments.
        </FlashMessage>
      ) : null}
      {location.pathname === '/accounts/balance' ? (
        <FlashMessage severity="normal">
          Link all your Bank Accounts to get started. Click on the Link Account Button.
        </FlashMessage>
      ) : null}
      {location.pathname === '/goals/goal' ? (
        <FlashMessage severity="normal">Keep your Goals and Milestones updated.</FlashMessage>
      ) : null}
      {location.pathname === '/accounts/transaction' ? (
        <FlashMessage severity="normal">
          Link all your Bank Accounts to view the Transactions. Click on the Link Account Button on the Balance Page to
          get started.
        </FlashMessage>
      ) : null}
      {secondaryMenuItems.length > 0 && <Row className={styles.gradient} />}
    </>
  );
};
