import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DeductionOfVehicleExpenses: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Deduction of Vehicle Expenses</h1>

        <h1 className={clsx(styles.subHeading)}>For Salaried Employees</h1>

        <p className={styles.bodyText}>
          If you have paid motor vehicle expenses, which are necessary to perform employment related work, then you can
          deduct such expenses from taxable income - provided the following conditions are met
        </p>

        <p className={styles.bodyText}>
          You had to work away from the employer&apos;s place of business or at different places.
        </p>

        <p className={styles.bodyText}>
          You were required as per employment agreement, to pay own motor vehicle expenses. However, you cannot claim
          these expenses, if you receive reimbursement or non-taxable allowance from employer
        </p>

        <p className={styles.bodyText}>
          If actual expenses are more than the non-taxable allowance, then such excess portion can be claimed.
        </p>

        <p className={styles.bodyText}>
          <a
            className={styles.link}
            href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2200.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Form T2200, Declaration of Conditions of Employment
          </a>
          , must be completed by the employer.
        </p>

        <p className={styles.bodyText}>Expenses you can claim (should be approved by your employer):</p>
        <ul className={styles.list}>
          <li>Fuel (gasoline, propane, oil)</li>
          <li>Maintenance and repairs</li>
          <li>Insurance</li>
          <li>Licence and registration fees</li>
          <li>Capital cost allowance</li>
          <li>Eligible interest you paid on a loan used to buy the motor vehicle</li>
          <li>Eligible leasing costs.</li>
        </ul>

        <h1 className={clsx(styles.paraHeading)}>Joint Ownership:</h1>
        <p className={styles.bodyText}>
          If you and somebody else own or lease the same vehicle, then the total amount the joint owners can claim,
          cannot be more than the amount that would be allowed, if only one person had owned or leased the vehicle.
        </p>

        <h1 className={clsx(styles.paraHeading)}>Employment and Personal Use Vehicle:</h1>
        <p className={styles.bodyText}>
          If motor vehicle is used for both employment and personal purpose, then you can claim percentage of expenses
          related to employment, based on kilometres driven for work.
        </p>

        <h1 className={clsx(styles.paraHeading)}>Working from Home Travel Expenses</h1>
        <p className={styles.bodyText}>
          Travel to and from the place of work is not considered use for employment, however, if an employee normally
          works in her home office, does not have an office at the employer&apos;s place of business but occasionally
          must travel there, the travel to and from the employer&apos;s place of business may qualify for a vehicle
          expense deduction.
        </p>

        <h1 className={clsx(styles.paraHeading)}>More Than One Vehicle</h1>
        <p className={styles.bodyText}>
          If you use more than one motor vehicle for work then calculate the expenses for each vehicle separately.
        </p>

        <h1 className={clsx(styles.noteHeading)}>Note</h1>
        <p className={styles.bodyText}>
          Vehicle expense record should include the total kilometres driven as well as the kilometres you drove for
          work. The record for each trip should list the
        </p>
        <ol className={styles.list}>
          <li>Date</li>
          <li>Destination</li>
          <li>Purpose</li>
          <li>
            Number of kilometres.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/10/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses/text/short/10-feb-2022/01" />
    </>
  );
};
