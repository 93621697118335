import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { MaintenanceForm } from './MaintenanceForm';
import { MaintenanceFormInputs } from './types';
import { BadRequestError } from '../../helpers/api';

import { updateMaintenance, getMaintenanceList, selectMaintenanceById, selectListLoading } from './homeSlice';

export const EditMaintenance: React.FC = () => {
  let { maintenanceId } = useParams();
  maintenanceId = parseInt(maintenanceId, 10);
  const maintenanceItem = useSelector(selectMaintenanceById(maintenanceId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getMaintenanceListAction = useCallback(() => dispatch(getMaintenanceList()), [dispatch]);

  const onSubmit = async (data: MaintenanceFormInputs) => {
    Object.assign(data, { house: 1 });
    try {
      await updateMaintenance(maintenanceId, data);
      history.push('/asset/home');
      getMaintenanceListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Home Maintenance']}>
      <MaintenanceForm defaultValues={maintenanceItem} onSubmit={onSubmit} />
    </Modal>
  );
};
