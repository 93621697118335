import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DebtToIncomeRatio: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Debt to Income Ratio</h1>

        <p className={styles.bodyText}>
          Debt to income ratio (DTI) connects how much debt you owe each month to how much you earn.
        </p>

        <h3 className={styles.paraHeading}>The Formula</h3>
        <p className={styles.bodyText}>
          Debt-to-income ratio (DTI) is the percentage of your gross monthly income (before taxes) that is available for
          payments of your mortgage or rent, credit cards, other debts etc.
        </p>

        <h3 className={styles.paraHeading}>How to Calculate</h3>

        <p className={styles.bodyText}>To calculate your debt-to-income ratio:</p>

        <h3 className={styles.paraHeading}>Step 1</h3>
        <p className={styles.bodyText}>
          Calculate your gross monthly income, which is your total income before deducting taxes.
        </p>

        <h3 className={styles.paraHeading}>Step 2</h3>
        <p className={styles.bodyText}>Add your monthly payments:</p>
        <ul className={styles.list}>
          <li>Monthly mortgage or house rent payments</li>
          <li>Credit card monthly minimum payments</li>
          <li>Student, auto, and other monthly loan payments</li>
          <li>Monthly child support payments</li>
          <li>Monthly payments of other debts.</li>
        </ul>

        <p className={styles.bodyText}>Note: Expenses like groceries, utilities, gas, and taxes are not included.</p>

        <h3 className={styles.paraHeading}>Step 3</h3>
        <p className={styles.bodyText}>
          Divide the total monthly payments (Step 2) by your gross monthly income (Step 1) and multiply by 100.
        </p>

        <h3 className={styles.paraHeading}>Step 4</h3>
        <p className={styles.bodyText}>
          The result would be your Debt-to-income ratio (DTI), which will be in percentage.
        </p>

        <h3 className={styles.paraHeading}>Takeaway</h3>
        <p className={styles.bodyText}>
          Remember, this is yardstick designed and extensively used by lenders. The lower the Debt-to-income ratio
          (DTI), the less risky applicant or borrower you are to the lenders.
        </p>

        <h3 className={styles.paraHeading}>Lender Thresholds</h3>
        <p className={styles.bodyText}>
          Following are the broad internal guidelines, which lenders use for their loan evaluation and approval
          purposes. It may slightly vary among institutions depending on their respective risk appetite.
        </p>

        <p className={styles.bodyText}>
          <strong>Less than 35% | Good</strong>
        </p>
        <p className={styles.bodyText}>
          Your debt is at an acceptable level from the lender&apos;s perspective. You are likely to have room for saving
          or investment after meeting mandatory bill payments.
        </p>

        <p className={styles.bodyText}>
          <strong>36% to 49% | Average</strong>
        </p>
        <p className={styles.bodyText}>
          You&apos;re managing your debt well, but you should try to improve. Any progress would make you better
          prepared for the unforeseen expenses. Your current status would not qualify you for the premium borrowing
          products or pricing and lenders may ask for additional security.
        </p>

        <p className={styles.bodyText}>
          <strong>More than 50% | Poor</strong>
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          This is a red alert situation. Clearly your cashflow after paying mandatory bills are very limited for
          everyday expenses and savings. You have almost no cushion left for unforeseen expenses. Lenders are likely to
          seriously limit your borrowing options. You are a strong candidate to fall pray of predatory lenders.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/30/2022'} />
      </div>

      <FeedbackBox source="/debt/debt-ratios/text/short/30-jun-2022/01" />
    </>
  );
};
