import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AccountingAndLegalFees: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Deduction of Accounting and Legal Fees</h1>

        <h2 className={clsx(styles.subHeading)}>For Salaried Employees</h2>

        <p className={styles.bodyText}>
          You are entitled to claim a deduction of the legal fees incurred in connection with the following:
        </p>

        <h2 className={clsx(styles.paraHeading)}>Legal and Accounting Fees for Tax Advice or Assistanc</h2>

        <ul className={styles.list}>
          <li>
            Fees (including any related accounting fees) paid for advice or assistance to respond to the CRA notice or
            to appeal an assessment or decision under the Income Tax Act, the Employment Insurance Act, the Canada
            Pension Plan, or the Quebec Pension Plan.
          </li>
          <li>Fees incurred to pay an accountant to prepare tax returns each year cannot be claimed.</li>
          <li>
            In case of commission employees, a reasonable accounting fees paid for helping to prepare and file their
            annual tax or benefit return is eligible for deduction.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>Legal Fees for Establishing Your Right to Income</h2>

        <p className={styles.bodyText}>
          Legal fees paid by an employee or former employee seeking to collect (or to establish a right to collect)
          salary or wages.
        </p>

        <ul className={styles.list}>
          <li>
            You do not actually have to be successful in your claim. If the nature of your original claim is for salary
            or wages, the fees are deductible regardless of the end result.
          </li>
          <li>
            If a court action is necessary and the Court requires the employer to reimburse its former employee for some
            or all of the legal fees incurred, the amount of that reimbursement must be subtracted from any deduction
            claimed.
          </li>
        </ul>

        <p className={styles.bodyText}>
          Legal fees paid by an employee or former employee in order to collect or to establish a right to collect a
          retiring allowance or pension benefits.
        </p>

        <ul className={styles.list}>
          <li>
            The legal fees incurred can be deducted, up to the total amount of the retiring allowance or pension income
            actually received for that year.
          </li>
          <li>
            Where part of the retiring allowance or pension benefits received in a particular year is contributed to an
            RRSP or registered pension plan, the amount contributed must be subtracted from the total amount received
            when calculating the maximum allowable deduction for legal fees.
          </li>
        </ul>

        <p className={styles.bodyText}>
          Legal fees portion which cannot be claimed in the year they are paid, can be carried forward and claimed in
          any of the next seven years
        </p>

        <h2 className={clsx(styles.paraHeading)}>Legal Fees for Child Support</h2>

        <p className={styles.bodyText}>
          An individual who is the recipient of support amounts can claim a deduction of legal fees incurred to:
        </p>

        <ul className={styles.list}>
          <li>Collect late support payments;</li>
          <li>
            Establish the amount of support payments from the payer (current or former spouse or common-law partner);
          </li>
          <li>To try to get an increase in support payments;</li>
        </ul>

        <p className={styles.bodyText}>
          Where an individual is the beneficiary of an order and receives a payment to cover some of the legal costs
          incurred, any amount claimable as a deduction for those costs must be reduced by the amount of that payment.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          It sometimes happens that reimbursement for legal fees incurred is received in a year after the one in which
          the deduction for the full amount of legal fees incurred was claimed. In that case, the individual who
          receives the reimbursement must include that amount in taxable income for the year in which it was received.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/17/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expensesII/text/short/17-feb-2022/02" />
    </>
  );
};
