import React from 'react';

import styles from './ForgotPasswordLayout.module.scss';

export const ForgotPasswordLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.logo} />
      {children}
    </div>
  );
};
