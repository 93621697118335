import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const ElectricityTimingOfUse: React.FC = () => {
  const columns = [
    {
      dataField: 'time',
      text: `Time of use\nPrice Periods`,
    },
    {
      dataField: 'winter',
      text: `Winter\n(November 1 - April 30)`,
    },
    {
      dataField: 'summer',
      text: `Summer\n(May 1 - October 31)`,
    },
    {
      dataField: 'price',
      text: `Time of Use Prices\n(¢/kWh)`,
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Electricity - Timing of Use</h1>

        <p className={styles.bodyText}>
          Planning to time the use the appliances (e.g. washer, dryer, dishwasher) could make a lot of difference in
          your utility bills. Saving could be as much as 53% or more than half of your current bill.
        </p>

        <p className={styles.bodyText}>
          For example, Ontario Energy Board exhibits the pricing of electricity during different hours of the day in the
          chart below.
        </p>

        <SimpleTable
          keyField="time_of_use"
          data={[
            {
              time: 'Off-Peak',
              winter: (
                <>
                  <p>Weekdays</p>
                  <p>7 p.m. – 7 a.m.</p>
                  <p>Weekends and holidays</p>
                  <p>all day</p>
                </>
              ),
              summer: (
                <>
                  <p>Weekdays</p>
                  <p>7 p.m. – 7 a.m.</p>
                  <p>Weekends and holidays</p>
                  <p>all day</p>
                </>
              ),
              price: 8.2,
            },
            {
              time: 'Mid-Peak',
              winter: (
                <>
                  <p>Weekdays</p>
                  <p>11 a.m. – 5 p.m.</p>
                </>
              ),
              summer: (
                <>
                  <p>Weekdays</p>
                  <p>7 a.m. – 11 a.m. and 5 p.m. – 7 p.m.</p>
                </>
              ),
              price: 11.3,
            },
            {
              time: 'On-Peak',
              winter: (
                <>
                  <p>Weekdays</p>
                  <p>7 a.m. – 11 a.m. and 5 p.m. – 7 p.m.</p>
                </>
              ),
              summer: (
                <>
                  <p>Weekdays</p>
                  <p>11 a.m. – 5 p.m.</p>
                </>
              ),
              price: 17.0,
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={clsx(styles.bodyText, styles.last)}>
          This clearly shows that rate during the On-Peak Hours is more than double of the Off-Peak Hours.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/29/2022'} />
      </div>

      <FeedbackBox source="/expenses/bills/utility-bills/text/short/29-may-2022/02" />
    </>
  );
};
