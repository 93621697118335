import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import HelpBox from '../components/Common/HelpBox';
import infoImg from '../common/images/info.svg';

export const ElectricVehicle: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Electric Vehicle
          <HelpBox placement="right" content={<p className="mb-0">Source: Government of Canada.</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>
        <h2 className={styles.subHeading}>Canadian Transition Plan</h2>

        <h3 className={styles.paraHeading}>New Vehicle Sales Goal</h3>
        <p className={styles.bodyText}>Canada has announced the following new vehicle sales goals targeting by:</p>
        <ul className={styles.list}>
          <li>Year 2025: 10% share for Electric Vehicles.</li>
          <li>Year 2030: 40% share for Electric Vehicles.</li>
          <li>Year 2040: 100% share for Electric Vehicles.</li>
        </ul>

        <h3 className={styles.paraHeading}>Total Vehicle Fleet Goal</h3>
        <p className={styles.bodyText}>This translates to Electric Vehicles will be by:</p>
        <ul className={styles.list}>
          <li>Year 2025: 3% of the total vehicle fleet.</li>
          <li>Year 2030: 11% of the total vehicle fleet.</li>
          <li>Year 2040: 60% of the total vehicle fleet.</li>
        </ul>

        <p className={styles.bodyText}>
          Extended forecast indicates that by Year 2050, Electric Vehicles will constitute 100% of all the registered
          vehicles in Canada.
        </p>
        <p className={styles.bodyText}>
          Government of Canada believes the above goals are ambitious but attainable particularly considering all the
          major auto manufacturer&apos;s announcements to offer Electric Vehicles only in the coming years.
        </p>

        <h3 className={styles.noteHeading}>Note</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          In 2019, Canada had 25 million registered light duty vehicles of which only 168,000 were Electric Vehicles,
          representing 0.67% of the market.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/06/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-I/text/short/06-jul-2022/01" />
    </>
  );
};
