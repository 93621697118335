import React from 'react';
import HelpBox from './HelpBox';

interface ColumnHeaderComponentsProps {
  sortElement: React.ReactNode;
  filterElement: React.ReactNode;
}

const ColumnHeaderWithHelp = (
  column: any,
  colIndex: number,
  { sortElement, filterElement }: ColumnHeaderComponentsProps,
): any => {
  return (
    <>
      {filterElement}
      <HelpBox content={column['helpText']}>{column['text']}</HelpBox>
      {sortElement}
    </>
  );
};

export default ColumnHeaderWithHelp;
