export interface BillItem {
  id: number;
  sector: string;
  institution: string;
  amount: string;
  billing_cycle: BillingCycleType;
  last_due_date: string;
  due_date: string;
}

export type BillItems = BillItem[];

export enum BillingCycleType {
  MONTHLY = 'Monthly',
  BIMONTHLY = 'Bimonthly',
  QUARTERLY = 'Quarterly',
  HALF_YEARLY = 'Half Yearly',
  ANNUALLY = 'Annually',
}

export type BillFormInputs = {
  sector: string;
  institution: string;
  amount: string;
  billing_cycle: BillingCycleType;
  last_due_date: string;
};
