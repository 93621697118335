import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TaxCredit: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tax Credit</h1>

        <ul className={styles.list}>
          <li>
            In 2021, the maximum limit for the basic personal tax credit is $13,808. Up to this amount is eligible for
            claim for income level of $151,978 or less.
          </li>
          <li>
            The maximum limit for the basic personal tax credit will range between $12,421 and $13,808 when the income
            is between $151,979 and $216,511.
          </li>
          <li>For income above $216,511, the maximum limit for the basic personal tax credit is $12,421.</li>
        </ul>

        <div>
          <h5 className={styles.noteHeading}>Note:</h5>
          <p className={styles.bodyText}>
            By 2023, the maximum limit for the basic personal tax credit will increase to $15,000.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </p>
        </div>

        <PublishedDate date={'01/23/2022'} />
      </div>

      <FeedbackBox source="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/02" />
    </>
  );
};
