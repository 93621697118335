import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DebtItems } from './types';
import {
  Table,
  dateFormatter,
  priceFormatter,
  deleteIconFormatter,
  editIconFormatter,
  percentageFormatter,
  TableSortCaret,
  betterRatesModalFormatter,
} from '../../components/Table';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import { deleteDebt, getDebtList } from './debtSlice';

import styles from '../../components/Table/Table.module.scss';

const BetterRatesHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Click here to look up the better deals in the market compared to the respective current facility. </li>
        <br />
        <li>We listed only the best five and filtered the rest. </li>
        <br />
        <li>
          You can easily switch the best five by yourself or we will introduce you. In either case, we will integrate
          all your key exposure there into your portal here.{' '}
        </li>
      </ul>
    </>
  );
};

const ProviderHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Financial Institution which provided the Credit Facility. </li>
        <br />
        <li>This item will be automatically updated.</li>
      </ul>
    </>
  );
};

const PurposeHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Indicate the purpose or reason for which you borrowed this money. </li>
        <br />
        <li>This item has to be manually updated.</li>
      </ul>
    </>
  );
};

const AccountNameHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>It the name of the Account in which the Credit Facility had been issued.</li>
        <br />
        <li>This item to be updated automatically.</li>
      </ul>
    </>
  );
};

const BalanceHelp = () => {
  return (
    <>
      <h6 className="mb-0">This is the current outstanding balance of the Credit Facility. </h6>
    </>
  );
};

const LimitHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>
          <b>Limit</b> is the maximum amount of the Credit Facility which can be borrowed.
        </li>
        <br />
        <li>Limit needs to be manually inserted.</li>
      </ul>
    </>
  );
};

const RateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>
          <b>Rate</b> the <b>Annual Interest Rate</b> that you pay for this Credit Facility.
        </li>
        <br />
        <li>
          <b>Annual Interest Rate</b> needs to be manually inserted.
        </li>
      </ul>
    </>
  );
};

const MaturityHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Maturity is the expiry of the Credit Facility.</li>
        <br />
        <li>Credit Facility must be repaid in Full or renewed by this date.</li>
        <br />
        <li>Renewed facility may or may not have the same terms.</li>
      </ul>
    </>
  );
};

export interface DebtTableProps {
  debtList: DebtItems;
}

export const DebtTable: React.FC<DebtTableProps> = ({ debtList }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const getDebtListAction = useCallback(() => dispatch(getDebtList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteDebt(id);
    getDebtListAction();
  };

  const columns = [
    {
      dataField: 'better_rates',
      text: 'Better Rates',
      sort: false,
      helpText: <BetterRatesHelp />,
      formatter: betterRatesModalFormatter,
      classes: styles.betterRatesColumn,
      formatExtraData: {},
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'account.provider_name',
      text: 'Provider',
      sort: true,
      helpText: <ProviderHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'debt_type',
      text: 'Purpose',
      sort: true,
      helpText: <PurposeHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'account.account_name',
      text: 'Account Name',
      sort: true,
      helpText: <AccountNameHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'account.balance',
      text: 'Balance',
      sort: true,
      helpText: <BalanceHelp />,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'limit',
      text: 'Limit',
      sort: true,
      helpText: <LimitHelp />,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'rate',
      text: 'Rate',
      sort: true,
      helpText: <RateHelp />,
      formatter: percentageFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'maturity',
      text: 'Maturity',
      sort: true,
      helpText: <MaturityHelp />,
      formatter: dateFormatter('MMM dd, yyyy'),
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/loan/debt/edit/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={debtList} columns={columns} bootstrap4 />;
};
