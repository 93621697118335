import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AlternativesToPredatoryLenders: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Alternatives to Predatory Lenders</h1>

        <p className={styles.bodyText}>Following are few alternatives to predatory lenders:</p>

        <h3 className={styles.paraHeading}>1. Earn Extra Money</h3>

        <p className={styles.bodyText}>This is by far the best option compared to the rest we have listed here.</p>

        <p className={styles.bodyText}>
          Take up some additional job in addition to your routine work. You can add more task or hours in your current
          role or do something completely different depending on your interest, ability, and skill.
        </p>
        <p className={styles.bodyText}>
          For example, online freelancing (tutoring, consulting, web designing, writing, designing), delivering pizza,
          driving for Uber, working at a store etc. Remember, any work is better than borrowing, particularly in
          unfavourable terms.
        </p>

        <h3 className={styles.paraHeading}>2. Borrow from Family or Friends</h3>

        <p className={styles.bodyText}>
          In case of urgent financial needs, you may borrow from your family or friends. Terms could be easier and
          perhaps interest free. Failure to repay on time would not impact your credit score, but you may face lawsuit
          and damaged relationship. Ensure you have a written loan agreement, clearly indicating the amount borrowed,
          interest rate, repayment schedule, collateral, and other terms.
        </p>

        <h3 className={styles.paraHeading}>3. Cash Advance from Credit Cards</h3>
        <p className={styles.bodyText}>
          A credit card cash advance would charge you a lower interest rate than the payday loans. It is certainly a
          good alternative to the payday loans. Remember, the interest rate will be higher than the purchases, if you
          withdraw cash. You may also need to pay 3% to 5% additional fee for the cash advance.
        </p>

        <h3 className={styles.paraHeading}>4. Explore Lending Circles</h3>
        <p className={styles.bodyText}>
          A lending circle generally consists of 5 to 15 people. As a member, you can borrow from your Lending Circle
          members to pay high-interest loans, cover emergency expenses, buy a car etc. You can borrow from $200 to few
          thousand dollars.
        </p>

        <h3 className={styles.paraHeading}>5. Peer-to-Peer Loans</h3>
        <p className={styles.bodyText}>
          Peer-to-peer loans match potential borrowers with investors willing to lend. You can borrow peer-to-peer loans
          through online platforms. Interest rate usually varies between 8% to 36% per year. There are also fees charged
          as percentage of total borrowed funds.
        </p>

        <h3 className={styles.paraHeading}>6. Installment Loans for Bad Credit</h3>
        <p className={styles.bodyText}>
          Few lenders specialize in offering personal loans to people with poor credit and little or no credit history.
          Their interest rates are high but not as high as payday loans. The loan structure and their intentions are
          also not as predatory.
        </p>

        <h3 className={styles.paraHeading}>7. Short-Term Bank Loans</h3>
        <p className={styles.bodyText}>
          Some banks approve short-term small-dollar loans as an alternative to the payday loans. They are pricy in
          regular market standard but cheaper than payday loans.
        </p>

        <h3 className={styles.paraHeading}>8. Credit Unions</h3>
        <p className={styles.bodyText}>
          As a member of a Credit Union, you may qualify for a payday alternative loan. For example, in Saskatchewan
          Affinity Credit Union offer short-term loans of $200 with an interest of $1.38 for that loan, compared to the
          industry standard rate of $34. You can borrow up to $2,000 from Affinity Credit Union.
        </p>

        <h3 className={styles.paraHeading}>9. Adopt A Payment Plan</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          Instead of borrowing more, ask your lender to structure a payment plan for you. This plan allows you to reduce
          your monthly payments by extending the tenor of the loan. Although you will pay more interest, it will relieve
          you from some of your monthly cash outflow and help you not to borrow more.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/14/2022'} />
      </div>

      <FeedbackBox source="/debt/predatory-lending/text/short/14-jul-2022/04" />
    </>
  );
};
