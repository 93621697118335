import React from 'react';

import batteryNoData from '../../common/images/batteries/1-No-Data.png';
import batteryPoor from '../../common/images/batteries/2-Poor.png';
import batteryAverage from '../../common/images/batteries/3-Average.png';
import batteryFair from '../../common/images/batteries/4-Fair.png';
import batteryGood from '../../common/images/batteries/5-Good.png';
import batteryExcellent from '../../common/images/batteries/6-Excellent.png';

import styles from './BatteryIndicator.module.scss';

export interface BatteryIndicatorProps {
  percentage: number;
}

export const BatteryIndicator: React.FC<BatteryIndicatorProps> = ({ percentage }) => {
  let batteryImage;
  let status;

  switch (true) {
    case percentage > 0 && percentage <= 20:
      batteryImage = batteryPoor;
      status = 'Poor';
      break;
    case percentage > 20 && percentage <= 40:
      batteryImage = batteryAverage;
      status = 'Average';
      break;
    case percentage > 40 && percentage <= 60:
      batteryImage = batteryFair;
      status = 'Fair';
      break;
    case percentage > 60 && percentage <= 80:
      batteryImage = batteryGood;
      status = 'Good';
      break;
    case percentage > 60 && percentage <= 100:
      batteryImage = batteryExcellent;
      status = 'Excellent';
      break;
    default:
      batteryImage = batteryNoData;
      status = 'No Data';
  }
  return (
    <div className={styles.wrapper}>
      <div className="d-flex flex-column">
        <img src={batteryImage} alt="" />
        <div className={styles.batteryStatus}>{status}</div>
      </div>
    </div>
  );
};
