import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const EqualMonthlyPaymentPlan: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Equal Monthly Payment Plan</h1>

        <ul className={styles.list}>
          <li>Many gas providers allow to manage your monthly bill better and pay the same amount every month.</li>
          <li>
            In Canada, due to weather most households spend the most on natural gas during the three to four coldest
            months of the year.
          </li>
          <li>During the summer, gas bills are very minimal since heating the home is not required.</li>
          <li>
            Many gas providers like Enbridge have come up with a plan to even out this monthly gas bill payments, so
            that there is no extra burden of gas bill payments - particularly during the holiday season.
          </li>
          <li>
            For example, Enbridge’s The Equal Monthly Payment Plan (EMPP) divides total estimated yearly natural gas
            costs into equal monthly payments.
          </li>
          <li>
            This eliminates fluctuation of gas bills in different seasons, making the monthly payment commitments more
            even and predictable for your planning and budgeting purposes.
          </li>
          <li>
            Gas service providers lookup your gas use history, expected gas rates and weather forecasts to estimate next
            year’s gas use. They then divide your estimated total yearly gas use into 12 equal monthly installment
            payments.
          </li>
          <li>
            Your total monthly payment is your installment payment plus any charges from other companies, or other
            charges by the gas service provider, if any.
          </li>
          <li>
            Providers would periodically review your plan and take into account any changes in the market, cost of
            natural gas and your gas use, to update your total estimated gas usage. They will adjust your monthly
            installments if the total estimated yearly gas usage changes.
          </li>
          <li>Throughout the year, your monthly installment payments are applied against your actual gas costs.</li>
          <li>
            At the end of your plan, the Equal Monthly Payment Plan (EMPP) balance should ideally be close to zero.
          </li>
          <li>
            Providers usually match total gas charges and Equal Monthly Payment Plan (EMPP) installments fairly well.
          </li>
          <li>
            If your actual total yearly cost exceeds the amount you paid through the average monthly billing
            installments, then the balance remaining unpaid will be carried forward to the next year’s Equal Monthly
            Payment Plan (EMPP).
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/03/2022'} />
      </div>

      <FeedbackBox source="/expenses/bills/bill-payments/text/short/03-jun-2022/02" />
    </>
  );
};
