import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const FixedOrFloatingEnergyRate: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Fixed or Floating Energy Rate</h1>

        <p className={styles.bodyText}>
          More than 90% of Canadians pay their electricity bills based on floating rates. They do not lock-in their
          electricity prices and choose to pay on a monthly basis based on prevailing market price of the electricity.
        </p>

        <h3>What is Fixed Energy Rate</h3>

        <p className={styles.bodyText}>
          Fixed rate plans give you a single, guaranteed rate for your electricity or natural gas that does not change
          during the contract period, regardless of current price in the market.
        </p>

        <p className={styles.bodyText}>
          The total energy bill you pay still remains variable because only the price has been fixed, while your
          quantity used may vary from month to month.
        </p>

        <h3 className={styles.paraHeading}>Fixed Rate Electricity Plans</h3>

        <p className={styles.bodyText}>
          Fixed rate electricity contracts have varied terms between 3 to 36 months. Price is guaranteed during this
          contract period.
        </p>

        <h3 className={styles.paraHeading}>Advantages</h3>

        <ul className={styles.list}>
          <li>In a market where price is raising, fixed rate plans will save you the most money.</li>
          <li>Guaranteed price eliminates uncertainty.</li>
          <li>Facilitate budget planning since electricity price is more predictable.</li>
          <li>Flexible terms allow to set shorter term contracts.</li>
          <li>
            Due to competition, energy suppliers offer a wide selection of fixed energy deals. You can choose the best
            suited to your usage pattern.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Disadvantages</h3>
        <ul className={styles.list}>
          <li>
            Fixed rate is generally higher than current market rate since, there is a cushion for possible increase.
          </li>
          <li>There is no benefit if the market price goes down.</li>
          <li>Hefty cancellation charges are applied if contact is cancelled before the end of an agreed term.</li>
          <li>Fixed rate plan options are complex, and it may take a while to understand them.</li>
          <li>
            Price guarantee is only for a limited time of the contract period after which you either go back to current
            market price or find a new suitable fixed rate contract.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/03/2022'} />
      </div>

      <FeedbackBox source="/expenses/bills/utility-bills/text/short/03-jun-2022/01" />
    </>
  );
};
