export const signUpProvinceOptions = [
  {
    value: 'AB',
    label: 'Alberta',
  },
  {
    value: 'BC',
    label: 'British Columbia',
  },
  {
    value: 'MB',
    label: 'Manitoba',
  },
  {
    value: 'NB',
    label: 'New Brunswick',
  },
  {
    value: 'NL',
    label: 'Newfoundland and Labrador',
  },
  {
    value: 'NS',
    label: 'Nova Scotia',
  },
  {
    value: 'ON',
    label: 'Ontario',
  },
  {
    value: 'PE',
    label: 'Prince Edward Island',
  },
  {
    value: 'QC',
    label: 'Quebec',
  },
  {
    value: 'SK',
    label: 'Saskatchewan',
  },
  {
    value: 'YT',
    label: 'Yukon',
  },
  {
    value: 'NT',
    label: 'Northwest Territories',
  },
  {
    value: 'NU',
    label: 'Nunavut',
  },
];

export const signUpSexOptions = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
];

export const signUpEducationOptions = [
  {
    value: 'High School',
    label: 'High School',
  },
  {
    value: 'Associate Degree',
    label: 'Associate Degree',
  },
  {
    value: 'Bachelor’s Degree',
    label: 'Bachelor’s Degree',
  },
  {
    value: 'Master’s Degree',
    label: 'Master’s Degree',
  },
  {
    value: 'Doctoral Degree',
    label: 'Doctoral Degree',
  },
  {
    value: 'Professional Degree',
    label: 'Professional Degree',
  },
];

export const signUpMaritalStatusOptions = [
  {
    value: 0,
    label: 'Never Married',
  },
  {
    value: 1,
    label: 'Married',
  },
  {
    value: 2,
    label: 'Living with Partner',
  },
  {
    value: 3,
    label: 'Separated',
  },
  {
    value: 4,
    label: 'Divorced',
  },
  {
    value: 5,
    label: 'Widowed',
  },
];

export const numberOfChildrenOptions = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
];

export const countryOptions = [
  {
    value: 'Canada',
    label: 'Canada',
  },
];
