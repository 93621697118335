import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const MakeItFailProof: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Make It Fail Proof</h1>

        <p className={styles.bodyText}>
          We all know by now, making bill payments on time is mission critical. System is pretty unforgiving when it
          comes to making payments and margin of error simply does not exist.
        </p>
        <p className={styles.bodyText}>
          Obviously, you need a system which is fail proof with multiple safety nets. We suggest the following:
        </p>

        <ol className={styles.list}>
          <li>
            <strong>Adopt T Minus Five:</strong> Move up the payment deadline by five calendar days. This will allow to
            build some cushion then rushing at the last minute. It also allows transit time needed to reach the fund to
            service provider or lender&apos;s account.
          </li>
          <li>
            <strong>Automate Payment:</strong> Pre-authorize the service provider or lender payment to charge either
            your account or credit card. This would ensure the bills are paid on time. Reminders are still helpful since
            funding has to be arranged. Ensure that the funding is available on the due date, or else this system will
            backfire. Service provider and your bank both will charge you for Non-Sufficient Funds (NSF).
          </li>
          <li>
            <strong>Prepay One Billing Cycle:</strong> Estimate the maximum amount of bill for a billing cycle and pay
            it in advance. Then continue to pay bills as usual. This prepayment will remain as a cushion, in case there
            are some delays in payments. Pay when you have some additional funding available. If the funding is not
            available, then build the cushion slowly. As little as 20-25% per bills will build the over 4 to 5 billing
            cycle.
          </li>
          <li>
            <strong>Follow Calendar and Reminders:</strong> Keep track of your Arctic Rich Calendar and Reminders. This
            will give you a visibility of your upcoming commitments and cash flow.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>
        <PublishedDate date={'10/18/2021'} />
      </div>

      <FeedbackBox source="/billpayments/text/short/18-oct-2021/02" />
    </>
  );
};
