import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TaxRates: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tax Rates and Brackets</h1>

        <ul className={styles.list}>
          <li>Federal Personal Tax Rates remains unchanged in 2021.</li>
          <li>
            Federal Personal Tax Brackets have been adjusted for inflation in 2021.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'01/23/2022'} />
      </div>

      <FeedbackBox source="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/01" />
    </>
  );
};
