import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DontHaveTime: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>I Don&apos;t Have Time</h1>

        <p className={styles.bodyText}>People often say, "I don&apos;t have time". We hear this so many times.</p>

        <p className={styles.bodyText}>
          This is particularly common when the question of achieving a life-transforming goal like losing weight, doing
          exercise, taking an advanced degree, building wealth etc. comes up.
        </p>

        <p className={styles.bodyText}>
          Although it may sound simple, virtually each of these goals are actually a full scope project involving
          multiple steps, which we call milestones.
        </p>

        <p className={styles.bodyText}>
          The real problem is not the shortage of time but lack of having a crystal-clear idea about what exactly needs
          to be done. In other words, how many steps are involved to achieve that goal, what those are and how they are
          sequenced.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          However, if these steps are clarified even before a goal is undertaken, scope of each step is clearly laid
          out, then subsequent steps become surprisingly simple and easy. People would find most of these steps usually
          take less than 5 minutes to complete and can easily be accommodated into their open pockets of daily schedule.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'07/27/2022'} />
      </div>
      <FeedbackBox source="/calendar/time-management/text/short/27-jul-2021/01" />
    </>
  );
};
