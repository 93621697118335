import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { MilestoneFormInputs } from './types';
import { DatePicker, Form, Input, Select, SubmitButton } from '../../components/Form';
import { selectMilestonesByGoalId, selectOnlyCompleteGoals } from './goalSlice';
import { MilestonePrioritySelect } from './MilestonePrioritySelect';
import { MilestoneLimitWarning } from './MilestoneLimitWarning';
import { Control, useWatch } from 'react-hook-form';

const schemaResolver = yupResolver<MilestoneFormInputs>(
  yup.object().shape({
    goal: yup.number().typeError('please select a goal').required('please select a goal'),
    priority: yup.number().typeError('please select a milestone').required('please select a milestone'),
    description: yup.string().required('please enter a description'),
    target_date: yup.string().required('please enter a target date'),
    status: yup.string().required('please select a status'),
  }),
);

const milestoneStatusOptions = [
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Overdue', label: 'Overdue' },
  { value: 'Complete', label: 'Complete' },
];

export interface MilestoneWarningProps {
  name: string;
  control?: Control;
  currentPriority?: number;
}

const MilestoneWarning: React.FC<MilestoneWarningProps> = ({ control, currentPriority }) => {
  const selectedPriorityValue = useWatch({ control, name: 'priority' }) as string;
  const selectedGoalId = useWatch({ control, name: 'goal' }) as string;
  const activeMilestones = useSelector(selectMilestonesByGoalId(+selectedGoalId));

  let message = '';

  // going over all the possible milestones (1, 2, 3, 4, 5) and checking if they already exist

  ['1', '2', '3', '4', '5'].forEach((iteratedMilestonePriority) => {
    const numericMilestonePriority = parseInt(iteratedMilestonePriority, 10);

    if (selectedPriorityValue === iteratedMilestonePriority && currentPriority !== numericMilestonePriority) {
      const milestoneAlreadyExists =
        activeMilestones.findIndex((milestone) => milestone.priority === numericMilestonePriority) > -1;

      if (milestoneAlreadyExists) {
        message = `Current Milestone ${iteratedMilestonePriority} will be Milestone ${currentPriority}.`;
      }
    }
  });

  if (message) {
    return (
      <div className="invalid-feedback" style={{ display: 'block', marginTop: '-25px' }}>
        {message}{' '}
      </div>
    );
  }
  return null;
};

export type MilestoneFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<MilestoneFormInputs>>;
  onSubmit: SubmitHandler<MilestoneFormInputs>;
  onMilestoneLimitReached?: () => void;
};

export const MilestoneForm: React.FC<MilestoneFormProps> = ({
  defaultValues = {},
  onSubmit,
  onMilestoneLimitReached,
}) => {
  const activeGoals = useSelector(selectOnlyCompleteGoals(false));

  const goalIdsOptions = activeGoals.map((goal) => ({
    value: goal.id,
    label: goal.description ?? goal.preset_name_display,
  }));

  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <MilestoneLimitWarning goalList={activeGoals} onMilestoneLimitReached={onMilestoneLimitReached} />
        <Select name="goal" options={goalIdsOptions} placeholder="Goal" disabled={!!defaultValues.goal} />
        <MilestonePrioritySelect name="priority" hasPriority={!!defaultValues.priority} />
        {defaultValues?.priority ? (
          <MilestoneWarning name="priority_warning" currentPriority={defaultValues.priority} />
        ) : (
          <span />
        )}
        <Input name="description" placeholder="Description" label="Description" maxLength={20} />
        <DatePicker name="target_date" placeholder="Target Date" />
        <Select name="status" options={milestoneStatusOptions} placeholder="Status" />
        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
