import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const CreditScoreVsCreditReport: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Credit Score Vs. Credit Report</h1>

        <h2 className={styles.subHeading}>Credit Score</h2>
        <p className={styles.bodyText}>
          A Credit Score is a single numeric value between 300-900, representing an individual&apos;s credit standing at
          any specific point in time. Credit Score indicates an individual&apos;s capacity to undertake debt obligation
          and more importantly to pay the debt back on time. A short summary of the Credit Score can be requested
          anytime. Credit Scores are not free and applicants need to pay for it.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Credit Report</h2>
        <p className={styles.bodyText}>
          A credit report is a detailed report describing the entire credit history of an individual person. This report
          includes personal information, employment history, and a list of current and past credit accounts. Generally,
          a credit report has four sections: a) Identity Information, b) Credit Accounts, c) Credit Inquiries, and d)
          Public Records. Canadians are entitled to one free credit report every year from each of Canada&apos;s two
          credit bureaus, Equifax and TransUnion. However, this free report doesn’t include the Credit Score.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Conclusion</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          A Credit Score and a Credit Report are different but they are related to each other. A Credit Score indicates
          the overall standing of a borrower while Credit Report details the basis of arriving at that standing. To
          improve Credit Score, each of the points on the Credit Report need to be addressed. Both are generated by the
          same institutions. Both are important for the borrower since lenders my look up one or both before granting
          any credit. <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'08/25/2021'} />
      </div>

      <FeedbackBox source="/creditscore/managingcreditscore/thebasics/text/short/25-aug-2021/1" />
    </>
  );
};
