import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const CreditScoreVsInterestRate: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: 'Credit Score',
    },
    {
      dataField: 'o1',
      text: 'Mortgage',
    },
    {
      dataField: 'o2',
      text: 'Auto Loan',
    },
    {
      dataField: 'o3',
      text: 'Credit Card',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Credit Score vs. Interest Rate</h1>

        <p className={styles.bodyText}>
          There is a direct relationship between your credit score and the interest rate you pay for different credit
          facilities.
        </p>

        <p className={styles.bodyText}>
          This is very real and will make a significant difference in terms of the interest you pay.
        </p>

        <p className={styles.bodyText}>
          Following chart details the interest rate charged based on individual’s credit scores in the United States.
        </p>

        <SimpleTable
          keyField="id"
          data={[
            {
              o0: (
                <span>
                  <strong>Bad </strong>(300–549)
                </span>
              ),
              o1: '8.60%',
              o2: '17.90%',
              o3: '24.99%',
            },
            {
              o0: (
                <span>
                  <strong>Poor </strong>(550–619)
                </span>
              ),
              o1: '5.70%',
              o2: '11.50%',
              o3: '23.99%',
            },
            {
              o0: (
                <span>
                  <strong>Fair </strong>(620–679)
                </span>
              ),
              o1: '4.80%',
              o2: '7.25%',
              o3: '16.99%',
            },
            {
              o0: (
                <span>
                  <strong>Good </strong>(680-739)
                </span>
              ),
              o1: '4.10%',
              o2: '4.05%',
              o3: '13.49%',
            },
            {
              o0: (
                <span>
                  <strong>Excellent </strong>(740–850)
                </span>
              ),
              o1: '3.80%',
              o2: '3.24%',
              o3: '11.99%',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: FICO</div>

        <p className={styles.bodyText}>
          Although the credit score matrix and actual interest rates charged are slightly different in USA than in
          Canada, the underlying principles driving the differences among different credit scores are exactly the same
          and spreads among the different classes of credit score are almost identical.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'01/23/2022'} />
      </div>

      <FeedbackBox source="/creditscore/impacton-bottomline/text/short/23-jan-2022/01" />
    </>
  );
};
