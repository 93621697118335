import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';

export const Page0009: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">New Scope</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        We redefine the scope of our work compared to what the industry offers today. We partner with our clients from
        the beginning of the process and extending support until the end. Our offer is an end-to-end solution rather
        than a set of stand-alone products.
      </Typography>
      <Typography variant="p">We will go through the following five steps:</Typography>
      <Typography variant="p">
        <span className="font-weight-bold">Learn:</span> Before we begin, we will learn few rules of this game and the
        most common traits that made others successful.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">Plan:</span> Our next step is to set our mind on where we want to go and set
        a course of action on how could to get there.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">Save:</span> Now it is time for action and we will begin by starting to save.
        Our focus at the beginning of the journey is to start saving rather than how much we save.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">Invest:</span> Once we save some money, we start investing or plating the
        seed. We will continue to explore different investment options available to us.
      </Typography>
      <Typography variant="p">
        <span className="font-weight-bold">Use:</span> Finally, we will jointly explore how to use our funds more
        prudently for the purpose we saved in first the place.
      </Typography>
      <Typography variant="p">
        This strategy is in sharp contrast to the services provided by today’s industry which only engage when people
        have enough money to invest.
      </Typography>
      <Typography variant="p">We have organized our entire structure based on these five simple steps.</Typography>
    </Container>
  );
};
