import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { SimpleTable } from './SimpleTable';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhenToAdopt5G: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: '',
    },
    {
      dataField: 'o1',
      text: '3G',
    },
    {
      dataField: 'o2',
      text: '4G',
    },
    {
      dataField: 'o3',
      text: '5G',
    },
  ];
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>When to Adopt 5G</h1>

        <p className={styles.bodyText}>
          In telecommunications, 5G stands for the 5th Generation Technology Standard for the broadband cellular
          networks. 5G Technology was introduced in 2019 and the adoption has just been increasing since then. It all
          boils down to speed. New iPhone 12 on 5G is now 10 times faster than 4G and promised to be 100 times faster
          eventually. Following information will help to decide whether and when to switch.
        </p>

        <ul className={styles.list}>
          <li>
            Current 4G meets most of the speed requirements for any average user. In rare cases (3-5% of situations),
            network congestion during peak hours may hinder user experience.
          </li>
          <li>
            To enjoy 5G Technology, a 5G enabled device will be needed, i.e. buying a new device would be mandatory.
          </li>
          <li>
            Technically, 5G does not use more data than 4G. However, more data usage is likely with 5G (currently up to
            2.7 times) as users take advantage of more advanced features. Almost certainly, a higher data plan may be
            necessary.
          </li>
          <li>
            5G network coverage is still at a very early stage covering mainly highly populated metropolitan areas. Even
            with 5G device and plan, but without 5G network coverage (towers or antennas in the usage area), 5G will not
            work.
          </li>
          <li>5G network will drain the battery of the device much faster.</li>
        </ul>
        <br />
        <h2 className={styles.paraHeading}>Highlights</h2>

        <SimpleTable
          keyField="o0"
          data={[
            { o0: 'Date of Launch', o1: '2001', o2: '2009', o3: '2019' },
            { o0: 'Technology Level', o1: 'Basic, Outdate', o2: 'Moderate, Current', o3: 'Advanced, Futuristic' },
            { o0: 'Average Upload Speed', o1: '1-2 mbps *', o2: '25 mbps', o3: '300 mbps' },
            { o0: 'Download Time 2-Hour Movie', o1: '26 Hours', o2: '6 Minutes', o3: '4 Seconds' },
            { o0: 'No of Devices Supported per sq km', o1: 'N/A', o2: '4-Thousand', o3: '1-Million' },
            { o0: 'Video Support', o1: 'N/A', o2: '1080 pixel', o3: '4K pixel' },
          ]}
          columns={columns}
          bootstrap4
        />
        <p className={styles.chartSource}>
          * mbps = Megabytes per second.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'08/25/2021'} />
      </div>

      <FeedbackBox source="/bills/managingbills/phone/text/short/25-aug-2021/02" />
    </>
  );
};
