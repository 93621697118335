import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const CanadaGreenerHomes: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Canada Greener Homes</h1>

        <ul className={styles.list}>
          <li>Details on Canada Greener Homes program is expected to be announced this year (2022).</li>
          <li>
            Under this program, you will be able to make your home more energy efficient financed by government grants
            and interest-free loans.
          </li>
          <li>Over seven years, $2.6 billion to be invested under the Canada Green Homes Grant Program.</li>
          <li>
            Under this program, about 700,000 Canadian Homeowners will be able to upgrade their homes to be more energy
            efficient and reduce their energy bills.
          </li>
          <li>Homeowners are expected to receive up to $5,000 to add energy efficient equipment in their homes.</li>
          <li>
            Homeowners may also receive up to $600 assistance for evaluation of their home energy consumption.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'04/08/2022'} />
      </div>

      <FeedbackBox source="/home/the-basics/text/short/08-apr-2022/01" />
    </>
  );
};
