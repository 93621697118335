import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { LineChart } from './LineChart';

export const KeepingCarLonger: React.FC = () => {
  const lineData = [
    {
      id: 'Light Trucks',
      color: '#0000ff',
      data: [
        {
          x: '2001',
          y: 9.4,
        },
        {
          x: '2002',
          y: 9.4,
        },
        {
          x: '2003',
          y: 9.4,
        },
        {
          x: '2004',
          y: 9.4,
        },
        {
          x: '2005',
          y: 9.5,
        },
        {
          x: '2006',
          y: 9.6,
        },
        {
          x: '2007',
          y: 9.7,
        },
        {
          x: '2008',
          y: 10.0,
        },
        {
          x: '2009',
          y: 10.4,
        },
        {
          x: '2010',
          y: 10.7,
        },
        {
          x: '2011',
          y: 11.0,
        },
        {
          x: '2012',
          y: 11.2,
        },
        {
          x: '2013',
          y: 11.3,
        },
        {
          x: '2014',
          y: 11.5,
        },
        {
          x: '2015',
          y: 11.5,
        },
        {
          x: '2016',
          y: 11.5,
        },
        {
          x: '2017',
          y: 11.6,
        },
        {
          x: '2018',
          y: 11.6,
        },
        {
          x: '2019',
          y: 11.6,
        },
        {
          x: '2020',
          y: 11.6,
        },
      ],
    },
    {
      id: 'Cars',
      color: '#ff0000',
      data: [
        {
          x: '2001',
          y: 9.8,
        },
        {
          x: '2002',
          y: 9.9,
        },
        {
          x: '2003',
          y: 10.0,
        },
        {
          x: '2004',
          y: 10.1,
        },
        {
          x: '2005',
          y: 10.2,
        },
        {
          x: '2006',
          y: 10.2,
        },
        {
          x: '2007',
          y: 10.3,
        },
        {
          x: '2008',
          y: 10.5,
        },
        {
          x: '2009',
          y: 10.8,
        },
        {
          x: '2010',
          y: 11.0,
        },
        {
          x: '2011',
          y: 11.2,
        },
        {
          x: '2012',
          y: 11.3,
        },
        {
          x: '2013',
          y: 11.4,
        },
        {
          x: '2014',
          y: 11.5,
        },
        {
          x: '2015',
          y: 11.6,
        },
        {
          x: '2016',
          y: 11.7,
        },
        {
          x: '2017',
          y: 11.9,
        },
        {
          x: '2018',
          y: 12.2,
        },
        {
          x: '2019',
          y: 12.4,
        },
        {
          x: '2020',
          y: 12.8,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Keeping Car Longer </h1>

        <ul className={styles.list}>
          <li>
            Average of vehicles in USA reached record high level at 12.1 years in 2021, according to research firm IHS
            Markit.
          </li>
          <li>
            Due to better quality, cars are lasting longer.
            <div className={styles.chartWrapper}>
              <h4 className={styles.chartHeader}>Average age of light vehicles in the U.S.</h4>

              <div className={styles.chartContainer}>
                <LineChart
                  data={lineData}
                  xLegendLabel="Year"
                  yLegendLabel="Average age (years)"
                  yToolTipLabel="Average age"
                  extraProps={{
                    margin: { top: 20, right: 200, bottom: 70, left: 70 },
                    axisBottom: {
                      tickRotation: -45,
                      orient: 'bottom',
                      tickSize: 5,
                      tickPadding: 5,
                      legend: 'Year',
                      legendOffset: 60,
                      legendPosition: 'middle',
                    },
                    yFormat: ',',
                    yScale: {
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: false,
                      reverse: false,
                    },
                    colors: (d: { color: string }) => d.color,
                    legends: [
                      {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      },
                    ],
                  }}
                />
              </div>
              <p className={styles.chartSource}>Source: IHS Market</p>
            </div>
          </li>
          <li>
            In year 2000, a car lasted approximately 150,000 kilometers. Today a car can easily last for more than
            300,000 kilometer and expected to change multiple hands.
          </li>
          <li>This extended life cycle offers better value for consumers.</li>
          <li>
            Since 2013, the total number of vehicles in operation in the U.S. has increased by 10%, to 279 million.
          </li>
          <li>Sales of new vehicles are also at record levels. </li>
          <li>
            This means, increase in average vehicle age doesn&apos;t mean Americans are not buying new cars and getting
            by with their older cars. In contrast, consumers are increasing their number of household vehicles, e.g.
            buying a third car.{' '}
          </li>
          <li>
            Prices for new vehicles are at record highs due to the pandemic and supply challenges. J.D. Power reported
            that the average price paid for an average new vehicle in May 2022 was US$38,255.{' '}
          </li>
          <li>
            Used car prices have also increased. In May 2022, the average used car prices rose 48% compared to the same
            period last year.
          </li>
          <li>
            To respond to the longer lives of the cars, auto makers will be offering you digital services and added
            features after the sale like new apps or hands-free driving to generate recurring revenue.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/03/2022'} />
      </div>

      <FeedbackBox source="/auto/car/text/short/03-jun-2021/01" />
    </>
  );
};
