import React from 'react';
import clsx from 'clsx';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AlertItem } from './types';
import styles from './Alerts.module.scss';

import { AlertIndicator } from './AlertIndicator';
import { AlertActions } from './AlertActions';

// import image
import smallLogo from '../../common/images/small-logo.png';
interface AlertContentProps {
  item: AlertItem;
  index: number;
}

const AlertContent = ({ item, index }: AlertContentProps) => {
  const body: string = item.body;

  return (
    <Accordion.Collapse eventKey={index + ''}>
      <div className={clsx(styles.listItem)}>
        <Row noGutters>
          {/* invisible content start */}
          <Col className="col-auto">
            <AlertIndicator
              invisible={true}
              item={item}
              activeKey={'0'}
              index={index}
              isOpened={false}
              selected={false}
              onChangeSelect={() => {
                console.log('');
              }}
            />
          </Col>
          {/* invisible content end */}

          <Col className={clsx(styles.borderBottom)}>
            <div className={clsx('pl-0', styles.alertContent)}>
              <div className="media">
                <div className="mr-3">
                  <img src={smallLogo} className={clsx(styles.logo)} alt="company logo" />
                </div>
                <div className="media-body">
                  <div className={clsx(styles.contentBody)}>
                    <div dangerouslySetInnerHTML={{ __html: body }}></div>
                  </div>
                  {/* <div className={clsx(styles.contentTitleBox)}>
                    <h6 className={clsx(styles.contentTitle, 'm-0')}>Arctic Rich</h6>
                  </div>
                  <div className={clsx(styles.contentBody)}>
                    <p>Hi Nancy:</p>
                    <p>
                      Please be adviced that your current debt utilization level is 76% and approaching the maximum
                      tolerance level of 80%.
                    </p>
                    <p>We would appreciate your attention in this regard.</p>
                    <p> Kind regards,</p>
                    <p className="mb-0"> Arctic Rich Team</p>
                  </div> */}
                </div>
              </div>
            </div>
          </Col>

          {/* invisible content start */}
          <Col className={clsx('col-auto', styles.borderBottom)}>
            <AlertActions
              invisible={true}
              item={item}
              onToggleFlag={() => {
                console.log('');
              }}
              onDelete={() => {
                console.log('');
              }}
            />
          </Col>
          {/* invisible content end */}
        </Row>
      </div>
    </Accordion.Collapse>
  );
};

export { AlertContent };
