import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const BorrowingForRRSPContribution: React.FC = () => {
  const columns = [
    {
      dataField: 'return',
      text: 'RRSP Required Return',
      headerAttrs: {
        hidden: 'true',
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      headerAttrs: {
        hidden: 'true',
      },
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Borrowing for RRSP Contribution</h1>

        <p className={styles.bodyText}>
          Borrowing for making RRSP contribution may not be a great option for the consumers. Here is why:
        </p>

        <ol className={styles.list}>
          <li>Saving is a round the year effort - not just one time event.</li>
          <li>Unused RRSP Contribution Limit does not expire and is carried forward to subsequent years.</li>
          <li>
            You will need a higher rate of return on investment due to added cost of borrowing.
            <p className={styles.listText}>
              <strong>Example</strong>
            </p>
            <p className={styles.listText}>
              You intend to invest $100 of your own money in RRSP and you expect to earn 5% or $5. Now let’s say you
              borrowed $100 at 6% annual interest rate to contribute to RRSP.
            </p>
            <SimpleTable
              keyField="rrsp"
              data={[
                { return: 'RRSP Required Return', amount: '$5' },
                { return: 'Plus: Interest to be paid to the Lender', amount: '$6' },
                { return: 'Total Required Return', amount: '$11' },
              ]}
              columns={columns}
              bootstrap4
            />
            <p className={styles.listText}>
              Now you have to earn $5 + $6 = $11 in first year instead of $5, because you have to pay $6 to the lender
              as interest for the money your borrowed.
            </p>
            <p className={styles.listText}>
              So, your required rate of return from investment increases from 5% to 11% for the RRSP contribution made
              by borrowed money compared to your own money for the same investment.
            </p>
          </li>
          <li>
            Interest on money borrowed for RRSP contribution is not Tax deductible. Therefore, you have to earn more of
            before-tax income to pay $6 interest.
            <p className={styles.listText}>
              <strong>Example</strong>
            </p>
            <p className={styles.listText}>
              In the previous example, you have to earn $8.57, to pay $6 after tax interest, considering your tax rate
              is 30%.
            </p>
            <p className={styles.listText}>$8.57 - ($8.57 X 30%) = $6.00.</p>
            <p className={styles.listText}>
              The required rate of return could be astronomically higher at 19% to 26% if you have borrowed at a much
              higher rate like 10 to 15%.
            </p>
          </li>
          <li>
            The incremental borrowing adds up with other borrowings and snowball to a much bigger size, which can
            quickly turn into an unmanageable size.
          </li>
          <li>
            If invested in equity, the investment value may reduce in a very short period, but the loan amount will
            remain the same. In case of emergency, you will not be able to sale your investment and pay off your debt.
            Your financial institution will also deduct Withholding Tax before handing you over the money.
            <p className={styles.listText}>
              <strong>Example</strong>
            </p>
            <p className={styles.listText}>
              You borrow $100 at 6% interest from your RRSP Contribution and invest it in shares. Value of those shares
              reduces to $80 after one year. We still have to pay back $100 loan with interest of $6.
            </p>
          </li>
          <li>
            Financial Institutions encourage to borrow during the tax season for making RRSP contribution. They could
            offer an investment as well as loan product. Naturally, it is a good business opportunity for the Financial
            Institutions. But it certainly does not make a great business sense for the consumers.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'05/07/2022'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/07-may-2022/02" />
    </>
  );
};
