import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import HelpBox from '../components/Common/HelpBox';
import infoImg from '../common/images/info.svg';
import { SimpleTable } from './SimpleTable';

export const EVRankBySales: React.FC = () => {
  const columns = [
    {
      dataField: 'rank',
      text: 'Rank',
    },
    {
      dataField: 'model',
      text: 'Model',
    },
    {
      dataField: 'sold',
      text: 'Units Sold',
    },
    {
      dataField: 'basePrice',
      text: 'Base Price',
    },
    {
      dataField: 'standardRange',
      text: 'Standard Range',
    },
    {
      dataField: 'longRange',
      text: 'Long Range',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Electric Vehicle in USA
          <HelpBox placement="right" content={<p className="mb-0">Source: US News</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>
        <h2 className={styles.subHeading}>12 Bestselling Models of 2021</h2>

        <p className={styles.bodyText}>
          There were over 20 Electric Vehicles for sale in the United States in 2021. Following 12 were top of the list
          by sales volume.:
        </p>

        <SimpleTable
          keyField="ownHeater"
          data={[
            {
              rank: '1',
              model: 'Tesla Model Y',
              sold: '172,700',
              basePrice: 'US$41,190',
              standardRange: '393 km',
              longRange: '525 km',
            },
            {
              rank: '2',
              model: 'Tesla Model 3',
              sold: '128,600',
              basePrice: 'US$38,690',
              standardRange: '423 km',
              longRange: '568 km',
            },
            {
              rank: '3',
              model: 'Ford Mustang Mach-E',
              sold: '27,140',
              basePrice: 'US$43,995',
              standardRange: '370 km',
              longRange: '491 km',
            },
            {
              rank: '4',
              model: 'Chevrolet Bolt EV + EUV',
              sold: '24,803',
              basePrice: 'US$37,495',
              standardRange: '416 km',
              longRange: '-',
            },
            {
              rank: '5',
              model: 'Volkswagen ID.4',
              sold: '16,742',
              basePrice: 'US$41,190',
              standardRange: 'US$41,190',
              longRange: '-',
            },
            {
              rank: '6',
              model: 'Nissan Leaf',
              sold: '14,239',
              basePrice: 'US$32,620',
              standardRange: '240 km',
              longRange: '364 km',
            },
            {
              rank: '7',
              model: 'Audi e-tron + e-tron',
              sold: '10,921',
              basePrice: 'US$66,995',
              standardRange: '350 km',
              longRange: '-',
            },
            {
              rank: '8',
              model: 'Porsche Taycan',
              sold: '9,419',
              basePrice: 'US$81,250',
              standardRange: '322 km',
              longRange: '365 km',
            },
            {
              rank: '9',
              model: 'Tesla Model S',
              sold: '9,100',
              basePrice: 'US$81,190',
              standardRange: '647 km',
              longRange: '-',
            },
            {
              rank: '10',
              model: 'Hyundai Kona Electric',
              sold: '8,936',
              basePrice: 'US$38,575',
              standardRange: '415 km',
              longRange: '-',
            },
            {
              rank: '11',
              model: 'Tesla Model X',
              sold: '3,000',
              basePrice: 'US$91,190',
              standardRange: '597 km',
              longRange: '-',
            },
            {
              rank: '12',
              model: 'Hyundai Ioniq Electric',
              sold: '1,766',
              basePrice: 'US$34,250',
              standardRange: '273 km',
              longRange: '-',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={styles.noteHeading}>Note</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          Not all carmakers provide sales data for their EVs, especially in cases where the electric models share a
          nameplate with a gas or hybrid model.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/06/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-II/text/short/06-jul-2022/01" />
    </>
  );
};
