import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import infoImg from '../common/images/info.svg';
import { PublishedDate } from './PublishedDate';

export const MinimumMonthlyPayment: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Minimum Monthly Payment
          <HelpBox
            placement="right"
            content={
              <>
                <p className="mb-1">Source: </p>
                <p className="mb-1">1. Equifax</p>
                <p className="mb-1">2. TransUnion</p>
                <p className="mb-0">3. Government of Canada </p>
              </>
            }
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <h2 className={styles.paraHeading}>Design</h2>

        <ul className={styles.list}>
          <li>
            Minimum monthly payment is designed by the lenders to stretch the life of the debt as much as possible.{' '}
          </li>
          <li>The main purpose of such design is to maximize lender’s profit. </li>
        </ul>

        <p className={styles.bodyText}>How the minimum monthly debt payments will affect you:</p>

        <h2 className={styles.paraHeading}>1. Encourage to Spend More</h2>
        <p className={styles.bodyText}>
          Low minimum monthly payment allows you to have more monthly free cashflow and encourage you to spend more
          leading to more borrowings.
        </p>

        <h2 className={styles.paraHeading}>2. Take Longer to Repay</h2>
        <p className={styles.bodyText}>
          The lenders set a small amount debt repayment schedule at a rock bottom level in such a way that the loan
          remains current in their book. However, the principal repayment turns out to be so insignificant that the
          borrower will have to bear the debt burden for a very long period.
        </p>

        <h2 className={styles.paraHeading}>3. Pay More Interest</h2>
        <p className={styles.bodyText}>
          If you decide only to pay the minimum monthly payment, then you will end up paying a lot more interest.
        </p>

        <h2 className={styles.paraHeading}>4. Lower Credit Score</h2>
        <p className={styles.bodyText}>
          Making only minimum payment will lower your credit score. It will be difficult to obtain new credit and if
          approved you will have to pay a higher interest rate. Credit agencies recommend not to exceed 30% utilization
          level in any of your credit card limits.
        </p>

        <h2 className={styles.paraHeading}>5. Roadblock to Wealth Building</h2>
        <p className={styles.bodyText}>
          Continuing to pay interest and debt principal limits resources available for investments. Instead of
          benefiting from the compounding interest and enriching yourself, you remain busy paying the interest and
          enriching your lender.
        </p>

        <h2 className={styles.paraHeading}>Calculation of Minimum Monthly Payments</h2>
        <p className={styles.bodyText}>
          Look up your Credit Agreement and review how your lender will calculate the minimum monthly payments. Ask your
          lender about the details before committing or signing the credit agreement.
        </p>
        <p className={styles.bodyText}>For credit cards, it is generally</p>
        <ol className={styles.list}>
          <li>A fixed dollar amount, usually minimum of $10, plus the applicable fees and interests. Or</li>
          <li>
            A fixed dollar amount, usually minimum of $10, or a percentage of the outstanding balance, generally 3% -
            whichever is higher.
          </li>
        </ol>

        <h2 className={styles.paraHeading}>Missing Minimum Monthly Payments</h2>
        <p className={styles.bodyText}>Missing minimum monthly payments would be consequential, your</p>
        <ol className={styles.list}>
          <li>Interest rate will almost certainly go up</li>
          <li>Any promotional interest rate offer will likely be cancelled.</li>
          <li>Credit score will be affected, and late payment records will be shown in your credit report.</li>
          <li>Credit card could be cancelled.</li>
        </ol>

        <h3 className={styles.paraHeading}>12 Month Repayment Rule</h3>
        <p className={styles.bodyText}>
          Before undertaking any new credit or loan, which does not have a fixed maturity or repayment schedule like
          auto loan or mortgage, make sure you are comfortable in repaying entire borrowed fund within next 6-12 months.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          If you can’t make full repayment within maximum of 12 months and it is not an emergency expense like
          healthcare, then we strongly recommend not to proceed. Wait until other debts are paid off or look for other
          alternatives, better bargains etc.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'03/26/2022'} />
      </div>

      <FeedbackBox source="/debt/understandingfundamentals/text/short/26-mar-2022/02" />
    </>
  );
};
