import React, { useCallback, useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { Form, Input, SubmitButtonLoader } from '../../components/Form';
import PasswordHelpBox from '../../components/Common/PasswordHelpBox';
import { SignUpStep1FormInputs } from './types';
import { createUser, setTokens, setStepCompleted, resetAllSteps } from './authSlice';
import { BadRequestError } from '../../helpers/api';
import { useDispatch } from 'react-redux';

import styles from './SignUpSteps.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

const schemaResolver = yupResolver<SignUpStep1FormInputs>(
  yup.object().shape({
    first_name: yup.string().required('Please enter First Name'),
    last_name: yup.string().required('Please enter Last Name'),
    email: yup.string().email('Please enter a valid Email').required('Please enter Email'),
    password: yup
      .string()
      .required('Please enter Password')
      .min(8, 'Password should be at least 8 characters long')
      .max(26, 'Password should be max 26 characters long')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must be 8-26 characters long, One uppercase, One lowercase, One number and one special character',
      )
      .test('not-match', 'Can not contain your first name, last name or email', function (value) {
        return !(
          (this.parent['first_name'] && value.includes(this.parent['first_name'])) ||
          (this.parent['last_name'] && value.includes(this.parent['last_name'])) ||
          (this.parent['email'] && value.includes(this.parent['email']))
        );
      }),
    password_confirmation: yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Both passwords need to be the same'),
    }),
  }),
);

export const SignUpStep1: React.FC = () => {
  const [formError, setFormError] = useState<string>('');
  const history = useHistory();
  const dispatch = useDispatch();

  const resetAllStepsAction = useCallback(() => dispatch(resetAllSteps()), [dispatch]);

  useEffect(() => {
    /* tslint:disable */
    if (window['fbq']) window['fbq']('track', 'PageView');
  }, []);

  useEffect(() => {
    resetAllStepsAction();
  }, [resetAllStepsAction]);

  const [showPasswordHelp, setShowPasswordHelp] = useState<boolean>(false);
  const passwordInput = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: SignUpStep1FormInputs) => {
    setLoading(true);

    try {
      const response = await createUser(data);
      setLoading(false);

      dispatch(
        setTokens({
          accessToken: response.tokens.access,
          refreshToken: response.tokens.refresh,
        }),
      );
      dispatch(setStepCompleted(1));
      history.push('/user/sign-up/address-info');
    } catch (err) {
      setLoading(false);
      if (err instanceof BadRequestError) {
        if (err.response.email && Array.isArray(err.response.email)) {
          setFormError(err.response.email[0]);
        }
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Container fluid className="px-0">
      <h3 className={styles.title}>Account Information</h3>
      {formError.length ? (
        <div className="invalid-feedback mb-2" style={{ display: 'block' }}>
          {formError}
        </div>
      ) : null}
      <Form onSubmit={onSubmit} resolver={schemaResolver}>
        <Input
          name="first_name"
          placeholder="First Name"
          className={styles.firstHalfInput + ' ' + styles.inputBlock}
          labelClassName={styles.label}
        />
        <Input
          name="last_name"
          placeholder="Last Name"
          className={styles.secondHalfInput + ' ' + styles.inputBlock}
          labelClassName={styles.label}
        />
        <Input name="email" placeholder="Email Address" className={styles.inputBlock} labelClassName={styles.label} />
        <Input
          type="password"
          name="password"
          placeholder="Create Password"
          className={styles.inputBlock}
          labelClassName={styles.label}
          onFocus={() => {
            setShowPasswordHelp(true);
          }}
          onBlur={() => {
            setShowPasswordHelp(false);
          }}
          refCallback={(ref) => {
            passwordInput.current = ref;
          }}
        />
        <PasswordHelpBox show={showPasswordHelp} target={passwordInput.current} />

        <Input
          type="password"
          name="password_confirmation"
          placeholder="Confirm Password"
          className={styles.inputBlock}
          labelClassName={styles.label}
        />
        <SubmitButtonLoader value="Continue" className={styles.submitButtonBlock} loading={loading} type="submit" />
      </Form>
    </Container>
  );
};
