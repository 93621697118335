import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';

export const Page0006: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">Learning to Value a Dollar</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        Every day, many of our hard-earned money slips through our fingers as expenses. Some of these expenses are large
        and some are small. We often tend not to pay attention to the small amounts we spend on items like a cup of
        coffee or a bottle of water. In reality, all these amounts can quickly build up.
      </Typography>
      <Typography variant="p">
        Same is true with our savings or investment. If we begin and persistently work on building our wealth, it can
        quickly build up as well. Moreover, we could get additional support from the increase in the value of our
        investments along the return on these investments.
      </Typography>

      <Typography variant="p">
        Overall, our entire journey will be centred-around one core philosophy, which is there is no such thing called
        Small. It worth our every effort to learn market intelligence, techniques and technology even that leads to a
        saving that apparently may appear to be very little on the surface.
      </Typography>
    </Container>
  );
};
