import React from 'react';
import styles from './Pages.module.scss';
import clsx from 'clsx';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const InformationKnowledge: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Information and Knowledge</h1>

        <p className={styles.bodyText}>
          Our promise is , "If any information, strategy or technology exists anywhere, you will certainly have it at
          your finger-tips".
        </p>
        <p className={styles.bodyText}>
          The information about all the service providers, price, terms, tactics and tools can arm you as a consumer
          with a sense of control and visibility that you may not have never experience before. You gradually become
          aware of the knowledge, resources, support and infrastructure around you to advance you cause.
        </p>
        <p className={styles.bodyText}>
          We urge you not be carried away with the apparent simplicity of the material. We take a lot of care and effort
          to filter out the material that you really do not need to learn and present only the essential items in very
          simple terms. We present in a form that is easy for you to understand and apply in your daily life.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          While we handle all the complexities at the back end, you enjoy a seamless experience in every step. Our role
          is to make it harder, preferably impossible, for the counterparts of your every transaction to take advantage
          of you just because you are not aware of certain information or strategy.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'06/06/2021'} />
      </div>

      <FeedbackBox source="/overview/learn/text/short/07-jun-2021/01" />
    </>
  );
};
