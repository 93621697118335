import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DebtManagement: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Debt Management</h1>

        <p className={styles.bodyText}>
          Closely monitoring the level of borrowing and capacity to borrow would be the key. Arctic Rich will assist you
          with tracking the figures, alerting you about any developments in your situation and possible action steps at
          those respective contexts.
        </p>
        <p className={styles.bodyText}>
          However, it is you who would need to take the actions to make the changes happen. We recommend getting started
          with the following:
        </p>
        <ol className={styles.list}>
          <li>
            <b>Improve Credit Score:</b> By taking care of underlying factors influencing the score, credit score can be
            systemically improved.
          </li>
          <li>
            <b>Renegotiate Interest Rates:</b> Better credit score turns you into better borrower, which means you can
            demand better interest rates - either from existing or new lenders.{' '}
          </li>
          <li>
            <b>Consolidate Debt:</b> Get rid of the expensive debt facilities and bring all of these to a single,
            well-priced facility.
          </li>
          <li>
            <b>Step-up Debt Repayment:</b> Make faster debt repayment then you had been doing in the past or your
            lenders are asking for. Avoid at all costs borrowing new money.{' '}
          </li>
          <li>
            <b>Reduce Reliance on Debt:</b> Make psychological, behavioral, and lifestyle shifts to do more with less.
            It is possible. If others can do it, you can do it too. It is not about giving up things you enjoy but when
            and how to get what you want.{' '}
          </li>
        </ol>
        <p className={clsx(styles.bodyText, styles.last)}>
          Once again, Arctic Rich will assist you with the right information and suggested actions every step of the
          way. You are not alone.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'06/13/2021'} />
      </div>

      <FeedbackBox source="/debt/managingdebt/text/short/13-jun-2021/01" />
    </>
  );
};
