import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const SettingGoal: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Setting Goal</h1>

        <p className={styles.bodyText}>
          We will first have to know, where we are going before we start our journey. This is such an important step
          that we call it mission critical.
        </p>

        <p className={styles.bodyText}>
          To be effective, a goal should ideally meet the following criteria. A goal should be:
        </p>

        <ol className={styles.list}>
          <li>Clear and specific.</li>
          <li>Measurable.</li>
          <li>Time bound.</li>
          <li>Challenging.</li>
          <li>Balanced</li>
        </ol>

        <p className={clsx(styles.bodyText, styles.last)}>
          Example: "Someday, I want to have a lot money" is not a goal. "I want to have $100 by 5.00 PM tomorrow" is a
          goal.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/18/2021'} />
      </div>

      <FeedbackBox source="/overview/text/short/18-mar-2021/01" />
    </>
  );
};
