import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const LifetimeGoals: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Lifetime Goals</h1>

        <p className={styles.bodyText}>
          Before setting up any of your personal goals, it is important to comprehend what you want to achieve in your
          life and what kind of person you would like to be. Once you articulate this big picture, the major areas of
          your life will be able to shape from this core concept.
        </p>

        <p className={styles.bodyText}>
          You may consider setting up goals for the following major areas for a balanced lifestyle.
        </p>

        <h3 className={styles.paraHeading}>1. Career</h3>

        <p className={styles.bodyText}>
          Decide what is your preferred occupation. How far would you like to progress in your career?
        </p>

        <h3 className={styles.paraHeading}>2. Money</h3>

        <p className={styles.bodyText}>
          What are your expected earnings in different phases of your life? Relate this with your occupation and career
          goals. What precent of your income would you like to save every month? How much would be your wealth when you
          retire. When do you want to retire?
        </p>

        <h3 className={styles.paraHeading}>3. Education</h3>

        <p className={styles.bodyText}>
          What kind of knowhow, skills, training, and education would you need acquire to achieve the above career and
          financial goals? When and how would you like to acquire these skills?
        </p>

        <h3 className={styles.paraHeading}>4. Health</h3>

        <p className={styles.bodyText}>
          What type of physical build you would like to achieve? How many days you would like to workout at home or in a
          gymnasium. How do you want to plan your diet? What would be your health condition when you become a senior?
        </p>

        <h3 className={styles.paraHeading}>5. Family</h3>

        <p className={styles.bodyText}>
          If or when do you want to marry? How would your ideal spouse or partner look like? Would you like to have
          kids? If yes, how many kids you would you plan for your family?
        </p>

        <h3 className={styles.paraHeading}>6. Recreation</h3>

        <p className={styles.bodyText}>
          Would you like to pursue any hobby? Where would you like to travel and when. Where and how would you like to
          spend your leisure time?
        </p>

        <h3 className={styles.paraHeading}>7. Philanthropy</h3>

        <p className={styles.bodyText}>
          How would you like to make this planet a better place for the generations to come? What are your philanthropic
          goals?
        </p>

        <h3 className={styles.paraHeading}>8. Personality</h3>

        <p className={styles.bodyText}>
          What kind of personality would you like to have? Would you like to build a positive, friendly, inspiring,
          hardworking, sincere, honest, and loving personality or vice versa?
        </p>

        <p className={styles.bodyText}>
          Do some research before finalizing your goals. Do not live someone else’s life or dreams. Choose the goals
          from your heart and those you want to achieve. Ask yourself what do you really want and are you ready to do
          what it takes to achieve these goals?
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Strat with a small list that you can focus and manage.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/29/2022'} />
      </div>

      <FeedbackBox source="/goal/basics/text/short/29-jun-2022/01" />
    </>
  );
};
