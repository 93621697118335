import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WorkingForMoney: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Working for Money</h1>

        <p className={styles.bodyText}>
          Most of us work for money, virtually all our life. Problem with this strategy is, we only have 24 hours day -
          limiting our capacity to earn.
        </p>
        <p className={styles.bodyText}>
          Rich people in contrast let their money work for them. How? It&apos;s very simple. They do not spend all they
          earn. Rather set aside a portion of their income and invest. These investments begin to generate return. Which
          they invest again.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Over time, income from their investments become so large that it exceeds their ordinary income. In other
          words, they do not need to work to earn a living anymore. Which gives them freedom to do the things they love
          or help others.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'05/23/2021'} />
      </div>

      <FeedbackBox source="/overview/text/short/23-may-2021/02" />
    </>
  );
};
