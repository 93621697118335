import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import styles from './Footer.module.scss';

const FooterContent = ({ isStaticPageLayout }: { isStaticPageLayout?: boolean }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Row>
      <Col className={isStaticPageLayout ? styles.footerContainerStaticPage : styles.footerContainer}>
        <hr className="mt-0" />
        Copyright © {currentYear} Arctic Rich Inc. All rights reserved.
      </Col>
    </Row>
  );
};
interface FooterProps {
  isFluid?: boolean;
  isStaticPageLayout?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isFluid, isStaticPageLayout }: FooterProps) => {
  return (
    <>
      {isFluid ? (
        <Container fluid>
          <FooterContent isStaticPageLayout={isStaticPageLayout} />
        </Container>
      ) : (
        <Container>
          <FooterContent isStaticPageLayout={isStaticPageLayout} />
        </Container>
      )}
    </>
  );
};
