import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import HelpBox from '../components/Common/HelpBox';
import infoImg from '../common/images/info.svg';

export const ElectricVehicleFederalGovernmentIncentives: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Electric Vehicle
          <HelpBox placement="right" content={<p className="mb-0">Source: Government of Canada.</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>
        <h2 className={styles.subHeading}>Federal Government Incentives</h2>

        <p className={styles.bodyText}>
          The Incentives for Zero-Emission Vehicles (iZEV) Program of Government of Canada helps consumers to adopt this
          clean technology and make it more affordable.
        </p>
        <p className={styles.bodyText}>
          The Incentives for Zero-Emission Vehicles (iZEV) Program offers point-of-sale incentives for consumers who buy
          or lease zero-emission vehicles (ZEVs).
        </p>

        <h3 className={styles.paraHeading}>Update: April 25, 2022</h3>
        <p className={styles.bodyText}>
          Due to increasing retail price of automobiles, the number of makes and models of eligible vehicles for
          government incentives were also expected to increase.
        </p>
        <p className={styles.bodyText}>
          To avoid any ongoing confusion during an inflationary environment, the definition of longer-range plug-in
          hybrid vehicles (PHEVs) has been changed and is now based on electric range in kilometres (km) instead of
          maximum retail price.
        </p>
        <p className={styles.bodyText}>
          Effective April 25, 2022, plug-in hybrid vehicles (PHEVs) with an electric range of 50 km or more will be
          eligible for $5,000 government incentive, and those under this threshold will continue to be eligible for
          $2,500 government incentive.
        </p>

        <p className={styles.bodyText}>
          As a result of this change, as of April 25, 2022, the following plug-in hybrid vehicles (PHEVs), are now
          eligible for up to $5,000 government incentive:
        </p>

        <ul className={styles.list}>
          <li>2020, 2021, 2022 Ford Escape plug-in hybrid vehicles (PHEVs)</li>
          <li>2022 Hyundai Santa Fe plug-in hybrid vehicles (PHEVs)</li>
          <li>2022 Hyundai Tucson plug-in hybrid vehicles (PHEVs)</li>
          <li>2022 Kia Sorento plug-in hybrid vehicles (PHEVs)</li>
        </ul>

        <h3 className={styles.paraHeading}>How to Receive the Incentive</h3>
        <p className={styles.bodyText}>
          The incentive is applied at the point-of-sale by the dealership. It will appear directly on the bill of sale
          or lease agreement on eligible zero-emission vehicles (ZEVs).
        </p>
        <p className={styles.bodyText}>
          The dealer applies taxes and fees to the purchase or lease before applying the incentive. The dealer must
          submit the documentation required to be reimbursed for an incentive provided to consumers at the
          point-of-sale.
        </p>

        <h3 className={styles.paraHeading}>Independent of Other Incentives</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          The federal incentive for eligible zero-emission vehicles (ZEVs) will be applied in addition to any provincial
          or territorial incentive offered.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/06/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-I/text/short/06-jul-2022/04" />
    </>
  );
};
