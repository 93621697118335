import React, { useRef, useEffect } from 'react';

import styles from './TwoFAInput.module.scss';

const allowedSymbols = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];

export interface TwoFAInputProps extends React.HTMLProps<HTMLInputElement> {
  register?: (ref: HTMLInputElement) => void;
}

// adopted from https://codepen.io/GeoffreyCrofte/pen/zYrxKRe

export const TwoFAInput: React.FC<TwoFAInputProps> = ({ register }) => {
  const otc1 = useRef<HTMLInputElement | null>(null);
  const otc2 = useRef<HTMLInputElement | null>(null);
  const otc3 = useRef<HTMLInputElement | null>(null);
  const otc4 = useRef<HTMLInputElement | null>(null);
  const otc5 = useRef<HTMLInputElement | null>(null);
  const otc6 = useRef<HTMLInputElement | null>(null);

  const inputFieldsRefs = [otc1, otc2, otc3, otc4, otc5, otc6];

  useEffect(() => {
    /**
     * Control on keyup to catch what the user intent to do.
     * I could have check for numeric key only here, but I didn't.
     */

    inputFieldsRefs.forEach(function (ref) {
      const input = ref.current;

      if (input) {
        input.addEventListener('keyup', function (e) {
          // Break if Shift, Tab, CMD, Option, Control.
          if (e.keyCode === 16 || e.keyCode === 9 || e.keyCode === 224 || e.keyCode === 18 || e.keyCode === 17) {
            return;
          }

          // On Backspace or left arrow, go to the previous field.
          if (
            (e.keyCode === 8 || e.keyCode === 37) &&
            this.previousElementSibling &&
            this.previousElementSibling.tagName === 'INPUT'
          ) {
            (this.previousElementSibling as HTMLInputElement).select();
          } else if (e.keyCode !== 8 && this.nextElementSibling) {
            (this.nextElementSibling as HTMLInputElement).select();
          }
        });

        /**
         * Better control on Focus
         * - don't allow focus on other field if the first one is empty
         * - don't allow focus on field if the previous one if empty (debatable)
         * - get the focus on the first empty field
         */

        input.addEventListener('focus', function (e) {
          // If the focus element is the first one, do nothing
          if (this === otc1.current) return;

          // If value of input 1 is empty, focus it.
          if (otc1.current && otc1.current.value === '') {
            otc1.current.focus();
          }

          // If value of a previous input is empty, focus it.
          // To remove if you don't wanna force user respecting the fields order.
          if ((this.previousElementSibling as HTMLInputElement).value === '') {
            (this.previousElementSibling as HTMLInputElement).focus();
          }
        });
      }
    });
  }, [inputFieldsRefs]);

  return (
    <fieldset className={styles.fieldSet}>
      <label htmlFor="otc-1">Number 1</label>
      <label htmlFor="otc-2">Number 2</label>
      <label htmlFor="otc-3">Number 3</label>
      <label htmlFor="otc-4">Number 4</label>
      <label htmlFor="otc-5">Number 5</label>
      <label htmlFor="otc-6">Number 6</label>

      <div>
        <input
          type="number"
          name="code1"
          ref={(r: HTMLInputElement) => {
            if (register) register(r);
            otc1.current = r;
          }}
          pattern="[0-9]*"
          autoComplete="one-time-code"
          id="otc-1"
          onKeyDown={(e) => !allowedSymbols.includes(e.key) && e.preventDefault()}
          required
        />

        <input
          type="number"
          name="code2"
          ref={(r: HTMLInputElement) => {
            if (register) register(r);
            otc2.current = r;
          }}
          pattern="[0-9]*"
          min="0"
          max="9"
          maxLength={1}
          id="otc-2"
          onKeyDown={(e) => !allowedSymbols.includes(e.key) && e.preventDefault()}
          required
        />

        <input
          type="number"
          name="code3"
          ref={(r: HTMLInputElement) => {
            if (register) register(r);
            otc3.current = r;
          }}
          pattern="[0-9]*"
          min="0"
          max="9"
          maxLength={1}
          id="otc-3"
          onKeyDown={(e) => !allowedSymbols.includes(e.key) && e.preventDefault()}
          required
        />

        <input
          type="number"
          name="code4"
          ref={(r: HTMLInputElement) => {
            if (register) register(r);
            otc4.current = r;
          }}
          pattern="[0-9]*"
          min="0"
          max="9"
          maxLength={1}
          id="otc-4"
          onKeyDown={(e) => !allowedSymbols.includes(e.key) && e.preventDefault()}
          required
        />

        <input
          type="number"
          name="code5"
          ref={(r: HTMLInputElement) => {
            if (register) register(r);
            otc5.current = r;
          }}
          pattern="[0-9]*"
          min="0"
          max="9"
          maxLength={1}
          id="otc-5"
          onKeyDown={(e) => !allowedSymbols.includes(e.key) && e.preventDefault()}
          required
        />

        <input
          type="number"
          name="code6"
          ref={(r: HTMLInputElement) => {
            if (register) register(r);
            otc6.current = r;
          }}
          pattern="[0-9]*"
          min="0"
          max="9"
          maxLength={1}
          id="otc-6"
          onKeyDown={(e) => !allowedSymbols.includes(e.key) && e.preventDefault()}
          required
        />
      </div>
    </fieldset>
  );
};
