import { AccountPurpose } from './types';

export const accountPurposeOptions = [
  {
    value: AccountPurpose.INVESTMENT_EF,
    label: 'Emergency Funds',
  },
  {
    value: AccountPurpose.INVESTMENT_STI,
    label: 'Short Term Investments',
  },
  {
    value: AccountPurpose.INVESTMENT_LTI,
    label: 'Long Term Investments',
  },
  {
    value: AccountPurpose.SAVINGS,
    label: 'Savings',
  },
];
