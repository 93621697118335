import React, { PropsWithChildren } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';
import { Resolver, SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';

import styles from './Form.module.scss';

export interface FormProps<Inputs> {
  defaultValues?: UnpackNestedValue<DeepPartial<Inputs>>;
  onSubmit: SubmitHandler<Inputs>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  resolver?: Resolver<Inputs, object>;
}

interface AbstractFormElementProps {
  name: string;
  type?: string;
}

export const Form = <Inputs,>({
  defaultValues,
  resolver,
  children,
  onSubmit,
}: PropsWithChildren<FormProps<Inputs>>) => {
  const methods = useForm<Inputs>({ mode: 'onChange', defaultValues, resolver });
  const { handleSubmit, register, control, errors, formState } = methods;
  const { isValid } = formState;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formBlock} noValidate>
        {Array.isArray(children)
          ? children.map((child, idx) => {
              return child ? (
                <>
                  {(child as React.ReactElement<AbstractFormElementProps>).props &&
                  (child as React.ReactElement<AbstractFormElementProps>).props.name
                    ? React.createElement((child as React.ReactElement<AbstractFormElementProps>).type, {
                        ...{
                          ...(child as React.ReactElement<AbstractFormElementProps>).props,
                          register,
                          key: (child as React.ReactElement<AbstractFormElementProps>).props.name,
                          errors,
                          control,
                        },
                      })
                    : (child as React.ReactElement<AbstractFormElementProps>).props.type &&
                      (child as React.ReactElement<AbstractFormElementProps>).props.type === 'submit'
                    ? React.createElement((child as React.ReactElement<AbstractFormElementProps>).type, {
                        ...{
                          ...(child as React.ReactElement<AbstractFormElementProps>).props,
                          key: `${idx}-${(child as React.ReactElement<AbstractFormElementProps>).props.name}`,
                          errors,
                          control,
                          disabled: !isValid,
                        },
                      })
                    : child}
                </>
              ) : null;
            })
          : children}
      </form>
    </FormProvider>
  );
};
