import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { TaxForm } from './TaxForm';
import { TaxFormInputs } from './types';
import { updateTax, getTaxList, selectTaxById, selectListLoading } from './taxSlice';
import { BadRequestError } from '../../helpers/api';

export const EditTax: React.FC = () => {
  let { taxId } = useParams();
  taxId = parseInt(taxId, 10);
  const taxItem = useSelector(selectTaxById(taxId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getTaxListAction = useCallback(() => dispatch(getTaxList()), [dispatch]);

  const onSubmit = async (data: TaxFormInputs) => {
    try {
      await updateTax(taxId, data);
      history.push('/income/tax');
      getTaxListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Tax']}>
      <TaxForm defaultValues={taxItem} onSubmit={onSubmit} />
    </Modal>
  );
};
