import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const WhatNewIn2021TaxOther: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Other</h1>

        <h2 className={clsx(styles.subHeading)}>Old Age Security (OAS)</h2>
        <p className={styles.bodyText}>
          Since July 2021, there is 10% increase in Old Age Security (OAS) payments for those aged 75 and more.{' '}
        </p>
        <p className={styles.bodyText}>
          For 2021, OAS repayment or clawback will begin from income level of $79,845 and above. Repayments would be 15%
          of the amount received over the income limit of $79,845.{' '}
        </p>

        <h2 className={clsx(styles.paraHeading)}>Canada Pension Plan (CPP)</h2>
        <p className={styles.bodyText}>
          2021 was the 3<sup>rd</sup> year of the phase-in of the CPP enhancements.
        </p>
        <p className={styles.bodyText}>
          Trend of the <strong>CPP Contribution Rate:</strong>
        </p>
        <p className={styles.bodyText}>2020: 5.25% </p>
        <p className={styles.bodyText}>2021: 5.45% </p>
        <p className={styles.bodyText}>2022: 5.70% </p>

        <p className={styles.bodyText}>2025: 5.95%. Will continue to increase until 2025. </p>
        <p className={styles.bodyText}>
          Trend of <strong>Maximum Pensionable Earnings</strong> trend:
        </p>
        <p className={styles.bodyText}>2021: $61,600.</p>
        <p className={styles.bodyText}>2022: $64,900.</p>
        <p className={styles.bodyText}>2025: $79,400. Will continue to increase until 2025.</p>

        <p className={styles.bodyText}>
          The maximum CPP Contribution in 2022 is $3,499.80, which is $6,999.60 for those who are self-employed.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Underused Housing Tax (UHT) </h2>
        <p className={styles.bodyText}>
          In 2022, 1% tax needs to be paid Canadian Non-Residents who owned a residential property in Canada, which is
          either vacant or under used. Value of the property would the most recent sale price or valuation for the
          property taxes – whichever is higher.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'01/23/2022'} />
      </div>

      <FeedbackBox source="/managingtaxes/whatsnewin2021/text/short/23-jan-2022/04" />
    </>
  );
};
