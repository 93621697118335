import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';
import { useSelector } from 'react-redux';
import { Control, useWatch } from 'react-hook-form';

import { GoalFormInputs, GoalItems } from './types';
import { DatePicker, Form, Select, SubmitButton, Dropdown } from '../../components/Form';
import { selectOnlyCompleteGoals } from './goalSlice';

const schemaResolver = yupResolver<GoalFormInputs>(
  yup.object().shape({
    priority: yup.number().typeError('please select a goal').required('please select a goal'),
    target_date: yup.string().required('please enter a target date'),
    status: yup.string().required('please select a status'),
    name: yup.string().required('Please enter your goal'),
  }),
);

const goalStatusOptions = [
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Overdue', label: 'Overdue' },
  { value: 'Complete', label: 'Complete' },
];

export interface GoalWarningProps {
  name: string;
  activeGoals: GoalItems;
  control?: Control;
  currentPriority?: number;
}

const GoalWarning: React.FC<GoalWarningProps> = ({ control, activeGoals, currentPriority }) => {
  const selectedPriorityValue = useWatch({ control, name: 'priority' }) as string;

  let message = '';

  // going over all the possible goals (Goal 1, Goal 2, Goal 3) and checking if they already exist

  ['1', '2', '3'].forEach((iteratedGoalPriority) => {
    const numericGoalPriority = parseInt(iteratedGoalPriority, 10);

    if (selectedPriorityValue === iteratedGoalPriority && currentPriority !== numericGoalPriority) {
      const goalAlreadyExists = activeGoals.findIndex((goal) => goal.priority === numericGoalPriority) > -1;

      if (goalAlreadyExists) {
        message = `Current Goal ${iteratedGoalPriority} will be Goal ${currentPriority}.`;
      }
    }
  });

  if (message) {
    return (
      <div className="invalid-feedback" style={{ display: 'block', marginTop: '-25px' }}>
        {message}{' '}
      </div>
    );
  }
  return null;
};

export type GoalFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<GoalFormInputs>>;

  onSubmit: SubmitHandler<GoalFormInputs>;
};

export const GoalForm: React.FC<GoalFormProps> = ({ defaultValues = {}, onSubmit }) => {
  const activeGoals = useSelector(selectOnlyCompleteGoals(false));

  let goalPriorityOptions = [
    { value: 1, label: 'Goal 1' },
    { value: 2, label: 'Goal 2' },
    { value: 3, label: 'Goal 3' },
  ];

  const goalOptions = [
    { value: 'Buy a House', label: 'Buy a House' },
    { value: 'Payoff Debt', label: 'Payoff Debt' },
    { value: 'Buy a Car', label: 'Buy a Car' },
    { value: 'Save for Retirement', label: 'Save for Retirement' },
    { value: 'Other', label: 'Other' },
  ];

  if (!defaultValues?.priority) {
    goalPriorityOptions = goalPriorityOptions.filter(
      (option) => activeGoals.findIndex((goal) => goal.priority === option.value) === -1,
    );
  }

  return (
    <Container fluid className="px-0">
      <Form
        onSubmit={onSubmit}
        resolver={schemaResolver}
        defaultValues={{ ...defaultValues, name: defaultValues?.name ?? defaultValues?.preset_name_display }}
      >
        <Dropdown name="name" options={goalOptions} placeholder="Goals" />
        <Select name="priority" options={goalPriorityOptions} placeholder="Priority" />
        {defaultValues?.priority ? (
          <GoalWarning name="priority_warning" activeGoals={activeGoals} currentPriority={defaultValues.priority} />
        ) : (
          <span />
        )}
        <DatePicker name="target_date" placeholder="Target Date" />
        <Select name="status" options={goalStatusOptions} placeholder="Status" />
        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
