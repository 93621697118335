import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DemandDebtRatio: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Demand Debt Ratio</h1>

        <ul className={styles.list}>
          <li>Demand Debt is the type of debt which your lenders may ask you to repay on demand.</li>
          <li>
            Examples of Demand Debts are credit card balance, line of credit, pay day loans, home equity loans etc.
          </li>
          <li>
            Debts with predetermined repayment schedule such as mortgage, auto loan and term loan are not Demand Debts.
          </li>
          <li>
            The formula for Demand Debt Ratio is liquid or sellable assets divided by total demand debt, multiplied by
            100. Demand Debt Ratio is expressed in percentage.
          </li>
          <li>
            Demand Debt Ratio indicates how readily you can repay your demand debt when you are asked by lenders without
            prior notice.
          </li>
          <li>
            Having too much Demand Debt compared to your liquid or sellable assets is clearly a bad planning and a red
            flag to the lenders.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/30/2022'} />
      </div>

      <FeedbackBox source="/debt/debt-ratios/text/short/30-jun-2022/04" />
    </>
  );
};
