import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SavingItems } from './types';
import {
  Table,
  dateFormatter,
  priceFormatter,
  deleteIconFormatter,
  editIconFormatter,
  TableSortCaret,
} from '../../components/Table';

import { deleteSaving, getSavingList } from './savingSlice';
import { useTableTotalRow } from '../../hooks';

import styles from '../../components/Table/Table.module.scss';

export interface SavingTableProps {
  savingList: SavingItems;
}

export const SavingTable: React.FC<SavingTableProps> = ({ savingList }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const getIncomeListAction = useCallback(() => dispatch(getSavingList()), [dispatch]);

  const total = savingList.reduce<number>(
    (accumulator, currentValue) => accumulator + parseFloat(currentValue.amount),
    0,
  );

  useTableTotalRow(total, 2, 2);

  const deleteEntityAction = async (id: number) => {
    await deleteSaving(id);
    getIncomeListAction();
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      formatter: dateFormatter('MMM dd, yyyy'),
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/save/saving/edit/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={savingList} columns={columns} bootstrap4 />;
};
