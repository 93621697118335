import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';
import creditUtilizationImg from '../common/images/creditUtilizationImg.png';

export const CreditUtilization: React.FC = () => {
  const columns = [
    {
      dataField: 'creditFacility',
      text: 'Credit Facility',
    },
    {
      dataField: 'limit',
      text: 'Limit',
    },
    {
      dataField: 'balance',
      text: 'Balance',
    },
    {
      dataField: 'utilization',
      text: 'Utilization',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Credit Utilization</h1>

        <h3 className={styles.paraHeading}>What is Credit Utilization</h3>

        <p className={styles.bodyText}>
          Credit utilization means how much of the total approved credit limits you have used.
        </p>

        <h3 className={styles.paraHeading}>Example</h3>

        <p className={styles.bodyText}>Lisa has three credit facilities which are as under:</p>

        <div className={styles.chartWrapper}>
          <h5 className={styles.chartSubHeader}>Figures in Canadian Dollars</h5>
          <SimpleTable
            keyField="id"
            data={[
              {
                creditFacility: 'BMO Master Card',
                limit: '$1,000',
                balance: '$200',
                utilization: '20%',
              },
              {
                creditFacility: 'TD Visa',
                limit: '$1,000',
                balance: '$300',
                utilization: '30%',
              },
              {
                creditFacility: 'RBC Line of Credit',
                limit: '$10,000',
                balance: '$700',
                utilization: '7%',
              },
              {
                creditFacility: 'Total',
                limit: '$12,000',
                balance: '$1,200',
                utilization: '10%',
              },
            ]}
            columns={columns}
            bootstrap4
          />
        </div>

        <h3 className={styles.paraHeading}>Calculating Credit Utilization</h3>
        <div className={styles.imgContainer}>
          <img src={creditUtilizationImg} alt="" width="500" />
        </div>

        <h3 className={styles.paraHeading}>Acceptable Credit Utilization Level</h3>
        <p className={styles.bodyText}>30% is considered acceptable utilization level of credit for individuals.</p>

        <h3 className={styles.paraHeading}>Importance of Credit Utilization</h3>
        <ul className={styles.list}>
          <li>
            Good indicator of your overall financial position. Lower credit utilization indicates better financial
            health.
          </li>
          <li>
            High level of credit utilization portrays your reliance on credits and lack of flexibility to pay off.
          </li>
          <li>
            High credit utilization indicates that you are relying on credit instead of income to fund your ongoing
            lifestyle.
          </li>
          <li>
            When you apply for new credit and have a history of high credit utilization, lenders would see this pattern
            as a red flag since you are likely to be even more dependent on debt going forward.
          </li>
          <li>
            You will be venerable to unforeseen situations like sudden loss of jobs, sickness, disability etc. You will
            not be able to repay the debt in such situations since you are already at the edge of your debt capacity.
          </li>
        </ul>

        <p className={styles.bodyText}>
          Overall, you turn into a risky borrower when you continue to use a high level of your approved credit
          facilities.
        </p>

        <h3 className={styles.paraHeading}>Reducing Credit Utilization</h3>
        <ul className={styles.list} onCopy={(e) => e.preventDefault()}>
          <li>Don’t borrow new money, particularly to fund nonessential or luxury items. Live within your means.</li>
          <li>Step up repayments of your existing credits. Adopt an aggressive plan.</li>
          <li>Obtain new credits or increase existing credit limits, even if you don’t need it immediately.</li>
          <li>Don’t cancel old credit facilities or credit cards, which you no longer use.</li>
          <li>
            Change your debt mix to lower your interest expense and use the savings of interest expense to repay off the
            principal debt faster. For example, transfer your credit card balances to a Line of Credit or avail a
            balance promotion from another credit card company to lower your burden of interest expense.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/26/2022'} />
      </div>

      <FeedbackBox source="/debt/understandingfundamentals/text/short/26-mar-2022/01" />
    </>
  );
};
