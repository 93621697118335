import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import styles from './ProgressIndicator.module.scss';

function inView(element: HTMLDivElement) {
  const elementHeight = element.clientHeight;
  // get window height
  const windowHeight = window.innerHeight;
  // get number of pixels that the document is scrolled
  const scrollY = window.scrollY || window.pageYOffset;

  // get current scroll position (distance from the top of the page to the bottom of the current viewport)
  const scrollPosition = scrollY + windowHeight;
  // get element position (distance from the top of the page to the bottom of the element)
  const elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight;

  // is scroll position greater than element position? (is element in view?)
  return scrollPosition > elementPosition;
}

export interface ProgressIndicatorProps {
  percentage: number;
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ percentage }) => {
  const [width, setWidth] = useState('0.01%');
  const progressBarRef = useRef<HTMLDivElement>(null);

  const getLabel = () => {
    return percentage >= 0 && percentage <= 20
      ? 'Unsatisfactory'
      : percentage > 20 && percentage <= 40
      ? 'Fair'
      : percentage > 40 && percentage <= 60
      ? 'Good'
      : percentage > 60 && percentage <= 80
      ? 'Very Good'
      : percentage > 80 && percentage <= 100
      ? 'Excellent'
      : 'No Data';
  };

  useEffect(() => {
    const animateProgressBar = () => {
      if (progressBarRef.current && inView(progressBarRef.current)) {
        setTimeout(() => setWidth(`${percentage}%`), 300);
      }
    };

    document.addEventListener('scroll', animateProgressBar);
    if (progressBarRef.current && inView(progressBarRef.current)) {
      animateProgressBar();
    }

    return () => {
      document.removeEventListener('scroll', animateProgressBar);
    };
  }, [percentage]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{percentage}%</div>
      <div className={styles.progressOuter}>
        <div className={styles.progressInner} style={{ width }} ref={progressBarRef} />
        <div className={cn(styles.circle, styles.circle1, { [styles.circle1Filled]: percentage >= 25 })} />
        <div className={cn(styles.circle, styles.circle2, { [styles.circle1Filled]: percentage >= 50 })} />
        <div className={cn(styles.circle, styles.circle3, { [styles.circle1Filled]: percentage >= 75 })} />
      </div>
      <div className={styles.subHeader}>{getLabel()}</div>
    </div>
  );
};
