import React from 'react';

import styles from './Pages.module.scss';
import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import infoImg from '../common/images/info.svg';

import { PieChart } from './PieChart';
import { PublishedDate } from './PublishedDate';

export const AssetAllocationMid60: React.FC = () => {
  const extraPie = {
    fill: [
      {
        match: {
          id: 'Stocks',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'Bonds',
        },
        id: 'dots',
      },
    ],
    margin: { top: 30, right: 0, bottom: 30, left: 0 },
  };

  const data = [
    {
      id: 'Stocks',
      label: 'Stocks',
      value: 25,
    },
    {
      id: 'Bonds',
      label: 'Bonds',
      value: 50,
    },
    {
      id: 'Real Estate',
      label: 'Real Estate',
      value: 15,
    },
    {
      id: 'Cash',
      label: 'Cash',
      value: 10,
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Asset Allocation | Age Mid-60s</h1>

        <h1 className={styles.subHeading}>
          Life Cycle Investment Guide
          <HelpBox placement="right" content={<p className="mb-0">Author: Burton G. Malkiel</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <div className={styles.pieChartContainer}>
          <PieChart data={data} extraProps={{ valueFormat: (value: number) => value + '%', ...extraPie }} />
        </div>

        <p className={styles.bodyText}>
          Risk tolerance is zero or negligible. It is important to ensure the principal investment does not
          significantly lose value.
        </p>

        <ul className={styles.list}>
          <li>
            <strong>Cash (5%): </strong>
            Money Market Fund. Short Term Bond Fund. Average maturity 1-2 years
          </li>
          <li>
            <strong>Bonds (50%): </strong>
            Zero-coupon treasury bond. No-load high grade bond fund, treasury inflation protection securities (5-10%)
          </li>
          <li>
            <strong>Stocks (25%): </strong>
            Two-thirds in U.S. and Canadian Stocks including high growth companies. Rest one-third is in International
            Stocks including emerging market
          </li>
          <li>
            <strong>Real Estate (15%): </strong>
            Real Estate Fund. REIT (Real Estate Income Trust) Fund
          </li>
        </ul>
        <PublishedDate date={'10/24/2021'} />
      </div>

      <FeedbackBox source="/investments/lifecycle-investment-guide/text/short/24-oct-2021/05" />
    </>
  );
};
