import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const FiveMinuteRule: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>5 Minute Rule</h1>

        <p className={styles.bodyText}>This simple rule can make all the difference.</p>
        <p className={styles.bodyText}>
          When any important issue surfaces, the first thing to do is think through what can be done to resolve the
          issue or advance it to the next step. When one or more actions are identified, then attempt should be made to
          get those done at the earliest opportunity.
        </p>
        <p className={styles.bodyText}>
          If any of these steps or tasks takes 5 minute or less, then that should be done immediately instead of saving
          it for the future.
        </p>
        <p className={styles.bodyText}>This is an extremely powerful strategy due to:</p>

        <ul className={styles.list}>
          <li>It either completes a task or takes it to the next level.</li>
          <li>
            In a very short period of time significant progress can be made. In half an hour 6 of such important tasks
            or milestones can be accomplished.
          </li>
          <li>
            Mind is freed up since it no longer has to keep a space occupied with this task to be done as well as
            related stress of potential consequence of not getting it done at all or on time.
          </li>
          <li>It gives a sense of relief and joy of completing the task.</li>
          <li>
            Newly emptied space of the brain is now a fertile ground for bigger goals and much bolder steps.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>
        <PublishedDate date={'07/24/2021'} />
      </div>

      <FeedbackBox source="/calendar/time-management/text/short/28-jul-2021/01" />
    </>
  );
};
