import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import infoImg from '../common/images/info.svg';
import { PublishedDate } from './PublishedDate';

export const AssetAllocation: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Asset Allocation | Overview</h1>

        <h1 className={styles.subHeading}>Life Cycle Investment Guide</h1>

        <h1 className={styles.paraHeading}>
          Recommended Savings and Investment Allocation
          <HelpBox placement="right" content={<p className="mb-0">Author: Burton G. Malkiel</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <p className={styles.bodyText}>
          The following guideline is extremely helpful to shape your portfolio and strategy. However, these are just the
          reference points, which should be kept in our mind while designing the portfolio or mix.
        </p>
        <p className={styles.bodyText}>This suggested mix may vary based on:</p>

        <ol className={styles.list}>
          <li>
            <strong>Our Risk Tolerance: </strong>
            If you are not a risk taker then you should make adjustments to reflect your own risk tolerance level. Idea
            is to settle at a level that you are personally comfortable with and allows you to sleep well at night.
          </li>

          <li>
            <strong>State of the Economy: </strong>
            If the market is believed to be too hot for any reason, then you may need to allocate more assets towards
            less risky or volatile assets as a temporary measure.
          </li>
        </ol>

        <p className={styles.bodyText}>
          It is a good practice to periodically check the actual portfolio mix with this target or recommended mix. If
          these two grossly differ, then you should definitely contact your Financial Adviser or Investment Company.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Don&apos;t be afraid to challenge them. This is the Rule Book every Business Schools teach and every
          Investment Professionals follow. Make sure there is a good reason to digress and you are happy with the
          response.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'10/24/2021'} />
      </div>

      <FeedbackBox source="/investments/lifecycle-investment-guide/text/short/24-oct-2021/0" />
    </>
  );
};
