import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const ChargerLevels: React.FC = () => {
  const columns = [
    {
      dataField: 'charger',
      text: 'Charger',
    },
    {
      dataField: 'outlet',
      text: 'Outlet',
    },
    {
      dataField: 'powerDelivery',
      text: 'Power Delivery',
    },
    {
      dataField: 'rangePerHour',
      text: 'Driving Range Added Per Hour',
    },
    {
      dataField: 'timeToCharge',
      text: 'Time to Charge 60 KWh',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Charger Levels</h1>

        <p className={styles.bodyText}>There are three charger levels of Electric Vehicles.</p>

        <SimpleTable
          keyField="chargerLevels"
          data={[
            {
              charger: 'Level 1',
              outlet: '120 Volt',
              powerDelivery: '1 - 1.4 kw',
              rangePerHour: '5 - 8 kilometers',
              timeToCharge: '30 - 40 hours',
            },
            {
              charger: 'Level 2',
              outlet: '208 - 240 Volt',
              powerDelivery: '3.9 - 19.2 kw',
              rangePerHour: '19 - 128 kilometers',
              timeToCharge: '2.5 - 4.5 hours',
            },

            {
              charger: 'Level 3',
              outlet: '400 - 900 Volt',
              powerDelivery: '24 - 300 kw',
              rangePerHour: '121 - 1,931 kilometers',
              timeToCharge: '30 - 40 minutes',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h3 className={styles.paraHeading}>Level 1 Charging: 120-Volt</h3>

        <p className={styles.bodyText}>Connectors Used: J1772, Tesla</p>
        <p className={styles.bodyText}>Charging Speed: 5 to 8 kilometers per Hour</p>
        <p className={styles.bodyText}>Charging Locations: Home and Workplace</p>
        <p className={styles.bodyText}>
          Level 1 charging can be done from any 120-volt outlet found at households. This is suitable for all the
          electric vehicles (EVs) and plug-in hybrid electric vehicles (PHEVs). Level 1 is the slowest method of
          charging
        </p>
        <p className={styles.bodyText}>
          Level 1 charging is ideal for plug-in hybrid electric vehicles (PHEVs) with smaller batteries (25 kWh or
          less). Level 1 charging is insufficient for electric vehicles (EVs) since they have larger batteries. Instead,
          Level 2 charging better suits their daily charging needs of the electric vehicles (EVs).
        </p>

        <h3 className={styles.paraHeading}>Level 2 Charging: 208-Volt to 240-Volt</h3>
        <p className={styles.bodyText}>Connectors Used: J1772, Tesla</p>
        <p className={styles.bodyText}>Charging Speed: 19 to 128 kilometers per Hour</p>
        <p className={styles.bodyText}>Locations: Home, Workplace & Public</p>
        <p className={styles.bodyText}>
          Level 2 charging is more suitable for electric vehicles (EVs) as it is 10 times faster than Level 1 charging.
          It is preferred by electric vehicle (EV) owners since batteries can be fully charged overnight. Level 2
          chargers can deliver up to 80 amps of power.
        </p>
        <p className={styles.bodyText}>
          Level 2 charging equipment can be easily installed at home or workplace. Public locations also have Level 2
          charging facilities.
        </p>
        <p className={styles.bodyText}>
          You can add a Level 2 charger at home without upgrading the service. It would need a dedicated 240-volt
          circuit like an electric clothes dryer or electric kitchen range.
        </p>

        <h3 className={styles.paraHeading}>Level 3 Charging: 400-Volt to 900-Volt</h3>
        <p className={styles.bodyText}>Connectors Used: Combined Charging System, CHAdeMO, Tesla</p>
        <p className={styles.bodyText}>Charging Speed: 191 to 1,931 kilometers per Hour</p>
        <p className={styles.bodyText}>Locations: Public</p>
        <p className={styles.bodyText}>
          Level 3 charging is the fastest among all three charging levels and uses direct current (DC) that has higher
          voltage. In contrast, Level 1 and Level 2 charging uses alternating current (AC) with lower voltage. Only a
          small number of residential locations have the high-voltage connection required for level 3 charging.
        </p>
        <p className={styles.bodyText}>
          DC Fast Chargers are very expensive. Even the residences with 400-volt electricity service are unlikely to
          install a Level 3 charger because it would cost more than the electric vehicle (EV).
        </p>
        <p className={styles.bodyText}>
          Level 3 chargers are known as Superchargers in Tesla community and DC Fast Chargers among rest. Very recently,
          Nissan has introduced a new term - CHAdeMO.
        </p>

        <h4 className={styles.noteHeading}>Note</h4>
        <p className={clsx(styles.bodyText, styles.last)}>
          Every electric vehicle comes with a portable charger, either Level 1 or Level 2. Some have adapters that can
          plug in to both Level 1 and Level 2 outlets.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/12/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-II/text/short/12-jul-2022/01" />
    </>
  );
};
