import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';
import chartImg from '../common/images/chart.png';

export const Page0010: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">Prioritizing</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        Going forward we will need to focus on activities which are not an immediate pressing need but have a defining
        effect on how we live in the long haul. Like most successful people we will try to balance between short-term
        priorities and long-term goals, as described in the following chart:
      </Typography>
      <Spacer variant="small" />
      <img src={chartImg} alt="info" width={650} />
      <Spacer variant="small" />
      <Typography variant="p">
        Overall, we may have to depart from our habitual routines and comfort zones in order to set our priorities in a
        way we have not done before.
      </Typography>
    </Container>
  );
};
