import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HoweTransferSecurityWorks: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>How e-Transfer Security Works</h1>

        <p className={styles.bodyText}>
          When you send money using Interac e-Transfer, your money doesn’t actually travel by your email or text message
          – just the notifications and deposit instructions.
        </p>

        <p className={styles.bodyText}>
          Banks transfer the funds among each other using secured and conventional processes like:
        </p>

        <ul className={styles.list}>
          <li>Authentication and transaction encryption.</li>
          <li>Financial institution authentication.</li>
          <li>Risk management system of each bank.</li>
        </ul>

        <p className={styles.bodyText}>
          Interac e-Transfer users are protected by multiple layers of security, making the service one of the most
          secure money transfer services globally.
        </p>

        <p className={styles.bodyText}>Your bank’s security measures include:</p>

        <ul className={styles.list}>
          <li>Encryption technology</li>
          <li>Confidential user IDs and passwords</li>
          <li>Secure login process</li>
          <li>Security question and answer.</li>
        </ul>

        <h3 className={styles.paraHeading}>How to Send Money Safely</h3>
        <p className={styles.bodyText}>You have a key role in keeping your financial activity secure:</p>
        <ul className={styles.list}>
          <li>Exercise extra caution when sending or receiving money.</li>
          <li>Only send money to people you know and trust, just as you would do while dealing with cash.</li>
          <li>
            Remember, an Interac e-Transfer transaction cannot be reversed once a recipient has deposited the funds.
          </li>
          <li>
            Always use a strong password that can’t easily be guessed or found and ensure you communicate it to the
            recipient via a safe channel.
          </li>
          <li>
            For routine payments, ask your recipient to set up Interac e-Transfer Autodeposit for ease and added
            security.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/28/2022'} />
      </div>

      <FeedbackBox source="/transaction/basics/text/short/28-jun-2022/02" />
    </>
  );
};
