import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const TuitionAndTrainingFees: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tuition and Training Fees</h1>

        <p className={styles.bodyText}>
          You can claim fees paid on eligible tuitions (generally from a post-secondary education institution) for
          occupational or professional courses taken in 2020 from eligible institutions situated in Canada from taxable
          income for the year, only if all the following conditions are fulfilled:
        </p>

        <ul className={styles.list}>
          <li>Age should be between 26 years to 65 years.</li>
          <li>You should be resident of Canada in the full year 2020.</li>
          <li>
            You should have CTCL (Canada Training Credit Limit) for 2020 through notice of assessment or reassessment
            for 2019.
          </li>
        </ul>

        <p className={clsx(styles.paraHeading)}>Claim Amount</p>

        <p className={styles.bodyText}>
          You can claim half of the fees claimed on line 32000 of your{' '}
          <a
            href="https://www.canada.ca/en/revenue-agency/services/forms-publications/tax-packages-years/general-income-tax-benefit-package.html"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Federal Schedule 11
          </a>{' '}
          or CTCL for 2020, whichever is less.
        </p>

        <p className={styles.bodyText}>Amount you cannot claim, if any of the below condition applies to you:</p>
        <ul className={styles.list}>
          <li>
            If an employer or employer of any of the parent, bears the fees paid and the amount is not part of your or
            your parent’s income.
          </li>
          <li>Fees is paid by others e.g., by federal program and the amount is not part of your income.</li>
        </ul>

        <p className={styles.bodyText}>
          <strong>Schedule 11</strong> should be filled to know your credit limit and the claimed amount will reduce
          CTCL for future years.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/10/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses/text/short/10-feb-2022/03" />
    </>
  );
};
