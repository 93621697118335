import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { HomeItems } from './types';
import {
  Table,
  dateFormatter,
  editIconFormatter,
  deleteIconFormatter,
  priceFormatter,
  TableSortCaret,
} from '../../components/Table';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import styles from '../../components/Table/Table.module.scss';
import { getHomeList, deleteHome } from './homeSlice';

const PurposeHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Indicate the primary purpose of your home here.</li>
        <br />
        <li>
          <b>Examples:</b> Principal Residence, Rental Property, Vacation Home etc.
        </li>
      </ul>
    </>
  );
};

const DatePurchaseHelp = () => {
  return (
    <>
      <h6 className="mb-0">Indicate here the date home was purchased. </h6>
    </>
  );
};

const PurchasePriceHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Indicate here the total purchase price of the home.</li>
        <br />
        <li>Total purchase price should include legal fees, real estate agent commission, taxes etc.</li>
      </ul>
    </>
  );
};

const CurrentValueHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Indicate the approximate current value of the property.</li>
        <br />
        <li>
          Pick a current listing of a similar to your home in your neighborhood. Make some adjustments, if necessary.
        </li>
        <br />
        <li>Learn more in the Knowledge Section.</li>
      </ul>
    </>
  );
};

export interface HomeTableProps {
  list: HomeItems;
}

const HomeFormatter = (priority: string) => {
  return <span>{priority}</span>;
};

export const HomeTable: React.FC<HomeTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getHomeListAction = useCallback(() => dispatch(getHomeList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteHome(id);
    getHomeListAction();
  };

  const columns = [
    {
      dataField: 'title',
      text: 'Purpose',
      sort: true,
      helpText: <PurposeHelp />,
      formatter: HomeFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'purchase_date',
      text: 'Date of Purchase',
      sort: true,
      helpText: <DatePurchaseHelp />,
      formatter: dateFormatter('MMM dd, yyyy'),
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'purchase_price',
      text: 'Purchase Price',
      sort: true,
      helpText: <PurchasePriceHelp />,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'current_price',
      text: 'Current Value',
      sort: true,
      helpText: <CurrentValueHelp />,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/asset/home/edit/home/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
