import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import HelpBox from '../../components/Common/HelpBox';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';

import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import { getAutoList, selectAutoList, selectListLoading, selectMaintenanceList, getMaintenanceList } from './autoSlice';
import { AutoTable } from './AutoTable';
import { MaintenanceTable } from './MaintenanceTable';

export function AutoList(): JSX.Element {
  const dispatch = useDispatch();
  const getAutoListAction = useCallback(() => dispatch(getAutoList()), [dispatch]);
  const getAutoMaintenanceListAction = useCallback(() => dispatch(getMaintenanceList()), [dispatch]);

  const autoList = useSelector(selectAutoList);
  const listLoading = useSelector(selectListLoading);

  const selectMaintenance = useSelector(selectMaintenanceList);

  useEffect(() => {
    getAutoListAction();
    getAutoMaintenanceListAction();
  }, [getAutoListAction, getAutoMaintenanceListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />

        <div className="d-flex align-items-center">
          <Typography variant="h1">Autos</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Enter the details of all your vehicles here. </li>
                  <br />
                  <li>Use best estimates where exact details are not available.  </li>
                  <br />
                  <li>Learn more in the Knowledge Section.</li>
                </ul>
              </>
            }
          >
            {' '}
            <ModalLinkButton to="/asset/auto/add/auto" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <AutoTable list={autoList} />
        <Spacer variant="gray-spacer" />
        <div className="d-flex align-items-center">
          <Typography variant="h2">Auto Maintenance</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Add here all the upcoming vehicle maintenance requirements for each vehicle. </li>
                  <br />
                  <li>Include both large and small maintenances.  </li>
                  <br />
                  <li>Learn more in the Knowledge Section.</li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/asset/auto/add/maintenance" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <Spacer variant="medium" />
        <MaintenanceTable list={selectMaintenance} />
        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Managing Auto</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/auto/categories/" />
      </Col>
    </Row>
  );
}
