import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { AutoForm } from './AutoForm';
import { AutoFormInputs } from './types';
import { createAuto, getAutoList } from './autoSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddAuto: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getAutoListAction = useCallback(() => dispatch(getAutoList()), [dispatch]);

  const onSubmit = async (data: AutoFormInputs) => {
    try {
      await createAuto(data);
      history.push('/asset/auto');
      getAutoListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Auto']}>
      <AutoForm onSubmit={onSubmit} />
    </Modal>
  );
};
