import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const TheSixLevelModelOfWork: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>The Six Level Model of Work</h1>

        <p className={styles.bodyText}>
          You must have a good understanding of your work to set your priorities. In this Six Level Model, there are six
          different perspectives to define and categorize your work.
        </p>

        <p className={styles.bodyText}>
          Following are the six-levels, using the aerospace analogy since it’s all about altitude:
        </p>

        <ul className={styles.list}>
          <li>50,000+ feet: Life.</li>
          <li>40,000 feet: Three- to five-year goals.</li>
          <li>30,000 feet: One- to two-year goals.</li>
          <li>20,000 feet: Areas of responsibility.</li>
          <li>10,000 feet: Current projects.</li>
          <li>Runway: Current actions</li>
        </ul>

        <p className={styles.bodyText}>We will review from the bottom up.</p>

        <h3 className={styles.paraHeading}>Runway: Current Actions</h3>

        <p className={styles.bodyText}>
          This is the accumulated list of actions that you immediately need to take. For example, the phone call you
          need to make, the utility bill you need to pay etc.
        </p>

        <h3 className={styles.paraHeading}>10,000 Feet: Current Projects</h3>
        <p className={styles.bodyText}>
          These are the short-term results you intend to achieve, such as setting up a home security system, organizing
          a party, moving to a new house or getting a financial advisor.
        </p>

        <h3 className={styles.paraHeading}> 20,000 Feet: Areas of Responsibility</h3>
        <p className={styles.bodyText}>
          You create most of your projects because of your responsibilities. These are the key areas within which you
          want to achieve results and maintain standards. In your personal life, your focus areas could be health,
          family, finances, recreation, spirituality etc.
        </p>

        <h3 className={styles.paraHeading}> 30,000 Feet: One- to Two-Year Goals</h3>
        <p className={styles.bodyText}>
          These goals are what you want to achieve in next one to two years. Identifying these goals helps you to set
          your priority tasks and highlight importance of certain aspects of life while diminishing others.
        </p>

        <h3 className={styles.paraHeading}> 40,000 Feet: Three- to Five-Year Vision</h3>
        <p className={styles.bodyText}>
          Focusing on three to five years into the future helps to think on broader categories like personal game plan,
          long-term career progression, family planning, financial goals etc. Decisions at this altitude can easily
          reset many of your priorities.
        </p>

        <h3 className={styles.paraHeading}> 50,000 Feet: Life</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          This is the “big picture” perspective. The reason you exist. This is the primary purpose that defines your
          work. All your goals, visions, objectives and tasks origins from this big picture.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/23/2022'} />
      </div>

      <FeedbackBox source="/calendar/time-management/text/short/23-jun-2022/01" />
    </>
  );
};
