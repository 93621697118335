import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HelpBox: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Help Box</h1>

        <div className={styles.videoContainer}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://player.vimeo.com/video/619309707?h=150c35108b&autoplay=1&title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Help box"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <p className={clsx(styles.bodyText, styles.last)}>
          Help Boxes are available in all the pages to explain the role of all the key Buttons, Icons, Headers and
          Terminologies. Hover your cursor on the desired location for assistance to navigate through the page.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'09/30/2021'} />
      </div>

      <FeedbackBox source="/overview/navigating-this-site/video/short/30-sep-2021/01" />
    </>
  );
};
