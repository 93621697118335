import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const EnvelopMethod: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Envelop Method</h1>

        <ul className={styles.list}>
          <li>Using Envelops is an old fashioned but still very effective way to control and monitor expenses.</li>
          <li>
            This is a very simple process and easy to keep track of things.Everyone can easily follow on a regular
            basis.
          </li>
          <li>Envelop could mean Paper Envelops or separate Bank Accounts or even an Excel Spreadsheet.</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>The Process</h2>

        <ul className={styles.list}>
          <li>
            <strong>Allocation of Funds</strong>: In this method, you allocate money for each of your major expense
            items for your monthly expenditure.
          </li>
          <li>
            <strong>Transfer of Funds</strong>: Then you transfer monthly income into these Envelops or expense items.
          </li>
          <li>
            <strong>Use of Paper Envelops</strong>: If you are used to cash only, then you may put actual cash into
            different Paper Envelops.
          </li>
          <li>
            <strong>User of Bank Accounts</strong>: Instead, these Envelops could be symbolic and you can transfer these
            allocated funds into different Saving Accounts in your Bank.
          </li>
          <li>
            <strong>Use of Excel Spreadsheet</strong>: An Excel Spreadsheet may also serve your purpose, but we do not
            recommend this Worksheet Process since you may not be able to update it regularly and soon you will likely
            lose track of your individual expense items.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>Rules of Envelop Method</h2>

        <ul className={styles.list}>
          <li>The expense amount you allocate at the beginning of month is final. You can’t change it.</li>
          <li>
            You can’t overspend in any expense items. When you run out of cash in any Envelop, that’s it for that month.
            Let’s say, if you spent all the cash from your Shopping Envelop, then you can’t shop during rest of the
            month and have to wait until the Shopping Envelop is refilled with next month’s allocated fund.
          </li>
          <li>
            In case of emergency, any one Envelop may borrow from another Envelop, but you have to cut back your
            expenses of the Envelop you are borrowing from.
          </li>
          <li>Overall, you can’t exceed the total expense limit of all the Envelops.</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>Caution</h2>

        <ul className={styles.list}>
          <li>
            If you are using Bank Accounts, ensure your accounts don’t allow overdraft. For example, if you are
            attempting to charge $25 in your Debit Card, and you only have $20 in your Bank Account then the transaction
            must be declined. Otherwise, you are setting yourself up for a lot of Overdraft Handling Fees.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>Conclusion</h2>

        <ul className={styles.list}>
          <li>
            The Envelop Method is an excellent way to build habit. It helps to train your mind to live within your
            means. Don’t try to be perfect in day one. Strat anywhere and gradually turn this into a systematic process
            of tracking and improvement.
          </li>
          <li>
            After adopting this process, you will have better insights about where you are overspending and how to
            gradually reduce your expenses in the categories where you are currently spending relatively more.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>
        <PublishedDate date={'02/19/2022'} />
      </div>

      <FeedbackBox source="/saving/timelessprinciples/text/short/19-feb-2022/01" />
    </>
  );
};
