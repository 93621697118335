import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Spinner, Row, Col } from 'react-bootstrap';

import styles from './VerifyEmail.module.scss';

import { doPostRequest, BadRequestError } from '../../helpers/api';

interface RequestBody {
  uuid: string;
  token: string;
}

const verifyEmailApi = (body: RequestBody) => {
  return doPostRequest('user/verify-email/', body, false);
};

export const VerifyEmail: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(decodeURIComponent(search));
  const verificationUuid = query.get('uuid');
  const verificationToken = query.get('token');

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [verifiedEmail, setVerifiedEmail] = useState<boolean>(false);

  useEffect(() => {
    if (verificationUuid && verificationToken) {
      const request: RequestBody = {
        uuid: verificationUuid,
        token: verificationToken,
      };

      setShowLoader(true);

      verifyEmailApi(request)
        .then((res) => {
          setShowLoader(false);
          setError('');
          setVerifiedEmail(true);
        })
        .catch((err) => {
          let errorMessage = '';
          if (err instanceof BadRequestError) {
            errorMessage = err.response + '';
          } else {
            errorMessage = err.message;
          }
          setShowLoader(false);
          setError(errorMessage);
          setVerifiedEmail(false);
        });
    }
  }, [verificationUuid, verificationToken]);

  return (
    <>
      {verifiedEmail ? <Redirect to="/user/login" /> : null}

      <div className="position-relative">
        {showLoader ? (
          <div className={styles.loader}>
            <Spinner animation="border" />
          </div>
        ) : null}

        {error ? (
          <>
            <Row className="">
              <Col lg={12}>
                <h5 className={'text-danger'}>{error}</h5>
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    </>
  );
};
