import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const IncomeFromOtherSources: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Income from Other Sources</h1>

        <p className={styles.bodyText}>
          Income from other sources like the following are also required to be included in the tax return.
        </p>

        <ul className={styles.list}>
          <li>Royalties,</li>
          <li>Investment Income</li>
          <li>Winning from Sports</li>
          <li>Lottery Winning</li>
        </ul>

        <p className={styles.bodyText}>
          Report on line 13000 of your tax return any taxable income that has not been or should not be reported
          anywhere else on the return. Specify the type of income you are reporting in the space provided on line 13000
          of your tax return.
        </p>

        <p className={styles.bodyText}>
          Report taxable scholarships, fellowships, bursaries, and artists&apos; project grants on line 13010 of your
          tax return.
        </p>

        <h3 className={styles.paraHeading}>Royalties</h3>

        <p className={styles.bodyText}>
          Royalties are considered payments received as compensation for using or allowing the use of a copyright,
          patent, trademark, formula, or secret process. They can also include payments relating to cinematic films,
          film works, or television tapes.
        </p>

        <p className={styles.bodyText}>
          If your royalties are from a work or invention of yours and there are no associated expenses, report the
          income on line 10400 - other employment income of your tax return.
        </p>

        <p className={styles.bodyText}>
          If there were associated expenses, report the income on line 13500 - Self-employment income of your tax
          return.
        </p>

        <p className={styles.bodyText}>
          Report all other royalties on line 12100 - Interest and other investment income of your tax return.
        </p>

        <h3 className={styles.paraHeading}>Interest Income</h3>

        <p className={styles.bodyText}>
          Interest from bank accounts, term deposits, saving bonds, treasury bills, dividend income, non-business
          income, interest on tax refund also need to be reported in the tax return. On T5 Forms, you must explicitly
          state, if you are earning interest. Taxes are applicable on this interest.
        </p>

        <h3 className={styles.paraHeading}>Winning Income</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          Winning income from sports and horse races, legal sports bets, lotteries, online casinos, etc.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/03/2021'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses-for-business/text/short/03-may-2022/01" />
    </>
  );
};
