import React, { useEffect, useState } from 'react';

import { Input, Radio } from '../../components/Form';
import { SubmitButton } from '../../components/Form';

import styles from './Questionnaire.module.scss';
import { Answer, Question } from './types';

export type QuestionFormType = {
  questions: Array<Question>;
  onFormSubmit: (answers: Record<number, Answer>) => void;
};

export const QuestionForm = ({ questions, onFormSubmit }: QuestionFormType) => {
  const [answers, setAnswers] = useState<Record<number, Answer>>({});

  useEffect(() => {
    const allAnswers: Record<number, Answer> = {};
    questions.forEach((question) => {
      const ans = question?.answers?.find((a: Answer) => a.is_selected);
      if (ans) {
        allAnswers[question.pk] = ans;
      }
      return ans;
    });
    setAnswers(allAnswers);
  }, [questions]);

  const isValid = questions.every((question) => question.pk in answers);

  const recordAnswer = (questionId: string | number, answer: Answer) => {
    setAnswers({ ...answers, [questionId]: answer });
  };

  return (
    <>
      {questions?.map((question, idx) => {
        return (
          <div
            key={question.pk}
            className={
              idx + 1 === questions?.length && questions?.length !== 1
                ? styles.lastQuestionContainer
                : styles.questionContainer
            }
          >
            <div className={styles.questionTitle}>{question.question}</div>

            <div className={styles.questionOptions}>
              {question?.answers?.map((option, oidx) => {
                const selectedAnswer = answers?.[question.pk];
                return (
                  <div key={oidx} className="row align-items-center">
                    <div className="col-12">
                      <Radio
                        idx={`idx-${idx}-${oidx}`}
                        name={`question_options-${question?.pk}`}
                        value={option?.answer}
                        className={
                          selectedAnswer?.pk === option?.pk && option.has_input
                            ? styles.questionOptionLast
                            : oidx + 1 === question?.answers?.length
                            ? styles.questionOptionLast
                            : styles.questionOption
                        }
                        checked={selectedAnswer?.pk === option?.pk}
                        onChange={(evt) => {
                          if (evt?.currentTarget?.checked) {
                            recordAnswer(question?.pk, { ...option, is_selected: true });
                          }
                        }}
                      >
                        {option?.answer}
                      </Radio>
                    </div>

                    {selectedAnswer?.pk === option?.pk && option.has_input ? (
                      <div className="col-6">
                        <Input
                          name={option.type_input}
                          placeholder={option?.answer ?? ''}
                          className={styles.inputBlock}
                          labelClassName={styles.label}
                          value={selectedAnswer.inputted_answer ?? ''}
                          onChange={(evt) => {
                            recordAnswer(question?.pk, { ...option, inputted_answer: evt.currentTarget.value });
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <SubmitButton
        value="Submit"
        disabled={!isValid}
        className={styles.submitButtonBlock}
        onClick={() => {
          onFormSubmit(answers);
        }}
      />
    </>
  );
};
