import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const BillingMembershipFee: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Billing Membership Fee</h1>

        <ol className={styles.list}>
          <li>Membership Fee will incur for your use of Arctic Rich service.</li>
          <li>
            Applicable taxes and other charges like transaction fees will also be charged along with your Membership
            Fee.
          </li>
          <li>
            Arctic Rich will need payment methods like Credit Cards, which are current, valid and accepted method of
            payment.
          </li>
          <li>You will be charged on a monthly billing cycle.</li>
          <li>
            Unless you cancel your membership prior to your billing date, you authorize Arctic Rich to charge the
            Membership Fee for the next billing cycle to your Payment Method.
          </li>
          <li>
            Generally, your Membership Fee will be charged on the same date of every month. For example, on 21st of
            every month.
          </li>
          <li>
            Your regular payment date may change in a select few circumstances. For example, your paid membership began
            on a day (March 31st) not contained in a given month (say April, which does not have 31st). In such cases,
            you will be charged on the last day of the month (April 30th).
          </li>
          <li>
            You authorize Arctic Rich to charge your Membership Fee up to approximately one month of service when you
            register in anticipation of membership or service-related charges.
          </li>
        </ol>

        <p className={styles.bodyText}>These are part of your Terms of Use for this account.</p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Please click here to review the full details of your obligations and responsibilities for the membership of
          Arctic Rich Account.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'09/19/2021'} />
      </div>

      <FeedbackBox source="/user-account/managing-account/text/short/19-sep-2021/02" />
    </>
  );
};
