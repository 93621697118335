import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { BillForm } from './BillForm';
import { BillFormInputs } from './types';
import { updateBill, getBillList } from './billSlice';
import { BadRequestError } from '../../helpers/api';
import { selectBillById, selectListLoading } from './billSlice';

export const EditBill: React.FC = () => {
  let { billId } = useParams();
  billId = parseInt(billId, 10);
  const billItem = useSelector(selectBillById(billId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getBillListAction = useCallback(() => dispatch(getBillList()), [dispatch]);

  const onSubmit = async (data: BillFormInputs) => {
    try {
      await updateBill(billId, data);
      history.push('/expense/bill');
      getBillListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Bills']}>
      <BillForm defaultValues={billItem} onSubmit={onSubmit} />
    </Modal>
  );
};
