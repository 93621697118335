import React, { useState, useEffect } from 'react';
import { Pagination as RBPagination } from 'react-bootstrap';
import clsx from 'clsx';
import styles from './Pagination.module.scss';

const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

export interface PaginationResult {
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  totalRecords?: number;
}

interface PaginationsProps {
  defaultSelected?: number;
  onPageChange: (value: PaginationResult) => void;
  total: number;
  pageSize: number;
  pageNeighbours: number;
  reset?: boolean;
  paginationClass?: string;
}

export const Pagination = ({
  defaultSelected,
  onPageChange,
  total,
  pageSize,
  pageNeighbours,
  reset,
}: PaginationsProps) => {
  const [selected, setselected] = useState(defaultSelected || 1);

  const totalPages = total < pageSize ? 1 : Math.ceil(total / pageSize);

  const pageNeighbours2 = Math.max(0, Math.min(pageNeighbours, 2)) || 0;

  const gotoPage = (page: number) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData: PaginationResult = {
      currentPage,
      totalPages: totalPages,
      pageLimit: pageSize,
      totalRecords: total,
    };

    if (onPageChange) {
      onPageChange(paginationData);
    }

    setselected(page);
  };

  useEffect(() => {
    if (reset) {
      setselected(defaultSelected || 1);
    }
  }, [reset, defaultSelected]);

  const handleClick = (page: number | string) => {
    if (typeof page === 'string') {
      gotoPage(parseInt(page));
    } else {
      gotoPage(page);
    }
  };

  const handleMoveLeft = () => {
    gotoPage(selected - pageNeighbours2 * 2 - 1);
  };

  const handleMoveRight = () => {
    gotoPage(selected + pageNeighbours2 * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const currentPage = selected;
    const pageNeighbours = pageNeighbours2;

    const totalNumbers = pageNeighbours2 * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages: Array<number | string> = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      console.log(endPage, endPage + singleSpillOffset);

      let extraPages = range(endPage, endPage + singleSpillOffset);
      console.log(JSON.stringify(extraPages));
      console.log(JSON.stringify(pages));

      extraPages = extraPages.filter((x) => x < totalPages);
      pages = [...pages, ...extraPages];

      pages = Array.from(new Set(pages));

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages + 1);
  };

  const pages = fetchPageNumbers();
  const isLastPage: boolean = pages.length === selected ? true : false;

  return (
    <RBPagination className={clsx(styles.appPagination)}>
      <RBPagination.Item
        className={clsx(styles.paginationItem)}
        onClick={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault();
          handleMoveLeft();
        }}
        disabled={selected === 1}
      >
        Previous
      </RBPagination.Item>
      {pages.map((page: number | string, index: number) => {
        return (
          <RBPagination.Item
            className={clsx(styles.paginationItem, { [styles.paginationItemActive]: selected === page })}
            key={index}
            active={selected === page}
            onClick={(e: React.FormEvent<HTMLInputElement>) => {
              e.preventDefault();
              handleClick(page);
            }}
          >
            {page}
          </RBPagination.Item>
        );
      })}
      <RBPagination.Item
        className={clsx(styles.paginationItem)}
        onClick={(e: React.FormEvent<HTMLInputElement>) => {
          e.preventDefault();
          handleMoveRight();
        }}
        disabled={isLastPage}
      >
        Next
      </RBPagination.Item>
    </RBPagination>
  );
};
