import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { TaxFormInputs } from './types';
import { Form, Input, SubmitButton } from '../../components/Form';

const schemaResolver = yupResolver<TaxFormInputs>(
  yup.object().shape({
    year: yup
      .number()
      .min(2000)
      .typeError('year should be more than 2000')
      .max(2021)
      .typeError('year should be less than 2022')
      .required('please select a date'),
    taxable_income: yup
      .number()
      .typeError('please enter taxable income')
      .required('please enter a taxable income')
      .positive(),
    tax_payable: yup
      .number()
      .typeError('please enter a taxable payable')
      .required('please enter a taxable payable')
      .positive(),
    // tax_rate: yup.number().required('please select a taxable rate'),
    tax_refund: yup
      .number()
      .typeError('please enter a taxable refund')
      .required('please enter a taxable refund')
      .positive(),
  }),
);

export type TaxFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<TaxFormInputs>>;
  onSubmit: SubmitHandler<TaxFormInputs>;
};

export const TaxForm: React.FC<TaxFormProps> = ({ defaultValues = {}, onSubmit }) => {
  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <Input name="year" placeholder="Year" label="Year" />
        <Input name="taxable_income" placeholder="Taxable Income" label="Taxable Income" prefix="$" />
        <Input name="tax_payable" placeholder="Tax Payable" label="Tax Payable" prefix="$" />
        <Input name="tax_refund" placeholder="Tax Refund" label="Tax Refund" prefix="$" />
        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
