import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const SecuringMobilePayments: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Securing Mobile Payments</h1>

        <h3 className={styles.paraHeading}>Password, Face ID, Fingerprint</h3>
        <p className={styles.bodyText}>
          Enable one or more of these security measures for opening the mobile app and sending a payment.
        </p>

        <h3 className={styles.paraHeading}>Two Factor Authentication</h3>
        <p className={styles.bodyText}>
          Two Factor Authentication (2FA) adds two separate layers of protection and makes it much harder to gain
          unauthorized access to your app. It is more secure to set up phone text messages for transmitting verification
          codes, instead of emails.
        </p>

        <h3 className={styles.paraHeading}>Payment Notifications</h3>
        <p className={styles.bodyText}>
          You get immediate notification of any changes in your account and can take corrective actions instantly.
        </p>

        <h3 className={styles.paraHeading}>App Updates</h3>
        <p className={styles.bodyText}>
          App updates generally include security enhancements and bug fixes. Experts recommend enabling automatic
          updates to shield against all recent security threats and vulnerabilities.
        </p>

        <h3 className={styles.paraHeading}>Verify Recipient</h3>
        <p className={styles.bodyText}>
          Double check the recipient details before transferring funds. This can prevent typing errors and sending money
          to a wrong person. Use a link or QR wherever possible to avoid typing related errors.
        </p>

        <h3 className={styles.paraHeading}>Treat Like Cash</h3>
        <p className={styles.bodyText}>
          Mobile payments are like cash outflows. Once paid, it is difficult to get the money back. You must confirm
          user&apos;s identity and other credentials before making any payment.
        </p>

        <h3 className={styles.paraHeading}>Conclusion</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          Apple Pay and Google Pay are the best options, if security and privacy are your prime concerns. If sending
          money to the wrong person is your primary concern, then use payment apps which offer QR codes and link sharing
          like Facebook Pay, PayPal Mobile Cash etc.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/27/2022'} />
      </div>

      <FeedbackBox source="/transaction/mobile-payments/text/short/27-jul-2022/03" />
    </>
  );
};
