import React from 'react';
import { Link } from 'react-router-dom';

import { Modal } from '../../components/Modal';

import warningSign from '../../common/images/warning-sign.svg';
import styles from './AddGoal.module.scss';

export const MilestoneLimitWarningModal = () => {
  return (
    <Modal title={['No', 'Goals']} variant="maximumGoals">
      <div className="d-flex">
        <img src={warningSign} alt="" width="119" height="107" />
        <div className={styles.text}>
          <p className={styles.paragraph1}>There can be maximum of five active Milestones per Goal.</p>
          <p className={styles.paragraph2}>Delete or complete a current Milestone to create a new one.</p>
        </div>
      </div>
      <Link to="/goals/goal">
        <button type="button" className={styles.button + ' btn'}>
          OK
        </button>
      </Link>
    </Modal>
  );
};
