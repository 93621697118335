import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const FourPercentRule2022: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>4 Percent Rule in 2022</h1>

        <ul className={styles.list}>
          <li>
            Today’s retirees may need to rethink the 4% Rule, considering today’s High 8.5% Inflation and High Stock and
            Bond valuations. High inflation means, the portfolio must earn a higher return to protect the original value
            of the principal investment.
          </li>
          <li>Bill Bengen, a Financial Planner, first invented the 4% Rule in1996.</li>
          <li>
            The 4% Rule suggests spending 4% of the total retirement funds in the first year of retirement and then
            adjusting the amount annually keeping pace with inflation.
          </li>
          <li>
            This game plan will protect investors from running out of money every 30 years. This formula would
            technically prevail in all the market conditions since 1926.
          </li>
          <li>Today it is much harder to predict if the standard playbook will work for the recent retirees.</li>
          <li>
            Considering current market context, today’s retirees should be much more conservative in the withdrawal of
            their retirement funds. But that doesn’t necessarily mean going below 4% withdrawal per year.
          </li>
          <li>
            The original Bill Bengen research was based on 55% US Large Cap Stocks, 45% Intermediate Term Treasure
            Bonds.
          </li>
          <li>
            Since 2006, Bill revised this portfolio adding international stocks and mid-size, small-cap and microcap
            U.S. Stocks and Treasure Bills. These additions increased the returns and supported a higher withdrawal rate
            of 4.7% per year.
          </li>
          <li>
            Based on today’s market context, Bill Bengen suggests new retirees with highly diversified portfolio which
            historically could support 4.7% withdrawal rate, should start somewhere at 4.4% withdrawal rate of per year.
          </li>
          <li>
            Morningstar Inc. is recommending 3.3% annual withdrawal rate for today’s retirees, considering current
            market volatility.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'05/10/2022'} />
      </div>

      <FeedbackBox source="/4-percent-rule/text/short/10-may-2022/01" />
    </>
  );
};
