import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';
import {
  getCreditScoreList,
  selectListLoading,
  selectTargetOrActualCreditScoreList,
  selectCreditFactorsByYear,
} from './creditScoreSlice';
import HelpBox from '../../components/Common/HelpBox';
import { CreditScoreTable } from './CreditScoreTable';
import styles from './CreditScoreList.module.scss';
import { CreditFactorTable } from './CreditFactorTable';

const currentYear = new Date().getFullYear();
const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4];

export function CreditScoreList(): JSX.Element {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [creditScoreType, setCreditScoreType] = useState('Actual');

  const dispatch = useDispatch();
  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const creditScoreList = useSelector(selectTargetOrActualCreditScoreList(creditScoreType));
  const listLoading = useSelector(selectListLoading);

  const selectedCreditFactors = useSelector(selectCreditFactorsByYear(selectedYear));

  useEffect(() => {
    getCreditScoreListAction();
  }, [getCreditScoreListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />

        <div className="d-flex align-items-center">
          <Typography variant="h1">Credit Score</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Add Credit Score Here​</li>
                  <br />
                  <li>Include up to two Credit Scores per year.</li>
                  <br />
                  <li>
                    You can collect your Actual Credit Score from the Credit Score Agencies or your Finacial
                    Institutions.​
                  </li>
                  <br />
                  <li>Add your Target Credit Scores for next three years.​</li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/loan/credit-score/add/credit-score" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>

        <Spacer variant="medium" />
        <span
          onClick={() => setCreditScoreType('Actual')}
          className={clsx(styles.creditScoreBlock, {
            [styles.creditScoreBlockSelected]: creditScoreType === 'Actual',
          })}
        >
          Actual
        </span>
        <div className={styles.creditScoreBlockSpacing} />
        <span
          onClick={() => setCreditScoreType('Target')}
          className={clsx(styles.creditScoreBlock, {
            [styles.creditScoreBlockSelected]: creditScoreType === 'Target',
          })}
        >
          Target
        </span>

        <CreditScoreTable list={creditScoreList} />
        <Spacer variant="gray-spacer" />
        <div className="d-flex align-items-center">
          <Typography variant="h2">Factors Influencing Credit Score</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Add any factors that contributed to losing points in the Credit Score.</li>
                  <br />
                  <li>
                    For example, delay in paying Bills, over usage of Debt Facilities, etc. Learn more in the Knowledge
                    Section.
                  </li>
                  <br />
                  <li>This should be available in the Credit Report.</li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/loan/credit-score/add/credit-factor" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <Spacer variant="medium" />
        {years.map((year) => (
          <React.Fragment key={year}>
            {year !== currentYear ? <div className={styles.creditScoreBlockSpacing} /> : null}
            <span
              onClick={() => setSelectedYear(year)}
              className={clsx(styles.creditScoreBlock, { [styles.creditScoreBlockSelected]: selectedYear === year })}
            >
              {year}
            </span>
          </React.Fragment>
        ))}
        <CreditFactorTable list={selectedCreditFactors} />
        <Spacer variant="red-spacer" />

        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Managing Credit Score</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/credit_score/categories/" />
      </Col>
    </Row>
  );
}
