import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import BEVLabel from '../common/images/BEVLabel.jpeg';

export const CostToPowerElectricVehicle: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Cost to Power Electric Vehicle</h1>

        <ol className={styles.list}>
          <li>
            An electric vehicle&apos;s energy consumption is measured in kilowatt-hours per 100 kilometers (kWh/100 kms)
            instead of liters per 100 kilometers (L/100 kms) used for conventional gasoline vehicle
          </li>
          <li>
            That rating is on the car&apos;s fuel economy sticker (as shown below) and in the owner&apos;s manual.
            <div className={styles.imgContainer}>
              <img src={BEVLabel} alt="" width="100%" />
            </div>
          </li>
          <li>
            To calculate your cost of charging at home, multiply your vehicle&apos;s kWh/100 kilometers figure by the
            electricity rate (the cost per kWh). Apply the electricity rate applicable for the time of day when you most
            frequently charge. The result is your cost per 100 kilometers.
          </li>
          <li>
            The cost can also be calculated from the total kilowatt hours needed to recharge the battery. For example,
            your electric vehicle&apos;s battery needs 50 kWh to recharge a fully depleted battery and the electricity
            rate is $0.20 per kWh, then it costs you $10.00 to fill it up (50 kWh * $0.20 per kWh).
          </li>
          <li>
            Electricity price is more stable compared to the gasoline. However, it could significantly vary depending on
            the province you live and provider of your electricity as well as when and where you are charging.
          </li>
          <li>
            Most electric vehicles can be charged at home from a regular (120 volt) outlet known as Level 1 charging. It
            is better to install a higher voltage charger (240 volt) for faster charging, called Level 2 charging.
          </li>
          <li>
            Charging at any charging station can be done in as little as 30 minutes. It can also take 10 hours or more
            depending on battery size, battery&apos;s current charge, maximum charging capacity of your vehicle and the
            charger.
          </li>
          <li>
            Electric Vehicle Supply Equipment (EVSE): Most automakers have a preferred charger provider, but you can buy
            a good charger from Amazon, Home Depot or Best Buy. Price of a good quality Level 2 home systems can range
            between $300 to $1,000 before installation.
          </li>
          <li>
            For vehicles with small battery, under 10 kWh, the Level 1 charging system that comes with the vehicle would
            be sufficient. For plug-in cars with larger batteries, Level 2 is preferred for overnight charging and
            faster top-ups.
          </li>
          <li>
            Solar is better for powering the car and home together. A properly sized whole-house solar system will pay
            for itself over about seven years and will last for at least 25 years. Starting at $9,000, costs largely
            dependent on the size of the system, regional labor rates, the quality of the solar panels and power
            inverter used, and the complexity of the installation.
          </li>
        </ol>

        <h4 className={styles.noteHeading}>Note</h4>
        <p className={styles.bodyText}>The marker in above exhibit indicates the following.</p>
        <ol className={styles.list}>
          <li>
            <strong>Fuel Consumption:</strong> Separate city and highway fuel consumption ratings in Le/100 km, and the
            combined electricity consumption rating are expressed in kWh/100 km. The combined rating reflects 55% city
            and 45% highway driving.
          </li>
          <li>
            <strong>Recharge Time:</strong> This shows the estimated time (in hours) to fully recharge the battery at
            240 volts.
          </li>
          <li>
            <strong>Driving Range:</strong> This shows the estimated driving distance (in kilometres) on a fully charged
            battery.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'07/12/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-II/text/short/12-jul-2022/02" />
    </>
  );
};
