import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const MustHave50: React.FC = () => {
  const columns = [
    {
      dataField: 'shelter',
      text: 'Shelter',
    },
    {
      dataField: 'transportation',
      text: 'Transportation',
    },
    {
      dataField: 'others',
      text: 'Food / Medical / Loan / Other',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Must Have 50%</h1>

        <p className={styles.bodyText}>
          Must Have expenses are the basics. Things you can’t live without. Not surprisingly, the list is very short:
          shelter, transportation, food, medical care, insurance, and the minimum payments you can’t escape like legal
          obligations. These are the amounts which need to be paid every month - in good times and bad.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Must Have Qualifiers</h2>

        <p className={styles.bodyText}>Three simple guidelines to decide what qualifies as the Must Haves:</p>
        <ol className={styles.list}>
          <li>Could you live in safety and with dignity without this purchase (at least for a while)?</li>
          <li>
            If you lost your regular income stream (e.g. job), would you still keep on spending money on this item?
          </li>
          <li>Could you live without this purchase for at least six months?</li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>Checklist of Must Haves</h2>

        <p className={styles.bodyText}>Following is the full list of expenses included in the Must Have category.</p>

        <SimpleTable
          keyField="id"
          data={[
            {
              shelter: 'House Rent or Mortgage',
              transportation: 'Monthly Car or Lease Payment',
              others: 'Basic Food Needs',
            },
            {
              shelter: 'Utilities (Gas, Water, Electricity)',
              transportation: 'Car Insurance, Registration',
              others: 'Recurring Medical Expenses',
            },
            { shelter: 'Property Taxes', transportation: 'Gas', others: 'Health Insurance' },
            { shelter: 'Property Insurance', transportation: 'Car Maintenance', others: 'Student Loan' },
            { shelter: 'Basic Phone Service', transportation: 'Public Transit', others: 'Life, Disability Insurance' },
            { shelter: 'Home Maintenance', transportation: 'Parking', others: 'Ongoing Legal Obligations' },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={clsx(styles.paraHeading)}>Understanding Your Must Have Level</h2>
        <h2 className={clsx(styles.paraHeading)}>Under 35% Must Have – Safety Zone</h2>
        <p className={styles.bodyText}>
          Excellent position to be in. You have plenty of flexibility. We recommend allocating the surplus to the Saving
          category. It is important to create as much cushion as possible during good times.
        </p>

        <h2 className={clsx(styles.paraHeading)}>35-50% Must Have - Balance</h2>
        <p className={styles.bodyText}>
          Your Must Have spending is well balanced. Saving and investing this surplus amount for the long-term would
          ensure your financial sustainability and lead you to financial independence much faster.
        </p>

        <h2 className={clsx(styles.paraHeading)}>50-65% Must Have – Danger Zone</h2>
        <p className={styles.bodyText}>
          Clearly an Amber or a Red Light. Your Must Have commitments are much larger for your income. You need to
          address this situation with a sense of urgency. We will guide you with the detail steps but it is you who
          would need to act. It has to be brought down to 50% level, either by reducing your expense or by increasing
          your income.
        </p>

        <h2 className={clsx(styles.paraHeading)}>65%+ Must Have – Crash Zone</h2>
        <p className={styles.bodyText}>
          ClYou are simply in a MAY DAY situation. There is no way to sugar coat this. All your efforts must be focused
          to trim the Must Haves or increase your income immediately. At this point, there is no other priority higher
          than this. In such an exceptional situation, you should be ready to forgo part or all of your 30% Wants until
          we put out the fire.
        </p>

        <h2 className={clsx(styles.noteHeading)}>Note</h2>

        <p className={styles.bodyText}>
          Caution must be applied while deciding on Food and Clothing items. Food here means the basic nutrition food
          needed for individuals. Any high-end or ready to eat food items as well as dining out would fall under the
          Wants category.
        </p>
        <p className={styles.bodyText}>
          Same with clothing. If basic clothing and shoes can take care of your needs for a little longer (six months or
          more), then spending in these items should be withheld. If spent however, will fall into the Wants category.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/24/2022'} />
      </div>

      <FeedbackBox source="/saving/20-30-50-plan/text/short/24-feb-2022/04" />
    </>
  );
};
