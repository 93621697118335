import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PasswordGuideline: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Password Guideline</h1>

        <p className={styles.bodyText}>Passwords must be 8-26 characters long and include all of the following:</p>

        <ul className={styles.list}>
          <li>One lowercase letter</li>
          <li>One uppercase letter</li>
          <li>One number</li>
          <li>One special character ( ! @ # $ % * )</li>
        </ul>

        <p className={clsx(styles.bodyText, styles.last)}>
          Passwords cannot contain your first name, last name or email address used to login to arcticrich.com.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'09/12/2021'} />
      </div>
      <FeedbackBox source="/user-account/managing-account/text/short/12-sep-2021/0" />
    </>
  );
};
