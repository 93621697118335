import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhatIsGIC: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is GIC</h1>

        <p className={styles.bodyText}>GIC stands for Guaranteed Investment Certificate (GIC).</p>

        <ul className={styles.list}>
          <li>
            It is a kind of deposit you usually keep in your bank. Very similar to the money you deposit in your savings
            account.
          </li>
          <li>
            Just like a deposit in a savings account, you will earn interest on your deposits or investments in GICs.
          </li>
          <li>
            You invest in GICs for a particular amount ($500 to $999,000) and for a particular period (one month to ten
            years) with a fixed maturity date.
          </li>
          <li>
            On the maturity date, you get back your original investment plus the interest you earn during the period.
          </li>
          <li>
            Minimum and maximum amount and tenor of investment in GIC may vary, depending on your financial institution.
          </li>
          <li>
            GICs earn higher interest rate than savings accounts. In exchange, you agree not to withdraw the funds until
            maturity.
          </li>
          <li>
            You will be required to pay high penalty if you choose to withdraw funds from GIC before its maturity date.
          </li>
          <li>
            GIC is regarded as a safe way to invest. As a GIC investor, you are guaranteed to get back the original
            money you invest and plus the interest you earn when your GIC matures.
          </li>
          <li>
            GICs are eligible for insurance coverage up to $100,000 by CDIC (Canadian Deposit Insurance Corporation).
          </li>
          <li>
            Your GICs are insured if your Bank is a CDIC (Canadian Deposit Insurance Corporation) member, GIC is issued
            in Canadian Dollars and term of GIC is 5 year or less.
          </li>
          <li>
            To summarise, GIC is a form of investment you can make by depositing money in your bank for a fixed period
            of time and earn a predetermined and guaranteed rate of interest.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/28/2022'} />
      </div>

      <FeedbackBox source="/balance/basics/text/short/28-jun-2022/02" />
    </>
  );
};
