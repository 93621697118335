import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const BenefitsOfOwningAHome: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Benefits of Owning a Home</h1>
        <p className={styles.bodyText}>It is always better to own a home than rent. Here is why:</p>

        <ol className={styles.list}>
          <li>
            <strong>Predictable Costs</strong>
            <p className={styles.listText}>
              Once a property is bought, the corresponding price and subsequent payments are known. Easy to forecast and
              plan expanses.
            </p>
          </li>
          <li>
            <strong>Value Appreciation</strong>
            <p className={styles.listText}>Over time, value of the property increases in most cases.</p>
          </li>
          <li>
            <strong>Building Equity</strong>
            <p className={styles.listText}>
              A portion of every mortgage payment goes toward repaying principal loan, which increases your proportion
              of the ownership in the property.
            </p>
          </li>
          <li>
            <strong>Cheaper</strong>
            <p className={styles.listText}>
              In most cases, costs associated with buying a home is far less than renting, particularly considering the
              inflation and property price increases during the time period under consideration (25-40 years).
            </p>
          </li>
          <li>
            <strong>Credit Profile</strong>
            <p className={styles.listText}>
              Having a mortgage is better for building a strong credit profile and history.
            </p>
          </li>
          <li>
            <strong>Free Living</strong>
            <p className={styles.listText}>
              Once the mortgage is fully paid off, you can live in that property for rest of your life with small
              expanses like property taxes, routine maintenance etc. This can be a significant contributor to your
              standard of living. On average, Canadians spend 45.9% of their total Household Expenses on housing.
              <img src={redCircle} className={styles.redCircle} alt="" />
            </p>
          </li>
        </ol>

        <PublishedDate date={'04/12/2022'} />
      </div>

      <FeedbackBox source="/home/buying-a-house/text/short/12-apr-2022/02" />
    </>
  );
};
