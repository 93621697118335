import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const MobilePaymentsVsDigitalWallets: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Mobile Payments vs. Digital Wallets</h1>

        <h3 className={styles.paraHeading}>Mobile Payments</h3>
        <ul className={styles.list}>
          <li>
            For the banking apps, you first need to download the app and enter your bank account access ID and password.
          </li>
          <li>
            After the authentications are complete, you can sign into your account from the app, same way you do from
            your computer.
          </li>
          <li>Almost all the banking activities can be performed from the mobile app including depositing cheques.</li>
          <li>You can also send money from the mobile app to a local or international recipient.</li>
        </ul>

        <h3 className={styles.paraHeading}>Digital Wallets</h3>
        <ul className={styles.list}>
          <li>A digital wallet is a form of mobile payment and work very much like a physical wallet.</li>
          <li>However, digital wallet works a bit differently than a Mobile Payment App.</li>
          <li>
            You can make in-person payments using digital wallets in any retail store that offer contactless payments.
          </li>
          <li>First, you need to download the digital wallet app to get started.</li>
          <li>You can then add your debit and credit card details.</li>
          <li>You may also upload your loyalty and gift cards in your digital wallet.</li>
          <li>
            To make a payment, you need to hold your device on the contactless terminal just as you would with your
            credit or debit card.
          </li>
          <li>
            Your digital wallet communicates your payment card details to a merchant&apos;s payment terminal using Near
            Field Communication Technology, that lets digital devices to communicate in proximity.
          </li>
          <li>
            Instead of being limited by mobile payments to a single institution, digital wallet gives you the freedom to
            use any debit or credit card from any institution that you have linked your digital wallet.
          </li>
          <li>
            Overall, digital wallet is more flexible than mobile payments.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'07/27/2022'} />
      </div>

      <FeedbackBox source="/transaction/mobile-payments/text/short/27-jul-2022/04" />
    </>
  );
};
