import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import creditScoreImg from '../common/images/creditScoreImg.png';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const MeaningCreditScore: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Meaning of Credit Scores</h1>

        <h2 className={clsx(styles.paraHeading)}>800 to 900</h2>

        <ul className={styles.list}>
          <li>Excellent Score. The highest Tier of Credit Score available.</li>
          <li>Lenders treat this score as the lowest possible risk available and consider as their prime target.</li>
          <li>
            This score is generally a result of late payments of loans or bills at almost negligent level or none,
            coupled with very low utilization of credit facilities.
          </li>
          <li>Credit facilities are generally paid in full on a regular basis.</li>
          <li>Entitled to the lowest interest, high loan amount and flexible credit terms.</li>
          <li>
            The premium offers, credit facilities and credit cards are usually offered exclusively to this elite class.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>720 to 799</h2>

        <ul className={styles.list}>
          <li>Regarded as a Very Good Score.</li>
          <li>This range of Credit Score reflects financially responsible behavior pattern.</li>
          <li>
            Credit score in this rage is generally the result of timely payment of loans and bills along with low
            utilization of the credit facilities.
          </li>
          <li>
            Lenders treat this as low risk and generally offer the Credit Applicants multiple options to choose from.
          </li>
          <li>Low interest rate and flexible terms are offered.</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>650 to 719</h2>

        <ul className={styles.list}>
          <li>Considered as a Good Score.</li>
          <li>
            This type of Credit Score Range is generally triggered by numerous delays in payments or defaults of the
            loans to one or more lenders.
          </li>

          <li>Lenders treat this type of Credit Score as a moderate to low risk.</li>

          <li>
            New credit approvals are common but unsecured credit facilities are selectively approved with caution.
          </li>

          <li>
            Higher interest rates and relatively stricter terms are expected although many within this range may
            officially qualify for the top-tier offers.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>600 to 649</h2>

        <ul className={styles.list}>
          <li>Treated as a Fair Score.</li>

          <li>
            Score reflects previous overdue loans. Lenders perceive these borrowers as high risk and deals only on a
            selective basis.
          </li>

          <li>Responsible financial commitment is questionable.</li>

          <li>Obtaining approval of new credit facility is unlikely.</li>

          <li>High interest rate and tougher terms are expected whenever any new credit is approved on rare cases.</li>
        </ul>
        <h2 className={clsx(styles.paraHeading)}>300 to 599</h2>

        <ul className={styles.list}>
          <li>Lowest score range. Poor Score.</li>

          <li>Significant work needs to be done to improve the Credit Score.</li>

          <li>
            Generally triggered by very high utilization level of Credit Facility or default in one or more credit
            facilities or declaration of bankruptcy.
          </li>

          <li>
            It would be very difficult (if not impossible) to get approval of any credit facility even at a very high
            interest rate or strict terms.
          </li>

          <li>
            Intense follow-up and professional help are recommended to improve the Credit Score.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <div className={styles.imgContainer}>
          <img src={creditScoreImg} className={styles.imgCnt} alt="" />
        </div>

        <PublishedDate date={'09/06/2021'} />
      </div>

      <FeedbackBox source="/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/02" />
    </>
  );
};
