import clsx from 'clsx';
import React from 'react';
import redCircle from '../common/images/red-circle.svg';
import FeedbackBox from '../components/Common/FeedbackBox';
import styles from './Pages.module.scss';
import { PublishedDate } from './PublishedDate';

export const CdmaVsGsmVsLte: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>CDMA vs GSM vs LTE</h1>
        <h1 className={styles.subHeading}>OVERVIEW</h1>
        <p className={styles.bodyText}>
          GSM and CDMA are the two radio networks used by wireless carriers. GSM allows to make voice calls and transmit
          data at the same time, while CDMA does not.
        </p>
        <p className={styles.bodyText}>
          Both CDMA and GSM are multiple-access technologies, meaning they allow multiple calls or data connections on a
          single radio channel.
        </p>
        <p className={styles.bodyText}>
          GSM transforms calls into digital data, gives them a shared channel and a time slot and puts the pieces of
          each call back together for the listener on the other end.
        </p>
        <p className={styles.bodyText}>
          CDMA encodes each call&apos;s data in a unique key. Then all calls are transmitted at once, with receivers
          “dividing” the combined signal back into individual calls.
        </p>
        <p className={styles.bodyText}>GSM and CDMA standards apply to 2G and 3G connectivity.</p>
        <p className={styles.bodyText}>
          GSM and CDMA are two different set of technologies and cannot be used together.
        </p>

        <h2 className={clsx(styles.paraHeading)}>GSM (Global System for Mobiles)</h2>
        <p className={styles.bodyText}>
          In GSM, all the subscriber data is stored in a SIM (Subscriber Identity Module) Card which can be used in any
          phone having Sim Card provision (non-CDMA phones). All the subscriber data such as personal details, data
          plans etc. is contained in this Sim Card. If a subscriber wants to change the phone, she simply needs to take
          out the Sim Card from old phone and use it in the new phone.{' '}
        </p>
        <p className={styles.bodyText}>Advantages of GSM </p>
        <ul className={styles.list}>
          <li>Can easily switch between different handsets or network carriers</li>
          <li>Can simultaneously use voice and data</li>
          <li>Accepted in 200+ countries, more suited for international roaming</li>
        </ul>
        <p className={styles.bodyText}>Disadvantages of GSM</p>
        <ul className={styles.list}>
          <li>Comparatively less efficient use of spectrum</li>
          <li>High chances of interference</li>
          <li>Lower data security</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>CDMA (Code Division Multiple Access)</h2>
        <p className={styles.bodyText}>
          CDMA is a proprietary technology developed by Qualcomm. It is a handset based technology where all the
          subscriber data is in-built in the phone. Each handset is identified with a serial number. A subscriber with a
          CDMA network carrier cannot switch to another carrier with the same handset. In order to switch the network
          carrier, the subscriber will have to change the handset.
        </p>
        <p className={styles.bodyText}>Advantages of CDMA</p>
        <ul className={styles.list}>
          <li>High data security</li>
          <li>Low chances of interference</li>
          <li>Efficient use of spectrum</li>
        </ul>
        <p className={styles.bodyText}>Disadvantages of CDMA</p>
        <ul className={styles.list}>
          <li>Handset specific</li>
          <li>May not be compatible in other countries following GSM (due to different frequency band)</li>
          <li>Cannot use data and voice together</li>
          <li>Very low adoption worldwide</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>LTE (Long Term Evolution)</h2>
        <p className={styles.bodyText}>
          Both the above two technologies, CDMA and GSM, were used in 2G and 3G standards
        </p>
        <p className={styles.bodyText}>
          For 4G standards, LTE is the most prevalent technology. LTE has global acceptance and is being used worldwide.
          LTE is quite advanced compared to GSM and CDMA in terms of efficient spectrum usage and frequency modulation
          resulting in higher throughput, better speeds and supporting more devices.
        </p>
        <p className={styles.bodyText}>
          With increasing adoption of LTE the debate between GSM and CDMA is becoming irrelevant. Now all the network
          carriers are moving towards 4G and 5G standards embracing LTE technology.
        </p>
        <p className={styles.bodyText}>
          Now GSM/CDMA is only being used by the network carriers as a fallback option in regions where 4G and 5G
          coverage is weak.
        </p>

        <h2 className={clsx(styles.paraHeading)}>CANADIAN LANDSACPE</h2>
        <p className={styles.bodyText}>
          In Canada, both Telus and Bell were on CDMA network until recently. Telus discontinued CDMA since May 31, 2017
          and Bell discontinued CDMA since April 30, 2019 and both switched to 4G LTE.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Presently all major network carriers are on GSM and 4G LTE network. Rogers, Bell and Telus also have 5G
          coverage in some regions.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'11/25/2021'} />
      </div>
      <FeedbackBox source="/bills/managingbills/phone/text/short/25-nov-2021/01" />
    </>
  );
};
