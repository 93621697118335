import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { GoalItems } from './types';

export const getMilestonesByGoalId = (goalId: number) => (goals: GoalItems) => {
  const foundGoal = goals.find((goalItem) => {
    return goalItem.id === goalId;
  });

  if (!foundGoal) {
    return [];
  }

  return foundGoal.milestones;
};

export const MilestoneLimitWarning = ({
  goalList,
  onMilestoneLimitReached,
}: {
  goalList: GoalItems;
  onMilestoneLimitReached?: () => void;
}) => {
  const { watch } = useFormContext();
  const selectedGoalId = watch('goal', 0);

  const [maximumNumberOfMilestonesReached, setMaximumNumberOfMilestonesReached] = useState(false);

  useEffect(() => {
    const numberOfAlreadyExistingMilestones = getMilestonesByGoalId(+selectedGoalId)(goalList).length;

    if (numberOfAlreadyExistingMilestones >= 5) {
      setMaximumNumberOfMilestonesReached(true);
    }
  }, [goalList, selectedGoalId]);

  if (maximumNumberOfMilestonesReached) {
    onMilestoneLimitReached && onMilestoneLimitReached();
  }

  return null;
};
