import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { IncomeFormInputs } from './types';
import { DatePicker, Form, Input, SubmitButton } from '../../components/Form';

const schemaResolver = yupResolver<IncomeFormInputs>(
  yup.object().shape({
    date: yup.string().required('please select date'),
    description: yup.string().required('please enter description'),
    amount: yup.number().typeError('please enter a number').required().positive(),
  }),
);

export type IncomeFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<IncomeFormInputs>>;
  onSubmit: SubmitHandler<IncomeFormInputs>;
};

export const IncomeForm: React.FC<IncomeFormProps> = ({ defaultValues = {}, onSubmit }) => {
  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <DatePicker name="date" placeholder="Date" />
        <Input name="description" placeholder="Description" label="Description" />
        <Input name="amount" placeholder="Amount" label="Amount" prefix="$" />
        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
