import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AccountAccess: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Account Access</h1>

        <p className={styles.bodyText}>
          Arctic Rich Account belongs to you and it is only for your personal use. Please keep the following in mind to
          safeguard the confidentiality of your account.
        </p>

        <ol className={styles.list}>
          <li>Maintain control over your accounts at all times.</li>
          <li>Don&apos;t reveal or share your Password with anyone.</li>
          <li>Don&apos;t choose a Password you are currently using elsewhere.</li>
          <li>Don&apos;t share the details of the Payment Methods of this account to anyone.</li>
          <li>Keep all the information held in your Arctic Rich Account confidential.</li>
          <li>Don&apos;t use anyone else&apos;s ID or Password to access their Arctic Rich Account.</li>
        </ol>

        <p className={styles.bodyText}>These are part of your Terms of Use for this account.</p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Please click here to review the full details of your obligations and responsibilities for accessing Arctic
          Rich Account.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'09/19/2021'} />
      </div>

      <FeedbackBox source="/user-account/managing-account/text/short/19-sep-2021/01" />
    </>
  );
};
