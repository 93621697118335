import React, { useState } from 'react';
import { Row, Col, Form, Spinner } from 'react-bootstrap';

import { doPostRequest, BadRequestError } from '../../helpers/api';

import HelpBox from './HelpBox';
import styles from './FeedbackBox.module.scss';

interface CommentRequestBody {
  message: string;
  page: string;
  mark: string;
  section?: string;
}

const createComment = (commentRequestBody: CommentRequestBody) => {
  return doPostRequest('comments/', commentRequestBody);
};

interface FeedbackBoxProps {
  source: string;
  section?: string;
}

const FeedbackBox = ({ source, section }: FeedbackBoxProps) => {
  const [showHelpFul, setShowHelpFul] = useState<boolean>(true);
  const [isHelpFul, setIsHelpFul] = useState<boolean | null>(null);
  const [comment, setComment] = useState<string>('');

  const onHelpfulButton = (helpful: boolean) => {
    setShowHelpFul(false);
    setIsHelpFul(helpful);
  };

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const onSubmit = () => {
    const request: CommentRequestBody = {
      message: comment,
      page: window.location.protocol + '//' + window.location.host + source,
      mark: isHelpFul ? '1' : '0',
      section: section,
    };

    setShowLoader(true);

    createComment(request)
      .then((res) => {
        setShowLoader(false);
        setError('');
        setMessageSent(true);
      })
      .catch((err) => {
        let errorMessage = '';
        if (err instanceof BadRequestError) {
          errorMessage = err.response + '';
        } else {
          errorMessage = err.message;
        }
        setShowLoader(false);
        setError(errorMessage);
        setMessageSent(false);
      });
  };

  return (
    <>
      <div className={styles.feedbackBoxContainer}>
        {showLoader ? (
          <div className={styles.feedbackBoxLoader}>
            <Spinner animation="border" />
          </div>
        ) : null}

        {messageSent || error ? (
          <>
            <Row className="">
              <Col lg={12}>
                {messageSent ? (
                  <h5 className={styles.commentFormLabel}>Thanks for providing feedback.</h5>
                ) : (
                  <h5 className={styles.commentFormLabel + ' text-danger'}>{error}</h5>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            {showHelpFul ? (
              <Row className="align-items-center">
                <Col lg="auto">
                  <span className={styles.helpLabel}>Helpful?</span>
                </Col>
                <Col>
                  <HelpBox
                    placement="bottom"
                    content={
                      <>
                        <p className="m-0">Solved my problem</p>
                      </>
                    }
                  >
                    <button type="button" className={styles.button + ' btn'} onClick={() => onHelpfulButton(true)}>
                      Yes
                    </button>
                  </HelpBox>

                  <HelpBox
                    placement="bottom"
                    content={
                      <>
                        <p className="m-0">Not helpful</p>
                      </>
                    }
                  >
                    <button type="button" className={styles.button + ' btn'} onClick={() => onHelpfulButton(false)}>
                      No
                    </button>
                  </HelpBox>
                </Col>
              </Row>
            ) : null}

            {!showHelpFul ? (
              <Row className="">
                <Col lg={12}>
                  <h5 className={styles.commentFormLabel}>Thanks for letting us know.</h5>
                  <div className={styles.textAreaContainer}>
                    <p className={styles.textAreaLabel}>
                      <span className={styles.label}>How can we make this article more helpful? (Optional)</span>
                      <span className={styles.counter}>{150 - comment.length}</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      maxLength={150}
                      rows={5}
                      className={styles.textarea}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  <h5 className={styles.commentFormLabel}>
                    Please don&apos;t include any personal Information in your comment.
                  </h5>

                  <button type="button" className={styles.submitButton + ' btn'} onClick={onSubmit}>
                    Submit
                  </button>
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default FeedbackBox;
