import { AccountPurpose } from './types';

export const accountPurposeFormatter = (
  cell: string,
  _: unknown,
  __: unknown,
  formatExtraData: Record<string, string>,
) => {
  let label = '';
  switch (cell) {
    case AccountPurpose.INVESTMENT_EF: {
      label = 'Emergency Funds';
      break;
    }
    case AccountPurpose.INVESTMENT_STI: {
      label = 'Short Term Investments';
      break;
    }
    case AccountPurpose.INVESTMENT_LTI: {
      label = 'Long Term Investments';
      break;
    }
    default:
      break;
  }
  return label;
};
