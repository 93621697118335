import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { uploadFile } from '../../helpers/api';
import { TaxDeductibleItems } from './types';
import {
  Table,
  priceFormatter,
  editIconFormatter,
  dateFormatter,
  deleteIconFormatter,
  TableSortCaret,
  uploadIconFormatter,
} from '../../components/Table';

import styles from '../../components/Table/Table.module.scss';
import { getTaxDeductibleList, deleteTaxDeductible } from './taxSlice';

export interface TaxDeductibleTableProps {
  list: TaxDeductibleItems;
}

export const TaxDeductibleTable: React.FC<TaxDeductibleTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getTaxListAction = useCallback(() => dispatch(getTaxDeductibleList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteTaxDeductible(id);
    getTaxListAction();
  };

  const fileUpload = (row: any, f: FileList | null) => {
    if (f) {
      uploadFile(`tax-deductible-expences/${row['id']}/files/`, f[0]);
    }
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      sortCaret: TableSortCaret,
      formatter: dateFormatter('MMM dd, yyyy'),
    },
    {
      dataField: 'description',
      text: 'Expense Description',
      sort: true,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'upload',
      text: 'File',
      sort: false,
      formatter: (cell: any, row: any) => uploadIconFormatter(row, fileUpload),
      classes: styles.uploadIconColumn,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/income/tax/edit/tax-deductible/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
