import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';

import { RootState } from '../../redux/';
import { HomeItems, HomeMaintenances, HomeFormInputs, MaintenanceFormInputs } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';

export interface HomeState {
  list: HomeItems;
  listMaintenance: HomeMaintenances;
  listLoading: boolean;
}

const initialState: HomeState = {
  list: [],
  listMaintenance: [],
  listLoading: false,
};

export const getHomeList = createAsyncAction('GET_HOME_LIST', async () => {
  return await doGetRequest('home-ownerships/');
});

export const getMaintenanceList = createAsyncAction('GET_HOME_MAINTENANCE_LIST', async () => {
  return await doGetRequest('home-maintenances/');
});

export const createHome = (homeRequestBody: HomeFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...homeRequestBody,
    purchase_date: format(fromUnixTime(Date.parse(homeRequestBody.purchase_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('home-ownerships/', updatedRequestBody);
};

export const updateHome = (homeId: number, homeRequestBody: HomeFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...homeRequestBody,
    purchase_date: format(fromUnixTime(Date.parse(homeRequestBody.purchase_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('home-ownerships/' + homeId + '/', updatedRequestBody);
};

export const deleteHome = async (homeId: number) => await doDeleteRequest('home-ownerships/' + homeId + '/');
export const deleteMaintenance = async (maintenanceId: number) =>
  await doDeleteRequest('home-maintenances/' + maintenanceId + '/');

export const createMaintenance = (maintenanceRequestBody: MaintenanceFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...maintenanceRequestBody,
    target_date: format(fromUnixTime(Date.parse(maintenanceRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('home-maintenances/', updatedRequestBody);
};

export const updateMaintenance = (maintenanceId: number, maintenanceRequestBody: MaintenanceFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...maintenanceRequestBody,
    target_date: format(fromUnixTime(Date.parse(maintenanceRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('home-maintenances/' + maintenanceId + '/', updatedRequestBody);
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getHomeList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getHomeList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getHomeList.fulfilled)]: (state, action: PayloadAction<HomeItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },

    [String(getMaintenanceList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getMaintenanceList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getMaintenanceList.fulfilled)]: (state, action: PayloadAction<HomeMaintenances>) => {
      const listMaintenance = action.payload;
      return {
        ...state,
        listMaintenance,
        listLoading: false,
      };
    },
  },
});

export const selectHomeList = (state: RootState) => state.home.list;
export const selectListLoading = (state: RootState) => state.home.listLoading;
export const selectMaintenanceList = (state: RootState) => state.home.listMaintenance;

export const selectHomeById = (homeId: number) => (state: RootState) =>
  state.home.list.find((homeItem) => {
    return homeItem.id === homeId;
  });

export const selectMaintenanceByHomeId = (homeId: number) => (state: RootState) => {
  const foundHome = state.home.list.find((homeItem) => {
    return homeItem.id === homeId;
  });

  if (!foundHome) {
    return [];
  }

  return foundHome.maintenances;
};

export const selectMaintenanceById = (maintenanceId: number) => (state: RootState) =>
  state.home.listMaintenance.find((maintenanceItem) => {
    return maintenanceItem.id === maintenanceId;
  });

export default homeSlice.reducer;
