import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { LineChart } from './LineChart';

export const RealEstate: React.FC = () => {
  const lineData = [
    {
      id: 'Real Estate',
      data: [
        {
          x: '2000',
          y: 68,
        },
        {
          x: '2001',
          y: 71,
        },
        {
          x: '2002',
          y: 75,
        },
        {
          x: '2003',
          y: 82,
        },
        {
          x: '2004',
          y: 89,
        },
        {
          x: '2005',
          y: 95,
        },
        {
          x: '2006',
          y: 104,
        },
        {
          x: '2007',
          y: 117,
        },
        {
          x: '2008',
          y: 129,
        },
        {
          x: '2009',
          y: 126,
        },
        {
          x: '2010',
          y: 135,
        },
        {
          x: '2011',
          y: 140,
        },
        {
          x: '2012',
          y: 149,
        },
        {
          x: '2013',
          y: 153,
        },
        {
          x: '2014',
          y: 160,
        },
        {
          x: '2015',
          y: 169,
        },
        {
          x: '2016',
          y: 179,
        },
        {
          x: '2017',
          y: 202,
        },
        {
          x: '2018',
          y: 219,
        },
        {
          x: '2019',
          y: 224,
        },
        {
          x: '2020',
          y: 229,
        },
        {
          x: '2021',
          y: 251,
        },
        {
          x: '2022',
          y: 292,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Real Estate</h1>

        <p className={styles.bodyText}>
          Investing your savings in Real Estate is another safe and rewarding way to secure your financial future.
        </p>

        <h3 className={styles.paraHeading}>Own Your Home</h3>

        <p className={styles.bodyText}>
          First step should be to a buy and own your principal home where you live. When you fully pay off your
          mortgage, the home would be yours with no monthly payments on housing expect maintenance and taxes.
        </p>

        <ol className={styles.list}>
          <li>
            <strong>Free Up Cashflow: </strong>This single strategy of owning your principal residence can significantly
            reduce your monthly expenses, which is very helpful particularly after retirement. On average, housing costs
            constitute about 30% household costs. Freeing up that much of cash for rest of your life is a great liberty
            any one can earn with a little bit of planning and focus.
          </li>
          <li>
            <strong>Value Appreciation: </strong>The second benefit of owning your principal residence is the increase
            in value of your home over time. This is one of those time-tested and safe ways to build wealth, which you
            can enjoy during your lifetime and transfer the wealth to your next generation.
            <p className={styles.listText}>
              Return from the growth in Real Estate value could as stunning as investments in Bonds and Stocks. In last
              22 years, House Price in Canada increased by 15% per year compared to 9.5% growth in the value of S&P 500
              Index.
            </p>
          </li>
        </ol>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>House Price Index</h4>
          <h5 className={styles.chartSubHeader}>Composite 11</h5>

          <div className={styles.chartContainer}>
            <LineChart
              data={lineData}
              xLegendLabel="Year"
              yLegendLabel="House Price Index"
              yToolTipLabel="Index"
              extraProps={{
                margin: { top: 20, right: 30, bottom: 70, left: 70 },
                axisBottom: {
                  tickRotation: -45,
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  legend: 'Year',
                  legendOffset: 60,
                  legendPosition: 'middle',
                },
                legends: [],
                yFormat: ',',
              }}
            />
          </div>
        </div>

        <h3 className={styles.paraHeading}>Invest in Real Estate Properties</h3>

        <p className={styles.bodyText}>
          Investment in Real Estate Properties is another great way to build your wealth. You should only consider
          investments in Real Estates after owning your principal residence or making significant progress on that
          front.
        </p>

        <ol className={styles.list}>
          <li>
            <strong>Real Assets: </strong>Many consider investment in Real Estate or Rental Home because the asset is
            real. The value of a home may fluctuate but it will never be worthless, unlike a company&apos;s shares or
            bonds if that company goes out of business.
          </li>
          <li>
            <strong>Value Appreciation: </strong>From value appreciation perspective, Real Estate also is a better
            option if the choice of the property is wise. As detailed above, Real Estate property values in Canada
            appreciated at a much higher rate of 15% compared to the Stock Market (9.5%).
          </li>
          <li>
            <strong>Leverage: </strong>To buy a home, only a small portion of your own fund is needed. 20-25% of own
            money can buy a home. While to buy a stock or bond, 100% of own money would be needed. However, all the
            appreciated value of the property belongs to the owner.
          </li>
          <li>
            <strong>Cash Flow: </strong>Rental income is the monthly cash flow that is earned after deducting mortgage,
            taxes, insurance and maintenance. Overtime rents increase pacing with inflation and demand of the property.
            Once the mortgage is fully paid off, majority of the rents become income.
            <p className={styles.listText}>
              Finally, the rental income could also be very attractive, if the property is located at a good
              neighbourhood or location with good communications.
            </p>
          </li>
        </ol>

        <p className={styles.bodyText}>
          Overall, Real Estate is the safest and simplest way to build wealth.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/22/2022'} />
      </div>

      <FeedbackBox source="/overview/know-your-allies/text/short/22-mar-2022/02" />
    </>
  );
};
