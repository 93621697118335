import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TheLatteFactor: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>The Latte Factor</h1>

        <p className={styles.bodyText}>
          The Latte Factor was first introduced by the author David Bach. The concept portrays how some small amount of
          money spent on a trivial item like a single cup of coffee everyday can make a significant difference in our
          financial destiny.
        </p>

        <h3 className={styles.paraHeading}>What is The Latte Factor</h3>
        <p className={styles.bodyText}>
          David Bach explains if you save your everyday coffee money, let’s say $5 per day, and invest the funds at a
          10% or 11% annual rate of return for 40 years, then you end up with $1 million at the time of your retirement.
          Latte factor shows, how much you can save by making apparently negligible adjustments without compromising
          lifestyle.
        </p>

        <h3 className={styles.paraHeading}>Key Elements</h3>
        <p className={styles.bodyText}>The author focuses on three key elements as the foundation of this concept.</p>

        <p className={styles.bodyText}>
          <strong>Small: </strong>We often ignore or underestimate the power of small steps and rule out the possibly of
          making any difference by focusing on such a small expense item like coffee money. However, it can make a real
          difference.
        </p>

        <p className={styles.bodyText}>
          <strong>Habit: </strong>Power of habit has an enormous effect on our financial future. Whether we build a
          habit of buying $5 coffee every morning from the neighbourhood corner coffee shop or save it for the long-term
          investments, after while it becomes our habit, and we get used to it.
        </p>

        <p className={styles.bodyText}>
          <strong>Compounding: </strong>Once the money is saved and invested in the best possible opportunity for a long
          period of time, the magic of compounding begins to work in our favour. Due to the compounding process our
          money continues to grow exponentially without any active effort from our part.
        </p>

        <h3 className={styles.paraHeading}>What is Your Latte Factor</h3>
        <p className={styles.bodyText}>
          The coffee example here has been largely used in figurative way. The assumptions like $5 coffee of 10-11%
          annual returns have also been super simplified to make the point and for the understanding of the mass
          population.
        </p>
        <p className={styles.bodyText}>
          We should not get too hung up with the integrities of the assumptions and focus on the big picture, which has
          very strong merits.
        </p>
        <p className={styles.bodyText}>
          Not everyone of us spends $5 for latte coffee every day but we most of us some similar type of habits that
          causes significant leakage of funds every day which could by collectively more than $5 a day. Some of the most
          common items are appended below and it is time find out own Latte Factor:
        </p>

        <ol className={styles.list}>
          <li>Coffee</li>
          <li>Eating Out</li>
          <li>Lottery</li>
          <li>Extra Banking Fee</li>
          <li>Late Payment Fee</li>
          <li>Unused Utility Fee</li>
          <li>Unused Subscriptions</li>
          <li>Unnoticed Addon Recurring Fees</li>
          <li>Small Ticket Online Purchases</li>
          <li>
            Interest on Credit Card Balance
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'03/14/2021'} />
      </div>

      <FeedbackBox source="/saving/timelessprinciples/text/short/14-mar-2022/01" />
    </>
  );
};
