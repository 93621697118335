import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import infoImg from '../common/images/info.svg';
import clsx from 'clsx';
import HelpBox from '../components/Common/HelpBox';
import { PublishedDate } from './PublishedDate';

export const Plan_20_30_50: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          20 | 30 | 50 Plan
          <HelpBox
            placement="right"
            content={
              <>
                <p className="mb-1">Author:</p>
                <p className="mb-1">Elizabeth Warren</p>
                <p className="mb-0">Amelia Warren Tyagi</p>
              </>
            }
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <h2 className={clsx(styles.subHeading)}>How Much to Save</h2>

        <p className={styles.bodyText}>
          We recommend allocating your monthly take-home income (after deducting the taxes and other contributions like
          pension plan, employment insurance etc.) in the following mix:
        </p>

        <ul className={styles.list}>
          <li>
            <strong>Saving 20%:</strong> Set aside for the long-term. Example: Education fund, retirement fund.
          </li>
          <li>
            <strong>Wants 30%:</strong> This is for fun, recreation and pleasure. Example: Movie ticket, dining out,
            vacation etc.
          </li>
          <li>
            <strong>Must Haves 50%:</strong> Essentials you can’t live without. Example: Home mortgage or rent,
            insurance payments, monthly transit pass etc.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>Benefits</h2>

        <ol className={styles.list}>
          <li>
            <strong>Big Picture Focus:</strong> This is a big picture game plan. Strategically decide where your
            hard-earned dollars will be spent.
          </li>
          <li>
            <strong>Simple:</strong> There are only three categories you can spend your money. Proven to be very
            effective and easy to implement. There is no need to track every single dollar you spend.
          </li>
          <li>
            <strong>Easy Monitoring:</strong> Easy to monitor on an ongoing basis as well. Helps you to flag whenever
            there is any excess spending in one area, causing shortfall in another. Facilitates prompt action to rectify
            the situation.
          </li>
          <p className={styles.bodyText}>
            For example, if you are saving only 10% then you are overspending in your Must Haves or Wants or a
            combination of both. You can easily locate and analyze the underlying cause and fix it.
          </p>
          <li>
            <strong>Right Balance:</strong> This plan allows you to set the right balance among the basic needs,
            enjoying the present and securing the future. None of these categories can grow out of proportion.
          </li>
          <li>
            <strong>Automatic:</strong> Once you settle down with this plan, all the categories are adjusted and
            fine-tuned to fit into the individual limits, then the system goes to autopilot mode. You would not need to
            worry about your money anymore. A lot of your time and attention will be freed up as a result so that you
            focus more on your passions and have more fun.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/24/2022'} />
      </div>

      <FeedbackBox source="/saving/20-30-50-plan/text/short/24-feb-2022/01" />
    </>
  );
};
