import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const CreditScoreForRenting: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Credit Score for Renting</h1>

        <p className={styles.bodyText}>
          In Canada, most of the landlords, property managers and rental companies check the credit scores of potential
          tenants as part of their routine rental application review and due diligence process.
        </p>

        <p className={styles.bodyText}>
          In most provinces, landlords are authorized to conduct an independent credit check to assess a tenant&apos;s
          credit worthiness, i.e., if the potential tenant will pay the rent on time.
        </p>

        <p className={styles.bodyText}>
          Landlords can pull up Resident & Tenant Screening Report, which verify applicant&apos;s identity, credit
          worthiness, criminal history, rental history, employment background, and more.
        </p>

        <p className={styles.bodyText}>
          The landlords usually look for timely payment history of applicant&apos;s rents and credit payments.
        </p>

        <p className={styles.bodyText}>
          Landlords do not ask for any particular credit score for a rental application. It is, however, safe to apply
          with a credit score of 640 or above, which includes fair, good, and excellent standings. Average renting score
          in Canada is 660.
        </p>

        <p className={styles.bodyText}>
          If a tenant is late on rent payments, break lease, get evicted, or fail to pay any move-out fees, then the
          landlord reports an unpaid balance to the credit agencies, which may hurt tenant&apos;s credit score.
        </p>

        <p className={styles.bodyText}>
          Lease guarantors are common for renters with low credit scores, or limited credit history. A lease guarantor
          is someone, with a good credit rating who signs the lease with you. Asking a friend or family member to be
          your guarantor could help you secure a rental property even with a low score.
        </p>

        <p className={styles.bodyText}>
          In cases where landlord is requested to relax the required credit score criteria, the tenant may have to pay
          more advance rent than standard requirements or higher than listed rents.
        </p>

        <h4 className={styles.noteHeading}>Note</h4>
        <ul className={styles.list}>
          <li>All landlords do not check the credit report reports, particularly the individual homeowners.</li>
          <li>Paying rent from credit card may boost the credit score.</li>
          <li>
            Landlord Credit Bureau (LCB) is promising to make renting an essential part of building up the credit score.
            Upon signing up, LCB would send tenant&apos;s monthly rent payment report to Equifax. Each timely rent
            payment could improve the credit score.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Modus Operandi</h3>
        <ul className={styles.list}>
          <li>
            Landlords can apply for potential tenant&apos;s credit score immediately after receiving the rental
            application.
          </li>
          <li>Landlords would need either the date of birth or SIN to apply. It is better to provide them both.</li>
          <li>
            A tenant credit check takes only a few minutes online and a report is immediately created for the landlord
            to review.
          </li>
          <li>
            It is recommended to attach an updated credit score with the application to save landlord&apos;s time.
          </li>
          <li>
            To comfortably qualify a rental application, it is better to
            <ol className={styles.subList} style={{ listStyle: 'lower-alpha' }}>
              <li>pay multiple rents upfront,</li>
              <li>share rent payment receipts from previous landlords or</li>
              <li>
                submit reference letters from previous landlords.
                <img src={redCircle} className={styles.redCircle} alt="" />
              </li>
            </ol>
          </li>
        </ul>

        <PublishedDate date={'08/05/2022'} />
      </div>

      <FeedbackBox source="/credit-score/whats-new/text/short/05-aug-2022/02" />
    </>
  );
};
