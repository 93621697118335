import React, { useState } from 'react';
import { DisplayError } from './DisplayError';
import styles from './Dropdown.module.scss';
import { Radio } from './Radio';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { Input } from '../../components/Form';
import { useFormContext } from 'react-hook-form';

export interface DropdownProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  options: { value: string | number; label: string }[];
  errors?: Record<string, Record<string, string>>;
  wrapClassName?: string;
  control?: Control;
  register?: (ref: HTMLInputElement) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  errors,
  placeholder,
  name,
  className,
  wrapClassName,
  control,
  register,
  ...otherProps
}) => {
  const formContext = useFormContext();

  const inputFieldDefaultValue = formContext.control.defaultValuesRef.current.name;
  const showOtherInputFieldDefaultValue = formContext.control.defaultValuesRef.current.preset_name_display === 'Other';

  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(inputFieldDefaultValue);
  const [showOtherInputField, setShowOtherInputField] = useState<boolean>(showOtherInputFieldDefaultValue);

  const onClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setShowDropdownMenu(!showDropdownMenu);
  };

  return (
    <div className={styles.dropdownRow + ' ' + className}>
      <Controller
        control={control}
        name={name}
        render={({ value, onChange }) => {
          return (
            <div
              className={`${showDropdownMenu ? styles.dropdownWrapOpen : styles.dropdownWrapClose} ${
                wrapClassName ? wrapClassName : ''
              }`}
            >
              <div defaultValue="" className={styles.dropdownElement} required {...otherProps}>
                <div className={styles.placeholderWrap} onClick={onClick}>
                  {value ? value : <span className={styles.placeholder}>{placeholder}</span>}
                </div>
                {showDropdownMenu ? (
                  <div>
                    <hr className={styles.divider} />
                    <Row>
                      {options.map((option) => (
                        <Col md="6" key={option.value}>
                          <div
                            className={styles.dropdownItem}
                            onClick={(evt) => {
                              evt.stopPropagation();
                              evt.nativeEvent.stopImmediatePropagation();
                            }}
                          >
                            <Radio
                              idx={`idx-${option.value}`}
                              value={option.value}
                              checked={
                                value === option.label || (showOtherInputField === true && option.label === 'Other')
                              }
                              onChange={(evt) => {
                                if (option.value !== 'Other') {
                                  setShowDropdownMenu(false);
                                  setShowOtherInputField(false);
                                  onChange(option.label);
                                } else {
                                  setShowOtherInputField(true);
                                  setInputValue('');
                                  setShowDropdownMenu(true);
                                  onChange(inputValue);
                                }
                              }}
                            >
                              <p
                                className={
                                  value === option.label || (showOtherInputField === true && option.label === 'Other')
                                    ? styles.labelActiveColor
                                    : styles.labelInActiveColor
                                }
                              >
                                {option.label}
                              </p>
                            </Radio>
                          </div>
                        </Col>
                      ))}
                      {showOtherInputField ? (
                        <Col md="12">
                          <Input
                            name={name}
                            value={inputValue}
                            onClick={(evt) => {
                              evt.stopPropagation();
                            }}
                            onChange={(evt) => {
                              setInputValue(evt.currentTarget.value);
                              onChange(evt.currentTarget.value);
                            }}
                            placeholder="e.g. Save for Vacation"
                            errors={errors}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
      />
      {!showOtherInputField && <DisplayError errors={errors} name={name} />}
    </div>
  );
};
