import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const LearningProcess: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Learning the Process</h1>

        <p className={styles.bodyText}>
          Human dream to build a happy and prosperous life is not something new. We have cherished this goal since the
          dawn of our civilization. Indeed, many succeeded in their endeavor - leaving footsteps behind. We could follow
          these trails to achieve similar success.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Our role is to lay out the stepping stones by combining all the accumulated human knowledge with cutting edge
          technology to re-imagine the possibilities of life. We end up with so much more that comes along with wealth
          like more self-esteem and confidence, ability to pursue our passion, earn more social respect and dignity,
          enjoy peace of mind and overall better quality of life, which are perhaps more important than the money
          itself.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/23/2021'} />
      </div>

      <FeedbackBox source="/overview/text/short/23-may-2021/03" />
    </>
  );
};
