import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { selectMilestonesByGoalId } from './goalSlice';
import { Select } from '../../components/Form';

export interface PrioritySelectControlProps extends React.HTMLProps<HTMLSelectElement> {
  hasPriority: boolean;
  name: string;
}

export const MilestonePrioritySelect: React.FC<PrioritySelectControlProps> = ({ hasPriority, name, ...otherProps }) => {
  const { watch } = useFormContext();
  const selectedGoalId = watch('goal', 0);

  const activeMilestones = useSelector(selectMilestonesByGoalId(+selectedGoalId));

  let milestonePriorityOptions = [
    { value: 1, label: 'Milestone 1' },
    { value: 2, label: 'Milestone 2' },
    { value: 3, label: 'Milestone 3' },
    { value: 4, label: 'Milestone 4' },
    { value: 5, label: 'Milestone 5' },
  ];

  if (!hasPriority) {
    milestonePriorityOptions = milestonePriorityOptions.filter(
      (option) => activeMilestones.findIndex((milestone) => milestone.priority === option.value) === -1,
    );
  }

  return (
    <Select
      name={name}
      options={milestonePriorityOptions}
      placeholder="Priority"
      {...otherProps}
      disabled={!selectedGoalId}
    />
  );
};
