import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';
import stSmImg from '../common/images/st_small_7.png';

export const Page0007: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">Start Small</Typography>
      <Spacer variant="small" />
      <Typography variant="p">We do not need to move mountain all at once.</Typography>
      <Typography variant="p">
        Even a small step toward the right direction could make a great deal of difference over time compared to doing
        nothing at all. However, we could sharply improve the result, if we combine this positive effort with stop doing
        the things that was taking us in the opposite direction from our goal. Following diagram details how dramatic
        the difference in outcome could be.
      </Typography>
      <Spacer variant="small" />
      <img src={stSmImg} alt="info" width={650} />
      <Spacer variant="small" />
      <Typography variant="p">
        For example, say we would like to improve our health. If we start doing exercise every day, that is a positive
        effort and will certainly deliver results over time. Now, if we combine that with healthy diet, we would see
        even more significant improvements in much shorter time.
      </Typography>
    </Container>
  );
};
