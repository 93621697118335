import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { numberFormatter } from '../components/Table';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const PhoneCallAlternatives: React.FC = () => {
  const columns = [
    {
      dataField: 'brand',
      text: 'Brand',
    },
    {
      dataField: 'price',
      text: 'Price',
    },
    {
      dataField: 'users',
      text: 'Users',
      formatter: numberFormatter,
    },
    {
      dataField: 'launch',
      text: 'launch',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Phone Call Alternatives</h1>

        <p className={styles.bodyText}>
          Following internet-based Applications (Apps) are great alternates to the traditional phone calls - both local
          and long distance. These Apps have many more features and much better quality.
        </p>

        <SimpleTable
          keyField="brand"
          data={[
            { brand: 'WhatsApp', family: 'Facebook', price: 'Free', users: '2000', launch: '2009' },
            { brand: 'Viber', family: 'Rakuten', price: 'Free', users: '1169', launch: '2010' },
            { brand: 'Google Duo', family: 'Google', price: 'Free', users: '1000', launch: '2016' },
            { brand: 'Skype', family: 'Microsoft', price: 'Free', users: '300', launch: '2003' },
            { brand: 'Face Time', family: 'Apple', price: 'Free', users: 'Undisclosed', launch: '2010' },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={styles.bodyText}>
          Over last 18 years, this industry has matured and stabilized. Four Technology Super Powers (Apple, Facebook,
          Google and Microsoft) are into this business, making the products more user friendly and secure.
        </p>

        <p className={styles.bodyText}>
          Users are increasingly turning to these Applications for their day-to-day local and long-distance
          conversations, switching from the traditional landline and mobile phone-based calling plans.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          The traditional calling plans continue to remain expensive, while all of the above Apps are completely free as
          long as the User’s Home Internet Plan is unlimited or call is made from a Cell Phone Hotspot location.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'09/19/2021'} />
      </div>

      <FeedbackBox source="/expenses/save-on-phone-bills/text/short/19-sep-2021/01" />
    </>
  );
};
