import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const TelephoneBanking: React.FC = () => {
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'rbc',
      text: 'RBC',
    },
    {
      dataField: 'td',
      text: 'TD',
    },
    {
      dataField: 'scotia',
      text: 'Scotia',
    },
    {
      dataField: 'cibc',
      text: 'CIBC',
    },
    {
      dataField: 'bmo',
      text: 'BMO',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Telephone Banking</h1>

        <p className={styles.bodyText}>
          Telephone Banking is a easy way to do the banking from home or on the go. Some of the key activities you can
          do over phone are appended below.
        </p>

        <SimpleTable
          keyField="id"
          data={[
            {
              description: 'Phone',
              rbc: `1-800-769-2511`,
              td: `1-888-751-9000`,
              scotia: `1-800-472-6842`,
              cibc: `1-800-465-2422`,
              bmo: `1-877-225-5266`,
            },
            {
              description: 'Pay Bills',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Check Account Balance',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Transfer Funds',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Pay or Draw Loans',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Prepay Mortgage',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Apply for Credit',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Invest in RRSP',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
            {
              description: 'Forex Rates',
              rbc: 'Yes',
              td: 'Yes',
              scotia: 'Yes',
              cibc: 'Yes',
              bmo: 'Yes',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={clsx(styles.bodyText, styles.last)}>
          Some of the transactions can’t be done at the bank’s branch or online but only through Telephone Banking.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/18/2022'} />
      </div>

      <FeedbackBox source="/balance/bankaccounts/text/short/18-mar-2022/01" />
    </>
  );
};
