import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { getSavingList, selectListLoading, selectSavingItemsByYear } from './savingSlice';
import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import { SavingTable } from './SavingTable';
import styles from './SavingList.module.scss';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';

const currentYear = new Date().getFullYear();
const years = [currentYear, currentYear - 1, currentYear - 2];

export function SavingList(): JSX.Element {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const selectedSavingItems = useSelector(selectSavingItemsByYear(selectedYear));
  const listLoading = useSelector(selectListLoading);

  const dispatch = useDispatch();
  const getSavingListAction = useCallback(() => dispatch(getSavingList()), [dispatch]);

  useEffect(() => {
    getSavingListAction();
  }, [getSavingListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />

        <div className="d-flex align-items-center">
          <Typography variant="h1">Saving</Typography>
          <ModalLinkButton to="/save/saving/add" className="ml-3">
            Add
          </ModalLinkButton>
        </div>

        <Spacer variant="medium" />
        {years.map((year) => (
          <React.Fragment key={year}>
            {year !== currentYear ? <div className={styles.savingListBlockSpacing} /> : null}
            <span
              onClick={() => setSelectedYear(year)}
              className={clsx(styles.savingListBlock, { [styles.savingListBlockSelected]: selectedYear === year })}
            >
              {year}
            </span>
          </React.Fragment>
        ))}

        <SavingTable savingList={selectedSavingItems} />

        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Saving More</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/saving/categories/" />
      </Col>
    </Row>
  );
}
