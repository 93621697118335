import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

import styles from './ForgotPasswordSent.module.scss';

export const ForgotPasswordSent: React.FC = () => {
  return (
    <Container fluid className="px-0 position-relative">
      <h1 className={styles.title1}>
        Thanks!
        <br />
        Check you Email to
        <br />
        reset your Password
      </h1>
      <h3 className={styles.title2}>
        Didn&apos;t get the email?
        <br />
        Check you spam folder or
        <br />
        <Link to="/user/forgot-password" className={styles.link}>
          Resend
        </Link>
      </h3>
    </Container>
  );
};
