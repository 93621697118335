import React, { Fragment } from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';

export interface LineChartProps {
  data: Serie[];
  xLegendLabel: string;
  yLegendLabel: string;
  xToolTipLabel?: string;
  yToolTipLabel?: string;
  extraProps?: Record<string, unknown>;
}

export const LineChart: React.FC<LineChartProps> = ({
  data,
  xLegendLabel,
  yLegendLabel,
  xToolTipLabel,
  yToolTipLabel,
  extraProps,
}) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    tooltip={(e) => {
      const data = e.point.data;
      return (
        <Fragment>
          <div
            style={{
              backgroundColor: 'white',
              padding: 10,
              boxShadow: '-1px 1px 1px 1px rgba(0,0,0,0.1)',
              borderRadius: 5,
            }}
          >
            <div>
              {xToolTipLabel || xLegendLabel || 'x'}: <strong>{data.xFormatted}</strong>
            </div>
            <div style={{ marginTop: 4 }}>
              {yToolTipLabel || yLegendLabel || 'y'}: <strong>{data.yFormatted}</strong>
            </div>
          </div>
        </Fragment>
      );
    }}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: xLegendLabel,
      legendOffset: 40,
      legendPosition: 'middle',
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLegendLabel,
      legendOffset: -60,
      legendPosition: 'middle',
    }}
    pointSize={10}
    pointColor={'white'}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    theme={{
      fontSize: 16,
      axis: {
        legend: {
          text: {
            fontSize: 20,
          },
        },
      },
    }}
    colors={() => '#ff0000'}
    {...extraProps}
  />
);
