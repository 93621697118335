import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const LatePaymentsCostYou: React.FC = () => {
  const columns = [
    {
      dataField: 'provider',
      text: 'Provider',
    },
    {
      dataField: 'sector',
      text: 'Sector',
    },
    {
      dataField: 'monthlyRate',
      text: 'Monthly Rate',
    },
    {
      dataField: 'yearlyRate',
      text: 'Yearly Rate',
    },
    {
      dataField: 'oneTimeFlat',
      text: 'One Time Flat',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Late Payments Cost You</h1>

        <p className={styles.bodyText}>Late payments cost you more than it appears on the surface.</p>

        <p className={styles.bodyText}>
          Service providers generally charge late payment fees at a much higher rate than going market interest rate of
          the consumer credit facilities. In addition, these interests are charged and compounded on a monthly basis.
        </p>

        <p className={styles.bodyText}>
          When these late payment charges are converted to annual rate, the figure looks astronomically high compared to
          the alternate cost of borrowing.
        </p>

        <p className={styles.bodyText}>Some of the current late payment charges are appended:</p>

        <h2 className={styles.paraHeading}>Late Payment Charges</h2>

        <SimpleTable
          keyField="ownHeater"
          data={[
            {
              provider: 'Enbridge Gas',
              sector: 'Gas',
              monthlyRate: '5.00%',
              yearlyRate: '79.59%',
              oneTimeFlat: '-',
            },
            {
              provider: 'Bell Canada',
              sector: 'Phone',
              monthlyRate: '3.00%',
              yearlyRate: '42.58%',
              oneTimeFlat: '-',
            },
            {
              provider: 'Tellus Mobility',
              sector: 'Phone',
              monthlyRate: '3.00%',
              yearlyRate: '79.59%',
              oneTimeFlat: '-',
            },
            {
              provider: 'Rogers Communications',
              sector: 'Phone',
              monthlyRate: '3.00%',
              yearlyRate: '79.59%',
              oneTimeFlat: '-',
            },
            {
              provider: 'Revenue Canada',
              sector: 'Government',
              monthlyRate: '2.00%',
              yearlyRate: '36.82%',
              oneTimeFlat: '10.00%',
            },
            {
              provider: 'City of Toronto',
              sector: 'Government',
              monthlyRate: '1.25%',
              yearlyRate: '17.33%',
              oneTimeFlat: '1.25%',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={styles.bodyText}>
          Consumers look at the late payments made in dollar terms instead of percentages. This blurs the magnitude of
          the true cost and it’s actual impact on overall financial position.
        </p>

        <p className={styles.bodyText}>
          Most of the consumers are also not aware of the rates at which these late payment fees are charged. Usually,
          this information can be found in fine prints of the Terms of Service, which is a very lengthy document that
          hardly anyone cares to read.
        </p>

        <p className={styles.bodyText}>Late payments may occur for many reasons, the most common are:</p>

        <ol className={styles.list}>
          <li>Slip of mind.</li>
          <li>Not managing cashflow well.</li>
          <li>Unplanned and impulse buying, usually after paydays.</li>
          <li>Total committed expenses are more than the income.</li>
        </ol>

        <p className={styles.bodyText}>
          All these factors can be managed with little bit of knowledge, planning and homework. It is very important to
          bring late payment charges to absolute zero by using all the resources available at Arctic Rich.
        </p>

        <h4 className={styles.noteHeading}>Note</h4>

        <p className={clsx(styles.bodyText, styles.last)}>
          In this section, only the monetary losses have been covered. Other implications of late payment of bills have
          been separately discussed.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/03/2022'} />
      </div>

      <FeedbackBox source="/expenses/bills/bill-payments/text/short/03-jun-2022/01" />
    </>
  );
};
