import React from 'react';
import styles from './Pages.module.scss';

import redCircle from '../common/images/red-circle.svg';
import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';

export const TireTreadDepth: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tire Tread Depth</h1>

        <ul className={styles.list}>
          <li>
            Tread is the rubber on the tire which touches the road. New tires generally have an average tread depth of 8
            to 9 millimetres or 10/32 to 11/32 inches.
          </li>

          <li>
            Minimum legal requirement for tire tread depth in Canada is 2/32” or 1.6mm. Tires must be replaced when
            tread is 1.5 mm or less.
          </li>

          <li>
            Tire performance in rain and snow reduces well before the tread depth reaches the minimum legal requirement
            of 1.6 mm. Most tire manufacturers recommend to replace tires much before this legal limit to ensure the
            vehicle can stop better particularly in a the wet-weather and low visibility conditions.
          </li>

          <li>
            When the tread on tires becomes too worn and the tread grooves become too shallow, a tire’s ability to
            efficiently evacuate water significantly diminishes. This would significantly increase the distance a
            vehicle takes to stop.
          </li>

          <li>
            Considering Canadian weather Tread Depth of 3.2 mm or 4/32nds of an inch would be the perfect time to
            replace a tire. This is when about two-thirds of the new tire has been worn out. Even at this tread depth,
            it would take an additional seven car length of distance to stop compared to the new tires. Fully worn tires
            (tread depth of 2/32” or 1.6mm) will take another additional seven cars of distance to stop.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'10/18/2021'} />
      </div>

      <FeedbackBox source="/auto/tire/text/short/18-oct-2021/02" />
    </>
  );
};
