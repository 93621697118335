import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HowMilestonesHelpToAccomplishGoals: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>How Milestones Help to Accomplish Goals</h1>

        <p className={styles.bodyText}>
          Setting goal is a very powerful tool. Goals enable us to achieve more than we are otherwise capable of.
          However, effectiveness of goal setting depends on many of the other associated tools like detailed planning,
          preparation and organizing. This includes breaking the goal down into smaller and measurable tasks, commonly
          known as milestones.
        </p>

        <p className={styles.bodyText}>
          Goals are generally big tasks and take considerable amount of time to achieve. At the beginning, large goals
          or their long time horizons may be overwhelming for individuals - making it difficult to even to start.
          Particularly, if the individuals are initiating the process on their own. That’s why very few people undertake
          bigger goals and even fewer succeed in achieving these goals.
        </p>

        <p className={styles.bodyText}>
          Following section details how carefully designed milestones can help us achieve bigger goals, which may appear
          seemingly impossible based our apparent capabilities and credentials.
        </p>

        <h3 className={styles.paraHeading}>1. Motivation</h3>

        <p className={styles.bodyText}>
          Achieving something however little and making progress however small inspire us as humans. This sense of
          progress energies us do more and continue our journey although the finish line could be far from our sight.
        </p>

        <p className={styles.bodyText}>
          When we breakdown a large goal into smaller tasks and successfully achieve and celebrate these milestones one
          at a time with all our focus, we continue to keep ourselves motivated to stay on course.
        </p>

        <h3 className={styles.paraHeading}>2. Focus</h3>

        <p className={styles.bodyText}>
          Milestone clearly stipulates the task in hand. Tells what needs to be done immediately. That takes away many
          ambiguities like where to start or there are too many things to do at the same time.
        </p>

        <p className={styles.bodyText}>
          Next milestones are not due until the current one is complete. This specificity helps individuals to focus
          relentlessly on the current task, stay on track and filter out all the other noises and distractions.
        </p>

        <h3 className={styles.paraHeading}>3. Avoid Overwhelm</h3>

        <p className={styles.bodyText}>
          It is quite usual to be overwhelmed when an individual undertakes a large goal. Since these milestones are
          smaller tasks with shorter deadlines, it is helpful to take eyes off the bigger goals and solely concentrate
          on the more doable part which is the milestone in hand. Successfully completing these little milestones also
          helps to build self-confidence.
        </p>

        <h3 className={styles.paraHeading}>4. Tracks Progress</h3>

        <p className={styles.bodyText}>
          Achieving each milestone is a progress. Each milestone is time bound and all the milestones add up to the
          timeline of the main goal. Looking at the previous milestone’s completions and timings, it is very easy to
          calculate whether the progress of the overall goal is on track, ahead or behind the schedule.
        </p>

        <p className={styles.bodyText}>
          Based on these findings, necessary adjustments in course of action can be made in the midway as well. Once
          again, a sense of progress is the single most motivator that energizes us to remain one course.
        </p>

        <h3 className={styles.paraHeading}>5. Accountability</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          Every milestone is a standalone task, whose success can be measured at the end of the task. How effectively
          the milestone was achieved and at what cost. These details would lead to accountability of the individual
          responsible. Ability of identify individual’s responsibility also brings in accountability, which eventually
          leads to higher dedication and commitment from the individual for successes at the milestone level.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/29/2022'} />
      </div>

      <FeedbackBox source="/goal/basics/text/short/29-jun-2022/03" />
    </>
  );
};
