import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const AirFilters: React.FC = () => {
  const columns = [
    {
      dataField: 'frequency',
      text: 'Frequency',
    },
    {
      dataField: 'outcome',
      text: 'Outcome',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Air Filters</h1>

        <h2 className={styles.subHeading}>Clean Air Filters Save Energy</h2>

        <p className={styles.bodyText}>
          Seemingly simple task of changing the air filter of your furnace regularly can save energy, money and
          environment.
        </p>

        <h2 className={styles.paraHeading}>1. Save Energy</h2>

        <p className={styles.bodyText}>
          Department of Energy estimates a clogged filter uses 15% more energy. This is a big savings on the energy
          bills. Considering HVAC (Heating, Ventilation and Air Conditioning Systems) is responsible for about half your
          energy bill.
        </p>

        <p className={styles.bodyText}>
          Overall, you can save 7.5% of your total energy bill every month. In 2019, the average Ontario household spent
          $2,128 on home energy. You can save on average $160 per year on your energy bill.
        </p>

        <h2 className={styles.paraHeading}>2. Longer Furnace Life</h2>

        <p className={styles.bodyText}>
          Clean filters allow the air to pass through the vents more efficiently and allow the furnace to function
          properly. A clogged air filter which is full of dirt causes the heating and cooling system to run longer in
          order to keep the temperature constant.
        </p>

        <p className={styles.bodyText}>
          Ongoing furnace servicing and maintenance cost would also be significantly less due to regular replacement of
          air filters.
        </p>

        <h2 className={styles.paraHeading}>3. Better Comfort</h2>

        <p className={styles.bodyText}>
          A clean furnace filter keeps the air inside home fresh and particularly helps people with respiratory
          problems.
        </p>

        <h2 className={styles.paraHeading}>Frequency of Replacement</h2>

        <p className={styles.bodyText}>
          The recommended frequency of filter replacement may vary from 1 to 3 months. Outcomes are generally as
          follows:
        </p>

        <SimpleTable
          keyField="ownHeater"
          data={[
            {
              frequency: 'One Month',
              outcome: 'Best',
            },
            {
              frequency: 'Two Months',
              outcome: 'Better',
            },
            {
              frequency: 'Three Months',
              outcome: 'Good',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={clsx(styles.bodyText, styles.last)}>
          Check your owner’s manual or manufacturer’s guidance for details.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/29/2022'} />
      </div>

      <FeedbackBox source="/expenses/bills/utility-bills/text/short/29-may-2022/03" />
    </>
  );
};
