import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const TopFiveLifeInsurance: React.FC = () => {
  const columns = [
    {
      dataField: 'rank',
      text: 'Rank',
    },
    {
      dataField: 'company',
      text: 'Company',
    },
    {
      dataField: 'headquarters',
      text: 'Headquarters',
    },
    {
      dataField: 'revenue',
      text: 'Revenue',
    },
    {
      dataField: 'profit',
      text: 'Profit',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Top Five</h1>

        <h2 className={styles.subHeading}>Life Insurance Companies in 2020</h2>

        <p className={styles.bodyText}>Ranking of the top 5 insurance companies are appended below.</p>

        <div className={styles.chartWrapper}>
          <h5 className={styles.chartSubHeader}>Figures in Billion Dollars</h5>
          <SimpleTable
            keyField="id"
            data={[
              {
                rank: '1',
                company: 'Manulife',
                headquarters: 'Toronto',
                revenue: '$78.9',
                profit: '$5.8',
              },
              {
                rank: '2',
                company: 'Power Corp.',
                headquarters: 'Montreal',
                revenue: '48.8',
                profit: '1.1',
              },
              {
                rank: '3',
                company: 'Sunlife',
                headquarters: 'Toronto',
                revenue: '43.3',
                profit: '2.5',
              },
              {
                rank: '4',
                company: 'Desjardins',
                headquarters: 'Levis',
                revenue: '23.7',
                profit: '2.6',
              },
              {
                rank: '5',
                company: 'iA Financial Corp.',
                headquarters: 'Quebec City',
                revenue: '17.6',
                profit: '0.6',
              },
            ]}
            columns={columns}
            bootstrap4
          />
        </div>
        <div className={styles.chartSource}>Source: Financial Post</div>

        <p className={clsx(styles.bodyText, styles.last)}>
          We highly recommend to choose large and reputable insurance companies.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/18/2022'} />
      </div>

      <FeedbackBox source="/insurance/thebasics/text/short/18-mar-2022/01" />
    </>
  );
};
