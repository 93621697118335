import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import parseISO from 'date-fns/parseISO';

import { RootState } from '../../redux/';
import { CalendarItems } from './types';
import { doGetRequest } from '../../helpers/api';

export interface CalendarState {
  list: CalendarItems;
  listLoading: boolean;
}

const initialState: CalendarState = {
  list: [],
  listLoading: false,
};

export const getCalendarList = createAsyncAction('GET_CALENDAR_LIST', async () => {
  return await doGetRequest('calendar/events/');
});

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getCalendarList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getCalendarList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getCalendarList.fulfilled)]: (state, action: PayloadAction<CalendarItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

const convertCalendarItemColor = (color: string) => {
  switch (color) {
    case 'blue':
      return '#0c78d3';
    case 'green':
      return '#038239';
    case 'orange':
      return '#ff8b00';
    case 'red':
      return '#ff0000';
    case 'purple':
      return 'purple';
    default:
      return '#0c78d3';
  }
};

export const selectCalendarList = (state: RootState) => state.calendar.list;
export const selectCalendarEventsList = (state: RootState) =>
  state.calendar.list.map((calendarItem, index) => ({
    id: String(index),
    title: calendarItem.title,
    color: convertCalendarItemColor(calendarItem.color),
    start: parseISO(calendarItem.start_date),
    allDay: true,
    extendedProps: {
      details: calendarItem.details,
      color: convertCalendarItemColor(calendarItem.color),
    },
  }));
export const selectListLoading = (state: RootState) => state.calendar.listLoading;

export default calendarSlice.reducer;
