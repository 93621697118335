import React from 'react';
import clsx from 'clsx';
import styles from './Pages.module.scss';

import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import infoImg from '../common/images/info.svg';

import { BarChart } from './BarChart';
import { PublishedDate } from './PublishedDate';

export const Inflation: React.FC = () => {
  const data = [
    {
      Month: 'OCT 2020',
      Ratio: 0.7,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'NOV 2020',
      Ratio: 1,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'DEC 2020',
      Ratio: 0.7,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'JAN 2021',
      Ratio: 1,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'FEB 2021',
      Ratio: 1.1,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'MAR 2021',
      Ratio: 2.2,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'APR 2021',
      Ratio: 3.4,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'May 2021',
      Ratio: 3.6,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'JUN 2021',
      Ratio: 3.1,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'JUL 2021',
      Ratio: 3.7,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'AUG 2021',
      Ratio: 4.1,
      color: 'rgb(84,129,176)',
    },
    {
      Month: 'SPT 2021',
      Ratio: 4.4,
      color: 'rgb(84,129,176)',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Inflation: Highest in 18 Years</h1>

        <h1 className={styles.subHeading}>4.4% Inflation in September 2021</h1>

        <p className={clsx(styles.bodyText)}>
          The annual inflation rate reached 4.4% in September 2021, highest level since February 2003. Cost of housing,
          transport and food accounted for most of the increase.
        </p>

        <ul className={styles.list}>
          <li>Gasoline: 32.8%</li>
          <li>Meat: 9.5%,</li>
          <li>Food: 3.9%,</li>
          <li>Housing: 4.8%.</li>
        </ul>

        <p className={clsx(styles.bodyText, styles.last)}>
          Key contributing factors to this rapid rise of inflation are:
        </p>

        <ol className={styles.list}>
          <li>Increase in consumer demand</li>
          <li>Supply chain issues</li>
          <li>Product shortages</li>
          <li>Rising oil prices</li>
        </ol>

        <p className={clsx(styles.bodyText, styles.last)}>
          Historical inflation rate is appended in the following chart.
        </p>

        <div className={styles.chartContainer}>
          <BarChart
            data={data}
            keys={['Ratio']}
            indexBy={'Month'}
            extraProps={{
              margin: { top: 20, right: 100, bottom: 70, left: 70 },
              axisRight: {
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32,
              },
              axisBottom: {
                tickSize: 0,
                tickRotation: -45,
              },
              enableGridY: false,
              legends: [],
            }}
          />
        </div>
        <div className={styles.chartSource}>Source: Statistics Canada</div>

        <h1 className={styles.paraHeading}>
          Analysis
          <HelpBox placement="right" content={<p className="mb-0">Statistics Canada</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <ol className={styles.list}>
          <li>
            Increase in consumer prices is obviously a challenge for those with Fixed Income. Additional caution is
            recommended, at least as a temporary measure to stay within the budget. Most logical step could be to cut
            costs on nonessential and luxury items.
          </li>
          <li>
            Increasing interest rate is a very common tool used by Government to control inflation. It is still early to
            predict if and when that will happen. Any increase will mean increase in debt service cost. We should be
            mindful of the such possibility and factor that into our planning for next 3 to 6 months budgets.
          </li>
        </ol>

        <PublishedDate date={'11/25/2021'} />
      </div>

      <FeedbackBox source="/overview/text/short/25-nov-2021/01" />
    </>
  );
};
