import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const OverViewLearnValue: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Learning to Value a Dollar</h1>

        <p className={styles.bodyText}>
          Everyday, we our hard-earned money slips through our fingers as expenses. Some of these expenses are large and
          some are small. We often tend not to pay attention to the small amounts we spend like a cup of coffee or a
          bottle of water. In reality, all these amounts can quickly add up.
        </p>
        <p className={styles.bodyText}>
          The same is true with our savings or investments. If we begin and persistently work on building our wealth, it
          can quickly build up as well. We could get some additional support from the increased value of our invested
          assets and the return on investments.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Overall, our entire journey will be centered around one core philosophy - there are no such things called
          small. It worth our every effort to learn market intelligence, techniques and technology even that leads to a
          saving that apparently may appear to be very little on the surface.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'03/18/2021'} />
      </div>

      <FeedbackBox source="/overview/text/short/18-mar-2021/01" />
    </>
  );
};
