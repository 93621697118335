import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { priceFormatter } from '../components/Table';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const WeeklySaving: React.FC = () => {
  const columns = [
    {
      dataField: 'interest',
      text: 'Interest',
    },
    {
      dataField: 'fiveyear',
      text: '5 Years',
      formatter: priceFormatter,
    },
    {
      dataField: 'tenyear',
      text: '10 Years',
      formatter: priceFormatter,
    },
    {
      dataField: 'twyear',
      text: '20 Years',
      formatter: priceFormatter,
    },
    {
      dataField: 'thyear',
      text: '30 Years',
      formatter: priceFormatter,
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What $25 Weekly Saving Can Do</h1>

        <p className={styles.bodyText}>
          Taking simple and small steps persistently over a long time can make enormous difference in our lives.
        </p>
        <p className={styles.bodyText}>
          For example, if we can save only $25 a week or $100 a month, we can save a significant amount of money as
          shown in the following chart. At 6% return, we could accumulate up to $101,000 of wealth over 30 years.
          Figures in Dollars.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>It is never too late or too small to start.</p>

        <SimpleTable
          keyField="id"
          data={[
            { interest: '3%', fiveyear: '6000', tenyear: '14000', twyear: '33000', thyear: '58000' },
            { interest: '4%', fiveyear: '7000', tenyear: '15000', twyear: '37000', thyear: '70000' },
            { interest: '5%', fiveyear: '7000', tenyear: '15000', twyear: '41000', thyear: '83000' },
            { interest: '6%', fiveyear: '7000', tenyear: '16000', twyear: '49000', thyear: '101000' },
          ]}
          columns={columns}
          bootstrap4
        />

        <PublishedDate date={'06/05/2021'} />
      </div>

      <FeedbackBox source="/overview/learn/text/short/05-jun-2021/01" />
    </>
  );
};
