import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TypesOfSavingsAccounts: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Types of Savings Accounts</h1>

        <h3 className={styles.paraHeading}>Basic Savings Account</h3>

        <ul className={styles.list}>
          <li>The basic entry-level savings accounts that allow the savers to deposit their money.</li>
          <li>
            Service providers generally specify free transaction limits, applicable interest rates and average minimum
            required balances for these accounts.
          </li>
          <li>Monthly charges may also apply in some cases.</li>
        </ul>

        <h3 className={styles.paraHeading}>High Interest Savings Account</h3>

        <ul className={styles.list}>
          <li>Suitable for larger deposits with a goal to earn higher interests.</li>
          <li>Suitable for larger deposits with a goal to earn higher interests.</li>
          <li>
            These accounts offer tiered interest rates for different levels of account balances. Generally, account
            balances above $3,000 to $5,000 are entitled to the maximum interest tier.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>High Interest Savings Account</h3>

        <ul className={styles.list}>
          <li>
            Hybrid savings accounts combine the benefits of a checking and a savings account into one package deal.
          </li>
          <li>Both checking and savings accounts are linked.</li>
          <li>
            Idle surplus funds in checking accounts are automatically transferred to the savings accounts to earn
            interests.
          </li>
          <li>Funds are transferred back from savings to checking account when required.</li>
          <li>
            These accounts would also have the standard other savings account features like maximum allowed free
            transactions, interest rates, monthly charges, if any etc.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Youth and Student Banking Savings Account</h3>
        <ul className={styles.list}>
          <li>Specifically caters the younger generation aged between 18 to 25.</li>
          <li>Usually there are no monthly charges and offers better interest rates than Basic Savings Accounts.</li>
          <li>Generally, allows higher number of free transactions. Some providers offers unlimited transactions.</li>
        </ul>

        <h3 className={styles.paraHeading}>USD Savings Account</h3>
        <ul className={styles.list}>
          <li>Suitable for the individuals who want to maintain account balances in US Dollars.</li>
          <li>Usually opened by the frequent travellers and business owners, who regularly transact in US Dollars.</li>
          <li>
            USD savings account have the same basic savings accounts features such as maximum free transaction limits,
            monthly charges, interest rates etc.
          </li>
          <li>Limitation on the use of USD funds may vary depending on the provider.</li>
        </ul>

        <h4 className={styles.paraHeading}>Note</h4>

        <p className={clsx(styles.bodyText, styles.last)}>
          TFSA, RESP, and RRSP related savings accounts are discussed in the Investment Section.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/07/2022'} />
      </div>

      <FeedbackBox source="/balance/basics/text/short/7-may-2022/01" />
    </>
  );
};
