import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CreditFactorItems } from './types';
import { Table, dateFormatter, editIconFormatter, deleteIconFormatter, TableSortCaret } from '../../components/Table';
import styles from '../../components/Table/Table.module.scss';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import { getCreditScoreList, deleteCreditFactor } from './creditScoreSlice';

const ProviderHelp = () => {
  return (
    <>
      <h6 className="mb-0">
        For reference purpose, mention here the name of the Credit Agency, proving the Credit Report highlighting this
        detail.
      </h6>
    </>
  );
};

const FactorHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Add any factors that contributed to losing points in the Credit Score.</li>
        <br />
        <li>
          For example, delay in paying Bills, Loan interest or principal, over usage of Debt Facilities, etc. Learn more
          in the Knowledge Section.​
        </li>
        <br />
        <li>This should be available in the Credit Report.</li>
      </ul>
    </>
  );
};

const ActionHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention here the action steps you intend to take to cover these shortfalls.</li>
        <br />
        <li>These should be simple, measurable action steps. Learn more in the Knowledge Section.​</li>
      </ul>
    </>
  );
};

const TargetDateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Indicate a Target Date of accomplishment for each Action Step.</li>
        <br />
        <li>With the accomplishment of each Action Step, gradually the Credit Score will improve.​</li>
        <br />
        <li>Be realistic in setting Target Dates, which can be achieved with reasonable effort. </li>
      </ul>
    </>
  );
};

export interface CreditFactorTableProps {
  list: CreditFactorItems;
}

export const CreditFactorTable: React.FC<CreditFactorTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteCreditFactor(id);
    getCreditScoreListAction();
  };

  const columns = [
    {
      dataField: 'provider',
      text: 'Provider',
      sort: true,
      helpText: <ProviderHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'description',
      text: 'Factors Influencing Credit Score',
      sort: true,
      helpText: <FactorHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'action_step',
      text: 'Action Step',
      sort: true,
      helpText: <ActionHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      formatter: dateFormatter('MMM yyyy'),
      sortCaret: TableSortCaret,
    },
    {
      dataField: 'target_date',
      text: 'Target Date',
      sort: true,
      helpText: <TargetDateHelp />,
      formatter: dateFormatter('MMM yyyy'),
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/loan/credit-score/edit/credit-factor/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
