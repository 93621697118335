import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { BarChart } from './BarChart';
import HelpBox from '../components/Common/HelpBox';
import infoImg from '../common/images/info.svg';

export const TechnologyInRealEstate: React.FC = () => {
  const data = [
    {
      feature: 'Pictures',
      Percentage: 65,
    },
    {
      feature: 'Property Details',
      Percentage: 58,
    },
    {
      feature: 'Floor Plan',
      Percentage: 46,
    },
    {
      feature: 'Property Tax',
      Percentage: 45,
    },
    {
      feature: 'Virtual Tours',
      Percentage: 41,
    },
    {
      feature: 'Neighborhood',
      Percentage: 36,
    },
    {
      feature: 'Website Ease',
      Percentage: 32,
    },
    {
      feature: 'Videos',
      Percentage: 29,
    },
    {
      feature: 'Mortgage info',
      Percentage: 23,
    },
    {
      feature: 'Open House',
      Percentage: 21,
    },
  ];

  const formatValue = (value: string | number) => `${value}%`;

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Technology in Real Estate
          <HelpBox
            placement="right"
            content={
              <>
                <p className="mb-1">Source:</p>
                <p className="mb-1">Toronto Star</p>
                <p className="mb-0">Financial Post</p>
              </>
            }
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <ul className={styles.list}>
          <li>The real estate sector is digitizing rapidly.</li>
          <li>
            The internet based digital marketplaces like listing homes, applying for mortgages, paying for related
            services had been gaining popularity for some time and is now becoming the mainstream.
          </li>
          <li>The trend has benefitted the younger generations who are more comfortable with technology.</li>
          <li>
            According to an IPSOS survey, 45% Ontarians begin their homebuying search online. Only 14% contacted a real
            estate agent as a first step
          </li>
        </ul>

        <div className={styles.chartWrapper}>
          <h5 className={styles.chartHeader}>IN REAL ESTATE, PICTURES THE BIGGEST DRAW</h5>
          <h5 className={styles.chartSubHeader}>Most-valued website feature</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={data}
              keys={['Percentage']}
              indexBy={'feature'}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 90, left: 70 },
                tooltipFormat: formatValue,
                legends: [],
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
                axisLeft: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Website feature',
                  legendPosition: 'middle',
                  legendOffset: -60,
                  format: formatValue,
                },
              }}
              formatValue={formatValue}
            />
          </div>
        </div>
        <p className={styles.chartSource}>Source: IPOS SURVEY FOR OREA</p>

        <ul className={styles.list}>
          <li>
            Realtor.ca remains the most popular source, which is an official web portal of the Canadian Real Estate
            Association (CERA).
          </li>
          <li>Realtor.ca posted 516 million visits in 2021 by 111 million unique visitors.</li>
          <li>kijiji.ca and zillow.ca are preferred by the younger home buyers.</li>
          <li>
            Zolo.ca, founded in 2012, is a new Canadian digital real estate platform. Zolo currently has 750+ active
            realtors. Zolo promises faster listings, real-time market insights, and a more enjoyable digital experience.
          </li>
          <li>
            Zolo was designed to serve rapidly growing mobile leaned consumer base with a mandate to be a digital-first
            real estate company.
          </li>
          <li>
            Zolo registered 165 million visits to its websites and more than 887 million page views in 2021. Zolo has
            300,000 users every day. 40% of the visitors are between 18 to 34 years, 25% are 35 to 44 years old.
          </li>
          <li>
            Zolo team is rich with tech industry veterans from Uber, Kijiji, and Zillow, award winning journalists, and
            experienced real estate professionals.
          </li>
          <li>
            Women homebuyers are more digital friendly, consisting 53% of the visitors in Zolo.ca. It is also because
            women take the lead role in finding and choosing the suitable home for the most families.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/30/2022'} />
      </div>

      <FeedbackBox source="/home/buying-a-house/text/short/30-jun-2022/02" />
    </>
  );
};
