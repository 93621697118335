import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const TypesOfETF: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Types of ETFs</h1>

        <h2 className={clsx(styles.paraHeading)}>1. Market Index ETFs</h2>
        <p className={styles.bodyText}>
          Market Index ETFs mimic the broad market indexes like S&P 500 or S&P TSX Composite Index. Most of the ETFs
          belong to this category. These Indexes perform like the Market Indexes they follow, and do not attempt to beat
          the market. Due to their simplicity, they have one of the lowest cost structures.
        </p>

        <h2 className={clsx(styles.paraHeading)}>2. Sector and Industry ETFs</h2>
        <p className={styles.bodyText}>
          Sector ETFs mimic specific indexes of a particular Sector or Industry, like Oil, Technology, Healthcare,
          Energy, Telecom etc. Their scope is not as broad as the Market Indexes. A lot of people prefer Sector specific
          ETFs as they believe certain Sectors will perform better than the overall market.
        </p>

        <h2 className={clsx(styles.paraHeading)}>3. Bond ETFs</h2>
        <p className={styles.bodyText}>
          Bond ETFs have recently become more popular. They are also commonly known as Fixed-Income ETF (since the
          interest rate of Bond is fixed unlike Stock returns). Leading Asset Managers are beginning to offer a wide
          suite of Bond ETFs. Canadian and US Government Treasuries, Corporate Bonds, Municipal Bonds, International
          Bonds, High-Yield Bonds are few of the categories to choose from.
        </p>

        <h2 className={clsx(styles.paraHeading)}>4. Commodity ETFs</h2>
        <p className={styles.bodyText}>
          Commodity ETFs mimic the price of certain commodities like Gold, Silver, Oil, Copper, Aluminium and other
          commodities. It operates very much like the Stock Market ETFs such as Market Index ETFs or Sector ETFs.
        </p>

        <h2 className={clsx(styles.paraHeading)}>5. Foreign Market ETFs</h2>
        <p className={styles.bodyText}>
          Foreign Market ETFs are structured very similar to Market Index ETFs (S&P 500 or S&P TSX Composite Index).
          However, these are composed of shares of foreign companies instead of domestic companies. Asset Managers offer
          ETFs for different markets, such as Japan, China, India, Brazil etc., making it easy for the average people to
          diversify internationally.
        </p>

        <h2 className={clsx(styles.paraHeading)}>6. Thematic ETFs</h2>
        <p className={styles.bodyText}>
          Thematic ETFs are structured based on different investment styles or level of market capitalization. For
          example, high growth stocks of the companies with large market capitalization would be of interest to certain
          group of people since they may have higher growth potential than the overall market.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/19/2022'} />
      </div>

      <FeedbackBox source="/investment/etf/text/short/19-feb-2022/02" />
    </>
  );
};
