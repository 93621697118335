import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const EVWaitTimes: React.FC = () => {
  const columns = [
    {
      dataField: 'manufacturer',
      text: 'Manufacturer',
    },
    {
      dataField: 'model',
      text: 'Model',
    },
    {
      dataField: 'waitTime',
      text: 'Wait Time',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Electric Vehicle Wait Times</h1>

        <p className={styles.bodyText}>Estimated waiting times for new electric cars, as of June 16, 2022:</p>

        <SimpleTable
          keyField="evWaitTime"
          data={[
            {
              manufacturer: 'Tesla',
              model: 'Model S',
              waitTime: '18-24 months',
            },
            {
              manufacturer: 'Tesla',
              model: 'Model X',
              waitTime: '18-24 months',
            },
            {
              manufacturer: 'Lexus',
              model: 'UX300e',
              waitTime: '15 months +',
            },
            {
              manufacturer: 'Ford',
              model: 'Mustang Mach-E',
              waitTime: '12-18 months',
            },
            {
              manufacturer: 'Volkswagen',
              model: 'ID.3',
              waitTime: '12 months +',
            },
            {
              manufacturer: 'Volkswagen',
              model: 'ID.4',
              waitTime: '12 months +',
            },
            {
              manufacturer: 'Porsche',
              model: 'Taycan',
              waitTime: '12 months +',
            },
            {
              manufacturer: 'Audi',
              model: 'E-Tron',
              waitTime: '12 months',
            },
            {
              manufacturer: 'Hyundai',
              model: 'IONIQ 5',
              waitTime: '9-12 months',
            },
            {
              manufacturer: 'Tesla',
              model: 'Model 3',
              waitTime: '6-12 months',
            },
            {
              manufacturer: 'Jaguar',
              model: 'iPace',
              waitTime: '6-9 months',
            },
            {
              manufacturer: 'Kia',
              model: 'EV6',
              waitTime: '6-9 months',
            },
            {
              manufacturer: 'Mini',
              model: 'Electric',
              waitTime: '6 months +',
            },
            {
              manufacturer: 'Nissan',
              model: 'Leaf',
              waitTime: '6 Months+',
            },
            {
              manufacturer: 'BMW',
              model: 'i4',
              waitTime: '6 months',
            },
            {
              manufacturer: 'BMW',
              model: 'iX',
              waitTime: '6 months',
            },
            {
              manufacturer: 'Hyundai',
              model: 'IONIQ',
              waitTime: '6 months',
            },
            {
              manufacturer: 'Renault',
              model: 'Zoe',
              waitTime: '4-5 months',
            },
            {
              manufacturer: 'Fiat',
              model: '500e',
              waitTime: '4-6 months',
            },
            {
              manufacturer: 'Kia',
              model: 'Soul',
              waitTime: '3-6 months',
            },
            {
              manufacturer: 'Kia',
              model: 'Niro EV',
              waitTime: '3-6 months',
            },
            {
              manufacturer: 'Peugeot',
              model: 'e208',
              waitTime: '3-6 months',
            },
            {
              manufacturer: 'Mazda',
              model: 'MX-30',
              waitTime: '3 months',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h4 className={styles.noteHeading}>Note</h4>
        <p className={clsx(styles.bodyText, styles.last)}>
          These figures are for a specific point in time and may significantly vary from time to time (June 16, 2022).
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/12/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-II/text/short/12-jul-2022/05" />
    </>
  );
};
