import React from 'react';

import styles from './ThumbnailImage.module.scss';

interface ThumbnailImageProps {
  page_url: string;
  id: number;
  src: any;
  srcSet: string;
  alt?: string;
  isPlaceholder?: boolean;
}

const ThumbnailImage = ({ page_url, id, src, srcSet, alt, isPlaceholder }: ThumbnailImageProps) => {
  return (
    <>
      {isPlaceholder ? (
        <img key={id} src={src} srcSet={srcSet} alt={alt} className="" />
      ) : (
        <a href={page_url} className={styles.zoomHover}>
          <img key={id} src={src} srcSet={srcSet} alt={alt} className={styles.imageThumbnail} />
        </a>
      )}
    </>
  );
};

export default ThumbnailImage;
