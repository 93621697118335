import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const ProfessionalUnionDues: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Annual Professional, Union Dues</h1>

        <h1 className={clsx(styles.subHeading)}>You can claim</h1>

        <p className={styles.bodyText}>
          Following expenses related to employment, paid for you and included in your taxable income for the year:
        </p>

        <ul className={styles.list}>
          <li>Annual membership dues for trade unions or public servant associations.</li>
          <li>
            Other professional dues paid to professional boards (including advisory/parity committees or similar body)
            as per provincial or territorial law.
          </li>
        </ul>

        <p className={styles.bodyText}>
          Professional membership dues required to keep/maintain professional status by law, e.g. a person responsible
          by law to maintain liability insurance, can deduct the premium expenses on income tax return.
        </p>

        <h1 className={clsx(styles.paraHeading)}>You cannot claim</h1>
        <p className={styles.bodyText}>
          Licenses, initiation fees, cost for special assessment, pension plan charges or any other charges.
        </p>

        <p className={styles.bodyText}>
          The amount claimed/deducted is shown on T4-form, statement of remuneration in <strong>Box 44</strong>.
        </p>

        <p className={styles.bodyText}>
          You may receive a receipt or union dues paid from union but this amount{' '}
          <strong>should not be added which is already shown in T4-form</strong> as it will wrongly double the claim
          amount. However, if GST/HST is also a component included in these dues and if it has been paid then you can
          claim tax credit/rebate. CRA refunds GST/HST at a specified rate.
        </p>

        <p className={styles.bodyText}>
          If you receive GST/HST tax rebate, then this rebate should be shown as earnings on the next year’s income tax
          return.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/10/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses/text/short/10-feb-2022/04" />
    </>
  );
};
