import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const AdvantagesOfCreditUnion: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Advantages of Credit Union</h1>

        <p className={styles.bodyText}>Following are few key advantages of banking with credit unions.</p>

        <h3 className={styles.paraHeading}>1. Traditional Banking Services</h3>
        <p className={styles.bodyText}>
          Credit unions are full service financial co-operatives. Like other financial institutions, credit unions
          provide chequing and savings accounts, mortgages, business loans and investment advice. Credit unions also
          offer registered savings plans, mutual funds, and online banking.
        </p>

        <h3 className={styles.paraHeading}>2. Owned by Clients</h3>
        <p className={styles.bodyText}>
          Credit unions are owned by their clients or members.Credit union&apos;s profits are indeed their client&apos;s
          profits. All the clients or members have an equal say in how their credit union operates and can vote for
          their board of directors through a democratic process.
        </p>

        <h3 className={styles.paraHeading}>3. Better Pricing</h3>
        <p className={styles.bodyText}>
          Credit unions give back to their owners (members or clients), by way of better pricing. Credit unions
          generally have lower fees and interest rates than banks. Credit unions also offers higher interest rates on
          deposits compared to banks. Their not-for-profit business structure and tax-exempt status also facilitate also
          helps to offer better price.
        </p>

        <h3 className={styles.paraHeading}>4. Access to ATMs</h3>
        <p className={styles.bodyText}>
          Credit union members have access to thousands of Automatic Teller Machines (ATMs) across Canada through The
          Exchange and Acculink ATM networks. It is branded as DING-FREE ATM. This ATM network is bigger than the most
          bank&apos;s ATM networks.
        </p>

        <h3 className={styles.paraHeading}>5. Highly Regulated</h3>
        <p className={styles.bodyText}>
          Credit unions in Canada are either provincially or federally regulated. Regulators oversee the operations of
          credit unions. As financial institutions, credit unions are required to meet strict regulatory standards like
          any other financial institution.
        </p>

        <h3 className={styles.paraHeading}>6. Deposit Protection</h3>
        <p className={styles.bodyText}>
          All the Canadian provinces have regulations that protect the deposits of credit union members. The protection
          coverage is at least equal, or more than the protection coverage offered by the big banks. In some provinces
          that coverage is unlimited.
        </p>

        <h3 className={styles.paraHeading}>7. Award Winning Service</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          Canada&apos;s credit unions have ranked top among all the financial institutions in overall customer service
          excellence for last 14 years in a row. 5.8 million Canadians trust the credit unions with their banking.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'08/05/2022'} />
      </div>

      <FeedbackBox source="/balance/credit-union/text/short/05-aug-2022/02" />
    </>
  );
};
