import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { HomeForm } from './HomeForm';
import { HomeFormInputs } from './types';
import { createHome, getHomeList } from './homeSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddHome: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getIncomeListAction = useCallback(() => dispatch(getHomeList()), [dispatch]);

  const onSubmit = async (data: HomeFormInputs) => {
    try {
      await createHome(data);
      history.push('/asset/home');
      getIncomeListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Home']}>
      <HomeForm onSubmit={onSubmit} />
    </Modal>
  );
};
