import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const NewHousingGSTHSTRefunds: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>New Housing GST/HST Refunds</h1>

        <h3 className={styles.paraHeading}>Who Can Claim</h3>

        <p className={styles.bodyText}>
          You may be eligible for a new housing rebate (refund) for some of the GST/HST paid, if you are an individual
          who:
        </p>

        <ol className={styles.list}>
          <li>Purchased a new house from a Builder.</li>
          <li>Purchased a substantially renovated house from a Builder.</li>
          <li>
            Purchased a house on a leased land from a builder (provided the lease is for 20 years or gives you the
            option to buy the land).
          </li>
          <li>
            Purchased shares in a co-operative housing corporation (co-op) for the purpose of using a unit in a new or
            substantially renovated cooperative housing complex.
          </li>
          <li>
            Constructed or substantially renovated your own home or hired someone else to construct or substantially
            renovate your home, provided the value of your home after construction is less than $450,000.
            <p className={styles.listText}>
              In Ontario, a provincial maximum new housing rebate of $24,000 is available regardless of the fair market
              value. You may not qualify for the Federal Part fair market value of the house exceeds $450,000.
            </p>
          </li>
          <li>Purchased or substantially renovated mobile home.</li>
          <li>Purchased or substantially renovated floating home.</li>
        </ol>

        <h3 className={styles.noteHeading}>Note:</h3>
        <p className={styles.bodyText}>
          Business corporations and partnerships cannot claim New Housing Rebates. This incentive is for the Individuals
          only.
        </p>

        <h3 className={styles.paraHeading}>What You Can Claim</h3>
        <p className={styles.bodyText}>You claim any of the following:</p>
        <ol className={styles.list}>
          <li>
            <strong>Federal Part of the HST </strong>paid for a
            <ol className={styles.subList} style={{ listStyle: 'lower-alpha' }}>
              <li>New or substantially renovated house.</li>
              <li>Used by Individuals or their relations and</li>
              <li>Used as a primary place of residence.</li>
            </ol>
            <p className={styles.listText}>All other conditions must also be met.</p>
          </li>
          <li>
            <strong>Provincial Part of the HST</strong>
            <p className={styles.listText}>
              This may available whether or not the Federal Part of the HST is available.
            </p>
          </li>
        </ol>

        <h3 className={styles.paraHeading}>Documents to Submit</h3>
        <p className={styles.bodyText}>
          In most cases, you do not have to submit any documents with your application, except the following.
        </p>
        <ol className={styles.list}>
          <li>Invoice, if your vendor didn’t charge the GST / HST on the invoice. Photocopies are accepted.</li>
          <li>Proof of occupancy.</li>
        </ol>

        <h3 className={styles.paraHeading}>Documents to Safe Keep</h3>
        <p className={styles.bodyText}>
          You must safe keep the following documents for six years and forward to Canada Revenue Agency (CRA), if
          requested.
        </p>

        <ol className={styles.list}>
          <li>Copies of all the completed and submitted Forms.</li>
          <li>Original copies of all the supporting invoices and documents.</li>
        </ol>

        <h3 className={styles.noteHeading}>Note:</h3>
        <p className={styles.bodyText}>
          After review, CRA will return your papers. Original invoices only; estimates, photocopies or account
          statements are not accepted.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'04/08/2022'} />
      </div>

      <FeedbackBox source="/home/the-basics/text/short/08-apr-2022/02" />
    </>
  );
};
