import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { TaxDeductibleForm } from './TaxDeductibleForm';
import { TaxDeductibleFormInputs } from './types';
import { updateTaxDeductible, selectTaxDeductibleById, selectListLoading, getTaxDeductibleList } from './taxSlice';
import { BadRequestError } from '../../helpers/api';

export const EditTaxDeductible: React.FC = () => {
  let { deductibleId } = useParams();
  deductibleId = parseInt(deductibleId, 10);
  const taxDeductibleItem = useSelector(selectTaxDeductibleById(deductibleId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getDeductibleListAction = useCallback(() => dispatch(getTaxDeductibleList()), [dispatch]);

  const onSubmit = async (data: TaxDeductibleFormInputs) => {
    try {
      await updateTaxDeductible(deductibleId, data);
      history.push('/income/tax');
      getDeductibleListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Tax Deductible']}>
      <TaxDeductibleForm defaultValues={taxDeductibleItem} onSubmit={onSubmit} />
    </Modal>
  );
};
