import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { SavingForm } from './SavingForm';
import { SavingFormInputs } from './types';
import { updateSaving, getSavingList, selectListLoading, selectSavingById } from './savingSlice';
import { BadRequestError } from '../../helpers/api';

export const EditSaving: React.FC = () => {
  let { savingId } = useParams();
  savingId = parseInt(savingId, 10);
  const savingItem = useSelector(selectSavingById(savingId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getSavingListAction = useCallback(() => dispatch(getSavingList()), [dispatch]);

  const onSubmit = async (data: SavingFormInputs) => {
    try {
      await updateSaving(savingId, data);
      history.push('/save/saving');
      getSavingListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Saving']}>
      <SavingForm defaultValues={savingItem} onSubmit={onSubmit} />
    </Modal>
  );
};
