import React, { useState } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';

import styles from './formatters.module.scss';
import { Link } from 'react-router-dom';
import { Modal } from '../Modal';
import { BetterRates } from '../../types';
import HelpBox from '../Common/HelpBox';

function numberWithCommas(x: string | number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatTotalValue = (totalValue: number) => {
  let formattedValue = numberWithCommas(String(totalValue));
  if (!formattedValue.includes('.')) {
    formattedValue += '.00';
  }
  return '$' + formattedValue;
};

export const priceFormatter = (cell: string) => {
  return <div className={styles.priceColumn}>${numberWithCommas(Number(cell).toFixed(2))}</div>;
};

export const numberFormatter = (cell: string) => {
  return <div className={styles.priceColumn}>{cell}</div>;
};

export const percentageFormatter = (cell: string) => {
  return <span>{cell}%</span>;
};

export const dateFormatter = (dateFormat: string) => (cell: string) => {
  if (!isValid(parseISO(cell))) {
    return '';
  }
  return <span>{format(parseISO(cell), dateFormat)}</span>;
};

export const editIconFormatter = (cell: number, _: unknown, __: unknown, formatExtraData: Record<string, string>) => {
  return (
    <Link
      to={{
        pathname: formatExtraData.baseUrl + cell,
        state: { background: formatExtraData.locationObj },
      }}
    >
      <span className={styles.editIcon} />
    </Link>
  );
};

export const deleteIconFormatter = (deleteAction: (id: number) => void | Promise<void>) => (
  cell: number,
  row: Record<string, number>,
) => {
  const DeleteEntityModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onCancelButtonClick = () => {
      setIsOpen(false);
    };

    const onDeleteButtonClick = async () => {
      await deleteAction(row.id);
      setIsOpen(false);
    };

    return (
      <>
        <div onClick={() => setIsOpen(true)}>
          <span className={styles.deleteIcon} />
        </div>
        <Modal
          title={['Delete', 'Confirmation']}
          variant="deleteConfirmation"
          show={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className="d-flex">
            <div className={styles.deleteImage} />
            <div>
              <div className={styles.deletePhraseOne}>Are you sure you want to permanently remove this item?</div>
              <div className={styles.deletePhraseTwo}>This action can’t be undone.</div>
            </div>
          </div>
          <div className={styles.deleteButtonsContainer}>
            <button className={styles.deleteCancelButton} onClick={onCancelButtonClick}>
              Cancel
            </button>
            <button className={styles.deleteConfirmButton} onClick={onDeleteButtonClick}>
              Delete
            </button>
          </div>
        </Modal>
      </>
    );
  };

  return <DeleteEntityModal />;
};

export const uploadIconFormatter = (row?: any, onChange?: (row: any, f: FileList | null) => void) => {
  const UploadFile = () => {
    const [selectedFile, setSelectedFile] = useState<FileList | null>();

    const getSize = () => {
      return selectedFile && selectedFile.length ? selectedFile[0].size / 1048576 : 0;
    };

    const ts = new Date().getTime();

    return (
      <HelpBox
        placement="right"
        content={
          <>
            <p className="mb-0">
              {selectedFile && selectedFile.length
                ? `${selectedFile[0]['name']}, ${getSize().toFixed(2)} MB`
                : 'No File Selected'}
            </p>
          </>
        }
      >
        <div className={styles.fileUploadInput}>
          <input
            type="file"
            className={styles.fileUploadInputFile}
            onChange={(e) => {
              if (onChange) {
                onChange(row, e.target.files);
                setSelectedFile(e.target.files);
              }
            }}
            title=""
            id={`input-${ts}`}
          />
          <label htmlFor={`input-${ts}`} className={styles.uploadIconContainer}>
            <span className={styles.uploadIcon}></span>
          </label>
        </div>
      </HelpBox>
    );
  };
  return <UploadFile />;
};

const getTop5BetterRates = (betterRates: BetterRates) => {
  return betterRates
    .slice()
    .sort((a, b) => a.rate - b.rate)
    .slice(0, 5);
};

export const betterRatesModalFormatter = (betterRates: BetterRates) => {
  const BetterRatesModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <React.Fragment>
        <Link to="#" onClick={() => setIsOpen(true)}>
          <span className={styles.betterRatesIcon} />
        </Link>
        <Modal
          title={['Better Rates', 'Top']}
          variant="betterRates"
          show={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          {betterRates.length > 0 ? (
            <table className={styles.betterRatesTable}>
              <thead>
                <tr>
                  <td className={styles.betterRatesTableFirstColumn}>Institution</td>
                  <td className={styles.betterRatesTableSecondColumn}>Type</td>
                  <td>Rate</td>
                </tr>
              </thead>
              <tbody>
                {getTop5BetterRates(betterRates).map((rate) => (
                  <tr key={rate.institution}>
                    <td>
                      <span className={styles.betterRatesInstitution}>{rate.institution}</span>
                    </td>
                    <td>{rate.type}</td>
                    <td>{rate.rate} %</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            'No Better Rates available.'
          )}
        </Modal>
      </React.Fragment>
    );
  };

  return <BetterRatesModal />;
};
