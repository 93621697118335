import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';
import { PieChart } from './PieChart';
import { BarChart } from './BarChart';
import { LineChart } from './LineChart';

export const Page0011: React.FC = () => {
  const marketShare = [
    {
      id: 'Apple',
      label: 'Apple',
      value: 53,
    },
    {
      id: 'Samsung',
      label: 'Samsung',
      value: 28,
    },
    {
      id: 'Huawei',
      label: 'Huawei',
      value: 7,
    },
    {
      id: 'Google',
      label: 'Google',
      value: 3,
    },
    {
      id: 'LG',
      label: 'LG',
      value: 3,
    },
    {
      id: 'Other',
      label: 'Other',
      value: 6,
    },
  ];

  const barData = [
    {
      Month: 'JAN',
      Ratio: 1,
      color: 'rgb(232, 193, 160)',
    },
    {
      Month: 'FEB',
      Ratio: 3,
      color: 'rgb(244, 117, 96)',
    },
    {
      Month: 'MAR',
      Ratio: 2,
      color: 'rgb(241, 225, 91)',
    },
    {
      Month: 'APR',
      Ratio: 1.65,
      color: 'rgb(97, 205, 187)',
    },
    {
      Month: 'JUN',
      Ratio: 2.02,
      color: 'rgb(232, 168, 56)',
    },
    {
      Month: 'JUL',
      Ratio: 2.6,
      color: 'rgb(166, 206, 227)',
    },
    {
      Month: 'AUG',
      Ratio: 4,
      color: 'rgb(178, 223, 138)',
    },
  ];

  const extraPie = {
    fill: [
      {
        match: {
          id: 'Apple',
        },
        id: 'dots',
      },
      {
        match: {
          id: 'Samsung',
        },
        id: 'dots',
      },
    ],
    margin: { top: 30, right: 0, bottom: 30, left: 0 },
  };

  const lineData = [
    {
      id: 'Canada',
      color: 'hsl(251, 70%, 50%)',
      data: [
        {
          x: 'Plane',
          y: 289,
        },
        {
          x: 'Helicopter',
          y: 117,
        },
        {
          x: 'Boat',
          y: 216,
        },
        {
          x: 'Train',
          y: 20,
        },
        {
          x: 'Subway',
          y: 107,
        },
        {
          x: 'Bus',
          y: 169,
        },
        {
          x: 'Car',
          y: 287,
        },
        {
          x: 'Moto',
          y: 261,
        },
        {
          x: 'Bicycle',
          y: 237,
        },
      ],
    },
  ];

  return (
    <div className={styles.containerStatic}>
      <h1 className={styles.mainHeading}>Bar Chart Sample</h1>

      <div className={styles.chartWrapper}>
        <h4 className={styles.chartHeader}>Estimated Investment Value</h4>
        <h5 className={styles.chartSubHeader}>Dow Jones Industrial Average Index</h5>

        <div className={styles.chartContainer}>
          <BarChart
            data={barData}
            keys={['Ratio']}
            indexBy={'Month'}
            axisLeftLegendTitle={'Ratio'}
            extraProps={{ margin: { top: 20, right: 100, bottom: 50, left: 70 } }}
          />
        </div>
      </div>
      <p className={styles.chartSource}>Source: Equifax, Trans Union, Government of Canada</p>

      <h1 className={styles.mainHeading}>Pie Chart Sample</h1>

      <div className={styles.pieChartContainer}>
        <PieChart data={marketShare} extraProps={{ valueFormat: (value: number) => value + '%', ...extraPie }} />
      </div>
      <p className={clsx(styles.chartSource, 'text-center')}>Source: Equifax, Trans Union, Government of Canada</p>

      <h1 className={styles.mainHeading}>Line Chart Sample</h1>

      <div className={styles.chartContainer}>
        <LineChart
          data={lineData}
          xLegendLabel="Transportation"
          yLegendLabel="Count"
          extraProps={{ margin: { top: 20, right: 100, bottom: 50, left: 70 } }}
        />
      </div>
      <p className={styles.chartSource}>Source: Equifax, Trans Union, Government of Canada</p>
    </div>
  );
};
