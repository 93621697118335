import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const RRSPContributionLimit: React.FC = () => {
  const columns = [
    {
      dataField: 'title',
      text: '',
    },
    {
      dataField: '2020',
      text: '2020',
    },
    {
      dataField: '2021',
      text: '2021',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>RRSP Contribution Limit</h1>

        <p className={styles.bodyText}>RRSP Contribution Limit is 18% of your previous year’s income.</p>

        <p className={styles.bodyText}>
          However, the Contribution Limit cannot exceed the Annual RRSP Limit set by Revenue Canada for that year, which
          is $27,830 for 2021.
        </p>

        <p className={styles.bodyText}>
          If there are any unused room from the preceding year’s Total Contribution Limit, then that will be added to
          this year’s Total RRSP Contribution Limit.
        </p>

        <p className={styles.bodyText}>
          <strong>Example</strong>
        </p>

        <SimpleTable
          keyField="brand"
          data={[
            { title: '2020 Total RRSP Contribution Limit', '2020': '$20,000', '2021': '' },
            { title: '2020 Actual RRSP Contribution', '2020': '$15,000', '2021': '' },
            { title: '2020 Unused Room of Contribution Limit', '2020': '$5,000', '2021': '' },
            { title: '2021 Last Year’s Unused Room', '2020': '', '2021': '$5,000' },
            { title: '2021 Contribution Limit: Income $100,000 X 18%', '2020': '', '2021': '$18,000' },
            { title: '2021 Total RRSP Contribution Limit', '2020': '', '2021': '$23,000' },
          ]}
          columns={columns}
          bootstrap4
        />

        <h4 className={styles.noteHeading}>Note</h4>

        <p className={styles.bodyText}>
          This is the simplified version of Calculating the RRSP Contribution, Limit which is applicable to most of the
          Canadians.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          The detail version is a bit more complex and not discussed here since most of the additional items are not
          relevant to the majority of the Canadians.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/03/2021'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/3-may-2022/02" />
    </>
  );
};
