import React from 'react';

import { default as BootstrapModal, ModalProps as BootstrapModalProps } from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';

import styles from './Modal.module.scss';

export interface ModalProps extends BootstrapModalProps {
  title: [string, string];
  onClose?: () => void;
  show?: boolean;
  variant?: 'default' | 'betterRates' | 'deleteConfirmation' | 'maximumGoals';
}

export const Modal: React.FC<ModalProps> = ({
  title,
  show = true,
  onClose,
  children,
  variant = 'default',
  ...otherProps
}) => {
  const history = useHistory();

  let onCloseFn = onClose;
  if (!onClose) {
    onCloseFn = () => {
      const prevPath = (history.location.state as {
        background?: {
          pathname?: string;
        };
      })?.background?.pathname;
      prevPath ? history.replace(prevPath) : history.goBack();
    };
  }
  return (
    <BootstrapModal
      {...otherProps}
      show={show}
      onHide={onCloseFn}
      dialogClassName={`${styles.modalContent} ${styles['modalContent--' + variant]}`}
      centered
    >
      <div className={styles.modalHeader}>
        <div className={styles.modalHeaderTitleLeft}>{title[0]}</div>
        <div className={styles.modalHeaderTitleDelimiter} />
        <div className={styles.modalHeaderTitleRight}>{title[1]}</div>
        <div className={styles.modalHeaderTitleCloseIcon} onClick={onCloseFn}>
          <svg xmlns="http://www.w3.org/2000/svg" width="33.057" height="33.82" viewBox="0 0 33.057 33.82">
            <path
              id="prefix__Path_40"
              fill="#cbcbcb"
              d="M873.54 45.784a.339.339 0 0 1 0-.471l15.224-15.589a.639.639 0 1 0-.913-.893l-15.192 15.557a.336.336 0 0 1-.482 0l-15.193-15.557a.639.639 0 0 0-.913.893L871.3 45.313a.337.337 0 0 1 0 .471l-15.225 15.59a.638.638 0 0 0 .011.9.636.636 0 0 0 .9-.011l15.193-15.557a.337.337 0 0 1 .482 0l15.192 15.557a.63.63 0 0 0 .456.193.638.638 0 0 0 .457-1.085z"
              data-name="Path 40"
              transform="translate(-855.889 -28.639)"
            />
          </svg>

          {/* <img src={closeIconSvg} width={33} height={34} alt="Close" /> */}
        </div>
      </div>
      <div className={styles.modalHeaderGradient} />
      <div className={styles.modalBody}>{children}</div>
    </BootstrapModal>
  );
};
