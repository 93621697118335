import React from 'react';

import styles from './FlashMessage.module.scss';

interface FlashMessageProps {
  severity: 'normal' | 'urgent';
}

export const FlashMessage: React.FC<FlashMessageProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};
