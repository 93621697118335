import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import { BillItems } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';
import { fromUnixTime } from 'date-fns';
import { BillFormInputs } from './types';
import format from 'date-fns/format';

export interface BillState {
  list: BillItems;
  listLoading: boolean;
}

const initialState: BillState = {
  list: [],
  listLoading: false,
};

export const getBillList = createAsyncAction('GET_BILL_LIST', async () => {
  return await doGetRequest('bills/');
});

export const deleteBill = async (billId: number) => await doDeleteRequest('bills/' + billId + '/');

export const createBill = (billRequestBody: BillFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...billRequestBody,
    last_due_date: format(fromUnixTime(Date.parse(billRequestBody.last_due_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('bills/', updatedRequestBody);
};

export const updateBill = (billId: number, billRequestBody: BillFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...billRequestBody,
    last_due_date: format(fromUnixTime(Date.parse(billRequestBody.last_due_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('bills/' + billId + '/', updatedRequestBody);
};

export const getBillByToken = (token: string) => {
  const updatedRequestBody = {
    token,
  };
  return doPostRequest('bills/token_verify/', updatedRequestBody, false);
};

export const updateBillByToken = ({ token, amount }: { token: string; amount: number }) => {
  const updatedRequestBody = {
    token,
    amount,
  };
  return doPostRequest('bills/update_payment_information/', updatedRequestBody, false);
};

export const billSlice = createSlice({
  name: 'bill',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getBillList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getBillList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getBillList.fulfilled)]: (state, action: PayloadAction<BillItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

export const selectBillList = (state: RootState) => state.bill.list;
export const selectListLoading = (state: RootState) => state.bill.listLoading;
export const selectBillById = (billId: number) => (state: RootState) =>
  state.bill.list.find((billItem) => {
    return billItem.id === billId;
  });

export default billSlice.reducer;
