import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import { Question } from './types';
import { doGetRequest, doPostRequest } from '../../helpers/api';

export interface QuestionState {
  list?: Array<Question>;
  loading: boolean;
  isSaved?: boolean;
  error: string | null;
}

const initialState: QuestionState = {
  list: [],
  isSaved: false,
  loading: false,
  error: null,
};

export const getQuestions = createAsyncAction('GET_QUESTIONS', async () => {
  return await doGetRequest('questions/');
});

export const saveQuestions = createAsyncAction(
  'SAVE_QUESTIONS',
  async (questions: Array<{ answer?: string | number; inputted_answer?: string }>) => {
    return await doPostRequest(`questions/answer/`, questions);
  },
);

export const saveQuestion = createAsyncAction(
  'SAVE_QUESTION',
  async (questionId: string | number, answer: { answer?: string | number; inputted_answer?: string }) => {
    return await doPostRequest(`questions/${questionId}/answer_update/`, answer);
  },
);

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: {
    //  get
    [String(getQuestions.pending)]: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    [String(getQuestions.rejected)]: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    [String(getQuestions.fulfilled)]: (state, action: PayloadAction<Array<Question>>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        lading: false,
        error: null,
      };
    },

    [String(saveQuestions.pending)]: (state) => {
      return {
        ...state,
        isSaved: false,
      };
    },
    [String(saveQuestions.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isSaved: false,
        error: action.payload || null,
      };
    },
    [String(saveQuestions.fulfilled)]: (state) => {
      return {
        ...state,
        isSaved: true,
      };
    },

    [String(saveQuestion.pending)]: (state) => {
      return {
        ...state,
        isSaved: false,
      };
    },
    [String(saveQuestion.rejected)]: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isSaved: false,
        error: action.payload || null,
      };
    },
    [String(saveQuestion.fulfilled)]: (state) => {
      return {
        ...state,
        isSaved: true,
      };
    },
  },
});

export const selectQuestions = (state: RootState) => state.questions.list;
export const selectLoading = (state: RootState) => state.questions.loading;

export default questionsSlice.reducer;
