import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const FilingComplaints: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Filing Complaints</h1>

        <h2 className={styles.subHeading}>Financial Institutions</h2>

        <p className={styles.bodyText}>
          There are formal channels to escalate problems you experience with the Financial Institutions. For example,
          financial institutions must provide consumers with information about the fees, interest rates and
          complaint-handling procedures. If there is a genuine issue, it should be escalated to the appropriate
          authorities immediately. Before filing, ensure that:
        </p>

        <ol className={styles.list} type="a">
          <li>The supporting documents are available.</li>
          <li>Consider the solution that would be appropriate and acceptable to you.</li>
        </ol>

        <p className={styles.bodyText}>We recommend following these steps for the fastest outcome.</p>

        <h3 className={styles.paraHeading}>Step 1</h3>

        <h3 className={styles.paraHeading}>Contact your Branch or Customer Contact Center.</h3>

        <p className={styles.bodyText}>
          This gives the person, branch or institution concerned to respond to our concern. However, we must speak to
          the person in charge or with appropriate authority. If the issue remains unresolved, we should escalate it to
          the next level.
        </p>

        <h3 className={styles.paraHeading}>Step 2</h3>
        <h3 className={styles.paraHeading}>Contact the Office of the Chief Executive Office.</h3>
        <p className={styles.bodyText}>
          Office of the Chief Executive Officer of each of the Financial Institutions has the capability to handle these
          types of customer complaint issues.
        </p>

        <h3 className={styles.paraHeading}>Step 3</h3>
        <h3 className={styles.paraHeading}>Contact the Ombudsman of the Institution Concerned</h3>
        <p className={styles.bodyText}>
          Ombudsman of any institution works outside the management chain of command and directly reports to the
          company’s Board of Directors
        </p>

        <h3 className={styles.paraHeading}>Royal Bank of Canada</h3>

        <p className={styles.bodyText}>Toll Free: 1-800-769-2542</p>
        <p className={styles.bodyText}>E-mail: Ombudsman@rbc.com,</p>
        <p className={styles.bodyText}>RBC Office of the Ombudsman, Royal Bank Plaza, PO Box 1, Toronto, ON M5J 2J5</p>

        <h3 className={styles.paraHeading}>TD Bank</h3>

        <p className={styles.bodyText}>Toll Free: 1-888-361-0319</p>
        <p className={styles.bodyText}>E-mail: td.scco@td.com</p>
        <p className={styles.bodyText}>
          Senior Customer Complaint Office, PO Box 1, Toronto-Dominion Center, Toronto, ON M5K 1A2
        </p>

        <h3 className={styles.paraHeading}>Scotiabank</h3>

        <p className={styles.bodyText}>E-mail: Ombudsman@scotiabank.com,</p>
        <p className={styles.bodyText}>Scotiabank Ombudsman, 44 King Street West, Toronto, ON M5H 1H1</p>

        <h3 className={styles.paraHeading}>Bank of Montreal</h3>

        <p className={styles.bodyText}>Toll Free: 1-800-371-254</p>
        <p className={styles.bodyText}>E-mail: bmo.ombudsman@bmo.com,</p>
        <p className={styles.bodyText}>
          BMO Financial Group Ombudsman, 1 First Canadian Place P.O. Box 150 Toronto, ON M5X 1H3
        </p>

        <h3 className={styles.paraHeading}>CIBC</h3>

        <p className={styles.bodyText}>Toll Free: 1-800-308-6859</p>
        <p className={styles.bodyText}>E-mail: ombudsman@cibc.com,</p>
        <p className={styles.bodyText}>CIBC Ombudsman, P.O. Box 342, Commerce Court, Toronto, ON M5L 1G2</p>

        <h3 className={styles.paraHeading}>Step 4</h3>
        <h3 className={styles.paraHeading}>Contact the Ombudsman for Banking Services and Investments (OBSI)</h3>
        <p className={styles.bodyText}>
          An independent Ombudsman has been appointed to serve the interests of customers and Canadian Banks and
          Investment Firms.
        </p>
        <p className={styles.bodyText}>E-mail: ombudsman@obsi.ca</p>
        <p className={styles.bodyText}>Mail:</p>
        <p className={styles.bodyText}>
          Ombudsman for Banking Services and Investments <br />
          401 Bay Street, Suite 1505
          <br />
          P.O. Box 5<br />
          Toronto, ON, M5H 2Y4
        </p>

        <p className={styles.bodyText}>Telephone: 1-888-451-4519</p>

        <p className={styles.bodyText}>Website: www.obsi.ca</p>

        <h3 className={styles.paraHeading}>Step 5</h3>
        <h3 className={styles.paraHeading}>Contact the Financial Consumer Agency of Canada (FCAC)</h3>
        <p className={styles.bodyText}>
          The FCAC supervises federally regulated financial institutions to ensure they comply with federal consumer
          protection laws.
        </p>
        <p className={styles.bodyText}>Mail</p>
        <p className={styles.bodyText}>
          Financial Consumer Agency of Canada <br />
          6th Floor, Enterprise Building <br />
          427 Laurier Avenue West <br />
          Ottawa, Ontario, K1R 1B9 <br />
        </p>
        <p className={styles.bodyText}>Telephone: English 1-888-461-3222. French 1-866-461-223</p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Website: www.fcac-acfc.gc.ca
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/18/2022'} />
      </div>

      <FeedbackBox source="/balance/bankaccounts/text/short/18-mar-2022/02" />
    </>
  );
};
