import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { HomeForm } from './HomeForm';
import { HomeFormInputs } from './types';
import { BadRequestError } from '../../helpers/api';

import { updateHome, getHomeList, selectHomeById, selectListLoading } from './homeSlice';

export const EditHome: React.FC = () => {
  let { homeId } = useParams();
  homeId = parseInt(homeId, 10);
  const homeItem = useSelector(selectHomeById(homeId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getHomeListAction = useCallback(() => dispatch(getHomeList()), [dispatch]);

  const onSubmit = async (data: HomeFormInputs) => {
    try {
      await updateHome(homeId, data);
      history.push('/asset/home');
      getHomeListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Home']}>
      <HomeForm defaultValues={homeItem} onSubmit={onSubmit} />
    </Modal>
  );
};
