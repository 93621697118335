import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { doPostRequest, BadRequestError, getAccessTokenFromLS } from './helpers/api';
import { AppLocationState } from './types';

interface TrackRequestBody {
  date: string;
  time: string;
  duration: number;
  url: string;
}

const createTrack = (trackRequestBody: TrackRequestBody) => {
  return doPostRequest('timeonpage/', trackRequestBody);
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

const formatDate = (date: Date) => {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-');
};

const formatTime = (time: Date) => {
  return [padTo2Digits(time.getHours()), padTo2Digits(time.getMinutes())].join(':');
};

const getFullPath = (path: string) => {
  return window.location.protocol + '//' + window.location.host + path;
};

const routeExeceptions = [
  '/user/sign-up/account-info',
  '/user/sign-up/address-info',
  '/user/sign-up/subscription-info',
  '/user/sign-up/completed',
  '/user/login',
  '/user/forgot-password',
  '/user/forgot-password-sent',
  '/user/password-reset',
  '/user/reset-password-confirmation',
  '/user/verify-email',
];

function Track({ children }: { children: React.ReactNode }): JSX.Element {
  const location = useLocation<AppLocationState>();

  useEffect(() => {
    if (getAccessTokenFromLS()) {
      if (!routeExeceptions.includes(location.pathname) && !location.pathname.startsWith('/user/two-fa')) {
        const prevRoute = sessionStorage.getItem('prevRoute');
        if (!prevRoute) {
          const visitInfo = { path: location.pathname, time: new Date().getTime() };
          sessionStorage.setItem('prevRoute', JSON.stringify(visitInfo));
        } else {
          const prevRouteInfo = JSON.parse(prevRoute);
          if (prevRouteInfo['path'] !== location.pathname && location.pathname) {
            const lastVisitTime: Date = new Date(prevRouteInfo['time']);
            const visitTime = new Date().getTime();
            const visitInfo = { path: location.pathname, time: visitTime };
            const request = {
              date: formatDate(lastVisitTime),
              time: formatTime(lastVisitTime),
              duration: visitTime - lastVisitTime.getTime(),
              url: getFullPath(prevRouteInfo['path']),
            };

            createTrack(request)
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              .then(() => {})
              .catch((err) => {
                let errorMessage = '';
                if (err instanceof BadRequestError) {
                  errorMessage = err.response + '';
                } else {
                  errorMessage = err.message;
                }
                console.log(errorMessage);
              });

            sessionStorage.setItem('prevRoute', JSON.stringify(visitInfo));
          }
        }
      }
    }
  }, [location.pathname]);

  return <>{children}</>;
}

export default Track;
