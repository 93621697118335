import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AddVideo: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Add</h1>

        <div className={styles.videoContainer}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src="https://player.vimeo.com/video/702406431?h=728bd1f36f&color=FF0000&title=0&byline=0&portrait=0"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Add"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <p className={clsx(styles.bodyText, styles.last)}>
          Add icon and function are standard in all pages. Keep your records current by adding the new information on a
          regular basis.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'04/18/2022'} />
      </div>

      <FeedbackBox source="/overview/add/video/short/18-apr-2022/02​" />
    </>
  );
};
