import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import ThumbnailImage from '../../components/Common/ThumbnailImage';
import { doGetRequest, API_URL_PREFIX } from '../../helpers/api';
import thumbnailPlaceholderImg from '../../common/images/thumbnail-placeholder.png';

import styles from './ThumbnailGallery.module.scss';

const serverUrl = (API_URL_PREFIX || '').split('/').slice(0, 3).join('/');
const getFullThumbnailUrl = (url: string) => `${serverUrl || 'https://server1.arcticrich.com'}${url}`;

type Thumbnail = {
  id: number;
  priority?: number;
  category?: number;
  title: string;
  subtitle?: string;
  page_url: string;
  image: string;
  image_2x: string;
  image_3x: string;
  isPlaceholder?: boolean;
};

type ThumbnailGroup = {
  pk: number;
  priority: number;
  section: number;
  title: string;
  thumbnails: Thumbnail[];
};

type Thumbnails = ThumbnailGroup[];

interface ThumbnailGalleryProps {
  apiUrl: string;
}

const ThumbnailGallery = ({ apiUrl }: ThumbnailGalleryProps) => {
  const [thumbnails, setThumbnails] = useState<Thumbnails>([]);

  useEffect(() => {
    if (apiUrl) {
      doGetRequest(apiUrl)
        .then((res) => {
          setThumbnails(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [apiUrl]);

  const getThumbnails = (thumbnailGroup: ThumbnailGroup) => {
    if (thumbnailGroup.thumbnails.length < 4) {
      const thumbnails: Array<Thumbnail> = [...thumbnailGroup.thumbnails];

      for (let i = 0; i < 4 - thumbnailGroup.thumbnails.length; i++) {
        thumbnails.push({
          id: thumbnailGroup.thumbnails.length + 1,
          isPlaceholder: true,
          image: thumbnailPlaceholderImg,
          image_2x: thumbnailPlaceholderImg,
          image_3x: thumbnailPlaceholderImg,
          title: '',
          subtitle: '',
          page_url: '#',
        });
      }
      return thumbnails;
    } else {
      return thumbnailGroup.thumbnails;
    }
  };

  return (
    <>
      {thumbnails.map((thumbnailGroup) => {
        const thumbs: Array<Thumbnail> = getThumbnails(thumbnailGroup);

        return (
          <React.Fragment key={thumbnailGroup.pk}>
            <Typography variant="h2">{thumbnailGroup.title}</Typography>
            <Spacer variant="medium" />
            <Row>
              <Col>
                <Carousel
                  slidesPerPage={4}
                  arrows
                  infinite
                  addArrowClickHandler
                  arrowLeft={<div className={styles.leftArrow} />}
                  arrowRight={<div className={styles.rightArrow} />}
                  arrowLeftDisabled={<div className={styles.leftArrow} />}
                  arrowRightDisabled={<div className={styles.rightArrow} />}
                >
                  {thumbs.map((thumbnail: Thumbnail, index: number) => {
                    return (
                      <ThumbnailImage
                        key={thumbnail.id}
                        page_url={thumbnail.isPlaceholder ? '#' : thumbnail.page_url}
                        src={thumbnail.isPlaceholder ? thumbnail.image : getFullThumbnailUrl(thumbnail.image)}
                        id={index}
                        isPlaceholder={thumbnail.isPlaceholder}
                        srcSet={
                          thumbnail.isPlaceholder
                            ? ''
                            : `${getFullThumbnailUrl(thumbnail.image)} 1x, ${getFullThumbnailUrl(
                                thumbnail.image_2x,
                              )} 2x, ${getFullThumbnailUrl(thumbnail.image_3x)} 3x`
                        }
                        alt="Gallery Thumbnail"
                      />
                    );
                  })}
                </Carousel>
              </Col>
            </Row>
            <Spacer variant="medium" />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ThumbnailGallery;
