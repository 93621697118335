import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';
import FeedbackBox from '../components/Common/FeedbackBox';

export const Page0002: React.FC = () => {
  return (
    <>
      <Container fluid className="px-0">
        <Typography variant="h1">Learning the Process</Typography>
        <Spacer variant="small" />
        <Typography variant="p">
          Human dream to build a happy and prosperous life is not something new. We have cherished this goal since the
          dawn of our civilization. Indeed, many succeeded in their endeavor - leaving footsteps behind. We could follow
          these trails to achieve similar success.
        </Typography>
        <Typography variant="p">
          Our role is to lay out the steppingstones by combining all the accumulated human knowledge with cutting edge
          technology to re-imagine the possibilities of life.
        </Typography>
        <Typography variant="p">
          We end up with so much more that comes along with wealth like more self-esteem and confidence, ability to
          pursue our passion, earn more social respect and dignity, enjoy peace of mind and overall better quality of
          life, which are perhaps more important than the money itself.
        </Typography>
      </Container>

      <FeedbackBox source="/A01B1C1D2E1F1G1H01I221220J1" />
    </>
  );
};
