import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ActivevsPassiveIncome: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Active vs. Passive Income</h1>

        <h3 className={styles.paraHeading}>What is Active Income</h3>

        <p className={styles.bodyText}>
          Active income is the money earned in exchange of performing specific duties or services according to an agreed
          task, within a specified time frame.
        </p>

        <p className={styles.bodyText}>
          Examples of active income are salaries, wages, tips, fees, commissions, and allowances.
        </p>

        <h3 className={styles.paraHeading}>What is Passive Income</h3>

        <p className={styles.bodyText}>Passive income is earned automatically with minimal effort to earn.</p>

        <p className={styles.bodyText}>
          Passive income is usually earned from investment made or work completed in the past, which continues to
          generate income without any additional effort.
        </p>

        <p className={styles.bodyText}>
          The key to the financial freedom and wealth building is setting aside active income and deploying in the
          opportunities to generate passive income.
        </p>

        <h3 className={styles.paraHeading}>Examples of Passive Income</h3>

        <p className={styles.bodyText}>Some of the passive income sources are:</p>

        <ul className={styles.list}>
          <li>Peer to Peer Lending</li>
          <li>Real Estate</li>
          <li>Exchange Traded Funds (ETF)</li>
          <li>Stocks</li>
          <li>Fixed Income or Bonds</li>
          <li>Real Estate Investment Trust (REIT)</li>
          <li>Certificate of Deposits (CD)</li>
          <li>
            Private Equity (PE)
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/08/2022'} />
      </div>

      <FeedbackBox source="/income/key-terms/text/short/08-jun-2022/04" />
    </>
  );
};
