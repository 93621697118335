import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import HelpBox from '../../components/Common/HelpBox';

import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import { getHomeList, selectHomeList, selectListLoading, selectMaintenanceList, getMaintenanceList } from './homeSlice';
import { HomeTable } from './HomeTable';
import { MaintenanceTable } from './MaintenanceTable';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';

// import styles from './GoalList.module.scss';

export function HomeList(): JSX.Element {
  const dispatch = useDispatch();
  const getHomeListAction = useCallback(() => dispatch(getHomeList()), [dispatch]);
  const getHomeMaintenanceListAction = useCallback(() => dispatch(getMaintenanceList()), [dispatch]);

  const homeList = useSelector(selectHomeList);
  const listLoading = useSelector(selectListLoading);

  const selectMaintenance = useSelector(selectMaintenanceList);

  useEffect(() => {
    getHomeListAction();
    getHomeMaintenanceListAction();
  }, [getHomeListAction, getHomeMaintenanceListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />
        <div className="d-flex align-items-center">
          <Typography variant="h1">Home</Typography>

          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Add the details of each of your home here.</li>
                  <br />
                  <li>
                    Even if all the information are not readily handy, attempt should be made to populate with the best
                    information available.{' '}
                  </li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/asset/home/add/home" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <HomeTable list={homeList} />
        <Spacer variant="gray-spacer" />
        <div className="d-flex align-items-center">
          <Typography variant="h2">Home Maintenance</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Indicate the approximate current value of the property here. </li>
                  <br />
                  <li>
                    Pick a current listing of a property similar to your home in your neighborhood. Make some
                    adjustments, if necessary.
                  </li>
                  <br />
                  <li>
                    It is important to know the upcoming home maintenance items and associated costs to plan ahead.
                  </li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/asset/home/add/maintenance" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <Spacer variant="medium" />
        <MaintenanceTable list={selectMaintenance} />
        <Spacer variant="red-spacer" />

        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Home</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/home/categories/" />
      </Col>
    </Row>
  );
}
