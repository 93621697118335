import React from 'react';

import { Spacer } from '../../components/Spacer';
import { Typography } from '../../components/Typography';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';

export const OverviewGallery: React.FC = () => {
  return (
    <React.Fragment>
      <Spacer variant="red-spacer" />
      <Typography variant="h3">KNOWLEDGE</Typography>
      <Spacer variant="small" />
      <Typography variant="h1">Learn</Typography>
      <Spacer variant="large" />

      <ThumbnailGallery apiUrl="sections/overview/categories/" />
    </React.Fragment>
  );
};
