import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Loader from '../Common/Loader';
import styles from './SubmitButton.module.scss';

export type SubmitButtonLoaderProps = {
  value: string;
  className: string;
  loading: boolean;
  disabled?: boolean;
  type?: string;
};

export const SubmitButtonLoader: React.FC<SubmitButtonLoaderProps> = ({
  value,
  className,
  loading,
  disabled,
  type,
  ...otherProps
}) => {
  return (
    <Row noGutters className={styles.inputRow + ' ' + className}>
      <Col>
        <Button type="submit" {...otherProps} className={styles.inputElement} disabled={loading || disabled}>
          {loading ? (
            <>
              <Loader />
            </>
          ) : (
            <span className={styles.btnLabel}>{value}</span>
          )}
        </Button>
      </Col>
    </Row>
  );
};
