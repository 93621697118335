import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const TravellingExpense: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Deduction of Travelling Expenses</h1>

        <h2 className={clsx(styles.subHeading)}>For Salaried Employees</h2>

        <p className={styles.bodyText}>
          If you paid Travelling expenses, which are necessary to perform employment related work, you can deduct
          travelling expenses from taxable income - provided you meet all the following criteria:
        </p>

        <ol className={styles.list}>
          <li>You had to work away from the employer&apos;s place of work, or at different places.</li>
          <li>
            As per your contract of employment, you are required to pay your own travelling expenses but you cannot
            claim these expenses, if you receive reimbursement or receive non-taxable allowance for travelling expenses.
          </li>
          <p className={styles.bodyText}>
            If actual expenses are more than the non-taxable allowance, then such excess portion can be claimed.
          </p>
          <li>
            <a
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2200.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Form T2200, Declaration of Conditions of Employment
            </a>
            , must be completed by the employer in order for the employee to claim these expenses.
          </li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>What&apos;s Included</h2>
        <p className={styles.bodyText}>Travelling expenses include:</p>
        <ol className={styles.list}>
          <li>
            Lodging and transportation (such as tickets of airplane, train, bus, etc.) expenses but not the motor
            vehicle expenses.
          </li>
          <li>
            Food and Beverage expenses (if you work at least 12 consecutive hours away from your normal work location):
            <ul className={styles.subList}>
              <li>
                a) Deduction of Food and Beverage expenses is calculated by identifying 50% of the lesser of i) The
                amount actually paid and ii) Amount reasonable in the circumstances.
              </li>
              <li>
                b) Any expenses incurred on Food and Beverage on airplane, train or bus while travelling can also be
                deducted (if those expenses are not included in ticket price).
              </li>
            </ul>
          </li>
        </ol>

        <p className={styles.bodyText}>
          In order to deduct transportation expenses on Form T777, you should keep the records of the expenses and
          wherever possible the receipts and make them available to employer for approval / certification in Form T2200
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/22/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expensesII/text/short/22-feb-2022/03" />
    </>
  );
};
