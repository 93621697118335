import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { SavingForm } from './SavingForm';
import { SavingFormInputs } from './types';
import { createSaving, getSavingList } from './savingSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddSaving: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getSavingListAction = useCallback(() => dispatch(getSavingList()), [dispatch]);

  const onSubmit = async (data: SavingFormInputs) => {
    try {
      await createSaving(data);
      history.push('/save/saving');
      getSavingListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <Modal title={['Add', 'Saving']}>
      <SavingForm onSubmit={onSubmit} />
    </Modal>
  );
};
