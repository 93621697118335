import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TminusFive: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>T Minus Five</h1>

        <p className={styles.bodyText}>
          Arctic Rich highly recommends to make all the due payments of loans and bills at least 5 days before the due
          date. This is commonly called T Minus Five because, Actual Due Date is moved up from the Target Due Date by
          five calendar days.
        </p>
        <p className={styles.bodyText}>
          For example, if your credit card payment is due on May 10, then you should treat the hard deadline to be May
          05.
        </p>
        <p className={styles.bodyText}>
          This is one of the most popular and successful Rule of Thumb. It is popular because it works. We continue to
          see astonishing results achieved simply by following through this easy step.
        </p>
        <p className={styles.bodyText}>Here is why does it works so well: </p>

        <ol className={styles.list}>
          <li>
            <strong>Allow the payment to go through.</strong> Depending on the payment methods, the money may take 2-3
            business days to reach lender&apos;s or service provider&apos;s bank account. Then there are weekends,
            government holidays etc.
          </li>
          <li>
            <strong>High late payment fees: Late payment fees for utility bills are very high.</strong> They charge an
            astronomically high interest rate of 30-50% per year, sometimes in addition to a flat fee. Credit Card and
            Line of Credit issuers will increase the going interest rate, if the payment is missed multiple times
            (normally twice).
          </li>
          <li>
            <strong>Penalties are severe:</strong> There is literally no margin of error. Even a single payment delay
            will have an impact on the Credit Score, however small. It will also remain on the record for seven long
            years, which all lenders and service providers will be able to see.
          </li>
          <li>
            <strong>Reduce mental stress:</strong> Delayed payment habits and its subsequent consequences cause stress
            deep in our mind. As the due dates approach closer and we do not act on it, our inner anxiety keeps building
            on. We begin to worry about the potential consequences of the delay and default, even though we may not be
            consciously aware of it.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'10/18/2021'} />
      </div>

      <FeedbackBox source="/billpayments/text/short/18-oct-2021/03" />
    </>
  );
};
