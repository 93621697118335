import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { numberFormatter } from '../components/Table';

import SimpleInterest from '../common/images/SimpleInterest.png';
import CompoundInterestImg from '../common/images/CompoundInterest.png';
import { BarChart } from './BarChart';
import { SimpleTable } from './SimpleTable';

export const CompoundInterest: React.FC = () => {
  const columns = [
    {
      dataField: 'year',
      text: 'Year',
    },
    {
      dataField: 'investment',
      text: 'Investment Value',
      formatter: numberFormatter,
    },
    {
      dataField: 'income',
      text: 'Monthly Income During Retirement',
      formatter: numberFormatter,
    },
  ];

  const barData = [
    {
      Year: 'Year 10',
      'Investment Value': 170,
    },
    {
      Year: 'Year 20',
      'Investment Value': 497,
    },
    {
      Year: 'Year 30',
      'Investment Value': 1125,
    },
    {
      Year: 'Year 40',
      'Investment Value': 2335,
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Compound Interest</h1>

        <p className={styles.bodyText}>
          “Compound interest is the eighth wonder of the world. He who understands it, earns it … he who doesn’t … pays
          it.” - Albert Einstein
        </p>

        <p className={styles.bodyText}>
          Compound interest the single most powerful force that can work literally wonders for you. In contrast, if you
          don’t understand it well enough, it will work against you in equal force and wreak havoc in your financial
          life for ever. If there is only one thing you want to know about money, that should be the mechanics and
          aftermath of compound interest.
        </p>

        <h3 className={styles.paraHeading}>What is Compound Interest</h3>
        <p className={styles.bodyText}>
          Let’s first understand what interest is. Interest is essentially the price of money. In other words, interest
          is the price we pay for using someone else’s money.
        </p>
        <p className={styles.bodyText}>There are two types interests: a) Simple Interest and b) Compound Interest.</p>

        <h3 className={styles.paraHeading}>Simple Interest</h3>
        <p className={styles.bodyText}>
          By definition, simple interest is a method to calculate the amount of interest charged on a dollar amount at a
          set rate for a set period of time.
        </p>
        <p className={styles.bodyText}>
          If you borrow $100 for one year from your bank at the simple interest rate of 10% per year, then at end of
          that year have to pay back $110 to the bank. This $100 includes the original $100 you borrowed plus $10
          interest which the bank earned by letting you use that $100 for a year.
        </p>

        <div className={styles.imgContainer}>
          <img src={CompoundInterestImg} alt="" width="100%" />
        </div>

        <p className={styles.bodyText}>
          If you borrowed that same $100 for two years instead of one year, then you have to pay back to the bank a
          total of $120. $100 is the original money you borrowed plus $10 for first year’s interest plus $10 for the
          second year’s interest.
        </p>

        <h3 className={styles.paraHeading}>Compound Interest</h3>

        <p className={styles.bodyText}>
          Compound interest takes this thing to the next level. Compound interest also charges interest on interest in
          addition to interest on the original money borrowed. By definition, compound interest accrues on both the
          original principal and the accumulated interest from the previous periods.
        </p>

        <div className={styles.imgContainer}>
          <img src={SimpleInterest} alt="" width="100%" />
        </div>

        <p className={styles.bodyText}>
          In the same above example, where you borrowed $100 from the bank for two years, at 10% compound interest, you
          have to pay back to the bank $121 at the end of two years. $10 for first year’s interest and $10 for the
          second year’s interest on $100 original money borrowed plus $1 on interest on the first year’s interest of
          $10.
        </p>

        <h3 className={styles.paraHeading}>Application in Real Life</h3>

        <p className={styles.bodyText}>
          The compounding can have a tremendous impact in our lives, well beyond just financial domain and define who we
          are and how we live, laugh and enjoy.
        </p>
        <p className={styles.bodyText}>
          If you save $1,000 per month and invest on high quality AAA rated Corporate Bond (current long term average
          return 6.56%), then you would get the following stunning result.
        </p>

        <SimpleTable
          keyField="brand"
          data={[
            { year: 'Year 1', investment: '$12,435', income: '$41' },
            { year: 'Year 5', investment: '$71,170', income: '$237' },
            { year: 'Year 10', investment: '$169,880', income: '$566' },
            { year: 'Year 20', investment: '$496,666', income: '$1,656' },
            { year: 'Year 30', investment: '$1,125,282', income: '$3,751' },
            { year: 'Year 40', investment: '$2,334,505', income: '$7,782' },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={styles.bodyText}>
          If this doesn’t sound great then let’s put this into context. An average Canadian earned $37,710 a year or
          $3,143 per month in 2019. The $1,000 per month contribution for 40 years in the above example will generate
          $7,782 per month for rest of your life, which is two and half times of earnings of an average working Canadian
          person. With mortgage fully paid off, that could assure a not only comfortable but affluent or luxurious
          life-style during your golden years.
        </p>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>Investment Value</h4>
          <h5 className={styles.chartSubHeader}>Figures in Thousand Dollars</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={barData}
              keys={['Investment Value']}
              indexBy={'Year'}
              axisLeftLegendTitle={'Investment Value'}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 50, left: 70 },
                legends: [],
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
                padding: 0.6,
              }}
            />
          </div>
        </div>

        <h3 className={styles.paraHeading}>Your Role</h3>

        <p className={styles.bodyText}>
          Your role is to take advantage of the Compounding interest. The following are non-negotiable conditions to
          reap its true benefits. Results will be significantly diminished or entirely eliminated, if you break any of
          these codes.
        </p>

        <ul className={styles.list}>
          <li>
            <strong>Start Early: </strong>Compounding interest needs time to do its magic. You must give it time to
            grow. More time it gets, more stunning it’s wonders are. Begin the process now, if you haven’t already
            started.
          </li>
          <li>
            <strong>Be Consistent: </strong>Using the other expense planning tools we offer, decide on your monthly
            contribution amount and stick to it. Continue depositing the same amount every month. Don’t give any second
            thought. Simply stick to it. If your income increases, recalculate and reset your monthly contribution.
          </li>
          <li>
            <strong>Leave it Alone: </strong>Once you begin and set the process to auto pilot, leave it alone. Don’t
            withdraw any funds from here. Forget about it, assume it doesn’t exist. Train your mind to accept that this
            fund is not accessible in any circumstances.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/18/2022'} />
      </div>

      <FeedbackBox source="/overview/know-your-allies/text/short/18-mar-2022/01" />
    </>
  );
};
