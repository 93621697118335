import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { getIncomeList, selectListLoading, selectIncomeItemsByYear } from './incomeSlice';
import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';
import { IncomeTable } from './IncomeTable';
import styles from './IncomeList.module.scss';

const currentYear = new Date().getFullYear();
const years = [currentYear, currentYear - 1, currentYear - 2];

export function IncomeList(): JSX.Element {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const selectedIncomeItems = useSelector(selectIncomeItemsByYear(selectedYear));
  const listLoading = useSelector(selectListLoading);

  const dispatch = useDispatch();
  const getIncomeListAction = useCallback(() => dispatch(getIncomeList()), [dispatch]);

  useEffect(() => {
    getIncomeListAction();
  }, [getIncomeListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />

        <div className="d-flex align-items-center">
          <Typography variant="h1">Income</Typography>
          <ModalLinkButton to="/income/income/add" className="ml-3">
            Add
          </ModalLinkButton>
        </div>

        <Spacer variant="medium" />
        {years.map((year) => (
          <React.Fragment key={year}>
            {year !== currentYear ? <div className={styles.incomeListBlockSpacing} /> : null}
            <span
              onClick={() => setSelectedYear(year)}
              className={clsx(styles.incomeListBlock, { [styles.incomeListBlockSelected]: selectedYear === year })}
            >
              {year}
            </span>
          </React.Fragment>
        ))}

        <IncomeTable incomeList={selectedIncomeItems} />

        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Maximizing Income</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/income/categories/" />
      </Col>
    </Row>
  );
}
