import React from 'react';
import { CheckboxRound } from '../../components/Form/CheckboxRound';
import clsx from 'clsx';

import { AlertItem } from './types';
import styles from './Alerts.module.scss';
import Icons from './Icons';

interface AlertIndicatorProps {
  item?: AlertItem;
  activeKey: string | undefined | null;
  index: number;
  isOpened: boolean;
  invisible?: boolean;
  selected: boolean;
  onChangeSelect: (value: boolean) => void;
}

const AlertIndicator = ({ item, isOpened, invisible, selected, onChangeSelect }: AlertIndicatorProps) => {
  const inputId = item && item.id ? item.id : 0;

  return (
    <div className={clsx(styles.alertIndicator, 'h-100', styles.paddingY, 'pl-0', { invisible: invisible })}>
      <div className={clsx(styles.alertIndicatorContent)}>
        <CheckboxRound
          inputId={'input' + inputId}
          checked={selected}
          onChange={onChangeSelect}
          className={clsx(styles.checkBox, { [styles.checkBoxChecked]: selected })}
        />

        {item && (!item.is_read || item.is_flagged || !isOpened) ? (
          <>
            {item.is_flagged && (
              <div>
                <div
                  className={clsx(styles.flagIcon, { [styles.hasSibling]: (!item.is_read && !isOpened) || isOpened })}
                >
                  <Icons icon="flag-red" />
                </div>
              </div>
            )}
            {!item.is_read && !isOpened && (
              <div>
                <div className={clsx(styles.unRead)}></div>
              </div>
            )}
          </>
        ) : null}

        {isOpened && (
          <div>
            <div className={clsx(styles.activeAlert, 'bg-success')}></div>
          </div>
        )}
      </div>

      {/* <Row className="justify-content-around align-items-center no-gutters">
        <Col>
          <CheckboxRound
            inputId={'input' + inputId}
            checked={selected}
            onChange={onChangeSelect}
            className={clsx(styles.checkBox, { [styles.checkBoxChecked]: selected })}
          />
        </Col>

        {item && (item.is_unread || item.is_flagged || !isOpened) && (
          <Col>
            {item && item.is_unread && !isOpened && <div className={clsx(styles.unRead, 'ml-2')}></div>}
            {item && item.is_flagged && (
              <div className={clsx(styles.flagIcon, 'ml-2')}>
                <Icons icon="flag-red" />
              </div>
            )}
          </Col>
        )}

        {isOpened && (
          <Col>
            <div className={clsx(styles.activeAlert, 'bg-success', 'ml-2')}></div>
          </Col>
        )}
      </Row> */}
    </div>
  );
};

export { AlertIndicator };
