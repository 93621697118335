import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Modal } from '../../components/Modal';
import { TaxForm } from './TaxForm';
import { TaxFormInputs } from './types';
import { getTaxList, createTax } from './taxSlice';
import { BadRequestError } from '../../helpers/api';

export const AddTax: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getTaxListAction = useCallback(() => dispatch(getTaxList()), [dispatch]);

  const onSubmit = async (data: TaxFormInputs) => {
    try {
      await createTax(data);
      history.push('/income/tax');
      getTaxListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Tax']}>
      <TaxForm onSubmit={onSubmit} />
    </Modal>
  );
};
