import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const KnowledgePower: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Is Knowledge Power</h1>
        <p className={styles.bodyText}>Is knowledge truly the source of power?</p>
        <p className={styles.bodyText}>
          The truth is, knowledge is only potential power. Knowledge alone will not bring any power, money or success.
        </p>
        <p className={styles.bodyText}>
          Knowledge has to be specialized on a particular subject. It has to be organized and intelligently applied
          through realistic plans. Finally, those plans have to be followed through persistently actions.
        </p>
        <p className={styles.bodyText}>
          You will find specialised knowledge throughout this platform. Understanding of this knowledgebase would
          essential to make progress but reading alone will not get you anywhere. You have to act on it, wherever
          possible.
        </p>
        <p className={styles.bodyText}>
          We have embedded this knowledgebase into the platform as much as possible. Analysis, searches, rankings,
          triggers, filters and alerts have been automated wherever possible.
        </p>
        <p className={styles.bodyText}>
          Rest depends on you and how you would like to utilize this platform to your advantage and reach your
          destination.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'10/18/2021'} />
      </div>

      <FeedbackBox source="/overview/onedollaratatime/text/short/18-oct-2021/01" />
    </>
  );
};
