import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const NetfileServiceHours: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Service Hours - NETFILE</h1>

        <p className={styles.bodyText}>NETFILE services are available 21 hours a day, every day.</p>
        <p className={styles.bodyText}>
          The services are not available for three hours a day from 3 am to 6 am (Eastern time), due to daily scheduled
          maintenance.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          The NETFILE will remain open until Friday, January 21, 2022 for this Tax Season.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'11/08/2021'} />
      </div>

      <FeedbackBox source="/tax/electronicfiling/text/short/08-nov-2021/03" />
    </>
  );
};
