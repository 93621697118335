import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';

export const NonSufficientFund: React.FC = () => {
  const columns = [
    {
      dataField: 'bank1',
      text: 'Bank',
    },
    {
      dataField: 'fee1',
      text: 'NSF Fee',
    },
    {
      dataField: 'bank2',
      text: 'Bank',
    },
    {
      dataField: 'fee2',
      text: 'NSF Fee',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Non-Sufficient Fund (NSF)</h1>

        <h3 className={styles.paraHeading}>What is NSF</h3>

        <p className={styles.bodyText}>
          Non-Sufficient Fund (NSF) or insufficient fund in your bank account is triggered when you have ordered your
          bank to pay someone by issuing a cheque or pre-authorised payment without having enough money to pay for it in
          your account. In such cases, banks may decline to pay, return the cheque due to insufficient funds in your
          account and change you an NSF fee.
        </p>

        <h3 className={styles.paraHeading}>NSF from Merchants</h3>

        <p className={styles.bodyText}>
          In addition to your banks, the merchants or service provider whose payment was declined due to insufficient
          funds may also charge you an NSF Fee for the same transaction. This dual charge makes the total penalty a
          significant burden.
        </p>
        <p className={styles.bodyText}>
          For example, you make pre-authorized insurance premium payment for your auto insurance from your bank. Last
          month you did not have enough money to pay for the insurance premium. Now your bank will charge you $48 as NSF
          Fees and your insurance company will also charge you $48 for declined payment. Your total expense is $96 on
          this transaction for a trivial oversight.
        </p>

        <h3 className={styles.paraHeading}>NSF is Refundable</h3>

        <p className={styles.bodyText}>
          Banks are not obligated to waive or refund NSF fees, but they often do so on request. If you ask for a refund
          of NSF fees and explain your situation, then your bank may be willing to work with you. Some banks have
          structured procedure in place to refund NSF fees, if you meet certain criteria.
        </p>

        <h3 className={styles.paraHeading}>NSF in Credit Score</h3>

        <p className={styles.bodyText}>
          Banks don’t report NSF records to the credit Agencies. However, your bank internally retains your NSF history
          and will look it up while reviewing any of your future credit applications like credit card, line of credit,
          auto loan or mortgage. Your NSF history will make it harder to get the credit facilities approved and likely
          increase the rate of interest.
        </p>

        <h3 className={styles.paraHeading}>Ways to Avoid NSF Fees</h3>

        <h3 className={styles.paraHeading}>1. Overdraft Protection</h3>

        <p className={styles.bodyText}>
          Request your bank to approve an Overdraft Protection for your account. It would be a small credit limit
          attached to your chequing account. Even if there are no balance available in your account, bank will honour
          the cheque or pre-authorized payment instruction you have issued.
        </p>

        <h3 className={styles.paraHeading}>2. Maintain a Minimum Balance</h3>

        <p className={styles.bodyText}>
          Maintaining a minimum account balance is good safety net. Your self-imposed minimum balance should be around
          $500, which should always be maintained. This should take care of the everyday average transactions.
        </p>

        <h3 className={styles.paraHeading}>3. Open a Savings Account</h3>

        <p className={styles.bodyText}>
          Same idea. As a safety net, maintain some minimum balance in a different account in the same bank. Preferably,
          a saving account. However, not all banks will look up your other account balances or room available in your
          line of credit facility before declining payments and charging you the NSF fees.
        </p>

        <h3 className={styles.paraHeading}>4. Monitor your Expenses and Accounts Closely</h3>

        <p className={styles.bodyText}>
          Track your expenses and account balances regularly. Good news is Arctic Rich will assist you with the right
          tools and raise flags when needed. All you have to do is pay attention to it and act.
        </p>

        <h3 className={styles.paraHeading}>5. Setup Triggers and Alerts</h3>

        <p className={styles.bodyText}>
          Setup your upcoming routine and one-off payments in Arctic Rich platform. Upcoming bill payment and minimum
          account balance triggers in Arctic Rich automatically activated and you will be notified if there are not
          enough funds to meet your scheduled expenses.
        </p>

        <h3 className={styles.paraHeading}>NSF Charges</h3>
        <SimpleTable
          keyField="marketIndexes"
          data={[
            {
              bank1: 'RBC Bank',
              fee1: '$45',
              bank2: 'National Bank',
              fee2: '$45',
            },
            {
              bank1: 'CIBC Bank',
              fee1: '$45',
              bank2: 'CIBC Bank',
              fee2: '$45',
            },
            {
              bank1: 'Scotia Bank',
              fee1: '$48',
              bank2: 'Tangerine',
              fee2: '$45',
            },
            {
              bank1: 'TD Bank',
              fee1: '$48',
              bank2: 'Meridian Credit U',
              fee2: '$48',
            },
            {
              bank1: 'BMO',
              fee1: '$48',
              bank2: 'EQ Bank',
              fee2: '$0',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <p className={styles.chartSource}>Source: Company Websites</p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Overall, NSF is expensive and quickly adds up. Little bit of planning, monitoring and attention can help you
          avoid this unnecessary expense.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/22/2022'} />
      </div>

      <FeedbackBox source="/transaction/banktransactions/text/short/22-mar-2022/01" />
    </>
  );
};
