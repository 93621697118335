import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { MaintenanceForm } from './MaintenanceForm';
import { MaintenanceFormInputs } from './types';
import { createMaintenance, getMaintenanceList } from './autoSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddAutoMaintenance: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getMaintenanceListAction = useCallback(() => dispatch(getMaintenanceList()), [dispatch]);

  const onSubmit = async (data: MaintenanceFormInputs) => {
    Object.assign(data, { vehicle: 4 });
    try {
      await createMaintenance(data);
      history.push('/asset/auto');
      getMaintenanceListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Maintenance']}>
      <MaintenanceForm onSubmit={onSubmit} />
    </Modal>
  );
};
