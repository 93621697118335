import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';
import { BarChart } from './BarChart';

export const RentalVacancyRatesOntario2020_2021: React.FC = () => {
  const data = [
    {
      Year: '2010',
      'Vacancy Rate': 2.9,
    },
    {
      Year: '2011',
      'Vacancy Rate': 2.2,
    },
    {
      Year: '2012',
      'Vacancy Rate': 2.5,
    },
    {
      Year: '2013',
      'Vacancy Rate': 2.6,
    },
    {
      Year: '2014',
      'Vacancy Rate': 2.3,
    },
    {
      Year: '2015',
      'Vacancy Rate': 2.4,
    },
    {
      Year: '2016',
      'Vacancy Rate': 2.1,
    },
    {
      Year: '2017',
      'Vacancy Rate': 1.6,
    },
    {
      Year: '2018',
      'Vacancy Rate': 1.8,
    },
    {
      Year: '2019',
      'Vacancy Rate': 2,
    },
    {
      Year: '2020',
      'Vacancy Rate': 3.2,
    },
    {
      Year: '2021',
      'Vacancy Rate': 3.4,
    },
  ];

  const formatValue = (value: string | number) => `${value}%`;

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Rental Vacancy Rates Ontario 2020-2021</h1>

        <div className={styles.chartWrapper}>
          <h5 className={styles.chartSubHeader}>Figures in Percentages</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={data}
              keys={['Vacancy Rate']}
              indexBy={'Year'}
              // axisLeftLegendTitle={'Vacancy Rate'}
              formatValue={formatValue}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 50, left: 100 },
                tooltipFormat: formatValue,
                legends: [],
                padding: 0.6,
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
                axisLeft: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Vacancy Rate',
                  legendPosition: 'middle',
                  legendOffset: -60,
                  format: formatValue,
                },
              }}
            />
          </div>
        </div>
        <p className={styles.chartSource}>Source: Statistics Canada</p>

        <PublishedDate date={'06/29/2022'} />
      </div>

      <FeedbackBox source="/home/buying-a-house/text/short/29-jun-2022/01" />
    </>
  );
};
