import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DiscretionaryIncome: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Discretionary Income</h1>

        <h3 className={styles.paraHeading}>What is Discretionary Income</h3>

        <p className={styles.bodyText}>
          Discretionary income is the remaining balance of your take-home income after paying for the necessities like
          food, clothing, and shelter.
        </p>

        <h3 className={styles.paraHeading}>How to Calculate Discretionary Income</h3>

        <p className={styles.bodyText}>
          Steps to calculate Discretionary Income of an individual or family are as follows:
        </p>

        <ol className={styles.list}>
          <li>
            <strong>Calculate Total Gross Income</strong>
            <p className={styles.listText}>
              Steps to calculate Discretionary Income of an individual or family are as follows:
            </p>
          </li>
          <li>
            <strong>Subtract Income Taxes</strong>
            <p className={styles.listText}>Deduct Income Taxes paid to Federal and Provincial authorities.</p>
            <p className={styles.listText}>This is also called Disposable Income.</p>
          </li>
          <li>
            <strong>Subtract the Necessities Expenses</strong>
            <p className={styles.listText}>
              Deduct all the expenses related to the necessities like groceries, essential clothing or footwear, rent or
              mortgage payments, utility bills etc.
            </p>
          </li>
          <li>
            <strong>Remaining Balance is Discretionary Income:</strong>
            <p className={styles.listText}>Remaining Balance is Discretionary Income:</p>
          </li>
        </ol>

        <h3 className={styles.paraHeading}>Where to Allocate Discretionary Income</h3>

        <p className={styles.bodyText}>Discretionary Income is generally allocated in the following three areas:</p>

        <ol className={styles.list}>
          <li>
            <strong>Non-Essential Spending</strong>
            <p className={styles.listText}>
              These spending could include vacations, luxury items, shopping, going to sporting events, movies,
              theaters, charitable donations etc.
            </p>
          </li>
          <li>
            <strong>Investments</strong>
            <p className={styles.listText}>
              Investments can be made in businesses ventures, real estates like rental properties, stocks or bonds can
              be purchased from the stock exchange or public market.
            </p>
          </li>
          <li>
            <strong>Savings</strong>
            <p className={styles.listText}>Savings are deposited in the Bank as a short-term or long-term deposits.</p>
          </li>
        </ol>

        <h3 className={styles.paraHeading}>Why Discretionary Income is Important</h3>

        <p className={styles.bodyText}>
          It is important to track the Discretionary Income, both in dollar value as well as a percentage of income.
        </p>
        <p className={styles.bodyText}>
          It not only indicates how much money you have available for non-essential expenses which is not only for
          having some fun and enjoyment of life, but also how much money is available for your investments and savings,
          which defines you future wellbeing and prosperity.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Therefore, it is vitally important for you to have enough discretionary expenses.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'04/08/2022'} />
      </div>

      <FeedbackBox source="/income/the-basics/text/short/08-apr-2022/01" />
    </>
  );
};
