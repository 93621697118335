import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';

import { RootState } from '../../redux/';
import { AutoItems, AutoFormInputs, AutoMaintenances, MaintenanceFormInputs } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';

export interface AutoState {
  list: AutoItems;
  listMaintenance: AutoMaintenances;
  listLoading: boolean;
}

const initialState: AutoState = {
  list: [],
  listMaintenance: [],
  listLoading: false,
};

export const getAutoList = createAsyncAction('GET_AUTO_LIST', async () => {
  return await doGetRequest('vehicle-ownerships/');
});

export const getMaintenanceList = createAsyncAction('GET_AUTO_MAINTENANCE_LIST', async () => {
  return await doGetRequest('vehicle-maintenances/');
});

export const createAuto = (autoRequestBody: AutoFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...autoRequestBody,
    purchase_date: format(fromUnixTime(Date.parse(autoRequestBody.purchase_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('vehicle-ownerships/', updatedRequestBody);
};

export const updateAuto = (autoId: number, autoRequestBody: AutoFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...autoRequestBody,
    purchase_date: format(fromUnixTime(Date.parse(autoRequestBody.purchase_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('vehicle-ownerships/' + autoId + '/', updatedRequestBody);
};

export const deleteAuto = async (autoId: number) => await doDeleteRequest('vehicle-ownerships/' + autoId + '/');
export const deleteMaintenance = async (maintenanceId: number) =>
  await doDeleteRequest('vehicle-maintenances/' + maintenanceId + '/');

export const createMaintenance = (maintenanceRequestBody: MaintenanceFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...maintenanceRequestBody,
    target_date: format(fromUnixTime(Date.parse(maintenanceRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('vehicle-maintenances/', updatedRequestBody);
};

export const updateMaintenance = (maintenanceId: number, maintenanceRequestBody: MaintenanceFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...maintenanceRequestBody,
    target_date: format(fromUnixTime(Date.parse(maintenanceRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('vehicle-maintenances/' + maintenanceId + '/', updatedRequestBody);
};

export const autoSlice = createSlice({
  name: 'auto',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getAutoList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getAutoList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getAutoList.fulfilled)]: (state, action: PayloadAction<AutoItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },

    [String(getMaintenanceList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getMaintenanceList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getMaintenanceList.fulfilled)]: (state, action: PayloadAction<AutoMaintenances>) => {
      const listMaintenance = action.payload;
      return {
        ...state,
        listMaintenance,
        listLoading: false,
      };
    },
  },
});

export const selectAutoList = (state: RootState) => state.auto.list;
export const selectListLoading = (state: RootState) => state.auto.listLoading;
export const selectMaintenanceList = (state: RootState) => state.auto.listMaintenance;

export const selectAutoById = (autoId: number) => (state: RootState) =>
  state.auto.list.find((autoItem) => {
    return autoItem.id === autoId;
  });

export const selectMaintenanceByAutoId = (autoId: number) => (state: RootState) => {
  const foundAuto = state.auto.list.find((autoItem) => {
    return autoItem.id === autoId;
  });

  if (!foundAuto) {
    return [];
  }

  return foundAuto.maintenances;
};

export const selectMaintenanceById = (maintenanceId: number) => (state: RootState) =>
  state.auto.listMaintenance.find((maintenanceItem) => {
    return maintenanceItem.id === maintenanceId;
  });

export default autoSlice.reducer;
