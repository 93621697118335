import React from 'react';
import clsx from 'clsx';

import styles from './CheckboxRound.module.scss';

export interface CheckboxRoundProps {
  name?: string;
  value?: string | number;
  className?: string;
  onChange: (selected: boolean) => void;
  checked: boolean;
  inputId: string;
}

export const CheckboxRound: React.FC<CheckboxRoundProps> = ({
  name,
  value,
  className,
  checked,
  onChange,
  inputId,
  ...otherProps
}) => {
  const onChangeSelect = (selected: boolean) => {
    onChange(selected);
  };
  return (
    <div className={className}>
      <div className={clsx(styles.round)}>
        <input
          type="checkbox"
          name={name}
          id={inputId}
          {...otherProps}
          className={clsx(styles.inputElement)}
          value={value}
          checked={checked}
          onChange={(e: any) => {
            onChangeSelect(e.target.checked);
          }}
        />
        <label htmlFor={inputId}></label>
      </div>
    </div>
  );
};
