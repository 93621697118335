import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';

export const Page0004: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">What $25 Weekly Saving Can Do</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        Taking simple and small steps persistently over a long time can make enormous difference in our lives.
      </Typography>
      <Typography variant="p">
        For example, if we can save only $25 a week or $100 a month, we can save a significant amount of money as shown
        in the following chart. At 6% return, we could accumulate up to $200,000 of wealth over 40 years. Figures in
        Dollars.
      </Typography>

      <Typography variant="p">It is never too late or too small to start.</Typography>
    </Container>
  );
};
