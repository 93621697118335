import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { GoalFormInputs, GoalItems } from './types';
import { Table, dateFormatter, editIconFormatter, deleteIconFormatter, TableSortCaret } from '../../components/Table';
import styles from '../../components/Table/Table.module.scss';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import { getGoalList, deleteGoal } from './goalSlice';

const PriorityHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Rank Goals in order of your priority. </li>
        <br />
        <li>You can change the rankings as your priorities evolve.  </li>
        <br />
        <li>SWAP Priorities by clicking the EDIT Button.</li>
        <br />
        <li>
          Example: You can change Priority 1 to Priority 2. Priority 2 will automatically become Priority 1. Similarly,
          you can SWAP Priority 2 and 3 as well.
        </li>
      </ul>
    </>
  );
};

const GoalHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention your Goal here.</li>
        <br />
        <li>Keep the Description brief but clear. </li>
        <br />
        <li>Example: Buy a home, Car down payment $15,000, etc. </li>
        <br />
        <li>Learn more in the Knowledge Section.</li>
      </ul>
    </>
  );
};

const TargetDateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Set a time here when you would like to complete this Goal. </li>
        <br />
        <li>Be realistic in your estimate.</li>
        <br />
        <li>If needed, you can change it anytime. </li>
      </ul>
    </>
  );
};

const StatusHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Update the status on regular basis. </li>
        <br />
        <li>Mark it complete when the goal is accomplished to make room to setup a new goal. </li>
      </ul>
    </>
  );
};

export interface GoalTableProps {
  list: GoalItems;
  variant: 'active' | 'complete';
}

const goalFormatter = (priority: string) => {
  return <span>Goal {priority}</span>;
};

const goalNameFormatter = (cell: string, row: GoalFormInputs) => {
  if (!cell) return <span>{row.preset_name_display}</span>;
  else return <span>{cell}</span>;
};

const activeColumns = (locationObj: any, deleteEntityAction: (id: number) => void) => [
  {
    dataField: 'priority',
    text: 'Priority',
    sort: true,
    sortCaret: TableSortCaret,
    helpText: <PriorityHelp />,
    formatter: goalFormatter,
    headerFormatter: ColumnHeaderWithHelp,
  },
  {
    dataField: 'name',
    text: 'Goal',
    sort: true,
    sortCaret: TableSortCaret,
    helpText: <GoalHelp />,
    headerFormatter: ColumnHeaderWithHelp,
    formatter: goalNameFormatter,
  },
  {
    dataField: 'target_date',
    text: 'Target Date',
    helpText: <TargetDateHelp />,
    formatter: dateFormatter('MMM dd, yyyy'),
    sort: true,
    sortCaret: TableSortCaret,
    headerFormatter: ColumnHeaderWithHelp,
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    sortCaret: TableSortCaret,
    helpText: <StatusHelp />,
    headerFormatter: ColumnHeaderWithHelp,
  },
  {
    dataField: 'id',
    text: 'Edit',
    sort: false,
    formatter: editIconFormatter,
    classes: styles.editIconColumn,
    formatExtraData: {
      baseUrl: '/goals/goal/edit/goal/',
      locationObj,
    },
  },
  {
    dataField: 'delete',
    text: 'Delete',
    sort: false,
    formatter: deleteIconFormatter(deleteEntityAction),
    classes: styles.deleteIconColumn,
  },
];

const completeColumns = (locationObj: any, deleteEntityAction: (id: number) => void) => [
  {
    dataField: 'description',
    text: 'Goal',
    sort: true,
    sortCaret: TableSortCaret,
  },
  {
    dataField: 'target_date',
    text: 'Completion Date',
    formatter: dateFormatter('MMM dd, yyyy'),
    sort: true,
    sortCaret: TableSortCaret,
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: true,
    sortCaret: TableSortCaret,
  },
  {
    dataField: 'delete',
    text: 'Delete',
    sort: false,
    formatter: deleteIconFormatter(deleteEntityAction),
    classes: styles.deleteIconColumn,
  },
];

export const GoalTable: React.FC<GoalTableProps> = ({ list, variant }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteGoal(id);
    getGoalListAction();
  };

  const columns =
    variant === 'active' ? activeColumns(location, deleteEntityAction) : completeColumns(location, deleteEntityAction);
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
