import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';

import { BarChart } from './BarChart';

export const HomeInsuranceRates: React.FC = () => {
  const barData = [
    {
      Rate: 'CAN, all',
      Value: 72,
    },
    {
      Rate: 'CAN, own',
      Value: 77,
    },
    {
      Rate: 'CAN, rent',
      Value: 41,
    },
    {
      Rate: 'AB, own',
      Value: 84,
    },
    {
      Rate: 'AB, rent',
      Value: 49,
    },
    {
      Rate: 'BC, own',
      Value: 72,
    },
    {
      Rate: 'BC, rent',
      Value: 47,
    },
    {
      Rate: 'ON, own',
      Value: 78,
    },
    {
      Rate: 'ON, rent',
      Value: 42,
    },
    {
      Rate: 'QC, own',
      Value: 70,
    },
    {
      Rate: 'QC, rent',
      Value: 33,
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Home Insurance Rates</h1>

        <p className={styles.bodyText}>Average current market prices of Home Insurance have been appended below.</p>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>Home Insurance Rates</h4>
          <h5 className={styles.chartSubHeader}>Figures in Canadian Dollars</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={barData}
              keys={['Value']}
              indexBy={'Rate'}
              axisLeftLegendTitle={'Value'}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 80, left: 70 },
                legends: [],
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
                padding: 0.6,
              }}
            />
          </div>
        </div>
        <div className={styles.chartSource}>Source: Insureye</div>

        <p className={styles.bodyText}>
          It is important to have a good understanding of the averge going market rates of insurance premiums, which
          would help you to shop around, intelligently negotiate with the providers, and make an informed choice.
        </p>

        <PublishedDate date={'06/03/2022'} />
      </div>

      <FeedbackBox source="/insurance/other-insurance/text/short/03-jun-2022/01" />
    </>
  );
};
