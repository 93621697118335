import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const BuyingAHouseCriteria: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Buying a House - Criteria</h1>

        <p className={styles.bodyText}>It is important to keep the following criteria in mind while buying a house:</p>

        <ol className={styles.list}>
          <li>Location.</li>
          <li>The school district.</li>
          <li>Neighbourhood.</li>
          <li>Taxes, Dues and Fees. Current and historical trends.</li>
          <li>House prices (per square feet). Current and historical trends.</li>
          <li>Transport, Communication (proximity to highways, public transit).</li>
          <li>Crime rate.</li>
          <li>Climate, Weather (history of flood, wildfire or tornado).</li>
          <li>Noise level (due to very close proximity to freeways or highways.</li>
          <li>
            Potential of future value appreciation of the property.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'04/12/2022'} />
      </div>

      <FeedbackBox source="/home/buying-a-house/text/short/12-apr-2022/01" />
    </>
  );
};
