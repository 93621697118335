import React, { useEffect, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

import styles from './HelpBox.module.scss';

interface PasswordHelpBoxProps {
  show: boolean;
  target: HTMLInputElement | null;
}

const PasswordHelpBox = ({ show, target }: PasswordHelpBoxProps) => {
  const uID = new Date().getTime();

  const [showBox, setShowBox] = useState(show);

  const hidePophover = () => {
    setShowBox(false);
  };

  useEffect(() => {
    setShowBox(show);
  }, [show]);

  return (
    <>
      <Overlay
        target={target ? target : document.body}
        show={showBox}
        placement={'right'}
        rootClose
        rootCloseEvent="mousedown"
        onHide={hidePophover}
      >
        {({ arrowProps, ...props }) => (
          <Popover
            id={`popover-help-${uID}`}
            {...props}
            arrowProps={{ ...arrowProps, style: { display: 'none' } }}
            className={styles.helpBox}
          >
            <Popover.Content bsPrefix="helpBoxBody">
              <p>Passwords must be 8-26 characters long and include all of the following:</p>
              <ul>
                <li>One lowercase letter</li>
                <li>One uppercase letter</li>
                <li>One number</li>
                <li>One special character (!@#$%*)</li>
              </ul>
              <p>
                Passwords can not contain your first name, last name or email address used to login to arcticrich.com.
              </p>
            </Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
};

export default PasswordHelpBox;
