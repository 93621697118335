import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';

import styles from './Alerts.module.scss';
import Icons from './Icons';
import { AlertIndicator } from './AlertIndicator';
import { AlertActions } from './AlertActions';

const getTitle = (key: 'all' | 'unread' | 'flags') => {
  switch (key) {
    case 'all':
      return 'All Alerts';
    case 'unread':
      return 'All Unread';
    case 'flags':
      return 'All Flags';
    default:
      break;
  }
};

interface ListHeaderProps {
  display: 'all' | 'unread' | 'flags';
  onChange: (displayType: 'all' | 'unread' | 'flags') => void;
  checked: boolean;
  onSelectAll: (selected: boolean) => void;
  isAnySelected: boolean;
  onToggleFlagAllAlerts: () => void;
  onDeleteAllAlerts: () => void;
}

const ListHeader = ({
  display,
  onChange,
  checked,
  onSelectAll,
  isAnySelected,
  onToggleFlagAllAlerts,
  onDeleteAllAlerts,
}: ListHeaderProps) => {
  const title = getTitle(display);

  const [selected, setSelected] = useState<boolean>(false);
  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  const onChangeSelect = (selected: boolean) => {
    setSelected(selected);
    onSelectAll(selected);
  };

  const disabled = !isAnySelected;

  return (
    <div className={clsx(styles.listItem)}>
      <Row noGutters>
        <Col className="col-auto">
          <AlertIndicator
            selected={selected}
            onChangeSelect={onChangeSelect}
            activeKey={null}
            index={0}
            isOpened={false}
          />
        </Col>
        <Col className={clsx(styles.borderBottom)}>
          <div className={clsx(styles.paddingY, 'pr-3', 'pl-0')}>
            <Dropdown className={clsx(styles.alerHeaderDropdown)}>
              <Dropdown.Toggle
                variant="light"
                id="alert-action-dropdown-toggle"
                className={clsx('bg-transparent', 'border-0', styles.dropdownToggle, 'p-0')}
              >
                <Icons icon="arrow-down" className="mr-2" />
                {title}
              </Dropdown.Toggle>

              <Dropdown.Menu className={clsx(styles.alerHeaderDropdownMenu)}>
                <Dropdown.Item className={clsx(styles.dropdownItem)} onClick={() => onChange('all')}>
                  All Alerts
                </Dropdown.Item>
                <Dropdown.Item className={clsx(styles.dropdownItem)} onClick={() => onChange('unread')}>
                  All Unread
                </Dropdown.Item>
                <Dropdown.Item className={clsx(styles.dropdownItem)} onClick={() => onChange('flags')}>
                  All Flags
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col className={clsx('col-auto', styles.borderBottom)}>
          <AlertActions disabled={disabled} onToggleFlag={onToggleFlagAllAlerts} onDelete={onDeleteAllAlerts} />
        </Col>
      </Row>
    </div>
  );
};

export { ListHeader };
