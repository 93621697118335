import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const WhatIsRRSP: React.FC = () => {
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'withRRSP',
      text: 'With RRSP',
    },
    {
      dataField: 'withoutRRSP',
      text: 'Without RRSP',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is RRSP</h1>

        <p className={styles.bodyText}>RRSP stands for Registered Retirement Savings Plan.</p>

        <p className={styles.bodyText}>RRSP is a retirement savings plan.</p>
        <p className={styles.bodyText}>
          You can open a RRSP account with your financial institution. Your financial institution will register your
          RRSP account with the Government of Canada (Federal). You, your spouse, or common-law partner can contribute
          to this account.
        </p>
        <p className={styles.bodyText}>
          You do not have to pay any taxes on the amount you invest into your RRSP Account. In other words, your RRSP
          contributions are tax deductible.
        </p>
        <p className={styles.bodyText}>
          You will also not have to pay any taxes on the earnings from RRSP accounts, as long as the earnings remain
          within the RRSP Account.
        </p>
        <p className={styles.bodyText}>
          However, you will have to pay taxes when you will withdraw the funds from your RRSP account. Both the original
          investment and subsequent earnings in the RRSP account will be taxable at the applicable tax rate at the time
          of the withdrawal.
        </p>

        <p className={styles.bodyText}>
          <strong>Example</strong>
        </p>

        <p className={styles.bodyText}>
          As displayed the following example, Registered Retirement Savings Plans clearly encourages you to save and
          investment more.
        </p>

        <SimpleTable
          keyField="rrsp"
          data={[
            { description: 'You save $100 for your investments', withRRSP: '$100', withoutRRSP: '$100' },
            {
              description: 'Your Income Tax on this $100 savings\n(Let’s assume 30% is your Tax Rate)',
              withRRSP: '0',
              withoutRRSP: '$30',
            },
            { description: 'Your actual investment after paying Taxes', withRRSP: '$100', withoutRRSP: '$70' },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={styles.paraHeading}>RRSP vs. RSP</p>

        <p className={styles.bodyText}>
          RRSP and RSP (Registered Savings Plan) are essentially the same thing, just two different names of the
          retirement savings plan, which is registered with the Government of Canada (Federal).
        </p>

        <p className={styles.paraHeading}>Eligible Investments</p>

        <p className={styles.bodyText}>RRSPs can have different types of Qualified Investments, such as</p>

        <ul className={styles.list}>
          <li>Stocks</li>
          <li>Bonds</li>
          <li>Mutual Funds</li>
          <li>Exchange Traded Funds (ETFs)</li>
          <li>Savings Deposits</li>
          <li>Treasury Bills</li>
          <li>Guaranteed Income Certificates (GICs)</li>
        </ul>

        <p className={styles.paraHeading}>History</p>

        <p className={clsx(styles.bodyText, styles.last)}>
          RRSP was introduced in 1957 as an incentive for individuals to save more. RRSP is a great way to invest money
          in a tax efficient manner.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/07/2022'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/07-may-2022/01" />
    </>
  );
};
