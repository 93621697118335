import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import { OverviewItems } from './types';
import { doGetRequest } from '../../helpers/api';

export interface InvestmentState {
  list: OverviewItems;
  listLoading: boolean;
}

const initialState: InvestmentState = {
  list: [],
  listLoading: false,
};

export const getOverviewList = createAsyncAction('GET_OVERVIEW_LIST', async () => {
  return await doGetRequest('ratios/overview/');
});

export const getOverviewThumbnails = createAsyncAction('GET_OVERVIEW_THUMBNAILS', async () => {
  return await doGetRequest('sections/overview/categories/');
});

export const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getOverviewList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getOverviewList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getOverviewList.fulfilled)]: (state, action: PayloadAction<OverviewItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

export const selectOverviewList = (state: RootState) => state.overview.list;
export const selectListLoading = (state: RootState) => state.overview.listLoading;

export default overviewSlice.reducer;
