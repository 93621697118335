import React, { useState } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { Modal } from '../../components/Modal';
import { doPostRequest, BadRequestError } from '../../helpers/api';

import { SupportForm } from './SupportForm';
import styles from './SupportTicket.module.scss';

interface RequestBody {
  message: string;
  subject: string;
  second_subject: string;
}

const createSupportRequest = (requestBody: RequestBody) => {
  return doPostRequest('support-messages/', requestBody);
};

export interface SupportTicketProps {
  onClose?: () => void;
}

export const SupportTicket: React.FC<SupportTicketProps> = ({ onClose }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const onSubmit = (request: RequestBody) => {
    setShowLoader(true);

    createSupportRequest(request)
      .then((res) => {
        setShowLoader(false);
        setError('');
        setMessageSent(true);
      })
      .catch((err) => {
        let errorMessage = '';
        if (err instanceof BadRequestError) {
          errorMessage = err.response + '';
        } else {
          errorMessage = err.message;
        }
        setShowLoader(false);
        setError(errorMessage);
        setMessageSent(false);
      });
  };

  return (
    <Modal title={['Contact', 'Support']} onClose={onClose}>
      <div className="position-relative">
        {showLoader ? (
          <div className={styles.feedbackBoxLoader}>
            <Spinner animation="border" />
          </div>
        ) : null}

        {messageSent ? (
          <>
            <Row className="">
              <Col lg={12}>
                <h5 className={styles.messages + ' text-success'}>
                  Thanks for reaching out to us. We&apos;ll get back to you shortly.
                </h5>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {error ? (
              <Row className="">
                <Col lg={12}>
                  <h5 className={styles.messages + ' text-danger'}>{error}</h5>
                </Col>
              </Row>
            ) : null}
            <SupportForm onSubmit={onSubmit} />
          </>
        )}
      </div>
    </Modal>
  );
};
