import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const SignsOfPredatoryLending: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Signs of Predatory Lending</h1>

        <p className={styles.bodyText}>Following are the top five signs of predatory lending:</p>

        <h3 className={styles.paraHeading}>1. High Interests and Hidden Fees</h3>

        <p className={styles.bodyText}>
          Payday loans are the most expensive loans you can borrow. If you borrow $1,000 today and agree to pay $1,150
          on your next pay day two weeks later, you are essentially paying 391% annualized interest.
        </p>

        <p className={styles.bodyText}>
          Canada&apos;s federal law limits the maximum interest rate that lenders can charge at 60% per year. Payday
          lenders bypass this restriction by registering at the provincial level and charging part of their pricing as
          fees (e.g., late payment penalties).
        </p>
        <p className={styles.bodyText}>
          Even the regulatory guideline of 60% interest rate that many lenders follow is extremely high and
          unsustainable.
        </p>

        <h3 className={styles.paraHeading}>2. Fast Approvals</h3>

        <p className={styles.bodyText}>
          Predatory lenders approve credit in as little as 30 minutes.You will receive a warm welcome and smooth
          application process without being asked too many details.
        </p>

        <p className={styles.bodyText}>
          Remember, this speed is designed to serve the predatory lenders, not you. They want to close the transaction
          as fast as possible, so that you do not have enough time to think through, ask questions, understand the
          underlying dangers of the loan structure, or explore other alternatives.
        </p>

        <h3 className={styles.paraHeading}>3. No Credit Check</h3>
        <p className={styles.bodyText}>
          Obtaining a credit score of an applicant is the first step for any prudent and responsible lender.
          Lender&apos;s need to know your credit worthiness or ability to repay and set the pricing and terms
          accordingly before lending any money.
        </p>
        <p className={styles.bodyText}>
          When any lender says, no credit check is needed - it should ring your bell. Predatory lenders do not ask the
          credit score because they already assumed you are a high-risk borrower and set the pricing and terms
          accordingly. This would cost you more and make it harder for you to repay the loan.
        </p>

        <h3 className={styles.paraHeading}>4. Frequent Refinancing</h3>
        <p className={styles.bodyText}>
          Predatory lenders do not expect full repayment. Indeed, their earning are higher when the loan tenor is
          stretched longer than original period.
        </p>
        <p className={styles.bodyText}>
          Predatory lending practice is quite common among the vehicle lenders. You can fund your new car by rolling
          your old, unpaid debt into your new car loan. This way, you end up owing more than your new car&apos;s value.
        </p>
        <p className={styles.bodyText}>
          In Ontario, borrowers can only borrow a second payday loan after paying off the first from the same lender.
          But cash strapped consumers can always go to the other payday lenders.
        </p>
        <p className={styles.bodyText}>
          Lenders also allow to extend tenor of the loan to reduce the monthly payment burden. It comes with hefty
          upfront fees and higher interest rates.
        </p>

        <h3 className={styles.paraHeading}>5. Ambiguous Pricing</h3>
        <p className={styles.bodyText}>
          Predatory lenders would never reveal the actual cost of borrowing. They specialize in concealing pricing
          information from their prospects. In our previous example, payday lenders charged $150 for $1000 advanced for
          two weeks, at 391% annual interest. But they will never tell you that.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Other lenders also follow non-transparent pricing policies and their pricing appear to be cheaper than the
          true pricing.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/14/2022'} />
      </div>

      <FeedbackBox source="/debt/predatory-lending/text/short/14-jul-2022/02" />
    </>
  );
};
