import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const EarnedvsUnearnedIncome: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Earned vs. Unearned Income</h1>

        <p className={styles.paraHeading}>What is Earned Income</p>

        <p className={styles.bodyText}>
          Earned income is all the earnings that you earn with your personal physical or mental efforts.
        </p>

        <p className={styles.bodyText}>
          This includes any wages, salaries, tips, net earnings (if you&apos;re self-employed) and any other income that
          you received in exchange of offering your personal services.
        </p>

        <h3 className={styles.paraHeading}>What is Unearned Income</h3>

        <p className={styles.bodyText}>
          Unearned income is the earning which you earn outside of your job or the business you own such as interest or
          dividend income.
        </p>

        <p className={styles.bodyText}>
          Unearned income is an extremely vital tool in wealth building process and often differentiates between the
          rich and mass population.
        </p>

        <h3 className={styles.paraHeading}>Examples of Unearned Income</h3>

        <ul className={styles.list}>
          <li>Dividend</li>
          <li>Interest</li>
          <li>Annuity payments</li>
          <li>Pension income</li>
          <li>Distributions from retirement accounts</li>
          <li>Capital gains</li>
          <li>Real estate income</li>
          <li>Alimony</li>
          <li>Unemployment compensation</li>
          <li>Inheritances</li>
          <li>Veteran&apos;s benefits</li>
          <li>Lottery winnings</li>
          <li>Child support</li>
          <li>Social Security benefits</li>
          <li>Forgiven deb</li>
          <li>
            Welfare benefits.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/08/2022'} />
      </div>

      <FeedbackBox source="/income/key-terms/text/short/08-jun-2022/02" />
    </>
  );
};
