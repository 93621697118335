import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';

export const AmberLights: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Amber Lights</h1>

        <p className={styles.bodyText}>
          Any of the following could mean things may not be going right. Closer attention to overall financial is
          certainly needed at this point. Good news is, all of these signs could be flagged in real time by Arctic Rich.
        </p>
        <ol className={styles.list}>
          <li>Unable to easily save a certain portion of income on a regular basis ( usually 10% to 20% ).</li>
          <li>Monthly expenses are consistently equal or higher than monthly income.</li>
          <li>Unable to pay bills on time.</li>
          <li>Debt level is increasing.</li>
          <li>Carrying credit card balances.</li>
          <li>Only making minimum payments for credit cards, store cards or other loans.</li>
          <li>Monthly debt repayments take up more than 25% of monthly income.</li>
          <li>Total debt balance higher than 30% of Annual Income.</li>
        </ol>
        <PublishedDate date={'06/12/2021'} />
      </div>

      <FeedbackBox source="/debt/managingdebt/text/short/12-jun-2021/01" />
    </>
  );
};
