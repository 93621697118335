import React, { useCallback, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import HelpBox from '../../components/Common/HelpBox';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';

import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { getDebtList, selectDebtList, selectListLoading } from './debtSlice';
import { DebtTable } from './DebtTable';

export function DebtList(): JSX.Element {
  const dispatch = useDispatch();
  const getDebtListAction = useCallback(() => dispatch(getDebtList()), [dispatch]);

  const listLoading = useSelector(selectListLoading);
  const debtList = useSelector(selectDebtList);

  useEffect(() => {
    getDebtListAction();
  }, [getDebtListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>

        <Spacer variant="small" />
        <div className="d-flex align-items-center">
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>
                    Debt Balances will be automatically be downloaded from the respective Financial Institutions.​
                  </li>
                  <br />
                  <li>
                    How to Link Bank Account: Account ( Menu ) {'>>'} Balance ( Page ) {'>>'} Link Account ( Button ).{' '}
                  </li>
                  <br />
                  <li>
                    Manually input Limit, Rate and Maturity, since these items are not provided by the Financial
                    Institutions.
                  </li>
                </ul>
              </>
            }
          >
            <Typography variant="h1">Debts</Typography>
          </HelpBox>
        </div>

        <DebtTable debtList={debtList} />

        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Managing Debt</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/debts/categories/" />
      </Col>
    </Row>
  );
}
