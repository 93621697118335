import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const GoalsVsMilestones: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Goals vs. Milestones</h1>

        <h3 className={styles.paraHeading}>Goals</h3>

        <ul className={styles.list}>
          <li>Goals are the results or targets individuals want to achieve within a specific period.</li>
          <li>Goal describes the destination where an individual plans to go.</li>
          <li>
            Goals can be qualitative or quantitative. Despite difficulties, qualitative goals are quantified as much as
            possible.
          </li>
          <li>Goals are determined based on overall vision, mission, and objective of individual&apos;s life.</li>
          <li>Goals are established at the beginning after life&apos;s visions and missions are set.</li>
          <li>Goals need to be specific, objective, and measurable.</li>
        </ul>

        <h3 className={styles.paraHeading}>Milestone</h3>

        <ul className={styles.list}>
          <li>Milestones are smaller steps towards achieving the bigger goals.</li>
          <li>Large goals are usually broken down into smaller tasks, called milestones.</li>
          <li>
            Milestones are the measurable tasks which need to be achieved to make progress towards accomplishing the
            goal.
          </li>
          <li>Milestones are quantifiable in most cases with definite target dates of completion.</li>
          <li>Milestones are determined based on a particular goal and are set after the goal has been established.</li>
          <li>
            Milestones are not the ultimate achievements on standalone basis, only a part of the bigger goal.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'06/29/2022'} />
      </div>

      <FeedbackBox source="/goal/basics/text/short/29-jun-2022/02" />
    </>
  );
};
