import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { getOverviewList, selectListLoading, selectOverviewList } from './overviewSlice';

import { Typography } from '../../components/Typography';

import { TrendChart } from './TrendChart';
import { PieChart } from './PieChart';
import { BatteryIndicator } from './BatteryIndicator';
import { ProgressIndicator } from './ProgressIndicator';
import { OverviewItem } from './types';

import styles from './Overview.module.scss';
import { OverviewGallery } from './OverviewGallery';
import { BarChart } from '../../staticPages/BarChart';

const getItemData = (item: OverviewItem, widgetName: string) => {
  return item.widgets.find((widget) => widget.name === widgetName)?.data;
};

const getItemType = (item: OverviewItem, widgetName: string) => {
  return item.widgets.find((widget) => widget.name === widgetName)?.type;
};

export function Overview(): JSX.Element {
  const dispatch = useDispatch();
  const getOverviewListAction = useCallback(() => dispatch(getOverviewList()), [dispatch]);

  const overviewList = useSelector(selectOverviewList);
  const listLoading = useSelector(selectListLoading);

  useEffect(() => {
    getOverviewListAction();
  }, [getOverviewListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  const hideSections = ['investments', 'savings', 'debt'];

  return (
    <>
      <Row>
        <Col className="p-0">
          <Typography variant="h3">INFORMATION</Typography>
          <div className={styles.overviewPage}>
            <div className={styles.spacerSmall} />
            <div className="d-flex align-items-center">
              <Typography variant="h1">Overview</Typography>
            </div>

            {overviewList
              .filter((item) => !hideSections.includes(item.code))
              .map((item, index) => (
                <React.Fragment key={index}>
                  <div className={styles.spacer} />
                  <Typography variant="h2">{item.name}</Typography>
                  <div className={styles.spacer} />
                  <Row className={styles.boxRow} noGutters>
                    <Col>
                      <div className={styles.chartBox}>
                        <h3 className={styles.chartTitle}>Trend</h3>
                        {getItemType(item, 'Trend') === 'line' ? (
                          <TrendChart data={getItemData(item, 'Trend')} />
                        ) : null}
                        {getItemType(item, 'Trend') === 'pie' ? <PieChart data={getItemData(item, 'Trend')} /> : null}
                        {getItemType(item, 'Trend') === 'bar' ? (
                          <BarChart
                            data={getItemData(item, 'Trend')}
                            keys={['hot dog', 'burger', 'sandwich', 'kebab', 'fries', 'donut']}
                            indexBy={'country'}
                            axisLeftLegendTitle={'food'}
                          />
                        ) : null}
                        {getItemType(item, 'Trend') === 'new_bar' ? (
                          <BarChart
                            data={getItemData(item, 'Trend')}
                            capitalizeLabels="capitalizeFirstLetterOnly"
                            keys={['ratio']}
                            indexBy={'month'}
                            axisLeftLegendTitle={'ratio'}
                            extraProps={{
                              margin: { top: 20, right: 30, bottom: 90, left: 70 },
                              legends: [],
                              axisBottom: {
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: -45,
                              },
                              padding: 0.6,
                            }}
                          />
                        ) : null}
                      </div>
                    </Col>
                    <div className={styles.boxDivider} />

                    <Col>
                      <div className={styles.progressBox}>
                        <h3 className={styles.progressTitle}>Progress</h3>
                        {getItemType(item, 'Progress') === 'progress' ? (
                          <ProgressIndicator percentage={getItemData(item, 'Progress')} />
                        ) : null}
                      </div>
                    </Col>
                    <div className={styles.boxDivider} />

                    <Col>
                      <div className={styles.batteryBox}>
                        <h3 className={styles.progressTitle}>Status</h3>
                        {getItemType(item, 'Battery') === 'battery' ? (
                          <BatteryIndicator percentage={getItemData(item, 'Battery')} />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  {index === overviewList.length - 1 ? null : <div className={styles.line} />}
                </React.Fragment>
              ))}
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="p-0">
          <OverviewGallery />
        </Col>
      </Row>
    </>
  );
}
