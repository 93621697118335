import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';

export const Page0008: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">Information and Know-how</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        Our brand promise is, “If any information, strategy or technology exists anywhere, you will certainly have it at
        your fingertips“.
      </Typography>
      <Typography variant="p">
        The information about all the service providers, price, terms, tactics and tools alone arm our clients with a
        sense of control and visibility they have never experience before. Our clients suddenly become aware of the
        knowledge, resources, support and infrastructure around them to advance their causes.
      </Typography>
      <Typography variant="p">
        We urge you not be carried away with the apparent simplicity of the material. We take a lot of care and effort
        to filter out the material that you really do not need to learn and present only the essential items in very
        simple terms. We present in a form that is easy to understand and appl.
      </Typography>
      <Typography variant="p">
        While we handle all the complexities at the back end, you enjoy a seamless experience in every step, from
        learning, planning, spending, saving, investing and using the funds. Our role is to make it harder, preferably
        impossible, for the counterparts of your every transaction to take advantage of you just because you are not
        aware of certain information or strategy.
      </Typography>
    </Container>
  );
};
