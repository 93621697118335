import React from 'react';

export interface IconsProps {
  icon: 'delete' | 'flag' | 'arrow-down' | 'flag-red' | 'flag-outline';
  className?: string;
}

export const Icons: React.FC<IconsProps> = ({ icon, className }) => {
  switch (icon) {
    case 'delete':
      return (
        <i className={className}>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.427" height="17.487" viewBox="0 0 15.427 17.487">
            <g id="prefix__Component_14_2">
              <path
                id="prefix__Path_110"
                d="M1591.282 391.127a.178.178 0 0 0-.2.156c-.014.116-1.349 11.565-1.464 12.694-.078.769-.142 1.148-.389 1.31a4.27 4.27 0 0 1-1.84.177h-5.1a4.269 4.269 0 0 1-1.84-.177c-.247-.162-.311-.541-.389-1.31-.115-1.129-1.451-12.578-1.464-12.694a.18.18 0 0 0-.2-.156.178.178 0 0 0-.156.2c.013.116 1.349 11.563 1.463 12.689.179 1.756.387 1.807 2.584 1.807h5.1c2.2 0 2.4-.051 2.583-1.807.115-1.126 1.45-12.573 1.463-12.689a.177.177 0 0 0-.151-.2z"
                className="prefix__cls-1"
                transform="translate(-1577.076 -388.333)"
              />
              <path
                id="prefix__Path_111"
                d="M1582.4 403.076h.019a.178.178 0 0 0 .159-.2l-.978-9.6a.178.178 0 0 0-.354.036l.978 9.6a.177.177 0 0 0 .176.164z"
                className="prefix__cls-1"
                data-name="Path 111"
                transform="translate(-1577.076 -388.333)"
              />
              <path
                id="prefix__Path_112"
                d="M1587.209 403.076a.177.177 0 0 0 .177-.16l.978-9.6a.178.178 0 0 0-.354-.036l-.978 9.6a.177.177 0 0 0 .159.2z"
                className="prefix__cls-1"
                data-name="Path 112"
                transform="translate(-1577.076 -388.333)"
              />
              <path
                id="prefix__Path_113"
                d="M1592.325 390.006h-5.258a2.51 2.51 0 0 0-4.733 0h-5.079a.178.178 0 1 0 0 .356h15.07a.178.178 0 1 0 0-.356zm-7.625-1.356a2.022 2.022 0 0 1 2.042 1.356h-4.083a2.021 2.021 0 0 1 2.041-1.356z"
                className="prefix__cls-1"
                data-name="Path 113"
                transform="translate(-1577.076 -388.333)"
              />
              <path
                id="prefix__Path_114"
                d="M1585.011 402.186v-10.514a.178.178 0 1 0-.356 0v10.514a.178.178 0 1 0 .356 0z"
                className="prefix__cls-1"
                data-name="Path 114"
                transform="translate(-1577.076 -388.333)"
              />
            </g>
          </svg>
        </i>
      );
    case 'arrow-down':
      return (
        <i className={className}>
          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.74 5.75">
            <polyline className="cls-1" points="0.34 0.37 5.35 5.07 10.4 0.37" />
          </svg>
        </i>
      );
    case 'flag-red':
      return (
        <i className={className}>
          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.9 17.72">
            <line className="cls-1" x1="0.23" y1="0.28" x2="2.38" y2="17.5" />
            <path
              className="cls-2"
              d="M1,.64S2.53-.8,6.21.64s5.45,2.3,7.82.55l.87,7.88a4.33,4.33,0,0,1-4.63.55C7.3,8.49,6.2,8.1,6.2,8.1S3.39,7.25,2,8.27Z"
            />
          </svg>
        </i>
      );
    case 'flag-outline':
      return (
        <i className={className}>
          <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.94 17.73">
            <line className="cls-1" x1="0.23" y1="0.5" x2="2.36" y2="17.5" />
            <path
              className="cls-2"
              d="M1.81,8.2C3.3,7.15,6.07,8,6.18,8s1.14.41,4,1.51a4.14,4.14,0,0,0,4.26-.43l-.8-7.3c-2.18,1.43-4,.72-7.09-.52l-.54-.21C2.61-.29,1.11,1,1.1,1l-.23.16L.82.68C.87.63,2.49-.81,6.21.65l.54.22c3.23,1.27,4.85,1.91,7,.35L14,1l.91,8.27-.07.07a4,4,0,0,1-2.76,1,5.64,5.64,0,0,1-2-.41l-4-1.5s-2.68-.79-4,.14l-.2.14Z"
            />
          </svg>
        </i>
      );
  }
  return null;
};

export default Icons;
