import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const RetirementSavingsRequired: React.FC = () => {
  const columns = [
    {
      dataField: 'desc',
      text: 'Description',
    },
    {
      dataField: 'high',
      text: 'High (Can $)',
    },
    {
      dataField: 'low',
      text: 'Low (Can $)',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Retirement Savings Required</h1>

        <h2 className={styles.subHeading}>For Middle-Class Couples</h2>

        <p className={styles.bodyText}>
          It is important for us to know how much we would need to save in order to secure a comfortable retirement.
        </p>

        <p className={styles.bodyText}>Following chart details an overall estimate:</p>

        <SimpleTable
          keyField="o0"
          data={[
            { desc: "Retirement Cost (at today's dollars)", high: '60,000', low: '40,000' },
            {
              desc: 'Less: Canada Pension Plan (CPP) + Old Age Security(OAS) ',
              high: '30,000',
              low: '30,000',
            },
            {
              desc: 'Balance: Needed from personal or work saving',
              high: '30,000',
              low: '10,000',
            },
            {
              desc: 'Rule of Thumb: 25 times savings of annual requirement',
              high: '750,000',
              low: '250,000',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={clsx(styles.paraHeading)}>Conclusion</h2>
        <p className={styles.bodyText}>
          10% savings from couple’s income (for 31 years at 3% annual return) will accumulate $500,000.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Note</h2>
        <ol type="a" className={styles.list}>
          <li>Retirement costs are estimated for a middle-class couple.</li>
          <li>CPP (Canada Pension Plan) and OAS (Old Age Security) starts at age 65.</li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>Caution</h2>
        <p className={styles.bodyText}>
          Please calculate your own eligibility for Canada Pension Plan (CPP) + Old Age Security (OAS) based on your
          personal context.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Qualifying criteria for CPP and OAS have recently changed and amounts may vary significantly compared to the
          figures used in the above example.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/01/2022'} />
      </div>

      <FeedbackBox source="/savinggoals/text/short/01-mar-2022/01" />
    </>
  );
};
