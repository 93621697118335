import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const ParkingExpense: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Deduction of Parking Expenses</h1>

        <h2 className={clsx(styles.subHeading)}>For Salaried Employees</h2>

        <p className={styles.bodyText}>
          If you have paid Parking expenses which are necessary to perform employment related work. then you can deduct
          your parking expenses from taxable income provided you meet all of the following criteria:
        </p>

        <ol className={styles.list}>
          <li>You had to work away from the employer&apos;s place of work, or at different places.</li>
          <li>
            As per your contract of employment, you are required to pay your own motor vehicle expenses but you cannot
            claim these expenses if you receive reimbursement or receive non-taxable allowance for motor vehicle
            expenses.
          </li>
          <p className={styles.bodyText}>
            If actual expenses are more than the non-taxable allowance, then such excess portion can be claimed.
          </p>
          <li>
            <a
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2200.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Form T2200, Declaration of Conditions of Employment
            </a>
            , must be completed by the employer in order for the employee to claim these expenses.
          </li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>What&apos;s Not Allowed</h2>
        <ol className={styles.list}>
          <li>
            Regular cost of parking at employer’s office (parking expenses at your main work location) e.g. daily or
            monthly parking fees.
          </li>
          <li>Traffic penalty fees like speeding ticket etc.</li>
          <li>
            If your employer reimburses you or you refuse a reimbursement or reasonable allowance from your employer
            then the parking fees can&apos;t be allowed for deduction.
          </li>
        </ol>

        <p className={styles.bodyText}>
          In order to deduct vehicle expenses on <span className={styles.link}>Form T777</span>, the employee must:
        </p>

        <ol className={styles.list}>
          <li>
            Safe keep the parking slips / receipts and make them available to the employer for approval / certification
            in Form T2200.
          </li>
          <li>
            Not include parking costs as part of your allowable motor vehicle expenses.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/22/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expensesII/text/short/22-feb-2022/04" />
    </>
  );
};
