import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import { IncomeItems } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';
import { formatISO } from 'date-fns';
import { IncomeFormInputs } from './types';

export interface IncomeState {
  list: IncomeItems;
  listLoading: boolean;
}

const initialState: IncomeState = {
  list: [],
  listLoading: false,
};

export const getIncomeList = createAsyncAction('GET_INCOME_LIST', async () => {
  return await doGetRequest('incomes/');
});

export const deleteIncome = async (incomeId: number) => await doDeleteRequest('incomes/' + incomeId + '/');

export const createIncome = (incomeRequestBody: IncomeFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...incomeRequestBody,
    date: formatISO(Date.parse(incomeRequestBody.date)),
  };
  return doPostRequest('incomes/', updatedRequestBody);
};

export const updateIncome = (incomeId: number, incomeRequestBody: IncomeFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...incomeRequestBody,
    date: formatISO(Date.parse(incomeRequestBody.date)),
  };
  return doPatchRequest('incomes/' + incomeId + '/', updatedRequestBody);
};

export const incomeSlice = createSlice({
  name: 'income',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getIncomeList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getIncomeList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getIncomeList.fulfilled)]: (state, action: PayloadAction<IncomeItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

export const selectIncomeList = (state: RootState) => state.income.list;
export const selectListLoading = (state: RootState) => state.income.listLoading;
export const selectIncomeById = (incomeId: number) => (state: RootState) =>
  state.income.list.find((incomeItem) => {
    return incomeItem.id === incomeId;
  });

export const selectIncomeItemsByYear = (year: number) => (state: RootState) => {
  const foundIncomeItems = state.income.list.filter((incomeItem) => {
    return parseInt(incomeItem.date.substring(0, 4), 10) === year;
  });

  if (!foundIncomeItems) {
    return [];
  }

  return foundIncomeItems;
};

export default incomeSlice.reducer;
