import React from 'react';

import styles from './Pages.module.scss';

type PublishedDateProps = {
  date: Date | string;
};

export const PublishedDate: React.FC<PublishedDateProps> = ({ date }: PublishedDateProps) => {
  const dateObj = date instanceof Date ? date : new Date(Date.parse(date));
  return (
    <p className={styles.publishedDate}>
      Published Date: {dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
    </p>
  );
};
