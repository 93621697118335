import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ETF: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is ETF</h1>

        <ul className={styles.list}>
          <li>ETF is the abbreviation of Exchange Traded Fund.</li>
          <li>
            ETFs are essentially a basket of securities which can be bought or sold in a Stock Exchange just like any
            other stock.
          </li>
          <li>ETFs in general mimic or shadow different Indexes like S&P 500.</li>
          <li>People love ETFs, because they are stunningly simple.</li>
          <li>
            By buying a single ETF, anyone can own many stocks and other securities at once, making diversification so
            much easy for everyone.
          </li>
          <li>First known ETF was launched in 1993.</li>
          <li>
            ETFs have evolved since its origin and today offer a vast range of investment themes and strategies.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'02/17/2022'} />
      </div>

      <FeedbackBox source="/investment/etf/text/short/17-feb-2022/01" />
    </>
  );
};
