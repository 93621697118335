import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import CDICInsurance from '../common/images/CDICInsurance.png';
import { PublishedDate } from './PublishedDate';

export const ProtectYourDeposit: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Protect Your Bank Deposits</h1>

        <h2 className={clsx(styles.subHeading)}>With CDIC Insurance</h2>

        <h2 className={clsx(styles.paraHeading)}>About CDIC</h2>

        <ul className={styles.list}>
          <li>
            The Canadian Deposit Insurance Corporation (CDIC) is an independent Crown Corporation established by the
            Canadian Federal Government.
          </li>
          <li>CDIC is fully owned by the Government of Canada.</li>
          <li>The CDIC was created by Canadian Parliament in 1967.</li>
          <li>
            CDIC&apos;s primary role is to insure bank deposits of up to $100,000 per insured category for EACH
            institution, provided the deposits are with one of CDIC&apos;s member Canadian Banks or Institutions.
          </li>
          <li>CDIC is neither a Bank nor a Private Insurance Company.</li>
          <li>
            CDIC is funded by premiums paid by their member institutions and don’t receive any public or government
            funds to operate.
          </li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>What&apos;s Covered</h2>
        <ul className={styles.list}>
          <li>Saving accounts.</li>
          <li>Chequeing accounts.</li>
          <li>Guaranteed Investment Certificates (GICs).</li>
          <li>Foreign Currencies (e.g. US Dollar).</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>What&apos;s Not Covered</h2>
        <ul className={styles.list}>
          <li>Stocks.</li>
          <li>Bonds.</li>
          <li>Mutual Funds</li>
          <li>Exchange Traded Funds (ETFs).</li>
          <li>Crypto Currencies.</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>How Much is Covered</h2>
        <p className={styles.bodyText}>
          Your coverage for different products has been appended below. As detailed below, $100,000 is covered
          individually for seven different categories and coverage could be up to $700,000 by a single Financial
          Institution.
        </p>
        <p className={styles.bodyText}>
          In this example, if you have two Financial Institutions (e.g. Scotiabank and TD Bank), then your total
          coverage from both of these Banks for these seven categories would be $1,400,000 ($700,000 + $700,000).
        </p>

        <div className={styles.imgContainer}>
          <img src={CDICInsurance} alt="" width="500" />
        </div>

        <h2 className={clsx(styles.paraHeading)}>Your Responsibility</h2>
        <ul className={styles.list}>
          <li>
            Ensure your contact information at your financial institution is up-to-date so that CDIC can send payments,
            if necessary.
          </li>
          <li>Know what is covered and what is not.</li>
          <li>Know whether your Financial Institution is a CDIC Member.</li>
          <li>You don&apos;t need pay any premium for the CDIC Insurance. You are automatically covered.</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>If your Financial Institution Fails</h2>
        <ul className={styles.list}>
          <li>CDIC will reimburse your insured funds, including interest.</li>
          <li>The reimbursements will be sent within days.</li>
          <li>
            The reimbursement process is automatic, i.e. you do not need to apply or contact CDIC for reimbursements.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'02/17/2022'} />
      </div>

      <FeedbackBox source="/balance/bankaccounts/text/short/19-feb-2022/01" />
    </>
  );
};
