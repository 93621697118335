import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import { ModalLinkButton } from '../../components/ModalLinkButton';
import HelpBox from '../../components/Common/HelpBox';
import ThumbnailGallery from '../../components/Common/ThumbnailGallery';
import { getGoalList, selectOnlyCompleteGoals, selectListLoading, selectMilestonesByGoalId } from './goalSlice';
import { GoalTable } from './GoalTable';
import { MilestoneTable } from './MilestoneTable';

import styles from './GoalList.module.scss';

export function GoalList(): JSX.Element {
  const [showCompleteGoals, setShowCompleteGoals] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState(1);
  const [goalWasSelectedByUser, setGoalWasSelectedByUser] = useState(false);

  const dispatch = useDispatch();
  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const listLoading = useSelector(selectListLoading);

  const goalList = useSelector(selectOnlyCompleteGoals(showCompleteGoals));
  const selectedMilestones = useSelector(selectMilestonesByGoalId(selectedGoalId));

  useEffect(() => {
    getGoalListAction();
  }, [getGoalListAction]);

  /**
   * Select the very first goal to display milestones for it
   */

  useEffect(() => {
    if (!goalWasSelectedByUser && goalList.length > 0) {
      setSelectedGoalId(goalList[0].id);
    }
  }, [goalList, goalWasSelectedByUser]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Row>
      <Col>
        <Typography variant="h3">INFORMATION</Typography>
        <Spacer variant="medium" />

        <div className="d-flex align-items-center">
          <Typography variant="h1">Goals</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                {/* <h6 className="font-weight-bold">Add Goal​</h6>
                <p>Click here to add your most important goals.​</p> */}
                <ul className="mb-0">
                  <li>To focus, there can be maximum of three active goals.​</li>
                  <br />
                  <li>Set Goal 1, Goal 2 and Goal 3, according to your priorities.</li>
                  <br />
                  <li>There can be only one Goal 1, one Goal 2 and one Goal 3.​</li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/goals/goal/add/goal" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <Spacer variant="medium" />
        <span
          onClick={() => setShowCompleteGoals(false)}
          className={clsx(styles.goalBlock, { [styles.goalBlockSelected]: !showCompleteGoals })}
        >
          Active
        </span>
        <div className={styles.goalBlockSpacing} />
        <span
          onClick={() => setShowCompleteGoals(true)}
          className={clsx(styles.goalBlock, { [styles.goalBlockSelected]: showCompleteGoals })}
        >
          Complete
        </span>
        <GoalTable list={goalList} variant={showCompleteGoals ? 'complete' : 'active'} />
        <Spacer variant="gray-spacer" />
        <div className="d-flex align-items-center">
          <Typography variant="h2">Milestones</Typography>
          <HelpBox
            placement="right"
            content={
              <>
                <ul className="mb-0">
                  <li>Breakdown each goal into few smaller pieces of Tasks or Milestones. </li>
                  <br />
                  <li>Describe those smaller pieces of tasks here.</li>
                  <br />
                  <li>Learn more in the Knowledge Section.</li>
                </ul>
              </>
            }
          >
            <ModalLinkButton to="/goals/goal/add/milestone" className="ml-3">
              Add
            </ModalLinkButton>
          </HelpBox>
        </div>
        <Spacer variant="medium" />
        {goalList.map((goal, index) => (
          <React.Fragment key={goal.id}>
            <span
              onClick={() => {
                setSelectedGoalId(goal.id);
                setGoalWasSelectedByUser(true);
              }}
              className={clsx(styles.goalBlock, { [styles.goalBlockSelected]: selectedGoalId === goal.id })}
            >
              {goal.description ?? goal.preset_name_display}
            </span>
            {index < goalList.length - 1 ? <div className={styles.goalBlockSpacing} /> : null}
          </React.Fragment>
        ))}
        <MilestoneTable list={selectedMilestones} />

        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Managing Goals</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/goals/categories/" />
      </Col>
    </Row>
  );
}
