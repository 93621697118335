import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const MilestonesGoal: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Milestones</h1>

        <p className={styles.bodyText}>
          Most of our lifechanging goals are combination of many small tasks. For seamless completion of these larger
          goals in our lives, it is always helpful to break these down to smaller tasks.
        </p>
        <p className={styles.bodyText}>
          Completing these smaller tasks would be the important milestones, which are crucial reference points in
          accomplishing the goal. Some of the benefits of establishing these milestones are
        </p>

        <ol className={styles.list}>
          <li>
            <strong>Manageable:</strong> A large undertaking may seem so overwhelming in scope that many of us would
            simply fear to attempt. However, breaking it down to smaller, bite-sized tasks suddenly makes the same
            undertaking within our reach. Like stepping stones, accomplishing one milestone leads us to the next and the
            next, eventually taking us to the finish line.
          </li>
          <li>
            <strong>Extensive Planning:</strong> Milestones of any goal are generally outlined during the planning
            phase. Selecting the right set of milestones and sequencing them appropriately needs extensive research and
            planning. But once done thoroughly and correctly, it creates a road map for successful execution. This
            advance planning helps to take into account any potential adversities or unforeseen obstacles on the way and
            make alternative arrangements.
          </li>
          <li>
            <strong>Sense of Progress:</strong> Achieving these milestones give us a sense of progress emitting joy and
            pride. Power of small wins are enormous. Of all the things that can energize our spirit, the most important
            is making progress in something meaningful to us. Each milestone allows us to evaluate the incremental
            progress toward the goal.
          </li>
          <li>
            <strong>Right Path:</strong> Milestones show us the way. Accomplishing a milestone ensures not only we are
            making progress but also, we are advancing in the right track. Individual milestone deadlines are a good
            indicator of whether we are on schedule. Completion of each milestone slowly but certainly leads us towards
            achieving the goal.
          </li>
          <li>
            <strong>Celebrate:</strong> It is an opportunity to celebrate when a project milestone has been reached. The
            series of successes build on a momentum and keep us motivated to achieve the next milestone and ultimately
            the main goal.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>
        <PublishedDate date={'10/20/2021'} />
      </div>

      <FeedbackBox source="/goal/settinggoal/text/short/20-oct-2021/02" />
    </>
  );
};
