import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HowOftenUpdated: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>How Often Updated</h1>

        <p className={styles.bodyText}>
          Credit reports are generally updated once a month. Sometimes, it may take up to 45 days to incorporate all the
          new updates.
        </p>
        <p className={styles.bodyText}>
          Updated credit reports reflect the new information provided by the lenders. Some lenders update the records
          more frequently than others. All recent transactions may not be updated in the credit report immediately –
          mainly due to this varying time delay in record submissions by different lenders.
        </p>
        <p className={styles.bodyText}>
          Credit agencies rely entirely on the lenders for the new information. This participation and submissions are
          voluntary and not a legal requirement.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          After incorporating the new information from the lenders, credit score of an individual may change. To recap,
          credit score is a snapshot of an individual&apos;s credit worthiness at a particular point in time.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'10/10/2021'} />
      </div>

      <FeedbackBox source="/creditscore/understandingcredit-score/text/short/10-oct-2021/02" />
    </>
  );
};
