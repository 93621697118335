import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const InteractInternationalTransfer: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Interact International Transfer</h1>

        <h2 className={styles.subHeading}>By Mastercard and Interac</h2>

        <h3 className={styles.paraHeading}>Now Adding Global Power</h3>

        <p className={styles.bodyText}>
          Send an international money transfer from your Canadian Bank Account wherever you are, in collaboration with
          Mastercard and Western Union.
        </p>

        <p className={styles.bodyText}>It is the same secure, easy-to-use Interac e-Transfer service, only global.</p>

        <h3 className={styles.paraHeading}>How-to Send Money Through International Transfer</h3>

        <p className={styles.bodyText}>How do you send money with International Transfer by Mastercard and Interac?</p>

        <p className={styles.bodyText}>
          <strong>You need your own:</strong>
        </p>

        <ol className={styles.list} type="a">
          <li>Bank Account with National Bank.</li>
          <li>Active email address or mobile phone number.</li>
        </ol>

        <p className={styles.bodyText}>
          <strong>To send, follow the easy-to-use instructions on screen:</strong>
        </p>

        <ol className={styles.list} type="1">
          <li>Log into your online or mobile banking app and select the account.</li>
          <li>
            Select “Send Money” &gt; International and then select the recipient’s destination and banking information.
          </li>
          <li>
            Your request is sent to the recipient, and you will receive a notification via email when the funds have
            been sent.
          </li>
          <li>
            Funds are directly sent to the recipient’s bank account.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'06/23/2022'} />
      </div>

      <FeedbackBox source="/transaction/basics/text/short/23-jun-2022/01" />
    </>
  );
};
