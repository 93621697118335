import { useEffect } from 'react';
import { formatTotalValue } from '../components/Table';

export const useTableTotalRow = (totalValue: number, cellsBeforeColspan: number, cellsAfterColspan: number) => {
  /**
   * Yep, this is absolutely non-React way
   *
   * But I haven not come up with better solution taking into account limitation of react-bootstrap-table-next
   *
   * 1. react-bootstrap-table-next does not support colspan / rowspan
   *    (https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/1445).
   * 2. Proposed hacky solutions at https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/285
   *    does not work for our case.
   * 3. I came up with other soluton and had to use direct DOM access.
   * 3. I would prefer to use ref, but no luck here, as well.
   *    Ref attribute of react-bootstrap-table-next does not give direct access to the DOM node
   */

  useEffect(() => {
    const tbodyRef = document.getElementById('js-mainDataTableContainer')?.getElementsByTagName('tbody')[0];

    if (tbodyRef) {
      document.querySelectorAll('.js-mainDataTableContainer__generatedRows').forEach((e) => e.remove());

      // Insert a row at the end of table
      const newRow = tbodyRef.insertRow();
      newRow.className = 'js-mainDataTableContainer__generatedRows';

      // Insert cells required for 'Total' label

      const cellsBefore = newRow.insertCell();
      cellsBefore.colSpan = cellsBeforeColspan;
      cellsBefore.style.fontWeight = 'bold';
      const cellsBeforeText = document.createTextNode('Total');
      cellsBefore.appendChild(cellsBeforeText);

      // Insert a cell with the actual total value

      const totalCell = newRow.insertCell();
      totalCell.style.textAlign = 'right';
      totalCell.style.fontWeight = 'bold';
      const totalCellText = document.createTextNode(formatTotalValue(totalValue));
      totalCell.appendChild(totalCellText);

      // Insert cells to be shown after total value is displayed

      const cellsAfter = newRow.insertCell();
      cellsAfter.colSpan = cellsAfterColspan;
    }
  }, [totalValue, cellsAfterColspan, cellsBeforeColspan]);
};
