import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const StayWithTheBigOne: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Stay with the Big One</h1>

        <p className={styles.bodyText}>
          Clearly, insurance policies are not a commodity. All the insurance policies and service providers are not
          meant to be the same. Unlike many other shopping strategies, you may not opt for the best bargain or the
          cheapest price when it comes to choosing insurance companies.
        </p>

        <p className={styles.bodyText}>
          Instead, you should carefully look at the rankings and financial strengths of the insurance companies. Market
          reputation among consumers is also very important. Your goal is to ensure that the insurance companies you
          choose to work with do not walk away when you need them the most - at the time of your claim.
        </p>

        <h3 className={styles.paraHeading}>Why Size Matters</h3>
        <p className={styles.bodyText}>
          Bigger insurance companies could be better choices while choosing an insurance provider. Here is why:
        </p>
        <ol className={styles.list}>
          <li>
            The bigger insurance companies have stronger financial capabilities and likely to weather temporary
            windfalls better and survive for a longer period. Many of the insurance policies could last a very long
            period of time, sometimes over 50 years. You need a company who will remain stable and strong at the time of
            your claim.
          </li>
          <li>
            Bigger insurance companies are generally more profitable due to the economies of scale, and have enough
            resources to pay for their ongoing claims.
          </li>
          <li>
            Larger insurance companies have more standardized policy writing and claim handling procedures in place
            because they are more concerned about their reputation in the market. It is highly likely that your claims
            will be paid within a reasonable time that is acceptable to the industry standard.
          </li>
          <li>
            There are often situations like pandemic, storm, flood or war when high dollar amounts of claims can be
            filed by a large number of policy holders. Unless the insurance company has a very deep pocket, it may not
            be financially capable to pay all the claims.
          </li>
          <li>
            Smaller insurance companies often compete on price, which can be dangerous in many ways. First, to
            compensate the lower price, the policies may have much more stringent conditions to file claims or cut back
            some essential policy features. Deduction limits of the polices are also likely to be higher. Second, lower
            profit margins of these companies further erode their financial strength and continue to get weaker over the
            long-term to honour all of its policy claims.
          </li>
        </ol>

        <p className={styles.bodyText}>
          After all, what good the few dollars of savings in your premiums will do today, if there is no one to go to
          file your claims or your claim is not honoured?
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/14/2021'} />
      </div>

      <FeedbackBox source="/insurance/thebasics/text/short/14-mar-2022/01" />
    </>
  );
};
