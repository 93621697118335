import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { CreditFactorForm } from './CreditFactorForm';
import { CreditFactorFormInputs } from './types';
import { createCreditFactor, getCreditScoreList } from './creditScoreSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddCreditFactor: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const onSubmit = async (data: CreditFactorFormInputs) => {
    try {
      await createCreditFactor(data);
      history.push('/loan/credit-score');
      getCreditScoreListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Factors Influencing Score']}>
      <CreditFactorForm onSubmit={onSubmit} />
    </Modal>
  );
};
