import React, { useState } from 'react';
import { DisplayError } from './DisplayError';

import styles from './TextArea.module.scss';

export interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  placeholder: string;
  register?: (ref: HTMLTextAreaElement) => void;
  name?: string;
  errors?: Record<string, Record<string, string>>;
  prefix?: string;
  labelClassName?: string;
  textAreaClassName?: string;
  refCallback?: (ref: HTMLTextAreaElement) => void;
  maxLength?: number;
}

export const TextArea: React.FC<TextAreaProps> = ({
  type,
  prefix,
  register,
  name,
  errors,
  className,
  textAreaClassName,
  labelClassName,
  placeholder,
  refCallback,
  maxLength,
  ...otherProps
}) => {
  const [value, setValue] = useState<string>('');
  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className={className + ' ' + styles.textAreaContainer}>
      <p className={styles.textAreaLabel}>
        <span className={styles.label}>{placeholder}</span>
        {maxLength ? <span className={styles.counter}>{maxLength - value.length}</span> : null}
      </p>
      <textarea
        name={name}
        ref={(r: HTMLTextAreaElement) => {
          if (register) register(r);
          if (refCallback) refCallback(r);
        }}
        maxLength={maxLength}
        onChange={onTextAreaChange}
        {...otherProps}
        className={textAreaClassName + ' ' + styles.textarea + ' form-control'}
      />
      {name && <DisplayError errors={errors} name={name} />}
    </div>
  );
};
