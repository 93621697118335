import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';
import clsx from 'clsx';

export const RRSPVSTFSA: React.FC = () => {
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'rrsp',
      text: 'RRSP (Registered Retirement Saving Plan)',
    },
    {
      dataField: 'tfsa',
      text: 'TFSA (Tax Free Saving Account)',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>RRSP VS. TFSA</h1>

        <p className={styles.bodyText}>The key differences between RRSP and TFSA are appended below:</p>

        <SimpleTable
          keyField="rrsp"
          data={[
            {
              description: '1. Plan Holder’s Age Restrictions',
              rrsp: (
                <ol>
                  <li>
                    Cannot maintain RRSP Account after age 71. Balance must be transferred to RRIF or an Annuity
                    Account.
                  </li>
                  <li>No minimum age to open RRSP Account.</li>
                </ol>
              ),
              tfsa: (
                <ol>
                  <li>Minimum 18 Years of age to open a TFSA Account</li>
                  <li>No maximum age.</li>
                </ol>
              ),
            },
            {
              description: '2. Contribution Eligibility',
              rrsp: 'Must have income during the year to be eligible to contribute.',
              tfsa: 'Do not need income during the year to be eligible to contribute.',
            },
            {
              description: '3. Contribution Limit',
              rrsp: '18% of last year’s income or $27,830, whichever lower in 2021.',
              tfsa: '$6,000 per year for everyone in 2022.',
            },
            {
              description: '4. If not Used',
              rrsp: 'Carry forward to next year allowed.',
              tfsa: 'Carry forward to next year allowed.',
            },
            {
              description: '5. Taxes on Contribution',
              rrsp: 'Contributions are made from BEFORE Tax Income.',
              tfsa: 'Contributions are made from AFTER Tax Income.',
            },
            {
              description: '6. Taxes on Withdrawals',
              rrsp: 'Tax needs to be paid on RRSP withdrawals.',
              tfsa: 'Withdrawals are completely tax free.',
            },
            {
              description: '7. Re-Contribution of Withdrawals',
              rrsp: 'Not allowed, except First Time Home Buyer Loan Program.',
              tfsa: 'Withdrawals can be re-contributed anytime.',
            },
            {
              description: '8. Tax Rate Assumption',
              rrsp: 'Tax rate will be lower at the time of withdrawal.',
              tfsa: 'Tax rate will be lower at the time of withdrawal.',
            },
            {
              description: '9. Assigning Beneficiary',
              rrsp:
                'Spouse can be named beneficiary; taxes are due on balance at the Plan Holder’s death, paid before transfer.',
              tfsa:
                'Spouse can be named beneficiary; tax is due only on the growth of the investment value since death.',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <p className={clsx(styles.bodyText, styles.last)}>
          Overall, RRSP and TFSA both are great options and individual choices may vary depending on the task in hand.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/07/2022'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/07-may-2022/03" />
    </>
  );
};
