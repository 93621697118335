import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AutoItems } from './types';
import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import { Table, dateFormatter, editIconFormatter, deleteIconFormatter, TableSortCaret } from '../../components/Table';
import styles from '../../components/Table/Table.module.scss';
import { getAutoList, deleteAuto } from './autoSlice';

const VehicleHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Enter the brief description of each vehicle that you can easily recognize. </li>
        <br />
        <li>Example: Black Ford Explorer - 2019, White Honda Civic - 2000.</li>
      </ul>
    </>
  );
};

const PurchaseDateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention the date you have purchase the vehicle.</li>
        <br />
        <li>This is not the model of the Vehicle. </li>
      </ul>
    </>
  );
};

const PurchasePriceHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Enter the total price paid to purchase this vehicle.</li>
        <br />
        <li>Add all the costs including delivery.</li>
        <br />
        <li>Do not add any taxes, registration fees or any other fees paid the government regulatory bodies.</li>
      </ul>
    </>
  );
};

const CurrentValueHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention estimated current value of the vehicle here.</li>
        <br />
        <li>Update this value regularly. At least once a year.</li>
        <br />
        <li>Learn more in the Knowledge Section on how to estimate this value.</li>
      </ul>
    </>
  );
};

const RemainingLifeHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention here how many years from now you intend to sell, dispose or replace the vehicle. </li>
        <br />
        <li>This not the remaining estimated total life of the vehicle.</li>
        <br />
        <li>Update on regular basis.</li>
      </ul>
    </>
  );
};

export interface AutoTableProps {
  list: AutoItems;
}

const AutoFormatter = (priority: string) => {
  return <span>{priority}</span>;
};

export const AutoTable: React.FC<AutoTableProps> = ({ list }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const getAutoListAction = useCallback(() => dispatch(getAutoList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteAuto(id);
    getAutoListAction();
  };

  const columns = [
    {
      dataField: 'title',
      text: 'Vehicle',
      sort: true,
      helpText: <VehicleHelp />,
      formatter: AutoFormatter,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'purchase_date',
      text: 'Date of Purchase',
      formatter: dateFormatter('MMM dd, yyyy'),
      sort: true,
      helpText: <PurchaseDateHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'purchase_price',
      text: 'Purchase Price',
      sort: true,
      helpText: <PurchasePriceHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'current_price',
      text: 'Current Value',
      sort: true,
      helpText: <CurrentValueHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'remaining_life',
      text: 'Remaining Life ( Years )',
      sort: true,
      helpText: <RemainingLifeHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/asset/auto/edit/auto/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={list} columns={columns} bootstrap4 />;
};
