import React from 'react';
import clsx from 'clsx';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';

export const NetfileRestrications: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Restrictions - NETFILE</h1>

        <p className={styles.bodyText}>
          Following tax returns may not be submitted to the Canada Revenue Agency (CRA) electronically using NETFILE:
        </p>
        <ul className={styles.list}>
          <li>A tax return for any taxation year other than 2017, 2018, 2019 or 2020.</li>
          <li>A tax return for another person.</li>
        </ul>

        <p className={styles.bodyText}>
          Example, if a taxpayer prepared a spousal/family return, each taxpayer must send his or her return separately.
        </p>

        <h2 className={clsx(styles.paraHeading)}>NETFILE can&apos;t be used to file tax return if:</h2>
        <ul className={styles.list}>
          <li>The applicant filed for bankruptcy in 2020 or 2021 (does not include a proposal for bankruptcy).</li>
          <li>Applicant is a non-resident of Canada</li>
        </ul>

        <h2 className={clsx(styles.paraHeading)}>NETFILE can&apos;t be used to change personal information.</h2>

        <PublishedDate date={'11/08/2021'} />
      </div>

      <FeedbackBox source="/tax/electronicfiling/text/short/08-nov-2021/02" />
    </>
  );
};
