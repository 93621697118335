import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const HowToSaveOnCarInsurance: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>How to Save on Car Insurance</h1>

        <p className={styles.bodyText}>
          It is important to have a balance between a full proof Car Insurance and the Insurance Premium you pay. The
          premiums can vary significantly depending on few important factors. You must give careful consideration of
          each of these factors prior to the annual renewal to ensure that you are not unnecessarily paying for what you
          don’t need.
        </p>

        <h3 className={styles.paraHeading}>1. Shop Around</h3>
        <p className={styles.bodyText}>
          Prices may differ from provider to provider. The difference could be significant. Experts recommend taking at
          least three quotes before finalizing, for example a) from those who sell through own agents, b) independent
          agents and c) directly to consumers over internet or phone.
        </p>
        <p className={styles.bodyText}>
          Remember, cheapest price is not the goal. The insurer must be reputable enough to respect your claims at the
          time when you need it most.
        </p>
        <h3 className={styles.paraHeading}>2. Lookup Insurance Costs Before Buying a Car</h3>
        <p className={styles.bodyText}>
          Before buying a car, look up the insurance cost of your chosen car. Insurance Premium may significantly vary
          depending on, a) car’s price, b) repair cost, c) likelihood of theft, d) installed anti-theft features, e)
          safety ratings, f) accident history.
        </p>

        <h3 className={styles.paraHeading}>3. Reduce Optional Insurance on Older Cars</h3>
        <p className={styles.bodyText}>
          As a rule of thumb, a comprehensive coverage is not cost effective if your older car is worth less than 10
          times of your insurance premium.
        </p>

        <h3 className={styles.paraHeading}>4. Bundle Your Insurance Package</h3>
        <p className={styles.bodyText}>
          Most insurers will give you significant discount if you purchase two or more policies from them. Try to bundle
          all your cars and home insurances into one package to get a meaningful discount. Many insurers also give good
          discounts to long standing customers with good track record.
        </p>

        <h3 className={styles.paraHeading}>5. Maintain a Good Credit Score</h3>
        <p className={styles.bodyText}>
          Good credit score indicates you will pay the premiums on time. Accordingly, your insurance premium will be
          significantly lower with an outstanding credit score. Research also indicates that the people who maintain
          good credit score also make fewer insurance claims.
        </p>

        <h3 className={styles.paraHeading}>6. Avail Low Usage Discounts</h3>
        <p className={styles.bodyText}>
          Some insurers offer discounts to those who drive less than average kilometers per year and particularly those
          who carpool to work.
        </p>

        <h3 className={styles.paraHeading}>7. Take a Group Insurance Plan</h3>
        <p className={styles.bodyText}>
          Most of the insurers offer discounts to those who purchase the insurance policy through a group plan from
          their employers, professional business and alumni groups or other associations.
        </p>

        <h3 className={styles.paraHeading}>8. Ask for Other Discounts</h3>
        <p className={styles.bodyText}>
          Many insurers offer discount to customers with cleaner driving track record with no accident history or any
          major traffic violations.
        </p>
        <p className={styles.bodyText}>
          If you have young driver included in your policy, who is a good student and taken driver’s safety course or
          away at college, then you will get a discount.
        </p>

        <h3 className={styles.paraHeading}>Conclusion</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          The type insurance coverage (Basic or Comprehensive), and the amount of deductibles have significant impact on
          your insurance premium. High Credit score holders also tend to file for less insurance claims.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/10/2022'} />
      </div>

      <FeedbackBox source="/insurance/auto-insurance/text/short/10-may-2022/02" />
    </>
  );
};
