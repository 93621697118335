import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { CreditFactorForm } from './CreditFactorForm';
import { CreditFactorFormInputs } from './types';
import { updateCreditFactor, getCreditScoreList } from './creditScoreSlice';
import { BadRequestError } from '../../helpers/api';
import { selectListLoading, selectCreditFactorById } from './creditScoreSlice';

export const EditCreditFactor: React.FC = () => {
  let { creditFactorId } = useParams();
  creditFactorId = parseInt(creditFactorId, 10);
  const creditFactorItem = useSelector(selectCreditFactorById(creditFactorId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const onSubmit = async (data: CreditFactorFormInputs) => {
    try {
      await updateCreditFactor(creditFactorId, data);
      history.push('/loan/credit-score');
      getCreditScoreListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  if (!creditFactorItem) {
    return <div>Credit Factor not found</div>;
  }

  return (
    <Modal title={['Edit', 'Factors Influencing Score']}>
      <CreditFactorForm defaultValues={creditFactorItem} onSubmit={onSubmit} />
    </Modal>
  );
};
