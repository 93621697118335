import React from 'react';
import styles from './Pages.module.scss';
import { SimpleTable } from './SimpleTable';
import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import infoImg from '../common/images/info.svg';
import { PublishedDate } from './PublishedDate';

export const TopTenManufacturers: React.FC = () => {
  const columns = [
    {
      dataField: 'year',
      text: '2021',
    },
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'country',
      text: 'Country',
      // formatter: numberFormatter,
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Top Ten Tire Manufacturers</h1>
        <h1 className={styles.subHeading}>
          Global Ranking by Size in 2021
          <HelpBox placement="right" content={<p className="mb-0">Source: Brand Directory</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <SimpleTable
          keyField="year"
          data={[
            { year: 1, name: 'Michelin', country: 'France' },
            { year: 2, name: 'Bridgestone', country: 'Japan' },
            { year: 3, name: 'Continental', country: 'Germany' },
            { year: 4, name: 'Dunlop', country: 'USA' },
            { year: 5, name: 'Goodyear', country: 'USA' },
            { year: 6, name: 'Pirelli', country: 'Italy' },
            { year: 7, name: 'Hankook', country: 'Korea' },
            { year: 8, name: 'Yokohama', country: 'Japan' },
            { year: 9, name: 'Sumitomo', country: 'Japan' },
            { year: 10, name: 'Linglong', country: 'China' },
          ]}
          columns={columns}
          bootstrap4
        />

        <PublishedDate date={'11/25/2021'} />
      </div>

      <FeedbackBox source="/auto/tire/text/short/25-nov-2021/01" />
    </>
  );
};
