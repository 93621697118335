import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Overlay, Popover } from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';

import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { Form, Input, Select, SubmitButtonLoader } from '../../components/Form';
import { doGetRequest, BadRequestError } from '../../helpers/api';
import helpboxStyles from '../../components/Common/HelpBox.module.scss';

import { completeStep2OfRegistration, setStepCompleted } from './authSlice';
import { SignUpStep2FormInputs } from './types';
import { signUpProvinceOptions, signUpSexOptions, signUpEducationOptions } from './constants';

import styles from './SignUpSteps.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

interface HelpBoxProps {
  show: boolean;
  target: HTMLInputElement | null;
  content: React.ReactNode | string;
  placement?: any;
}

type AddressErrorResponse = {
  address?: {
    message?: string;
    data?: {
      formatted_address?: string;
      postal_address?: {
        regionCode?: string;
        languageCode?: string;
        postalCode?: string;
        administrativeArea?: string;
        locality?: string;
        addressLines?: string[];
      };
      previous_response_id?: string;
    };
  };
};

const HelpBox = ({ show, target, content, placement }: HelpBoxProps) => {
  const uID = new Date().getTime();

  const [showBox, setShowBox] = useState(show);

  const hidePophover = () => {
    setShowBox(false);
  };

  useEffect(() => {
    setShowBox(show);
  }, [show]);

  return (
    <>
      <Overlay
        target={target ? target : document.body}
        show={showBox}
        placement={placement || 'right'}
        rootClose
        rootCloseEvent="mousedown"
        onHide={hidePophover}
      >
        {({ arrowProps, ...props }) => (
          <Popover
            id={`popover-help-${uID}`}
            {...props}
            arrowProps={{ ...arrowProps, style: { display: 'none' } }}
            className={helpboxStyles.helpBox}
          >
            <Popover.Content bsPrefix="helpBoxBody">{content}</Popover.Content>
          </Popover>
        )}
      </Overlay>
    </>
  );
};

const getCountries = () => {
  return doGetRequest('countries/', false);
};

const schemaResolver = yupResolver<SignUpStep2FormInputs>(
  yup.object().shape({
    address: yup.string().required('Please enter Address'),
    city: yup.string().required('Please enter City'),
    province: yup.string().required('Please select Province'),
    postal_code: yup.string().required('Please enter Postal Code'),
    sex: yup.string().required('Please select Sex'),
    education: yup.string().required('Please select Education'),
    mobile_phone: yup.string().required('Please enter Cell Phone'),
  }),
);

export const SignUpStep2: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [countryOpts, setCountryOpts] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [addressError, setAddressError] = useState<AddressErrorResponse | undefined>();

  useEffect(() => {
    /* tslint:disable */
    if (window['fbq']) window['fbq']('track', 'PageView');
  }, []);

  useEffect(() => {
    getCountries()
      .then((res) => {
        setCountryOpts(res);
      })
      .catch((err) => {
        console.log('error while fetching countries: ' + err);
      });
  }, []);

  const [selectedCountry, setSelectedCountry] = useState('CA');
  const [mobileNumber, setMobileNumber] = useState<string | undefined>('');

  const onSubmit = async (data: SignUpStep2FormInputs) => {
    setLoading(true);
    try {
      let country = selectedCountry
        ? countryOpts.find((c) => c['code'].toUpperCase() === selectedCountry.toUpperCase())
        : null;
      country = country ? country['pk'] : country;

      if (addressError?.address?.data?.previous_response_id) {
        data['previous_response_id'] = addressError?.address?.data?.previous_response_id;
      }

      await completeStep2OfRegistration({
        ...data,
        country,
        mobile_phone: '+1' + (data['mobile_phone'] || '').replace(/[^\d]/g, ''),
      });
      setLoading(false);
      dispatch(setStepCompleted(2));
      history.push('/user/sign-up/subscription-info');
    } catch (err) {
      setLoading(false);
      if (err instanceof BadRequestError) {
        console.log(err.response);
        setAddressError(err.response as AddressErrorResponse);
      } else {
        console.log(err);
      }
    }
  };

  const formatPhoneNumber = (phoneNumberString: string | number) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const [showMobileHelp, setShowMobileHelp] = useState<boolean>(false);
  const mobileInput = useRef<HTMLInputElement | null>(null);

  const [showCountryHelp, setShowCountryHelp] = useState<boolean>(false);
  const countryInput = useRef<HTMLInputElement | null>(null);

  return (
    <Container fluid className="px-0">
      <h3 className={styles.title}>Personal Information</h3>
      {addressError?.address?.message && (
        <div className="alert alert-primary" role="alert">
          {addressError.address?.message}
        </div>
      )}
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={{ country: selectedCountry }}>
        <Input
          name="address"
          placeholder="Address"
          className={styles.firstHalfInput + ' ' + styles.inputBlock}
          labelClassName={styles.label}
        />
        <Input
          name="city"
          placeholder="City"
          className={styles.secondHalfInput + ' ' + styles.inputBlock}
          labelClassName={styles.label}
        />

        <Select
          name="province"
          options={signUpProvinceOptions}
          placeholder="Province"
          className={styles.firstHalfInput + ' ' + styles.inputBlock}
          wrapClassName={styles.selectWrap}
        />
        <Input
          name="postal_code"
          placeholder="Postal Code"
          className={styles.secondHalfInput + ' ' + styles.inputBlock}
          labelClassName={styles.label}
        />

        <div
          className={styles.firstHalfInput + ' ' + styles.inputBlock}
          onFocus={() => {
            setShowCountryHelp(true);
          }}
          onMouseLeave={() => {
            setShowCountryHelp(false);
          }}
          ref={countryInput}
        >
          <ReactFlagsSelect
            countries={countryOpts.map((c) => c['code'].toUpperCase())}
            fullWidth={true}
            selected={selectedCountry}
            onSelect={(code) => setSelectedCountry(code)}
          />
        </div>
        <HelpBox
          content="Arctic Rich currently offers services in Canada only."
          show={showCountryHelp}
          target={countryInput.current}
          placement="top"
        />

        <Input
          name="mobile_phone"
          placeholder="Cell Phone"
          labelClassName={styles.label}
          value={mobileNumber}
          maxLength={10}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMobileNumber(e.target.value)}
          onBlur={(e) => {
            setMobileNumber(formatPhoneNumber(e.target.value) || '');
            setShowMobileHelp(false);
          }}
          className={styles.secondHalfInput + ' ' + styles.inputBlock}
          onFocus={() => {
            setShowMobileHelp(true);
          }}
          refCallback={(ref) => {
            mobileInput.current = ref;
          }}
          autoComplete="off"
        />
        <HelpBox
          show={showMobileHelp}
          target={mobileInput.current}
          placement="top"
          content={
            <>
              <ol className="mb-0">
                <li className="mb-1">Insert your 10 digit cell phone number.</li>
                <li>You will receive access codes in this number to securely login to your account.</li>
              </ol>
            </>
          }
        />

        <Select
          name="sex"
          options={signUpSexOptions}
          placeholder="Sex"
          className={styles.firstHalfInput + ' ' + styles.inputBlock}
          wrapClassName={styles.selectWrap}
        />
        <Select
          name="education"
          options={signUpEducationOptions}
          placeholder="Education"
          className={styles.secondHalfInput + ' ' + styles.inputBlock}
          wrapClassName={styles.selectWrap}
        />

        <SubmitButtonLoader value="Continue" className={styles.submitButtonBlock} loading={loading} type="submit" />
      </Form>
    </Container>
  );
};
