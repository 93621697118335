import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const Saving20: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Saving 20%</h1>

        <p className={styles.bodyText}>
          Goal is to set aside funds for savings before spending any money in the other two categories – Must Haves
          (50%) and Wants (30%).
        </p>

        <p className={styles.bodyText}>
          Funds from savings are ideally invested in Long Term Investments. These are locked in to pay for expenses
          while we are in retirements. It is important to understand that the money we earned last month is not only for
          this month’s expense but also meant for the days when we would not have any income, particularly after our
          retirement.
        </p>

        <p className={styles.bodyText}>
          The support infrastructure we had in the past decade have changed significantly. Employers no longer issue
          Defined Benefit Plan and moved to Defined Contribution Plan. This means the benefit amount is no longer fixed
          and vary depending on our contributions and market conditions. Same with Canada Pension Plan. Recent policy
          changes have made it much harder to qualify for a meaningful amount as their qualifying criteria has become
          more stringent.
        </p>

        <p className={styles.bodyText}>
          At the early stage of life or the saving process, part of this savings may be used in the following purposes.
        </p>

        <ol className={styles.list}>
          <li>Emergency Funds</li>
          <li>Medium Term Investments or</li>
          <li>Paying off the Debt.</li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>How Much to Save</h2>

        <h2 className={clsx(styles.paraHeading)}>20%+ Saving - Excellent</h2>
        <p className={styles.bodyText}>
          This is a great achievement. The best practice that most of the successful savers follow. It is important to
          keep the momentum sustainable. Having a good opening base for investments helps to take advantage of upcoming
          market gains and magic of the compounding interest.
        </p>

        <h2 className={clsx(styles.paraHeading)}>10-20% Saving - Good</h2>
        <p className={styles.bodyText}>
          Satisfactory progress at this level of savings. Clearly ahead of the most of your peer group. It is important
          to work on it until the target of 20% saving has been reached. Taking a closer look at Must Have and Want
          related expenses will indicate the rooms for improvements and areas could be worked on.
        </p>

        <h2 className={clsx(styles.paraHeading)}>10% Saving – Average</h2>
        <p className={styles.bodyText}>
          This is an average rate of saving, but certainly not negligible. Steady pace of this level of saving can
          facilitate a reasonable safety net over time. This is a good progress and means you are capable of building on
          this success and increase your saving rate by using more creative tools and game plans.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Less than 10% Saving - Fail:</h2>
        <p className={styles.bodyText}>
          While it may give you some comfort that you are saving and making progress, which you certainly are, the
          reality is that such level of savings is unlikely to secure any dependable safety net that we are working so
          hard to build.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/24/2022'} />
      </div>

      <FeedbackBox source="/saving/20-30-50-plan/text/short/24-feb-2022/02" />
    </>
  );
};
