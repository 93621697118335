import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const SuppliesExpense: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Deduction of Supplies Expenses</h1>

        <h2 className={clsx(styles.subHeading)}>For Salaried Employees</h2>

        <p className={styles.bodyText}>
          If you spent on Supplies, which are necessary to perform employment related work, then you can deduct your
          cost of Supplies from your taxable income – provided you meet all the following criteria:
        </p>

        <ol className={styles.list}>
          <li>You had to work away from the employer&apos;s place of business or at different places.</li>
          <li>
            You use the supplies only for work and you do not receive reimbursement or non-taxable allowance for
            Supplies.
          </li>
          <li>
            <a
              href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2200.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Form T2200, Declaration of Conditions of Employment
            </a>
            , must be completed by the employer in order for the employee to claim these expenses.
          </li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>Expenses Eligible for Deduction</h2>
        <ol className={styles.list}>
          <li>
            Allowed supplies are those items which are <strong>used by you</strong> while directly performing your job
            e.g.: envelopes, stationary items, printer paper etc.
          </li>
          <li>
            Special clothing you wear or have to wear for your work, is not considered as Supplies except the clothing
            which cannot be used for any other occasion like special wigs, clown costumes etc.
          </li>
          <li>Any tools which are considered to be equipment (capital expenses) are not considered as Supplies.</li>
          <li>
            Mobile Phone expenses related to basic service long distance calls are allowed up to a reasonable extent, if
            you can separate personal and official usage for cellular minutes and data. Only the official usage is
            allowed for deduction.
          </li>
          <li>Phone expenses like phone cover, lease etc. are not considered as Supplies.</li>
          <li>
            From landline phones, if you make long distance calls for official use, then it may be considered for
            deduction.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/22/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expensesII/text/short/22-feb-2022/02" />
    </>
  );
};
