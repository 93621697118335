import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { AlertItem } from './types';
import styles from './Alerts.module.scss';

import { AlertIndicator } from './AlertIndicator';
import { AlertActions } from './AlertActions';

interface AlertHeaderProps {
  item: AlertItem;
  index: number;
  activeKey: string | undefined;
  onDeleteSingleAlert: (alertId: number) => void;
  onToggleFlagSingleAlert: (alertId: number) => void;
  checked: boolean;
  onSelectAlert: (selected: boolean, id: number) => void;
  onReadAlert: (id: number) => void;
}

const AlertHeader = ({
  item,
  index,
  activeKey,
  onDeleteSingleAlert,
  onToggleFlagSingleAlert,
  checked,
  onSelectAlert,
  onReadAlert,
}: AlertHeaderProps) => {
  const isOpened: boolean = activeKey && activeKey.toString() === index.toString() ? true : false;
  // const date = formatDate('MMMM dd, yyyy', item.date);
  // const time = formatDate('h:mm dd aa', item.date);
  // const formattedDate = `${date} | ${time}`;

  /*
  alert selection
  */
  const [selected, setSelected] = useState<boolean>(false);
  const onChangeSelect = (selected: boolean) => {
    if (item) {
      setSelected(selected);
      onSelectAlert(selected, item.id);
    }
  };

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  const onDeleteAlert = () => {
    if (item) {
      onDeleteSingleAlert(item.id);
    }
  };

  const onToggleFlagAlert = () => {
    if (item) {
      onToggleFlagSingleAlert(item.id);
    }
  };

  const onClickHeader = () => {
    if (!item.is_read) onReadAlert(item.id);
  };

  return (
    <div className={clsx(styles.listItem, { [styles.highlighted]: selected })}>
      <Row noGutters>
        <Col className="col-auto">
          <AlertIndicator
            selected={selected}
            onChangeSelect={onChangeSelect}
            item={item}
            activeKey={activeKey}
            index={index}
            isOpened={isOpened}
          />
        </Col>
        <Col className={clsx(styles.alertContentHover, styles.borderBottom, styles.alertSubjectArea)}>
          <Accordion.Toggle as="div" className={clsx(styles.alertHeader)} eventKey={index + ''} onClick={onClickHeader}>
            <div className={clsx(styles.paddingY, 'pr-3', 'pl-0')}>
              <h6
                className={clsx('mt-0', styles.alertSubject, {
                  'font-weight-bold': !item.is_read || isOpened || selected,
                })}
              >
                Subject: {item.subject}
              </h6>
              <p className={clsx('mb-0', 'text-muted', styles.alertDate)}>{item.date}</p>
            </div>
          </Accordion.Toggle>
        </Col>
        <Col className={clsx(styles.alertContentHover, 'col-auto', styles.borderBottom, styles.alertActionArea)}>
          <AlertActions item={item} onToggleFlag={onToggleFlagAlert} onDelete={onDeleteAlert} />
        </Col>
      </Row>
    </div>
  );
};

export { AlertHeader };
