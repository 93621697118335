import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { SimpleTable } from './SimpleTable';

export const InternationalMoneyTransferProviders: React.FC = () => {
  const columns = [
    {
      dataField: 'rank',
      text: 'Rank',
    },
    {
      dataField: 'provider',
      text: 'Provider',
    },
  ];

  const tableLinkStyle = {
    fontSize: '0.875rem',
  };

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>International Money Transfer Providers</h1>

        <h2 className={styles.subHeading}>Top 8 in Canada</h2>

        <p className={styles.bodyText}>
          Following are the top 8 providers of money transfer providers offering services in Canada.
        </p>

        <SimpleTable
          keyField="ownHeater"
          data={[
            {
              rank: '1',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://wise.com">
                  Wise
                </a>
              ),
            },
            {
              rank: '2',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://support.apple.com/en-ca/HT207875">
                  ApplePay
                </a>
              ),
            },
            {
              rank: '3',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://www.paypal.com/ca/home">
                  PayPal
                </a>
              ),
            },
            {
              rank: '4',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://www.westernunion.com/">
                  Western Union
                </a>
              ),
            },
            {
              rank: '5',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://www.moneygram.com">
                  MoneyGram
                </a>
              ),
            },
            {
              rank: '6',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://www.payoneer.com/enterprise/about/">
                  Payoneer
                </a>
              ),
            },
            {
              rank: '8',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://www.worldremit.com">
                  WorldRemit
                </a>
              ),
            },
            {
              rank: '8',
              provider: (
                <a style={tableLinkStyle} className={styles.link} href="https://azimo.com/en/ca">
                  Azimo
                </a>
              ),
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <h4 className={styles.noteHeading}>Note</h4>
        <p className={styles.bodyText}>
          The above ranking is based on our internal review and analysis. A more detailed review is expected to be
          released shortly.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/29/2022'} />
      </div>

      <FeedbackBox source="/balance/basics/text/short/29-jun-2022/02" />
    </>
  );
};
