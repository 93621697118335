import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const Netfile: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is NETFILE</h1>

        <p className={styles.bodyText}>NETFILE is an electronic tax-filing system introduced in 2001.</p>
        <p className={styles.bodyText}>
          NETFILE allows individuals to prepare their personal returns electronically online and submit the income tax
          and benefit return directly to the Canada Revenue Agency (CRA).
        </p>
        <p className={styles.bodyText}>
          NETFILE is designed and administered by Canada Revenue Agency (CRA), which is a fully owned agency of the
          Government of Canada.
        </p>
        <p className={styles.bodyText}>
          However, NETFILE is not a Tax Preparation Software. In other words, a Tax Return cannot be prepared using
          NETFILE.
        </p>
        <p className={styles.bodyText}>
          NETFIL is only a transmission service that facilitates submission of the personal income tax return to CRA
          using the Internet.
        </p>
        <p className={styles.bodyText}>
          To file tax return using NETFILE, individuals need to use one of the CRA Approved Tax Software or Web
          Application, which are commonly called “NETFILE Certified“.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          When the preparation of Tax Return Application is finalized and submitted, the Tax Preparation Software or Web
          Application produces a .tax file, which must then be uploaded to the CRA independently to complete the tax
          filing process.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'10/22/2021'} />
      </div>

      <FeedbackBox source="/tax/electronicfiling/text/short/22-oct-2021/01" />
    </>
  );
};
