import React from 'react';
import clsx from 'clsx';

import styles from './Loader.module.scss';

const Loader = () => {
  return (
    <>
      <div className={styles.spinner}>
        <div className={clsx(styles.bar, styles.bar1)}></div>
        <div className={clsx(styles.bar, styles.bar2)}></div>
        <div className={clsx(styles.bar, styles.bar3)}></div>
        <div className={clsx(styles.bar, styles.bar4)}></div>
        <div className={clsx(styles.bar, styles.bar5)}></div>
        <div className={clsx(styles.bar, styles.bar6)}></div>
        <div className={clsx(styles.bar, styles.bar7)}></div>
        <div className={clsx(styles.bar, styles.bar8)}></div>
        <div className={clsx(styles.bar, styles.bar9)}></div>
        <div className={clsx(styles.bar, styles.bar10)}></div>
        <div className={clsx(styles.bar, styles.bar11)}></div>
        <div className={clsx(styles.bar, styles.bar12)}></div>
      </div>
    </>
  );
};

export default Loader;
