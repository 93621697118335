import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AllBondPortfolio: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>All Bond Portfolio</h1>

        <p className={styles.bodyText}>Following are the few compelling reasons to consider bonds.</p>

        <ul className={styles.list}>
          <li>Average historical long-term return is 10% on stocks and 5% on bonds.</li>
          <li>
            For stocks, adjusted real return comes down to around 6% to 7% after deducting the fees, transaction costs,
            other expenses and taxes, which is significantly lower than commonly believed return for stocks of 10% to
            11%.
          </li>
          <li>
            After considering the possibility of significant losses in stocks, the risk of bad timing of investment or
            withdrawal and the volatility in the stock market, bond clearly stands out as a better investment option for
            your important purposes like education, retirement and other financial goals.
          </li>
          <li>
            If the return on stocks is lower than historical average of 10% to 11% due to bad timing of any individual,
            then the actual return after deducting the aforementioned expenses could be even lower than the bonds.
          </li>
          <li>
            This uncertainly in stocks make highly the rated bonds a safer choice for the investors with more
            predictable returns and almost nonexistent risk of losing principal or capital.
          </li>
          <li>
            Overall, bond is perfectly capable of constituting an All Bond Portfolio, where the entire investment is
            built only with bonds. Various types of bonds can take care of different individual requirements within an
            individual’s investment portfolio.
          </li>
          <li>
            Bond is also a great complement to a diversified portfolio mix with other forms of investments like stocks.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/09/2022'} />
      </div>

      <FeedbackBox source="/investment/bond/text/short/9-mar-2022/02" />
    </>
  );
};
