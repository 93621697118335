import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { AutoFormInputs } from './types';
import { DatePicker, Form, Input, SubmitButton } from '../../components/Form';

const schemaResolver = yupResolver<AutoFormInputs>(
  yup.object().shape({
    purchase_date: yup.string().required('please select date'),
    title: yup.string().required('please enter title'),
    purchase_price: yup.number().typeError('please enter a number').required().positive(),
    current_price: yup.number().typeError('please enter a number').required().positive(),
    remaining_life: yup.number().typeError('please enter a number').required().positive(),
  }),
);

export type AutoFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<AutoFormInputs>>;
  onSubmit: SubmitHandler<AutoFormInputs>;
};

export const AutoForm: React.FC<AutoFormProps> = ({ defaultValues = {}, onSubmit }) => {
  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <Input name="title" placeholder="Purpose" label="Purpose" />
        <DatePicker name="purchase_date" placeholder="Date of Purchase" />
        <Input name="purchase_price" placeholder="Purchase Price" label="Purchase Price" prefix="$" />
        <Input name="current_price" placeholder="Current Price" label="Current Price" prefix="$" />
        <Input name="remaining_life" placeholder="Remaining Life" label="Remaining Life" />
        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
