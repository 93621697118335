import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TypesOfGICs: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Types of GICs</h1>

        <p className={styles.bodyText}>Following are the main types of GICs available in the market.</p>

        <h3 className={styles.paraHeading}>Fixed Rate GICs</h3>

        <p className={styles.bodyText}>
          Fixed Rate GICs pay a predetermined interest rate at the end of the term. This is the most popular GIC in the
          market.
        </p>

        <p className={styles.bodyText}>
          For example, if you invest $1,000 in a Fixed Rate GIC at 1% interest rate for one year, you will receive $10
          of interest plus your original $1,000 investment (Total $1,010) at end of the one-year term.
        </p>

        <h3 className={styles.paraHeading}>Variable Rate GICs</h3>

        <p className={styles.bodyText}>
          Variable Rate GICs link interest rates to another reference point which varies from time to time, like the
          Bank’s prime rate. These are called Variable Rate GICs since its interest rate varies along with the reference
          point. Whether you can benefit from these types of GICs depends on the direction of the market since your
          investment’s rate will go up pacing with market and will go down if the market interest rates fall.
        </p>

        <h3 className={styles.paraHeading}>Equity Linked GICs</h3>

        <p className={styles.bodyText}>
          Equity Linked GICs link interest payments to a particular Stock Market Index. At the beginning of the GIC term
          the rate of interest is unknown and determined at maturity based on the performance of its reference Stock
          Market Index.
        </p>

        <p className={styles.bodyText}>
          If the reference Stock Market Index declines from the date of your investment to the GIC’s maturity date, then
          you may not be entitled to any return at all. You will only get back your original principal, which is always
          guaranteed.
        </p>

        <h3 className={styles.paraHeading}>Escalating Rate GICs</h3>

        <p className={styles.bodyText}>
          Escalating Rate GICs increase the interest rate pacing with time, i. e. interest rates will increase in
          subsequent terms. For example, in a 3-Year Escalating Rate GIC will pay you 1.00% interest in year one, 2.00%
          in year two, and 3.00% in year three. These GICs want to attract investments for the longer term and offer the
          best returns in the later part of the GIC term.
        </p>

        <h3 className={styles.paraHeading}>Registered and non-registered GICs</h3>

        <p className={styles.bodyText}>
          You can invest in GICs though your non-registered or registered investment accounts. Non-registered accounts
          are type of GIC accounts you can open in your bank, which are not registered with the Government of Canada.
          Registered accounts are Tax-Free Savings Accounts (TFSAs), Registered Retirement Savings Plans (RRSPs) and
          Registered Retirement Income Funds (RRIFs), which have built in tax incentives from the Government.
        </p>

        <h3 className={styles.noteHeading}>Note</h3>

        <ol className={styles.list}>
          <li>
            GICs may also differ based on when you receive the interests. Generally, your Bank will let you choose if
            you want to withdraw the interests monthly, semi-annually, or annually. You can also set it up to
            automatically reinvest these interests until the final maturity date of the GIC.
          </li>
          <li>
            To simplify, throughout the article we have used the term “Bank” to represent the concerned issuer of GIC or
            the financial institution. It could be a bank, credit union, or any other type of company authorized to
            issue GICs in Canada.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'06/28/2022'} />
      </div>

      <FeedbackBox source="/balance/basics/text/short/28-jun-2022/01" />
    </>
  );
};
