import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TypesOfAutoInsurance: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Types of Auto Insurance</h1>

        <p className={styles.bodyText}>
          Most common types of Auto Insurance Policies are appended below. You can buy the additional insurance policies
          to cover your risks, which are not covered by your basic policy.
        </p>

        <h3 className={styles.paraHeading}>Liability Insurance</h3>
        <ul className={styles.list}>
          <li>Liability insurance covers losses related to injury or death caused by your vehicle to other people.</li>
          <li>Liability insurance also covers any damage to other vehicle caused by your vehicle.</li>
          <li>
            If the losses or damages caused by your vehicle is more than your liability limit, then you will pay the
            balance from your own sources.
          </li>
          <li>
            Liability insurance does not cover any cost of repair of your car. You need to buy additional insurance to
            cover these costs.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Accident Benefits or Bodily Injury Insurance</h3>
        <p className={styles.bodyText}>Accident benefits cover the following:</p>
        <ul className={styles.list}>
          <li>Your medical expenses.</li>
          <li>Loss of income due to a car accident.</li>
        </ul>
        <p className={styles.bodyText}>
          In Quebec, you are automatically insured for bodily injuries. Part of the premium is paid from the driver’s
          license registration fees. You don’t need to buy any extra coverage for this.
        </p>

        <h3 className={styles.paraHeading}>Collision Insurance</h3>
        <ul className={styles.list}>
          <li>
            Collision insurance covers the cost of repairing or replacing your car if you hit another car or object.
          </li>
          <li>
            This may be included in your mandatory insurance coverage. Ask your insurance provider for the details.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Comprehensive Insurance</h3>
        <p className={styles.bodyText}>
          Comprehensive insurance covers the cost of repairing or replacing your car due to any other type of damage or
          loss, such as:
        </p>
        <ul className={styles.list}>
          <li>Vandalism</li>
          <li>Theft</li>
        </ul>
        <p className={styles.bodyText}>
          Comprehensive insurance does not cover the loss or damage to your car, if you hit another car or object in a
          collision.
        </p>

        <h3 className={styles.paraHeading}>Optional Insurance Coverage</h3>
        <p className={styles.bodyText}>
          You may purchase additional insurance policies for the following risk coverages:
        </p>
        <ul className={styles.list}>
          <li>Renting a car or using alternate transportation, while your car is undergoing repairs.</li>
          <li>Repair physical damage to a rental car.</li>
          <li>Emergency road-side assistance.</li>
          <li>
            Collision forgiveness which keeps your premium from increasing in the first accident where you had been at
            fault.
          </li>
          <li>Depreciation to ensure that you get the full value which you paid for the car.</li>
        </ul>

        <h3 className={styles.paraHeading}>Conclusion</h3>
        <ul className={styles.list}>
          <li>Ask your provider, what risks your insurance policy covers and does not cover.</li>
          <li>Think carefully, what additional risks you need to cover.</li>
          <li>
            Review your policy every year before renewal as your situation may change.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'05/10/2022'} />
      </div>

      <FeedbackBox source="insurance/auto-insurance/text/short/10-may-2022/02" />
    </>
  );
};
