import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const InteracCrossBorder: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Interac Cross Border</h1>

        <p className={styles.bodyText}>Enjoy cross-border spending with the convenience of Interac Debit</p>

        <p className={styles.bodyText}>
          When you’re in the U.S. ready to pump gas at a pitstop or pick up some snacks at the drugstore, and you see a
          debit terminal at checkout, you may very likely be able to pay with Interac Debit.
        </p>

        <h3 className={styles.paraHeading}>Benefits</h3>

        <ul className={styles.list}>
          <li>All the benefits and security of Interac Debit.</li>
          <li>Cash back at supermarkets and some pharmacies.</li>
          <li>Use a Canadian Debit Card in the U.S. to shop conveniently and securely.</li>
          <li>Interac collaborate with NYCE Payment Networks.</li>
        </ul>

        <h3 className={styles.paraHeading}>Where Can You Shop with Interac Debit?</h3>

        <p className={styles.bodyText}>
          Shop conveniently at most of your favourite stores, gas stations and other retailers, and enjoy the benefits
          of using your Debit Card.
        </p>

        <h3 className={styles.paraHeading}>Two Million Retailers in the U.S</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          Cross Border payments allows you to securely use your Debit Card at approximately two million retailers in the
          U.S.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/23/2022'} />
      </div>

      <FeedbackBox source="/transaction/basics/text/short/23-jun-2022/02" />
    </>
  );
};
