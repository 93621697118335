import React, { useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '../../components/Modal';
import { GoalForm } from './GoalForm';
import { GoalFormInputs } from './types';
import { createGoal, getGoalList, selectOnlyCompleteGoals } from './goalSlice';
import { BadRequestError } from '../../helpers/api';

import warningSign from '../../common/images/warning-sign.svg';
import styles from './AddGoal.module.scss';

export const AddGoal: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const activeGoals = useSelector(selectOnlyCompleteGoals(false));

  const maximumNumberOfGoalsReached =
    activeGoals.findIndex((goal) => goal.priority === 1) > -1 &&
    activeGoals.findIndex((goal) => goal.priority === 2) > -1 &&
    activeGoals.findIndex((goal) => goal.priority === 3) > -1;

  const onSubmit = async (data: GoalFormInputs) => {
    try {
      await createGoal(data);
      history.push('/goals/goal');
      getGoalListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (maximumNumberOfGoalsReached) {
    return (
      <Modal title={['No', 'Goals']} variant="maximumGoals">
        <div className="d-flex">
          <img src={warningSign} alt="" width="119" height="107" />
          <div className={styles.text}>
            <p className={styles.paragraph1}>There can be maximum of three Active Goals.</p>
            <p className={styles.paragraph2}>Delete or complete a current goal to create a new one.</p>
          </div>
        </div>
        <Link to="/goals/goal">
          <button type="button" className={styles.button + ' btn'}>
            OK
          </button>
        </Link>
      </Modal>
    );
  }

  return (
    <Modal title={['Add', 'Goal']}>
      <GoalForm onSubmit={onSubmit} />
    </Modal>
  );
};
