import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import FullCalendar, { EventContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import { getCalendarList, selectCalendarEventsList, selectListLoading } from './calendarSlice';
import { Typography } from '../../components/Typography';
import { Spacer } from '../../components/Spacer';
import HelpBox from '../../components/Common/HelpBox';

import { CalendarGallery } from './CalendarGallery';
import styles from './CalendarList.module.scss';

interface EventHelpProps {
  details: {
    entity: string;
    [key: string]: unknown;
  };
}

const EventHelp = ({ details }: EventHelpProps) => {
  const { entity, ...rest } = details;

  return (
    <>
      <h5 className={cn(styles.eventHelpTitle, styles[entity])}>{entity.replace(/_/g, ' ')}</h5>
      <ul className={styles.eventHelpList}>
        {Object.keys(rest).map((key: string, idx: number) => {
          return (
            <li key={idx} className={cn(styles[entity])}>
              <span className={styles.eventHelpListItem}>
                <i className={cn(styles.eventHelpListCircle, styles[entity])} />
                <span className={styles.eventHelpKey}>{key.replace(/_/g, ' ')}:&nbsp;</span> {rest[key]}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

function renderEventContent(eventContent: EventContentArg) {
  const showHelp = eventContent.event.extendedProps && eventContent.event.extendedProps.details;

  return (
    <>
      {showHelp ? (
        <>
          <HelpBox content={<EventHelp details={eventContent.event.extendedProps.details} />}>
            <div className="w-100">
              <b>{eventContent.timeText}</b>
              <span>{eventContent.event.title}</span>
            </div>
          </HelpBox>
        </>
      ) : (
        <>
          <div className="w-100">
            <b>{eventContent.timeText}</b>
            <span>{eventContent.event.title}</span>
          </div>
        </>
      )}
    </>
  );
}

export function CalendarList(): JSX.Element {
  const dispatch = useDispatch();
  const getCalendarListAction = useCallback(() => dispatch(getCalendarList()), [dispatch]);

  const calendarEventsList = useSelector(selectCalendarEventsList);
  const listLoading = useSelector(selectListLoading);

  useEffect(() => {
    getCalendarListAction();
  }, [getCalendarListAction]);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <Row>
        <Col>
          <Typography variant="h3">INFORMATION</Typography>
          <Spacer variant="small" />
          <div className="d-flex align-items-center">
            <Typography variant="h1">Calendar</Typography>
          </div>
          <div className={styles.divider} />
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            initialView="dayGridMonth"
            events={calendarEventsList}
            eventContent={renderEventContent}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,list',
            }}
          />
          <div className={styles.calendarFooter}>
            <div>
              <i className={cn(styles.circle, styles.redCircle)} />
              Debt
            </div>
            <div>
              <i className={cn(styles.circle, styles.greenCircle)} />
              Investment
            </div>
            <div>
              <i className={cn(styles.circle, styles.orangeCircle)} />
              Bills
            </div>
            <div>
              <i className={cn(styles.circle, styles.blueCircle)} />
              Goal
            </div>
            <div>
              <i className={cn(styles.circle, styles.purpleCircle)} />
              Others
            </div>
          </div>
          <Spacer variant="red-spacer" />

          <CalendarGallery />
        </Col>
      </Row>
    </>
  );
}
