import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhyETF: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Why ETF</h1>

        <p className={styles.bodyText}>This are very compelling reasons to consider ETFs:</p>

        <ol className={styles.list}>
          <li>
            <strong>Simple</strong>: ETFs can be bought or sold through stock exchange with few simple steps.
          </li>
          <li>
            <strong>Easy Diversification</strong>: Super easy to diversify investments to many stocks with the purchase
            of a single ETF.
          </li>
          <li>
            <strong>Lower Fees</strong>: ETFs have much lower fees in contrast to Mutual Funds. On average, ETF is about
            five times cheaper than Mutual Funds.
          </li>
          <li>
            <strong>Lower Tax</strong>: ETF structures are more tax friendly, helping to reduce taxes compared to Mutual
            Funds.
          </li>
          <li>
            <strong>Like a Stock</strong>: ETF behaves much like a stock. It has its own ticker symbol.
          </li>
          <li>
            <strong>Liquidity</strong>: ETFs can be bought or sold anytime during the Stock Exchange trading hours,
            offering same level of liquidity of any stock.
          </li>
          <li>
            <strong>Transparency</strong>: An ETF can be bought or sold based on real-time value of its participating
            stocks of which the ETF basket is composed of.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/19/2022'} />
      </div>

      <FeedbackBox source="/investment/etf/text/short/19-feb-2022/01" />
    </>
  );
};
