import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { BarChart } from './BarChart';

export const MobilePaymentApps: React.FC = () => {
  const barData = [
    {
      Apps: 'PayPal',
      Percentage: 20.4,
    },
    {
      Apps: 'Apple Pay',
      Percentage: 15.5,
    },
    {
      Apps: 'Starbucks',
      Percentage: 13.3,
    },
    {
      Apps: 'Google Pay',
      Percentage: 12.2,
    },
    {
      Apps: 'Uber',
      Percentage: 10.9,
    },
    {
      Apps: 'BMO',
      Percentage: 10.1,
    },
    {
      Apps: 'TD',
      Percentage: 8.8,
    },
    {
      Apps: 'Samsung Pay',
      Percentage: 2.7,
    },
    {
      Apps: 'Android',
      Percentage: 6.8,
    },
    {
      Apps: 'RBC',
      Percentage: 6.6,
    },
    {
      Apps: 'CIBC',
      Percentage: 6.1,
    },
  ];

  const formatValue = (value: string | number) => `${value}%`;

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Mobile Payment Apps</h1>

        <p className={styles.bodyText}>Market share of the most popular mobile payments apps in Canada in 2019.</p>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>Mobile Payment Apps - Canada 2019</h4>
          <h5 className={styles.chartSubHeader}>Figures in Percentages</h5>

          <div className={styles.chartContainer}>
            <BarChart
              data={barData}
              keys={['Percentage']}
              indexBy={'Apps'}
              axisLeftLegendTitle={'Percentage'}
              formatValue={formatValue}
              extraProps={{
                margin: { top: 20, right: 100, bottom: 80, left: 70 },
                legends: [],
                tooltipFormat: formatValue,
                axisBottom: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -45,
                },
                axisLeft: {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Percentage',
                  legendPosition: 'middle',
                  legendOffset: -60,
                  format: formatValue,
                },
                padding: 0.6,
              }}
            />
          </div>
        </div>
        <div className={styles.chartSource}>Source: Satista</div>

        <p className={clsx(styles.bodyText, styles.last)}>
          Mobile payment apps have gained more popularity since 2019.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/27/2022'} />
      </div>

      <FeedbackBox source="/transaction/mobile-payments/text/short/27-jul-2022/01" />
    </>
  );
};
