import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ClaimingTaxBenefitsForDependents: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Claiming Tax Benefits for Dependents</h1>

        <h3 className={styles.paraHeading}>Who is a Dependent</h3>

        <p className={styles.bodyText}>The dependant can be one of the following persons:</p>

        <ul className={styles.list}>
          <li>Your parent or grandparent</li>
          <li>Your child, grandchild, brother, or sister under 18 years of age</li>
          <li>
            Your child, grandchild, brother, or sister 18 years of age or older with an impairment in physical or mental
            functions.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Criteria to Qualify</h3>
        <p className={styles.bodyText}>
          To claim the credit, both of following criteria must be met. At any time during the year:
        </p>

        <ol className={styles.list}>
          <li>You did not have a spouse, or if you did, you weren’t living with or being supported by him or her.</li>
          <li>
            The dependant was supported by you, meaning you maintained a home and the dependant lived with you. Just
            visiting wouldn&apos;t count to qualify.
          </li>
        </ol>

        <h3 className={styles.paraHeading}>Support to Spouse</h3>

        <p className={styles.bodyText}>You can claim for support to spouse tax credit:</p>

        <ul className={styles.list}>
          <li>
            If your spouse&apos;s net income is less than the basic personal exemption then you can claim the spousal
            amount. This is a non-refundable tax credit that reduces your taxable income.
          </li>
          <li>
            You can claim an additional $2,295, if your spouse is dependent due to a mental or physical infirmity.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Common Tax Credits</h3>

        <p className={styles.bodyText}>Common tax credits applicable for dependents are:</p>

        <ol className={styles.list}>
          <li>
            <strong>Childcare Expenses:</strong>
            <p className={styles.listText}>
              You can claim childcare expenses for your dependent child aged up to 18 years (no age limit if the child
              has a mental or physical infirmity). You can claim the expenses, even if your child is living away for
              education, as long as the child mentions your address as his or her permanent address.
            </p>
          </li>
          <li>
            <strong>Parent or Grandparent</strong>
            <p className={styles.listText}>
              To make a claim, the person must be dependent on you and live with you in your home.
            </p>
          </li>
          <li>
            <strong>Other Relatives</strong>
            <p className={styles.listText}>
              Other relatives like brother, sister who are over the age of 18 and who, at any time in the year was
              dependent on you because of a mental or physical infirmity, are also considered as eligible dependents for
              the purpose of claiming the expenses.
            </p>
          </li>
        </ol>

        <h3 className={styles.paraHeading}>Exclusion</h3>
        <ol className={styles.list}>
          <li>
            To claim someone as a dependent on your tax return, you can&apos;t be claimed as a dependent on someone
            else&apos;s tax return.
          </li>
          <li>You or someone else is claiming a spouse amount for this dependent</li>
          <li>Someone else in your household is making this claim. </li>
          <li>
            You can only make a claim for one dependent, even if you have more than one child or adults whom you
            support. In addition, each household can only make one claim even if there are additional dependents or
            people who are eligible to claim another dependent.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'04/08/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses-for-business/text/short/08-apr-2022/01" />
    </>
  );
};
