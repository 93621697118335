import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const LimitationsCreditScore: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Limitation of Credit Score</h1>

        <p className={styles.bodyText}>
          Just like any other prediction models, Credit Score is not perfect. Here are few of the limitations of credit
          score.
        </p>

        <ol className={styles.list}>
          <li>
            <strong>For Lenders Only: </strong>Credit scores have been designed to serve the lending community only.
            Credit scores only predict how likely the borrowers will repay the borrowed facility. But it says nothing
            about an individual’s other aspects of financial health like insurance coverages, level of investments,
            saving habits, overall financial sustainability etc.
          </li>
          <li>
            <strong>Historical Data: </strong>Credit score models use past behavior patterns to predict the future
            performance of a borrower. Therefore, credit scores are unable to evaluate those who have not borrowed money
            previously or have a credit history. Not having a credit score does not mean someone has a higher chance of
            default. Credit Score models simply have no way of assessing the potential borrower.
          </li>
          <li>
            <strong>Lack of Qualitative Data: </strong>Good prediction models generally use both quantitive and
            qualitive information to predict future events. In this case, we know an individual delayed or defaulted to
            pay loans, bills etc. But credit score model does not consider why that individual delayed or defaulted.
            <p className={styles.listText}>
              There could be many other reasons for such a delay or default other than the ability or willingness to
              pay, such as slip of mind, long travels, sickness etc. Financial institutions neither have access nor do
              they record such information, which seriously limits the credit score model’s prediction abilities.
            </p>
          </li>

          <li>
            <strong>Limited Lifetime: </strong>Just like the financial position of the individuals, their respective
            Credit Scores can also change very quickly. Credit Agencies and Financial Institutions are aware of that
            challenge and work towards updating and re-evaluating all the credit scores on an ongoing basis.
            <p className={styles.listText}>
              Due to large volume of data reporting and high frequency of updating, timeliness of getting all the
              information to generate a meaningful score remains a major challenge. Different financial institutions
              have different reporting cycles, which means a credit score is never fully updated and reflective of true
              position.
            </p>
          </li>

          <li>
            <strong>Transparency vs.Window Dressing: </strong>As the call for more transparency intensifies across all
            the consumer products and services, Credit Agencies are beginning to share more details on exactly how do
            they calculate their credit scores.
            <p className={styles.listText}>
              While this is a welcome news and has many upsides, unfortunately it also opens doors for deliberate
              manipulation. Users can take advantages of this insight and window dress their credit score. If
              successful, the borrower my may appear much better than their actual profile, while applying for a credit.
            </p>
          </li>

          <li>
            <strong>Too Many Errors: </strong>The main challenge of any credit score is the astounding number of errors
            found in the credit reports. At least, one in five consumers have potentially a material error in their
            credit reports.
            <p className={clsx(styles.listText)}>
              These mistakes systematically understate the credit scores and make the applicants look much riskier than
              they really are, resulting the credit facilities more expensive, lower approved credit limits and at times
              rejected the credit application altogether.
              <img src={redCircle} className={styles.redCircle} alt="" />
            </p>
          </li>
        </ol>

        <PublishedDate date={'03/01/2022'} />
      </div>

      <FeedbackBox source="/creditscore/text/short/01-mar-2022/01" />
    </>
  );
};
