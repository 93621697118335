import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const CalendarAndReminders: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Calendar and Reminders</h1>

        <p className={styles.bodyText}>You need to setup reminders for two types of actions to be taken.</p>

        <ol className={styles.list} type="1">
          <li>Tasks that must be completed on a specific day or time.</li>
          <li>Tasks that need to be done, as soon as possible.</li>
        </ol>

        <p className={styles.bodyText}>Calendar would take care of the first type of tasks.</p>

        <h3 className={styles.paraHeading}>What Should Go to the Calendar</h3>

        <p className={styles.bodyText}>Three types of tasks should go on to the calendar.</p>

        <h3 className={styles.paraHeading}>1. Time Specific Actions</h3>
        <p className={styles.bodyText}>
          These are mainly appointments to attend meetings. Tracking these tasks on a calendar along with reminder alert
          is sufficient. For example, a meeting with your banker.
        </p>

        <h3 className={styles.paraHeading}> 2. Day Specific Actions</h3>
        <p className={styles.bodyText}>
          These are the tasks that you need to perform at any time during a particular day but not necessarily at a
          specific time. For example, repayment of loan on a due date. This should be tracked on your calendar for that
          particular day but not at any specific timeslot.
        </p>

        <h3 className={styles.paraHeading}> 3. Day Specific Information</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          The calendar is also a place to keep track of things you want to know about on specific days. These are not
          necessarily actions you will have to take, instead learn certain information on a certain date which can be
          useful. For example, the day Bank of Canada will announce the interest rate.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/23/2022'} />
      </div>

      <FeedbackBox source="/calendar/time-management/text/short/23-jun-2022/02" />
    </>
  );
};
