import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';
import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { BarChart } from './BarChart';
import { PublishedDate } from './PublishedDate';

export const IncomeDistribution: React.FC = () => {
  const data = [
    {
      Income: '> $5,000',
      Tax: 2.1,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$5,000 - $9,999',
      Tax: 2.1,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$10,000 - $19,999',
      Tax: 5.6,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$20,000 - $29,999',
      Tax: 8.7,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$30,000 - $39,999',
      Tax: 12.5,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$40,000 - $49,999',
      Tax: 13.5,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$50,000 - $59,999',
      Tax: 10.7,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$60,000 - $79,999',
      Tax: 17.6,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '$80,000 - $99,999',
      Tax: 11.5,
      color: 'rgb(61,119,214)',
    },
    {
      Income: '< $100,000',
      Tax: 15.7,
      color: 'rgb(61,119,214)',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Income Distribution - Canada 2019</h1>

        <h1 className={styles.subHeading}>By Income Level | In Percentage</h1>

        <div className={styles.chartContainer}>
          <BarChart
            data={data}
            keys={['Tax']}
            indexBy={'Income'}
            axisLeftLegendTitle={'Percentage of Taxfilters'}
            extraProps={{
              margin: { top: 20, right: 100, bottom: 130, left: 60 },
              label: (d: { value: string }) => `${d.value}%`,
              axisBottom: {
                tickSize: 0,
                tickRotation: -45,
                legend: 'Income in Candian dollars',
                legendPosition: 'middle',
                legendOffset: 120,
              },
              axisLeft: {
                tickSize: 0,
                legend: 'Percentage of Taxfilters',
                legendPosition: 'middle',
                legendOffset: -50,
              },
              legends: [],
            }}
          />
        </div>

        <div className={styles.chartSource}>Source: Statistics Canada</div>

        <p className={clsx(styles.bodyText, styles.last)}>
          This chart would give you a good perspective on where you stand on a national scale and help you plan where
          you want to go with respect to your income level.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'10/24/2021'} />
      </div>

      <FeedbackBox source="/income/text/short/25-nov-2021/01" />
    </>
  );
};
