import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';

export const WhoProvidesCreditScores: React.FC = () => {
  const columns = [
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'equifax',
      text: 'Equifax',
    },
    {
      dataField: 'transunion',
      text: 'TransUnion',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Who Provides Credit Scores</h1>

        <p className={styles.bodyText}>
          Equifax and TransUnion are the two leading credit score providers for the consumers in Canada. Both are US
          companies and listed in New York Stock Exchange facilitating high degree of transparency and visibility in
          their management and operations.
        </p>

        <p className={styles.bodyText}>
          Both Equifax and TransUnion are reputable and respected companies and considered as leaders in this market.
          Both company’s credits reports are generally consulted by the service providers in the financial industry,
          property management industry and the employers, while assessing the creditworthiness and character of
          individuals.
        </p>

        <p className={styles.bodyText}>
          Equifax and TransUnion use the same information provided by different financial institutions and service
          providers and follow the same scoring matrix for easier comparison. However, both credit agencies use their
          own unique methodologies to calculate their respective credit scores.
        </p>

        <h3 className={styles.paraHeading}>At a Glance</h3>

        <SimpleTable
          keyField="id"
          data={[
            {
              description: 'Year Founded',
              equifax: '1899',
              transunion: '1969',
            },
            {
              description: 'Employees in 2021',
              equifax: '13,000',
              transunion: '10,200',
            },
            {
              description: 'Countries Operated',
              equifax: '24',
              transunion: '30',
            },
            {
              description: 'Sales in Year 2021',
              equifax: 'US$ 4.9 Billion',
              transunion: 'US$ 2.7 Billion',
            },
            {
              description: 'Profit in Year 2021',
              equifax: 'US$ 744 Million',
              transunion: 'US$ 343 Million',
            },
            {
              description: 'Profit Margin in Year 2021',
              equifax: '15%',
              transunion: '13%',
            },
            {
              description: 'Company Status',
              equifax: 'Public Limited',
              transunion: 'Public Limited',
            },
            {
              description: 'Listed in',
              equifax: 'New York Stock Exchange',
              transunion: 'New York Stock Exchange',
            },
            {
              description: 'Ticker Symbol',
              equifax: 'EFX',
              transunion: 'TRU',
            },
            {
              description: 'Headquarters',
              equifax: 'Atlanta',
              transunion: 'Chicago',
            },
            {
              description: 'Parent',
              equifax: 'N/A. Independent',
              transunion: 'Goldman Sachs',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <div className={styles.chartSource}>Source: Company sources and regulatory filings.</div>

        <h3 className={styles.noteHeading}>Note</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          Credit scores between Equifax and TransUnion may differ, at times significantly. This is mainly due to the way
          they calculate these scores.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/14/2022'} />
      </div>

      <FeedbackBox source="/creditscore/impactonbottomline/text/short/14-mar-2022/01" />
    </>
  );
};
