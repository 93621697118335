import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const SelectionCriteria: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Selection Criteria</h1>

        <p className={styles.bodyText}>
          While choosing a mobile payment app, it is important to keep the following criteria in mind.
        </p>

        <ul className={styles.list}>
          <li>
            <strong>Secure: </strong>Security is the most important consideration for choosing a mobile payment app. All
            mobile payments are vulnerable to compromise and utmost caution must be taken in selecting an app offering
            the highest security.
          </li>
          <li>
            <strong>Provider: </strong>Technology of mobile payment apps are fast-changing. Regularly updating the app
            needs technological and financial resources. Therefore, it is recommended to choose from the industry
            leaders like Apple or Samsung who have deep pockets to dedicate required resources.
          </li>
          <li>
            <strong>Ease of Use: </strong>The mobile app must be easy to use so that it is convenient for everyday use.
          </li>
          <li>
            <strong>Processing Speed: </strong>Transaction processing speed can make a big difference in the user
            experience. It is important to take this factor into account while deciding.
          </li>
          <li>
            <strong>Acceptability Rage: </strong>The app must be accepted by the large Canadian banks. Otherwise,
            several apps would be needed for regular use, which is inconvenient. Some of the store&apos;s own payment
            apps like Starbucks may be considered, particularly when the app enables to pre-order.
          </li>
          <li>
            <strong>Reward Programs: </strong>Mobile app should be able to sync with your favourite reward programs.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'07/27/2022'} />
      </div>

      <FeedbackBox source="/transaction/mobile-payments/text/short/27-jul-2022/02" />
    </>
  );
};
