import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import {
  doGetRequest,
  doPostRequest,
  doPatchRequest,
  setAccessTokenToLS,
  setRefreshTokenToLS,
  redirectToLoginPage,
  clearTokens,
} from '../../helpers/api';
import {
  UserDetails,
  UserPaymentMethods,
  SignUpStep1FormInputs,
  SignUpStep2FormInputs,
  ProfileFormInputs,
  ProfileForm5Inputs,
} from './types';
import format from 'date-fns/format';
import { fromUnixTime } from 'date-fns';

interface AuthState {
  accessToken?: string;
  refreshToken?: string;
  userSignUp: {
    step1Completed: boolean;
    step2Completed: boolean;
    step3Completed: boolean;
    step4Completed: boolean;
    [key: string]: boolean;
  };
  userDetails?: UserDetails;
  userDetailsLoading: boolean;
  userPaymentMethods: UserPaymentMethods;
}

const initialState: AuthState = {
  accessToken: undefined,
  refreshToken: undefined,
  userSignUp: {
    step1Completed: false,
    step2Completed: false,
    step3Completed: false,
    step4Completed: false,
  },
  userDetails: undefined,
  userDetailsLoading: false,
  userPaymentMethods: [],
};

export const getUserDetails = createAsyncAction('GET_USER_DETAILS', async () => {
  return await doGetRequest('user/');
});

export const getUserPaymentMethods = createAsyncAction('GET_USER_PAYMENT_METHODS', async () => {
  return await doGetRequest('user/payment-methods/');
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [String(getUserDetails.pending)]: (state) => {
      return {
        ...state,
        userDetailsLoading: true,
      };
    },
    [String(getUserDetails.rejected)]: (state) => {
      return {
        ...state,
        userDetailsLoading: false,
      };
    },
    [String(getUserDetails.fulfilled)]: (state, action: PayloadAction<UserDetails>) => {
      const userDetails = action.payload;
      return {
        ...state,
        userDetails,
        userDetailsLoading: false,
      };
    },
    [String(getUserPaymentMethods.fulfilled)]: (state, action: PayloadAction<UserPaymentMethods>) => {
      const userPaymentMethods = action.payload;
      return {
        ...state,
        userPaymentMethods,
      };
    },
  },
  reducers: {
    setTokens: (state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) => {
      const { accessToken, refreshToken } = action.payload;

      setAccessTokenToLS(accessToken);
      setRefreshTokenToLS(refreshToken);

      return {
        ...state,
        accessToken,
        refreshToken,
      };
    },
    setStepCompleted: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        userSignUp: {
          ...state.userSignUp,
          [`step${action.payload}Completed`]: true,
        },
      };
    },
    resetAllSteps: (state) => {
      return {
        ...state,
        userSignUp: {
          ...state.userSignUp,
          step1Completed: false,
          step2Completed: false,
          step3Completed: false,
          step4Completed: false,
        },
      };
    },
    setUserDetailsLoading: (state) => {
      return {
        ...state,
        userDetailsLoading: true,
      };
    },
  },
});

export const createUser = async (userRequestBody: SignUpStep1FormInputs) => {
  return await doPostRequest('user/', userRequestBody, false);
};

const hasDateOfBirth = (
  userRequestBody: SignUpStep2FormInputs | ProfileFormInputs,
): userRequestBody is ProfileForm5Inputs => (userRequestBody as ProfileForm5Inputs).date_of_birth !== undefined;

export const updateUser = async (userRequestBody: SignUpStep2FormInputs | ProfileFormInputs) => {
  let updatedUserRequestBody = userRequestBody;

  if (hasDateOfBirth(userRequestBody)) {
    // TODO use transformers or something similar
    updatedUserRequestBody = {
      ...userRequestBody,
      date_of_birth: format(fromUnixTime(Date.parse(userRequestBody.date_of_birth) / 1000), 'yyyy-MM-dd'),
    };
  }
  return await doPatchRequest('user/', updatedUserRequestBody);
};

export const completeStep2OfRegistration = async (userRequestBody: SignUpStep2FormInputs | ProfileFormInputs) => {
  let updatedUserRequestBody = userRequestBody;

  if (hasDateOfBirth(userRequestBody)) {
    // TODO use transformers or something similar
    updatedUserRequestBody = {
      ...userRequestBody,
      date_of_birth: format(fromUnixTime(Date.parse(userRequestBody.date_of_birth) / 1000), 'yyyy-MM-dd'),
    };
  }
  return await doPatchRequest('user/step-3/', updatedUserRequestBody);
};

export const completeStep3OfRegistration = async (
  paymentMethodId: string,
  giftCodes: { coupon: string; promo: string },
) => {
  const requestBody: { coupon_code?: string; promo_code?: string } = {};

  if (giftCodes.coupon) {
    requestBody.coupon_code = giftCodes.coupon;
  }

  if (giftCodes.promo) {
    requestBody.promo_code = giftCodes.promo;
  }

  return await doPostRequest('user/step-2/' + paymentMethodId + '/', requestBody);
};

export const checkCouponCode = async (code: string) => {
  return await doPostRequest('user/check_coupon/', { coupon_code: code });
};

export const checkPromoCode = async (code: string) => {
  return await doPostRequest('user/check_coupon/', { promo_code: code });
};

export const verifySms = async ({
  verificationCode,
  smsCode,
  rememberDevice,
}: {
  verificationCode: string;
  smsCode: string;
  rememberDevice: boolean;
}) => {
  return await doPostRequest(
    'token/verify-sms/',
    {
      verification: verificationCode,
      code: smsCode,
      remember_device: rememberDevice,
    },
    false,
  );
};

export const resendCode = async ({ verificationCode }: { verificationCode: string }) => {
  return await doPostRequest(
    'token/resend-code/',
    {
      verification: verificationCode,
    },
    false,
  );
};

export const createUserPaymentMethod = async (paymentMethodId: string) => {
  return await doPostRequest('user/payment-methods/' + paymentMethodId + '/create/', {});
};

export const setDefaultUserPaymentMethod = async (id: string) => {
  return await doPostRequest('user/payment-methods/' + id + '/set_as_default/', {});
};

export const signOut = () => {
  clearTokens();
  redirectToLoginPage();
};

export const { setTokens, setStepCompleted, resetAllSteps, setUserDetailsLoading } = authSlice.actions;

export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const isPreviousStepCompleted = (currentStepId: number) => (state: RootState) =>
  state.auth.userSignUp[`step${currentStepId - 1}Completed`] === true;
export const selectUserDetails = (state: RootState) => state.auth.userDetails;
export const selectUserDetailsLoading = (state: RootState) => state.auth.userDetailsLoading;
export const selectUserPaymentMethods = (state: RootState) => state.auth.userPaymentMethods;
export const selectDefaultPaymentMethod = (state: RootState) => {
  const userPaymentMethods = selectUserPaymentMethods(state);

  if (userPaymentMethods.length === 0) {
    return null;
  }

  if (!userPaymentMethods[0] || !userPaymentMethods[0].default_payment_method) {
    return null;
  }

  const defaultUserPaymentMethod = userPaymentMethods[0].default_payment_method;
  const foundUserPaymentMethod = userPaymentMethods.find((pm) => pm.card_id === defaultUserPaymentMethod);

  if (!foundUserPaymentMethod) {
    return null;
  }
  return foundUserPaymentMethod;
};

export default authSlice.reducer;
