import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const RisksOfMobilePayments: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Risks of Mobile Payments</h1>

        <h3 className={styles.paraHeading}>1. Losing Phone</h3>
        <p className={styles.bodyText}>
          Losing your phone is like losing your credit card. The person who finds your phone can do any online or
          in-person transaction just as you would.
        </p>
        <p className={styles.bodyText}>
          It is very important to use phone&apos;s built-in protections and setup Two Factor Authentication to unlock
          your phone, i.e., a PIN along with a biometric method like fingerprint or facial recognition.
        </p>

        <h3 className={styles.paraHeading}>2. Hackers</h3>
        <p className={styles.bodyText}>
          Your credit card number is stored in digital wallet securely by encrypting it with an algorithm. Randomly
          generated payment tokens are also used to disguise your card information from merchants while making
          purchases. Overall, the risk of a hacker to access your account numbers is extremely slim.
        </p>
        <p className={styles.bodyText}>
          This risk of compromise significantly goes up, if you add card details to your digital wallet using an
          unsecured public Wi-Fi network. Hackers usually lurk on such unsecured public Wi-Fi networks and can re-create
          digital wallet&apos;s registration system, for which you were required to enter your card&apos;s data.
        </p>
        <p className={styles.bodyText}>
          As a remedy, always add or manage your digital wallet information from the security of your home, using your
          own password-protected Wi-Fi network.
        </p>

        <h3 className={styles.paraHeading}>3. Malware</h3>
        <p className={styles.bodyText}>
          Hackers use malware to remotely access computers and smartphones to steal passwords and private information.
          Malware infection is triggered by the user clicking on a link sent by a malicious party.
        </p>
        <p className={styles.bodyText}>
          Computers are generally more vulnerable than cellphones, but mobile malware continues to be a growing threat.
          iPhones are more secure from malware due to Apple&apos;s high-quality standards. But no one is fully immune.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Be careful. Don&apos;t click on links in suspicious ads, emails or text messages.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/27/2022'} />
      </div>

      <FeedbackBox source="/transaction/mobile-payments/text/short/27-jul-2022/05" />
    </>
  );
};
