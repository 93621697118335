import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PuttingOffThings: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Putting Things Off</h1>

        <p className={styles.bodyText}>
          Putting things off is very common. "Will do it tomorrow", we comfort our inner selves.
        </p>
        <p className={styles.bodyText}>
          Who doesn&apos;t know that doing the right thing at the right time is the most critical factor contributing to
          any success? Why do we do it then?
        </p>
        <p className={styles.bodyText}>
          Because, invariably our future selves are more virtuous. We tend to think we will be perfect tomorrow and it
          is fine to be a little lazy today.
        </p>
        <p className={styles.bodyText}>
          Putting things off affects our progress in many ways. One of those is wasting time on thinking about the same
          pending thing again and again until it is fully complete. This is an extremely inefficient use of time and
          energy, building up stress.
        </p>
        <p className={styles.bodyText}>
          As soon as we complete a task or upload the task on a reliable system to deal with it at the right time, our
          brain stops working on it.
        </p>
        <p className={styles.bodyText}>
          On the contrary, according to Psychologists, our mind will keep on working on anything that has yet be
          decided. It occupies and operates in a certain space in our brain just like a computer, which obviously cannot
          be used for anything else. The short-term part the brain, similar to a RAM of a computer, actively keeps on
          running all the incomplete, unresolved and undecided items.
        </p>
        <p className={styles.bodyText}>
          As we continue to stack up more and more unresolved and pending tasks, our brain keeps on getting more
          occupied and eventually clogged, keeping little or no space for critical or creative thinking.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Even our brain has a limit of how much unresolved items it can hold before it becomes disfunctional – at least
          partly. Not surprisingly, we see so many people lacking focus or concentration as they are burdened by their
          own mental overload.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'07/28/2021'} />
      </div>

      <FeedbackBox source="/calendar/time-management/text/short/28-jul-2021/02" />
    </>
  );
};
