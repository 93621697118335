import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';

import { RootState } from '../../redux/';
import { SavingItems } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';
import { formatISO } from 'date-fns';
import { SavingFormInputs } from './types';

export interface SavingState {
  list: SavingItems;
  listLoading: boolean;
}

const initialState: SavingState = {
  list: [],
  listLoading: false,
};

export const getSavingList = createAsyncAction('GET_Saving_LIST', async () => {
  return await doGetRequest('savings/');
});

export const deleteSaving = async (savingId: number) => await doDeleteRequest('savings/' + savingId + '/');

export const createSaving = (savingRequestBody: SavingFormInputs) => {
  const updatedRequestBody = {
    ...savingRequestBody,
    date: formatISO(Date.parse(savingRequestBody.date)),
  };
  return doPostRequest('savings/', updatedRequestBody);
};

export const updateSaving = (savingId: number, savingRequestBody: SavingFormInputs) => {
  const updatedRequestBody = {
    ...savingRequestBody,
    date: formatISO(Date.parse(savingRequestBody.date)),
  };
  return doPatchRequest('savings/' + savingId + '/', updatedRequestBody);
};

export const savingSlice = createSlice({
  name: 'saving',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getSavingList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getSavingList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getSavingList.fulfilled)]: (state, action: PayloadAction<SavingItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

export const selectSavingList = (state: RootState) => state.saving.list;
export const selectListLoading = (state: RootState) => state.saving.listLoading;
export const selectSavingById = (savingId: number) => (state: RootState) =>
  state.saving.list.find((savingItem) => {
    return savingItem.id === savingId;
  });

export const selectSavingItemsByYear = (year: number) => (state: RootState) => {
  const foundSavingItems = state.saving.list.filter((savingItem) => {
    return parseInt(savingItem.date.substring(0, 4), 10) === year;
  });

  if (!foundSavingItems) {
    return [];
  }

  return foundSavingItems;
};

export default savingSlice.reducer;
