import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DisposableIncome: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Disposable Income</h1>

        <h2 className={styles.paraHeading}>What is Disposable Income</h2>

        <p className={styles.bodyText}>
          Disposable income is the remaining balance of your income that you have available after paying your income
          taxes.
        </p>

        <p className={styles.bodyText}>It is also called Net Pay or Disposable Personal Income (DPI).</p>

        <p className={styles.bodyText}>
          The indirect taxes like sales tax, payroll taxes etc. are not deducted for calculating the disposable income.
        </p>

        <h2 className={styles.paraHeading}>Formula</h2>

        <p className={styles.bodyText}>
          Disposable Income = Total Gross Personal Income – Direct Income Taxes (Federal and Provincial).
        </p>

        <p className={styles.bodyText}>
          <strong>Example</strong>
        </p>

        <p className={styles.bodyText}>
          Your Total Gross Annual Income is $100,000 and Income Tax for the year is $25,000.
        </p>

        <p className={styles.bodyText}>
          Your <strong>Disposable Income</strong> = $100,000 - $25,000 = $75,000.
        </p>

        <h2 className={styles.paraHeading}>Why is it Important</h2>

        <p className={styles.bodyText}>
          Disposable Income indicates how much money you have available as income to take care of your necessities,
          non-essential spending and saving. This is the real indicator of your available financial resources, not your
          Gross Income.
        </p>

        <p className={styles.bodyText}>Low Disposable Income will negatively impact your saving rate.</p>

        <p className={styles.bodyText}>
          Income Tax is your largest expense item, and it is important to pay close attention to how much tax you are
          paying and exploring if there are any eligible tax deductions that you haven’t availed.
        </p>

        <p className={styles.bodyText}>
          Your goal should be to maximize your Disposable Income, so that you have more money available for the
          necessities and the remaining balance for your savings and non-essential spending.
        </p>

        <h2 className={styles.noteHeading}>Why is it Important</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          Disposable Income is used for calculating your Debt-to-Income Ratio.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/31/2022'} />
      </div>

      <FeedbackBox source="/income/the-basics/text/short/31-mar-2022/01" />
    </>
  );
};
