import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';

import styles from './Questionnaire.module.scss';
import { Answer, Question } from './types';
import { QuestionFormField } from './QuestionFormField';
import { QuestionForm } from './QuestionForm';
import {
  // async midlewares
  getQuestions,
  saveQuestions,
  saveQuestion,
  // state returns
  selectQuestions,
  selectLoading,
} from './questionsSlice';
import Loader from '../../components/Common/Loader';

export const Questionnaire = () => {
  const dispatch = useDispatch();
  const [allQuestions, setAllQuestions] = useState<Array<Question>>([]);
  const [questionToEdit, setQuestionToEdit] = useState<Question | undefined>();
  const [showFullForm, setShowFullForm] = useState<boolean>(true);

  const getQuestionListAction = useCallback(() => dispatch(getQuestions()), [dispatch]);

  useEffect(() => {
    getQuestionListAction();
  }, [getQuestionListAction]);

  const questions = useSelector(selectQuestions);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    const hasQuestionAnswered = Boolean(
      questions?.find((question) => question?.answers?.find((answer) => answer.is_selected)),
    );
    setShowFullForm(!hasQuestionAnswered);
    setAllQuestions(questions ?? []);
  }, [questions]);

  const saveQuestionsAction = useCallback(
    (questionAnswers: Array<{ answer?: string | number; inputted_answer?: string }>) =>
      dispatch(saveQuestions(questionAnswers)),
    [dispatch],
  );

  const saveQuestionAction = useCallback(
    (questionId: string | number, answer: { answer?: string | number; inputted_answer?: string }) =>
      dispatch(saveQuestion(questionId, answer)),
    [dispatch],
  );

  const onFormSubmit = (answers: Record<number, Answer>) => {
    const questionAnswers: Array<{ answer?: string | number; inputted_answer?: string }> = [];

    let questions = [...allQuestions];

    let questionIdToSave = undefined;
    let answerToSave = undefined;

    for (const questionId in answers) {
      questionIdToSave = questionId;
      const answer = answers[questionId];
      if (answer) {
        const ansPayload = answer?.has_input
          ? {
              answer: answer.pk,
              inputted_answer: answer?.inputted_answer,
            }
          : { answer: answer.pk };

        questionAnswers.push(ansPayload);
        answerToSave = ansPayload;

        questions = questions.map((question) =>
          question.pk.toString() === questionId.toString()
            ? {
                ...question,
                answers: question.answers.map((a) =>
                  a.pk === answer.pk ? { ...answer, is_selected: true } : { ...a, is_selected: false },
                ),
              }
            : { ...question },
        );
      }
    }
    setAllQuestions(questions);
    showFullForm
      ? saveQuestionsAction(questionAnswers)
      : questionIdToSave && answerToSave && saveQuestionAction(questionIdToSave, answerToSave);
    setShowFullForm(false);
    setQuestionToEdit(undefined);
  };

  return (
    <Container>
      <h1 className={styles.heading}>Investment Profile</h1>

      {loading ? <Loader /> : <></>}

      <div className={styles.questionBlock}>
        {showFullForm ? (
          <>
            <QuestionForm questions={allQuestions ?? []} onFormSubmit={onFormSubmit} />
          </>
        ) : (
          <>
            {allQuestions?.map((question, idx) => {
              const isEditing = questionToEdit?.pk === question.pk;
              const selectedAnswer = question?.answers.find((a) => a.is_selected);

              return (
                <div
                  className={idx + 1 === allQuestions?.length ? styles.lastQuestionContainer : styles.questionContainer}
                  key={question.pk}
                >
                  <div className={styles.questionTitle}>{question.question}</div>
                  {isEditing ? (
                    <QuestionFormField
                      questions={questionToEdit ? [questionToEdit] : []}
                      key={question.pk}
                      onFormSubmit={onFormSubmit}
                    />
                  ) : (
                    <div className={styles.questionAnswerContainer}>
                      <div className={styles.questionCircle}></div>
                      <div className={styles.questionAnswer}>
                        {selectedAnswer?.has_input ? selectedAnswer?.inputted_answer : selectedAnswer?.answer}
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className={styles.editButton + ' btn'}
                    onClick={() => {
                      !isEditing ? setQuestionToEdit(question) : setQuestionToEdit(undefined);
                    }}
                  >
                    {isEditing ? 'Close' : 'Edit'}
                  </button>
                </div>
              );
            })}
          </>
        )}
      </div>
    </Container>
  );
};
