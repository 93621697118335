import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const OverdraftProtection: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Overdraft Protection </h1>

        <h3 className={styles.paraHeading}>What is Overdraft Protection</h3>
        <p className={styles.bodyText}>
          Overdraft Protection covers any shortfall in the chequeing account balance up to an approved limit while
          processing a transaction.
        </p>

        <p className={styles.bodyText}>
          Indeed, your bank will lend you money and complete the transaction even if you don’t have enough money
          available for the transaction in your bank account.
        </p>

        <p className={styles.bodyText}>
          Overdraft Protection is an optional add-on feature to the chequeing account. You need to apply for it
          separately and obtain approval. Overdraft Protection coverage is not unlimited. Instead, you will enjoy
          Overdraft Protection only up to the pre-approved limit.
        </p>

        <h3 className={styles.paraHeading}>How Does it Work</h3>
        <p className={styles.bodyText}>
          If you write a cheque, issue pre-authorized payments, or withdraw more than the balance you have in your
          chequeing account, then the account balance goes to negative after the transaction is processed.
        </p>
        <p className={styles.bodyText}>
          You can repay this overdraft outstanding balance by making a deposit to that account. Your deposits will be
          automatically applied to repay the overdraft balance.
        </p>

        <h3 className={styles.paraHeading}>Types of Overdraft Protection Plans</h3>
        <p className={styles.bodyText}>There are two types of Overdraft Protection Plans:</p>
        <ul className={styles.list}>
          <li>
            <strong>Monthly Plan: </strong>Pay the monthly fee irrespective of any overdraft during the month.
          </li>
          <li>
            <strong>Pay as You Go Plan: </strong>One-time fee charged every time an overdraft occurs in the account.
          </li>
        </ul>
        <p className={styles.bodyText}>
          Both plans charge an interest on the overdraft balance at a much higher rate (19%-25%) than conventional
          products line of credit (8%+) or credit card (13%+).
        </p>

        <h3 className={styles.paraHeading}>Benefits</h3>

        <p className={styles.bodyText}>Overdraft Protection is a hustle-free tool to manage ongoing expenses:</p>

        <ul className={styles.list}>
          <li>Low fees. Generally, around $5 for monthly plans and $5 per overdraft for the pay as you go plans.</li>
          <li>Avoid late payment fees for the bills or other routine payments.</li>
          <li>Avoid declined transactions and Non-Sufficient Fees (NSF Fees) (around $45 to $48 per transaction).</li>
          <li>Avoid declined transaction charges claimed by some merchants (around $48 per transaction).</li>
          <li>Keep the credit score unaffected despite insufficient funds.</li>
          <li>
            Manage finances better and enjoy uninterrupted transactions in case of oversight, emergencies and occasional
            delays or mismatch in cashflow.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Conditions</h3>
        <ul className={styles.list}>
          <li>
            Most banks would need you to pay back the overdraft amount within certain days, which generally ranges
            between 30 to 90 days.
          </li>
          <li>
            If you are on a Pay as You Go plan, then the fees will be charged each day the overdraft balance will remain
            outstanding.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/25/2022'} />
      </div>

      <FeedbackBox source="/transaction/banktransactions/text/short/25-mar-2022/02" />
    </>
  );
};
