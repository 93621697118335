import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useHistory, Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import { Form, Input, SubmitButton } from '../../components/Form';
import { ForgotPasswordFormInputs } from './types';
import { requestPasswordReset, BadRequestError } from '../../helpers/api';

import styles from './ForgotPassword.module.scss';

const schemaResolver = yupResolver<ForgotPasswordFormInputs>(
  yup.object().shape({
    email: yup.string().email('Please enter a valid Email').required('Please enter Email'),
  }),
);

export const ForgotPassword: React.FC = () => {
  const [formError, setFormError] = useState<string>('');
  const history = useHistory();

  const onSubmit = async (data: ForgotPasswordFormInputs) => {
    try {
      await requestPasswordReset(data);
      history.push('/user/forgot-password-sent');
    } catch (err) {
      if (err instanceof BadRequestError) {
        if (err.response.email && Array.isArray(err.response.email)) {
          setFormError(err.response.email[0]);
        }
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Container fluid className="px-0 position-relative">
      <h1 className={styles.title}>
        Please enter the information
        <br />
        to find your
        <br />
        Arctic Rich password
      </h1>

      <React.Fragment>
        {formError.length ? <div className={styles.formErrorBlock + ' invalid-feedback'}>{formError}</div> : null}
      </React.Fragment>
      <Form onSubmit={onSubmit} resolver={schemaResolver}>
        <Input
          name="email"
          placeholder="Email Address"
          label="Email Address"
          className={styles.inputBlock}
          labelClassName={styles.label}
        />
        <SubmitButton value="Continue" className={styles.submitButtonBlock} type="submit" />
        <div className={styles.linksBlock}>
          Don’t have an account?{' '}
          <Link to="/user/sign-up/account-info" className={styles.link}>
            Sign Up
          </Link>
          <div className={styles.policyBlock}>
            <a href="https://arcticrich.com/privacypolicy.html" className={styles.policyLink}>
              Privacy
            </a>{' '}
            |{' '}
            <a href="https://arcticrich.com/termsofuse.html" className={styles.policyLink}>
              Terms
            </a>
          </div>
        </div>
      </Form>
    </Container>
  );
};
