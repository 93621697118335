import React from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';

import './App.scss';

import {
  SignUpStep1,
  SignUpStep2,
  SignUpStep3,
  SignUpStep4,
  Login,
  VerifyEmail,
  Profile,
  ForgotPassword,
  ForgotPasswordSent,
  PasswordReset,
  PasswordResetSent,
  TwoFA,
} from './features/auth';
import { AccountList, EditAccountItem } from './features/account';
import { Questionnaire } from './features/invenstmentProfile';
import { InvestmentList, AddInvestment, EditInvestment } from './features/investment';
import { IncomeList, AddIncome, EditIncome } from './features/income';
import { SavingList, AddSaving, EditSaving } from './features/saving';
import { AddInsurance, EditInsurance, InsuranceList } from './features/insurance';
import { TransactionList } from './features/transaction';
import { DebtList, EditDebt } from './features/debt';
import { GoalList, AddGoal, EditGoal, AddMilestone, EditMilestone } from './features/goal';
import { AutoList, EditAuto, AddAuto, AddAutoMaintenance, EditAutoMaintenance } from './features/auto';
import { HomeList, EditHome, AddHome, AddMaintenance, EditMaintenance } from './features/home';
import { staticPagesRoutes, staticPagesRoutesPaths } from './staticPages';
import { BillList, AddBill, EditBill, PublicBillInfo } from './features/bill';
import {
  CreditScoreList,
  AddCreditScore,
  EditCreditScore,
  AddCreditFactor,
  EditCreditFactor,
} from './features/creditScore';
import { TaxList, EditTax, AddTax, EditTaxDeductible, AddTaxDeductible } from './features/tax';
import { CalendarList } from './features/calendar';
import { Overview } from './features/overview';
import { AppLocationState } from './types';
import {
  LoginLayout,
  SignUpLayout,
  RegularLayout,
  ProfileLayout,
  ForgotPasswordLayout,
  StaticPageLayout,
  BillLayout,
} from './components/Layout';

import { AlertList } from './features/commCenter';
import Track from './Track';

function App(): JSX.Element {
  const location = useLocation<AppLocationState>();
  const background = location.state && location.state.background;

  return (
    <Track>
      <Switch>
        <Route path={['/bills']}>
          <BillLayout>
            <Switch>
              <Route exact path="/bills/payment-info">
                <PublicBillInfo />
              </Route>
            </Switch>
          </BillLayout>
        </Route>
        <Route path={['/user/sign-up/:step']}>
          <SignUpLayout>
            <Switch>
              <Route exact path="/user/sign-up/account-info">
                <SignUpStep1 />
              </Route>
              <Route exact path="/user/sign-up/address-info">
                <SignUpStep2 />
              </Route>
              <Route exact path="/user/sign-up/subscription-info">
                <SignUpStep3 />
              </Route>
              <Route exact path="/user/sign-up/completed">
                <SignUpStep4 />
              </Route>
            </Switch>
          </SignUpLayout>
        </Route>
        <Route path={['/user/login']}>
          <LoginLayout>
            <Switch>
              <Route exact path="/user/login">
                <Login />
              </Route>
            </Switch>
          </LoginLayout>
        </Route>
        <Route
          path={[
            '/user/forgot-password',
            '/user/forgot-password-sent',
            '/user/password-reset',
            '/user/reset-password-confirmation',
            '/user/two-fa/:verificationCode',
            '/user/verify-email',
          ]}
        >
          <ForgotPasswordLayout>
            <Switch>
              <Route exact path="/user/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/user/forgot-password-sent">
                <ForgotPasswordSent />
              </Route>
              <Route exact path="/user/password-reset">
                <PasswordReset />
              </Route>
              <Route exact path="/user/reset-password-confirmation">
                <PasswordResetSent />
              </Route>
              <Route exact path="/user/two-fa/:verificationCode">
                <TwoFA />
              </Route>
              <Route exact path="/user/verify-email/">
                <VerifyEmail />
              </Route>
            </Switch>
          </ForgotPasswordLayout>
        </Route>
        <Route path={['/user/profile', '/user/investment-profile']}>
          <ProfileLayout>
            <Switch>
              <Route exact path="/user/profile">
                <Profile />
              </Route>
              <Route exact path="/user/investment-profile">
                <Questionnaire />
              </Route>
            </Switch>
          </ProfileLayout>
        </Route>
        <Route path={staticPagesRoutesPaths}>
          <StaticPageLayout>{staticPagesRoutes}</StaticPageLayout>
        </Route>

        <Route>
          <RegularLayout>
            <Switch location={background || location}>
              <Route exact path="/">
                <Redirect to="/goals/overview" />
              </Route>
              <Route path="/save/saving">
                <SavingList />
              </Route>
              <Route path="/save/investment">
                <InvestmentList />
              </Route>
              <Route path="/save/insurance">
                <InsuranceList />
              </Route>
              <Route path="/accounts/balance">
                <AccountList />
              </Route>
              <Route path="/accounts/transaction">
                <TransactionList />
              </Route>
              <Route path="/loan/debt">
                <DebtList />
              </Route>
              <Route path="/goals/goal">
                <GoalList />
              </Route>
              <Route path="/income/income">
                <IncomeList />
              </Route>
              <Route path="/expense/bill">
                <BillList />
              </Route>
              <Route path="/asset/auto">
                <AutoList />
              </Route>
              <Route path="/asset/home">
                <HomeList />
              </Route>
              <Route path="/income/tax">
                <TaxList />
              </Route>
              <Route path="/loan/credit-score/">
                <CreditScoreList />
              </Route>
              <Route path="/goals/calendar">
                <CalendarList />
              </Route>
              <Route path="/goals/overview">
                <Overview />
              </Route>
              <Route path="/comm-center">
                <AlertList />
              </Route>
            </Switch>
            {background && (
              <Switch>
                <Route path="/save/insurance/add">
                  <AddInsurance />
                </Route>
                <Route path="/save/insurance/edit/:insuranceId">
                  <EditInsurance />
                </Route>
                <Route path="/save/saving/add">
                  <AddSaving />
                </Route>
                <Route path="/save/saving/edit/:savingId">
                  <EditSaving />
                </Route>
                <Route path="/save/investment/add/:investmentType">
                  <AddInvestment />
                </Route>
                <Route path="/save/investment/edit/:investmentId">
                  <EditInvestment />
                </Route>
                <Route path="/accounts/balance/edit/:accountItemId">
                  <EditAccountItem />
                </Route>
                <Route path="/loan/debt/edit/:debtId">
                  <EditDebt />
                </Route>
                <Route path="/goals/goal/add/goal">
                  <AddGoal />
                </Route>
                <Route path="/goals/goal/edit/goal/:goalId">
                  <EditGoal />
                </Route>
                <Route path="/asset/home/add/home">
                  <AddHome />
                </Route>
                <Route path="/asset/home/add/maintenance">
                  <AddMaintenance />
                </Route>
                <Route path="/asset/home/edit/home/:homeId">
                  <EditHome />
                </Route>
                <Route path="/income/tax/add/tax/">
                  <AddTax />
                </Route>
                <Route path="/income/tax/add/tax-deductible/">
                  <AddTaxDeductible />
                </Route>
                <Route path="/income/tax/edit/tax/:taxId">
                  <EditTax />
                </Route>
                <Route path="/income/tax/edit/tax-deductible/:deductibleId">
                  <EditTaxDeductible />
                </Route>
                <Route path="/asset/home/edit/maintenance/:maintenanceId">
                  <EditMaintenance />
                </Route>
                <Route path="/asset/auto/add/auto">
                  <AddAuto />
                </Route>
                <Route path="/asset/auto/add/maintenance">
                  <AddAutoMaintenance />
                </Route>
                <Route path="/asset/auto/edit/auto/:autoId">
                  <EditAuto />
                </Route>
                <Route path="/asset/auto/edit/maintenance/:maintenanceId">
                  <EditAutoMaintenance />
                </Route>
                <Route path="/goals/goal/add/milestone">
                  <AddMilestone />
                </Route>
                <Route path="/goals/goal/edit/milestone/:milestoneId">
                  <EditMilestone />
                </Route>
                <Route path="/income/income/add">
                  <AddIncome />
                </Route>
                <Route path="/income/income/edit/:incomeId">
                  <EditIncome />
                </Route>
                <Route path="/expense/bill/add">
                  <AddBill />
                </Route>
                <Route path="/expense/bill/edit/:billId">
                  <EditBill />
                </Route>
                <Route path="/loan/credit-score/add/credit-score">
                  <AddCreditScore />
                </Route>
                <Route path="/loan/credit-score/edit/credit-score/:creditScoreId">
                  <EditCreditScore />
                </Route>
                <Route path="/loan/credit-score/add/credit-factor">
                  <AddCreditFactor />
                </Route>
                <Route path="/loan/credit-score/edit/credit-factor/:creditFactorId">
                  <EditCreditFactor />
                </Route>
              </Switch>
            )}
          </RegularLayout>
        </Route>
      </Switch>
    </Track>
  );
}

export default App;
