import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { SimpleTable } from './SimpleTable';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PhoneFamily: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: '',
    },
    {
      dataField: 'o1',
      text: '',
    },
    {
      dataField: 'o2',
      text: '',
    },
    {
      dataField: 'o3',
      text: '',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Phone Family</h1>

        <p className={styles.bodyText}>
          In Canada, the four major phone companies operate in different brand names in addition to their respective
          flagship brands. These flagship brands generally serve the premium segment of the market, while other brands
          target to cover the mid and bottom-end of market.
        </p>
        <p className={styles.bodyText}>
          Most of the brands became part of the family through series of acquisitions in the past. Many of these were
          new entrants in the market, launched under the government initiative to promote competition in the market.
          However, Telecom is a capital-intensive business and it is difficult for any new entrant to succeed in this
          sector without a large client base while trying to compete on price against these giant incumbents.
        </p>
        <p className={styles.bodyText}>
          It is important for the consumers to understand that apart from the name, there is not must difference in the
          quality of service from technical perspective although the offering price is significantly different (unless
          something is explicitly mentioned like coverage area and download speed).
        </p>
        <p className={styles.bodyText}>
          Although these customer facing brands or company names are different, in the backend all these companies use
          the same technology and equipment to serve their clients to reduce costs. Essentially, they are charging
          different price points for the same service to capture the top, mid and bottom part of the market. Gaining
          more customers is critical in this business to pay for the expensive equipment and keep up with the changing
          technology.
        </p>
        <p className={styles.bodyText}>
          Therefore, today&apos;s customers need only two things to focus on - technical specifications and pricing.
          would be to get the highest possible technical specifications at the lowest possible price. Rest all are
          marketing gimmicks, and we should not fall for those traps.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <SimpleTable
          keyField="id"
          data={[
            {
              o0: 'Parent',
              o1: 'BCE Inc.',
              o2: 'Rogers Communications.',
              o3: 'Telus Corporations.',
              o4: 'Shaw Communications',
            },
            { o0: 'Premium', o1: 'Bell Mobility', o2: 'Rogers Wireless', o3: 'Telus Mobility', o4: 'Shaw Mobile' },
            { o0: 'Mid-Market', o1: '1Bell MTS', o2: 'Fido Mobile', o3: 'Koodo Mobile' },
            { o0: 'Mid-Market', o1: 'Virgin Mobile', o2: '', o3: '' },
            { o0: 'Discount', o1: 'Lucky Mobile', o2: 'ChatrMobile', o3: 'Public Mobile', o4: 'Freedom Mobile' },
            { o0: '', o1: '', o2: 'Cityfone', o3: '' },
            { o0: '', o1: '', o2: 'Primus Wireless', o3: '' },
            { o0: '', o1: '', o2: 'Zoomer Wireless', o3: '' },
            { o0: '', o1: '', o2: 'SimplyConnect', o3: '' },
          ]}
          columns={columns}
          bootstrap4
        />

        <PublishedDate date={'09/12/2021'} />
      </div>

      <FeedbackBox source="/expenses/save-on-phone-bills/text/short/12-sep-2021/0" />
    </>
  );
};
