import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import { LineChart } from './LineChart';

export const DisposableIncomeTrend: React.FC = () => {
  const lineData = [
    {
      id: 'Real Estate',
      data: [
        {
          x: '1990',
          y: 22917,
        },
        {
          x: '1991',
          y: 22780,
        },
        {
          x: '1992',
          y: 22886,
        },
        {
          x: '1993',
          y: 23091,
        },
        {
          x: '1994',
          y: 22943,
        },
        {
          x: '1995',
          y: 22746,
        },
        {
          x: '1996',
          y: 22593,
        },
        {
          x: '1997',
          y: 23014,
        },
        {
          x: '1998',
          y: 23953,
        },
        {
          x: '1999',
          y: 24564,
        },
        {
          x: '2000',
          y: 24753,
        },
        {
          x: '2001',
          y: 25577,
        },
        {
          x: '2002',
          y: 26102,
        },
        {
          x: '2003',
          y: 25846,
        },
        {
          x: '2004',
          y: 25991,
        },
        {
          x: '2005',
          y: 25912,
        },
        {
          x: '2006',
          y: 25836,
        },
        {
          x: '2007',
          y: 27130,
        },
        {
          x: '2008',
          y: 27275,
        },
        {
          x: '2009',
          y: 28314,
        },
        {
          x: '2010',
          y: 28557,
        },
        {
          x: '2011',
          y: 28392,
        },
        {
          x: '2012',
          y: 28883,
        },
        {
          x: '2013',
          y: 29369,
        },
        {
          x: '2014',
          y: 29466,
        },
        {
          x: '2015',
          y: 30804,
        },
        {
          x: '2016',
          y: 30332,
        },
        {
          x: '2017',
          y: 30675,
        },
        {
          x: '2018',
          y: 30664,
        },
        {
          x: '2019',
          y: 31200,
        },
        {
          x: '2020',
          y: 33352,
        },
        {
          x: '2021',
          y: 33180,
        },
        {
          x: '2022',
          y: 33279,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Disposable Income Trend</h1>

        <p className={styles.bodyText}>Historical Disposable Income trend in Canada are appended below.</p>

        <div className={styles.chartWrapper}>
          <h4 className={styles.chartHeader}>Disposable Income Trend</h4>
          <h5 className={styles.chartSubHeader}>Figures in Canadian Dollars</h5>

          <div className={styles.chartContainer}>
            <LineChart
              data={lineData}
              xLegendLabel="Year"
              yLegendLabel="Canadian Dollar"
              xToolTipLabel="Year"
              yToolTipLabel="Canadian Dollar"
              extraProps={{
                margin: { top: 20, right: 30, bottom: 70, left: 70 },
                axisBottom: {
                  tickRotation: -45,
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  legend: 'Year',
                  legendOffset: 60,
                  legendPosition: 'middle',
                },
                axisLeft: {
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -60,
                  legendPosition: 'middle',
                  format: ',',
                },
                legends: [],
                yFormat: ',',
              }}
            />
          </div>
          <p className={styles.chartSource}>Source: Statistics Canada</p>
        </div>

        <p className={clsx(styles.bodyText, styles.last)}>
          Disposable Income equals Gross Income less Income Taxes.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/31/2022'} />
      </div>

      <FeedbackBox source="/income/thebasics/text/short/31-mar-2022/02" />
    </>
  );
};
