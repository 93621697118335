import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WeeklyReview: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Weekly Review</h1>

        <p className={styles.bodyText}>Weekly Review is considered as a critical success factor.</p>

        <p className={styles.bodyText}>
          All the high priority goals, milestones, tasks and financial commitments must be reviewed on a weekly basis.
        </p>

        <p className={styles.bodyText}>It is important to keep the actions to be taken fresh in mind.</p>

        <p className={styles.bodyText}>
          During a busy week while dealing with countless distraction and firefighting, it is very easy slip from mind
          the commitments of the day. Weekly recap and planning have been proven to be every effective to keep it active
          in mind despite other day to day priorities.
        </p>

        <p className={styles.bodyText}>
          Reserve a fixed time during the week, preferably in the weekend evenings or early mornings. This time is
          exclusively personal, so turn off the TV, Computer and Phone.
        </p>

        <p className={styles.bodyText}>
          During this time, review last week&apos;s performance as well as upcoming week’s tasks and financial
          commitments. It is quiet moment to reflect on what went right and what else could be done. Any new leads that
          surfaced during the week should also be added. Items which are completed to be checked off.
        </p>

        <p className={styles.bodyText}>
          Review every single commitment in your last week&apos;s calendar. Have those been met? Any follow-up or
          rectifying action needed? Also, review next 1-2 week&apos;s commitments. Are you on track? Do you have all the
          resources you need?
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Step back and review from a high-level perspective, if the overall system or process is working as intended.
          Compare the actual progress with the target. If progress is not satisfactory, then think through and note down
          the next set of actions needed to get back on track.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'07/27/2021'} />
      </div>

      <FeedbackBox source="/calendar/time-management/text/short/27-jul-2021/02" />
    </>
  );
};
