import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const InterestOnStudentLoan: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Interest on Student Loan</h1>

        <p className={styles.bodyText}>
          You are eligible to claim interest paid on qualifying loans for post-secondary education on your tax return.
        </p>

        <p className={styles.bodyText}>
          Let&apos;s understand what the non-qualifying loans / interests are (i.e., you cannot claim interest in your
          tax return):
        </p>

        <ul className={styles.list}>
          <li>
            Lines of credits or personal loans even if you use the loan proceeds to pay for post-secondary education.
            Only the interest paid on loans taken under the Canada Student Loans Act, the Canada Student Financial
            Assistance Act, Apprentice Loans Act or similar provincial or territorial government laws qualify.
          </li>
          <li>Refundable tax credit.</li>
          <li>
            If you negotiated your student loan to make it combined with other loans, then the interest on such loan
            does not qualify to claim.
          </li>
        </ul>

        <p className={styles.bodyText}>
          Only <strong>you</strong> can claim the interest (whether you or any other person related to you paid it).
        </p>

        <p className={styles.bodyText}>
          Only unclaimed interest portion will be considered. If no tax is payable for the year, then it is better not
          to claim in that year and carry it forward. You can use the unclaimed portion in any of the next 5 years to
          reduce your payable taxes.
        </p>

        <p className={styles.bodyText}>
          Keep the records of claimable interest on loan in case CRA wants to see the supporting documents during a
          subsequent audit (if you are filing the tax return electronically).
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'02/10/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses/text/short/10-feb-2022/02" />
    </>
  );
};
