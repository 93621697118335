import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { capitalizeFirstLetterOnly } from '../helpers/tranformers';

export interface BarChartProps {
  data: Record<string, unknown>[];
  keys: string[];
  indexBy: string;
  axisLeftLegendTitle?: string;
  extraProps?: Record<string, unknown>;
  capitalizeLabels?: 'capitalizeFirstLetterOnly' | undefined;
  formatValue?: (value: string | number) => string;
}

export const BarChart: React.FC<BarChartProps> = ({
  data,
  keys,
  indexBy,
  axisLeftLegendTitle,
  extraProps,
  capitalizeLabels,
  formatValue,
}) => (
  <ResponsiveBar
    data={data.map((item) => ({
      ...item,
      [indexBy]:
        typeof item[indexBy] === 'string' && capitalizeLabels
          ? capitalizeFirstLetterOnly(item[indexBy] as string)
          : item[indexBy],
    }))}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 20, right: 100, bottom: 50, left: 20 }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    // colors={{ scheme: 'nivo' }}
    colors={() => '#ff0000'}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.45)',
        size: 3,
        padding: 1,
        stagger: true,
      },
    ]}
    fill={keys.map((k) => ({
      match: { id: k },
      id: 'dots',
    }))}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: indexBy,
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend:
        axisLeftLegendTitle && capitalizeLabels ? capitalizeFirstLetterOnly(axisLeftLegendTitle) : axisLeftLegendTitle,
      legendPosition: 'middle',
      legendOffset: -60,
    }}
    labelSkipWidth={100}
    labelSkipHeight={12}
    labelTextColor="#ffffff"
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 80,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 10,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    theme={{
      fontSize: 15,
      labels: {
        text: {
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
      axis: {
        legend: {
          text: {
            fontSize: 20,
          },
        },
      },
    }}
    layers={[
      'grid',
      'axes',
      'bars',
      'markers',
      'legends',
      ({ bars }) => {
        return (
          <g>
            {bars.map(({ width, x, y, data }: { width: number; height: number; x: number; y: number; data: any }) => {
              return (
                <text
                  key={data.id}
                  transform={`translate(${x - data.value.toString().length * 4 + width / 2}, ${y - 10})`}
                  textAnchor="central"
                  dominantBaseline="central"
                >{`${(formatValue ? formatValue(data.value) : data.value) ?? ''}`}</text>
              );
            })}
          </g>
        );
      },
    ]}
    {...extraProps}
  />
);
