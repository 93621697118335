import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const TaxesOnRRSPWithdrawals: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Taxes on RRSP Withdrawals</h1>

        <p className={styles.bodyText}>
          When withdrawing funds from an RRSP Account, your financial institution will deduct taxes upfront from your
          withdrawal amount.
        </p>

        <p className={styles.bodyText}>This is commonly known as Withholding Tax.</p>

        <p className={styles.bodyText}>The amount of tax to be deducted upfront will depend on</p>

        <ol className={styles.list} type="a">
          <li>Where you reside and</li>
          <li>How much amount you withdraw from your RRSP Accoun</li>
        </ol>

        <p className={styles.bodyText}>For residents of Canada (except Quebec), deductions would be as follows:</p>

        <ul className={styles.list}>
          <li>10% on withdrawal amounts up to $5,000</li>
          <li>20% on withdrawal amounts over $5,000 up to including $15,000</li>
          <li>30% on withdrawal amounts over $15,000</li>
        </ul>

        <p className={styles.bodyText}>
          <strong>Example</strong>
        </p>

        <p className={styles.bodyText}>
          If you reside in Ontario and withdrew $5,000 from you RRSP account, then your financial institution will
          deduct $500 upfront (10% of $5,000) and pay you the balance $4,500. This $500 is called the Withholding Tax.
        </p>

        <h3 className={styles.paraHeading}>Quebec Only</h3>

        <p className={styles.bodyText}>Deductions would be as follows:</p>

        <ul className={styles.list}>
          <li>5% on withdrawal amounts up to $5,000</li>
          <li>10% on withdrawal amounts over $5,000 up to including $15,000</li>
          <li>15% on withdrawal amounts over $15,000</li>
        </ul>

        <p className={styles.bodyText}>For funds in Quebec, a provincial tax will also be deducted upfront.</p>

        <h3 className={styles.paraHeading}>Actual Tax Obligations</h3>

        <p className={styles.bodyText}>
          Your actual tax obligation for the RRSP withdrawal amount is not the same amount deducted by your financial
          institution upfront.
        </p>

        <p className={styles.bodyText}>
          Your actual tax obligation will be determined when you file your tax return at the end of the year after
          including all your RRSP withdrawals during the year.
        </p>

        <h3 className={styles.paraHeading}>Non-residents</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          For non-residents of Canada, tax withholding rate is 25%, unless otherwise specified by a treaty.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'05/03/2021'} />
      </div>

      <FeedbackBox source="/investment/RRSP/text/short/3-may-2022/01" />
    </>
  );
};
