import React, { useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { CreditScoreForm } from './CreditScoreForm';
import { CreditScoreFormInputs } from './types';
import { createCreditScore, getCreditScoreList } from './creditScoreSlice';
import { BadRequestError } from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const AddCreditScore: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getCreditScoreListAction = useCallback(() => dispatch(getCreditScoreList()), [dispatch]);

  const onSubmit = async (data: CreditScoreFormInputs) => {
    try {
      await createCreditScore(data);
      history.push('/loan/credit-score');
      getCreditScoreListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  return (
    <Modal title={['Add', 'Credit Score']}>
      <CreditScoreForm onSubmit={onSubmit} />
    </Modal>
  );
};
