import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ETFInMyPortfolio: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>ETF in My Portfolio</h1>

        <ul className={styles.list}>
          <li>Role of ETF in your personal Portfolio will largely depend on your goals, objectives and preferences.</li>
          <li>
            Mutual Funds have some distinct advantage over ETFs, since most of the Target Date Funds are structured as
            Mutual Funds.
          </li>
          <li>
            If you prefer actively managed funds and would like to support the Active Fund Managers who picks these
            stocks, then Mutual Fund would be your natural choice instead of ETF.
          </li>
          <li>
            If you want the option to buy or sell less than one share, commonly known as Fractional Trading, then Mutual
            Fund would be better since Mutual Funds allow Fractional Trading, while ETFs don&apos;t
          </li>
          <li>
            How much price you are willing to pay for the management and performance for your Funds would also be a key
            factor. Mutual Funds will charge you on average about five times more than ETFs. If you want to keep your
            costs and taxes low, then ETFs would be your preference.
          </li>
          <li>
            ETFs will be a better fit for you, if you prefer passive style of investing. Despite active management
            style, very few Mutual Fund Managers can persistently beat the market.
          </li>
          <li>
            Despite passive management style, ETFs will offer you plenty of options, starting from mimicking board
            market index like S&P 500 to managing some very specialized investment strategies.
          </li>
          <li>
            Key risk of any ETF is, it may close down, similar to a Mutual Fund. In such scenario, the ETF issuer will
            notify its plan to close down the Fund and the date of closure. The investors will receive payments for
            every share they own - at par with its closing value of the net assets.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'02/19/2022'} />
      </div>

      <FeedbackBox source="/investment/etf/text/short/19-feb-2022/03" />
    </>
  );
};
