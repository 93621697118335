import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhoUseCreditScore: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Who Use Credit Score</h1>

        <p className={styles.bodyText}>
          Credit Scores may be looked up in many different situations. For example, when applying for any type of loan
          or credit card, phone connection, insurance policy, utilities, job and even renting an apartment. All these
          activities involve future financial commitments from an individual on an ongoing basis.
        </p>
        <p className={styles.bodyText}>
          There must be a genuine business need to ask for a Credit Score, which primarily involve assessing the ability
          to assume financial responsibility by any person. Without the respective individual’s consent, no one can see
          the Credit Score or Report.
        </p>

        <ol className={styles.list}>
          <li>
            <b>Banks and Credit Unions:</b> Banks will look up the Credit Score of an applicant to open a simple Bank
            Account, even without any overdraft protection option or any line of credit attached to it. This is a simple
            due diligence process that the banks always follow to assess the credit worthiness of the applicant.
          </li>

          <li>
            <b>Creditors:</b> Before issuing or renewing any credit card, overdraft, line of credit, student loan, auto
            loan or mortgage, lenders would like to know the applicant’s Credit Score. Credit Report would tell them how
            much an individual has borrowed, from whom and how well she met the financial commitments in past. This
            information is very helpful for the lenders in their assessment of the applicant.
          </li>

          <li>
            <b>Insurance Companies:</b> Insurance Companies use the Credit Score for two reasons. a) To determine the
            premium, because historically it has been proven that individuals with lower Credit Score are more likely to
            file insurance claims and accordingly their premium would be higher; b) ability of the applicant to pay the
            premium on an ongoing basis.
          </li>

          <li>
            <b>Collection Agencies:</b> Collection Agencies are entitled to obtain any individual’s Credit Report. They
            may use this information to review the person’s financial position and evaluate whether the individual has
            any ability to repay the collection amount.
          </li>

          <li>
            <b>Utility Companies:</b> When setting up a new account, Utility Companies like gas, electricity, water,
            heating, and phone companies will obtain a Credit Report. If the Credit Score is too low and below a certain
            threshold, then these Utility Companies will require a minimum deposit from the applicant to approve the
            account. This minimum deposit is meant to cover the amount of one or two billing cycles.
          </li>

          <li>
            <b>Landlords:</b> When renting an apartment or house, the to-be Landlord may obtain a Credit Report. Indeed,
            all professional residential building operators do so as a standard operating procedure. Their goal is to
            assess how regularly this individual will likely to pay their rent on time in the future. As a safety net,
            the Landlords may ask for a safety deposit covering the notice period needed to vacate the property
            according to the rental agreement.
          </li>

          <li>
            <b>Employers:</b> When applying for any job or during the annual performance review process, the Employers
            can look at an individual’s Credit Report. Obviously, the individual’s prior consent would be needed to look
            up such report. However, it is likely to be an abbreviated version without many Banking Arrangement and
            other financial details, which any Financial Institution is entitled to see.
          </li>

          <li>
            <b>Government Agencies:</b> Government Agencies often need to have a detailed understanding of an
            individual&apos;s income, assets, and debt obligations, particularly if there are any discrepancy between
            the figures reported to Financial Institutions and the Government Agencies. These are helpful for assessing
            an individual&apos;s eligibility for government funded student loan, government assisted income support,
            childcare support, healthcare support and determining income taxes.
          </li>

          <li>
            <b>Someone with a Court Order:</b> In rare cases, a Court may give an order to an organization or individual
            to access any individual&apos;s Credit Score on expectational basis, if it is deemed necessary according to
            the merit of the case.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>
        <PublishedDate date={'09/06/2021'} />
      </div>

      <FeedbackBox source="/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/03" />
    </>
  );
};
