import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';
import styles from './Pages.module.scss';
import infoImg from '../common/images/apple_img.jpg';

export const Page1: React.FC = () => {
  return (
    <Container fluid className="px-5">
      <Typography variant="h1">Change the refresh rate on your 16-inch MacBook Pro or Apple Pro Display XDR</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        You can switch between refresh rates on your MacBook Pro (16-inch, 2019) or Pro Display XDR.
      </Typography>
      <Typography variant="p">
        For professional workflows such as video editing, you can set the refresh rate of your display to match the
        frame rate of the video you{'&apos;'}re editing or viewing.
      </Typography>
      <Typography variant="h1">Available refresh rates</Typography>
      <Spacer variant="small" />
      <Typography variant="p">You can switch between these refresh rates on your Retina display:</Typography>
      <ul className={styles.list}>
        <li>60 Hertz</li>
        <li>59.94 Hertz</li>
        <li>50 Hertz</li>
        <li>48 Hertz</li>
        <li>47.95 Hertz</li>
      </ul>
      <Typography variant="h1">Change the refresh rate</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        Choose a refresh rate that divides evenly into the frame rate of your content. For example, if the content you
        {'&apos;'}re viewing is 24 frames per second, select the 48 Hertz refresh rate.
      </Typography>
      <ol className={styles.list}>
        <li>Choose Apple menu {'>'} System Preferences, then click Displays.</li>
        <li>Press and hold the Option key and select the Scaled button. The refresh rate menu appears.</li>

        <img src={infoImg} alt="info" width={650} />
        <li>Click the Refresh Rate pop-up menu and choose a refresh rate.</li>
      </ol>
      <Typography variant="p">
        When you{'&apos;'}re done viewing or editing the video content, you might want to change your refresh rate back
        to the default of 60 Hertz to ensure smooth performance in macOS, such as when you minimize a window.
      </Typography>
      <Typography variant="h1">Learn more</Typography>
      <Spacer variant="small" />

      <Link to="https://support.apple.com/kb/HT210754" className={styles.link}>
        Use multiple displays with your MacBook Pro (16-inch, 2019).
      </Link>
      <Spacer variant="small" />
      <Link to="https://support.apple.com/kb/HT210754" className={styles.link}>
        Set up and use Apple Pro Display XDR.
      </Link>
      <Spacer variant="small" />
      <Typography variant="span">Published Date: December 10, 2019</Typography>
    </Container>
  );
};
