import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';
import FeedbackBox from '../components/Common/FeedbackBox';

import styles from './Pages.module.scss';

export const Page0001: React.FC = () => {
  return (
    <>
      <Container fluid className="px-0">
        <Typography variant="h1">Setting Goal</Typography>
        <Spacer variant="small" />
        <Typography variant="p">
          We will first have to know, where we are going before we start our journey. This is such an important step
          that we call it mission critical.
        </Typography>
        <Typography variant="p">
          To be effective, a goal should ideally meet the following criteria. A goal should be:
        </Typography>
        <ol className={styles.list}>
          <li>Clear and specific.</li>
          <li>Measurable.</li>
          <li>Time bound.</li>
          <li>Challenging.</li>
          <li>Balanced.</li>
        </ol>
        <Typography variant="p">
          Example: Someday, I want to have a lot money is not a goal. I want to have $100 by 5.00 PM tomorrow is a goal.
        </Typography>
      </Container>

      <FeedbackBox source="/page0001" />
    </>
  );
};
