import { BillingCycleType } from './types';

export const billingCycleOptions = [
  {
    value: BillingCycleType.MONTHLY,
    label: BillingCycleType.MONTHLY,
  },
  {
    value: BillingCycleType.BIMONTHLY,
    label: BillingCycleType.BIMONTHLY,
  },
  {
    value: BillingCycleType.QUARTERLY,
    label: BillingCycleType.QUARTERLY,
  },
  {
    value: BillingCycleType.HALF_YEARLY,
    label: BillingCycleType.HALF_YEARLY,
  },
  {
    value: BillingCycleType.ANNUALLY,
    label: BillingCycleType.ANNUALLY,
  },
];
