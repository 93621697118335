import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import { SimpleTable } from './SimpleTable';
import { PieChart } from './PieChart';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PopularSmartPhones: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: 'Smart Phone Brands',
    },
    {
      dataField: 'o1',
      text: 'Market Share March 2020',
    },
    {
      dataField: 'o2',
      text: 'Market Share Mar 2021',
    },
  ];

  const marketShare = [
    {
      id: 'Apple',
      label: 'Apple',
      value: 53,
    },
    {
      id: 'Samsung',
      label: 'Samsung',
      value: 28,
    },
    {
      id: 'Huawei',
      label: 'Huawei',
      value: 7,
    },
    {
      id: 'Google',
      label: 'Google',
      value: 3,
    },
    {
      id: 'LG',
      label: 'LG',
      value: 3,
    },
    {
      id: 'Other',
      label: 'Other',
      value: 6,
    },
  ];

  // const colors: Array<string> = ['#0432ff', '#ff2f92', '#942093', '#ffc000', '#5b9bd5', '#70ad47'];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Popular Smart Phones</h1>

        <p className={styles.bodyText}>
          It is important to understand which phones are more popular before going shopping for a new one. Popular
          phones are popular for good reasons: they are reliable, durable, more secure and loaded with cool features.
        </p>

        <div className={styles.chartContainer}>
          <PieChart data={marketShare} extraProps={{ valueFormat: (value: number) => value + '%' }} />
        </div>

        <h2 className={styles.paraHeading}>Highlights</h2>

        <p className={styles.bodyText}>
          <b>Apple</b> is clearly the market leader covering more than half of the total Canadian market, steadily
          capturing other&apos;s market share as shown in the following table. Clearly our top choice. With the new
          lineup representing different price points, Apple is now much more affordable. Moreover, Apple offers better
          privacy and security features compared to its competitors.
        </p>
        <p className={styles.bodyText}>
          <b>Samsung</b> is the second in size with about half of Apple&apos;s. Still a good choice although continuing
          to lose market to Apple.
        </p>
        <p className={styles.bodyText}>
          <b>Huawei, LG</b> and <b>Google</b> are much smaller players. Devices are somewhat economic compared to the
          leaders. All three are steadily losing market. Not a core business of Google or LG. Huawei has ongoing
          security concerns. Although all these three brands are well resourced, continuation in the Smartphone business
          and future technical support is not as assured as Apple or Samsung.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Trends in Smartphone Market Share:</h2>

        <SimpleTable
          keyField="o0"
          data={[
            { o0: 'Apple ', o1: '51.53%', o2: '53.25%' },
            { o0: 'Samsung', o1: '28.78%', o2: '28.05%' },
            { o0: 'Huawei', o1: '7.23%', o2: '6.56%' },
            { o0: 'Google', o1: '3.27%', o2: '2.9%' },
            { o0: 'LG 3.0', o1: '3.01%', o2: '2.82%' },
          ]}
          columns={columns}
          bootstrap4
        />

        <div className={clsx(styles.chartSource, styles.last)}>
          Source: Company documents, regulatory filings.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </div>
        <PublishedDate date={'08/25/2021'} />
      </div>

      <FeedbackBox source="/bills/managingbills/phone/text/short/25-aug-2021/01" />
    </>
  );
};
