import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PaydayLoanProviders: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Payday Loan Providers</h1>

        <p className={styles.bodyText}>
          Following are few payday loan providers. We provide the list for you to be aware of who these providers are
          and their offers.
        </p>

        <ol className={styles.list}>
          <li>PAY2DAY</li>
          <li>Cash Payday</li>
          <li>MyCanada Payday</li>
          <li>Mr. Payday</li>
          <li>It&apos;s Payday</li>
          <li>Mogo Financials</li>
          <li>Loan Connect</li>
          <li>Coast Capital</li>
          <li>Fairstone</li>
          <li>Courtier Du Quebec</li>
          <li>LoanMeNow</li>
          <li>LendDirect</li>
          <li>iCash</li>
          <li>Focus Cash Loans</li>
          <li>GoDay</li>
          <li>Mr. Payday</li>
          <li>Cash Depot</li>
        </ol>

        <h4 className={styles.noteHeading}>Note</h4>
        <p className={styles.bodyText}>
          This is an exception to the to the other rankings we provide. Most rankings are a short-list of the providers
          we find the best.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          In contract, this list is to alerts you as we recommend avoiding payday loans at all cost and explore other
          alternatives.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/14/2022'} />
      </div>

      <FeedbackBox source="/debt/predatory-lending/text/short/14-jul-2022/05" />
    </>
  );
};
