import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PredatoryLending: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is Predatory Lending</h1>

        <p className={styles.bodyText}>
          Following are the most popular ways to describe predatory lending practices by the lenders.
        </p>

        <ul className={styles.list}>
          <li>
            Predatory lending refers to unethical practices of the lending institutions while approving a loan which are
            unfair, deceptive, or fraudulent.
          </li>
          <li>
            The practice of lenders by deceptively convincing borrowers to agree to unfair and abusive loan terms, or
            systematically violating those terms in ways that make it harder for the borrower to defend against.
          </li>
        </ul>

        <p className={styles.bodyText}>
          As a predatory lending practice, lenders trick the borrower to believe that an interest rate is lower than it
          really is.
        </p>

        <h3 className={styles.paraHeading}>Target</h3>

        <p className={styles.bodyText}>
          Predatory lenders always target the less educated, the poor, racial minorities, and the elderly community.
          However, population from all demographics usually fall pray of the predatory lenders.
        </p>

        <h3 className={styles.paraHeading}>Product Types</h3>

        <p className={styles.bodyText}>
          The lending practices which are considered predatory include payday loans, certain types of credit cards and
          consumer debt, subprime loans, and overdraft loans, when the interest rates are considered unreasonably high.
        </p>

        <h3 className={styles.paraHeading}>Predator&apos;s Game Plan</h3>

        <p className={clsx(styles.bodyText, styles.last)}>
          Predatory lenders generally approve loans which are secured by collateral, like car or house, so that if the
          borrower defaults on the loan, the lender can repossess or foreclose and profit by selling the repossessed
          asset or foreclosed property.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/14/2022'} />
      </div>

      <FeedbackBox source="/debt/predatory-lending/text/short/14-jul-2022/01" />
    </>
  );
};
