import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ChildCareExpenses: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Child Care Expenses</h1>

        <p className={styles.bodyText}>
          Childcare expenditure can be claimed through the CRA Form T778 to reduce tax burden primarily for the purpose
          to facilitate your earning a living (employment or business) or to attend school.
        </p>

        <h2 className={styles.paraHeading}>Eligible Child (for whom you can claim)</h2>

        <ul className={styles.list}>
          <li>
            The child must be under 16 years of age at any time in the year. However, the age limit does not apply for a
            dependent child with impairment in physical or mental function.
          </li>
          <li>A child who was dependent on you or your spouse or common-law partner.</li>
        </ul>

        <h2 className={styles.paraHeading}>Childcare Expense You Can Claim</h2>

        <ul className={styles.list}>
          <li>
            Costs/Expenses paid to nursery schools, boarding schools, day sports schools, day camps, daycare centers and
            caregivers/nannies.
          </li>
        </ul>

        <h2 className={styles.paraHeading}>Childcare Expenses You Cannot Claim</h2>

        <ul className={styles.list}>
          <li>Medical or hospital care expenses, clothing costs, recreational programs and transportation costs.</li>

          <li>The cost of tuition paid to an educational institution but the lodging portion can be claimed.</li>

          <li>Reimbursed portion of child care expenses by employer.</li>

          <li>Child care by the child&apos;s parents, spouse or common-law partner.</li>
        </ul>

        <h2 className={styles.paraHeading}>How Much Can You Claim</h2>

        <ul className={styles.list}>
          <li>$8,000 per child for children under the age of 7 years</li>

          <li>$5,000 per child for children aged 7 to 16 years.</li>

          <li>$11,000 for disabled, dependent child of any age who qualify for the disability tax credit.</li>

          <li>
            $5,000 for a disabled child over the age of 16 who does not qualify for the disability tax credit but was
            dependent on you.
          </li>

          <li>
            For a boarding school or overnight camp, claim up to $200 per week per child under the age of 7 years, $125
            per week for a child aged 7 to 16 years, $275 per week for an eligible disabled child.
          </li>
        </ul>

        <h2 className={styles.paraHeading}>Supporting Documents</h2>

        <ul className={styles.list}>
          <li>You should provide Business Number (BN) or Social Insurance Number (SIN) of caregivers to claim.</li>

          <li>
            Proof of expenses in the form of receipts should be kept for records.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'09/12/2021'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses/text/short/12-sep-2021/01" />
    </>
  );
};
