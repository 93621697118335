import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

// components
import { Typography } from '../../components/Typography';
import { Pagination, PaginationResult } from '../../components/Pagination';
import { Spacer } from '../../components/Spacer';

import { AlertHeader } from './AlertHeader';
import { AlertContent } from './AlertContent';
import { ListHeader } from './ListHeader';

import {
  // async midlewares
  getAlertsList,
  toggleAlertFlag,
  toggleAllAlertsFlag,
  deleteAlert,
  deleteMultipleAlerts,
  setReadFlag,

  // state returns
  selectAlertsList,
} from './alertSlice';

// types
import { AlertItem } from './types';
import { RootState } from '../../redux/';

export function AlertList(): JSX.Element {
  const dispatch = useDispatch();

  /*
  get alerts list
  */
  const getAlertsListAction = useCallback((filters?: unknown) => dispatch(getAlertsList(filters)), [dispatch]);
  useEffect(() => {
    getAlertsListAction();
  }, [getAlertsListAction]);

  const alertsList = useSelector(selectAlertsList);

  const { isAlertDeleted, isFlagToggled, isReadFlagChanged } = useSelector((state: RootState) => ({
    isAlertDeleted: state.alerts.isAlertDeleted,
    isFlagToggled: state.alerts.isFlagToggled,
    isReadFlagChanged: state.alerts.isReadFlagChanged,
  }));

  /*
  flag handeling
  */
  const toggleAlertFlagAction = useCallback((alertId: string) => dispatch(toggleAlertFlag(alertId)), [dispatch]);
  const toggleAllAlertsFlagAction = useCallback(
    (alertIds: Array<string | number>) => dispatch(toggleAllAlertsFlag(alertIds)),
    [dispatch],
  );

  const deleteAlertAction = useCallback((alertId: string) => dispatch(deleteAlert(alertId)), [dispatch]);
  const deleteMultipleAlertsAction = useCallback(
    (alertIds: Array<string | number>) => dispatch(deleteMultipleAlerts(alertIds)),
    [dispatch],
  );

  const setReadFlagAction = useCallback((alertId: string) => dispatch(setReadFlag(alertId)), [dispatch]);

  useEffect(() => {
    if (isFlagToggled || isAlertDeleted) {
      getAlertsListAction();
      setSelectedAlerts([]);
    }
  }, [getAlertsListAction, isFlagToggled, isAlertDeleted]);
  useEffect(() => {
    if (isReadFlagChanged) {
      getAlertsListAction();
    }
  }, [getAlertsListAction, isReadFlagChanged]);

  /*
  accordian handeling
  */
  const [activeKey, setactiveKey] = useState<string | undefined>(undefined);

  //handles active card for accordian
  const handleSelect = (eventKey: string | undefined) => {
    setactiveKey(eventKey);
  };

  /*
  alerts type
  */
  const [display, setDisplay] = useState<'all' | 'unread' | 'flags'>('all');
  const onChangeDisplay = (displayType: 'all' | 'unread' | 'flags') => {
    setDisplay(displayType);
    getAlertsListAction({ show: displayType });
  };

  /*
  pagination
  */
  const [selectedPageSize] = useState(5);
  const [defaultPage, setdefaultPage] = useState(0);
  const onPageChange = (page: PaginationResult) => {
    setactiveKey(undefined);
    setdefaultPage(page['currentPage']);
    getAlertsListAction({ page: page.currentPage });
  };

  const total: number = (alertsList || []).length;

  const [selectedAlerts, setSelectedAlerts] = useState<Array<number>>([]);
  const onSelectAlert = (selected: boolean, id: number) => {
    let modifiedData = [...selectedAlerts];
    if (selected) {
      modifiedData = [...modifiedData, id];
    } else {
      modifiedData = (modifiedData || []).filter((c) => c + '' !== id + '');
    }
    setSelectedAlerts(modifiedData);
  };

  const onSelectAll = (selected: boolean) => {
    if (selected) {
      const allIds = (alertsList || []).map((alert: AlertItem) => alert.id);
      setSelectedAlerts(allIds);
    } else {
      setSelectedAlerts([]);
    }
  };

  const selectedAlertsLength: number = (selectedAlerts || []).length;
  const isAllSelected: boolean = total !== 0 && total === selectedAlertsLength;
  const isAnySelected: boolean = selectedAlertsLength > 0;

  /*
  delete alert (i.e. bulk delete & single delete)
  */
  const onDeleteAllAlerts = () => {
    deleteMultipleAlertsAction(selectedAlerts);
  };
  const onDeleteSingleAlert = (alertId: number) => {
    deleteAlertAction(alertId + '');
  };

  /*
  flag toggle (i.e. bulk toggle flag & single toggle flag)
  */
  const onToggleFlagAllAlerts = () => {
    toggleAllAlertsFlagAction(selectedAlerts);
  };

  const onToggleFlagSingleAlert = (alertId: number) => {
    toggleAlertFlagAction(alertId + '');
  };

  /*
  read alert
  */
  const onReadAlert = (id: number) => {
    setReadFlagAction(id + '');
  };

  const fromIdx = defaultPage === 0 ? 0 : (defaultPage - 1) * selectedPageSize;
  const toIdx = defaultPage === 0 ? selectedPageSize : defaultPage * selectedPageSize;
  const alertsListPaginated: Array<AlertItem> = (alertsList || []).slice(fromIdx, toIdx);

  return (
    <Row>
      <Col>
        <div className="d-flex align-items-center">
          <Typography variant="h1">Alerts</Typography>
        </div>
        <Spacer variant="large" />

        <div>
          <ListHeader
            display={display}
            onChange={onChangeDisplay}
            checked={isAllSelected}
            onSelectAll={onSelectAll}
            isAnySelected={isAnySelected}
            onToggleFlagAllAlerts={onToggleFlagAllAlerts}
            onDeleteAllAlerts={onDeleteAllAlerts}
          />

          {alertsListPaginated.length ? (
            <Accordion defaultActiveKey={activeKey} onSelect={(eventKey: any) => handleSelect(eventKey)}>
              {(alertsListPaginated || []).map((item: AlertItem, key: number) => {
                const checked: boolean = selectedAlerts.includes(item.id);
                return (
                  <div key={key}>
                    <AlertHeader
                      activeKey={activeKey}
                      item={item}
                      index={key}
                      onDeleteSingleAlert={onDeleteSingleAlert}
                      onToggleFlagSingleAlert={onToggleFlagSingleAlert}
                      checked={checked}
                      onSelectAlert={onSelectAlert}
                      onReadAlert={onReadAlert}
                    />
                    <AlertContent item={item} index={key} />
                  </div>
                );
              })}
            </Accordion>
          ) : null}
        </div>

        <div className="w-100 d-flex justify-content-end pt-5">
          <Pagination
            total={total}
            pageSize={selectedPageSize}
            pageNeighbours={1}
            onPageChange={onPageChange}
            defaultSelected={defaultPage}
          />
        </div>
      </Col>
    </Row>
  );
}
