import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const ControlYourExpenses: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Control Your Expenses</h1>

        <ul className={styles.list}>
          <li>
            It is often asked how can we save 10% or more of income, when we are struggling to meet our bare
            necessities?
          </li>
          <li>
            The reality is, most of us have little or no money to save, irrespective of our income level or size of the
            family we support.
          </li>
          <li>
            Therefore, the true cause of our challenges does not root in the level of our income or the size of the
            family we support but elsewhere.
          </li>
          <li>
            Here is the most significant fact, what we call as our “necessary expenses” will always grow to pace with
            our income unless we actively take counter measures.
          </li>
          <li>We confuse necessary expenses with desires.</li>
          <li>Humans by nature are burdened with more desires than their earnings can gratify.</li>
          <li>
            No matter how hard you try to gratify your desires, there will always be many remaining ungratified desires.
            Even the richest person on planet can’t gratify every desire.
          </li>
          <li>
            Carefully study your accustomed habits of living. Here you will find accepted expense that may intelligently
            be reduced or eliminated.
          </li>
          <li>Your goal should be to demand 100% value appreciation of each dollar you spend.</li>
          <li>
            First, write down each of your desires you wish to spend your earnings. Then select the items which are
            necessary and other desires which are possible to gratify with 90% or less of your income. Delete the rest
            and consider those as great multitude of desires that must go unsatisfied. Don’t look back and regret about
            them.
          </li>
          <li>
            Plan your necessary expenses and never touch the savings. Keep updating your plan. Keep on fine-tuning it.
          </li>
          <li>
            Since you are making your own plan, you have the liberty to fulfill only your cherished desires within your
            income limits and forego the casual wises.
          </li>
          <li>
            Remember, main purpose of thoughtful planning is to help you to grow your wealth. Careful planning will help
            you to meet your necessities and those desires, which are attainable.
          </li>
          <li>
            Thoughtful planning, monitoring and control show you the leaks in your wallet and enables you to stop or
            control your expenses, and focus only on your definite and gratifying purposes.
          </li>
          <li>
            Carefully plan your expenses so that you can pay for your necessities as well as enjoyments and gratify your
            worthwhile desires without spending more than 90% or less of your income.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>
        <PublishedDate date={'06/08/2022'} />
      </div>

      <FeedbackBox source="/saving/timeless-principles/text/short/08-jun-2022/01" />
    </>
  );
};
