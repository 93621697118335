import React from 'react';
import styles from './Pages.module.scss';

import redCircle from '../common/images/red-circle.svg';
import FeedbackBox from '../components/Common/FeedbackBox';
import { SimpleTable } from './SimpleTable';
import clsx from 'clsx';
import { PublishedDate } from './PublishedDate';

export const TireSpeedRating: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: 'Speed Symbol',
    },
    {
      dataField: 'o1',
      text: 'Maximum Speed',
    },
    {
      dataField: 'o2',
      text: 'Maximum Speed',
    },
    {
      dataField: 'o3',
      text: 'Vehicle Type',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tire Speed Rating</h1>

        <ul className={styles.list}>
          <li>Tire speed rating indicates the maximum speed recommended for a tire when properly inflated.</li>

          <li>
            Tire speed rating is different than the tire size. Tire speed rating is mentioned next to the tire size on
            the tire.
          </li>

          <li>
            Always choose a tire with a speed rating an equal or greater than the original equipment tire that came with
            the new car.
          </li>

          <li>
            Winter tire could be an exception, where a speed rating lower than the summer tires would entail better
            flexibility. High-speed index tires are built harder, which is not preferable during winter conditions.
            Decrease should only be by one letter.
          </li>

          <li>Installing tire with different speed ratings is not recommended even if their size is the same.</li>

          <li>
            If tries with different speed limit are installed then the speed limit of the vehicle will be limited to the
            tires with the lowest speed rating.
          </li>

          <SimpleTable
            keyField="id"
            data={[
              {
                o0: 'L',
                o1: '120 km/h',
                o2: '75 mph',
                o3: 'Off Road. Light Trucks.',
              },
              {
                o0: 'M',
                o1: '130 km/h',
                o2: '81 mph',
                o3: 'Temporary Spare Tires',
              },
              {
                o0: 'N',
                o1: '140 km/h',
                o2: '87 mph',
                o3: '--',
              },
              {
                o0: 'P',
                o1: '150 km/h',
                o2: '93 mph',
                o3: '--',
              },
              {
                o0: 'Q',
                o1: '160 km/h',
                o2: '99 mph',
                o3: 'Studless & Studdable Winter Tires',
              },
              {
                o0: 'R',
                o1: '170 km/h',
                o2: '106 mph',
                o3: 'H.D. Light Truck Tires',
              },
              {
                o0: 'S',
                o1: '180 km/h',
                o2: '112 mph',
                o3: 'Family Sedans, Vans',
              },
              { o0: 'T', o1: '190 km/h', o2: '118 mph', o3: 'Family Sedans, Vans' },
              {
                o0: 'U',
                o1: '200 km/h',
                o2: '124 mph',
                o3: '--',
              },
              {
                o0: 'H',
                o1: '210 km/h',
                o2: '130 mph',
                o3: 'Sport Sedans, Coupes',
              },
              {
                o0: 'V',
                o1: '240 km/h',
                o2: '149 mph',
                o3: 'Sport Sedans, Coupes, Sports Cars',
              },
              { o0: 'W', o1: '270 km/h', o2: '168 mph', o3: 'Sport Sedans, Coupes, Sports Cars' },
              {
                o0: 'Y',
                o1: '300 km/h',
                o2: '186 mph',
                o3: 'Sport Sedans, Coupes, Sports Cars',
              },
              {
                o0: 'Z',
                o1: '240 km/h',
                o2: '149 mph+',
                o3: 'Sports Cars, Exotic Cars',
              },
            ]}
            columns={columns}
            bootstrap4
          />

          <p className={clsx(styles.bodyText, styles.last)}>
            Overall, speed rating is more than just the speed. Higher the speed limit, better would be the grip and
            stopping power, which would trigger lower tread life and reduced performance in the winter.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </p>

          <PublishedDate date={'10/19/2021'} />
        </ul>
      </div>

      <FeedbackBox source="/auto/tire/text/short/19-oct-2021/01" />
    </>
  );
};
