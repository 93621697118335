import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MilestoneFormInputs } from './types';
import { createMilestone, getGoalList } from './goalSlice';
import { BadRequestError } from '../../helpers/api';

import { Modal } from '../../components/Modal';
import { MilestoneForm } from './MilestoneForm';
import { MilestoneLimitWarningModal } from './MilestoneLimitWarningModal';

export const AddMilestone: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const [showLimitWarning, setShowLimitWarning] = useState(false);

  const onSubmit = async (data: MilestoneFormInputs) => {
    try {
      await createMilestone(data);

      history.push('/goals/goal');
      getGoalListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  const handleMilestoneLimitReached = () => setShowLimitWarning(true);

  return (
    <>
      {showLimitWarning ? (
        <MilestoneLimitWarningModal />
      ) : (
        <Modal title={['Add', 'Milestone']}>
          <MilestoneForm onSubmit={onSubmit} onMilestoneLimitReached={handleMilestoneLimitReached} />
        </Modal>
      )}
    </>
  );
};
