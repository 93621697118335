import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const RisksOfBonds: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Risks of Bonds</h1>

        <p className={styles.bodyText}>Following are the key risks an investor takes when buying a bond.</p>

        <ol className={styles.list}>
          <li>
            <strong>Inflation Risk: </strong>Inflation reduces the purchasing power of money. Since the interest rate
            and payments of bonds are fixed and does not change during the life of the bond, the cashflow from interest
            and principal received by the Bondholder over the years continues to lose value. If the inflation rate is
            very high, then it may seriously impact real return an investor generates from the investment in bonds.
          </li>
          <li>
            <strong>Interest Rate Risk: </strong>Interest rate of a bond does not change during its life time. If the
            interest rate increases in the market after purchasing the bond, then the newly issued bonds in the market
            will carry a higher interest rate to match the market. But the investors who have bought the bonds
            previously at a lower interest rate are stuck with those and will be deprived of the increased interest rate
            in the market.
            <p className={styles.listText}>
              In contrast, the investor will gain if the interest rate in the market goes down after the purchase of the
              bond. The investor will continue to enjoy the higher interest rate despite lower interest rate market
              environment.
            </p>
          </li>
          <li>
            <strong>Credit Risk: </strong>Credit risk is the risk that a bondholder carries that the borrower or the
            issuer of the bond will be unable to pay the principal or interest on time or not at all. Rating Agencies
            come to the aid of investors by helping them to assess the risk level of the borrower.
          </li>
          <li>
            <strong>Liquidity Risk: </strong>Bonds cannot be sold as easily as stocks in the open market. Investor
            generally buy bonds with an intention to hold until they become due. Accordingly, frequent trading of bonds
            is not very common.
            <p className={styles.listText}>
              This causes a challenge to find a buyer, when an investor wants to sell bonds quickly, particularly if the
              bonds are lower quality (junk bonds) or not very common in nature (municipality bonds).
            </p>
          </li>

          <li>
            <strong>Call Risk: </strong>Many borrowers or issuer of the bond reserve the right to repay the borrowed
            money before the due date. They keep a provision in the Bond to call the bonds anytime. Bonds are generally
            called when prevailing market interest rate is lower than the bond and issuer can save by repaying the bond
            and issuing new bonds at a lower interest rate.
            <p className={clsx(styles.listText, styles.last)}>
              This is a potential risk for the investor because suddenly the high paying bond is no longer available and
              the money has to be reinvested at a lower rate, which will reduce the overall return.
              <img src={redCircle} className={styles.redCircle} alt="" />
            </p>
          </li>
        </ol>

        <PublishedDate date={'03/09/2022'} />
      </div>

      <FeedbackBox source="/investment/bond/text/short/9-mar-2022/05" />
    </>
  );
};
