import React from 'react';
import Container from 'react-bootstrap/Container';
import { Typography } from '../components/Typography';
import { Spacer } from '../components/Spacer';
// import styles from './Pages.module.scss';

export const Page0003: React.FC = () => {
  return (
    <Container fluid className="px-0">
      <Typography variant="h1">Working for Money?</Typography>
      <Spacer variant="small" />
      <Typography variant="p">
        Most of us work for money, virtually all our life. Problem with this strategy is, we only have 24 hours day -
        limiting our capacity to earn.
      </Typography>
      <Typography variant="p">
        Rich people in contrast let their money work for them. How? It&apos;s very simple. They do not spend all they
        earn. Rather set aside a portion of their income and invest. These investments begin to generate return. Which
        they invest again.
      </Typography>
      <Typography variant="p">
        Over time, income from their investments become so large that it exceeds their ordinary income. In other words,
        they do not need to work to earn a living anymore. Which gives them freedom to the things they love or help
        others.
      </Typography>
      <Typography variant="p">Sounds simple? Yes, indeed. The catch is, we often mix up simple and easy!</Typography>
    </Container>
  );
};
