import React from 'react';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { CreditScoreFormInputs } from './types';
import { DatePicker, Form, Input, Select, SubmitButton } from '../../components/Form';

const schemaResolver = yupResolver<CreditScoreFormInputs>(
  yup.object().shape({
    date: yup.string().required('please select a date'),
    provider: yup.string().required('please select a provider'),
    credit_score: yup
      .number()
      .min(300, 'credit score should be more than 300')
      .max(900, 'credit score should be less than 900')
      .typeError('please enter a credit score')
      .required('please enter a credit score'),
  }),
);

const providerOptions = [
  { value: 'Equifax', label: 'Equifax' },
  { value: 'Transunion', label: 'Transunion' },
];

export type CreditScoreFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<CreditScoreFormInputs>>;
  onSubmit: SubmitHandler<CreditScoreFormInputs>;
};

export const CreditScoreForm: React.FC<CreditScoreFormProps> = ({ defaultValues = {}, onSubmit }) => {
  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <DatePicker name="date" placeholder="Date" />
        <Select name="provider" options={providerOptions} placeholder="Provider" />
        <Input name="credit_score" placeholder="Credit Score" label="Credit Score" />
        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
