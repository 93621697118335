import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhyCalendar: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Why Calendar</h1>

        <ol className={styles.list}>
          <li>
            Calendar is more than a simple list of things to be done. It is about doing some specific things at a
            particular date and time.
          </li>
          <li>It brings stunning visibility and specificity into the User&apos;s daily life.</li>
          <li>Calendar is a very important tool that plays a cental role in getting organized.</li>
          <li>
            Calendar allows to flag certain important events and trigger specific actions at the appropriate time.
          </li>
          <li>
            Automated calendar along with reminder emails and text messages can build a fail-proof system around the
            User to meet the important Fianacial commitments. Missing a single one could turn out to be very costly.
          </li>
          <li>
            Having an exclusive calendar solely for financial matters ensures the focus, which financial commitments
            deserve and it is not crowded out with other unrelated events.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'09/12/2021'} />
      </div>

      <FeedbackBox source="/calendar/time-management/text/short/12-sep-2021/01" />
    </>
  );
};
