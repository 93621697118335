import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WinterTires: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Winter Tires</h1>

        <p className={styles.bodyText}>The world&apos;s first Winter Tires were manufactured in 1934.</p>
        <p className={styles.bodyText}>
          Winter Tires differ from Summer Tires in two key factors: Rubber Compound and Tread Pattern.
        </p>
        <p className={styles.bodyText}>
          Winter Tires use more natural rubber in the tread compound so that it remains soft at temperature below 7
          degrees Celsius.
        </p>
        <p className={styles.bodyText}>
          Instead of hardening, Winter Tires remain soft and flexible in cold climates to reduce stopping distance when
          breaking.
        </p>
        <p className={styles.bodyText}>
          On road surfaces covered with snow and ice or on the roads in cold conditions, Winter Tires provide
          outstanding grip.
        </p>
        <p className={styles.bodyText}>
          Treads in Winter Tires have deeper blocks to dig into snow and ice for better grip. These deeper tread blocks
          provide cavity for snow. Surprisingly, nothing can grip snow more than the snow itself. This compacted snow
          trapped inside these deeper blocks further intensify the grip effect.
        </p>
        <p className={styles.bodyText}>
          Winter Tire treads have thousands of tiny grooves and channels (known as sipes) which bite into the snow and
          ice by clearing the slush and water from the car’s path.
        </p>
        <p className={styles.bodyText}>
          The sipes reduce or eliminate the risk of hydroplaning or aquaplaning, a dangerous driving condition that
          occurs when water causes car&apos;s tires to lose contact with the road surface.
        </p>
        <p className={styles.bodyText}>
          Don&apos;t use Winter Tires during Summer. Their rubber compound is too soft for dry asphalt, and will wear
          out much faster. Enhanced rolling resistance creates additional drag, which increases fuel consumption and
          noise.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'09/29/2021'} />
      </div>

      <FeedbackBox source="/wintertires/text/short/29-sept-2021/01" />
    </>
  );
};
