import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { IncomeForm } from './IncomeForm';
import { IncomeFormInputs } from './types';
import { updateIncome, getIncomeList, selectListLoading, selectIncomeById } from './incomeSlice';
import { BadRequestError } from '../../helpers/api';

export const EditIncome: React.FC = () => {
  let { incomeId } = useParams();
  incomeId = parseInt(incomeId, 10);
  const incomeItem = useSelector(selectIncomeById(incomeId));
  const listLoading = useSelector(selectListLoading);

  const history = useHistory();
  const dispatch = useDispatch();
  const getIncomeListAction = useCallback(() => dispatch(getIncomeList()), [dispatch]);

  const onSubmit = async (data: IncomeFormInputs) => {
    try {
      await updateIncome(incomeId, data);
      history.push('/income/income');
      getIncomeListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Income']}>
      <IncomeForm defaultValues={incomeItem} onSubmit={onSubmit} />
    </Modal>
  );
};
