import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import styles from './Profile.module.scss';

import ThumbnailGallery from '../../components/Common/ThumbnailGallery';
import { Typography } from '../../components/Typography';
import { DatePicker, Form, Input, Select, SubmitButton } from '../../components/Form';
import {
  ProfileForm1Inputs,
  ProfileForm2Inputs,
  ProfileForm4Inputs,
  ProfileForm5Inputs,
  ProfileForm6Inputs,
} from './types';
import {
  getUserDetails,
  getUserPaymentMethods,
  selectUserDetails,
  selectUserDetailsLoading,
  selectDefaultPaymentMethod,
  updateUser,
  signOut,
  createUserPaymentMethod,
  setDefaultUserPaymentMethod,
  setUserDetailsLoading,
} from './authSlice';
import { BadRequestError } from '../../helpers/api';
import {
  signUpProvinceOptions,
  signUpSexOptions,
  signUpEducationOptions,
  signUpMaritalStatusOptions,
  numberOfChildrenOptions,
  countryOptions,
} from './constants';
import { Spacer } from '../../components/Spacer';
import { STRIPE_KEY } from '../../constants';

const formatPhoneNumber = (phoneNumberString: string | number) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return undefined;
};

const StripeErrorMessage: React.FC = ({ children }) => (
  <div className={styles.stripeErrorMessage} role="alert">
    {children}
  </div>
);

const stripePromise = loadStripe(STRIPE_KEY);

const schema1Resolver = yupResolver<ProfileForm1Inputs>(
  yup.object().shape({
    first_name: yup.string().required('Please enter First Name'),
    last_name: yup.string().required('Please enter Last Name'),
    email: yup.string().email('Please enter a valid Email').required('Please enter Email'),
  }),
);

const schema2Resolver = yupResolver<ProfileForm2Inputs>(
  yup.object().shape({
    password: yup
      .string()
      .test('len', 'Password should be at least 8 symbols', (val) => !val || (val && val.toString().length > 8)),
    password_confirmation: yup.string().when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Both passwords need to be the same'),
    }),
  }),
);
const schema3Resolver = yupResolver(yup.object().shape({}));
const schema4Resolver = yupResolver<ProfileForm4Inputs>(
  yup.object().shape({
    address: yup.string().required('Please enter Street Address'),
    house_number: yup.string().required('Please enter House Number'),
    city: yup.string().required('Please enter City'),
    province: yup.string().required('Please select Province'),
    postal_code: yup.string().required('Please enter Postal Code'),
    country_name: yup.string().required('Please select Country'),
  }),
);
const schema5Resolver = yupResolver<ProfileForm5Inputs>(
  yup.object().shape({
    sex: yup.string().required('Please select Sex'),
    date_of_birth: yup.string().required('Please enter Date Of Birth'),
    marital_status: yup.string().required('Please select Marital Status'),
    number_of_children: yup.string().required('Please select Number of Children'),
  }),
);
const schema6Resolver = yupResolver<ProfileForm6Inputs>(
  yup.object().shape({
    education: yup.string().required('Please select Education'),
    occupation: yup.string().required('Please enter Occupation'),
    employer: yup.string().required('Please enter Employer'),
  }),
);

const getMaritalStatusLabel = (maritalStatusId: string) => {
  const foundStatus = signUpMaritalStatusOptions.find((status) => status.value === parseInt(maritalStatusId, 10));
  if (foundStatus) {
    return foundStatus.label;
  }
  return 'Unknown';
};

type AddressErrorResponse = {
  address?: {
    message?: string;
    data?: {
      formatted_address?: string;
      postal_address?: {
        regionCode?: string;
        languageCode?: string;
        postalCode?: string;
        administrativeArea?: string;
        locality?: string;
        addressLines?: string[];
      };
      previous_response_id?: string;
    };
  };
};

const ProfileWithoutStripe: React.FC = () => {
  const [block1Active, setBlock1Active] = useState(false);
  const [block2Active, setBlock2Active] = useState(false);
  const [block3Active, setBlock3Active] = useState(false);
  const [block4Active, setBlock4Active] = useState(false);
  const [block5Active, setBlock5Active] = useState(false);
  const [block6Active, setBlock6Active] = useState(false);

  const dispatch = useDispatch();
  const getUserDetailsAction = useCallback(() => dispatch(getUserDetails()), [dispatch]);
  const setUserDetailsLoadingAction = useCallback(() => dispatch(setUserDetailsLoading()), [dispatch]);
  const getUserPaymentMethodsAction = useCallback(() => dispatch(getUserPaymentMethods()), [dispatch]);

  const userDetails = useSelector(selectUserDetails);
  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const defaultUserPaymentMethod = useSelector(selectDefaultPaymentMethod);

  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState<string | undefined>(undefined);

  useEffect(() => {
    getUserDetailsAction();
  }, [getUserDetailsAction]);

  useEffect(() => {
    getUserPaymentMethodsAction();
  }, [getUserPaymentMethodsAction]);

  const deactivateAllProfileBlocks = () => {
    setBlock1Active(false);
    setBlock2Active(false);
    setBlock3Active(false);
    setBlock4Active(false);
    setBlock5Active(false);
    setBlock6Active(false);
  };

  const [block4Error, setBlock4Error] = useState<AddressErrorResponse | undefined>();

  const onSubmit = async (
    data: ProfileForm1Inputs | ProfileForm2Inputs | ProfileForm4Inputs | ProfileForm5Inputs | ProfileForm6Inputs,
    errorHandler?: (error?: AddressErrorResponse) => void,
  ) => {
    try {
      await updateUser(data);
      getUserDetailsAction();
      errorHandler && errorHandler(undefined);
      deactivateAllProfileBlocks();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
        errorHandler && errorHandler(err.response);
      } else {
        console.log(err);
        errorHandler && errorHandler(err as AddressErrorResponse);
      }
    }
  };

  const onSubmitBlock1 = async (data: ProfileForm1Inputs) => {
    await onSubmit(data);
  };

  const [mobileNumber, setMobileNumber] = useState<string | undefined>('');
  const [homePhoneNumber, setHomePhoneNumber] = useState<string | undefined>('');

  useEffect(() => {
    if (userDetails && userDetails['mobile_phone']) {
      setMobileNumber(formatPhoneNumber(userDetails['mobile_phone'].replace('+1', '')));
    }
    if (userDetails && userDetails['home_phone']) {
      setHomePhoneNumber(formatPhoneNumber(userDetails['home_phone'].replace('+1', '')));
    }
  }, [userDetails]);

  const onSubmitBlock2 = async (data: ProfileForm2Inputs) => {
    if (!data.password) {
      delete data.password;
    }
    await onSubmit({
      ...data,
      mobile_phone: (data['mobile_phone'] || '').replace(/[^\d]/g, ''),
      home_phone: (data['home_phone'] || '').replace(/[^\d]/g, ''),
    });
  };

  const onSubmitBlock3 = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      card: cardElement!,
    });

    if (error) {
      console.log('[error]', error);
      setStripeError(error.message);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      setStripeError(undefined);

      try {
        setUserDetailsLoadingAction();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await createUserPaymentMethod(paymentMethod!.id);
        if (response.id) {
          await setDefaultUserPaymentMethod(response.id);
        }
        getUserDetailsAction();
        getUserPaymentMethodsAction();
      } catch (err) {
        setStripeError(err.message);
      } finally {
        deactivateAllProfileBlocks();
      }
    }
  };

  const onSubmitBlock4 = async (data: ProfileForm4Inputs) => {
    const req = { ...data };
    if (block4Error?.address?.data?.previous_response_id) {
      req['previous_response_id'] = block4Error?.address?.data?.previous_response_id;
    }
    await onSubmit(req, setBlock4Error);
  };

  const onSubmitBlock5 = async (data: ProfileForm5Inputs) => {
    await onSubmit(data);
  };

  const onSubmitBlock6 = async (data: ProfileForm6Inputs) => {
    await onSubmit(data);
  };

  if (userDetailsLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileHeader}>
        <Container className="position-relative">
          <button type="button" className={styles.signOutButton + ' btn'} onClick={() => signOut()}>
            Sign Out
          </button>
          <div className={styles.profileName}>
            {userDetails && userDetails.first_name} {userDetails && userDetails.last_name}
          </div>
          <div className={styles.accountId}>Account ID: {userDetails && userDetails.customer_id}</div>
        </Container>
      </div>
      <Container>
        {block1Active ? (
          <div className={styles.profileBlock}>
            <div className={styles.profileFormBlock}>
              <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock1Active(false)}>
                Close
              </button>
              <h2 className={styles.activeBlockHeading}>Account</h2>
              <Form onSubmit={onSubmitBlock1} resolver={schema1Resolver} defaultValues={userDetails}>
                <Input name="first_name" placeholder="First Name" label="First Name" />
                <Input name="last_name" placeholder="Last Name" label="Last Name" />
                <Input name="email" placeholder="E-mail" label="E-mail" />
                <SubmitButton value="Submit" type="submit" className={styles.submitButton} />
              </Form>
            </div>
            <div className={styles.grayLine} />
          </div>
        ) : (
          <div className={styles.profileBlock}>
            <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock1Active(true)}>
              Edit
            </button>
            <h2 className={styles.inactiveBlockHeading}>Account</h2>
            <div className={styles.profileBlockFieldTitle}>First Name</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.first_name}</div>
            <div className={styles.profileBlockFieldTitle}>Last Name</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.last_name}</div>
            <div className={styles.profileBlockFieldTitle}>E-Mail</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.email}</div>
            <div className={styles.grayLine} />
          </div>
        )}
        {block2Active ? (
          <div className={styles.profileBlock}>
            <div className={styles.profileFormBlock}>
              <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock2Active(false)}>
                Close
              </button>
              <h2 className={styles.activeBlockHeading}>Security</h2>
              <Form
                onSubmit={onSubmitBlock2}
                resolver={schema2Resolver}
                defaultValues={{ ...userDetails, mobile_phone: mobileNumber, home_phone: homePhoneNumber }}
              >
                <Input type="password" name="password" placeholder="Password" label="Password" />
                <Input
                  type="password"
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  label="Confirm Password"
                />
                <Input
                  name="mobile_phone"
                  placeholder="Cell Phone"
                  label="Cell Phone"
                  value={mobileNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMobileNumber(e.target.value)}
                  onBlur={(e) => {
                    setMobileNumber(formatPhoneNumber(e.target.value) || '');
                  }}
                />

                <Input
                  name="home_phone"
                  placeholder="Home Phone"
                  label="Home Phone"
                  value={homePhoneNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHomePhoneNumber(e.target.value)}
                  onBlur={(e) => {
                    setHomePhoneNumber(formatPhoneNumber(e.target.value) || '');
                  }}
                />
                <SubmitButton value="Submit" type="submit" className={styles.submitButton} />
              </Form>
            </div>
            <Spacer variant="red-spacer" />
          </div>
        ) : (
          <div className={styles.profileBlock}>
            <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock2Active(true)}>
              Edit
            </button>
            <h2 className={styles.inactiveBlockHeading}>Security</h2>
            <div className={styles.profileBlockFieldTitle}>Password</div>
            <div className={styles.profileBlockFieldValue}>*****</div>
            <div className={styles.profileBlockFieldTitle}>Cell Phone</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && mobileNumber}</div>
            <div className={styles.profileBlockFieldTitle}>Home Phone</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && homePhoneNumber}</div>
            <Spacer variant="red-spacer" />
          </div>
        )}
        {block3Active ? (
          <div className={styles.profileBlock}>
            <div className={styles.profileFormBlock}>
              <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock3Active(false)}>
                Close
              </button>
              <h2 className={styles.activeBlockHeading}>Payment Information</h2>
              <Form onSubmit={onSubmitBlock3} resolver={schema3Resolver} defaultValues={userDetails}>
                <div className={styles.stripeField}>
                  <CardElement
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: '13px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#9e2146',
                        },
                      },
                    }}
                  />
                  {stripeError && <StripeErrorMessage>{stripeError}</StripeErrorMessage>}
                </div>
                <SubmitButton value="Submit" type="submit" className={styles.submitButton} />
              </Form>
            </div>
            <div className={styles.grayLine} />
          </div>
        ) : (
          <div className={styles.profileBlock}>
            <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock3Active(true)}>
              Edit
            </button>
            <h2 className={styles.inactiveBlockHeading}>Payment Information</h2>
            <div className={styles.profileBlockFieldTitle}>Credit Card Number</div>
            <div className={styles.profileBlockFieldValue}>
              {defaultUserPaymentMethod ? `**** **** **** ${defaultUserPaymentMethod.last4}` : '???'}
            </div>
            <div className={styles.profileBlockFieldTitle}>MM/YY</div>
            <div className={styles.profileBlockFieldValue}>
              {defaultUserPaymentMethod
                ? `${
                    parseInt(defaultUserPaymentMethod.exp_month, 10) >= 10
                      ? defaultUserPaymentMethod.exp_month
                      : '0' + defaultUserPaymentMethod.exp_month
                  } / ${defaultUserPaymentMethod.exp_year}`
                : '??/??'}
            </div>
            <div className={styles.grayLine} />
          </div>
        )}
        {block4Active ? (
          <div className={styles.profileBlock}>
            <div className={styles.profileFormBlock}>
              <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock4Active(false)}>
                Close
              </button>
              <h2 className={styles.activeBlockHeading}>Address</h2>
              <Form onSubmit={onSubmitBlock4} resolver={schema4Resolver} defaultValues={userDetails}>
                <div className={styles.addressEdit}>
                  {block4Error?.address?.message && (
                    <div className="alert alert-primary" role="alert">
                      {block4Error.address?.message}
                    </div>
                  )}
                </div>
                <Input name="address" placeholder="Street Address" label="Street Address" />
                <Input
                  name="house_number"
                  placeholder="Apartment, Suite, Building"
                  label="Apartment, Suite, Building"
                />
                <Input name="city" placeholder="City" label="City" />
                <Select name="province" options={signUpProvinceOptions} placeholder="Province / State" />
                <Input name="postal_code" placeholder="Postal Code" label="Postal Code" />
                <Select name="country_name" options={countryOptions} placeholder="Country / Region" />
                <SubmitButton value="Submit" type="submit" className={styles.submitButton} />
              </Form>
            </div>
            <Spacer variant="red-spacer" />
          </div>
        ) : (
          <div className={styles.profileBlock}>
            <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock4Active(true)}>
              Edit
            </button>
            <h2 className={styles.inactiveBlockHeading}>Address</h2>
            <div className={styles.profileBlockFieldTitle}>Street Address</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.address}</div>
            <div className={styles.profileBlockFieldTitle}>Apartment, Suite, Building</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.house_number}</div>
            <div className={styles.profileBlockFieldTitle}>City</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.city}</div>
            <div className={styles.profileBlockFieldTitle}>Province / State</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.province}</div>
            <div className={styles.profileBlockFieldTitle}>Postal Code</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.postal_code}</div>
            <div className={styles.profileBlockFieldTitle}>Country / Region</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.country_name}</div>
            <Spacer variant="red-spacer" />
          </div>
        )}
        {block5Active ? (
          <div className={styles.profileBlock}>
            <div className={styles.profileFormBlock}>
              <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock5Active(false)}>
                Close
              </button>
              <h2 className={styles.activeBlockHeading}>Personal Profile</h2>
              <Form onSubmit={onSubmitBlock5} resolver={schema5Resolver} defaultValues={userDetails}>
                <Select name="sex" options={signUpSexOptions} placeholder="Sex" />
                <DatePicker name="date_of_birth" placeholder="Date of Birth" />
                <Select name="marital_status" options={signUpMaritalStatusOptions} placeholder="Marital Status" />
                <Select name="number_of_children" options={numberOfChildrenOptions} placeholder="Number of Children" />
                <SubmitButton value="Submit" type="submit" className={styles.submitButton} />
              </Form>
            </div>
            <div className={styles.grayLine} />
          </div>
        ) : (
          <div className={styles.profileBlock}>
            <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock5Active(true)}>
              Edit
            </button>
            <h2 className={styles.inactiveBlockHeading}>Personal Profile</h2>
            <div className={styles.profileBlockFieldTitle}>Sex</div>
            <div className={styles.profileBlockFieldValue}>
              {userDetails && userDetails.sex === 'M' ? 'Male' : 'Female'}
            </div>
            <div className={styles.profileBlockFieldTitle}>Date of Birth</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.date_of_birth}</div>
            <div className={styles.profileBlockFieldTitle}>Marital Status</div>
            <div className={styles.profileBlockFieldValue}>
              {userDetails && getMaritalStatusLabel(userDetails.marital_status)}
            </div>
            <div className={styles.profileBlockFieldTitle}>Number of Children</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.number_of_children}</div>
            <div className={styles.grayLine} />
          </div>
        )}
        {block6Active ? (
          <div className={styles.profileBlock}>
            <div className={styles.profileFormBlock}>
              <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock6Active(false)}>
                Close
              </button>
              <h2 className={styles.activeBlockHeading}>Career Profile</h2>
              <Form onSubmit={onSubmitBlock6} resolver={schema6Resolver} defaultValues={userDetails}>
                <Select name="education" options={signUpEducationOptions} placeholder="Education" />
                <Input name="occupation" placeholder="Occupation" label="Occupation" />
                <Input name="employer" placeholder="Employer" label="Employer" />

                <SubmitButton value="Submit" type="submit" className={styles.submitButton} />
              </Form>
            </div>
          </div>
        ) : (
          <div className={styles.profileBlock}>
            <button type="button" className={styles.editButton + ' btn'} onClick={() => setBlock6Active(true)}>
              Edit
            </button>
            <h2 className={styles.inactiveBlockHeading}>Career Profile</h2>
            <div className={styles.profileBlockFieldTitle}>Education</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.education}</div>
            <div className={styles.profileBlockFieldTitle}>Occupation</div>
            <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.occupation}</div>
            <div className={styles.profileBlockFieldTitle}>Employer</div>
            {userDetails && userDetails.employer ? (
              <div className={styles.profileBlockFieldValue}>{userDetails && userDetails.employer}</div>
            ) : null}
          </div>
        )}

        <Spacer variant="red-spacer" />
        <Typography variant="h3">KNOWLEDGE</Typography>
        <Spacer variant="small" />
        <Typography variant="h1">Managing Account</Typography>
        <Spacer variant="large" />
        <ThumbnailGallery apiUrl="sections/user_account/categories/" />
      </Container>
    </div>
  );
};

export const Profile = () => {
  return (
    <Elements stripe={stripePromise} options={{}}>
      <ProfileWithoutStripe />
    </Elements>
  );
};
