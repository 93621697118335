import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const Income: React.FC = () => {
  const columns = [
    {
      dataField: 'beginWith',
      text: 'Begin with',
    },
    {
      dataField: 'income',
      text: 'Monthly Gross Income',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Income</h1>

        <p className={styles.bodyText}>Your earnings are the engine of your Wealth Building Plan.</p>

        <p className={styles.bodyText}>How to calculate Income for this purpose:</p>

        <SimpleTable
          keyField="id"
          data={[
            { beginWith: '(-) Less:', income: 'Taxes' },
            { beginWith: '(-) Less:', income: 'Employment Insurance Premium deducted at source' },
            { beginWith: '(-) Less:', income: 'Canada Pension Plan (CPP) deducted at source' },
            { beginWith: '(+) Add:', income: 'Child Support Allowance' },
            { beginWith: '(+) Add:', income: 'Tips' },
            { beginWith: '(+) Add:', income: 'Average Monthly Annual Bonus' },
            { beginWith: '(+) Add:', income: 'All other sources of Income.' },
          ]}
          columns={columns}
          bootstrap4
        />

        <ol className={styles.list}>
          <li>If your income varies from month-to-month, then make an average as your best estimate.</li>
          <li>
            If your taxes are not deducted at source (i.e. by your employer), then estimate the taxes you will pay.
            Using the guidance in your Tax Page, you can make a fairly accurate estimate of amount of Taxes you will
            need to pay based on your estimated income.
          </li>
          <li>
            Your final income number does not have to be exact. An educated estimate is fine.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'02/24/2021'} />
      </div>

      <FeedbackBox source="/saving/20-30-50-plan/text/short/24-feb-2022/05" />
    </>
  );
};
