import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const EmergencyFunds: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Emergency Funds</h1>

        <p className={styles.bodyText}>An emergency fund is built to meet emergency expenses only.</p>

        <p className={styles.bodyText}>
          Emergency expenses are unforeseen, which may cause existential threat (e.g. funding the cost of living during
          job loss or layoff, repair a sudden car breakdown or emergency medical expenses).
        </p>

        <p className={styles.bodyText}>Birthday or Christmas parties, vacations or a TV on sale are not emergencies.</p>

        <p className={styles.bodyText}>
          It is the best practice to build Emergency Funds as a cushion for the unforeseen situations. No exceptions.
          This step must be a priority over Medium and Long-Term Investment goals.
        </p>

        <p className={styles.bodyText}>
          At the initial stage of investing, priority is to ensure safety and liquidity.
        </p>

        <h3 className={styles.paraHeading}>1. Safety</h3>
        <p className={styles.bodyText}>
          The cushion should cover 6 to 12 months of your household’s essential expenses. $15,000 to $20,000 is a
          reasonable range on average to get started, but it will depend on your current earnings and individual
          context.
        </p>

        <h3 className={styles.paraHeading}>2. Liquidity</h3>
        <p className={styles.bodyText}>
          The fund should be invested in a semi-liquid form that would earn good returns but can easily be converted
          into cash during emergencies in a short notice without losing the principal amount.
        </p>

        <p className={styles.bodyText}>
          An Emergency Fund is meant to be a safety net and very critical element to the wealth building process, which
          can be built with little bit of planning and minimum effort.
        </p>

        <h3 className={styles.paraHeading}>Benefits</h3>
        <p className={styles.bodyText}>An Emergency Fund offers numerous benefits.</p>

        <ul className={styles.list}>
          <li>Ability to remain in control of any situation even during emergencies.</li>
          <li>Adds certainty and peace of mind.</li>
          <li>Remain on course on building wealth process without interruption.</li>
          <li>Adds flexibility to make important and bold moves like switching jobs.</li>
        </ul>

        <p className={clsx(styles.bodyText, styles.last)}>
          You must save your Emergency Fund before moving on to Short-Term and Long-Term Investment Phases. Don’t
          attempt to ignore or skip it.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'04/12/2022'} />
      </div>

      <FeedbackBox source="/investment/investement-types/text/short/12-apr-2022/01" />
    </>
  );
};
