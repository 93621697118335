import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const AllElectricVehiclesFuelEconomy: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>All-Electric Vehicles</h1>
        <h2 className={styles.subHeading}>Fuel Economy</h2>

        <p className={styles.bodyText}>
          All-electric vehicles (EVs) run on electricity only. They are propelled by one or more electric motors powered
          by rechargeable battery packs.
        </p>
        <p className={styles.bodyText}>Advantages of All-electric vehicles:</p>

        <p className={styles.bodyText}>
          <strong>Energy Efficient.</strong> EVs convert over 77% of the electrical energy from the grid to power at the
          wheels. Conventional gasoline vehicles convert 12%–30% of the energy stored in gasoline.
        </p>

        <p className={styles.bodyText}>
          <strong>Environmentally Friendly.</strong> EVs emit no tailpipe pollutants, although the power plant producing
          the electricity may emit them. Electricity from nuclear-, hydro-, solar-, or wind-powered plants causes no air
          pollutants.
        </p>

        <p className={styles.bodyText}>
          <strong>Performance Benefits.</strong> Electric motors provide quiet, smooth operation, stronger acceleration
          and require less maintenance than internal combustion engines (ICEs).
        </p>

        <p className={styles.bodyText}>
          <strong>Reduced Energy Dependence.</strong> Electricity is a domestic energy source.
        </p>

        <p className={styles.bodyText}>
          All-electric vehicles (EVs) have some drawbacks compared to gasoline vehicles:
        </p>

        <p className={styles.bodyText}>
          <strong>Driving Range.</strong> EVs have a shorter driving range than most conventional vehicles—although EV
          driving ranges are improving. Most EVs can travel more than 150 kms on a charge, and some can travel in excess
          of 300 or 500 kms depending on the model.
        </p>

        <p className={styles.bodyText}>
          <strong>Recharge time.</strong> Fully recharging the battery pack can take 3 to 12 hours. Even a "fast charge"
          to 80% capacity can take 30 minutes.
        </p>

        <p className={clsx(styles.bodyText, styles.last)}>
          Batteries for EVs are designed for extended life and may last 12 to 15 years in moderate climates and 8 to 12
          years in severe climates. However, these batteries are expensive, and replacing them may be costly if they
          fail.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'07/06/2022'} />
      </div>

      <FeedbackBox source="/auto/electric-vehicle-series-I/text/short/06-jul-2022/03" />
    </>
  );
};
