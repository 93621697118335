import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const LongTermInvestment: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Long Term Investment</h1>

        <h3 className={styles.paraHeading}>Time Horizon</h3>
        <p className={styles.bodyText}>
          Long Term Investments generally have long time horizon, usually from six years to 40 years or more.
        </p>

        <h3 className={styles.paraHeading}>Purpose</h3>
        <p className={styles.bodyText}>Long-term Investments are intended for</p>
        <ol className={styles.list}>
          <li>Building wealth.</li>
          <li>Funding retirement.</li>
          <li>Funding children’s education.</li>
        </ol>

        <h3 className={styles.paraHeading}>Risk Tolerance</h3>
        <p className={styles.bodyText}>
          Since the time horizon in the Long Term Investment is very long, there is room for tolerance to undertake
          higher level of risks in exchange of the higher return potential.
        </p>
        <p className={styles.bodyText}>
          It is expected that market will go through several cycles before the investment funds are expected to be
          withdrawn. This flexibility allows the investor to make adjustment to the portfolio mix as the due date of
          withdrawal approaches.
        </p>

        <h3 className={styles.paraHeading}>Investment Instruments</h3>
        <p className={styles.bodyText}>The most common investment instruments for Long Term Investments are:</p>
        <ol className={styles.list}>
          <li>Exchange Traded Fund</li>
          <li>Mutual Fund</li>
          <li>Stock</li>
          <li>Bond</li>
        </ol>

        <h3 className={styles.paraHeading}>Asset Allocation</h3>
        <p className={styles.bodyText}>
          At the early stage of life, it is recommended to have higher proportion of asset allocated to relatively more
          risky or volatile instruments like stocks or stock-based ETFs or Mutual Funds. As the time passes, gradually
          some of these assets need to be deployed to less volatile instruments like Fixed Income Instruments or Bonds.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Refer to the Asset Allocation section for more detailed guidance.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'04/12/2022'} />
      </div>

      <FeedbackBox source="/investment/investement-types/text/short/12-apr-2022/03" />
    </>
  );
};
