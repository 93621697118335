import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as yup from 'yup';
import clsx from 'clsx';

import { setStepCompleted, completeStep3OfRegistration, checkCouponCode, checkPromoCode } from './authSlice';
import { Form, SubmitButtonLoader, Checkbox, Input } from '../../components/Form';
import { yupResolver } from '@hookform/resolvers';
import { SignUpStep3FormInputs } from './types';
import { STRIPE_KEY } from '../../constants';

import styles from './SignUpSteps.module.scss';
import stepStyles from './SignUpStep3.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

const stripePromise = loadStripe(STRIPE_KEY);
const schemaResolver = yupResolver<SignUpStep3FormInputs>(
  yup.object().shape({
    accept_terms: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  }),
);

const StripeErrorMessage: React.FC = ({ children }) => (
  <div className={stepStyles.stripeErrorMessage} role="alert">
    {children}
  </div>
);

const SignUpStep3WithoutStripe: React.FC = () => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState<string | undefined>(undefined);
  const [couponError, setCouponError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const dispatch = useDispatch();

  const onSubmit = async (data: SignUpStep3FormInputs) => {
    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    const giftCodes = {
      coupon: '',
      promo: '',
    };

    if (data.code) {
      try {
        const result = await Promise.any([checkPromoCode(data.code), checkCouponCode(data.code)]);

        if (result.coupon_code) {
          giftCodes.coupon = data.code;
        } else if (result.promo_code) {
          giftCodes.promo = data.code;
        }
      } catch (err) {
        setCouponError('Gift or promo code is invalid');
        return;
      } finally {
        setSubmitting(false);
      }
    }

    setCouponError('');

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      card: cardElement!,
    });

    if (error) {
      console.log('[error]', error);
      setStripeError(error.message);
      setSubmitting(false);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      setStripeError(undefined);

      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await completeStep3OfRegistration(paymentMethod!.id, giftCodes);
        dispatch(setStepCompleted(3));
        history.push('/user/sign-up/completed');
      } catch (err) {
        setStripeError(err.message);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Container fluid className="px-0 position-relative">
      <h3 className={stepStyles.title}>Payment Information</h3>
      <Form onSubmit={onSubmit} resolver={schemaResolver}>
        <div className={stepStyles.stripeField}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '13px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
          {stripeError && <StripeErrorMessage>{stripeError}</StripeErrorMessage>}
        </div>
        <Input
          name="code"
          placeholder="Gift / Promo Code (If Any)"
          className={styles.inputBlock}
          labelClassName={styles.label}
        />
        <React.Fragment>
          {couponError ? (
            <div className={clsx(stepStyles.couponErrorBlock, 'invalid-feedback')}>{couponError}</div>
          ) : null}
        </React.Fragment>
        <Checkbox name="accept_terms" className={stepStyles.checkboxBlock}>
          I agree to the Arctic Rich Terms and Conditions
        </Checkbox>
        <div className={stepStyles.spacer} />

        <SubmitButtonLoader
          value="Continue"
          className={styles.submitButtonBlock}
          loading={submitting}
          disabled={!stripe || submitting}
        />
      </Form>
    </Container>
  );
};

export const SignUpStep3 = () => {
  useEffect(() => {
    /* tslint:disable */
    if (window['fbq']) window['fbq']('track', 'PageView');
  }, []);

  return (
    <Elements stripe={stripePromise} options={{}}>
      <SignUpStep3WithoutStripe />
    </Elements>
  );
};
