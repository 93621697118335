import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const Donts: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Dont&apos;s</h1>

        <p className={styles.bodyText}>
          Few activities hurt the Credit Score more than others. Here are the top five. Be careful with these! Few
          little missteps may cost lot more than you think.
        </p>

        <ol className={styles.list}>
          <li>
            <b>Late payment:</b> Late payments are not taken kindly by the lenders as well as the credit agencies. This
            is an early sign of trouble or financial difficulty of any individual that these folks are not willing to
            ignore. Credit cards, loans or utility bills payments - all are being tracked and carry the same importance.
            Even a single delay will reduce the score. They also have a long memory. Each record will stay in the report
            for seven years.
          </li>
          <li>
            <b>High debt utilization ratio:</b> The second most important factor contributing to the lower score. This
            is next level of trouble sign or financial difficulty. Many keep borrowing when their earnings cannot keep
            up with expenses. Utilization of 30% or below is considered acceptable. Anything beyond will negatively
            impact the score.
          </li>
          <li>
            <b>Applying for multiple credits simultaneously:</b> Credit agencies and lenders can easily track when and
            where a potential borrower is enquiring for new credits. They call it “Hard Inquiries”. It sounds like a
            sign of desperation to lenders since someone is trying borrow money urgently but going to different lenders
            because everyone is turning that person down. Cause of concern indeed. Immediately they flag the person
            concerned as a high-risk target.
            <p className={styles.listText}>
              <b>Exception:</b> All inquiries related to auto loan, mortgage, or new utility provider within 14 to 45
              days are treated as a single inquiry. This allows individuals to shop around for the best deal.
            </p>
          </li>
          <li>
            <b>Closing credit accounts:</b> Closing a fully paid credit card, line of credit any other revolving
            evergreen credit is not a good idea. On the surface it may seem like a good step but it has several
            implications on how Credit Agencies will treat this.
            <ul className={styles.subList}>
              <li>
                <b>a) Increase in debt utilization ratio:</b> Closing a credit means, the limit of that credit is no
                longer available. As a result, the debt utilization will immediately go up.
              </li>
              <li>
                <b>b) Change in credit mix:</b> If type of credit reduces, then rest of the credits will get more
                proportion of the total credit. Lenders want to see how responsibly individual can manage different
                types of credit.
              </li>
              <li>
                <b>c) Shorten Credit History:</b> If an old credit is deleted then the history of that credit will no
                longer be counted, which will shorten the credit history and impact the score.
              </li>
            </ul>
          </li>
          <li>
            <b>Not using credit:</b> Not using credit for a long period is not a good thing. Lenders will not be able to
            read the past behaviour of the person and it will be difficult for them to predict future conduct or
            worthiness as a borrower. Lenders may also close credit accounts due to inactivity. In that case, the person
            concerned will be impacted in the same way mentioned in the above section.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'09/19/2022'} />
      </div>

      <FeedbackBox source="/creditscore/thebasics/text/short/19-sep-2021/01" />
    </>
  );
};
