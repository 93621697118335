import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';

export const AutomateTransactions: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Automate Transactions</h1>

        <p className={styles.bodyText}>
          Automating the routine transactions is one of the best practices you can adopt, particularly the saving
          process.
        </p>

        <h3 className={styles.paraHeading}>Steps</h3>
        <ul className={styles.list}>
          <li>
            Automatically transfer your routine savings from your salary account or main chequeing account to a sperate
            saving account on each pay day.
          </li>
          <li>This saving account is for temporary parking only and it is not your investment account.</li>
          <li>From this saving account you can transfer funds to your RRSP, RRIF or other investment accounts.</li>
          <li>
            The transfer to investment account could be done at any interval of your choice - monthly, quarterly, or
            semi-annually.
          </li>
        </ul>

        <h3 className={styles.paraHeading}>Benefits</h3>

        <p className={styles.bodyText}>
          Setting aside funds on each pay day ensures that you separate the saving funds before spending any money for
          other purposes.
        </p>

        <p className={styles.bodyText}>
          You have to act only once to automate. Rest of the process is in autopilot, and you don’t have to pay
          attention to it.
        </p>

        <h3 className={styles.paraHeading}>Exceptions</h3>
        <p className={styles.bodyText}>
          In case of emergency or rare situations, you can always temporarily holdback the transfer to your savings
          account as an exception.
        </p>

        <h3 className={styles.paraHeading}>Direct Deposit</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          If possible, you may directly transfer your routine savings from the salary account or main chequeing account
          to your investment account, skipping the temporary parking step into your saving account. However, in such
          case you may have to be mindful of the market conditions.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/25/2022'} />

        <FeedbackBox source="/transaction/banktransactions/text/short/25-mar-2022/01" />
      </div>
    </>
  );
};
