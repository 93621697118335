import React from 'react';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { SubmitHandler, UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';

import { CreditFactorFormInputs } from './types';
import { DatePicker, Form, Input, Select, SubmitButton } from '../../components/Form';
import { selectCreditScoreList, selectListLoading } from './creditScoreSlice';
import { formatDate } from '../../helpers/tranformers';

const schemaResolver = yupResolver<CreditFactorFormInputs>(
  yup.object().shape({
    credit_report: yup.number().typeError('please select a credit report').required('please select a credit report'),
    description: yup.string().required('please enter factors influencing credit score'),
    action_step: yup.string().required('please enter an action step'),
    target_date: yup.string().required('please select a target date'),
  }),
);

export type CreditFactorFormProps = {
  defaultValues?: UnpackNestedValue<DeepPartial<CreditFactorFormInputs>>;
  onSubmit: SubmitHandler<CreditFactorFormInputs>;
};

export const CreditFactorForm: React.FC<CreditFactorFormProps> = ({ defaultValues = {}, onSubmit }) => {
  const creditScoreList = useSelector(selectCreditScoreList);
  const listLoading = useSelector(selectListLoading);

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  const creditScoreOptionsList = creditScoreList.map((creditScore) => ({
    value: creditScore.id,
    label: `${creditScore.provider} (${formatDate('MMM yyyy', creditScore.date)})`,
  }));

  return (
    <Container fluid className="px-0">
      <Form onSubmit={onSubmit} resolver={schemaResolver} defaultValues={defaultValues}>
        <Select name="credit_report" options={creditScoreOptionsList} placeholder="Credit Score" />

        <Input
          name="description"
          placeholder="Factors Influencing Credit Score"
          label="Factors Influencing Credit Score"
        />
        <DatePicker name="target_date" placeholder="Target Date" />
        <Input name="action_step" placeholder="Action Step" label="Action Step" />

        <SubmitButton value="Submit" type="submit" />
      </Form>
    </Container>
  );
};
