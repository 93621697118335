import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DebtLoad: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Debt Load</h1>

        <h3 className={styles.paraHeading}>What is Debt Load</h3>
        <p className={styles.bodyText}>The Debt Load is the total amount of money you owe which is the Total Debt.</p>
        <p className={styles.bodyText}>Total Debt is the consumer debt plus mortgage loans.</p>

        <h3 className={styles.paraHeading}>What is Consumer Debt</h3>
        <p className={styles.bodyText}>
          Consumer Debt is the money owed on credit facilities except the mortgage debt. This includes outstanding
          balances of credit card, line of credit, car loan, etc. Consumer Debt does not include routine monthly
          expenses, such as phone bills, utility bills, etc.
        </p>

        <h3 className={styles.paraHeading}>Lender Guideline</h3>
        <p className={styles.bodyText}>
          Following are the general guidelines that lender’s usually follow. It is better to adopt lower thresholds than
          these guidelines.
        </p>

        <ul className={styles.list}>
          <li>Consumer debt payments should be within 15% to 20% of the before taxes gross monthly income.</li>
          <li>Total debt payments should be between 35% to 40% of the before taxes gross monthly income.</li>
        </ul>

        <h3 className={styles.paraHeading}>Conclusion</h3>
        <p className={clsx(styles.bodyText, styles.last)}>
          If your debt repayments are higher than the above guidelines, then your debt load is probably becoming
          manageable. You are unlikely to be able to meet your obligations when those become due for repayment. The high
          debt load might also make it harder for you to get new credit at favourable terms.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'06/30/2022'} />
      </div>

      <FeedbackBox source="/debt/debt-ratios/text/short/30-jun-2022/03" />
    </>
  );
};
