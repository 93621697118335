import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import stsmlImg from '../common/images/stsmall.png';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const StartSmall: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Start Small</h1>

        <p className={styles.bodyText}>We do not need to move the mountain all at once.</p>
        <p className={styles.bodyText}>
          Even a small step toward the right direction could make a great deal of difference over time compared to doing
          nothing at all. However, we could sharply improve our results, if we continue to take the positive steps and
          simultaneously avoid the activities which could negatively impact the outcome.
        </p>
        <p className={clsx(styles.bodyText)}>
          Following diagram details how dramatic the difference in the outcome could be. For example, say we would like
          to improve our health. If we start doing exercise everyday, that is a positive effort and will certainly
          deliver results over time. Now, if we also avoid fast food or smoking at the same time, we will begin to see
          even more significant improvements in a much shorter time.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <div className={styles.imgContainer}>
          <img src={stsmlImg} alt="" width="500" />
        </div>
        <PublishedDate date={'06/05/2021'} />
      </div>

      <FeedbackBox source="/overview/learn/text/short/05-jun-2021/02" />
    </>
  );
};
