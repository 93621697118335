import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WriteItGoal: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Write it</h1>

        <p className={styles.bodyText}>Write down your goal. As detail as you can.</p>

        <ol className={styles.list}>
          <li>A goal that is not written, is not a goal at all.</li>
          <li>A written goal brings specificity and clarity to what exactly needs to be done.</li>
          <li>
            Many experts suggest writing and re-writing the goals every day, day after day. This simple practice embeds
            the objective deep into the subconscious mind.
          </li>
          <li>Writing down goal activates our physical, intellectual, and emotional senses.</li>
          <li>
            Write each goal on a separate sheet of paper. Write it on Index Cards. Carry it with you all the time and
            review it regularly. Upload the goals in your Arctic Rich Portal.
          </li>
          <li>Write your goal in present tense. Don&apos;t use future tense.</li>
          <li>Place your written goals somewhere you can see most of the time.</li>
          <li>
            Your mind is equipped with a goal-seeking function, which is completely non-judgemental. It will
            continuously seek and eventually achieve your written goal.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'10/20/2021'} />
      </div>

      <FeedbackBox source="/goal/settinggoal/text/short/20-oct-2021/01" />
    </>
  );
};
