import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';

import { RootState } from '../../redux/';
import { TaxItems, TaxFormInputs, TaxDeductibleFormInputs, TaxDeductibleItems } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';

export interface TaxState {
  list: TaxItems;
  listDeductible: TaxDeductibleItems;
  listLoading: boolean;
}

const initialState: TaxState = {
  list: [],
  listDeductible: [],
  listLoading: false,
};

export const getTaxList = createAsyncAction('GET_TAX_LIST', async () => {
  return await doGetRequest('taxes/');
});

export const getTaxDeductibleList = createAsyncAction('GET_TAX_DEDUCTIBLE_LIST', async () => {
  return await doGetRequest('tax-deductible-expences/');
});

export const createTax = (taxRequestBody: TaxFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...taxRequestBody,
    // date: format(fromUnixTime(Date.parse(taxRequestBody.date) / 1000), 'yyyy-MM-dd'),
    rating: 'Good',
  };
  return doPostRequest('taxes/', updatedRequestBody);
};

export const updateTax = (taxId: number, taxRequestBody: TaxFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...taxRequestBody,
    // date: format(fromUnixTime(Date.parse(taxRequestBody.year) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('taxes/' + taxId + '/', updatedRequestBody);
};

export const deleteTax = async (taxId: number) => await doDeleteRequest('taxes/' + taxId + '/');

export const deleteTaxDeductible = async (taxDeductibleId: number) =>
  await doDeleteRequest('tax-deductible-expences/' + taxDeductibleId + '/');

export const createTaxDeductible = (taxDeductibleRequestBody: TaxDeductibleFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...taxDeductibleRequestBody,
    date: format(fromUnixTime(Date.parse(taxDeductibleRequestBody.date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('tax-deductible-expences/', updatedRequestBody);
};

export const updateTaxDeductible = (taxDeductibleId: number, taxDeductibleRequestBody: TaxDeductibleFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...taxDeductibleRequestBody,
    date: format(fromUnixTime(Date.parse(taxDeductibleRequestBody.date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('tax-deductible-expences/' + taxDeductibleId + '/', updatedRequestBody);
};

export const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getTaxList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getTaxList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getTaxList.fulfilled)]: (state, action: PayloadAction<TaxItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
    [String(getTaxDeductibleList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getTaxDeductibleList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getTaxDeductibleList.fulfilled)]: (state, action: PayloadAction<TaxDeductibleItems>) => {
      const listDeductible = action.payload;
      return {
        ...state,
        listDeductible,
        listLoading: false,
      };
    },
  },
});

export const selectTaxList = (state: RootState) => state.tax.list;
export const selectListLoading = (state: RootState) => state.tax.listLoading;

export const selectTaxById = (taxId: number) => (state: RootState) =>
  state.tax.list.find((taxItem) => {
    return taxItem.id === taxId;
  });

export const selectTaxDeductibleById = (deductibleId: number) => (state: RootState) =>
  state.tax.listDeductible.find((deductibleItem) => {
    return deductibleItem.id === deductibleId;
  });

export const selectTaxDeductibleByYear = (year: number) => (state: RootState) => {
  const foundTaxes = state.tax.listDeductible.filter((taxItem) => {
    return parseInt(taxItem.date.substring(0, 4), 10) === year;
    // return parseInt(taxItem.date) === year;
  });

  if (!foundTaxes) {
    return [];
  }

  return (
    foundTaxes
      // .flatMap((tax) => tax.tax_deductible_expenses)
      .map((taxDeductible) => {
        // const parentTax = foundTaxes.find((tax) => tax.id === taxDeductible.id);
        return {
          ...taxDeductible,
          // provider: parentTax ? parentTax.provider : '',
        };
      })
  );
};

export default taxSlice.reducer;
