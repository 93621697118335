import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const HowToAvoidPredatoryLending: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>How to Avoid Predatory Lending</h1>

        <h3 className={styles.paraHeading}>Does</h3>

        <ol className={styles.list}>
          <li>Explore your options before finalizing your provider.</li>
          <li>Deal only with an authorized and licensed lender.</li>
          <li>Ask about any up-front fees.</li>
          <li>Do your own calculations. Know how much you may reasonably qualify.</li>
          <li>Know your payment obligation throughout the term of the loan.</li>
          <li>Check whether monthly payments will change during the loan term.</li>
          <li>Find out if there are any balloon payments due.</li>
          <li>Ask if the loan has any prepayment penalty.</li>
        </ol>

        <h3 className={styles.paraHeading}>Don&apos;ts</h3>

        <ol className={styles.list}>
          <li>Do not sign any waiver of rights.</li>
          <li>Do not proceed if you are rushed to sign the loan agreement.</li>
          <li>Do not sign before reading all the documents very carefully.</li>
          <li>
            Do not sign any document with blank spaces.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'07/14/2022'} />
      </div>

      <FeedbackBox source="/debt/predatory-lending/text/short/14-jul-2022/03" />
    </>
  );
};
