import React from 'react';
import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';

export const TypesOfLifeInsurance: React.FC = () => {
  const columns = [
    {
      dataField: 'type',
      text: 'Insurance Type',
    },
    {
      dataField: 'termLife',
      text: 'Term Life',
    },
    {
      dataField: 'termTo100',
      text: 'Term to 100',
    },
    {
      dataField: 'wholeLife',
      text: 'Whole Life',
    },
    {
      dataField: 'universalLife',
      text: 'Universal Life',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Types of Life Insurance</h1>

        <p className={styles.bodyText}>
          Main types of Life Insurance and their key characteristics have been appended below. This matrix should help
          you to choose a suitable policy.
        </p>

        <SimpleTable
          keyField="id"
          data={[
            {
              type: 'Type',
              termLife: 'Term',
              termTo100: 'Permanent Life',
              wholeLife: 'Permanent Life',
              universalLife: 'Permanent Life',
            },
            {
              type: 'Complexity',
              termLife: 'Simplest',
              termTo100: 'Simple',
              wholeLife: 'Moderate',
              universalLife: 'Moderate',
            },
            {
              type: 'Price Position',
              termLife: 'Cheapest',
              termTo100: 'Low',
              wholeLife: 'Economic',
              universalLife: 'High',
            },
            {
              type: 'Coverage',
              termLife: '10 Y, 20 Y, 30 Y',
              termTo100: 'Whole Life',
              wholeLife: 'Whole Life',
              universalLife: 'Whole Life',
            },
            {
              type: 'Benefit Availability',
              termLife: 'Only if dead within term',
              termTo100: 'As long as alive',
              wholeLife: 'As long as alive',
              universalLife: 'As long as alive',
            },
            {
              type: 'Feature A',
              termLife: 'No accumulated value, insurance only.',
              termTo100: 'No accumulated value.',
              wholeLife: 'Premium unchanged throughout policy.',
              universalLife: 'Choose premium for cash accumulation.',
            },
            {
              type: 'Feature B',
              termLife: 'Renewable at term end.',
              termTo100: 'Premium unchanged during term',
              wholeLife: 'Borrow money against policy',
              universalLife: 'Well defined policy + investment account',
            },
            {
              type: 'Termination Option',
              termLife: 'Terminates at Term End',
              termTo100: 'Yes',
              wholeLife: 'Yes',
              universalLife: 'Yes',
            },
          ]}
          columns={columns}
          bootstrap4
        />
        <p className={clsx(styles.bodyText, styles.last)}>
          More detail discussion on each of these types are separately presented.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'03/26/2022'} />
      </div>

      <FeedbackBox source="/insurance/thebasics/text/short/26-mar-2022/01" />
    </>
  );
};
