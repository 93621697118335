import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { Modal } from '../../components/Modal';
import { GoalForm } from './GoalForm';
import { GoalFormInputs } from './types';
import { updateGoal, getGoalList, selectOnlyCompleteGoals, updateGoalPriority } from './goalSlice';
import { BadRequestError } from '../../helpers/api';

import { selectGoalById, selectListLoading } from './goalSlice';

export const EditGoal: React.FC = () => {
  let { goalId } = useParams();
  goalId = parseInt(goalId, 10);
  const goalItem = useSelector(selectGoalById(goalId));
  const listLoading = useSelector(selectListLoading);
  const activeGoals = useSelector(selectOnlyCompleteGoals(false));

  const history = useHistory();
  const dispatch = useDispatch();
  const getGoalListAction = useCallback(() => dispatch(getGoalList()), [dispatch]);

  const onSubmit = async (data: GoalFormInputs) => {
    try {
      await updateGoal(goalId, data);

      // update already existing goals if needed

      const alreadyExistingGoal = activeGoals.find((goal) => goal.priority === data.priority);
      const nonUpdatedGoal = activeGoals.find((goal) => goal.id === goalId);

      if (alreadyExistingGoal && nonUpdatedGoal) {
        await updateGoalPriority(alreadyExistingGoal.id, nonUpdatedGoal.priority);
      }

      history.push('/goals/goal');
      getGoalListAction();
    } catch (err) {
      if (err instanceof BadRequestError) {
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    }
  };

  if (listLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Modal title={['Edit', 'Goal']}>
      <GoalForm defaultValues={goalItem} onSubmit={onSubmit} />
    </Modal>
  );
};
