import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';
import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import infoImg from '../common/images/info.svg';
import { PublishedDate } from './PublishedDate';

export const CalculatingCreditScore: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          Calculating Credit Score
          <HelpBox placement="right" content={<p className="mb-0">Source: TransUnion, Equifax</p>}>
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <p className={styles.bodyText}>
          Following five factors are considered while calculating any credit score. Importance or weight used for the
          respective factors are also mentioned next to it. However, these are approximate figures and may vary
          depending on the service providers.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Payment History: 35%</h2>

        <p className={styles.bodyText}>
          Before approving any credit, any lender or creditor would like to assess how an individual is going perform in
          the future. They think, past conduct is the best predictor of anyone’s future behaviour. Not surprisingly,
          Payment History is the most important factor in calculating Credit Score and carries more than one third of
          total importance or weight.
        </p>

        <p className={styles.bodyText}>
          Repayment track record of all types of loans, credits, credit cards and few selected bills are analysed.
          Following details are particularly monitored:
        </p>

        <ol className={styles.list}>
          <li>How late the payments were made from the due date.</li>
          <li>Amount of the late payments.</li>
          <li>How frequently the payments were missed.</li>
          <li>How recently payments were missed.</li>
          <li>How many of the total credit accounts or facilities missed payments.</li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>Used vs. Available Credit: 30%</h2>

        <p className={styles.bodyText}>
          How much of the credit facilities used is the second most important factor and constitutes slightly less than
          one third of total importance or weight for the credit score calculation. Credit facilities like line of
          credit, revolving credit, credit card etc. are considered here, which are revolving and evergreen in nature.
          Generally, total balance on these types of credit facilities will be compared to the total amount of credit
          limit from these same credit facilities. Lenders want see how responsibly someone is managing her debt and
          whether there is enough cushion in the within the currently approved credit facilities, should any sudden and
          unforeseen need for funds arises. Total amount of credit limit is also considered on a stand-alone basis.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Credit History: 15%</h2>

        <p className={styles.bodyText}>
          Creditors, in principle, would like to see how responsibly and persistently an individual can manage her
          financial obligations over time. Creditors think nothing tells the future better than the past. Accordingly,
          longer the track record, the better. Creditors pay attention to the aging of the oldest credit in file as well
          as the most recent ones. Recently opened credit account(s) indicate whether there is any sudden increase in
          the borrowing or funding requirements, which could trigger a flag.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Public Records: 10%</h2>

        <p className={styles.bodyText}>
          Events like bankruptcy or overdue payables collected through any Collection Agency is retained in the public
          records. These events reflect negatively on the individual and portrays on how an individual was not able to
          responsibly manage her financial obligations. These events have significant negative effect on the credit
          score.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Inquiries: 10%</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          Every time a lender or creditor access someone&apos;s credit records to assess her credit worthiness, it will
          ultimately affect her credit score. These types of inquiries are called Hard Inquires. Inquiries by many
          lenders to evaluate someone&apos;s credit at the same time, may indicate that the individual is desperately
          trying to get some loan or credit, which could potentially be an early sign of financial difficulty. However,
          that may not always be the case. Therefore, other vital warning signs also need to be taken into
          consideration.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'10/10/2021'} />
      </div>

      <FeedbackBox source="/creditscore/understandingcredit-score/text/short/10-oct-2021/01" />
    </>
  );
};
