import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const PayYourselfFirst: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Pay Yourself First</h1>

        <ul className={styles.list}>
          <li>
            <strong>What is it: </strong>
            Set aside a portion of your income for long-term savings and investments. This amount must be transferred to
            a separate dedicated account before spending rest of the income for any other purposes.
          </li>

          <p className={styles.bodyText}>
            This fund will be locked down and you will not be able to access unless there is an extreme emergency. Money
            saved for any other purposes like down payment of purchase of car, home etc. are excluded from this
            calculation.
          </p>

          <li>
            <strong>Origins: </strong>This is an ancient concept, and the practice can be traced back to
            four-thousand-year-old Babylonian civilization.
          </li>

          <li>
            <strong>How Much to Save: </strong>Saving anywhere between 1% to 20% of the net take-home pay is ideal. 10%
            is considered to the most common wisdom. Some may save 25% or more. The more, the better.
          </li>

          <p className={styles.bodyText}>
            If saving larger percentage is a stretch, then it is acceptable to start small – as little as 1%. At the
            early stage, it is more important to start the saving process than how much is being saved.
          </p>

          <p className={styles.bodyText}>
            Go over the Arctic Rich recommended budgeting exercises to determine how much you can reasonably save
            without any unnecessary stress.
          </p>

          <li>
            <strong>Mode of Operation: </strong>The saving process must be automatic. Your bank can set up an automatic
            transfer of a certain fixed amount from your everyday checking account to your destination saving account.
          </li>

          <p className={styles.bodyText}>
            Your savings will be temporarily parked in these saving accounts and will eventually be transferred to
            different forms of investments such as Emergency Funds, Short Term Investments and Long-Term Investments
            depending on your context.
          </p>

          <li>
            <strong>Where to Save: </strong>Refer to Arctic Rich’s preferred list of accounts where you can save these
            funds. Key considerations for selecting an ideal account would be:
            <ol className={styles.subList} type="a">
              <li>a) Flexibility to deposit and withdrawal,</li>
              <li>b) Interest earned, and</li>
              <li>c) Transaction cost.</li>
            </ol>
          </li>

          <li>
            <strong>Benefits: </strong>This a simple, easy to track system that focuses solely on the big picture. This
            System is recommended by Arctic Rich. This system allows to bypass the painstaking process of tracking every
            dollar spent, which is not only time consuming but also very hard to implement.
          </li>

          <li>
            <strong>Caution: </strong>Pay Yourself First System would only be meaningful, if you can live within your
            means. If you are partly funding your lifestyle with Line of Credit, Credit Card, Home Equity Loan, or any
            other borrowed funds, while simultaneously saving these funds, then this system would not be helpful at all.
          </li>

          <p className={styles.bodyText}>
            While you may feel great about your increasing saving balances, any savings made will be eroded by the
            incremental borrowing and its associated costs.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </p>
        </ul>

        <PublishedDate date={'01/20/2022'} />
      </div>

      <FeedbackBox source="/saving/timelessprinciples/text/short/20-jan-2022/01" />
    </>
  );
};
