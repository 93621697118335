import React, { useState } from 'react';
import { Modal } from '../Modal';

import styles from './ConfirmMessage.module.scss';

interface ConfirmMessageProps {
  message: React.ReactNode | string;
  onConfirm: () => void;
  onClose: () => void;
  confirmBtnVariant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'link';
  confirmBtnLabel?: string;
}

const ConfirmMessage = ({ message, onConfirm, onClose, confirmBtnVariant, confirmBtnLabel }: ConfirmMessageProps) => {
  const [show, setShow] = useState(true);

  const onModalClose = () => {
    setShow(false);
    onClose();
  };

  const onDeleteButtonClick = async () => {
    onConfirm();
  };

  return (
    <>
      <Modal title={['Delete', 'Confirmation']} variant="deleteConfirmation" show={show} onClose={onModalClose}>
        <div className="d-flex">
          <div className={styles.deleteImage} />
          <div>
            <div className={styles.deletePhraseOne}>
              Are you sure you want to permanently remove the selected items(s)?
            </div>
            <div className={styles.deletePhraseTwo}>This action can’t be undone.</div>
          </div>
        </div>
        <div className={styles.deleteButtonsContainer}>
          <button className={styles.deleteCancelButton} onClick={onModalClose}>
            Cancel
          </button>
          <button className={styles.deleteConfirmButton} onClick={onDeleteButtonClick}>
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmMessage;
