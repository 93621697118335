import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import infoImg from '../common/images/info.svg';
import clsx from 'clsx';
import HelpBox from '../components/Common/HelpBox';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const FourPercRule: React.FC = () => {
  const columns = [
    {
      dataField: 'col1',
      text: 'Inflation-Adjusted Withdrawals',
    },
    {
      dataField: 'col2',
      text: '3%',
    },
    {
      dataField: 'col3',
      text: '4%',
    },
    {
      dataField: 'col4',
      text: '5%',
    },
    {
      dataField: 'col5',
      text: '6%',
    },
    {
      dataField: 'col6',
      text: '7%',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          The 4% Rule
          <HelpBox
            placement="right"
            content={
              <>
                <p className="mb-1">
                  <strong>Source</strong>
                </p>
                <p className="mb-1">Journal of Financial Planning, April 2011.</p>
                <p className="mb-1">Author Philio L. Cooley, Carl M. Hubbard and Daniel T. Walz.</p>
                <p className="mb-0">Data used: 1926-2009.</p>
              </>
            }
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <h2 className={styles.subHeading}>Retirement Portfolio Withdrawal Plan</h2>

        <ul className={styles.list}>
          <li>
            4% Rule is a guidance on how much money we could withdraw during retirement without running out of our
            Initial Retirement Investment Portfolio.
          </li>
          <li>
            <strong>The 4% Rule: </strong>If investors withdraw inflation-adjusted 4% of their original investment
            balance at the time of their retirement every year, then their original investment balance is likely to last
            more than 30 years.
          </li>
          <li>
            <strong>Example: </strong>Emma retires in year 2021 at age 55 with a balance of $1,000,000 investment funds.
            If she withdraws inflation-adjusted 4% of the investment balance every year (which is $40,000 per year),
            then her investment will likely last 30 years or more. Emma will likely never run out of money.
          </li>
          <li>
            <strong>Inflation-adjusted Withdrawal: </strong>In the above example, Emma withdrew $40,000 in the first
            year of her retirement. Let’s say, in the second year of her retirement, inflation was 3%. So, in the second
            year Emma could withdraw $41,200. This extra withdrawal amount of $1,200 would help her to cover the extra
            cost of living due to inflation.
          </li>

          <li>
            <strong>IPortfolio Mix: </strong>Ideal mix of Investment Portfolio for this type of outcome would be 75%
            Stocks (S&P 500 Index Funds) and 25% Bonds (Long-term, High Grade Corporate Bonds).
          </li>

          <li>
            <strong>Chances of Investment Portfolio Lasting 30 Years.</strong>
            <p className={clsx(styles.bodyText, styles.last)}>
              Percentages in the following table indicates the probability or chances of keeping the investment
              portfolio value same as the first year of retirement. For example, with 75% Stocks and 25% Bonds Portfolio
              Mix, the Initial Investment Portfolio has 100% chance of retaining the same value after 30 years of
              retirement.
            </p>
          </li>
        </ul>

        <SimpleTable
          keyField="o0"
          data={[
            { col1: '100% Stocks', col2: '100%', col3: '98%', col4: '80%', col5: '62%', col6: '55%' },
            { col1: '75% Stocks + 25% Bonds', col2: '100%', col3: '100%', col4: '82%', col5: '60%', col6: '45%' },
            { col1: '50% Stocks + 50% Bonds', col2: '100%', col3: '100%', col4: '82%', col5: '60%', col6: '45%' },
            { col1: '25% Stocks + 75% Bonds', col2: '100%', col3: '80%', col4: '31%', col5: '22%', col6: '7%' },
            { col1: '100% Bonds', col2: '84%', col3: '35%', col4: '22%', col5: '11%', col6: '2%' },
          ]}
          columns={columns}
          bootstrap4
        />

        <PublishedDate date={'03/01/2022'} />
      </div>

      <FeedbackBox source="/4-percent-rule/text/short/01-mar-2022/06" />
    </>
  );
};
