import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import clsx from 'clsx';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const Wants30: React.FC = () => {
  const columns = [
    {
      dataField: 'col1',
      text: '',
      headerAttrs: {
        hidden: 'true',
      },
    },
    {
      dataField: 'col2',
      text: '',
      headerAttrs: {
        hidden: 'true',
      },
    },
    {
      dataField: 'col3',
      text: '',
      headerAttrs: {
        hidden: 'true',
      },
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Wants 30%</h1>

        <p className={styles.bodyText}>
          30% is a reasonably decent amount allocated for Wants, so that there is ample of room for you to enjoy life
          and relax a little bit. After all, it must be a sustainable solution to stick to.
        </p>

        <p className={styles.bodyText}>
          Having a too strict plan with all work and no play will likely lose the steam. Lack of the enthusiasm you
          began with, may lead you to give up the effort altogether.
        </p>

        <p className={styles.bodyText}>Balance is very important.</p>

        <p className={styles.bodyText}>
          Wants are for fun, which is relative and varies from person to person. This is an open concept. Unlike the
          Must Have category (which is strictly defined), you can do almost anything you enjoy and deem as fun, as long
          as you don’t exceed the 30% limit of your income.
        </p>

        <p className={styles.bodyText}>
          Save from your Want category in advance, if you are planning to spend any large amount in a single month for
          Wants like vacations.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Don’ts</h2>

        <ol className={styles.list}>
          <li>Don’t borrow from next month’s Want Fund.</li>
          <li>Don’t dip into your savings or borrow money to pay for your Wants.</li>
        </ol>

        <h2 className={clsx(styles.paraHeading)}>Examples of Wants</h2>

        <SimpleTable
          keyField="id"
          data={[
            { col1: 'Netfilx, Spotify Subscriptions', col2: 'Movies', col3: 'Vacation' },
            { col1: 'Cleaning Service', col2: 'Starbucks Coffee', col3: 'Birthday present' },
            { col1: 'Haircut', col2: 'Dining out', col3: 'Golf Club Membership' },
            { col1: 'New Sofa', col2: 'Babysitter for a night out', col3: 'Nails, Massage' },
            { col1: 'T-shirt', col2: 'Car wash', col3: 'Soccer camp' },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={clsx(styles.paraHeading)}>Why Strict Limit</h2>
        <p className={styles.bodyText}>
          30% cap for Wants is to ensure liberation and not to be interpreted as deprivation. Setting up a strict limit
          for Wants makes the process worry-free. You know you are within the pre-set limit and playing safe. Now you
          can freely give yourself 100% permission spend the allocated fund without any tension at the back of your
          mind.
        </p>

        <p className={styles.bodyText}>
          Having a strict limit of 30% for Wants is the most important part of the plan, and the only antidote of
          crash-budgeting.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/24/2022'} />
      </div>

      <FeedbackBox source="/saving/20-30-50-plan/text/short/24-feb-2022/03" />
    </>
  );
};
