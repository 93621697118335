import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const DisabilityInsurance: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Disability Insurance</h1>

        <p className={styles.bodyText}>
          Disability insurance offers income protection to individuals who become disabled for a long period of time,
          and as a result can no longer work during that time.
        </p>

        <h2 className={styles.paraHeading}>Highlights</h2>

        <ol className={styles.list}>
          <li>The Disability Insurance generally cover between 60-85% of annual gross income.</li>
          <li>Majority of the policies begin paying within 3-6 months after declaration of disability.</li>
          <li>Disability Policy is expected to offer benefits until age 65 of the policy holder.</li>
          <li>
            Canada Pension Plan offers limited Disability Benefits, which you may qualify. This amount is deductible
            from eligible the benefit from the Insurance Company.
          </li>
          <li>
            You may consider buying Owner Occupation Coverage, which will cover, if you are unable to do the work
            related to your own occupation.
          </li>
        </ol>

        <h2 className={styles.paraHeading}>Caution</h2>

        <ol className={styles.list}>
          <li>
            If there is any disability insurance policy in place from your employer, we recommend to double check, if
            this insurance policy could be transferred to personal name when the beneficiary will no longer be with the
            company..
          </li>
          <li>If the employer is paying the insurance premium, the proceeds of the benefit will be taxable.</li>
          <li>
            Insurance Companies generally run a thorough check on beneficiary&apos;s past health records by engaging a
            highly skilled professional investigator in case of any claim. Therefore, it is very important to have full
            disclosures on the policyholder&apos;s health records in a timely manner.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ol>

        <PublishedDate date={'05/29/2022'} />
      </div>

      <FeedbackBox source="/insurance/other-insurance/text/short/29-may-2022/01" />
    </>
  );
};
