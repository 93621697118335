import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { BillItems } from './types';
import {
  Table,
  dateFormatter,
  priceFormatter,
  deleteIconFormatter,
  editIconFormatter,
  TableSortCaret,
} from '../../components/Table';

import ColumnHeaderWithHelp from '../../components/Common/ColumnHeaderWithHelp';
import styles from '../../components/Table/Table.module.scss';
import { getBillList, deleteBill } from './billSlice';
import { useDispatch } from 'react-redux';

const SectorHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention here the Sector to which the Bill belongs.​</li>
        <br />
        <li>If it is an Electricity Bill, then mention Electricity here.​</li>
      </ul>
    </>
  );
};

const ProviderHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention here the name of the Provider of your goods or service.​​</li>
        <br />
        <li>If it is your cell phone Bill, then mention Bell, Rogers, Telus etc. here.​​</li>
      </ul>
    </>
  );
};

const AmountHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Mention the amount which will be due for payment here.​​​</li>
        <br />
        <li>
          If the amount may vary over the billing cycles, then mention the average or the highest amount of last 12
          months.​​
        </li>
      </ul>
    </>
  );
};

const DueDateHelp = () => {
  return (
    <>
      <ul className="mb-0">
        <li>Select the last Due Date here. You will find this date in your previous invoice.</li>
        <br />
        <li>Also, select the appropriate Billing Cycle Here.​</li>
        <br />
        <li>Next Due Dates will then automatically be updated and displayed here.​​</li>
      </ul>
    </>
  );
};

export interface BillTableProps {
  billList: BillItems;
}

export const BillTable: React.FC<BillTableProps> = ({ billList }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const getBillListAction = useCallback(() => dispatch(getBillList()), [dispatch]);

  const deleteEntityAction = async (id: number) => {
    await deleteBill(id);
    getBillListAction();
  };

  const columns = [
    {
      dataField: 'sector',
      text: 'Sector',
      sort: true,
      helpText: <SectorHelp />,
      sortCaret: TableSortCaret,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'institution',
      text: 'Provider',
      sort: true,
      sortCaret: TableSortCaret,
      helpText: <ProviderHelp />,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'amount',
      text: 'Amount',
      sort: true,
      formatter: priceFormatter,
      sortCaret: TableSortCaret,
      helpText: <AmountHelp />,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'due_date',
      text: 'Due Date',
      sort: true,
      formatter: dateFormatter('MMM dd, yyyy'),
      sortCaret: TableSortCaret,
      helpText: <DueDateHelp />,
      headerFormatter: ColumnHeaderWithHelp,
    },
    {
      dataField: 'id',
      text: 'Edit',
      sort: false,
      formatter: editIconFormatter,
      classes: styles.editIconColumn,
      formatExtraData: {
        baseUrl: '/expense/bill/edit/',
        locationObj: location,
      },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: false,
      formatter: deleteIconFormatter(deleteEntityAction),
      classes: styles.deleteIconColumn,
    },
  ];
  return <Table keyField="id" data={billList} columns={columns} bootstrap4 />;
};
