import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Link, useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import clsx from 'clsx';

import { Form, SubmitButton } from '../../components/Form';
import { TwoFaFormInputs } from './types';
import { verifySms, resendCode } from './authSlice';
import { BadRequestError, setAccessTokenToLS, setRefreshTokenToLS } from '../../helpers/api';
import { TwoFAInput } from './TwoFAInput';

import styles from './TwoFA.module.scss';

const schemaResolver = yupResolver<TwoFaFormInputs>(yup.object().shape({}));

export const TwoFA: React.FC = () => {
  const [formError, setFormError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { verificationCode } = useParams();
  const history = useHistory();

  const onResendClickHandler = async () => {
    setSubmitting(true);

    try {
      await resendCode({
        verificationCode: verificationCode,
      });
    } catch (err) {
      if (err instanceof BadRequestError) {
        if (err.response.code && Array.isArray(err.response.code)) {
          setFormError(err.response.code[0]);
        }
        if (err.response.verification && Array.isArray(err.response.verification)) {
          setFormError(err.response.verification[0]);
        }
        if (err.response.non_field_errors && Array.isArray(err.response.non_field_errors)) {
          setFormError(err.response.non_field_errors[0]);
        }
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmit = async (data: TwoFaFormInputs) => {
    setSubmitting(true);
    const code = `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`;

    try {
      const response = await verifySms({
        verificationCode: verificationCode,
        smsCode: code,
        rememberDevice: true,
      });

      setAccessTokenToLS(response.access);
      setRefreshTokenToLS(response.refresh);

      history.push('/');
    } catch (err) {
      if (err instanceof BadRequestError) {
        if (err.response.code && Array.isArray(err.response.code)) {
          setFormError(err.response.code[0]);
        }
        if (err.response.verification && Array.isArray(err.response.verification)) {
          setFormError(err.response.verification[0]);
        }
        if (err.response.non_field_errors && Array.isArray(err.response.non_field_errors)) {
          setFormError(err.response.non_field_errors[0]);
        }
        console.log(err.response);
      } else {
        console.log(err);
        console.log(err.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container fluid className="px-0 position-relative">
      <h1 className={styles.title}>Two-step Authentication</h1>
      <p className={styles.description}>
        To continue, please enter the 6-digit verification
        <br />
        code sent to your phone.
      </p>
      <Form onSubmit={onSubmit} resolver={schemaResolver}>
        <p className={styles.didntReceive}>
          Didn&apos;t receive a code?{' '}
          <Link to="#" className={styles.link} onClick={onResendClickHandler}>
            Resend
          </Link>
        </p>
        <TwoFAInput name="code" />
        <div className={styles.formErrorWrapper}>
          {formError ? <div className={clsx('invalid-feedback', styles.formErrorBlock)}>{formError}</div> : null}
        </div>
        <SubmitButton value="Continue" disabled={submitting} type="submit" />
      </Form>
    </Container>
  );
};
