import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import focusImg from '../common/images/focusImg.png';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const Focus: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Focus</h1>
        <div className={styles.imgContainer}>
          <img src={focusImg} className={styles.imgCnt} alt="" />
        </div>

        <p className={styles.bodyText}>
          We begin by managing our expenses in a way that we can set aside enough money to save. Managing expense does
          not mean giving up what we are already enjoying now rather paying much less for the very same stuff.
        </p>
        <p className={styles.bodyText}>
          First Step is to focus on the big ticket items. It is important to remember the 80:20 rule which stipulates,
          we spend 80% of our money in just 20% of the items. We can significantly reduce our expenses in these big
          ticket items, simply by making informed choices. In other words, we can see some quick and encouraging results
          in a short period of time just by focusing on few selected items.
        </p>
        <p className={clsx(styles.bodyText, styles.last)}>
          Second Step is to focus on the other 20% of our expense items. Saving from this small expenses can also
          quickly add up to a very decent sum, and if accumulated over a long period of time could become a very
          significant and game changing figure.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>
        <PublishedDate date={'06/06/2021'} />
      </div>

      <FeedbackBox source="/overview/learn/text/short/06-jun-2021/01" />
    </>
  );
};
