import jwtDecode from 'jwt-decode';
import { DecodedToken } from './types';

export const API_URL_PREFIX = process.env.REACT_APP_API_ENDPOINT;
export const LS_ACCESS_TOKEN_KEY = 'arctic_rich_access_token';
export const LS_REFRESH_TOKEN_KEY = 'arctic_rich_refresh_token';

export const getAccessTokenFromLS = () => localStorage.getItem(LS_ACCESS_TOKEN_KEY);
export const setAccessTokenToLS = (tokenValue: string) => localStorage.setItem(LS_ACCESS_TOKEN_KEY, tokenValue);
export const getRefreshTokenFromLS = () => localStorage.getItem(LS_REFRESH_TOKEN_KEY);
export const setRefreshTokenToLS = (tokenValue: string) => localStorage.setItem(LS_REFRESH_TOKEN_KEY, tokenValue);
export const clearTokens = () => {
  localStorage.removeItem(LS_ACCESS_TOKEN_KEY);
  localStorage.removeItem(LS_REFRESH_TOKEN_KEY);
};

export class AuthenticationError extends Error {
  response: Record<string, unknown>;

  constructor(response: Record<string, unknown>) {
    super('401 Authentication Error');
    this.name = 'AuthenticationError';
    this.response = response;
  }
}

export class BadRequestError extends Error {
  response: Record<string, unknown>;

  constructor(response: Record<string, unknown>) {
    super('400 Bad Response');
    this.response = response;
  }
}

export const redirectToLoginPage = () => {
  window.location.replace('/user/login');
  return;
};

export const redirectToTwoFaPage = (verificationCode: string) => {
  window.location.replace(`/user/two-fa/${verificationCode}`);
  return;
};

export const redirectToSignUp2Page = () => {
  window.location.replace(`/user/sign-up/address-info`);
  return;
};

export const redirectToSignUp3Page = () => {
  window.location.replace(`/user/sign-up/subscription-info`);
  return;
};

export const loginWithCredentials = async ({ email, password }: { email: string; password: string }) => {
  const response = await fetch(`${API_URL_PREFIX}/token/`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 401) {
    const jsonResponse = await response.json();
    throw new AuthenticationError(jsonResponse);
  }

  const jsonResponse = await response.json();

  setAccessTokenToLS(jsonResponse.access);
  setRefreshTokenToLS(jsonResponse.refresh);

  switch (jsonResponse.next_step) {
    case 'verify_TFA':
      return redirectToTwoFaPage(jsonResponse.verification);
    case '2':
      return redirectToSignUp2Page();
    case '3':
      return redirectToSignUp3Page();
  }

  return jsonResponse;
};

export const requestPasswordReset = async ({ email }: { email: string }) => {
  const response = await fetch(`${API_URL_PREFIX}/user/reset/`, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.status === 400) {
    const jsonResponse = await response.json();
    throw new BadRequestError(jsonResponse);
  }
  return await response.json();
};

export const executePasswordReset = async ({ password }: { password: string }, token: string | null) => {
  const response = await fetch(`${API_URL_PREFIX}/user/reset/confirm/`, {
    method: 'POST',
    body: JSON.stringify({
      password,
      token,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.status > 200) {
    const jsonResponse = await response.json();
    throw new BadRequestError(jsonResponse);
  }
  return await response.json();
};

const refreshAccessToken = async () => {
  const response = await fetch(`${API_URL_PREFIX}/token/refresh/`, {
    method: 'POST',
    body: JSON.stringify({
      refresh: getRefreshTokenFromLS(),
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const json = await response.json();
  setAccessTokenToLS(json.access);
};

const checkAndUpdateTokens = async () => {
  if (!getAccessTokenFromLS()) {
    return redirectToLoginPage();
  } else {
    try {
      /**
       * Please refer to http://169.48.29.83:8000/api/v1/#gettoken to understand more about tokens workflow
       */
      const accessTokenExpiryTime = jwtDecode<DecodedToken>(getAccessTokenFromLS() as string).exp;
      const isAccessTokenOutdated = Date.now() > accessTokenExpiryTime * 1000;

      if (isAccessTokenOutdated) {
        const refreshTokenExpiryTime = jwtDecode<DecodedToken>(getRefreshTokenFromLS() as string).exp;
        const isRefreshTokenOutdated = Date.now() > refreshTokenExpiryTime * 1000;

        if (isRefreshTokenOutdated) {
          return redirectToLoginPage();
        } else {
          await refreshAccessToken();
        }
      }
    } catch (e) {
      clearTokens();
      return redirectToLoginPage();
    }
  }
};

const genericResponseHandler = async (response: Response) => {
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.indexOf('application/json') !== -1) {
    if (response.status === 400) {
      const jsonResponse = await response.json();
      throw new BadRequestError(jsonResponse);
    }
    if (response.status === 401) {
      const jsonResponse = await response.json();
      throw new AuthenticationError(jsonResponse);
    }
    return await response.json();
  } else {
    if (response.ok) {
      return Promise.resolve();
    } else {
      throw new BadRequestError({});
    }
  }
};

export const doGetRequest = async (url: string, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());
  const response = await fetch(`${API_URL_PREFIX}/${url}`, {
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  });

  try {
    return await genericResponseHandler(response);
  } catch (err) {
    if (err instanceof AuthenticationError) {
      redirectToLoginPage();
    }
  }
};

export const doPostRequest = async (url: string, requestBody: unknown, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const response = await fetch(`${API_URL_PREFIX}/${url}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  });

  return await genericResponseHandler(response);
};

export const doDeleteRequest = async (url: string, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const response = await fetch(`${API_URL_PREFIX}/${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  });

  return await genericResponseHandler(response);
};

export const doPatchRequest = async (url: string, requestBody: unknown, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const response = await fetch(`${API_URL_PREFIX}/${url}`, {
    method: 'PATCH',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  });

  return await genericResponseHandler(response);
};

export const doPutRequest = async (url: string, requestBody: unknown, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const response = await fetch(`${API_URL_PREFIX}/${url}`, {
    method: 'PUT',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  });

  return await genericResponseHandler(response);
};

export const uploadFile = async (url: string, file: File, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const data = new FormData();
  data.append('file', file);

  const response = await fetch(`${API_URL_PREFIX}/${url}`, {
    method: 'POST',
    headers: {
      // 'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
    body: data,
  });
  return await genericResponseHandler(response);
};

export const doMultiplePostRequest = async (urls: Array<string>, requestBody: unknown, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const options = {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  };
  const apiUrls: Array<any> = [];
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    apiUrls.push(fetch(`${API_URL_PREFIX}/${url}`, { ...options }).then((value) => value.json()));
  }

  Promise.all(urls)
    .then((responses) => {
      return responses;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const doMultipleDeleteRequest = async (urls: Array<string>, requestBody: unknown, useCredentials = true) => {
  useCredentials && (await checkAndUpdateTokens());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...(useCredentials ? { Authorization: `Bearer ${getAccessTokenFromLS()}` } : {}),
    },
  };
  const apiUrls: Array<any> = [];
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    apiUrls.push(fetch(`${API_URL_PREFIX}/${url}`, { ...options }).then((value) => value.json()));
  }

  Promise.all(urls)
    .then((responses) => {
      return responses;
    })
    .catch((err) => {
      console.log(err);
    });
};
