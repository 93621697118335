import React, { useState, useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import ConfirmMessage from '../../components/Common/ConfirmMessage';
import { AlertItem } from './types';
import { RootState } from '../../redux/';
import Icons from './Icons';
import styles from './Alerts.module.scss';

interface AlertActionsProps {
  item?: AlertItem;
  onToggleFlag?: () => void;
  onDelete?: () => void;
  invisible?: boolean;
  disabled?: boolean;
}

const AlertActions = ({ item, onToggleFlag, onDelete, invisible, disabled }: AlertActionsProps) => {
  const { isAlertDeleted } = useSelector((state: RootState) => ({
    isAlertDeleted: state.alerts.isAlertDeleted,
  }));

  /*
    toggle flag
    */
  const onToggleFlagAlert = () => {
    if (onToggleFlag) onToggleFlag();
  };

  /*
    delete alert actions & modal & action handeling
    */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onClickDelete = () => {
    setIsOpen(true);
  };

  const onDeleteAlert = () => {
    if (onDelete) onDelete();
  };

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isAlertDeleted) {
      onClose();
    }
  }, [onClose, isAlertDeleted]);

  return (
    <>
      <div
        className={clsx('h-100', 'w-100', 'd-flex', 'align-items-center', 'justify-content-center', 'pl-3', {
          invisible: invisible,
        })}
      >
        <div>
          <Button
            variant="link"
            onClick={onToggleFlagAlert}
            disabled={disabled}
            className={clsx('d-inline-block', 'pr-0')}
          >
            <span className={clsx(styles.btnAction)}>
              <Icons icon="flag-outline" className="" />
            </span>
          </Button>
          <Button variant="link" onClick={onClickDelete} disabled={disabled} className={clsx('d-inline-block')}>
            <span className={clsx(styles.btnAction)}>
              <Icons icon="delete" />
            </span>
          </Button>
        </div>
      </div>
      {isOpen && (
        <ConfirmMessage
          message="Are you sure you want to delete the selected item(s)?"
          onConfirm={onDeleteAlert}
          onClose={onClose}
        />
      )}
    </>
  );
};

export { AlertActions };
