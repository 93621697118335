import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';

export const transformEntityISODateField = (entity: Record<string, unknown>, field: string, dateFormat: string) => {
  return { ...entity, [field]: format(parseISO(entity[field] as string), dateFormat) };
};

export const formatDate = (dateFormat: string, date: string) => {
  if (!isValid(parseISO(date))) {
    return '';
  }
  return format(parseISO(date), dateFormat);
};
