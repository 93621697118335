import React from 'react';
import clsx from 'clsx';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const RelocationExpenses: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: 'Reason for relocation',
    },
    {
      dataField: 'o1',
      text: 'From which income, relocation expenses can be deducted/claimed',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Relocation Expenses</h1>

        <p className={styles.bodyText}>
          If you move from one place to another in Canada or from outside Canada or moving out of Canada to work/run a
          business or to study as a full-time student (for post-secondary program at university, college or another
          educational institution), then you can claim relocation related expenses from the respective income.
        </p>

        <p className={styles.bodyText}>You must be resident or deemed resident of Canada to claim these expenses.</p>

        <p className={styles.bodyText}>
          Your new home must be <strong>at least 40 km closer</strong> to work/business location or school to be
          eligible to claim moving expenses.
        </p>

        <p className={styles.bodyText}>A simplified form to understand the income perspective in each category:</p>

        <SimpleTable
          keyField="id"
          data={[
            { o0: 'Employment', o1: 'Employment income' },
            { o0: 'Business/Profession', o1: 'Business income/ Self-employment income' },
            { o0: 'Full time study', o1: 'Scholarships, prizes, fellowships, research grants etc.' },
          ]}
          columns={columns}
          bootstrap4
        />

        <h2 className={clsx(styles.paraHeading)}>Moving/ Relocation Expenses You Can Claim</h2>

        <ul className={styles.list}>
          <li>Transportation, storage cost including in transit storage, packing, movers and insurance.</li>
          <li>Travel Expenses including vehicle expenses for self and family including accommodations and meals.</li>
          <li>Temporary living expenses of family for meals and lodging, generally up to a maximum of 15 days.</li>
          <li>Expenses to change the address on legal documents, permits, registrations, driving licenses etc.</li>
          <li>Cost for utility hook ups and disconnections.</li>
          <li>
            Maintenance cost for old home when vacant (maximum of $5000) for a reasonable period when efforts done to
            sell the old home e.g. property tax, insurance premiums, heating and utilities expenses, interest etc.
          </li>
          <li>Expenses of selling old home like advertising, legal fees, real estate commission etc.</li>
          <li>
            Expenses of buying new home viz. legal fees, taxes paid (except GST/HST) for transfer/ registration of
            title.
          </li>
        </ul>

        <p className={styles.bodyText}>
          <strong>Form T1-M</strong> is used while claiming these deductions at the time of filing tax return.
          Reimbursed portion of relocation expenses from employer cannot be claimed.
        </p>

        <h2 className={clsx(styles.paraHeading)}>Carry Forward</h2>

        <p className={clsx(styles.bodyText, styles.last)}>
          If any portion of relocation expenses couldn’t be claimed in the year of moving because income is less than
          moving expenses, then unclaimed expenses can be <strong>carried forward</strong> and claimed as deduction in
          the <strong>next year</strong>.
          <img src={redCircle} className={styles.redCircle} alt="" />
        </p>

        <PublishedDate date={'02/17/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expensesII/text/short/17-feb-2022/01" />
    </>
  );
};
