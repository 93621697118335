import React from 'react';
import styles from './Pages.module.scss';

import HelpBox from '../components/Common/HelpBox';
import FeedbackBox from '../components/Common/FeedbackBox';
import infoImg from '../common/images/info.svg';
import { SimpleTable } from './SimpleTable';
import { PublishedDate } from './PublishedDate';

export const LongRecordStays: React.FC = () => {
  const columns = [
    {
      dataField: 'o0',
      text: 'SL. No',
    },
    {
      dataField: 'o1',
      text: 'Record',
    },
    {
      dataField: 'o2',
      text: 'Equifax',
    },
    {
      dataField: 'o3',
      text: 'TransUnion',
    },
  ];

  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>
          How Long Records Stay
          <HelpBox
            placement="right"
            content={<p className="mb-0">Source: Equifax, Trans Union, Government of Canada</p>}
          >
            <img src={infoImg} className={styles.infoIcon} alt="" />
          </HelpBox>
        </h1>

        <p className={styles.bodyText}>Highlights are appended:</p>

        <SimpleTable
          keyField="id"
          data={[
            {
              o0: '1',
              o1: 'Late payments',
              o2: '6 Years (from the date reported)',
              o3: '6 Years (from the date reported).',
            },
            {
              o0: '2',
              o1: 'Collection or charged off accounts',
              o2: '6 Years (from the date of last payment).',
              o3: '6 Years (from the date of last payment).',
            },
            {
              o0: '3',
              o1: 'Bankruptcy (Filed once)',
              o2: '6 Years after the discharge date or 7 Years after the date filed without a discharge date.',
              o3: '6 Years after the discharge date or 7 Years (ON, QC, PE, NB, NL)',
            },
            {
              o0: '4',
              o1: 'Bankruptcy (Filed twice or more)',
              o2: '14 Years after the discharge date.',
              o3: '14 Years after the discharge date.',
            },
            {
              o0: '5',
              o1: 'Judgments (debt owed due to a court order).',
              o2: '6 Years.',
              o3: '6 Years in most Provinces 7 Years (ON, QC, NB, NL)',
            },
            {
              o0: '6',
              o1: 'Registered Consumer Proposal',
              o2: '3 Years after paying off all debts.',
              o3: '3 Years after debt paid off. 6 Years after signing proposal Whichever is sooner.',
            },
            {
              o0: '7',
              o1: 'Debt Management Plan',
              o2: '2 Years after paying off all debts.',
              o3: '2 Years after paying off all debts.',
            },
            { o0: '8', o1: 'Secured Loans', o2: '6 Years from the date filed.', o3: '6 Years from the date filed.' },
            {
              o0: '9',
              o1: 'Cheque Returned (insufficient funds)',
              o2: '6 Years from the date of incident.',
              o3: '6 Years from the date of incident.',
            },
            {
              o0: '10',
              o1: 'Credit Inquiries by the lenders for approving credit (Hard Inquiries)',
              o2: '3 Years.',
              o3: '6 Years.',
            },
            { o0: '11', o1: 'Garnishment', o2: '7 Years from date filed.', o3: '7 Years from date filed. (PE: 10Y).' },
            { o0: '12', o1: 'Foreclosure', o2: '6 Years.', o3: '6 Years.' },
            {
              o0: '13',
              o1: 'Active accounts paid as agreed',
              o2: 'As long as account is open and lenders report.',
              o3: 'As long as account is open and lenders report.',
            },
            {
              o0: '14',
              o1: 'Closed accounts paid as agreed',
              o2: '10 Years from the closing date.',
              o3: '6 Years from the closing date.',
            },
          ]}
          columns={columns}
          bootstrap4
        />

        <PublishedDate date={'09/06/2021'} />
      </div>

      <FeedbackBox source="/creditscore/managingcreditscore/thebasics/text/short/06-sep-2021/01" />
    </>
  );
};
