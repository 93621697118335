import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const GrantsSubsidiesRebates: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Grants, Subsidies and Rebates</h1>

        <h2 className={styles.subHeading}>For the Self-employed and Business Owners</h2>

        <p className={styles.bodyText}>
          If you have received grants, subsidies or rebates from the government or government agency to purchase
          depreciable assets or property, then you may reduce such asset’s capital cost by subtracting the grant,
          subsidy or rebate amount.
        </p>

        <p className={styles.bodyText}>
          If you cannot apply such grant, subsidy or rebate to reduce the capital cost of that asset, then report grant,
          subsidy or rebate as an income in Form T2121.
        </p>

        <p className={styles.bodyText}>
          If you have paid GST/HST on depreciable asset bought for your business use, then you would generally receive
          ITC (Input Tax Credit), which is also a government aid. Therefore, it can also be subtracted from the asset’s
          capital cost.
        </p>

        <p className={styles.bodyText}>
          In case of ITC (Input Tax Credit) for a passenger vehicle is used for your business, you can adopt one of
          following methods:
        </p>

        <ul className={styles.list}>
          <li>
            If passenger vehicle was used 90% or more of the time for your business, then subtract the amount of ITC
            from the vehicle&apos;s capital cost.
          </li>
          <li>
            If, passenger vehicle was used less than 90% of the time for your business, then subtract the amount of ITC
            from opening cost of the next year.
          </li>
        </ul>

        <p className={styles.bodyText}>
          If you receive an incentive from a non-government agency to buy depreciable asset for business, then you can
          either:
        </p>

        <ul className={styles.list}>
          <li>Add the amount in your income or</li>
          <li>
            Subtract the amount from the capital cost of the asset.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'03/22/2022'} />
      </div>

      <FeedbackBox source="/tax/tax-deductible-expenses-for-business/text/short/22-mar-2022/01" />
    </>
  );
};
