import React from 'react';
import { default as BootstrapTable, BootstrapTableProps } from 'react-bootstrap-table-next';

import tableStyles from '../components/Table/Table.module.scss';
import styles from './Pages.module.scss';

type TableProps = BootstrapTableProps;

export const SimpleTable: React.FC<TableProps> = ({ columns, data, ...otherProps }) => {
  return (
    <div className={styles.staticTableContainer}>
      <BootstrapTable
        {...otherProps}
        data={data}
        columns={columns}
        striped
        classes={tableStyles.tableContainer}
        headerClasses={styles.tableHeader}
        footerClasses={styles.tableFooter}
      />
    </div>
  );
};
