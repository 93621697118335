import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'redux-promise-middleware-actions';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';

import { RootState } from '../../redux/';
import { GoalFormInputs, GoalItems, MilestoneFormInputs } from './types';
import { doDeleteRequest, doGetRequest, doPatchRequest, doPostRequest } from '../../helpers/api';

export interface GoalState {
  list: GoalItems;
  listLoading: boolean;
}

const initialState: GoalState = {
  list: [],
  listLoading: false,
};

export const getGoalList = createAsyncAction('GET_GOAL_LIST', async () => {
  return await doGetRequest('goals/');
});

export const getPresetName = (presetNameDisplay: string) => {
  switch (presetNameDisplay) {
    case 'Buy a House':
      return 10;
    case 'Payoff Debt':
      return 20;
    case 'Buy a Car':
      return 30;
    case 'Save for Retirement':
      return 40;
    default:
      return 1000;
  }
};

export const createGoal = (goalRequestBody: GoalFormInputs) => {
  // TODO use transformers or something similar

  const updatedRequestBody = {
    ...goalRequestBody,
    preset_name: getPresetName(goalRequestBody.name),
    target_date: format(fromUnixTime(Date.parse(goalRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };

  return doPostRequest('goals/', updatedRequestBody);
};

export const updateGoal = (goalId: number, goalRequestBody: GoalFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...goalRequestBody,
    preset_name: getPresetName(goalRequestBody.name),
    target_date: format(fromUnixTime(Date.parse(goalRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('goals/' + goalId + '/', updatedRequestBody);
};

export const updateGoalPriority = (goalId: number, newGoalPriority: number) => {
  const updatedRequestBody = {
    priority: newGoalPriority,
  };
  return doPatchRequest('goals/' + goalId + '/', updatedRequestBody);
};

export const updateMilestonePriority = (milestoneId: number, newMilestonePriority: number) => {
  const updatedRequestBody = {
    priority: newMilestonePriority,
  };
  return doPatchRequest('milestones/' + milestoneId + '/', updatedRequestBody);
};

export const deleteGoal = async (goalId: number) => await doDeleteRequest('goals/' + goalId + '/');
export const deleteMilestone = async (milestoneId: number) => await doDeleteRequest('milestones/' + milestoneId + '/');

export const createMilestone = (milestoneRequestBody: MilestoneFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...milestoneRequestBody,
    target_date: format(fromUnixTime(Date.parse(milestoneRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPostRequest('milestones/', updatedRequestBody);
};

export const updateMilestone = (goalId: number, milestoneRequestBody: MilestoneFormInputs) => {
  // TODO use transformers or something similar
  const updatedRequestBody = {
    ...milestoneRequestBody,
    target_date: format(fromUnixTime(Date.parse(milestoneRequestBody.target_date) / 1000), 'yyyy-MM-dd'),
  };
  return doPatchRequest('milestones/' + goalId + '/', updatedRequestBody);
};

export const goalSlice = createSlice({
  name: 'goal',
  initialState,
  reducers: {},
  extraReducers: {
    [String(getGoalList.pending)]: (state) => {
      return {
        ...state,
        listLoading: true,
      };
    },
    [String(getGoalList.rejected)]: (state) => {
      return {
        ...state,
        listLoading: false,
      };
    },
    [String(getGoalList.fulfilled)]: (state, action: PayloadAction<GoalItems>) => {
      const list = action.payload;
      return {
        ...state,
        list,
        listLoading: false,
      };
    },
  },
});

export const selectGoalList = (state: RootState) => state.goal.list;
export const selectOnlyCompleteGoals = (showOnlyCompleteGoals: boolean) => (state: RootState) =>
  state.goal.list.filter((goal) => (showOnlyCompleteGoals ? goal.status === 'Complete' : goal.status !== 'Complete'));

export const selectListLoading = (state: RootState) => state.goal.listLoading;

export const selectGoalById = (goalId: number) => (state: RootState) =>
  state.goal.list.find((goalItem) => {
    return goalItem.id === goalId;
  });

export const selectMilestonesByGoalId = (goalId: number) => (state: RootState) => {
  const foundGoal = state.goal.list.find((goalItem) => {
    return goalItem.id === goalId;
  });

  if (!foundGoal) {
    return [];
  }

  return foundGoal.milestones;
};

export const selectMilestoneById = (milestoneId: number) => (state: RootState) => {
  let foundMilestone = undefined;

  state.goal.list.forEach((goalItem) => {
    const milestoneFoundOrNot = goalItem.milestones.find((milestoneItem) => {
      return milestoneItem.id === milestoneId;
    });
    if (milestoneFoundOrNot) {
      foundMilestone = milestoneFoundOrNot;
    }
  });

  return foundMilestone;
};

export default goalSlice.reducer;
