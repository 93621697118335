import React from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

import styles from './PasswordResetSent.module.scss';
import { SubmitButton } from '../../components/Form';

export const PasswordResetSent: React.FC = () => {
  return (
    <Container fluid className="px-0 position-relative">
      <h1 className={styles.title}>
        You&apos;ve successfully
        <br />
        changed your
        <br />
        Arctic Rich password
      </h1>
      <Link to="/user/login">
        <SubmitButton value="Continue" className={styles.continueButtonBlock} />
      </Link>
    </Container>
  );
};
