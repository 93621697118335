import React from 'react';

import styles from './Pages.module.scss';

import FeedbackBox from '../components/Common/FeedbackBox';
import redCircle from '../common/images/red-circle.svg';
import { PublishedDate } from './PublishedDate';

export const WhatIsACreditUnion: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>What is a Credit Union</h1>

        <ul className={styles.list}>
          <li>Credit unions are cooperatives.</li>
          <li>
            Credit unions are financial institutions offering savings, credit, and other traditional banking and
            financial services to their cooperative members.
          </li>
          <li>
            Credit unions are created, owned and operated by their cooperative members and profits are shared among
            owners.
          </li>
          <li>They are governed by a volunteer board of directors elected from their members.</li>
          <li>
            After making the first deposit in a credit union, the depositor become an owner and member of the credit
            union.
          </li>
          <li>
            All members are equal owners in the credit union and have the right to vote in electing the board members.
          </li>
          <li>Credit unions are not-for-profit entities.</li>
          <li>
            Credit unions do not pay corporate income taxes.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>

        <PublishedDate date={'08/05/2022'} />
      </div>

      <FeedbackBox source="/balance/credit-union/text/short/05-aug-2022/01" />
    </>
  );
};
