import React from 'react';
import styles from './Pages.module.scss';

import redCircle from '../common/images/red-circle.svg';
import FeedbackBox from '../components/Common/FeedbackBox';
import { PublishedDate } from './PublishedDate';

export const TireExpiry: React.FC = () => {
  return (
    <>
      <div className={styles.containerStatic}>
        <h1 className={styles.mainHeading}>Tire Expiry</h1>
        <ul className={styles.list}>
          <li>Legally, tires never expire.</li>

          <li>Technically, tires do have a limited shelf life, even if they have never been used.</li>

          <li>
            Age of tire is critical because the rubber compounds in tires begin to deteriorate as the time passes,
            irrespective of the condition of the tread.
          </li>

          <li>
            As tire&apos;s rubber cracks over time, it begins to spread on the surface as well as inside the tire. Then
            steel belts in the tread starts to separate from the rest of the tire.
          </li>

          <li>
            Some auto manufactures (Mercedes-Benz, Ford, Chrysler, Volkswagen) recommend to replace tires after 6 years
            from their date of manufacturing, irrespective of their conditions.
          </li>

          <li>
            A tire must not be used after 10 years from the date of manufacturing. Any tire in use beyond 5 years from
            the date of manufacture must be annually inspected by a qualified professional.
          </li>

          <li>At the time of purchase, tires should not be aged more than 18 months.</li>

          <li>
            Check when the tires were manufactured before buying. Month and year of manufacturing is mentioned on
            tire&apos;s sidewall. The four digits indicate the week and year of manufacturing. 0321 would mean 3rd week
            of 2021.
            <img src={redCircle} className={styles.redCircle} alt="" />
          </li>
        </ul>
        <PublishedDate date={'10/18/2021'} />
      </div>

      <FeedbackBox source="/auto/tire/text/short/18-oct-2021/01" />
    </>
  );
};
